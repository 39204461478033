import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalProps } from '@dropbox/dig-components/modal';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Link, Text } from '@dropbox/dig-components/typography';
import styles from './cross-promo-legal-disclaimer-modal.module.scss';
import {
  getBrandName,
  getBrandShortName,
  siteCodes,
} from 'js/sign-components/common/brand';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

function CrossPromoLegalDisclaimerModal({ ...props }: ModalProps) {
  const brandNameHS = getBrandName('S');
  const brandNameHW = getBrandName('W');
  const learnMoreLink = HfReactHelper.getFaqUrl(
    'articles/360010125531-Team-Members-and-Role-Permissions',
    siteCodes.forms,
  );

  return (
    <Modal
      aria-labelledby="cross-promo-legal-disclaimer-modal-title"
      isCentered
      shouldCloseOnOverlayClick={false}
      {...props}
    >
      <>
        <Modal.Header hasBottomSpacing="title-standard">
          <div className={styles.spinner}>
            <Spinner />
          </div>
          <Modal.Title id="cross-promo-legal-disclaimer-modal-title">
            <FormattedMessage
              id=""
              description="Text displayed to the user informing them that they are being redirected to the forms product."
              defaultMessage="Opening {brandNameHW}..."
              values={{
                brandNameHW,
              }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text size="medium">
            <FormattedMessage
              id=""
              description="Legal disclaimer alerting the user about security differences between experiences"
              defaultMessage="By the way, {brandNameHS} and {brandNameHW} have separate team settings and permissions. <a>Learn more</a>"
              values={{
                brandNameHS,
                brandNameHW: getBrandShortName('W'),
                // eslint-disable-next-line react/display-name
                a: (...chunks: any[]) => {
                  return (
                    <Link
                      href={learnMoreLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {chunks}
                    </Link>
                  );
                },
              }}
            />
          </Text>
        </Modal.Body>
        <Modal.Footer />
      </>
    </Modal>
  );
}

export default CrossPromoLegalDisclaimerModal;
