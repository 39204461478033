/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import configureRaven from '../common/utils/sentry';

require('../../scss/style.scss');

require('common/utils/log-uncaught-promises');
require('common/logger/track-logger');

const Application = require('./application');

// for server-side rendering (lib/vendor/react-server) (CC)
global.Application = Application;
global.app = new Application();

// Accessing 'window' breaks node.js server-side rendering.
// We MUST use 'typeof' to check for its existence. -Chris
if (typeof window !== 'undefined') {
  window.app = global.app;
}

global.HfReactHelper = HfReactHelper;
exports.components = require('./components');
global.Validation = require('signer-app/signer-experience/signer-validation-constants');
global.React = require('react');
global.ReactDOM = require('react-dom');

// Adding Sentry Raven for error logging
configureRaven({});

// hack to expose HS components to the react server (CC)
global.HSComponents = exports.components;
