/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  camelcase,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len,
  no-lone-blocks,
  react/no-direct-mutation-state,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import cx from 'classnames';
import createReactClass from 'create-react-class';
import TabSwitcher from './tab-switcher';
import Tab from './tab';

const TabbedArea = createReactClass({
  propTypes: {
    tabData: PropTypes.array.isRequired,
    shouldUseTabSwitcher: PropTypes.bool,
    areaClassNames: PropTypes.string,
    rowClassNames: PropTypes.string,
    paneAreaClassNames: PropTypes.string,
    rememberActiveTab: PropTypes.bool,
    tabTypes: PropTypes.string,
    headerText: PropTypes.string,
    bottomImage: PropTypes.string,
    bottomImageWidth: PropTypes.number,
    bottomImageHeight: PropTypes.number,
  },

  getInitialState() {
    let key = 0;
    if (this.props.rememberActiveTab) {
      key = this.getSavedActiveTab();
    }

    return {
      activeTab: key,
      paneAreaClassNames: this.props.paneAreaClassNames,
      tabRowClassNames: this.props.tabRowClassNames,
      areaClassNames: this.props.areaClassNames,
    };
  },

  getDefaultProps() {
    return {
      areaClassNames: 'tabbed-area',
      tabRowClassNames: 'm-tabbed-area--row row',
      paneAreaClassNames: 'm-tabs--pane-area l-padding-v-60',
      rememberActiveTab: false,
    };
  },

  componentDidMount() {
    $(document).bind('tabClick', this.handleClickEvent);
    // FIXME: tabClick is a custom event, we should have a constant for it
  },

  componentWillUnmount() {
    $(document).unbind('tabClick', this.handleClickEvent);
  },

  handleClickEvent(e) {
    if (e.key != this.state.activeTab) {
      this.onTabClick(e.key, true);
    }
  },

  onTabClick(key, noEvent) {
    this.setState({ activeTab: key });
    if (noEvent !== true) {
      $(document).trigger({ type: 'tabClick', key });
    }

    if (this.props.rememberActiveTab) {
      this.saveActiveTab(key);
    }
  },

  onNextClick() {
    const key =
      this.state.activeTab == this.props.tabData.length - 1
        ? 0
        : this.state.activeTab + 1;
    this.setState({ activeTab: key });
    if (this.props.rememberActiveTab) {
      this.saveActiveTab(key);
    }
  },

  onPrevClick() {
    const key =
      this.state.activeTab == 0
        ? this.props.tabData.length - 1
        : this.state.activeTab - 1;
    this.setState({ activeTab: key });
    if (this.props.rememberActiveTab) {
      this.saveActiveTab(key);
    }
  },

  saveActiveTab(key) {
    if (HfReactHelper.hasLocalStorage()) {
      localStorage.setItem('activeTab', key);
    } else if (typeof Cookie !== 'undefined') {
      Cookie.set('activeTab', key, undefined, '/');
    }
  },

  getSavedActiveTab() {
    let key;
    if (HfReactHelper.hasLocalStorage()) {
      key = localStorage.getItem('activeTab');
    } else if (typeof Cookie !== 'undefined') {
      key = Cookie.get('activeTab');
    }
    return key || 0;
  },

  render() {
    const tabs = this.props.tabData.map((tab, i) => {
      return (
        <Tab
          clickableItemData={tab.clickableItemData}
          index={i}
          key={i}
          tabClassNames={tab.tabClassNames}
          onTabClick={this.onTabClick}
          isActive={this.state.activeTab == i}
        ></Tab>
      );
    });

    const panes = this.props.tabData.map((tab, i) => {
      let classNames = {
        'is-active': this.state.activeTab == i,
      };
      if (this.props.tabTypes == 'tabs') {
        classNames = HfReactHelper.extend(
          { 'm-tabs--pane': true, row: true },
          classNames,
        );
      } else if (this.props.tabTypes == 'tiles') {
        classNames = HfReactHelper.extend(
          { 'm-tabs_tiles--pane': true },
          classNames,
        );
        this.state.paneAreaClassNames =
          'm-tabs_tiles--pane-area l-padding-t-40';
      }

      return (
        <div key={`pane${i}`} className={cx(classNames)}>
          {tab.contents}
          {tab.bottomImage ? (
            <img
              className="m-tabs_tiles--bottom-image"
              width={tab.bottomImageWidth}
              height={tab.bottomImageHeight}
              src={tab.bottomImage}
            />
          ) : null}
        </div>
      );
    });

    const tab_switcher = this.props.shouldUseTabSwitcher ? (
      <TabSwitcher
        onNextClick={this.onNextClick}
        onPrevClick={this.onPrevClick}
      ></TabSwitcher>
    ) : (
      ''
    );

    {
      this.state.tabRowClassNames =
        this.props.tabTypes == 'tiles'
          ? 'columns small-12 small-centered text-center'
          : this.state.tabRowClassNames;
    }
    {
      this.state.areaClassNames =
        this.props.tabTypes == 'tiles'
          ? 'tabbed-area text-center l-padding-t-80'
          : this.state.areaClassNames;
    }

    return (
      <div className={this.state.areaClassNames}>
        <h2 className="m-tabs_tiles--tile-header">{this.props.headerText}</h2>
        <div className={this.state.tabRowClassNames}>
          <div className="m-tabs--wrapper">{tabs}</div>
        </div>
        <div className={this.state.paneAreaClassNames} data-equalizer>
          {panes}
          {tab_switcher}
        </div>
      </div>
    );
  },
});

module.exports = TabbedArea;
