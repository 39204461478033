/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  func-names,
  import/no-extraneous-dependencies,
  max-len,
  @typescript-eslint/no-use-before-define
*/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import ApiMenuSectionItem from './section-item';
import AdjustScrollingMixin from './adjust-scrolling-mixin';

const ApiMenuSection = createReactClass({
  mixins: [AdjustScrollingMixin],

  propTypes: {
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    reference: PropTypes.string.isRequired,
    items: PropTypes.array,
    initiallyActiveItem: PropTypes.string,
    initiallyActiveSubItem: PropTypes.string,
    isInitiallyActive: PropTypes.bool,
    isInitiallyCollapsed: PropTypes.bool.isRequired,
    menuIsFixed: PropTypes.bool,
  },

  getInitialState() {
    return {
      isActive: this.props.isInitiallyActive,
      isCollapsed: this.props.isInitiallyCollapsed,
      activeItem: this.props.initiallyActiveItem,
      activeSubItem: this.props.initiallyActiveSubItem,
    };
  },

  componentWillReceiveProps(newProps) {
    this.setState({
      isActive: newProps.isInitiallyActive,
      isCollapsed: newProps.isInitiallyCollapsed,
      activeItem: newProps.initiallyActiveItem,
      activeSubItem: newProps.initiallyActiveSubItem,
    });
  },

  componentDidUpdate() {
    this.adjustScrolling();
  },

  toggleSection() {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    });
  },

  render() {
    const title = this.props.title;

    const classes = classNames({
      'l-api-docs-menu--section': true,
      'small-6': true,
      'medium-12': true,
      'large-12': true,
      'is-active': this.state.isActive,
      'is-expandable': this.props.items && this.props.items.length > 0,
      'is-collapsed': this.state.isCollapsed,
    });

    const items = this.props.items
      ? this.props.items.map((item) => {
          const isActive =
            this.state.activeItem != null &&
            this.state.activeItem.toLowerCase() == item.reference.toLowerCase();

          return (
            <ApiMenuSectionItem
              key={`item_${item.reference}`}
              title={item.title}
              reference={item.reference}
              url={item.url}
              subItems={item.subItems}
              initiallyActiveSubItem={this.state.activeSubItem}
              isInitiallyActive={isActive}
            ></ApiMenuSectionItem>
          );
        })
      : null;

    const toggleSection = this.toggleSection;

    const section = function (items, url) {
      if (items === null) {
        return linkSection(url);
      } else {
        return itemSection(items);
      }
    };

    let itemSection = function (items) {
      return (
        <div>
          <div
            className="l-api-docs-menu--section--title"
            onClick={items ? toggleSection : null}
          >
            {title}
          </div>

          {items && items.length ? (
            <div className="l-api-docs-menu--items">{items}</div>
          ) : null}
        </div>
      );
    };

    let linkSection = function (url) {
      return (
        <a
          className="l-api-docs-menu--section--title"
          style={{ margin: 0 }}
          href={url}
        >
          {title}
        </a>
      );
    };

    return (
      <div
        className={classes}
        data-ref={this.props.reference}
        data-qa-ref="l-api-docs-menu"
      >
        {section(items, this.props.url)}
      </div>
    );
  },
});

module.exports = ApiMenuSection;
