/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-extraneous-dependencies,
  react/no-string-refs
*/
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import countries from '../../constants/countries';
import './index.scss';

const messages = defineMessages({
  selectCountry: {
    id: '',
    defaultMessage: 'Select your country',
    description: 'countries dropdown',
  },
  initialCountryName: {
    id: '',
    description: 'select country dropdown on signup',
    defaultMessage: 'Select your country?',
  },
});

class CountriesDropdown extends React.Component {
  static propTypes = {
    selectedCountryCode: PropTypes.string,
    onCountryCodeChange: PropTypes.func,
    intl: PropTypes.object,
  };

  measureWidthEl = React.createRef();

  constructor(props) {
    super(props);

    const { intl } = props;

    let initialCountryName = intl.formatMessage(messages.initialCountryName);
    const countryName = countries[this.props.selectedCountryCode];
    if (this.props.selectedCountryCode !== '' && countryName !== undefined) {
      initialCountryName = intl.formatMessage(countryName);
    }

    this.state = {
      selectedCountryName: initialCountryName,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(evt) {
    const { intl } = this.props;
    const selectedCountryCode = evt.currentTarget.value;

    this.setState({
      selectedCountryName: intl.formatMessage(countries[selectedCountryCode]),
    });

    if (this.props.onCountryCodeChange !== undefined) {
      this.props.onCountryCodeChange(selectedCountryCode);
    }
  }

  componentDidMount() {
    // using setTimeout as on first render in getWidth() clientWidth returns 0
    // as element is not loaded yet
    setTimeout(() => {
      const width = this.getWidth() === undefined ? 135 : this.getWidth();
      if (width !== this.state.width) {
        this.setState({ width });
      }
    }, 300);
  }

  componentDidUpdate() {
    const width = this.getWidth();
    if (width !== this.state.width) {
      this.setState({ width });
    }
  }

  getWidth() {
    const el = this.measureWidthEl.current;
    if (el.clientWidth === 0) {
      // Don't change the width when the component is hiddent with `display: none`.
      return this.state.width;
    }
    return Math.min(300, Math.max(50, el.clientWidth));
  }

  render() {
    const { intl, selectedCountryCode } = this.props;
    const { selectedCountryName } = this.state;

    let translatedCountryList = {};
    Object.entries(countries).forEach(([key]) => {
      translatedCountryList = {
        ...translatedCountryList,
        [key]: intl.formatMessage(countries[key]),
      };
    });
    const collator = new Intl.Collator(intl.locale, {
      ignorePunctuation: true,
    });
    // Creating array here as JS object do not guarantee order by specification
    // ref: https://stackoverflow.com/questions/6166905/chrome-sorts-objects-by-key
    const sortedCountryList = Object.entries(translatedCountryList)
      .map(([k, v]) => {
        return {
          code: k,
          name: v,
        };
      })
      .sort((x, y) => collator.compare(x.name, y.name));

    return (
      <div>
        <div
          ref={this.measureWidthEl}
          style={{
            position: 'absolute',
            display: 'inline-block',
            padding: '10px',
            top: '-9999px',
            left: '-9999px',
          }}
        >
          {selectedCountryName}
        </div>
        <select
          className="countries-dropdown--select"
          style={{ width: this.state.width }}
          defaultValue={selectedCountryCode}
          onChange={this.handleChange}
        >
          <option value="" disabled="disabled">
            {intl.formatMessage(messages.selectCountry)}
          </option>
          {sortedCountryList.map((country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default injectIntl(CountriesDropdown);
