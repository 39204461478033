/* eslint-disable max-len */

import './index.scss';

import React from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import classnames from 'classnames';
import { Button } from '@dropbox/dig-components/buttons';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

import AdminSettingsDropdown from 'hellospa/components/admin-settings-dropdown';
import * as locks from 'hellospa/common/constants/admin/locks';

const messages = defineMessages({
  optionOrgAdminsManagersOnly: {
    id: '', // IDs are procedurally generated.
    description:
      'An option in a dropdown list indicating that only admins or managers with the organization can modify this setting',
    defaultMessage: 'Org admins/managers only',
  },
  optionTeamAdminsManagers: {
    id: '',
    description:
      'An option in a dropdown list indicating that admins and managers of a team can modify this setting',
    defaultMessage: 'Team admins/managers',
  },
  optionSubTeamAdminsManagers: {
    id: '',
    description:
      'An option in a dropdown list indicating that admins and managers of a subteam can modify this setting',
    defaultMessage: 'Subteam admins/managers',
  },
  optionEachUser: {
    id: '',
    description:
      'An option in a dropdown list indicating that each user of a team can modify this setting',
    defaultMessage: 'Each user',
  },
});

function AdminSettingsLockSelect({
  onLockChange,
  isOrgLevel,
  allowEachUserOption,
  ...props
}) {
  const intl = useIntl();
  const baseClass = 'admin-settings-lock-select';

  const rootClasses = React.useMemo(() => {
    return classnames(baseClass, props.className, {
      [`${baseClass}--is-disabled`]: props.disabled,
    });
  }, [props.className, props.disabled]);

  const lockOptionOrgAdminsManagersOnly = React.useMemo(
    () => ({
      value: locks.ORG_ADMINS_MANAGERS_ONLY,
      text: intl.formatMessage(
        isOrgLevel
          ? messages.optionOrgAdminsManagersOnly
          : messages.optionTeamAdminsManagers,
      ),
    }),
    [isOrgLevel, intl],
  );

  const lockOptionTeamAdminsManagers = React.useMemo(
    () => ({
      value: locks.TEAM_ADMINS_MANAGERS,
      text: intl.formatMessage(
        isOrgLevel
          ? messages.optionTeamAdminsManagers
          : messages.optionSubTeamAdminsManagers,
      ),
    }),
    [isOrgLevel, intl],
  );

  const lockOptionEachUser = React.useMemo(
    () => ({
      value: locks.EACH_USER,
      text: intl.formatMessage(messages.optionEachUser),
    }),
    [intl],
  );

  const lockOptions = React.useMemo(
    () => [
      lockOptionOrgAdminsManagersOnly,
      lockOptionTeamAdminsManagers,
      ...(allowEachUserOption ? [lockOptionEachUser] : []),
    ],
    [
      lockOptionOrgAdminsManagersOnly,
      lockOptionEachUser,
      lockOptionTeamAdminsManagers,
      allowEachUserOption,
    ],
  );

  return (
    <div className={rootClasses}>
      {props.disabled ? (
        <p className="admin-settings-lock-select__locked-text">
          {props.isDmaSetting ? (
            <div className="dropbox-button">
              <Button
                variant="primary"
                href={
                  props.dmaSettingUrl
                    ? props.dmaSettingUrl
                    : HfReactHelper.getDropboxUrl('account/general')
                }
                target="_blank"
                id="change-setting-on-dropbox"
                data-testid="change-setting-on-dropbox"
              >
                <FormattedMessage
                  id=""
                  defaultMessage="Change on Dropbox"
                  description="button, button label, when clicked will go to dropbox"
                />
              </Button>
            </div>
          ) : (
            <FormattedMessage
              id=""
              description="Shown to the user if a setting can't be changed and is locked by an account owner"
              defaultMessage="Locked by parent"
            />
          )}
        </p>
      ) : (
        <AdminSettingsDropdown
          {...props}
          onChange={onLockChange}
          options={lockOptions}
        />
      )}
    </div>
  );
}

export default AdminSettingsLockSelect;
