/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!  WARNING: Do NOT edit this file!  !!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * Foundation components are not intended to be edited to
 * satisfy specific use cases. If this component does not
 * do what you need it to, please reach out to a frontend
 * engineer before creating a new component or making
 * unapproved changes to this file.
 *
 * @author Nathan Buchar <nathan.buchar@hellosign.com>
 */

import React from 'react';
import classnames from 'classnames';
import styles from './text-tag.scss';

import { UIIcon } from '@dropbox/dig-icons';
import { WarningLine } from '@dropbox/dig-icons/assets';

export enum TextTagType {
  Neutral, // Gray
  Info, // Blue
  Success, // Green
  Warning, // Yellow
  Error, // Red
  Danger, // Dark red with danger icon
}

export type TextTagPropTypes = React.BaseHTMLAttributes<HTMLDivElement> & {
  kind: TextTagType;
};

function TextTag({
  kind,
  className = '',
  children,
  ...props
}: TextTagPropTypes) {
  return (
    <div
      data-qa-ref="text-tag"
      className={classnames(styles.textTag, className, {
        [styles.neutral]: kind === TextTagType.Neutral,
        [styles.info]: kind === TextTagType.Info,
        [styles.success]: kind === TextTagType.Success,
        [styles.warning]: kind === TextTagType.Warning,
        [styles.error]: kind === TextTagType.Error,
        [styles.danger]: kind === TextTagType.Danger,
      })}
      {...props}
    >
      {kind === TextTagType.Danger && (
        <UIIcon src={WarningLine} className={styles.dangerIcon} />
      )}
      {children}
    </div>
  );
}

export default TextTag;
