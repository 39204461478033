import React from 'react';
import classnames from 'classnames';
import styles from './icons.scss';

const GoogleDocLogo = (props) => (
  <svg
    {...props}
    viewBox="0 0 200 25"
    className={classnames(styles.icon, props.className)}
  >
    <defs>
      <pattern
        id="pattern-1"
        width="0"
        height="0"
        x="9.37384"
        y="5.24903256"
        patternUnits="userSpaceOnUse"
      >
        <use href="#image-2"></use>
      </pattern>
      <image
        id="image-2"
        width="0"
        height="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAlwSFlzAAAAAAAAAAAB6mUWpAAAAAtJREFUCB1jYAACAAAFAAGNu5vzAAAAAElFTkSuQmCC"
      ></image>
    </defs>
    <g
      id="fn-googledocs"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="Rectangle" x="0" y="0" width="105" height="30"></rect>
      <g
        id="5cfac50fa92867035622cc76_google-docs-black"
        transform="translate(0.000000, 5.000000)"
      >
        <path
          d="M39.1908,11.7919 C39.1908,10.3468 38.1503,9.42196 36.9942,9.42196 C35.7804,9.42196 34.7399,10.4046 34.7399,11.7919 C34.7399,13.1792 35.7804,14.1619 36.9942,14.1619 C38.2081,14.1619 39.1908,13.1792 39.1908,11.7919 Z M40.9249,11.7919 C40.9249,14.0462 39.133,15.7225 36.9942,15.7225 C34.8555,15.7225 33.0636,14.0462 33.0636,11.7919 C33.0636,9.47977 34.8555,7.86127 36.9942,7.86127 C39.1908,7.80347 40.9249,9.47977 40.9249,11.7919 Z"
          id="Shape"
          fill="#222222"
        ></path>
        <path
          d="M47.7457,11.7919 C47.7457,10.3468 46.7052,9.42196 45.4914,9.42196 C44.2775,9.42196 43.237,10.4046 43.237,11.7919 C43.237,13.1792 44.2775,14.1619 45.4914,14.1619 C46.7052,14.1619 47.7457,13.1792 47.7457,11.7919 Z M49.4798,11.7919 C49.4798,14.0462 47.6879,15.7225 45.5492,15.7225 C43.3526,15.7225 41.6185,14.0462 41.6185,11.7919 C41.6185,9.47977 43.4104,7.86127 45.5492,7.86127 C47.6879,7.80347 49.4798,9.47977 49.4798,11.7919 Z"
          id="Shape"
          fill="#222222"
        ></path>
        <path
          d="M56.1272,11.7919 C56.1272,10.4046 55.2023,9.36416 54.0462,9.36416 C52.8324,9.36416 51.8497,10.4046 51.8497,11.7919 C51.8497,13.1792 52.8324,14.1618 54.0462,14.1618 C55.2023,14.1618 56.1272,13.1792 56.1272,11.7919 Z M57.6879,8.09248 L57.6879,15.2023 C57.6879,18.0925 55.9538,19.3064 53.9306,19.3064 C52.0231,19.3064 50.867,18.0347 50.4046,16.9942 L51.9075,16.3584 C52.1965,16.9942 52.8324,17.7457 53.8728,17.7457 C55.1445,17.7457 55.9538,16.9364 55.9538,15.4335 L55.9538,14.8555 L55.896,14.8555 C55.4913,15.3179 54.7399,15.7803 53.815,15.7803 C51.8497,15.7803 50.0578,14.0462 50.0578,11.8497 C50.0578,9.59537 51.8497,7.86127 53.815,7.86127 C54.7399,7.86127 55.4913,8.2659 55.896,8.72832 L55.9538,8.72832 L55.9538,8.09248 L57.6879,8.09248 Z"
          id="Shape"
          fill="#222222"
        ></path>
        <polygon
          id="Path"
          fill="#222222"
          points="58.6705 15.4913 60.4046 15.4913 60.4046 3.93064 58.6705 3.93064"
        ></polygon>
        <path
          d="M62.8324,11.6763 L66.3584,10.2312 C66.185,9.71098 65.6069,9.42196 64.9133,9.42196 C63.9884,9.36416 62.7746,10.1156 62.8324,11.6763 Z M66.9942,13.0636 L68.3237,13.9884 C67.8613,14.6243 66.8208,15.7225 65.0289,15.7225 C62.7746,15.7225 61.0983,13.9884 61.0983,11.7919 C61.0983,9.42196 62.7746,7.86127 64.7977,7.86127 C66.8208,7.86127 67.8613,9.47977 68.1503,10.3468 L68.3237,10.8092 L63.0636,13.0058 C63.4682,13.815 64.104,14.2197 64.9711,14.2197 C65.896,14.1618 66.5318,13.7572 66.9942,13.0636 Z"
          id="Shape"
          fill="#222222"
        ></path>
        <path
          d="M26.6474,10.7514 L26.6474,9.07514 L32.3121,9.07514 C32.3699,9.36416 32.3699,9.71098 32.3699,10.0578 C32.3699,11.3295 32.0231,12.8902 30.9248,13.9884 C29.8266,15.0867 28.4971,15.7225 26.6474,15.7225 C23.2948,15.7225 20.4046,12.948 20.4046,9.59538 C20.4046,6.24277 23.237,3.46821 26.6474,3.46821 C28.4971,3.46821 29.8266,4.21965 30.867,5.14451 L29.711,6.30058 C29.0173,5.60694 28.0347,5.08671 26.7052,5.08671 C24.2196,5.08671 22.3121,7.05202 22.3121,9.53757 C22.3121,12.0231 24.2196,13.9884 26.7052,13.9884 C28.3237,13.9884 29.1907,13.3526 29.8266,12.7746 C30.289,12.3121 30.6358,11.6185 30.7514,10.6936 L26.6474,10.7514 Z"
          id="Path"
          fill="#222222"
        ></path>
        <path
          d="M72.4856,15.4913 L72.4856,4.79769 L75.7226,4.79769 C77.3411,4.79769 78.6706,5.31792 79.6532,6.30058 C80.6359,7.28324 81.1561,8.55492 81.1561,10.1734 C81.1561,11.7919 80.6359,13.0636 79.6532,14.0462 C78.6706,15.0289 77.3411,15.4913 75.7226,15.4913 L72.4856,15.4913 Z M73.8729,6.12717 L73.8729,14.1619 L75.7804,14.1619 C76.9943,14.1619 77.9769,13.815 78.7284,13.1214 C79.422,12.4277 79.8266,11.4451 79.8266,10.1734 C79.8266,8.90174 79.4798,7.91908 78.7284,7.22544 C78.0347,6.53179 77.0521,6.12717 75.7804,6.12717 L73.8729,6.12717 Z"
          id="Shape"
          fill="#222222"
        ></path>
        <path
          d="M81.9075,11.8497 C81.9075,10.7514 82.2544,9.82659 82.948,9.07514 C83.6416,8.3237 84.5665,7.97688 85.6648,7.97688 C86.763,7.97688 87.6301,8.3237 88.3237,9.07514 C89.0174,9.82659 89.422,10.7514 89.422,11.8497 C89.422,13.0058 89.0752,13.9306 88.3237,14.6243 C87.6301,15.3757 86.7052,15.7225 85.6648,15.7225 C84.5665,15.7225 83.6994,15.3757 82.948,14.6243 C82.2544,13.8728 81.9075,12.948 81.9075,11.8497 Z M83.2948,11.8497 C83.2948,12.659 83.526,13.2948 83.9885,13.7572 C84.4509,14.2197 85.0289,14.5087 85.6648,14.5087 C86.3584,14.5087 86.8786,14.2775 87.3411,13.7572 C87.8035,13.2948 88.0347,12.659 88.0347,11.8497 C88.0347,11.0983 87.8035,10.4624 87.3411,9.9422 C86.8786,9.42197 86.3006,9.19075 85.6648,9.19075 C84.9711,9.19075 84.4509,9.42197 83.9885,9.9422 C83.526,10.4046 83.2948,11.0405 83.2948,11.8497 Z"
          id="Shape"
          fill="#222222"
        ></path>
        <path
          d="M94.0463,15.7225 C92.948,15.7225 92.081,15.3757 91.3295,14.6243 C90.6359,13.8728 90.2891,12.948 90.2891,11.8497 C90.2891,10.7514 90.6359,9.82659 91.3295,9.07514 C92.0232,8.3237 92.948,7.97688 94.0463,7.97688 C94.7977,7.97688 95.4336,8.15029 96.0116,8.55491 C96.5897,8.90173 96.9943,9.42197 97.2833,10.1156 L96.0116,10.6358 C95.607,9.71098 94.9712,9.24856 93.9885,9.24856 C93.3527,9.24856 92.8324,9.47977 92.37,10 C91.9076,10.5202 91.6764,11.1561 91.6764,11.8497 C91.6764,12.6012 91.9076,13.237 92.37,13.6994 C92.8324,14.2197 93.3527,14.4509 93.9885,14.4509 C94.9712,14.4509 95.7226,13.9884 96.1272,13.0636 L97.3411,13.5838 C97.0521,14.2197 96.6475,14.7399 96.0694,15.1445 C95.4336,15.5491 94.7977,15.7225 94.0463,15.7225 Z"
          id="Path"
          fill="#222222"
        ></path>
        <path
          d="M104.046,13.4682 C104.046,14.104 103.757,14.6243 103.237,15.0867 C102.659,15.5491 101.965,15.7225 101.156,15.7225 C100.405,15.7225 99.7688,15.5491 99.1908,15.1445 C98.6128,14.7399 98.2659,14.2775 97.9769,13.6416 L99.1908,13.1214 C99.3642,13.5838 99.6532,13.8728 100,14.1619 C100.347,14.3931 100.694,14.5087 101.098,14.5087 C101.561,14.5087 101.908,14.3931 102.197,14.2197 C102.486,14.0462 102.659,13.815 102.659,13.526 C102.659,13.0636 102.312,12.7168 101.561,12.4855 L100.289,12.1965 C98.844,11.8497 98.1503,11.1561 98.1503,10.1156 C98.1503,9.42197 98.4394,8.90173 98.9596,8.49711 C99.4798,8.09249 100.173,7.91908 101.041,7.91908 C101.676,7.91908 102.254,8.09249 102.775,8.3815 C103.295,8.67052 103.642,9.07514 103.873,9.59538 L102.659,10.1156 C102.543,9.82659 102.312,9.59538 101.965,9.36416 C101.676,9.19075 101.33,9.07514 100.925,9.07514 C100.578,9.07514 100.231,9.19075 99.9422,9.36416 C99.6532,9.53757 99.5376,9.76879 99.5376,10 C99.5376,10.4046 99.9422,10.6936 100.694,10.8671 L101.792,11.1561 C103.295,11.6185 104.046,12.3699 104.046,13.4682 Z"
          id="Path"
          fill="#222222"
        ></path>
        <path
          d="M13.1792,20 L1.27168,20 C0.578035,20 0,19.422 0,18.7283 L0,1.27168 C0,0.578035 0.578035,0 1.27168,0 L8.84393,0 L14.3931,5.49133 L14.3931,18.7283 C14.3931,19.422 13.8728,20 13.1792,20 Z"
          id="Path"
          fill="#222222"
        ></path>
        <polygon
          id="Path"
          fill="#F1F1F1"
          points="3.4104 10.9827 10.7514 10.9827 10.7514 10.0578 3.4104 10.0578"
        ></polygon>
        <polygon
          id="Path"
          fill="#F1F1F1"
          points="3.4104 12.7746 10.7514 12.7746 10.7514 11.8497 3.4104 11.8497"
        ></polygon>
        <polygon
          id="Path"
          fill="#F1F1F1"
          points="3.4104 14.5665 10.7514 14.5665 10.7514 13.6416 3.4104 13.6416"
        ></polygon>
        <polygon
          id="Path"
          fill="#F1F1F1"
          points="3.4104 16.3584 8.95953 16.3584 8.95953 15.4335 3.4104 15.4335"
        ></polygon>
        <rect
          id="Rectangle"
          fill="url(#pattern-1)"
          fillRule="nonzero"
          transform="translate(11.895924, 7.685966) scale(-1, 1) rotate(-180.000000) translate(-11.895924, -7.685966) "
          x="9.37384"
          y="5.24903256"
          width="5.04416826"
          height="4.87386744"
        ></rect>
        <path
          d="M10.1156,5.49133 L14.3931,5.49133 L8.84393,0 L8.84393,4.21965 C8.84393,4.9133 9.42197,5.49133 10.1156,5.49133 Z"
          id="Path"
          fill="#787878"
        ></path>
      </g>
    </g>
  </svg>
);

export default React.memo(GoogleDocLogo);
