import React, { useMemo } from 'react';
import { Modal } from '@dropbox/dig-components/modal';
import { Text, Title } from '@dropbox/dig-components/typography';
import { FormattedMessage } from 'react-intl';
import { FileTemplateLine, SignatureLine } from '@dropbox/dig-icons/assets';
import { UIIcon } from '@dropbox/dig-icons';
import styles from './billboard.module.scss';
import { PauseCancelModalContext } from '../types';

type BillboardProps = {
  freePlanName: PauseCancelModalContext['freePlanName'];
  planName: PauseCancelModalContext['planName'];
};

const BillboardContent = ({ planName, freePlanName }: BillboardProps) => {
  const getHeader = useMemo(() => {
    return (
      <FormattedMessage
        id=""
        description="header, cancel flow header confirming cancellation action"
        defaultMessage="Are you sure you want to cancel your {planName} plan?"
        values={{
          planName,
        }}
      />
    );
  }, [planName]);

  const getDescription = useMemo(() => {
    return (
      <FormattedMessage
        id=""
        description="header, cancel flow header for section describing features the user will lose by downgrading to Free plan"
        defaultMessage="Downgrading to the {freePlanName} plan, you will lose:"
        values={{
          freePlanName,
        }}
      />
    );
  }, [freePlanName]);

  return (
    <div className={styles.billboard}>
      <Modal.Header hasBottomSpacing="title-standard">
        <Title id="cancellation-flow-modal-title" className={styles.header}>
          {getHeader}
        </Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <Text>{getDescription}</Text>
        <ul className={styles.benefitList}>
          <li className={styles.benefit}>
            <UIIcon
              src={FileTemplateLine}
              role="presentation"
              className={styles.benefitIcon}
            />
            <Text>
              <FormattedMessage
                id=""
                description="list item, inform user of losing ability to use or create templates"
                defaultMessage="Templates that you've already created and the ability to create new ones."
              />
            </Text>
          </li>
          <li className={styles.benefit}>
            <UIIcon
              src={SignatureLine}
              role="presentation"
              className={styles.benefitIcon}
            />
            <Text>
              <FormattedMessage
                id=""
                description="list item, inform user of losing access to paid features"
                defaultMessage="All other paid features and will be limited to just 3 free signature requests per month"
              />
            </Text>
          </li>
        </ul>
      </Modal.Body>
    </div>
  );
};

export default BillboardContent;
