import React from 'react';
import { defineMessages } from 'react-intl';
import * as types from 'hellospa/page/prep-and-send/data/types';
import { intl } from 'hellospa/common/hs-intl-provider';
import { SiteCodeType } from 'js/sign-components/common/brand';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import SubmenuWalkthrough from './submenu-walkthrough';

const SignaturePaperSpot = React.lazy(async () => ({
  default: (
    await import('@dropbox/dig-illustrations/dist/cjs/spot/signature-paper')
  ).SignaturePaperSpot,
}));

const EsignatureTemplateSpot = React.lazy(async () => ({
  default: (
    await import('@dropbox/dig-illustrations/dist/cjs/spot/esignature-template')
  ).EsignatureTemplateSpot,
}));

const PuzzleConnectionSpot = React.lazy(async () => ({
  default: (
    await import('@dropbox/dig-illustrations/dist/cjs/spot/puzzle-connection')
  ).PuzzleConnectionSpot,
}));

const messages = defineMessages({
  docsTitle: {
    id: '',
    defaultMessage: "It's easy to get documents signed",
    description: 'Submenu title to teach users how to use Documents page',
  },
  docsContent: {
    id: '',
    defaultMessage:
      'Just select documents, add signers, place fields, and send!',
    description: 'Submenu content explaining users how to use Documents page',
  },
  docsLink: {
    id: '',
    defaultMessage: 'Sign documents',
    description: 'Link button sending users to Documents page',
  },
  templateTitle: {
    id: '',
    defaultMessage: 'Save time with templates',
    description: 'Submenu title to teach users how to use Templates',
  },
  templateContent: {
    id: '',
    defaultMessage:
      'Format a document once, then save as template so you can get it signed again in the future.',
    description:
      'Submenu content explaining users how to upgrade and use Templates',
  },
  templateLink: {
    id: '',
    defaultMessage: 'Create a template',
    description: 'Link button sending users to Templates page',
  },
  upgradeLink: {
    id: '',
    defaultMessage: 'Upgrade to create a template',
    description: 'Link button sending users to Upgrade page',
  },
  integrationsTitle: {
    id: '',
    defaultMessage: 'Connect tools you already use',
    description: 'Submenu title to teach users how to use Integrations',
  },
  integrationsContent: {
    id: '',
    defaultMessage:
      'Get documents signed directly in Gmail, Microsoft Word, Salesforce, and more.',
    description:
      'Submenu content explaining users how to upgrade and use Integrations',
  },
  integrationsLink: {
    id: '',
    defaultMessage: 'Connect tools',
    description: 'Link button sending users to Integrations page',
  },
});

export type MenuWalkthroughProps = {
  siteCode: SiteCodeType;
  isFree?: boolean;
};

function MenuWalkthrough({ siteCode, isFree = false }: MenuWalkthroughProps) {
  const site = HfReactHelper.HfSites.getSite(siteCode);
  const templateLink = HfReactHelper.urlHelper(
    `prep-and-send/new/${types.RequestTypes.Template}`,
    siteCode,
  );
  const docsImage = (
    <React.Suspense fallback={<></>}>
      <SignaturePaperSpot />
    </React.Suspense>
  );

  const templateImage = (
    <React.Suspense fallback={<></>}>
      <EsignatureTemplateSpot />
    </React.Suspense>
  );

  const integrationsImage = (
    <React.Suspense fallback={<></>}>
      <PuzzleConnectionSpot />
    </React.Suspense>
  );

  return (
    <>
      <SubmenuWalkthrough
        data-testid="menu-walkthrough-signature-submenu"
        title={intl.formatMessage(messages.docsTitle)}
        image={docsImage}
        body={intl.formatMessage(messages.docsContent)}
        buttons={[
          {
            href: HfReactHelper.urlHelper('prep-and-send', site),
            children: intl.formatMessage(messages.docsLink),
          },
        ]}
      />
      <SubmenuWalkthrough
        data-testid="menu-walkthrough-templates-submenu"
        title={intl.formatMessage(messages.templateTitle)}
        image={templateImage}
        body={intl.formatMessage(messages.templateContent)}
        buttons={[
          {
            href: isFree
              ? HfReactHelper.urlHelper('info/pricing', site)
              : templateLink,
            children: isFree
              ? intl.formatMessage(messages.upgradeLink)
              : intl.formatMessage(messages.templateLink),
          },
        ]}
      />
      <SubmenuWalkthrough
        data-testid="menu-walkthrough-integrations-submenu"
        title={intl.formatMessage(messages.integrationsTitle)}
        image={integrationsImage}
        body={intl.formatMessage(messages.integrationsContent)}
        buttons={[
          {
            href: HfReactHelper.urlHelper(
              'home/myAccount?current_tab=integrations',
              site,
            ),
            children: intl.formatMessage(messages.integrationsLink),
          },
        ]}
      />
    </>
  );
}

export default MenuWalkthrough;
