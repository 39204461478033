/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len,
  no-prototype-builtins,
  react/no-find-dom-node,
  react/no-string-refs
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import DropDownMenu from 'common/components/drop-down-menu';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import classNames from 'classnames';
import createReactClass from 'create-react-class';

const TopBarMenuLink = createReactClass({
  propTypes: {
    linkText: PropTypes.string.isRequired,
    linkUrl: PropTypes.string,
    linkOptions: PropTypes.object,
    linkOverrideColors: PropTypes.object,
    pageToHighLight: PropTypes.string,
    useWhite: PropTypes.bool,
    'data-qa-ref': PropTypes.string,
  },

  componentDidMount() {
    if (this.state.menuLeft == null) {
      // Only set once
      this.getMenuLeft();
    }
  },

  // dropdown menu-related functions
  getInitialState() {
    return {
      menuOpen: false,
      menuLeft: null,
    };
  },

  getMenuLeft() {
    const node = ReactDOM.findDOMNode(this);
    const parent = node.offsetParent;
    if (node && parent) {
      const rect = node.getBoundingClientRect();
      const parentRect = parent.getBoundingClientRect();
      this.setState({
        menuLeft: rect.left - parentRect.left,
      });
    }
  },

  openMenu() {
    this.setState({ menuOpen: true });
  },

  setStateMousedOverDropDownMenuTrue() {
    this.setState({ mousedOverDropDownMenu: true });
  },

  setStateMousedOverDropDownMenuFalse() {
    this.setState({ mousedOverDropDownMenu: false });
  },

  closeMenu() {
    this.setState({ menuOpen: false });
    window.setTimeout(() => {
      if (this.state.mousedOverDropDownMenu !== true) {
        this.setState({ menuOpen: false });
      }
    }, 200);
  },

  render() {
    const cx = classNames;
    let rowClasses = {
      'm-link-list--link--container': true,
      'm-link-list--link-break-1': false,
    };
    let linkClasses = {
      'm-link-list--link': true,
    };
    const linkDefaultColors = {
      'c-white': !!this.props.useWhite,
      'hc-warm-chinchilla': !!this.props.useWhite,
      'hc-cerulean': !this.props.useWhite,
      'c-ashen': !this.props.useWhite,
    };

    const linkColors = this.props.linkOverrideColors
      ? this.props.linkOverrideColors
      : linkDefaultColors;

    if (
      this.props.linkOptions &&
      this.props.linkOptions.hasOwnProperty('class')
    ) {
      const newProperty = {};
      const newPropertyKey = this.props.linkOptions.class;
      newProperty[newPropertyKey] = true;
      linkClasses = HfReactHelper.extend(newProperty, linkClasses);
    }

    if (this.props.linkUrl == this.props.pageToHighLight) {
      linkClasses = HfReactHelper.extend(
        {
          'm-link-list--currentPage': true,
        },
        linkClasses,
      );
    }

    if (
      this.props.linkOptions &&
      this.props.linkOptions.hasOwnProperty('colors')
    ) {
      const propertyColorOverride = {};
      const propertyColorOverrideKey = this.props.linkOptions.colors;
      propertyColorOverride[propertyColorOverrideKey] = true;
      linkClasses = HfReactHelper.extend(propertyColorOverride, linkClasses);
    } else {
      linkClasses = HfReactHelper.extend(linkColors, linkClasses);
    }

    if (
      this.props.linkOptions &&
      this.props.linkOptions.hasOwnProperty('container_class')
    ) {
      const containerClasses =
        this.props.linkOptions.container_class.split(' ');
      for (let i = 0; i < containerClasses.length; i++) {
        const clazz = containerClasses[i];
        rowClasses[clazz] = true;
      }
    }

    // Stringify classes
    linkClasses = cx(linkClasses);
    rowClasses = cx(rowClasses);

    let dropDownMenu;
    if (
      this.props.linkOptions.hasOwnProperty('sub_menu_items') &&
      this.state.menuOpen
    ) {
      let style;
      if (
        this.props.linkOptions.hasOwnProperty('sub_menu_lock_to_parent') &&
        this.props.linkOptions.sub_menu_lock_to_parent
      ) {
        style = {};
        style.left = this.state.menuLeft ? this.state.menuLeft : 0;
      } else {
        style = { right: 40 }; // Default, right side menu
      }

      dropDownMenu = (
        <div
          onMouseEnter={this.setStateMousedOverDropDownMenuTrue}
          onMouseLeave={this.setStateMousedOverDropDownMenuFalse}
        >
          <DropDownMenu
            id={this.props.linkOptions.sub_menu_id}
            ref="dropDownMenu"
            style={style}
            dropDownMenuLinkData={this.props.linkOptions.sub_menu_items}
            className={this.props.linkOptions.sub_menu_class}
          />
        </div>
      );
    }

    let dropDownArrow;
    if (
      this.props.linkOptions.hasOwnProperty('show_menu_drop_down_arrow') &&
      this.props.linkOptions.show_menu_drop_down_arrow
    ) {
      dropDownArrow = (
        <span className="m-drop-down-menu--drop-down-arrow">&#x25BE;</span>
      );
    }

    return (
      <div
        className={rowClasses}
        onMouseEnter={this.openMenu}
        onMouseLeave={this.closeMenu}
        data-qa-ref={this.props['data-qa-ref']}
      >
        <a
          className={linkClasses}
          href={this.props.linkUrl}
          target={this.props.linkOptions.target}
          tabIndex="-1"
        >
          {this.props.linkText}
          {dropDownArrow}
        </a>
        {dropDownMenu}
      </div>
    );
  },
});

module.exports = TopBarMenuLink;
