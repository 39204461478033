/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  react/no-find-dom-node,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import HSIntlProvider from 'hellospa/common/hs-intl-provider';
import LogInForm from './login-form';

class LogInModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      emailAddress: this.props.emailAddress,
      isProcessing: false,
    };
  }

  static propTypes = {
    header: PropTypes.string.isRequired,
    googleSignInClientId: PropTypes.string,
    tabOffset: PropTypes.number,
    emailAddress: PropTypes.string,
    loginUrl: PropTypes.string,
    recoverPasswordForm: PropTypes.object.isRequired, // Serialized sfForm
    loginForm: PropTypes.object.isRequired, // Serialized sfForm
    redirectUrl: PropTypes.string,
    hideRememberMe: PropTypes.bool,
    hideGoogleOpenId: PropTypes.bool,
    arkoseEnabled: PropTypes.bool,
    arkosePublicKey: PropTypes.string,
  };

  componentDidMount() {
    if (!this.props.isInline && this.isFoundationFriendly()) {
      $(document).foundation();
      $(ReactDOM.findDOMNode(this))
        .closest('.reveal-modal')
        .foundation('reveal', 'open');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isInline && this.isFoundationFriendly()) {
      $(ReactDOM.findDOMNode(this))
        .closest('.reveal-modal')
        .foundation('reveal', 'open');
    }
  }

  // Note: version 1.4.4 of jQuery is incompatible with Foundation.
  isFoundationFriendly() {
    return window.$ && $.fn.jquery !== '1.4.4';
  }

  render() {
    const props = this.props;
    const loginFormProps = {
      header: props.header,
      buttonText: props.buttonText,
      isInline: props.isInline,
      inlineSideText: props.inlineSideText,
      googleSignInClientId: props.googleSignInClientId,
      redirectUrl: props.redirectUrl,
      isFromApiPricing: props.isFromApiPricing,
      planId: props.planId,
      tabOffset: props.tabOffset,
      emailAddress: props.emailAddress,
      recoverPasswordForm: props.recoverPasswordForm,
      action: props.loginUrl,
      fields: props.loginForm.fields,
      values: props.loginForm.values,
      csrfToken: props.loginForm.values._csrf_token,
      namespace: props.loginForm.namespace,
      hideRememberMe: props.hideRememberMe,
      hideGoogleOpenId: props.hideGoogleOpenId,
      arkoseEnabled: props.arkoseEnabled,
      arkosePublicKey: props.arkosePublicKey,
    };

    return (
      <HSIntlProvider>
        <LogInForm {...loginFormProps} />
      </HSIntlProvider>
    );
  }
}

module.exports = LogInModal;
