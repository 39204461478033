import React from 'react';
import { useSelector } from 'react-redux';
import * as selectors from 'hellospa/page/prep-and-send/data/selectors';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { GlobalHeader } from '@dropbox/dig-components/global_header';
import { UseMenuTriggerProps } from '@dropbox/dig-components/hooks';
import { Text } from '@dropbox/dig-components/typography';
import { Menu } from '@dropbox/dig-components/menu';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import type { PropsWithSiteCode } from 'js/sign-components/common/brand';
import { GlobalNavTourItems } from 'hellospa/components/global-nav/global-nav-onboarding-tour';
import MenuWalkthrough from './menu-walkthrough';
import {
  CookiesAndCcpaFeatureGate,
  useCookiesAndCcpaIsEnabled,
} from 'hello-react/components/cookies-and-ccpa-feature';

export const HelpMenuContext = React.createContext<null | JSX.Element>(null);

export const HelpMenuProvider = HelpMenuContext.Provider;

const messages = defineMessages({
  help: {
    id: '',
    description: 'Text for a button which opens a help menu',
    defaultMessage: 'Help',
  },
});

export type HelpMenuProps = PropsWithSiteCode<{
  isFree?: boolean;
  preset?: 'basic' | 'basicWithWalkthrough';
}>;

export const FAQs = () => (
  <Menu.LinkItem
    href={HfReactHelper.getFaqUrl()}
    data-qa-ref="global-header-faqs-link"
    target="_blank"
  >
    <FormattedMessage
      id=""
      description="Text for a button which redirects the user to the FAQs (frequently asked questions) page when pressed"
      defaultMessage="FAQs"
    />
  </Menu.LinkItem>
);

export const ContactUs = ({ siteCode }: PropsWithSiteCode<{}>) => {
  const site = HfReactHelper.HfSites.getSite(siteCode);
  const isDeepIntegration = useSelector(selectors.isDeepIntegration);
  const contactUsHref = isDeepIntegration
    ? HfReactHelper.getFaqUrl('requests/new')
    : HfReactHelper.urlHelper('home/help', site);

  return (
    <Menu.LinkItem
      href={contactUsHref}
      data-qa-ref="global-header-contact-us-link"
      target="_blank"
    >
      <FormattedMessage
        id=""
        description="Text for a button which redirects the user to the Contact Us page when pressed"
        defaultMessage="Contact us"
      />
    </Menu.LinkItem>
  );
};

export const PrivacyAndLegalSubMenu = () => (
  <div data-testid="global-header-privacy-and-legal-link">
    <Menu.Segment>
      <Menu.LinkItem
        data-testid="global-header-security-link"
        href={HfReactHelper.getWebflowUrl('security')}
        target="_blank"
      >
        <FormattedMessage
          id=""
          description="Link which redirects user to the Security page"
          defaultMessage="Security"
        />
      </Menu.LinkItem>
      <Menu.LinkItem
        data-testid="global-header-legal-link"
        href={HfReactHelper.getWebflowUrl('legal')}
        target="_blank"
      >
        <FormattedMessage
          id=""
          description="Link which redirects user to the Legal page"
          defaultMessage="Legal"
        />
      </Menu.LinkItem>
      <Menu.LinkItem
        data-testid="global-header-privacy-link"
        href={HfReactHelper.getWebflowUrl('privacy')}
        target="_blank"
      >
        <FormattedMessage
          id=""
          description="Link which redirects user to the Privacy page"
          defaultMessage="Privacy"
        />
      </Menu.LinkItem>
      <Menu.LinkItem
        data-testid="global-header-terms-link"
        href={HfReactHelper.getWebflowUrl('terms')}
        target="_blank"
      >
        <FormattedMessage
          id=""
          description="Link which redirects user to the Terms and Conditions page"
          defaultMessage="Terms"
        />
      </Menu.LinkItem>
    </Menu.Segment>
  </div>
);

export const PrivacyAndLegal = () => (
  <Menu.Submenu
    withTriggerContent={
      <span data-qa-ref="global-header-privacy-and-legal">
        <FormattedMessage
          id=""
          description="Text for a button which redirects the user to the Terms page when pressed"
          defaultMessage="Privacy and legal"
        />
      </span>
    }
  >
    <PrivacyAndLegalSubMenu />
  </Menu.Submenu>
);

export const CookiesAndCCPA = () => {
  const isEnabled = useCookiesAndCcpaIsEnabled();
  if (!isEnabled) {
    return null;
  }
  return (
    <Menu.ActionItem
      data-testid="global-header-cookies-link"
      onClick={() => {
        window.dropbox?.privacyConsent?.openOptionsDialog?.();
      }}
    >
      <FormattedMessage
        id=""
        description="Text for a button which displays cookie preferences when pressed. CCPA stands for California Consumer Privacy Act"
        defaultMessage="Cookies & CCPA preferences"
      />
    </Menu.ActionItem>
  );
};

export const MenuItem = {
  FAQs,
  ContactUs,
  PrivacyAndLegal,
  CookiesAndCCPA,
  PrivacyAndLegalSubMenu,
  MenuWalkthrough,
};

export function BasicMenuWithWalkthrough({ siteCode, isFree }: HelpMenuProps) {
  return (
    <>
      <Menu.Segment>
        <Menu.RowContent>
          <Text
            variant="label"
            size="large"
            role="heading"
            aria-level={2}
            isBold
            id="did-you-know-heading"
          >
            <FormattedMessage
              id=""
              defaultMessage="Did you know?"
              description="Heading for a section which educates the user"
            />
          </Text>
        </Menu.RowContent>
      </Menu.Segment>
      <Menu.Segment aria-labelledby="did-you-know-heading">
        <MenuItem.MenuWalkthrough siteCode={siteCode} isFree={isFree} />
      </Menu.Segment>
      <Menu.Segment>
        <MenuItem.FAQs />
        <MenuItem.ContactUs siteCode={siteCode} />
        <MenuItem.PrivacyAndLegal />
        <CookiesAndCcpaFeatureGate>
          <MenuItem.CookiesAndCCPA />
        </CookiesAndCcpaFeatureGate>
      </Menu.Segment>
    </>
  );
}

export function BasicMenu({ siteCode }: HelpMenuProps) {
  return (
    <Menu.Segment>
      <MenuItem.FAQs />
      <MenuItem.ContactUs siteCode={siteCode} />
      <MenuItem.PrivacyAndLegal />
      <CookiesAndCcpaFeatureGate>
        <MenuItem.CookiesAndCCPA />
      </CookiesAndCcpaFeatureGate>
    </Menu.Segment>
  );
}

/**
 * Renders the Help menu for the global header. You can
 * customize the menu items by wrapping your layout in a
 * <HelpMenuProvider with a custom value which represents
 * the Help menu.
 *
 * @example
 *
 * ```
 * import { Menu } from '@dropbox/dig-components/menu';
 * import { HelpMenuProvider, MenuItem } from 'hellospa/components/global-header/help-menu';
 *
 * // ...
 *
 * <HelpMenuProvider
 *   // Custom help menu
 *   value={
 *     <Menu.Segment>
 *       <MenuItem.FAQs />
 *       <MenuItem.ContactUs siteCode={siteCode} />
 *       <Menu.LinkItem>
 *         Custom menu item
 *       </Menu.LinkItem>
 *     </Menu.Segment>
 *   }
 * >
 *   <Layout ... />
 * </HelpMenuProvider>
 * ```
 */

export default function HelpMenu({ preset, ...props }: HelpMenuProps) {
  const intl = useIntl();

  const menuPreset = React.useMemo(() => {
    switch (preset) {
      case 'basic':
        return <BasicMenu {...props} />;
      case 'basicWithWalkthrough':
      default:
        return <BasicMenuWithWalkthrough {...props} />;
    }
  }, [preset, props]);

  return (
    <GlobalHeader.Help
      // Need to use `as unknown` hack to get DIG HelpMenu to accept
      // HTML attributes that have not been explictly allowed.
      triggerProps={
        {
          title: intl.formatMessage(messages.help),
          'aria-label': intl.formatMessage(messages.help),
          'data-qa-ref': 'global-header-help-btn',
          'data-tour-item': GlobalNavTourItems.helpButton,
        } as unknown as UseMenuTriggerProps
      }
    >
      <HelpMenuContext.Consumer>
        {(customMenu) => (
          <div data-qa-ref="global-header-help-menu">
            {/* Use preset if specified, else use custom menu or basic menu */}
            {/* with walkthrough as fallback. */}
            {preset
              ? menuPreset
              : customMenu ?? <BasicMenuWithWalkthrough {...props} />}
          </div>
        )}
      </HelpMenuContext.Consumer>
    </GlobalHeader.Help>
  );
}
