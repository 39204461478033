import { Banner } from '@dropbox/dig-components/banner';
import { FormattedMessage } from 'react-intl';
import { Button } from '@dropbox/dig-components/buttons';
import { PictogramIcon } from '@dropbox/dig-icons';
import { WarningLine } from '@dropbox/dig-icons/assets';
import React from 'react';

export function APIAppBanner() {
  return (
    <Banner
      variant="rich"
      type="neutral"
      withLeftPictogram={<PictogramIcon src={WarningLine} />}
    >
      <Banner.Message>
        <span>
          <FormattedMessage
            id=""
            description="API App limit"
            defaultMessage="You’ve reached the limit for self-published API apps. To request approval for more, please contact support."
          />
        </span>
      </Banner.Message>
      <Banner.Actions>
        <Button variant="outline" href="mailto:apisupport@hellosign.com">
          <FormattedMessage
            id=""
            description="Contact Support"
            defaultMessage="Contact Support"
          />
        </Button>
      </Banner.Actions>
    </Banner>
  );
}
