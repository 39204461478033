/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-extraneous-dependencies
*/
import './collect-info-modal.scss';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import FixedModal from 'common/components/fixed-modal';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import CollectInfoForm from './collect-info-form';

export default class CollectInfoModal extends Component {
  render() {
    const { fields, values, namespace } = this.props.collectInfoForm;
    const form = (
      <CollectInfoForm
        fields={fields}
        values={values}
        action={HfReactHelper.urlHelper(
          'home/collectInfo',
          HfReactHelper.HfSites.SITE_CODE_HS,
        )}
        onSuccess={this.props.onSuccess}
        namespace={namespace}
        isEnterprise={this.props.isEnterprise}
      ></CollectInfoForm>
    );

    return (
      <FixedModal hideOnOverlayClick={true}>
        <div className="m-collect-info-modal">
          <div className="m-collect-info-modal--header">
            <h1>
              <FormattedMessage
                id=""
                description="header text in modal in sign up flow, asking user to fill the form to create an account"
                defaultMessage="Create your eSignature profile"
              />
            </h1>
            <div className="subheader">
              <small>
                <FormattedMessage
                  id=""
                  description="header text in modal in sign up flow, asking user to fill out the form to help us provide them a better experience in the future"
                  defaultMessage="This information helps us personalize your experience."
                />
              </small>
            </div>
          </div>
          {form}
        </div>
      </FixedModal>
    );
  }
}
