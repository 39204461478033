/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { GoogleLogo } from 'hellospa/icons/google-logo';
import hsFetch from 'js/sign-components/common/hs-fetch';
import {
  NotificationBanner,
  NotificationBannerType,
} from 'hellospa/components/notification-banner';
import { intl } from 'hellospa/common/hs-intl-provider';

const messages = defineMessages({
  googleSignInError: {
    id: '',
    defaultMessage:
      'This page has expired due to inactivity. Please reload the page and try again.',
    description: 'Show message to user if there is error on google signin',
  },
});

class GoogleLoginOrSignup extends React.Component {
  /**
   * React prop type definitions.
   *
   * @static
   */
  static propTypes = {
    redirectUrl: PropTypes.string,
    isSignup: PropTypes.bool,
    getIsOptedIn: PropTypes.func || undefined,
    csrfToken: PropTypes.string,
    formType: PropTypes.string,
    rememberMe: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };

    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleCallbackMessage = this.handleCallbackMessage.bind(this);
  }

  componentDidMount() {
    window.addEventListener('message', this.handleCallbackMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleCallbackMessage);
  }

  handleCallbackMessage(event) {
    if (!event.data?.isGoogleOpenId) {
      return;
    }

    const formData = new FormData();

    formData.append('id_token', event.data.idToken);
    formData.append('form_type', this.props.formType);
    formData.append('redirect_url', this.props.redirectUrl);
    formData.append('opt_in', this.props.getIsOptedIn?.() ?? false);
    formData.append('csrf_token', this.props.csrfToken);
    formData.append('should_remember_me', this.props.rememberMe);

    hsFetch('/account/processGoogleSignIn', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          this.setState({ hasError: true });
          return;
        }

        response.json().then((data) => {
          window.location.replace(data.redirect_url);
        });
      })
      .catch(() => {
        this.setState({ hasError: true });
      });
  }

  /**
   * Called when the Google login or signup button is pressed.
   *
   * @private
   */
  handleButtonPress() {
    let url = '/account/openid/connect/google';
    if (
      typeof this.props.redirectUrl === 'string' &&
      this.props.redirectUrl.length > 0
    ) {
      url = `${url}?on_login_redirect_url=${window.btoa(this.props.redirectUrl)}`;
    }
    window.hsGoogleOpenIdWindow = window.open(
      url,
      '_blank',
      'width=640, height=480',
    );
  }

  /**
   * Get attribute for selenium based on page
   *
   * @returns {string}
   * @private
   */
  get qaAttribute() {
    const pathname = window.location.pathname;
    let qaAttr = '';

    if (pathname.indexOf('signUp') >= 0) {
      qaAttr = 'sign-in-with-google-button-sign-up-page';
    } else if (pathname.indexOf('logIn') >= 0) {
      qaAttr = 'sign-in-with-google-button';
    }

    return qaAttr;
  }

  /**
   * Renders the React element into the DOM.
   *
   * @returns {HTMLElement}
   * @override
   */
  render() {
    return (
      <Fragment>
        <Button
          type="button"
          variant="outline"
          size="large"
          data-testid="google-button"
          data-qa-ref={this.qaAttribute}
          withIconLeft={<UIIcon src={GoogleLogo} />}
          onClick={this.handleButtonPress}
        >
          {this.props.isSignup ? (
            <FormattedMessage
              id=""
              description="sign up with Google button"
              defaultMessage="Sign up with Google"
            />
          ) : (
            <FormattedMessage
              id=""
              description="sign in with Google button"
              defaultMessage="Sign in with Google"
            />
          )}
        </Button>

        {this.state.hasError && (
          <NotificationBanner
            message={{
              type: NotificationBannerType.Err,
              translatedMessage: intl.formatMessage(messages.googleSignInError),
            }}
            onClose={() => {
              this.setState({ hasError: false });
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default function GoogleLoginOrSignupWrapper(props) {
  return <GoogleLoginOrSignup {...props} />;
}
