import React from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { GlobalHeader as DbxGlobalHeader } from '@dropbox/dig-components/global_header';
import { IconButton, Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { ListViewLine, UpgradeLine } from '@dropbox/dig-icons/assets';
import { LayerContext } from '@dropbox/dig-components/layer';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { useMatchMedia } from 'js/sign-components/common/hooks';
import Logo from 'signer-app/parts/logo-v2';
import { SiteCodeType } from 'js/sign-components/common/brand';
import { getCSRFToken, setCsrfToken } from 'js/sign-components/common/hs-fetch';

import AccountMenu, { GlobalHeaderAccountMenuTypes } from './account-menu';
import HelpMenu, { HelpMenuProps } from './help-menu';
import DelinquencyBanner from './delinquency-banner';
import BillingNotification from 'hellospa/components/billing-notification/billing-notification';
import ExpiredCCNotification from 'hellospa/components/expired-cc-notification';
import FreeTrialEndingBanner from '../free-trial-ending-banner';

import styles from './global-header.scss';

import { sideBarCollapsedMaxWidth } from '../global-nav';

export { sideBarCollapsedMaxWidth };

const messages = defineMessages({
  searchPlaceholder: {
    id: '',
    description: 'Placeholder text for a search input field',
    defaultMessage: 'Search by keyword',
  },
  toggleNavigation: {
    id: '',
    description:
      'Text for a button which toggles the navigation drawer when pressed',
    defaultMessage: 'Toggle navigation',
  },
});

declare global {
  interface Window {
    primarySignatureGuid?: string;
  }
}

type GlobalHeaderTypes = GlobalHeaderAccountMenuTypes & {
  app: any;
  siteCode: SiteCodeType;
  isFree: boolean;

  // Optional
  userId?: number;
  withSearch?: boolean;
  logoOnly?: boolean;
  onSearch?: () => void;
  searchChips?: React.ReactFragment;
  legacyLayout?: boolean;
  showDelinquencyBanner: boolean;
  nextBillingDate?: number;
  endDate?: number;
  canManageBilling: boolean;
  helpMenuPreset?: HelpMenuProps['preset'];
  prevPlanName?: string;
  hasAlternativeBilling?: boolean;
  hasFrozenSubscription?: boolean;
  freeTrialEndDate?: number;
  showFreeTrialEndBanner?: boolean;
  ccExpiredBeforeRenewal?: boolean;
  ccExpiringBeforeRenewal?: boolean;
  showExpiredCCNotification?: boolean;
  csrfToken?: string;
};

function GlobalHeader({
  app,
  siteCode,
  searchChips,
  isFree,
  userId,
  withSearch = false,
  logoOnly = false,
  onSearch,
  legacyLayout,
  showDelinquencyBanner = false,
  signatureModalProps,
  nextBillingDate,
  endDate,
  canManageBilling,
  prevPlanName,
  hasAlternativeBilling,
  hasFrozenSubscription,
  helpMenuPreset,
  freeTrialEndDate,
  showFreeTrialEndBanner = false,
  ccExpiredBeforeRenewal = false,
  ccExpiringBeforeRenewal = false,
  showExpiredCCNotification = false,
  csrfToken,
  ...props
}: GlobalHeaderTypes) {
  const intl = useIntl();
  const isWordmarkShown = useMatchMedia('(min-width: 600px)');
  const isSidebarCollapsible = useMatchMedia(
    `(max-width: ${sideBarCollapsedMaxWidth})`,
  );
  const isWideScreen = useMatchMedia('(min-width: 1441px)');
  const site = HfReactHelper.HfSites.getSite(siteCode);
  const canShowDelinquencyBanner =
    showDelinquencyBanner &&
    typeof nextBillingDate === 'number' &&
    typeof endDate === 'number';

  const [canShowFreeTrialEndBanner, setCanShowFreeTrialEndBanner] =
    React.useState(showFreeTrialEndBanner);

  // CSRF Token is used in the in-app signature modal.
  React.useEffect(
    () => {
      if (csrfToken) {
        setCsrfToken(csrfToken);
      }
      if (app && signatureModalProps && 'csrfToken' in signatureModalProps) {
        app.csrfToken = signatureModalProps.csrfToken;
      } else {
        app.csrfToken = getCSRFToken();
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [],
  ); // Only run this on mount.

  // Hack until we get rid of PHP templates.
  const onSidebarToggle = React.useCallback(() => {
    document.body.classList.toggle('responsive-layout__sidebar-open');
    window.scrollTo({ top: 0 });
  }, []);

  // Hack until we get rid of PHP templates.
  // @ts-ignore It's OK in this case if not all code paths return a value.
  React.useEffect(() => {
    if (legacyLayout) {
      const screen = document.getElementsByClassName(
        'responsive-layout__screen',
      )[0];

      const handleClick = () => onSidebarToggle();
      screen.addEventListener('click', handleClick);

      return () => {
        screen.removeEventListener('click', handleClick);
      };
    }
  }, [legacyLayout, onSidebarToggle]);

  // TODO
  if (withSearch) {
    // eslint-disable-next-line no-console
    console.error(
      'Warning! The search bar is not yet fully implemented. Do not use it yet!',
    );
  }

  return (
    <>
      {canShowFreeTrialEndBanner && (
        <FreeTrialEndingBanner
          freeTrialEndDateInMs={freeTrialEndDate && freeTrialEndDate * 1000}
          onRequestClose={() => setCanShowFreeTrialEndBanner(false)}
        />
      )}
      {userId && nextBillingDate && showExpiredCCNotification && (
        <ExpiredCCNotification
          userId={userId}
          siteCode={siteCode}
          canManageBilling={canManageBilling}
          nextBillingDateInMs={nextBillingDate * 1000}
          ccExpiredBeforeRenewal={ccExpiredBeforeRenewal}
          ccExpiringBeforeRenewal={ccExpiringBeforeRenewal}
        />
      )}
      {canShowDelinquencyBanner && !showFreeTrialEndBanner ? (
        <DelinquencyBanner
          siteCode={siteCode}
          nextBillingDateInMs={nextBillingDate * 1000}
          endDateInMs={endDate * 1000}
          canManageBilling={canManageBilling}
        />
      ) : (
        <BillingNotification
          siteCode={siteCode}
          prevPlanName={prevPlanName}
          canManageBilling={canManageBilling}
          hasAlternativeBilling={hasAlternativeBilling}
          hasFrozenSubscription={hasFrozenSubscription}
        />
      )}
      <DbxGlobalHeader.Wrapper hasNoBottomBorder={isWideScreen}>
        {isSidebarCollapsible && (
          <IconButton
            type="button"
            aria-haspopup="true"
            aria-controls="global-nav__drawer"
            variant="transparent"
            onClick={onSidebarToggle}
            data-qa-ref="global-header-sidebar-toggle-btn"
            title={intl.formatMessage(messages.toggleNavigation)}
          >
            <UIIcon src={ListViewLine} />
          </IconButton>
        )}
        {isWordmarkShown && (
          <Logo
            className={styles.logo}
            siteCode={siteCode}
            variant="wordmark"
          />
        )}
        {!logoOnly && withSearch && (
          // Search is not yet fully implemented.
          <DbxGlobalHeader.Search
            hasExpandableAnimation
            onSubmit={onSearch}
            chips={searchChips}
            inputProps={{
              placeholder: intl.formatMessage(messages.searchPlaceholder),
            }}
          />
        )}
        {!logoOnly && (
          <DbxGlobalHeader.UtilityNav>
            {isFree && (!hasFrozenSubscription || canManageBilling) && (
              <Text className={styles.upgradeCta}>
                <Button
                  href={HfReactHelper.urlHelper('info/pricing', site)}
                  variant="opacity"
                  data-qa-ref="global-header-upgrade-btn"
                  data-testid="global-header-upgrade-btn"
                  rel="nofollow noreferrer"
                  target="_blank"
                  withIconLeft={<UIIcon src={UpgradeLine} />}
                >
                  <FormattedMessage
                    id=""
                    description="Text for a button which redirects the user to the Pricing page when pressed"
                    defaultMessage="Upgrade"
                  />
                </Button>
              </Text>
            )}
            <LayerContext.Provider value={{ zIndex: 26 }}>
              {' '}
              {/* Global header is z-index: 25 */}
              {/* Help button */}
              <HelpMenu
                siteCode={siteCode}
                isFree={isFree}
                preset={helpMenuPreset}
              />
              <AccountMenu
                app={app}
                siteCode={siteCode}
                isFree={isFree}
                signatureModalProps={signatureModalProps}
                {...props}
              />
            </LayerContext.Provider>
          </DbxGlobalHeader.UtilityNav>
        )}
      </DbxGlobalHeader.Wrapper>
    </>
  );
}

export default GlobalHeader;
