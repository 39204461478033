/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  global-require,
  import/no-extraneous-dependencies,
  import/no-named-as-default,
  max-len,
  no-nested-ternary,
  no-redeclare,
  no-var,
  no-void,
  one-var,
  react/no-string-refs,
  react/prop-types,
  vars-on-top
*/
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Modal } from '@dropbox/dig-components/modal';
import { Checkbox } from '@dropbox/dig-components/controls';
import { Button as ModalButton } from '@dropbox/dig-components/buttons';
import { GroupHuddleHero } from '@dropbox/dig-illustrations/dist/cjs/hero/group-huddle';
import { Link, Text } from '@dropbox/dig-components/typography';
// Importing intl from below path is not the preferred method, but injectIntl breaks test method. so for DEV-7412 we can use that
import { intl } from 'hellospa/common/hs-intl-provider';
import watchAppNotifierMixin from 'common/components/mixins/watch-app-notifier';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import Button from 'common/components/button';
import TeamReporting from 'hellosign/team-reporting';
import createReactClass from 'create-react-class';
import Spacer, { SpacerSize } from 'hellospa/foundation/spacing/spacer';
import {
  getBrandName,
  getBrandShortName,
} from 'js/sign-components/common/brand';
import TeamMember from './member';

const messages = defineMessages({
  notEnoughSeatError: {
    id: '',
    defaultMessage: 'You need to upgrade to add more team members.',
    description: 'Team Page, error from invite member',
  },
  invalidEmail: {
    id: '',
    defaultMessage: 'Please enter a valid email address!',
    description: 'Team Page, invalid email error under invite box',
  },
  alreadyTeamMember: {
    id: '',
    defaultMessage: 'That user is already on your team.',
    description: 'Team Page, invalid email error under invite box',
  },
  saveButton: {
    id: '',
    defaultMessage: 'Save',
    description:
      'Team Page, button text that triggers saving the new name of a team',
  },
});

const TeamPage = createReactClass({
  /**
   */

  mixins: [watchAppNotifierMixin],

  /**
   */

  propTypes: {
    csrfToken: PropTypes.string,
    baseFormCsrfToken: PropTypes.string.isRequired,
    canEditAdminUsers: PropTypes.bool,
    isDropboxManaged: PropTypes.bool,
    requesterEmail: PropTypes.string,
    teamName: PropTypes.string,
    teamInviteGuid: PropTypes.string,
  },

  /**
   */

  getInitialState() {
    const app = this.props.app;

    const allTeamMembers = app.serverSideGeneratedData.allTeamMembers;

    return {
      app,
      user: app.user,
      team: app.user.getTeam(),
      teamMembers: app.user.getTeam().getMembers(), // Only includes team members from current page (includes pending invites)
      allTeamMembers, // app.user.getTeam().getMembers({ pageSize: Infinity }),  // All team members, not just current page (does not include pending invites)
      subscription: app.serverSideGeneratedData.subscription,
      canEditAdminUsers: this.hasOneAdmin(allTeamMembers)
        ? false
        : this.props.canEditAdminUsers,
      isModalOpen: this.props.isDropboxManaged,
      isChecked: false,
    };
  },

  refreshPage() {
    window.location.reload(false);
  },

  /**
   */
  hasOneAdmin(allTeamMembers) {
    return allTeamMembers.filter((member) => member.isAdmin).length === 1;
  },

  /**
   */

  toggleEditTeamName() {
    this.setState({ editTeamName: !this.state.editTeamName }, () => {
      const node = this.refs.teamNameInput;
      node.focus();
      node.setSelectionRange(
        this.state.team.name.length,
        this.state.team.name.length,
      );
    });
  },

  /**
   */

  saveTeamName(event) {
    let teamName = this.refs.teamNameInput.value.trim();

    if (teamName.length === 0) {
      teamName = 'Untitled Team';
    }

    this.state.team.set('name', teamName);
    this.setState({ editTeamName: false });
    this.state.team.save().then((err) => {
      if (err.error_msg) {
        var err = new Error(err.error_msg);
        this.setState({ error: err });
      }
    });
    event.preventDefault();
  },

  closeModal(event) {
    this.setState({ isModalOpen: false });
    event.preventDefault();
  },

  handleCheckboxToggle(event) {
    this.setState({ isChecked: event.target.checked });
  },

  confirmInviteUrl(doesAccept) {
    const qs = new URLSearchParams({
      execute: '1',
      does_accept: doesAccept ? '1' : '0',
      guid: this.props.teamInviteGuid,
    });
    return HfReactHelper.urlHelper(`account/confirmTeamInvite?${qs}`);
  },

  /**
   */

  async addTeamMember(event) {
    this.setState({ error: void 0 });

    event.preventDefault();

    if (this.state.csvFile) {
      try {
        await this.state.team.importTeamMembers(this.state.csvFile);
      } catch (err) {
        this.setState({ error: err });
      }
      return;
    }

    const ref = this.refs.newMemberEmailAddress;
    const teamMemberEmail = ref.value || '';

    // TODO - move this to model
    if (!HfReactHelper.isValidEmailAddress(teamMemberEmail)) {
      return this.setState({
        error: new Error(intl.formatMessage(messages.invalidEmail)),
      });
    }

    for (let i = 0; i < this.state.allTeamMembers.length; i++) {
      if (this.state.allTeamMembers[i].emailAddress === teamMemberEmail) {
        return this.setState({
          error: new Error(intl.formatMessage(messages.alreadyTeamMember)),
        });
      }
    }

    if (this.state.team.pastTeamMemberLimit()) {
      const {
        subscription: { isAlternativeBilling, isSeatPlan },
      } = this.state;

      if (isSeatPlan) {
        if (isAlternativeBilling) {
          return this.state.app.actions.ui.showModal(
            <div className="l-new-modal">
              <div className="l-new-modal--content-wrap">
                <div className="l-new-modal--title">
                  <FormattedMessage
                    id=""
                    defaultMessage="Add Seats"
                    description="Team Page, must contact partner to add more seats."
                  />
                </div>
                <div className="l-new-modal--content">
                  <p>
                    <FormattedMessage
                      id=""
                      defaultMessage="For additional licenses, please contact your sales rep or partner. Otherwise, email {salesEmail}"
                      description="Team Page, must contact partner to add more seats."
                      values={{
                        salesEmail: () => (
                          <a
                            href={`mailto:${HfReactHelper.HfConstants.api_pricing.URLS.salesUrl}`}
                          >
                            {
                              HfReactHelper.HfConstants.api_pricing.URLS
                                .salesUrl
                            }
                          </a>
                        ),
                      }}
                    />
                  </p>
                </div>
                <div className="l-new-modal--actions">
                  <Button
                    buttonText={
                      <FormattedMessage
                        id=""
                        defaultMessage="Close"
                        description="Team Page, new seat needed modal close button text"
                      />
                    }
                    buttonColor="white"
                    buttonTextColor="grasshopper"
                    buttonHoverColor="grasshopper"
                    buttonClass="m-button-butmit close"
                    buttonBorderColor="grasshopper"
                    buttonHoverTextColor="white"
                  />
                </div>
              </div>
            </div>,
            {
              showCloseButton: false,
              modal: true,
            },
            'new-enterprise-seat',
          );
        } else if (this.state.team.formattedSeatProRateAmount) {
          return this.state.app.actions.ui.showModal(
            <div className="l-new-modal">
              <div className="l-new-modal--content-wrap">
                <div className="l-new-modal--title">
                  <FormattedMessage
                    id=""
                    defaultMessage="New seat needed"
                    description="Team Page, more seat need to add member to team"
                  />
                </div>
                <div className="l-new-modal--content">
                  <p>
                    <FormattedMessage
                      id=""
                      defaultMessage="To invite this person to your team, please purchase a new seat."
                      description="Team Page, purchase new seat"
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id=""
                      defaultMessage="Each seat you add will be billed at your rate of {pricePerSeat} This seat will be prorated at {formattedSeatProRateAmount} for this {periodCycle}."
                      description="Team Page, invite new member flow, explains to user that adding new member will incur additional charges, {pricePerSeat} placeholder. Additionally informs user of pro-rated amount for billing cycle associated with enterprise agreement"
                      values={{
                        pricePerSeat: (
                          <b>
                            {this.state.team.formattedSeatPrice}/
                            {this.state.team.periodName}.
                          </b>
                        ),
                        formattedSeatProRateAmount: (
                          <b>{this.state.team.formattedSeatProRateAmount}</b>
                        ),
                        periodCycle: <b> {this.state.team.periodName}</b>,
                      }}
                    />
                  </p>
                </div>
                <div className="l-new-modal--actions">
                  <Button
                    buttonText={
                      <FormattedMessage
                        id=""
                        defaultMessage="Cancel"
                        description="Team Page, new seat needed modal cancel button text"
                      />
                    }
                    buttonColor="white"
                    buttonTextColor="grasshopper"
                    buttonHoverColor="grasshopper"
                    buttonClass="m-button-butmit close"
                    buttonBorderColor="grasshopper"
                    buttonHoverTextColor="white"
                  />
                  <Button
                    buttonText={
                      <FormattedMessage
                        id=""
                        defaultMessage="Continue"
                        description="Team Page, new seat needed modal continue button text"
                      />
                    }
                    onClick={this._addTeamMember.bind(
                      this,
                      teamMemberEmail,
                      ref,
                    )}
                    buttonColor="grasshopper"
                    buttonHoverColor="ashen"
                    buttonClass="m-button-butmit close"
                    buttonHoverTextColor="white"
                  />
                </div>
              </div>
            </div>,
            {
              showCloseButton: false,
              modal: true,
            },
            'new-enterprise-seat',
          );
        }
      } else {
        return this.setState({
          error: new Error(intl.formatMessage(messages.notEnoughSeatError)),
        });
      }
    }

    this._addTeamMember(teamMemberEmail, ref);
  },

  /**
   */

  async _addTeamMember(teamMemberEmail, ref) {
    this.setState({ addingTeamMember: true });

    try {
      var member = await this.state.team.addMember({
        emailAddress: teamMemberEmail,
        csrfToken: this.props.csrfToken,
      });
      this.setState({
        allTeamMembers: this.state.allTeamMembers.concat([
          {
            emailAddress: '',
            isAdmin: false,
            isLocked: false,
            pending: false,
          },
        ]),
      });
    } catch (e) {
      return this.setState({ error: e, addingTeamMember: false });
    }

    this.setState({
      error: void 0,
      addingTeamMember: false,
    });

    // Clear the input field
    ref.value = '';

    // Generate dialog modal with outcome message
    if (member) {
      let title, msg;
      // This for accounts who have not confirmed their email
      if (typeof member.confirm_email !== 'undefined') {
        title = (
          <FormattedMessage
            id=""
            defaultMessage="Account confirmation required"
            description="Team Page, text on modal when click on invite button"
          />
        );
        msg = (
          <FormattedMessage
            id=""
            defaultMessage="You must confirm your email before adding a team member."
            description="Team Page, text on modal when click on invite button"
          />
        );
      } else if (!member.success) {
        title = 'Error';
        msg = member.error_msg;
        if (
          typeof msg === 'undefined' &&
          typeof member[0].error_msg !== 'undefined'
        ) {
          msg = member[0].error_msg;
        }
      } else if (typeof member.warning !== 'undefined') {
        title = member.pending_email_confirmation ? (
          <FormattedMessage
            id=""
            defaultMessage="Invitation Pending"
            description="Team Page, text on modal when click on invite button"
          />
        ) : (
          <FormattedMessage
            id=""
            defaultMessage="Invitation Sent"
            description="Team Page, text on modal when click on invite button"
          />
        );
        msg = member.warning;
      } else {
        title = (
          <FormattedMessage
            id=""
            defaultMessage="Invitation Sent"
            description="Team Page, text on modal when click on invite button"
          />
        );
        msg = (
          <FormattedMessage
            id=""
            defaultMessage="We've sent {emailAddress} an invitation to join your team."
            description="Team Page, text on modal when click on invite button"
            values={{ emailAddress: member.emailAddress }}
          />
        );
      }
      this.state.app.actions.ui.showModal(
        <div className="l-new-modal">
          <div className="l-new-modal--content-wrap">
            <div
              className="l-new-modal--title"
              data-qa-ref="team-invite-sent-confirmation-box"
            >
              {title}
            </div>
            <div className="l-new-modal--content">
              <p>{msg}</p>
            </div>
            <div className="l-new-modal--actions">
              <Button
                buttonText={
                  <FormattedMessage
                    id=""
                    defaultMessage="Close"
                    description="Team Page, button on modal when click on invite button"
                  />
                }
                buttonColor="grasshopper"
                buttonHoverColor="ashen"
                buttonClass="m-button-submit close"
                buttonHoverTextColor="white"
                buttonAttrs={{ 'data-qa-ref': 'team-invite-close' }}
              />
            </div>
          </div>
        </div>,
        { showCloseButton: false, modal: true },
        'invite-existing-account',
      );
    }
  },

  /**
   */

  onCSVFile(file) {
    this.setState({ csvFile: file });
  },

  /**
   */

  render() {
    const canModifyTeam =
      (this.state.team.isOwner || this.state.team.isManager) &&
      this.state.team.maxSize > 1;
    const sections = {};

    if (this.state.team.maxSize < Infinity) {
      sections.bucketUsage = (
        <p className="bucket-usage">
          <FormattedMessage
            id=""
            defaultMessage="{teamMaxSize, plural,
              =1{You have used <span>{teamSize, number}</span> of # team seat. <a>UPGRADE FOR MORE SEATS</a>}
              other{You have used <span>{teamSize, number}</span> of # team seats. <a>UPGRADE FOR MORE SEATS</a>}
            }"
            description="Team Page, bucket usage"
            values={{
              a: (...chunks) => (
                <a
                  className="team-page-more-seats"
                  href={
                    HfReactHelper.isHelloSign()
                      ? '/home/myAccount?current_tab=billing'
                      : '/info/pricing'
                  }
                >
                  {chunks}
                </a>
              ),
              span: (...chunks) => <span id="team_size_display">{chunks}</span>,
              teamSize: this.state.team.size,
              teamMaxSize: this.state.team.maxSize,
            }}
          />
        </p>
      );
    }

    if (this.props.isDropboxManaged) {
      sections.dropboxManagedModal = (
        <div className="dma-modal">
          <Modal
            aria-labelledby="join-team-modal-title"
            open={this.state.isModalOpen}
            isCentered
            withCloseButton="Close"
            onRequestClose={this.closeModal}
          >
            <Modal.Content>
              <GroupHuddleHero aspectRatio="16:9" />
              <Modal.Header hasBottomSpacing="title-standard">
                <Modal.Title id="join-team-modal-title">
                  <FormattedMessage
                    id=""
                    description="modal header informing user they have been invited to join a Sign team"
                    defaultMessage="You've been invited to join a team"
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Spacer below={SpacerSize.MD}>
                  <FormattedMessage
                    id=""
                    description="modal text informing user what it means to join a team"
                    defaultMessage="You have been invited to join {teamName} by {requesterEmail}. By joining this
                    team, you'll be able to share documents among your teammates. Team Admins will also
                    have access to your documents."
                    values={{
                      requesterEmail: this.props.requesterEmail,
                      teamName: this.props.teamName,
                    }}
                  />
                </Spacer>
                <Spacer below={SpacerSize.MD}>
                  <p id="terms-container">
                    <Checkbox
                      aria-label="terms-checkbox"
                      id="terms-checkbox"
                      onChange={this.handleCheckboxToggle}
                    />
                    <Text
                      tagName="label"
                      id="terms-checkbox-label"
                      htmlFor="terms-checkbox-label"
                    >
                      <FormattedMessage
                        id=""
                        description="checkbox text in modal that user agrees to the Sign terms"
                        defaultMessage="I agree to the <l1>{brandName} Terms</l1>. Learn how we use and protect your data
                        in our <l2>Privacy Policy</l2>."
                        values={{
                          brandName: getBrandName('S'),
                          l1: (...chunks) => (
                            <Link
                              href={HfReactHelper.getWebflowUrl('terms')}
                              target="_blank"
                            >
                              {chunks}
                            </Link>
                          ),
                          l2: (...chunks) => (
                            <Link
                              href={HfReactHelper.getWebflowUrl('privacy')}
                              target="_blank"
                            >
                              {chunks}
                            </Link>
                          ),
                        }}
                      />
                    </Text>
                  </p>
                </Spacer>
                <Text color="faint" size="small">
                  <FormattedMessage
                    id=""
                    description="modal text informing user that deleting their Dropbox account does not delete their Sign account"
                    defaultMessage="By joining a {brandShortName} team plan, your existing Dropbox or DocSend services will become
                    independent accounts. If you decide to delete your {brandShortName} account at a later point, those independent
                    accounts of yours will not be deleted."
                    values={{
                      brandShortName: getBrandShortName('S'),
                    }}
                  />
                </Text>
              </Modal.Body>
              <Modal.Footer>
                <ModalButton
                  variant="opacity"
                  href={this.confirmInviteUrl(false)}
                >
                  <FormattedMessage
                    id=""
                    description="modal button text for user to decline the team invite"
                    defaultMessage="Decline"
                  />
                </ModalButton>
                <ModalButton
                  variant="primary"
                  href={this.confirmInviteUrl(true)}
                  disabled={!this.state.isChecked}
                >
                  <FormattedMessage
                    id=""
                    description="modal button text for user to accept the team invite"
                    defaultMessage="Accept"
                  />
                </ModalButton>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </div>
      );
    }

    if (this.props.showReportingLink) {
      sections.reporting = (
        <p className="bucket-usage">
          <TeamReporting
            csrfToken={this.props.baseFormCsrfToken}
            isEnabled={this.props.isReportingEnabled}
            canRequestUsage={this.props.canRequestUsageReport}
          />
        </p>
      );
    }

    // still not done - don't impl for now (CC)
    /*
        if (this.state.csvFile) {
        sections.uploadCSVButton = <form className='csv_import'>
        <span onClick={this.onCSVFile.bind(this, void 0)} id='csv_import_link'>Cancel</span>
        </form>
        } else {
        sections.uploadCSVButton = <UploadCSVButton onFile={this.onCSVFile} />;
        }
        */

    if (canModifyTeam) {
      sections.editTeamName = (
        <a href="#" id="edit_team_name_link" onClick={this.toggleEditTeamName}>
          <img
            className="team-page-edit-title"
            src={require('./team_edit_icon_@2x.png')}
          />
        </a>
      );

      sections.addTeamMember = (
        <div>
          {sections.bucketUsage}
          {sections.reporting}
          <form className="add_team_member" onSubmit={this.addTeamMember}>
            <input
              ref="newMemberEmailAddress"
              type="text"
              name="new_member_email_address"
              placeholder={
                this.state.csvFile ? this.state.csvFile.name : void 0
              }
              className="required email new_member_email_address no_focus"
              disabled={!!this.state.csvFile || this.state.addingTeamMember}
              data-qa-ref="team-email-text-box"
            />
            <div id="add-team-member-submit-react-holder">
              <Button
                buttonId={
                  this.state.csvFile
                    ? 'csv_import_submit'
                    : 'add_team_member_submit'
                }
                buttonText={
                  this.state.csvFile ? (
                    <FormattedMessage
                      id=""
                      defaultMessage="Invite People"
                      description="Team Page, text on button under team name"
                    />
                  ) : this.state.addingTeamMember ? (
                    <FormattedMessage
                      id=""
                      defaultMessage="Inviting..."
                      description="Team Page, text on button under team name"
                    />
                  ) : (
                    <FormattedMessage
                      id=""
                      defaultMessage="Invite"
                      description="Team Page, text on button under team name"
                    />
                  )
                }
                buttonColor="grasshopper"
                buttonHoverColor="ashen"
                buttonClass="m-button-butmit"
                buttonHoverTextColor="white"
                type="submit"
                buttonAttrs={{ 'data-qa-ref': 'team-invite-button' }}
              />
            </div>
          </form>
          {sections.uploadCSVButton}
        </div>
      );
    } else if (this.state.team.isOwner) {
      sections.addTeamMember = <div>{sections.bucketUsage}</div>;
    }

    if (this.state.editTeamName) {
      sections.editTeamName = (
        <form onSubmit={this.saveTeamName} className="edit-team-title-form">
          <input
            type="text"
            ref="teamNameInput"
            defaultValue={this.state.team.name}
          />
          <Button
            buttonText={intl.formatMessage(messages.saveButton)}
            onClick={this.saveTeamName}
            buttonColor="grasshopper"
            buttonHoverColor="ashen"
            buttonClass="m-button-butmit"
            buttonHoverTextColor="white"
          />
        </form>
      );
    } else {
      const untitledTeamName = (
        <FormattedMessage
          id=""
          defaultMessage="Untitled Team"
          description="Team Page, team name"
        />
      );
      sections.editTeamName = (
        <h1>
          <span id="team_name">{this.state.team.name || untitledTeamName}</span>
          {sections.editTeamName}
        </h1>
      );
    }

    if (this.state.error) {
      sections.error = (
        <div id="error_container">
          <label htmlFor="new_member_email_address" className="error">
            {this.state.error.message}
          </label>
        </div>
      );
    }

    sections.teamMembers = this.state.teamMembers.map((teamMember, i) => {
      return (
        <TeamMember
          app={this.props.app}
          key={teamMember.emailAddress + i}
          member={teamMember}
          teamMembers={this.state.teamMembers}
          allTeamMembers={this.state.allTeamMembers}
          canEditAdminUsers={this.state.canEditAdminUsers}
          refreshPage={this.refreshPage}
        />
      );
    });

    return (
      <div className="team-page">
        {sections.dropboxManagedModal}
        <div
          className={`team-accounts-column ${HfReactHelper.isHelloSign() ? 'hs' : 'hf'}`}
        >
          {sections.editTeamName}
          {sections.addTeamMember}
          {sections.error}

          <div id="team_list_header">
            <div id="member_email">
              <FormattedMessage
                id=""
                defaultMessage="Member Email"
                description="Team Page, Member Email header"
              />
            </div>
            <div id="role">
              <FormattedMessage
                id=""
                defaultMessage="Role"
                description="Team Page, Role header"
              />
            </div>
            {HfReactHelper.isHelloSign() ? (
              <div id="templates">
                <FormattedMessage
                  id=""
                  defaultMessage="Templates Owned"
                  description="Team Page, Templates Owned team list header"
                />
              </div>
            ) : (
              void 0
            )}
          </div>

          <div id="team_list_container">
            <table className="team_list">
              <tbody>{sections.teamMembers}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  },
});

module.exports = TeamPage;
