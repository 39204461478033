import React from 'react';
import PropTypes from 'prop-types';
import baseform from 'common/components/base-form';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  selectAddress: {
    id: '',
    description: 'placeholder text in payment modal cc street name text input',
    defaultMessage: 'Select address',
  },
  selectCity: {
    id: '',
    description: 'placeholder text in payment modal cc city text input',
    defaultMessage: 'City',
  },
  selectState: {
    id: '',
    description: 'placeholder text in payment modal cc state text input',
    defaultMessage: 'State',
  },
});

const HFCCAddressForm = baseform.createFormClass({
  propTypes: {
    fields: PropTypes.array,
    values: PropTypes.object,
    tabOffset: PropTypes.number,
  },

  handleChange(evt) {
    const nameStrArr = evt.target.name
      .replace('[', '_')
      .replace(']', '')
      .split('_');
    const stateName =
      nameStrArr[0] +
      nameStrArr[2].charAt(0).toUpperCase() +
      nameStrArr[2].slice(1);
    this.props.handleChange(stateName, evt.target.value);
  },

  render() {
    const { tabOffset, intl } = this.props;
    return (
      <>
        <div className="row mt20">
          <div className="m-payment--label columns small-12 text-left">
            <p>
              <FormattedMessage
                id=""
                description="modal title in pricing page, asking user to enter billing info"
                defaultMessage="Enter your billing information"
              />
            </p>
          </div>
        </div>
        <div className="row m-payment--wrapper">
          <div className="columns small-12 large-12">
            <div className="row">
              <div className="m-payment--billing-string columns small-12 ">
                {this.renderField(this.props.fieldMap.cc_street.name, {
                  className: 'm-payment--cc-address-street',
                  tabIndex: tabOffset,
                  placeholder: intl.formatMessage(messages.selectAddress),
                  'data-qa-ref': 'cc-address-street',
                  onChange: this.handleChange,
                })}
              </div>
            </div>
            <div className="row">
              <div className="m-payment--billing-string columns small-8 text-left">
                {this.renderField(this.props.fieldMap.cc_city.name, {
                  className: 'm-payment--cc-address-city',
                  tabIndex: tabOffset + 1,
                  placeholder: intl.formatMessage(messages.selectCity),
                  'data-qa-ref': 'cc-address-city',
                  onChange: this.handleChange,
                })}
              </div>
              <div className="columns small-4">
                {this.renderField(this.props.fieldMap.cc_state.name, {
                  className: 'm-payment--cc-address-state',
                  tabIndex: tabOffset + 2,
                  placeholder: intl.formatMessage(messages.selectState),
                  'data-qa-ref': 'cc-address-state',
                  onChange: this.handleChange,
                })}
              </div>
            </div>
            <div className="row">
              <div className="columns small-12">
                {this.renderField(this.props.fieldMap.cc_country.name, {
                  className: 'm-payment--cc-address-country',
                  tabIndex: tabOffset + 3,
                  'data-qa-ref': 'cc-address-country',
                  onChange: this.handleChange,
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
});

export default injectIntl(HFCCAddressForm);
