/**
 * This file copies over the logic needed to support the
 * legacy signature modal. See hellosign/components/app/header.
 *
 * This component acts as interface between the new layout
 * and the old signature modal.
 */

import React from 'react';
import SignIntegratedSignatureModal from 'signer-app/signature-modal/modal';
import { SignatureModalProps } from 'signer-app/signature-modal/signature-modal';
import { useSignatureModalContext } from 'signer-app/signature-modal/signature-modal-context/context';

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onInsert: SignatureModalProps['onInsert'];
};

function SignatureModalInterface({ isOpen, onInsert, onRequestClose }: Props) {
  const context = useSignatureModalContext();
  return (
    <SignIntegratedSignatureModal
      isOpen={isOpen}
      type={'S'}
      loading={false}
      onClose={(): void => {
        onRequestClose();
      }}
      initialTypeInValue={context.signatureInitialTypeInValue}
      onInsert={onInsert}
      {...context}
    />
  );
}

export default SignatureModalInterface;
