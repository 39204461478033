import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Modal } from '@dropbox/dig-components/modal';
import { Button } from '@dropbox/dig-components/buttons';
import { FormattedMessage } from 'react-intl';
import { errors, modalTypes } from '../constants';
import ResumePausedContent from './resume-paused-content';
import { PauseCancelModalContext } from '../types';
import { confirmResumePause } from '../utils';

type ConfirmProps = {
  setModalType: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<string | null>>;
  handleCloseModal: () => void;
  modalContext: PauseCancelModalContext;
};

const ResumePaused = ({
  setModalType,
  setError,
  handleCloseModal,
  modalContext,
}: ConfirmProps) => {
  const { totalCharge, currencyCode, unpauseDate, planName } = modalContext;
  const [isConfirmingResumePaused, setIsConfirmingResumePaused] =
    useState(false);

  const handleConfirmResumePaused = useCallback(async () => {
    setIsConfirmingResumePaused(true);
    const response = await confirmResumePause();

    if (response.success) {
      setIsConfirmingResumePaused(false);
      handleCloseModal();
      window.location.reload();
    } else {
      setError(errors.RESUME_PAUSED);
      setModalType(modalTypes.ERROR);
    }
  }, [handleCloseModal, setError, setModalType]);

  const handleClickBack = useCallback(() => {
    setModalType(modalTypes.BILLBOARD);
  }, [setModalType]);

  return (
    <div>
      <ResumePausedContent
        totalCharge={totalCharge}
        currencyCode={currencyCode}
        planName={planName}
        unpauseDate={unpauseDate}
      />
      <Modal.Footer>
        <Button
          variant="transparent"
          onClick={handleClickBack}
          data-qa-ref="cancel-plan-from-resume-paused-modal-button"
        >
          <FormattedMessage
            id=""
            description="button, modal button text, opens a modal that prompts user to cancel their plan"
            defaultMessage="Cancel plan"
          />
        </Button>
        {/* This div is used to add spacing between action buttons */}
        <div />
        <Button
          variant="primary"
          onClick={handleConfirmResumePaused}
          data-qa-ref="confirm-resume-paused-button"
          disabled={isConfirmingResumePaused}
        >
          <FormattedMessage
            id=""
            description="button, modal button text, accept resume-paused plan"
            defaultMessage="Resume plan"
          />
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default ResumePaused;
