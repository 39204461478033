import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { RadioButton } from '@dropbox/dig-components/controls';
import { Text } from '@dropbox/dig-components/typography';
import { ReasonProps, values } from './types';
import { TextArea } from '@dropbox/dig-components/text_fields';
import styles from '../survey.module.scss';

const messages = defineMessages({
  alternativePlaceholder: {
    id: '',
    description:
      'Placeholder text for a text area when users can provide more information about why they are cancelling their subscription.',
    defaultMessage: 'My alternative e-signature solution is ...',
  },
});

function CancelReasonAlternative({ contextRef, ...props }: ReasonProps) {
  const intl = useIntl();

  return (
    <div className={styles.reason}>
      <div className={styles.reasonGroup}>
        <RadioButton
          {...props}
          className={styles.reasonButton}
          data-qa-ref="cancel-subscription-survey__reason-alternative"
          id="cancel-subscription-survey__radio-alternative"
          name="reason"
          value={values.ALTERNATIVE}
        />
        <div className={styles.contextContainer}>
          <Text
            tagName="label"
            htmlFor="cancel-subscription-survey__radio-alternative"
          >
            <FormattedMessage
              id=""
              description="An option the user can select when explaining why s/he is canceling their subscription."
              defaultMessage="I found a better alternative"
            />
          </Text>
          {props.checked && (
            <div className={styles.reasonContext}>
              <TextArea
                data-qa-ref="cancel-subscription-survey__reason-alternative-context"
                ref={contextRef}
                id="cancel-subscription-survey__context-alternative"
                rows={3}
                disabled={props.disabled}
                placeholder={intl.formatMessage(
                  messages.alternativePlaceholder,
                )}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CancelReasonAlternative;
