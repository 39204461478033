import React from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';

import HelloModal from 'common/components/hello-modal';

const messages = defineMessages({
  modalContentLabel: {
    id: '', // ID's are procedurally generated
    description:
      'The title of the modal when the admin is disabling an integration.',
    defaultMessage: 'Disable integration?',
  },
  modalButtonCancel: {
    id: '',
    description:
      'Text for a button that closes the modal without saving when clicked.',
    defaultMessage: 'Cancel',
  },
  modalButtonDisable: {
    id: '',
    description:
      'Text for a button that will disable an integration when clicked.',
    defaultMessage: 'Disable',
  },
});

const IntegrationConfirmDialog = ({
  integrationName,
  isOpen,
  onRequestClose,
}) => {
  const intl = useIntl();

  const requestCloseCancel = React.useCallback(() => {
    onRequestClose(false);
  }, [onRequestClose]);

  const requestCloseDisable = React.useCallback(() => {
    onRequestClose(true);
  }, [onRequestClose]);

  return (
    <HelloModal
      isOpen={isOpen}
      onRequestClose={requestCloseCancel}
      contentLabel={intl.formatMessage(messages.modalContentLabel)}
      buttons={[
        {
          type: 'secondary',
          text: intl.formatMessage(messages.modalButtonCancel),
          onClick: requestCloseCancel,
        },
        {
          type: 'primary',
          text: intl.formatMessage(messages.modalButtonDisable),
          onClick: requestCloseDisable,
        },
      ]}
    >
      <div className="integration-confirm-dialog">
        <div className="container px-0">
          <div className="row no-gutters">
            <div className="col">
              <p>
                <FormattedMessage
                  id=""
                  description="Body text for a modal which confirms that an admin wants to disable an integration."
                  defaultMessage="<strong>{integrationName}</strong> may have been activated by some of your users. You can still modify this option until all settings changes have been saved."
                  values={{
                    integrationName,
                    // eslint-disable-next-line react/display-name
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </HelloModal>
  );
};

export default IntegrationConfirmDialog;
