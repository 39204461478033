import React from 'react';
import { TextArea } from '@dropbox/dig-components/text_fields';

function ReceiptAddress(props) {
  const { onChange, receiptAddress, message, disabled } = props;
  return (
    <div className="admin-settings-textarea">
      <TextArea
        id="receipt-address"
        className="admin-settings-textarea__textarea"
        data-qa-ref="receipt-address"
        data-testid="receipt-address"
        rows="4"
        cols="30"
        onChange={onChange}
        disabled={disabled}
        defaultValue={receiptAddress}
      />
      <div className="state-message">
        <small>{message}</small>
      </div>
    </div>
  );
}

export default ReceiptAddress;
