import { defineMessages } from 'react-intl';
// These should eventually be put in a React.Intl translations file,
// when we have frontend app support

const legalText = defineMessages({
  HS: {
    id: '',
    description:
      'legal info text for subscription cancellation, subscription cancellation, survey',
    defaultMessage:
      "By canceling, you will retain access to business features through the remainder of the period already paid. When this expires, you will lose access to these features. You will not receive a free trial if you choose to sign up for a new subscription. View our <a>Terms of Service</a> for more details. Also, we'll have to stop providing our employees with free cookies.",
  },
  HF: {
    id: '',
    description:
      'legal info text for subscription cancellation, subscription cancellation, survey',
    defaultMessage:
      "By canceling, you will retain access to your paid features for the remainder of the period already paid. When this expires, you will lose your fax number(s), the ability to receive faxes, and your monthly allotment of fax pages. You will not receive a free trial if you choose to sign up for a new subscription. View our <a>Terms of Service</a> for more details. Also, we'll have to stop providing our employees with free cookies.",
  },
});

const cancelReasons = defineMessages({
  cancel_reason_no_use: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage: 'Not using this service frequently',
  },
  cancel_reason_ux: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage: 'Service was hard to use',
  },
  cancel_reason_customer_service: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage: 'Unhappy with the customer service I received',
  },
  cancel_reason_cost: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage: 'The cost of this service',
  },
  cancel_reason_features: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage: 'The service did not have the features I need',
  },
  cancel_reason_competitor: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage: 'I started using a different service',
  },
});

const competitorReasons = defineMessages({
  competitor_reason_ux: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage: 'Their tool was easier to use',
  },
  competitor_reason_customer_service: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage: 'Better customer service',
  },
  competitor_reason_features: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage: 'More features',
  },
  competitor_reason_cost: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage: 'Better price',
  },
  competitor_reason_quality: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage: 'Better quality',
  },
});

const errors = defineMessages({
  noSelection: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage: 'Please select at least one option',
  },
  messageTooShort: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage: 'Please enter a brief message for support',
  },
});

const placeholders = defineMessages({
  'cancel_reason_features-detail': {
    id: '',
    description:
      'link, subscription cancellation, survey, question to member regarding product and suggestions to improve',
    defaultMessage: 'What was missing?',
  },
});

const nonGroupedMessages = defineMessages({
  teamAdminWarning: {
    id: '',
    description: 'link, subscription cancellation, survey',
    defaultMessage:
      'Canceling your subscription will also disband your team and shared access to documents will be revoked.',
  },
});

export const cancelStrings = {
  legalText,
  cancelReasons,
  competitorReasons,
  placeholders,
  ...nonGroupedMessages,
  errors,
};

export const cancelConstants = {
  competitorList: [
    '17 Hats',
    'Adobe EchoSign',
    'AssureSign',
    'Cudasign',
    'DocuSign',
    'DotLoop',
    'eSignLive',
    'Legalesign',
    'Other',
    'PandaDoc',
    'RightSignature',
    'Sertifi',
    'Silanis',
  ],
  AUTOCOMPLETE_MIN_THRESHOLD: 2,
  SUPPORT_MESSAGE_MIN_THRESHOLD: 3,
};
