/* If you edit this file, please remove this header and clean up the resulting eslint errors. */
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len,
  no-redeclare,
  no-var,
  react/no-find-dom-node,
  react/prop-types,
  vars-on-top
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import createReactClass from 'create-react-class';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { getBrandName } from 'js/sign-components/common/brand';
import PaymentForm from './payment-form';

import './payment-modal.scss';

const messages = defineMessages({
  rateWithPeriodMonthly: {
    id: '',
    description: 'display, payment modal, price for a month',
    defaultMessage: '{price}, plus applicable taxes, monthly',
  },
  rateWithPeriodYearly: {
    id: '',
    description: 'display, payment modal, price for a yearly',
    defaultMessage: '{price}, plus applicable taxes, yearly',
  },

  // Change description after running this
  signUpPlan: {
    id: '',
    description:
      'header, payment modal, provides terms to start free trial after adding credit card information',
    defaultMessage: 'Sign up for {planName}',
  },
  startTrial: {
    id: '',
    description:
      'header, payment modal, provides terms to start free trial after adding credit card information',
    defaultMessage: 'Start {planName} Trial',
  },
  upgradePlan: {
    id: '',
    description:
      'header, payment modal, provides terms to upgrade plan after providing/confirming credit card information',
    defaultMessage: 'Upgrade to {planName}',
  },
  downgradePlan: {
    id: '',
    description:
      'header, payment modal, provides terms to downgrade plan after providing/confirming credit card information',
    defaultMessage: 'Downgrade to {planName}',
  },
});

const PaymentModal = createReactClass({
  // NOTE: These propTypes are incomplete - see render() for all props
  propTypes: {
    tabOffset: PropTypes.number,
    paymentForm: PropTypes.object,
    registerFaxLineForm: PropTypes.object,
    numSeats: PropTypes.number,
    formAction: PropTypes.string,
    headerText: PropTypes.string,
    forceFreeTrial: PropTypes.bool,
    numFreeTrialDays: PropTypes.number,
    tosUrl: PropTypes.string,
    refundPolicyUrl: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,
    legalRequirementLinkHref: PropTypes.string,
    cancelLinkHref: PropTypes.string,
    planRawName: PropTypes.string,
    planName: PropTypes.string,
    isApi: PropTypes.bool,
    nextBillingDate: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    appElement: PropTypes.object,
    newNumFaxPages: PropTypes.number,
    newPageQuota: PropTypes.number,
    numPagesUsed: PropTypes.number,
    faxLinesCostInCents: PropTypes.number,
    hasTeam: PropTypes.bool,
  },

  getInitialState() {
    return {
      stripe_token: '',
      renewalChecked: this.props.ackAutoRenewal,
      hasError: false,
    };
  },

  componentDidMount() {
    if (NODE_ENV !== 'test') {
      const $root = $(ReactDOM.findDOMNode(this));
      const $modal = $root.closest('.reveal-modal');
      const $doc = $(document);

      $doc.foundation();

      $doc.on('open.fndtn.reveal', () => {
        document.body.classList.add('legacy-foundation-modal-visible');

        const firstInput = $root.find('input:first');
        firstInput.focus();
      });

      $doc.one('closed.fndtn.reveal', () => {
        document.body.classList.remove('legacy-foundation-modal-visible');
      });

      $modal.foundation('reveal', 'open');
    }
  },

  componentWillReceiveProps() {
    if (NODE_ENV !== 'test') {
      $(ReactDOM.findDOMNode(this))
        .closest('.reveal-modal')
        .foundation('reveal', 'open');
    }
  },

  checkIfDowngrade() {
    return this.props.timePeriod == 'M'
      ? this.props.currentMonthlyRate > this.props.monthlyExpectedRate
      : this.props.currentAnnualRate > this.props.annualExpectedRate;
  },

  onRenewalCheck(event) {
    this.setState({ renewalChecked: event.target.checked });
  },

  getRenewalCheck() {
    return this.state.renewalChecked;
  },

  generateBillingString() {
    const billingsStrings = defineMessages({
      baseMo: {
        id: '',
        description:
          'legal notice text, informs user of how trial/charging will be done, when and what amount',
        defaultMessage:
          "{freeTrialDays, plural, one {Your # day trial starts today. if you change your mind, you can cancel your account and you won't be charged. After your free trial, you will be charged {rateString}, plus applicable taxes, monthly} other {Your # day trial starts today. Within those # days, if you change your mind, you can cancel your account and you won't be charged. After your free trial, you will be charged {rateString}, plus applicable taxes, monthly}}",
      },
      baseMoWithCoupon: {
        id: '',
        description:
          'legal notice text, informs user of how trial/charging will be done, when and what amount',
        defaultMessage:
          "{freeTrialDays, plural, one {Your # day trial starts today. if you change your mind, you can cancel your account and you won't be charged. After your free trial, you will be charged {rateString}, plus applicable taxes, monthly for the first {couponDurationWithPeriodString} and then {fullRateWithPeriodString} thereafter} other {Your # day trial starts today. Within those # days, if you change your mind, you can cancel your account and you won't be charged. After your free trial, you will be charged {rateString}, plus applicable taxes, monthly for the first {couponDurationWithPeriodString} and then {fullRateWithPeriodString} thereafter}}",
      },
      baseYr: {
        id: '',
        description:
          'legal notice text, informs user of how trial/charging will be done, when and what amount',
        defaultMessage:
          "{freeTrialDays, plural, one {Your # day trial starts today. if you change your mind, you can cancel your account and you won't be charged. After your free trial, you will be charged {rateString}, plus applicable taxes, yearly} other {Your # day trial starts today. Within those # days, if you change your mind, you can cancel your account and you won't be charged. After your free trial, you will be charged {rateString}, plus applicable taxes, yearly}}",
      },
      baseYrWithCoupon: {
        id: '',
        description:
          'legal notice text, informs user of how trial/charging will be done, when and what amount',
        defaultMessage:
          "{freeTrialDays, plural, one {Your # day trial starts today. if you change your mind, you can cancel your account and you won't be charged. After your free trial, you will be charged {rateString}, plus applicable taxes, yearly for the first {couponDurationWithPeriodString} and then {fullRateWithPeriodString} thereafter} other {Your # day trial starts today. Within those # days, if you change your mind, you can cancel your account and you won't be charged. After your free trial, you will be charged {rateString}, plus applicable taxes, yearly for the first {couponDurationWithPeriodString} and then {fullRateWithPeriodString} thereafter}}",
      },
      proBase: {
        id: '',
        description:
          'legal notice text, informs user of how trial/charging will be done, when and what amount, with instructions how to cancel subscription',
        defaultMessage:
          '{freeTrialDays, plural,' +
          'one {Your # day free trial starts today. ' +
          "if you change your mind, you can cancel your account and you won't be charged." +
          '\n\n' +
          'After your free trial, you will be charged {rateWithPeriodString} until you <cancel_link>cancel</cancel_link>' +
          " your subscription. Previous charges <refund_doc_link>won't be refunded</refund_doc_link>" +
          " when you cancel <legal_requirement_link>unless it's legally required.</legal_requirement_link>}" +
          'other {Your # day free trial starts today. ' +
          "Within those # days, if you change your mind, you can cancel your account and you won't be charged." +
          '\n\n' +
          'After your free trial, you will be charged {rateWithPeriodString} until you <cancel_link>cancel</cancel_link>' +
          " your subscription. Previous charges <refund_doc_link>won't be refunded</refund_doc_link>" +
          " when you cancel <legal_requirement_link>unless it's legally required.</legal_requirement_link>}" +
          '}',
      },
      proBaseWithCoupon: {
        id: '',
        description:
          'legal notice text, informs user of how trial/charging will be done, when and what amount, with coupon duration, with instructions how to cancel subscription',
        defaultMessage:
          '{freeTrialDays, plural,' +
          'one {Your # day free trial starts today. ' +
          "if you change your mind, you can cancel your account and you won't be charged." +
          '\n\n' +
          'After your free trial, you will be charged {rateWithPeriodString} for the first {couponDurationWithPeriodString} and then {fullRateWithPeriodString} thereafter until you <cancel_link>cancel</cancel_link>' +
          " your subscription. Previous charges <refund_doc_link>won't be refunded</refund_doc_link>" +
          " when you cancel <legal_requirement_link>unless it's legally required.</legal_requirement_link>}" +
          'other {Your # day free trial starts today. ' +
          "Within those # days, if you change your mind, you can cancel your account and you won't be charged." +
          '\n\n' +
          'After your free trial, you will be charged {rateWithPeriodString} for the first {couponDurationWithPeriodString} and then {fullRateWithPeriodString} thereafter until you <cancel_link>cancel</cancel_link>' +
          " your subscription. Previous charges <refund_doc_link>won't be refunded</refund_doc_link>" +
          " when you cancel <legal_requirement_link>unless it's legally required.</legal_requirement_link>}" +
          '}',
      },
      billingToday: {
        id: '',
        description:
          'legal notice, informs user that charge for the plan will be done today after user authorities payment form',
        defaultMessage:
          'You will be billed {rateString}, plus applicable taxes, today',
      },
      billingTodayWithCoupon: {
        id: '',
        description:
          'legal notice, informs user that discounted charge for the plan will be done today after user authorities payment form',
        defaultMessage:
          'You will be billed today for {rateWithPeriodString} for the first {couponDurationWithPeriodString} and then {fullRateWithPeriodString} thereafter',
      },
      billingTodayPro: {
        id: '',
        description:
          'legal notice, informs user that charge for the plan will be done today after user authorities payment form, with instructions how to cancel subscription',
        defaultMessage:
          'Starting today, you will be charged {rateWithPeriodString} until you <cancel_link>cancel</cancel_link>' +
          " your subscription. Previous charges <refund_doc_link>won't be refunded</refund_doc_link>" +
          " when you cancel <legal_requirement_link>unless it's legally required.</legal_requirement_link>",
      },
      billingTodayProWithCoupon: {
        id: '',
        description:
          'legal notice, informs user that the discounted charge for the plan will be done today after user authorities payment form, with instructions how to cancel subscription',
        defaultMessage:
          'Starting today, you will be charged {rateWithPeriodString} for the first {couponDurationWithPeriodString} and then {fullRateWithPeriodString} thereafter until you <cancel_link>cancel</cancel_link>' +
          " your subscription. Previous charges <refund_doc_link>won't be refunded</refund_doc_link>" +
          " when you cancel <legal_requirement_link>unless it's legally required.</legal_requirement_link>",
      },
      nextCycle: {
        id: '',
        description:
          'legal notice, informing user that next charge will be some amount dollars',
        defaultMessage:
          'At the beginning of your next billing cycle you will be billed {rateString}, plus applicable taxes.',
      },
      nextCycleWithCoupon: {
        id: '',
        description:
          'legal notice, informing user that next charge will be some amount dollars, discounted for the duration of the coupon, then the full rate afterwards',
        defaultMessage:
          'At the beginning of your next billing cycle you will be billed {rateString}, plus applicable taxes, for the first {couponDurationWithPeriodString} and then {fullRateWithPeriodString} thereafter',
      },
      nextCyclePro: {
        id: '',
        description:
          'legal notice, informing user that next charge will be some amount dollars with instructions how to cancel subscription',
        defaultMessage:
          'At the beginning of your next billing cycle, you will be charged {rateWithPeriodString} until you <cancel_link>cancel</cancel_link>' +
          " your subscription. Previous charges <refund_doc_link>won't be refunded</refund_doc_link>" +
          " when you cancel <legal_requirement_link>unless it's legally required.</legal_requirement_link>",
      },
      nextCycleProWithCoupon: {
        id: '',
        description:
          'legal notice, informing user that next discounted charges for length of coupon, then the full amount after coupon has expired, with instructions how to cancel subscription',
        defaultMessage:
          'At the beginning of your next billing cycle, you will be charged {rateWithPeriodString} for the first {couponDurationWithPeriodString} and then {fullRateWithPeriodString} thereafter until you <cancel_link>cancel</cancel_link>' +
          " your subscription. Previous charges <refund_doc_link>won't be refunded</refund_doc_link>" +
          " when you cancel <legal_requirement_link>unless it's legally required.</legal_requirement_link>",
      },
      afterFreeTrialPro: {
        id: '',
        description:
          'legal notice, informing user about charges that will occur after free trial, with instructions how to cancel subscription',
        defaultMessage:
          'You are currently on a free trial. After your free trial, you will be charged {rateWithPeriodString} until you <cancel_link>cancel</cancel_link>' +
          " your subscription. Previous charges <refund_doc_link>won't be refunded</refund_doc_link>" +
          " when you cancel <legal_requirement_link>unless it's legally required.</legal_requirement_link>",
      },
      afterFreeTrialProWithCoupon: {
        id: '',
        description:
          'legal notice, informing user about discounted charges for length of coupon that will occur after free trial, then the full amount after coupon has expired, with instructions how to cancel subscription',
        defaultMessage:
          'You are currently on a free trial. After your free trial, you will be charged {rateWithPeriodString} for the first {couponDurationWithPeriodString} and then {fullRateWithPeriodString} thereafter until you <cancel_link>cancel</cancel_link>' +
          " your subscription. Previous charges <refund_doc_link>won't be refunded</refund_doc_link>" +
          " when you cancel <legal_requirement_link>unless it's legally required.</legal_requirement_link>",
      },
      afterFreeTrial: {
        id: '',
        description:
          'legal notice, informing user about charges that will occur after free trial',
        defaultMessage:
          'You will be billed {rateString}, plus applicable taxes, at the end of your existing free trial',
      },
      afterFreeTrialWithCoupon: {
        id: '',
        description:
          'legal notice, informing user about discounted charges for length of coupon that will occur after free trial, then the full amount after coupon has expired,',
        defaultMessage:
          'You will be billed {rateWithPeriodString} for the first {couponDurationWithPeriodString} and then {fullRateWithPeriodString} thereafter at the end of your existing free trial',
      },
      prorate: {
        id: '',
        description:
          'legal notice, informs user that amount to pay today has been reduced for current payment(prorating), and starting next billing cycle full amount will be charged',
        defaultMessage:
          'You will be billed a prorated amount of {proratedRateString}, plus applicable taxes, today. On the start of your next billing cycle you will then be charged the full amount for the new period ({rateString}, plus applicable taxes)',
      },
      downgrade: {
        id: '',
        description:
          'legal notice, informs user what will happen in case of downgrading to free subscription',
        defaultMessage:
          'Downgrading will end your free trial and you will not be charged. You can upgrade at any time.',
      },
      chargeBaseMo: {
        id: '',
        description:
          'legal notice, informs user of pro-rated(less than full, but outstanding for the duration used) charge today and explains what amount will be charged starting next billing cycle',
        defaultMessage:
          'You will be charged {proratedRateString}, plus applicable taxes, for your current pro-rated billing cycle. Starting with your next billing cycle, you will be charged {rateString}/mo, plus applicable taxes',
      },
      chargeBaseYr: {
        id: '',
        description:
          'legal notice, informs user of pro-rated(less than full, but outstanding for the duration used) charge today and explains what amount will be charged starting next billing cycle',
        defaultMessage:
          'You will be charged {proratedRateString}, plus applicable taxes, for your current pro-rated billing cycle. Starting with your next billing cycle, you will be charged {rateString}/yr, plus applicable taxes',
      },
      chargePro: {
        id: '',
        description:
          'legal notice, informs user of pro-rated(less than full, but outstanding for the duration used) charge today and explains what amount will be charged starting next billing cycle, with instructions how to cancel subscription',
        defaultMessage:
          'You will be charged {proratedRateString}, plus applicable taxes, for your current pro-rated billing cycle. Starting with your next billing cycle, you will be charged {rateWithPeriodString} until you <cancel_link>cancel</cancel_link>' +
          " your subscription. Previous charges <refund_doc_link>won't be refunded</refund_doc_link>" +
          " when you cancel <legal_requirement_link>unless it's legally required.</legal_requirement_link>",
      },
      couponDurationYearOnly: {
        id: '',
        description:
          'legal notice, coupon duration split by year and month if applicable, duration only for year or years',
        defaultMessage:
          '{couponAnnualDuration, plural, one {year} other {# years}}',
      },
      couponDurationMonthOnly: {
        id: '',
        description:
          'legal notice, coupon duration split by year and month if applicable, duration only for month or months',
        defaultMessage:
          '{couponMonthDuration, plural, one {month} other {# months}}',
      },
      couponDurationYearMonthSingle: {
        id: '',
        description:
          'legal notice, coupon duration split by year and month if applicable, duration single for year and month, due to hard coded numbers no pluralization needed',
        defaultMessage: 'year and 1 month',
      },
      couponDurationYearMonthPlural: {
        id: '',
        description:
          'legal notice, coupon duration split by year and month if applicable, duration plural for year and plural for months, no pluralization needed as every combination is separated',
        defaultMessage:
          '{couponAnnualDuration} years and {couponMonthDuration} months',
      },
      couponDurationYearSingleMonthPlural: {
        id: '',
        description:
          'legal notice, coupon duration split by year and month if applicable, duration single for year and plural for months, no pluralization needed as every combination is separated',
        defaultMessage: 'year and {couponMonthDuration} months',
      },
      couponDurationYearPluralMonthSingle: {
        id: '',
        description:
          'legal notice, coupon duration split by year and month if applicable, duration plural for year and single for months, no pluralization needed as every combination is separated',
        defaultMessage: '{couponAnnualDuration} years and 1 month',
      },
      extraFaxLineCharge: {
        id: '',
        description:
          'billing string in HF payment modal, informing user that the charge shown includes the extra charge of extra fax line',
        defaultMessage:
          'Included in this amount is a charge of {faxLinesCost} for your current extra fax lines.',
      },
      totalFaxPagesAfterUpgrade: {
        id: '',
        description:
          'billing string in HF payment modal, informing user that after upgrade there will be extra fax pages from upgrade plus the remaining fax pages',
        defaultMessage:
          'The number of fax pages available to you after upgrading will be {newNumFaxPages} ({newPageQuota} from the new plan minus {numPagesUsed} already used this month).',
      },
    });
    const { intl } = this.props;
    const rateString =
      this.props.timePeriod == 'M'
        ? this.props.formattedMonthlyExpectedRate
        : this.props.formattedAnnualExpectedRate;
    const fullRateString =
      this.props.timePeriod == 'M'
        ? this.props.formattedMonthlyPrice
        : this.props.formattedAnnualPrice;

    const couponAnnualDuration = Math.ceil(this.props.couponNumMonths / 12);
    const couponMonthDuration = this.props.couponNumMonths;

    let couponDurationWithPeriodString = '';

    let rateWithPeriodString = '';
    let fullRateWithPeriodString = '';
    if (this.props.timePeriod == 'M') {
      couponDurationWithPeriodString = intl.formatMessage(
        billingsStrings.couponDurationMonthOnly,
        { couponMonthDuration },
      );
      rateWithPeriodString = intl.formatMessage(
        messages.rateWithPeriodMonthly,
        { price: rateString },
      );
      fullRateWithPeriodString = intl.formatMessage(
        messages.rateWithPeriodMonthly,
        { price: fullRateString },
      );
    } else {
      couponDurationWithPeriodString = intl.formatMessage(
        billingsStrings.couponDurationYearOnly,
        { couponAnnualDuration },
      );
      rateWithPeriodString = intl.formatMessage(messages.rateWithPeriodYearly, {
        price: rateString,
      });
      fullRateWithPeriodString = intl.formatMessage(
        messages.rateWithPeriodYearly,
        { price: fullRateString },
      );
    }
    const freeTrialDays = this.props.numFreeTrialDays || 30;

    let proratedRateString =
      this.props.timePeriod == 'M'
        ? this.props.formattedMonthlyProratedRate
        : this.props.formattedAnnualProratedRate;

    // If the update charge is specifically passed than we show it
    if (this.props.formattedUpgradeCharge) {
      proratedRateString = this.props.formattedUpgradeCharge;
    }

    let showAutoRenewal = false;

    let billingString = '';
    if (this.props.couponCode) {
      billingString =
        this.props.timePeriod == 'M'
          ? intl.formatMessage(billingsStrings.baseMoWithCoupon, {
              freeTrialDays,
              rateString,
              couponDurationWithPeriodString,
              fullRateWithPeriodString,
            })
          : intl.formatMessage(billingsStrings.baseYrWithCoupon, {
              freeTrialDays,
              rateString,
              couponDurationWithPeriodString,
              fullRateWithPeriodString,
            });
    } else {
      billingString =
        this.props.timePeriod == 'M'
          ? intl.formatMessage(billingsStrings.baseMo, {
              freeTrialDays,
              rateString,
            })
          : intl.formatMessage(billingsStrings.baseYr, {
              freeTrialDays,
              rateString,
            });
    }

    if (
      (this.props.showHSAutoRenewal &&
        this.props.planRawName.toLowerCase() === 'essentials') ||
      (HfReactHelper.isHelloFax() &&
        (this.props.planRawName.toLowerCase() === 'home office' ||
          this.props.planRawName.toLowerCase() === 'professional'))
    ) {
      showAutoRenewal = true;
      billingString = this.props.couponCode
        ? intl.formatMessage(billingsStrings.proBaseWithCoupon, {
            freeTrialDays,
            rateWithPeriodString,
            couponDurationWithPeriodString,
            fullRateWithPeriodString,
            cancel_link: (msg) => (
              <a
                href={this.props.cancelLinkHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg}
              </a>
            ),
            refund_doc_link: (msg) => (
              <a
                href={this.props.refundPolicyUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg}
              </a>
            ),
            legal_requirement_link: (msg) => (
              <a
                href={this.props.legalRequirementLinkHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg}
              </a>
            ),
          })
        : intl.formatMessage(billingsStrings.proBase, {
            freeTrialDays,
            rateWithPeriodString,
            cancel_link: (msg) => (
              <a
                href={this.props.cancelLinkHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg}
              </a>
            ),
            refund_doc_link: (msg) => (
              <a
                href={this.props.refundPolicyUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg}
              </a>
            ),
            legal_requirement_link: (msg) => (
              <a
                href={this.props.legalRequirementLinkHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                {msg}
              </a>
            ),
          });
    }

    const billingTodayString = showAutoRenewal
      ? intl.formatMessage(billingsStrings.billingTodayPro, {
          rateWithPeriodString,
          cancel_link: (msg) => (
            <a
              href={this.props.cancelLinkHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
          refund_doc_link: (msg) => (
            <a
              href={this.props.refundPolicyUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
          legal_requirement_link: (msg) => (
            <a
              href={this.props.legalRequirementLinkHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
        })
      : intl.formatMessage(billingsStrings.billingToday, { rateString });
    const billingTodayWithCouponString = showAutoRenewal
      ? intl.formatMessage(billingsStrings.billingTodayProWithCoupon, {
          rateWithPeriodString,
          couponDurationWithPeriodString,
          fullRateWithPeriodString,
          cancel_link: (msg) => (
            <a
              href={this.props.cancelLinkHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
          refund_doc_link: (msg) => (
            <a
              href={this.props.refundPolicyUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
          legal_requirement_link: (msg) => (
            <a
              href={this.props.legalRequirementLinkHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
        })
      : intl.formatMessage(billingsStrings.billingTodayWithCoupon, {
          rateWithPeriodString,
          couponDurationWithPeriodString,
          fullRateWithPeriodString,
        });
    const billingNextCycleString = showAutoRenewal
      ? intl.formatMessage(billingsStrings.nextCyclePro, {
          rateWithPeriodString,
          cancel_link: (msg) => (
            <a
              href={this.props.cancelLinkHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
          refund_doc_link: (msg) => (
            <a
              href={this.props.refundPolicyUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
          legal_requirement_link: (msg) => (
            <a
              href={this.props.legalRequirementLinkHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
        })
      : intl.formatMessage(billingsStrings.nextCycle, { rateString });
    const billingNextCycleWithCouponString = showAutoRenewal
      ? intl.formatMessage(billingsStrings.nextCycleProWithCoupon, {
          rateWithPeriodString,
          couponDurationWithPeriodString,
          fullRateWithPeriodString,
          cancel_link: (msg) => (
            <a
              href={this.props.cancelLinkHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
          refund_doc_link: (msg) => (
            <a
              href={this.props.refundPolicyUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
          legal_requirement_link: (msg) => (
            <a
              href={this.props.legalRequirementLinkHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
        })
      : intl.formatMessage(billingsStrings.nextCycleWithCoupon, {
          rateString,
          couponDurationWithPeriodString,
          fullRateWithPeriodString,
        });
    const billingAfterFreeTrialString = showAutoRenewal
      ? intl.formatMessage(billingsStrings.afterFreeTrialPro, {
          rateWithPeriodString,
          cancel_link: (msg) => (
            <a
              href={this.props.cancelLinkHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
          refund_doc_link: (msg) => (
            <a
              href={this.props.refundPolicyUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
          legal_requirement_link: (msg) => (
            <a
              href={this.props.legalRequirementLinkHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
        })
      : intl.formatMessage(billingsStrings.afterFreeTrial, { rateString });
    const billingAfterFreeTrialWithCouponString = showAutoRenewal
      ? intl.formatMessage(billingsStrings.afterFreeTrialProWithCoupon, {
          rateWithPeriodString,
          couponDurationWithPeriodString,
          fullRateWithPeriodString,
          cancel_link: (msg) => (
            <a
              href={this.props.cancelLinkHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
          refund_doc_link: (msg) => (
            <a
              href={this.props.refundPolicyUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
          legal_requirement_link: (msg) => (
            <a
              href={this.props.legalRequirementLinkHref}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </a>
          ),
        })
      : intl.formatMessage(billingsStrings.afterFreeTrialWithCoupon, {
          rateWithPeriodString,
          couponDurationWithPeriodString,
          fullRateWithPeriodString,
        });

    let downgradingToFree = false;
    if (!this.props.forceFreeTrial && this.props.isApi) {
      if (!this.props.isTrialing && this.props.proratio == 1) {
        billingString = this.props.couponCode
          ? billingTodayWithCouponString
          : billingTodayString;
      } else {
        billingString = intl.formatMessage(billingsStrings.prorate, {
          rateString,
          proratedRateString,
        });
      }
    } else if (
      !this.props.forceFreeTrial &&
      ((this.props.timePeriod == 'M' && this.props.monthlyExpectedRate <= 0) ||
        (this.props.timePeriod == 'Y' && this.props.annualExpectedRate <= 0))
    ) {
      billingString = (
        <span>
          <FormattedMessage
            id=""
            description="legal notice, informs user in case of a downgrade or canceling subscription all paid features will be unavailable, with exception already sign and loaded information. "
            defaultMessage="By downgrading to a free account, you will lose access to the following features:"
          />
          <br />
          <br />
          <ul style={{ listStyleType: 'disc', fontSize: '14px' }}>
            <li>
              <FormattedMessage
                id=""
                description="label, feature, list of features that user loses access to, allows use of templates"
                defaultMessage="Templates"
              />
            </li>
            <li>
              <FormattedMessage
                id=""
                description="label, feature, list of features that user loses access to, lets users send unlimited amount of documents"
                defaultMessage="Unlimited signature sends"
              />
            </li>
            <li>
              <FormattedMessage
                id=""
                description="label, feature, list of features that user loses access to, data validation"
                defaultMessage="Data validation"
              />
            </li>
          </ul>
          <FormattedMessage
            id=""
            description="legal notice, informs user what will happen in case of downgrading to free subscription"
            defaultMessage="If you choose to do so, you may continue to use the features on your current plan until {nextBillingDate}."
            values={{ nextBillingDate: this.props.nextBillingDate }}
          />
        </span>
      );
      downgradingToFree = true;
    } else if (this.props.isTrialing) {
      billingString = this.props.couponCode
        ? billingAfterFreeTrialWithCouponString
        : billingAfterFreeTrialString;
      if (this.props.isEnterprise) {
        // When in a UI trial and switching to an Enterprise UI we charge in full today
        billingString = `${this.props.couponCode ? billingTodayWithCouponString : billingTodayString}`;
      }
    } else if (
      (this.props.timePeriod == 'M' &&
        this.props.monthlyExpectedRate !== this.props.monthlyProratedRate) ||
      (this.props.timePeriod == 'Y' &&
        this.props.annualExpectedRate !== this.props.annualProratedRate)
    ) {
      // need to display prorated information
      const isDowngrade = this.checkIfDowngrade();
      if (isDowngrade) {
        billingString = this.props.couponCode
          ? billingNextCycleWithCouponString
          : billingNextCycleString;
      } else if (this.props.UiPendingCancel) {
        billingString = this.props.couponCode
          ? billingTodayWithCouponString
          : billingTodayString;
      } else if (showAutoRenewal) {
        billingString = intl.formatMessage(billingsStrings.chargePro, {
          proratedRateString,
          rateWithPeriodString,
          cancel_link: (...chunks) => (
            <a
              href={HfReactHelper.getFaqUrl(
                'articles/215348988-How-do-I-cancel-or-delete-my-account-',
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </a>
          ),
          refund_doc_link: (...chunks) => (
            <a
              href={this.props.refundPolicyUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </a>
          ),
          legal_requirement_link: (...chunks) => (
            <a
              href={HfReactHelper.getFaqUrl(
                'articles/115007654048-If-I-cancel-my-subscription-do-I-get-a-refund-',
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </a>
          ),
        });
      } else if (this.props.timePeriod == 'M') {
        billingString = intl.formatMessage(billingsStrings.chargeBaseMo, {
          proratedRateString,
          rateString,
        });
      } else {
        billingString = intl.formatMessage(billingsStrings.chargeBaseYr, {
          proratedRateString,
          rateString,
        });
      }
    } else if (!this.props.isFreeTrialEligible) {
      const isDowngrade = this.checkIfDowngrade();
      if (isDowngrade) {
        billingString = this.props.couponCode
          ? billingNextCycleWithCouponString
          : billingNextCycleString;
      } else {
        billingString = this.props.couponCode
          ? billingTodayWithCouponString
          : billingTodayString;
      }
    }

    if (HfReactHelper.isHelloFax()) {
      const {
        hasTeam,
        faxLinesCostInCents,
        newPageQuota,
        numPagesUsed,
        newNumFaxPages,
      } = this.props;
      if (hasTeam && faxLinesCostInCents !== null && faxLinesCostInCents > 0) {
        billingString += ` ${intl.formatMessage(billingsStrings.extraFaxLineCharge, { faxLinesCost: HfReactHelper.formatCurrency(intl, faxLinesCostInCents) })}`; // always usd
      }
      if (newPageQuota !== null && numPagesUsed !== null) {
        billingString += intl.formatMessage(
          billingsStrings.totalFaxPagesAfterUpgrade,
          { newNumFaxPages, newPageQuota, numPagesUsed },
        );
      }
    }

    if (!downgradingToFree && !showAutoRenewal) {
      const supportEmail =
        HfReactHelper.HfConstants.supportEmail[
          HfReactHelper.HfSites.SITE_CODE_HS
        ];
      billingString = (
        <span>
          {billingString}.
          <br />
          <br />
          {HfReactHelper.isHelloFax() ? (
            <FormattedMessage
              id=""
              description="legal notice agreeing to terms of service"
              defaultMessage="By signing up, I accept the <link_terms>{brandName} Terms</link_terms> and <link_refund_policy>Refund Policy</link_refund_policy>."
              values={{
                brandName: getBrandName('F'),
                link_terms: (...chunks) => (
                  <a
                    href={this.props.tosUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </a>
                ),
                link_refund_policy: (...chunks) => (
                  <a
                    href={this.props.refundPolicyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id=""
              description="legal notice, label, link to email support"
              defaultMessage="If you have any questions, you can reach our support team at {supportEmail}."
              values={{
                supportEmail: (
                  <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                ),
              }}
            />
          )}
        </span>
      );
    }
    if (showAutoRenewal) {
      billingString = (
        <span>
          {billingString}
          <br />
          <br />
          <label htmlFor="ack_auto_renewal_checkbox">
            <input
              type="checkbox"
              checked={this.state.renewalChecked}
              onChange={this.onRenewalCheck}
              id="ack_auto_renewal_checkbox"
              name="ack_auto_renewal"
            />{' '}
            <FormattedMessage
              id=""
              description="legal notice, checkbox text that ensures user has read privacy policy, usage terms and agrees to those."
              defaultMessage="I agree to the <link_terms>{brandName} Terms</link_terms>, <link_privacy_policy>Privacy Policy</link_privacy_policy>, and <link_refund_policy>Refund Policy</link_refund_policy>, and <b>Automatic Renewal Terms</b> above."
              values={{
                brandName: getBrandName('S'),
                link_terms: (...chunks) => (
                  <a
                    href={this.props.tosUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </a>
                ),
                link_privacy_policy: (...chunks) => (
                  <a
                    href={this.props.privacyPolicyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </a>
                ),
                link_refund_policy: (...chunks) => (
                  <a
                    href={this.props.refundPolicyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </a>
                ),
                b: (...chunks) => <strong>{chunks}</strong>,
              }}
            />
          </label>
        </span>
      );
    } else {
      billingString = (
        <span>
          {billingString}
          <br />
          <br />
          <FormattedMessage
            id=""
            description="legal notice, informs user that by continuing sign up process user has read privacy policy, usage terms and agrees to those."
            defaultMessage="By clicking continue, you agree to the <link_terms>{brandName} Terms</link_terms>, <link_privacy_policy>Privacy Policy</link_privacy_policy>, and <link_refund_policy>Refund Policy</link_refund_policy>."
            values={{
              brandName: getBrandName('S'),
              link_terms: (...chunks) => (
                <a
                  href={this.props.tosUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
              link_privacy_policy: (...chunks) => (
                <a
                  href={this.props.privacyPolicyUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
              link_refund_policy: (...chunks) => (
                <a
                  href={this.props.refundPolicyUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </span>
      );
    }

    if (!this.props.isAccountConfirmed) {
      billingString = (
        <span>
          {billingString}
          <br />
          <FormattedMessage
            id=""
            description="legal notice, informs user that if they do not confirm the email address their subscription downgraded at the end of the free trial."
            defaultMessage="If you do not verify your email address during your trial, your account will not be activated and we will downgrade you to free at the end of your trial. You will still have access to your data and your free account."
          />
        </span>
      );
    }
    return billingString;
  },

  renderHelloSign() {
    const { intl } = this.props;

    let headerText;
    if (this.props.headerText) {
      headerText = this.props.headerText;
    } else {
      headerText = intl.formatMessage(messages.startTrial, {
        planName: this.props.name,
      });
      const currentRate =
        this.props.timePeriod == 'Y'
          ? parseFloat(this.props.currentAnnualRate)
          : parseFloat(this.props.currentMonthlyRate);
      if (
        !this.props.isApi &&
        currentRate == 0.0 &&
        this.props.isFreeTrialEligible
      ) {
        headerText = intl.formatMessage(messages.startTrial, {
          planName: this.props.name,
        });
      } else if (this.props.isUpgrade || this.props.UiPendingCancel) {
        headerText = intl.formatMessage(messages.upgradePlan, {
          planName: this.props.name,
        });
      } else {
        headerText = intl.formatMessage(messages.downgradePlan, {
          planName: this.props.name,
        });
      }
    }

    const card = {
      name: this.props.cardHolderName,
      suffix: this.props.ccSuffix,
    };

    return (
      <PaymentForm
        key={`payment_form_${this.props.planId}`}
        appElement={this.props.appElement}
        errors={this.props.errors}
        fields={this.props.paymentForm.fields}
        values={this.props.paymentForm.values}
        namespace={this.props.paymentForm.namespace}
        stripeToken={this.state.stripe_token}
        dbxPaymentMethod={this.props.dbxPaymentMethod}
        planId={this.props.planId}
        planName={this.props.planName}
        couponCode={this.props.couponCode}
        couponNumMonths={this.props.couponNumMonths}
        timePeriod={this.props.timePeriod}
        tabOffset={this.props.tabOffset}
        card={card}
        buttonText={this.props.buttonText}
        headerText={headerText}
        billingString={this.generateBillingString()}
        ccExpiration={this.props.ccExpiration}
        numSeats={this.props.numSeats}
        formAction={this.props.formAction}
        currentPlanName={this.props.currentPlanName}
        isApi={Boolean(this.props.isApi)}
        name={this.props.name}
        renewalChecked={this.getRenewalCheck()}
        closeModal={this.props.closeModal}
        isModalOpen={this.props.isModalOpen}
        monthlyExpectedRate={this.props.monthlyExpectedRate}
      />
    );
  },

  renderHelloFax() {
    const { intl } = this.props;
    let headerText;
    if (this.props.headerText) {
      headerText = this.props.headerText;
    } else {
      headerText = intl.formatMessage(messages.startTrial, {
        planName: this.props.name,
      });
      const currentRate =
        this.props.timePeriod == 'Y'
          ? Number(this.props.currentAnnualRate)
          : Number(this.props.currentMonthlyRate);
      if (
        !this.props.isApi &&
        currentRate == 0 &&
        this.props.isFreeTrialEligible
      ) {
        headerText = intl.formatMessage(messages.startTrial, {
          planName: this.props.name,
        });
      } else if (this.props.isUpgrade || this.props.UiPendingCancel) {
        headerText = intl.formatMessage(messages.upgradePlan, {
          planName: this.props.name,
        });
      } else {
        headerText = intl.formatMessage(messages.downgradePlan, {
          planName: this.props.name,
        });
      }
    }

    const card = {
      name: this.props.cardHolderName,
      suffix: this.props.ccSuffix,
    };
    return (
      <PaymentForm
        key={`payment_form_${this.props.planId}`}
        appElement={this.props.appElement}
        fields={this.props.paymentForm.fields}
        values={this.props.paymentForm.values}
        namespace={this.props.paymentForm.namespace}
        paymentForm={this.props.paymentForm}
        registerFaxLineForm={this.props.registerFaxLineForm}
        errors={this.props.errors}
        csrfToken={this.props.csrfToken}
        stripeToken={this.state.stripe_token}
        dbxPaymentMethod={this.props.dbxPaymentMethod}
        planId={this.props.planId}
        planName={this.props.planName}
        couponCode={this.props.couponCode}
        couponNumMonths={this.props.couponNumMonths}
        timePeriod={this.props.timePeriod}
        tabOffset={this.props.tabOffset}
        card={card}
        buttonText={this.props.buttonText}
        headerText={headerText}
        billingString={this.generateBillingString()}
        ccExpiration={this.props.ccExpiration}
        numSeats={this.props.numSeats}
        formAction={this.props.formAction}
        currentPlanName={this.props.currentPlanName}
        isApi={Boolean(this.props.isApi)}
        name={this.props.name}
        renewalChecked={this.getRenewalCheck()}
        closeModal={this.props.closeModal}
        isModalOpen={this.props.isModalOpen}
        monthlyExpectedRate={this.props.monthlyExpectedRate}
      />
    );
  },

  render() {
    // HelloSign renders a modal with a payment form and other upgrade
    // details in react. This is the newer implementation which is preferred.

    // HelloFax falls back on an original implementation which uses an iframe.
    // We want to replace this at some point and it mostly works out of the box
    // but some of the messaging is HelloSign/HelloFax specific and hasn't been
    // reviewed.

    // An exception for this is if it's HelloFax and we're downgrading to a free plan
    // from a paid plan. The original HelloFax iframe doesn't support that functionality
    // because it used a different mechanism to downgrade to free accounts. But the new
    // HelloSign implementation it turns out works fine with HelloFax too so we'll use that.
    if (HfReactHelper.isHelloSign() || this.props.price === 0.0) {
      return this.renderHelloSign();
    } else {
      return this.renderHelloFax(); // Deprecated!
    }
  },
});

module.exports = injectIntl(PaymentModal);
