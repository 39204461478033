import React from 'react';

export function useOnMount(effect: Parameters<typeof React.useEffect>[0]) {
  React.useEffect(
    () => {
      return effect();
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [],
  );
}
