/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-extraneous-dependencies,
  max-len,
  react/jsx-no-duplicate-props,
  react/no-unescaped-entities,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FixedModal from 'common/components/fixed-modal';
import Button from 'common/components/button';
import CloseImageSrc from 'common/assets/modal-close@2x.png';
import './modal.scss';
import notificationIcon from 'common/images/info.svg';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import StyleProvider from 'hello-ui/style';
import { getBrandName } from 'js/sign-components/common/brand';
import classNames from 'classnames';
import styles from './modal.module.css';

export class UsageReportingModal extends React.Component {
  static defaultProps = {
    allowedOptions: ['compliance', 'usage'],
    rounded: true,
  };

  static propTypes = {
    allowedOptions: PropTypes.arrayOf(PropTypes.oneOf(['compliance', 'usage'])),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    rounded: PropTypes.bool,
    successful: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      values: {
        compliance: false,
        usage: false,
      },
    };
  }

  handleChange(option) {
    return () => {
      this.setState((previousState) => {
        return {
          ...previousState,
          values: {
            ...previousState.values,
            [option]: !previousState.values[option],
          },
        };
      });
    };
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.onSubmit(this.state.values);
  }

  isValid() {
    const { values } = this.state;

    return values.compliance || values.usage;
  }

  render() {
    const { allowedOptions, busy, isEnabled, onClose, rounded } = this.props;

    const { values } = this.state;
    const valid = this.isValid();

    const modalStyle = { borderRadius: rounded ? 5 : 0 };
    const usageAllowed = allowedOptions.includes('usage');
    const complianceDisabled =
      !isEnabled || !allowedOptions.includes('compliance') || busy;
    const usageDisabled = !isEnabled || !usageAllowed || busy;
    const submitDisabled = !isEnabled || !valid;

    const pricingUrl = HfReactHelper.urlHelper(
      'info/pricing?contact_sales=1',
      HfReactHelper.HfSites.SITE_CODE_HS,
    );

    return (
      <StyleProvider>
        <FixedModal width={600} modalStyle={modalStyle}>
          <div className="team-reporting-modal-close-button" onClick={onClose}>
            <img src={CloseImageSrc} />
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className={styles.header}>
              <p className={styles.p}>
                <FormattedMessage
                  id=""
                  defaultMessage="Reporting: Select Report"
                  description="Team Page, header on modal when click on download report"
                />
              </p>

              <div className={styles.p3}>
                <div className={styles.mb4}>
                  <input
                    type="checkbox"
                    value="compliance"
                    name="compliance"
                    onChange={this.handleChange('compliance')}
                    checked={values.compliance}
                    id="teams-report-compliance"
                    disabled={complianceDisabled}
                  />
                  <label
                    htmlFor="teams-report-compliance"
                    className={classNames(styles.label, {
                      [styles.disabled]: complianceDisabled,
                    })}
                  >
                    <FormattedMessage
                      id=""
                      defaultMessage="Compliance"
                      description="Team Page, header on modal when click on download report"
                    />
                  </label>
                  <p
                    className={classNames({
                      [styles.disabled]: complianceDisabled,
                    })}
                  >
                    <FormattedMessage
                      id=""
                      defaultMessage="The compliance report contains per-user account status and last login date."
                      description="Team Page, on modal when click on download report"
                    />
                  </p>
                </div>

                <div>
                  <input
                    type="checkbox"
                    value="usage"
                    name="usage"
                    checked={values.usage}
                    onChange={this.handleChange('usage')}
                    id="teams-report-usage"
                    disabled={usageDisabled}
                  />
                  <label
                    className={classNames(styles.label, {
                      [styles.disabled]: usageDisabled,
                    })}
                    htmlFor="teams-report-usage"
                  >
                    <FormattedMessage
                      id=""
                      defaultMessage="Usage"
                      description="Team Page, how many template usage, on modal when click on download report"
                    />
                  </label>
                  <p
                    className={classNames({
                      [styles.disabled]: usageDisabled,
                    })}
                  >
                    <FormattedMessage
                      id=""
                      defaultMessage="The usage report includes per-user templates owned, requests sent over the last 6 months, and signed documents over the last 6 months."
                      description="Team Page, on modal when click on download report"
                    />
                  </p>
                </div>
              </div>

              {isEnabled && !usageAllowed && (
                <div className={classNames(styles.my2, styles.px3)}>
                  <div className={styles.flex}>
                    <img
                      src={notificationIcon}
                      className={classNames(
                        styles.infoIcon,
                        'team-reporting-modal__info-icon',
                      )}
                    />
                    <p>
                      <FormattedMessage
                        id=""
                        defaultMessage="Usage report was generated within the last hour. Please try again later."
                        description="Team Page, on modal when click on download report"
                      />
                    </p>
                  </div>
                </div>
              )}

              {!isEnabled && (
                <div className={classNames(styles.my2, styles.px3)}>
                  <div className={classNames(styles.flex, styles.end)}>
                    <img
                      src={notificationIcon}
                      className={classNames(
                        styles.infoIcon,
                        'team-reporting-modal__info-icon',
                      )}
                    />
                    <p>
                      <FormattedMessage
                        id=""
                        defaultMessage="This feature isn't available on your current plan. <br /> Please <a>contact our Sales team</a> for more information about this and other {brandName} Enterprise features."
                        description="Team Page, on modal when click on download report"
                        values={{
                          brandName: getBrandName('S'),
                          a: (...chunks) => <a href={pricingUrl}>{chunks}</a>,
                        }}
                      />
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className={classNames(styles.p3, 'team-reporting-modal-bar')}>
              <div className={classNames(styles.flex, styles.end)}>
                <a className={styles.closeLink} onClick={onClose}>
                  <FormattedMessage
                    id=""
                    defaultMessage="Cancel"
                    description="Team Page, text on button on modal when click on download report"
                  />
                </a>

                <Button
                  buttonColor="cerulean"
                  buttonText={
                    busy ? (
                      <FormattedMessage
                        id=""
                        defaultMessage="Submitting..."
                        description="Team Page, text on button on modal when click on download report"
                      />
                    ) : (
                      <FormattedMessage
                        id=""
                        defaultMessage="Submit"
                        description="Team Page, text on button on modal when click on download report"
                      />
                    )
                  }
                  disabled={submitDisabled}
                  type="submit"
                />
              </div>
            </div>
          </form>
        </FixedModal>
      </StyleProvider>
    );
  }
}

export default UsageReportingModal;
