/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable @typescript-eslint/no-use-before-define */
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import HSIntlProvider from 'hellospa/common/hs-intl-provider';
import pushAnalyticsEvent from 'common/utils/pushAnalyticsEvent';
import CreateAccount from 'hellosign/components/auth/create-account.jsx';
import { getTrackingVariant } from 'common/utils/getTrackingVariant';
import PricingLevelNew from '../pricing-level-new';
import CorporatePricingLevelNew from '../corporate-pricing-level-new';
import Arrow from '../arrow';

class PricingLevels extends React.Component {
  static propTypes = {
    billingPlans: PropTypes.array.isRequired,
    paymentForm: PropTypes.object,
    countryCodeFromIp: PropTypes.string,
    signupForm: PropTypes.object.isRequired, // Serialized sfForm
    csrfToken: PropTypes.string,
    signupUrl: PropTypes.string,
    tosUrl: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,
    googleSignInClientId: PropTypes.string,
    trialPeriod: PropTypes.string,
  };

  state = {
    currentRowIndex: 0,
    corporateFeatures: {},
  };

  shouldBeCarousel = () => this.props.billingPlans.length;

  shouldRenderLevel = (level) => {
    return level && level.name && level.is_displayed === 'true';
  };

  validBillingPlans = () =>
    this.props.billingPlans.filter(this.shouldRenderLevel);

  rows = () => {
    const maxLength = HfReactHelper.isHelloSign() ? 3 : 4;

    return this.validBillingPlans()
      .map(pricingLevel.bind(this, this.props))
      .reduce((previousValue, currentValue) => {
        const lastRow = previousValue[previousValue.length - 1];
        if (lastRow && lastRow.length < maxLength) {
          lastRow.push(currentValue);
          previousValue[previousValue.lenth - 1] = lastRow;
          return previousValue;
        } else {
          previousValue.push([currentValue]);
          return previousValue;
        }
      }, [])
      .map((row, index) => {
        return (
          <div className="row" key={index}>
            {row}
          </div>
        );
      });
  };

  handleClick = () => {
    const nextIndex = (this.state.currentRowIndex + 1) % this.rows().length;
    this.setState({ currentRowIndex: nextIndex });
  };

  handleTryOurFreePlan = () => {
    let redirectUrl = null;
    const freePlanProps = this.props.billingPlans
      .filter((level) => {
        return level.name === 'Free';
      })
      .map(pricingLevel.bind(this, this.props))[0];
    const attachPoint = HfReactHelper.getRevealAttachPoint(
      'create_account_modal',
      undefined,
      undefined,
      undefined,
      'close-modal-left-side',
    );
    const { planId, timePeriod, couponCode } = freePlanProps.props;
    if (typeof redirectUrl !== 'string') {
      redirectUrl =
        freePlanProps.props.timePeriod === 'M'
          ? freePlanProps.props.monthlyRedirect
          : freePlanProps.props.annualRedirect;

      if (freePlanProps.props.monthlyExpectedRate > 0) {
        redirectUrl = `info/payment?plan_id=${planId}&period=${timePeriod}`;
        if (couponCode) {
          redirectUrl += `&coupon_code=${couponCode}`;
        }
      }
    }

    pushAnalyticsEvent({
      category: 'signup',
      action: `create account start - ${getTrackingVariant()}`,
      label: 'free',
    });

    /**
     * This function used to spin up a whole new React application in order to
     * host this modal by way of React.render()
     *
     * When you spin up a new app you lose access to all context:
     * - No localization
     * - No feature flags
     * - This becomes an independent application running in the same browser
     *
     * The way this was functioning ReactDOM.render() is called exactly once
     * with whatever the data was when they clicked the button. In order to
     * avoid introducing new bugs by having this modal render and re-render with
     * updated data, I'm just going to store the DECLARATION of what should be
     * in the modal.
     */
    const modalPortalHack = ReactDOM.createPortal(
      <HSIntlProvider>
        <CreateAccount
          key={freePlanProps.props.planId}
          planId={freePlanProps.props.planId}
          isApi={freePlanProps.props.isApi}
          planName={freePlanProps.props.rawName}
          skipCC={freePlanProps.props.skipCC}
          period={freePlanProps.props.timePeriod}
          trialPeriod={this.props.trialPeriod}
          googleSignInClientId={this.props.googleSignInClientId}
          csrfToken={this.props.csrfToken}
          errorMsg={freePlanProps.props.acctModalError}
          header="Let's get started"
          buttonText="Continue"
          isFromApiPricing={freePlanProps.props.isApi}
          isFromPricing={!freePlanProps.props.isApi}
          redirectUrl={redirectUrl}
          tabOffset={1000}
          signupUrl={this.props.signupUrl}
          tosUrl={this.props.tosUrl}
          googleAnalyticsTrackingVariant="pricing_page"
          privacyPolicyUrl={this.props.privacyPolicyUrl}
          countryCodeFromIp={this.props.countryCodeFromIp}
          signupForm={this.props.signupForm}
        />
      </HSIntlProvider>,
      attachPoint,
    );
    this.setState({ modalPortalHack });
  };

  shouldRenderArrow = () => this.rows().length > 1;

  render() {
    const currentRow = this.rows()[this.state.currentRowIndex];
    const animationDirection =
      this.state.currentRowIndex === 1 ? 'left' : 'right';
    const arrow = (
      <Arrow handleClick={this.handleClick} direction={animationDirection} />
    );
    const showCorporatePricing = HfReactHelper.isHelloSign();
    const totalColumns = this.validBillingPlans().length;
    const className = classNames({
      'm-pricing-level-new': true,
      'large-12': !showCorporatePricing && totalColumns >= 3,
      'large-9': showCorporatePricing && totalColumns >= 3,
      'large-8': totalColumns === 2,
      'large-6': totalColumns === 1,
      columns: true,
      [`pricing-animation-${animationDirection}`]: true,
    });
    const wrapperStyle = {
      overflow: 'hidden',
      position: 'relative',
      paddingTop: 60,
    };
    return (
      <div>
        {this.state?.modalPortalHack}
        <div className={className} style={wrapperStyle}>
          {currentRow}
        </div>
        {this.shouldRenderArrow() ? arrow : null}
        {showCorporatePricing
          ? corporatePricingLevel(this.props, totalColumns)
          : null}
      </div>
    );
  }
}

function pricingLevel(props, level, i) {
  // TODO:  Refactor PricingLevel so all you need to is pass "level" to
  // "PricingLevel" and let "PricingLevel" figure out all these props. In
  // other words, don't tell "PricingLevel" what it needs. "PricingLevel"
  // should be the one that know's what it needs. (FR)
  const pricingLevelProps = {
    key: i,
    totalColumns: props.billingPlans.length,
    signupUrl: props.signupUrl,
    cardHolderName: props.cardHolderName,
    errors: props.errors,
    paymentForm: props.paymentForm,
    registerFaxLineForm: props.registerFaxLineForm,
    dbxPaymentMethod: props.dbxPaymentMethod,
    signupForm: props.signupForm,
    mustContactSales: props.mustContactSales,
    changePlanRestricted: props.changePlanRestricted,
    isAdmin: props.isAdmin,
    isDelinquent: props.isDelinquent,
    timePeriod: props.timePeriod,
    skipCC: props.skipCC,
    trialPeriod: props.trialPeriod,
    name: level.name,
    rawName: level.raw_name,
    isApi: level.is_api,
    isUpgrade: level.is_upgrade,
    mainFeature: level.main_feature,
    features: level.features,
    monthlyPrice: level.rate_monthly,
    annualPrice: level.rate_annual,
    formattedMonthlyPrice: level.formatted_rate_monthly,
    formattedAnnualPrice: level.formatted_rate_annual,
    formattedAnnualPricePerMonth: level.formatted_rate_annual_per_month,
    monthlyCouponPrice: level.coupon_monthly_price,
    annualCouponPrice: level.coupon_annual_price,
    formattedMonthlyCouponPrice: level.formatted_coupon_monthly_price,
    formattedAnnualCouponPrice: level.formatted_coupon_annual_price,
    formattedAnnualCouponPricePerMonth:
      level.formatted_coupon_annual_price_per_month,
    price: props.isMonthly ? level.rate_monthly : level.rate_annual,
    monthlyExpectedRate: level.monthly_expected_rate,
    annualExpectedRate: level.annual_expected_rate,
    formattedMonthlyExpectedRate: level.formatted_monthly_expected_rate,
    formattedAnnualExpectedRate: level.formatted_annual_expected_rate,
    monthlyProratedRate: level.monthly_prorated_rate,
    annualProratedRate: level.annual_prorated_rate,
    formattedMonthlyProratedRate: level.formatted_monthly_prorated_rate,
    formattedAnnualProratedRate: level.formatted_annual_prorated_rate,
    minSeats: level.num_seats_min,
    maxSeats: level.num_seats_max,
    mostPopular: level.is_api && i === 1,
    coupon: props.hasValidCoupon,
    couponEligible: level.coupon,
    couponCode: props.hasValidCoupon ? level.coupon_code : null,
    couponNumMonths: props.hasValidCoupon ? props.coupon.num_months : null,
    isFreeTrialEligible: level.is_free_trial_eligible,
    numFreeDays: level.num_free_days,
    isTrialing: level.is_trialing,
    proratio: level.proratio,
    planId: level.plan_id,
    isDisplayed: level.is_displayed,
    monthlyRedirect: level.monthly_redirect,
    annualRedirect: level.annual_redirect,
    ccSuffix: level.cc_suffix,
    ccExpiration: level.cc_expiration,
    isCurrentPlan: level.current_plan === level.plan_id,
    ApiPendingCancel: level.api_pending_cancel,
    UiPendingCancel: level.ui_pending_cancel,
    currentMonthlyRate: level.current_monthly_rate,
    currentAnnualRate: level.current_annual_rate,
    currentPlanName: props.pricingData.current_plan_name,
    isLoggedIn: level.is_logged_in,
    acctModalError: level.acct_modal_error,
    wasSelectedBeforeLogIn:
      level.is_logged_in && level.was_selected_before_login,
    tosUrl: props.tosUrl,
    privacyPolicyUrl: props.privacyPolicyUrl,
    refundPolicyUrl: props.refundPolicyUrl,
    csrfToken: props.csrfToken,
    googleSignInClientId: props.googleSignInClientId,
    ackAutoRenewal: props.ackAutoRenewal,
    showHSAutoRenewal: props.showHSAutoRenewal,
    isAccountConfirmed: props.isAccountConfirmed,
    legalRequirementLinkHref: props.legalRequirementLinkHref,
    cancelLinkHref: props.cancelLinkHref,
    nextBillingDate: props.nextBillingDate,
    newNumFaxPages: level.new_num_fax_pages,
    newPageQuota: level.new_page_quota,
    numPagesUsed: level.num_pages_used,
    faxLinesCostInCents: level.fax_lines_cost_in_cents,
    hasTeam: level.has_team,
    pricingSource: props.pricingSource,
    hasCurrentPlan: props.pricingData.has_current_plan,
    countryCodeFromIp: props.countryCodeFromIp,
    isTeamPlan: level.is_team_plan,
    arkoseSignUpEnabled: props.arkoseSignUpEnabled,
    arkoseSignUpPublicKey: props.arkoseSignUpPublicKey,
    isRecommended: level.is_recommended,
    currencyCode: props.pricingData.currency_code,
    currencySymbol: props.pricingData.currency_symbol,
  };

  return (
    <PricingLevelNew
      {...pricingLevelProps}
      tier={level.tier}
      isApi={props.isApi}
      // eslint-disable-next-line max-len
      isCurrentPlan={
        HfReactHelper.isHelloSign()
          ? level.is_current_tier
          : level.current_plan === level.plan_id
      }
      minSeats={level.tier_num_seats_min}
      // eslint-disable-next-line max-len
      maxSeats={
        level.tier_num_seats_max ||
        (level.plans &&
          level.plans.length &&
          level.plans[level.plans.length - 1].size)
      }
      canDirectPurchase={props.canDirectPurchase}
    />
  );
}

function corporatePricingLevel(props, totalColumns) {
  const billingPlan = props.pricingData.billing_plans.filter(
    (plan) => plan.is_enterprise,
  )[0];
  const corporateLevelProps = {
    key: 'corporate',
    isApi: props.isApi,
    pricingSource: props.pricingSource,
    coupon: props.coupon,
    corporateFeatures: props.corporateFeatures || {},
    timePeriod: props.timePeriod,
    totalColumns,
    corpLevelName: props.corpLevelName,
    changePlanRestricted: props.changePlanRestricted,
    canDirectPurchase: props.canDirectPurchase,
    isCurrentPlan: billingPlan.is_current_tier,
    // eslint-disable-next-line max-len
    minSize: !props.isApi && billingPlan.tier_num_seats_min,
  };

  return <CorporatePricingLevelNew {...corporateLevelProps} />;
}

export default injectIntl(PricingLevels);
