/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len
*/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import AdjustScrollingMixin from './adjust-scrolling-mixin';
import ApiMenuSectionSubItem from './section-sub-item';

const ApiMenuSectionItem = createReactClass({
  mixins: [AdjustScrollingMixin],

  propTypes: {
    title: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    subItems: PropTypes.array,
    initiallyActiveSubItem: PropTypes.string,
    isInitiallyActive: PropTypes.bool,
    isInitiallyExpanded: PropTypes.bool,
  },

  getInitialState() {
    return {
      isActive: this.props.isInitiallyActive,
      isExpanded:
        this.props.isInitiallyExpanded || this.props.isInitiallyActive,
      activeSubItem: this.props.initiallyActiveSubItem,
    };
  },

  componentWillReceiveProps(newProps) {
    this.setState({
      isActive: newProps.isInitiallyActive,
      isExpanded: newProps.isInitiallyExpanded,
      activeSubItem: newProps.initiallyActiveSubItem,
    });
  },

  componentDidUpdate() {
    this.adjustScrolling();
  },

  expand() {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  },

  render() {
    const arrow = this.props.subItems ? (
      <span className="l-api-docs-menu--arrow" onClick={this.expand}></span>
    ) : null;

    const classes = classNames({
      'l-api-docs-menu--items--item': true,
      'is-active': this.state.isActive,
      'is-expanded': this.state.isActive || this.state.isExpanded,
      'is-expandable': this.props.subItems && this.props.subItems.length > 0,
    });

    const subItems = this.props.subItems
      ? this.props.subItems.map((subItem) => {
          const isActive =
            this.state.activeSubItem != null &&
            this.state.activeSubItem.toLowerCase() ==
              subItem.reference.toLowerCase();

          return (
            <ApiMenuSectionSubItem
              key={`sub_item_${subItem.reference}`}
              title={subItem.title}
              reference={subItem.reference}
              url={subItem.url}
              isInitiallyActive={isActive}
            ></ApiMenuSectionSubItem>
          );
        })
      : null;

    return (
      <div className={classes} data-ref={this.props.reference}>
        {arrow}
        <a href={this.props.url}>{this.props.title}</a>
        {subItems && subItems.length ? (
          <div className="l-api-docs-menu--sub-items">{subItems}</div>
        ) : null}
      </div>
    );
  },
});

module.exports = ApiMenuSectionItem;
