import React from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Modal, ModalProps } from '@dropbox/dig-components/modal';
import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { TextArea, TextInput } from '@dropbox/dig-components/text_fields';
import { BatonPassHero } from '@dropbox/dig-illustrations/dist/cjs/hero/baton-pass';
import Spacer, { SpacerSize } from 'hellospa/foundation/spacing/spacer';
import { useClipboard } from 'js/sign-components/common/hooks';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { getBrandName } from 'js/sign-components/common/brand';

import CopyText from './copy-text';

const messages = defineMessages({
  socialSharing: {
    id: '',
    description:
      'Social sharing text for Facebook or Twitter including a referal URL.',
    defaultMessage:
      "Join {productName} and get 1 extra document free. It's the easiest way to sign and send documents. {referUrl}",
  },
  linkCopied: {
    id: '',
    description:
      'Text in a popup informing the user the link was successfully copied to their clipboard.',
    defaultMessage: 'Link copied',
  },
  textCopied: {
    id: '',
    description:
      'Text in a popup informing the user the text was successfully copied to their clipboard.',
    defaultMessage: 'Text copied',
  },
});

type ReferModalProps = ModalProps & {
  referUrl: string;
};

function ReferModal({ onRequestClose, referUrl, ...props }: ReferModalProps) {
  const intl = useIntl();
  const [isClipboardSupported] = useClipboard();

  const socialText = intl.formatMessage(messages.socialSharing, {
    productName: getBrandName('S'),
    referUrl,
  });

  const selectAll = (
    evt: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    evt.currentTarget.select();
  };

  return (
    <Modal
      aria-labelledby="refer-modal-title"
      isCentered
      onRequestClose={onRequestClose}
      {...props}
    >
      {/* This content is portaled and requires new backdrop theme context */}
      <>
        <BatonPassHero aspectRatio="16:9" />
        <Spacer top={SpacerSize.XL} />
        <Modal.Body>
          <Modal.Title id="refer-modal-title">
            <FormattedMessage
              id=""
              description="Title text for a modal which allows users to share an invite code with a friend to get free signature requests. 'Freebies' is a jocular way of saying 'free stuff' in American English."
              defaultMessage="Get freebies when friends join"
            />
          </Modal.Title>
          <Text>
            <FormattedMessage
              id=""
              description="text upselling bulk send when a non Enterprise Plus customer clicks on bulk send"
              defaultMessage="For every friend who joins {productName}, we'll give you both 1 free document (up to 500 documents)."
              values={{
                productName: getBrandName('S'),
              }}
            />
          </Text>
          <Spacer top={SpacerSize.LG} />
          <Text isBold variant="label">
            <FormattedMessage
              id=""
              description="Text next to a referral link and a 'copy link' button."
              defaultMessage="Share your invite link"
            />
          </Text>
          <TextInput
            data-qa-ref="refer-modal-referral-link"
            value={referUrl}
            onChange={() => {}} // Don't allow field to change
            onClick={selectAll}
          />
          {isClipboardSupported && (
            <Spacer top={SpacerSize.XS}>
              <CopyText
                textCopiedMessage={intl.formatMessage(messages.linkCopied)}
                textToCopy={referUrl}
                data-qa-ref="refer-modal-copy-link"
              >
                <FormattedMessage
                  id=""
                  description="Text for a button which copies the link in a field when pressed."
                  defaultMessage="Copy link"
                />
              </CopyText>
            </Spacer>
          )}
          <Spacer top={SpacerSize.LG} />
          <Text isBold variant="label">
            <FormattedMessage
              id=""
              description="Text next to a referral link and a 'copy link' button."
              defaultMessage="Share to Facebook or Twitter"
            />
          </Text>
          <TextArea
            data-qa-ref="refer-modal-social-text"
            rows={4}
            onClick={selectAll}
            onChange={() => {}} // Don't allow field to change.
            value={socialText}
          />
          {isClipboardSupported && (
            <Spacer top={SpacerSize.XS}>
              <CopyText
                textCopiedMessage={intl.formatMessage(messages.textCopied)}
                textToCopy={socialText}
                data-qa-ref="refer-modal-copy-text"
              >
                <FormattedMessage
                  id=""
                  description="Text for a button which copies the text in a field when pressed."
                  defaultMessage="Copy text"
                />
              </CopyText>
            </Spacer>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-qa-ref="refer-modal-view-plans-button"
            variant="opacity"
            href={HfReactHelper.urlHelper(
              'info/pricing',
              HfReactHelper.HfSites.SITE_CODE_HS,
            )}
          >
            <FormattedMessage
              id=""
              description="Text for a button which brings the user to the Plans and Pricing page when pressed."
              defaultMessage="View more plans"
            />
          </Button>
          <Button
            data-qa-ref="refer-modal-close-button"
            variant="primary"
            onClick={onRequestClose}
          >
            <FormattedMessage
              id=""
              description="Text for a buton which closes a modal when pressed."
              defaultMessage="Done"
            />
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
}

export default ReferModal;
