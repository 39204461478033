import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button as DigButton } from '@dropbox/dig-components/buttons';
import { ApiSettingsSection } from './shared';
import { CallbackTester } from './callback-tester';

/**
 * This is a new version of the callback URL section that's using the new
 * callback tester implementation. This component still assumes that it's
 * rendered within a
 */
export function CallbackUrlSection(props: {
  url: string;
  title: React.ReactNode;
  description: React.ReactNode;

  /**
   * The identifier for the field under the `edit_account` namespace within the
   * parent form on the page.
   */
  fieldName: string;
}) {
  const { url, title, description, fieldName } = props;
  const [inputValue, setInputValue] = useState(url);
  const hasEdited = url !== inputValue;

  // Change the variant if the input value has been edited to prompt the user
  // to save
  const btnVariant = hasEdited ? 'primary' : 'outline';
  const titleId = `callback-${fieldName}-label`;

  return (
    <ApiSettingsSection
      title={<span id={titleId}>{title}</span>}
      description={description}
      action={
        /* This button submits the form which is rendered with PHP */
        <DigButton
          variant={btnVariant}
          data-qa-ref="account-callback-save-button"
          type="submit"
        >
          <FormattedMessage
            id=""
            defaultMessage="Save"
            description="button, setting page, when clicked it will save the callback url the customer entered"
          />
        </DigButton>
      }
    >
      <CallbackTester
        value={inputValue}
        onValueChange={setInputValue}
        aria-labelledby={titleId}
        name={`edit_account[${fieldName}]`}
        successAction={
          hasEdited ? (
            <DigButton variant="transparent" type="submit">
              Save
            </DigButton>
          ) : null
        }
      />
    </ApiSettingsSection>
  );
}
