/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  import/no-named-as-default,
  max-len,
  react/prop-types
*/
import './index.scss';
import _ from 'lodash';
import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Button from 'common/components/button';
import DisclosureModal from 'ui/disclosure-modal';

const messages = defineMessages({
  previewDisclosureButton: {
    id: '', // IDs are procedurally generated
    description:
      'button text in setting page, when clicked opens the modal with disclosure on it',
    defaultMessage: 'PREVIEW DISCLOSURE',
  },
});

const EsignDisclosurePreview = createReactClass({
  propTypes: {
    businessNameField: PropTypes.string,
    additionalTextEnabled: PropTypes.bool,
    additionalTextField: PropTypes.string,
    headerTemplate: PropTypes.string,
    textTemplate: PropTypes.string,
    additionalTextJoin: PropTypes.string,
    downloadUrl: PropTypes.string,
    buttonEnabled: PropTypes.bool,
    showDownloadButton: PropTypes.bool,
    showPrintButton: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      showDownloadButton: false,
      showPrintButton: true,
    };
  },

  getInitialState() {
    return {
      showDisclosureModal: false,
    };
  },

  /**
   * Show the disclosure modal
   */
  showDisclosureModal() {
    this.setState({
      showDisclosureModal: true,
    });
  },

  /**
   * Close the disclosure modal
   */
  closeDisclosureModal(evt) {
    evt.preventDefault();
    this.setState({
      showDisclosureModal: false,
    });
  },

  /**
   * Download disclosure text
   */
  downloadDisclosureText() {
    window.location = this.props.downloadUrl;
  },

  render() {
    let disclosureModal;
    if (this.state.showDisclosureModal) {
      disclosureModal = this.renderDisclosureModal();
    }

    return (
      <div className="m-esign-disclosure-preview">
        <Button
          className={this.props.buttonClassName}
          buttonColor={this.props.buttonColor || 'white'}
          buttonText={
            this.props.buttonText ||
            this.props.intl.formatMessage(messages.previewDisclosureButton)
          }
          buttonTextColor={this.props.buttonTextColor || 'cerulean'}
          buttonHoverColor={this.props.buttonHoverColor || 'cerulean'}
          buttonHoverTextColor={this.props.buttonHoverTextColor || 'white'}
          buttonBorderColor={this.props.buttonBorderColor || 'cerulean'}
          onClick={this.showDisclosureModal}
          disabled={!this.props.buttonEnabled}
        />
        {disclosureModal}
      </div>
    );
  },

  renderDisclosureModal() {
    const replaceExp = /\$BUSINESS_NAME\$/g;
    const businessName = _.escape($(this.props.businessNameField).val()); // escape the business name
    const header = _.unescape(
      this.props.headerTemplate.replace(replaceExp, businessName),
    );
    let text = _.unescape(this.props.textTemplate).replace(
      replaceExp,
      businessName,
    );

    const additionalText = document.querySelector(
      this.props.additionalTextField,
    );
    if (this.props.additionalTextEnabled) {
      text += `${_.unescape(this.props.additionalTextJoin)}<p>${_.escape(additionalText.value).replace(/(\r\n|\r|\n)/g, '<br/>')}</p>`;
    }

    return (
      <DisclosureModal
        {...this.props}
        header={header}
        content={text}
        onSubmit={this.closeDisclosureModal}
        onClose={this.closeDisclosureModal}
        onDownload={this.downloadDisclosureText}
        showDownloadButton={this.props.showDownloadButton}
        showPrintButton={this.props.showPrintButton}
      />
    );
  },
});

module.exports = injectIntl(EsignDisclosurePreview);
