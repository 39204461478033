/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-extraneous-dependencies
*/
import Button from 'common/components/button';
import React from 'react';

export default function CancelOfferPricingLevel(p) {
  const stayWithUsText = (
    <p>Stay with us and enjoy an exclusive annual discount:</p>
  );

  const previousPrice = (
    <div className="previous-price">
      <span className="price">{p.offerPlanInfo.formattedOriginalPrice}</span>
    </div>
  );

  const largePrice = (
    <div className="large-price">
      <span className="price">{p.offerPlanInfo.formattedDiscountedPrice}</span>
    </div>
  );

  const selectOfferButton = (
    <Button
      data-testid="select-offer-btn"
      buttonText="Select Offer"
      buttonColor="cerulean"
      buttonClass="select-offer-button"
      buttonLink="/account/acceptCustomerCancelOffer"
    />
  );

  const or = <div className="round-or">OR</div>;

  const continueToCancelButton = (
    <Button
      data-testid="cancel-offer-btn"
      buttonText="No thank you, cancel my plan"
      buttonColor="plume"
      buttonBorderColor="cerulean"
      buttonTextColor="cerulean"
      buttonClass="continue-to-cancel-button"
      buttonLink="/account/declineCustomerCancelOffer"
    />
  );

  return (
    <div className="m-cancel-offer-pricing-level text-center">
      <div className="m-cancel-offer-pricing-level--offer">
        {stayWithUsText}
        {previousPrice}
        {largePrice}
        {selectOfferButton}
        {or}
        {continueToCancelButton}
      </div>
    </div>
  );
}
