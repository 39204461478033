import React from 'react';
import { NotificationBannerType } from '../notification-banner';
import { Banner } from '@dropbox/dig-components/banner';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { getDevelopersSiteUrl } from 'js/sign-components/common/brand';

type APIReferenceDocProps = {
  interactiveDocUrl: string;
};

const messages = defineMessages({
  close: {
    id: '',
    description: 'Aria label for the close button on a banner',
    defaultMessage: 'Close',
  },
});

// Currently, it contains only warning on old reference doc page.
// But this file can be re-used and refactored in the future when re-design work takes place.
function APIReferenceDoc({ interactiveDocUrl }: APIReferenceDocProps) {
  const intl = useIntl();
  const [open, setOpen] = React.useState(true);
  const close = () => setOpen(false);
  if (!open) {
    return null;
  }
  return (
    <Banner
      data-testid="try-our-endpoints-banner"
      variant="basic"
      type={NotificationBannerType.Warning}
      withLeftIcon={false}
      withCloseButton={intl.formatMessage(messages.close)}
      onRequestClose={close}
    >
      <Banner.Message>
        <FormattedMessage
          id=""
          description="Notification message for new interactive open api documentation"
          defaultMessage="Try our endpoints without writing any code using our new docs: {interactiveDocUrl}"
          values={{
            interactiveDocUrl: (
              <a
                href={interactiveDocUrl}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                {getDevelopersSiteUrl()}
              </a>
            ),
          }}
        />
      </Banner.Message>
    </Banner>
  );
}

export default APIReferenceDoc;
