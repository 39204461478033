import React, { Dispatch, SetStateAction } from 'react';
import { Modal } from '@dropbox/dig-components/modal';
import { Button } from '@dropbox/dig-components/buttons';
import { FormattedMessage } from 'react-intl';
import styles from './billboard.module.scss';
import { modalTypes } from '../constants';
import BillboardContent from './billboard-content';
import { BowlEmptyHero } from '@dropbox/dig-illustrations/dist/cjs/hero/bowl-empty';
import { PauseCancelModalContext, SafeDowngradeStatusEnum } from '../types';

type BillboardProps = {
  setModalType: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<string | null>>;
  handleCloseModal: () => void;
  modalContext: PauseCancelModalContext;
  currentStatus: SafeDowngradeStatusEnum | null;
};

const Billboard = ({
  setModalType,
  handleCloseModal,
  modalContext,
  currentStatus,
}: BillboardProps) => {
  const { planName, freePlanName } = modalContext;

  // The user may confirm cancellation on the survey modal
  const handleOpenContinueCancelModal = () => {
    setModalType(modalTypes.SURVEY);
  };

  const handleOpenPauseModal = () => {
    setModalType(modalTypes.PAUSE);
  };

  return (
    <div>
      <div className={styles.billboardImage}>
        <BowlEmptyHero aspectRatio="16:9" />
      </div>
      <BillboardContent planName={planName} freePlanName={freePlanName} />
      <Modal.Footer>
        <Button
          variant="transparent"
          onClick={handleCloseModal}
          data-qa-ref="billboard-close-button"
        >
          <FormattedMessage
            id=""
            description="button, close modal button text"
            defaultMessage="Close"
          />
        </Button>
        {/* Only show pause option if not already paused */}
        {currentStatus !== SafeDowngradeStatusEnum.PAUSED ? (
          <Button
            variant="outline"
            onClick={handleOpenPauseModal}
            data-qa-ref="billboard-pause-button"
          >
            <FormattedMessage
              id=""
              description="button, modal CTA to pause plan instead"
              defaultMessage="Pause plan instead"
            />
          </Button>
        ) : (
          <div />
        )}
        <Button
          variant="outline"
          onClick={handleOpenContinueCancelModal}
          data-qa-ref="billboard-continue-cancellation-button"
        >
          <FormattedMessage
            id=""
            description="button, modal CTA to continue cancelling plan"
            defaultMessage="Continue cancelling"
          />
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default Billboard;
