import hsFetch from 'js/sign-components/common/hs-fetch';
import { SurveySubmitBody } from './types';

/**
 * Used to pause a subscription
 * @param pauseInterval
 */
export const confirmPause = (pauseInterval: string) => {
  const formData = new FormData();
  formData.append('pause_interval', pauseInterval);

  return hsFetch('/account/pauseSubscription', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
};

/**
 * Used to resume a currently paused subscription
 */
export const confirmResumePause = () => {
  return hsFetch('/endpoint/account/ImmediatelyResumePausedSubscription', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
};

/**
 * Used to cancel a pending pause request
 */
export const confirmCancelPause = () => {
  return hsFetch('/endpoint/account/CancelPendingSubscriptionPause', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
};

/**
 * Used to cancel a subscription
 * @param planToCancel
 */
export const cancelSubscription = (planToCancel: string) => {
  const formData = new FormData();
  formData.append('plan', planToCancel);

  return hsFetch('/endpoint/org/CancelSubscription', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
};

/**
 * Used to submit survey upon subscription cancel
 * @param reason
 * @param planToCancel
 * @param context
 */
export const submitSurvey = async ({
  reason,
  planToCancel,
  context,
}: SurveySubmitBody) => {
  const formData = new FormData();
  formData.append('reason', reason);
  formData.append('plan', planToCancel);
  if (context) formData.append('context', context);

  return hsFetch('/endpoint/org/SaveSurveyAfterCancel', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
  }).then((res) => res.json());
};
