import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@dropbox/dig-components/buttons';
import { Checkbox } from '@dropbox/dig-components/controls';
import { ApiAppProps, ThirdPartyApps } from './api-app-section';
import APIKeySection from './api-key-section';
import { CallbackUrlSection } from './callback-section';
import styles from './api-setting.scss';
import { Banner } from '@dropbox/dig-components/banner';
import { Link, Text } from '@dropbox/dig-components/typography';
import Spacer, { SpacerSize } from 'hellospa/foundation/spacing/spacer';
import { Twinkle1Spot } from '@dropbox/dig-icons/assets';
import { UIIcon } from '@dropbox/dig-icons';
import HFUtils from 'js/sign-components/common/hf-react-helper';
import { strong } from 'hellospa/common/hs-intl-provider';
import { Box, Stack } from '@dropbox/dig-foundations';
import { ApiSettingsSection, SectionSeparator } from './shared';
import { ApiAppSettings } from './api-app-settings';
import { SnackbarProvider } from './snackbars';
import CallbackUrlWarningBanner from './callback-url-warning-banner';

type APISettingsProps = {
  hasTeam: boolean;
  showApi: boolean;
  hasApiAccess: boolean;
  supportEmail: string;
  brandName: string;
  accountCallbackUrl?: string | null;
  inboundFaxCallbackUrl?: string | null;
  outboundFaxCallbackUrl?: string | null;
  isHF: boolean;
  grantedApps: ApiAppProps[];
  deleteFaxAfterSendV3?: boolean;
  showInsecureCallbackUrlWarningBanner: boolean;
};

const messages = defineMessages({
  didYouKnowCloseButton: {
    id: '',
    description: 'Button, accessibility text',
    defaultMessage: 'Dismiss alert',
  },
  deleteFaxAfterSent: {
    id: '',
    description:
      'Checkbox label, Delete outgoing faxes sent using the V3 API once successfully transmitted',
    defaultMessage:
      'Delete outgoing faxes sent using the V3 API once successfully transmitted',
  },
});

const didYouKnowBannerLocalStorageKey =
  'api_pod_did_you_know_banner_is_dismissed';

export function APISettings({
  hasTeam,
  showApi,
  hasApiAccess,
  supportEmail,
  brandName,
  accountCallbackUrl,
  inboundFaxCallbackUrl,
  outboundFaxCallbackUrl,
  isHF,
  grantedApps,
  deleteFaxAfterSendV3,
  showInsecureCallbackUrlWarningBanner,
}: APISettingsProps) {
  const intl = useIntl();
  const [didYouKnowBannerIsDismissed, setDidYouKnowBannerIsDismissed] =
    useState(!!window.localStorage.getItem(didYouKnowBannerLocalStorageKey));
  const [hasNeverUsedApi, setHasNeverUsedApi] = useState(false);
  const [shouldDeleteFax, setdeleteFaxAfterSendV3] =
    useState(deleteFaxAfterSendV3);
  let maybeShowQuickStart = false;
  // Check for insecure URLs

  const deleteFaxCheckboxLabel = intl.formatMessage(
    messages.deleteFaxAfterSent,
  );
  let notice = (
    <FormattedMessage
      id=""
      description="caption text, settings page, tells users that if they wanted access to the api and you need to get a new plan"
      defaultMessage="Production access to the {brand_name} API requires an API plan. {link_document} or {plans_pricing_link}."
      values={{
        brand_name: <strong>{brandName}</strong>,
        link_document: (
          <a href="/api" target="_blank" rel="noopener noreferrer">
            get more information
          </a>
        ),
        plans_pricing_link: (
          <a href="/api/pricing" target="_blank" rel="noopener noreferrer">
            see our plans and pricing
          </a>
        ),
      }}
    />
  );

  notice = (
    <FormattedMessage
      id=""
      description="caption text, settings page, tells users that if they wanted access to the api and you need to get a new plan"
      defaultMessage="<strong>Note:</strong> You can fully test every endpoint and almost all our features in test mode. Production access to the {brand_name} API requires an API plan. See our <a>plans and pricing</a>."
      values={{
        brand_name: <strong>{brandName}</strong>,
        strong: function renderStrong(...chunks: string[]) {
          return <Text isBold>{chunks}</Text>;
        },
        a: function renderLink(...chunks: string[]) {
          return (
            <Link href="/api/pricing" target="_blank" rel="noopener noreferrer">
              {chunks}
            </Link>
          );
        },
      }}
    />
  );

  if (hasTeam && !hasApiAccess) {
    notice = (
      <FormattedMessage
        id=""
        description="warning message, settings page, note to user that role is not the correct access level to perform API requests"
        defaultMessage='<strong>You are currently a non-developer team member.</strong> This means you will be unable
        to make API requests using this account. If you wish to do so, please contact a team administrator who
        can promote your account to "Developer" status.'
        values={{
          strong,
        }}
      />
    );
  } else if (showApi) {
    maybeShowQuickStart = true;
    notice = (
      <FormattedMessage
        id=""
        description="caption text, settings page, message for developers to help them build custom software using API"
        defaultMessage="Need help building an integration with our API? Check out our <l2>documentation</l2> for examples or email our <l1>API Support team</l1>."
        values={{
          /* eslint-disable-next-line react/display-name */
          l1: (...chunks: string[]) => (
            <a
              href={`mailto:${supportEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </a>
          ),
          /* eslint-disable-next-line react/display-name */
          l2: (...chunks: string[]) => (
            <a
              href="/api/documentation"
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </a>
          ),
        }}
      />
    );
  }

  return (
    <SnackbarProvider>
      <div>
        <Spacer top={SpacerSize.XL} />
        {showInsecureCallbackUrlWarningBanner && <CallbackUrlWarningBanner />}
        <Spacer top={SpacerSize.XS} />
        {hasNeverUsedApi &&
          !didYouKnowBannerIsDismissed &&
          maybeShowQuickStart && (
            <Banner
              data-testid="send-your-first-signature-request-banner"
              variant="basic"
              withLeftIcon={<UIIcon src={Twinkle1Spot} role="presentation" />}
              type="attention"
              withCloseButton={intl.formatMessage(
                messages.didYouKnowCloseButton,
              )}
              className={styles.didYouKnowAlert}
              onRequestClose={() => {
                setDidYouKnowBannerIsDismissed(true);
                localStorage.setItem(didYouKnowBannerLocalStorageKey, 'true');
              }}
            >
              <Banner.Message>
                <span>
                  <FormattedMessage
                    id=""
                    description="Text, displayed to users who have not sent a signature request or fax in the last 90 days"
                    defaultMessage="Send your first {documentType} using the {siteName} API."
                    values={{
                      documentType: isHF ? 'fax' : 'signature request',
                      siteName: brandName,
                    }}
                  />
                </span>
              </Banner.Message>
              <Banner.Actions>
                <Button
                  variant="transparent"
                  href={HFUtils.getDocumentationUrl('api/quickstart/')}
                  className={styles.quickStartLink}
                  data-testid="api-settings-view-quick-start-btn"
                >
                  <FormattedMessage
                    id=""
                    description="Link that leads to API Quickstart documentation"
                    defaultMessage="View Quickstart"
                  />
                </Button>
              </Banner.Actions>
            </Banner>
          )}
        <Stack gap="Macro Small">
          <Text tagName="p">{notice}</Text>
          <Stack gap="Macro Medium" paddingBottom="Macro Medium">
            <ApiSettingsSection
              title={
                <FormattedMessage
                  id=""
                  description="title, settings page, title on the sub section that links to another page which is a dashboard"
                  defaultMessage="API Dashboard"
                />
              }
              description={
                <FormattedMessage
                  id=""
                  description="caption text, settings page, details the section where the dashboard is for the API"
                  defaultMessage="Get detailed info on your API usage, including requests and callback events."
                />
              }
              action={
                <Button
                  data-qa-ref="api-dashboard-link"
                  variant="outline"
                  href="/apidashboard"
                  target="_blank"
                >
                  <FormattedMessage
                    id=""
                    description="button, settings page, links to a dashboard that shows stats about API usage"
                    defaultMessage="View API Dashboard"
                  />
                </Button>
              }
            />
            <SectionSeparator />
            <APIKeySection
              setHasNeverUsedApi={setHasNeverUsedApi}
              brandName={brandName}
            />
            {isHF ? (
              <>
                <CallbackUrlSection
                  url={inboundFaxCallbackUrl ?? ''}
                  fieldName="inbound_fax_callback_url_v3"
                  title={
                    <FormattedMessage
                      id=""
                      description="title, settings page, title on a section that contains a form. To input a callback url which is a full http://www.example.com "
                      defaultMessage="Inbound fax callback"
                    />
                  }
                  description={
                    <FormattedMessage
                      id=""
                      description="caption text, settings page, details the section where the inbound fax callback url is entered for the account"
                      defaultMessage="Receive notifications for inbound faxes involving your account."
                    />
                  }
                />
                <CallbackUrlSection
                  url={outboundFaxCallbackUrl ?? ''}
                  fieldName="outbound_fax_callback_url_v3"
                  title={
                    <FormattedMessage
                      id=""
                      description="title, settings page, title on a section that contains a form. To input a callback url which is a full http://www.example.com "
                      defaultMessage="Outbound fax callback"
                    />
                  }
                  description={
                    <FormattedMessage
                      id=""
                      description="caption text, settings page, details the section where the outbound fax callback url is entered for the account"
                      defaultMessage="Receive notifications for outbound faxes involving your account."
                    />
                  }
                />
              </>
            ) : (
              <CallbackUrlSection
                url={accountCallbackUrl ?? ''}
                fieldName="callback_url"
                title={
                  <FormattedMessage
                    id=""
                    description="title, settings page, title on a section that contains a form. To input a callback url which is a full http://www.example.com "
                    defaultMessage="Account callback"
                  />
                }
                description={
                  <FormattedMessage
                    id=""
                    description="caption text, settings page, details the section where the account callback url is entered for the account"
                    defaultMessage="Receive notifications for signature request or template events involving your account. {learnMoreLink}"
                    values={{
                      learnMoreLink: (
                        <Link
                          key="learnMore"
                          href={HFUtils.getDocumentationUrl(
                            'api/reference/operation/accountUpdateEventCallback/',
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage
                            id=""
                            description="link text, settings page, link to the account callback documentation"
                            defaultMessage="Learn more"
                          />
                        </Link>
                      ),
                    }}
                  />
                }
              />
            )}
            {Array.isArray(grantedApps) && grantedApps.length > 0 ? (
              /**
               * Temporary `paddingTop` and `clear` styles applied here to
               * deal with the `float` styles in the above sections
               */
              <>
                <Box style={{ clear: 'both' }} paddingTop="Macro Medium">
                  <ThirdPartyApps apps={grantedApps} />
                </Box>
                <SectionSeparator />
              </>
            ) : null}
            {!isHF ? (
              /**
               * Temporary `paddingTop` and `clear` styles applied here to
               * deal with the `float` styles in the above sections
               */
              <Box style={{ clear: 'both' }} paddingTop="Macro Medium">
                <ApiAppSettings />
              </Box>
            ) : null}
          </Stack>
          {isHF && (
            <div className="m-settings--section is-api no-margin">
              <div className="m-settings--api--account-delete-fax-checkbox">
                <Checkbox
                  id={`delete_fax_after_send_v3`}
                  aria-label={deleteFaxCheckboxLabel}
                  data-testid="api_tab.delete_fax_after_send_v3"
                  className={'checkbox'}
                  disabled={false}
                  onChange={(e) => setdeleteFaxAfterSendV3(e.target.checked)}
                  defaultChecked={shouldDeleteFax}
                  value={shouldDeleteFax ? 1 : 0}
                  name="edit_account[delete_fax_after_send_v3]"
                />
                <Text
                  tagName="label"
                  className="checkbox-label"
                  htmlFor={deleteFaxCheckboxLabel}
                >
                  <Spacer left={SpacerSize.SM} />
                  {deleteFaxCheckboxLabel}
                </Text>
              </div>
            </div>
          )}
        </Stack>
      </div>
    </SnackbarProvider>
  );
}

export default function APISettingsTab(props: APISettingsProps) {
  // Since these tabs are coming from old PHP template it messes up.
  // Api section loads even if you are on other tabs. So this is extra step to prevent that.
  const isApiTab = () => {
    const hash = window.location.hash;
    if (hash) {
      return hash === '#api';
    } else {
      const searchParam = new URLSearchParams(window.location.search);
      const currentTab = searchParam.get('current_tab');
      if (currentTab === 'api') {
        return true;
      }
    }
    return false;
  };

  const [isApiTabActive, setIsApiTabActive] = useState(isApiTab());

  useEffect(() => {
    function onHashChange() {
      setIsApiTabActive(isApiTab());
    }

    window.addEventListener('hashchange', onHashChange);

    return () => {
      window.removeEventListener('hashchange', onHashChange);
    };
  }, []);

  return isApiTabActive ? <APISettings {...props} /> : null;
}
