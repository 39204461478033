import React from 'react';
import { FormattedMessage } from 'react-intl';

function BillingCycle(props) {
  const {
    billingPlans,
    isFreeTrial,
    localizedPeriodName,
    localizedTotalWithDiscounts,
    localizedTotalWithDiscountsAtRenewal,
    startDate,
    nextBillingDate,
    renewDate,
    hasWebCoupon,
    webCouponPercentage,
    hasAPICoupon,
    apiCouponPercentage,
    billingPeriodsLeft,
    apiBillingPeriodsLeft,
    hasAlternativeBilling,
    isFree,
    isDelinquent,
    subEndDate,
    subApiEndDate,
    isAutoDowngrade,
    isTaxExclusive,
    isFrozen,
  } = props;

  const bodyChunks = [];

  let areAllPlansCanceled = true;
  for (let i = 0; i < billingPlans.length; i++) {
    const bpq = billingPlans[i];

    if (!bpq.isCanceled) {
      if (
        (bpq.isApi && !subApiEndDate) ||
        (!bpq.isApi && bpq.name !== 'Free' && !subEndDate)
      ) {
        areAllPlansCanceled = false;
        break;
      }
    }
  }

  if (isFrozen) {
    bodyChunks.push(
      <FormattedMessage
        id=""
        description="info text, billing settings page, informs user that their payment is past due and their subscription is in a frozen state."
        defaultMessage="Your plan is currently past due because of a payment issue, and your account has been temporarily limited. Please update your billing information to restore full access."
      />,
    );
  } else if (!isDelinquent && !isAutoDowngrade) {
    // Only mention charge date if at least one plan is not
    // canceled. Canceled plans are still marked as "paid"
    // plans.
    if (!hasAlternativeBilling && !areAllPlansCanceled) {
      if (isFreeTrial) {
        bodyChunks.push(
          <FormattedMessage
            id=""
            description="info text, billing settings page, informs user when trial period will end and user will be charged."
            defaultMessage="You will be charged once your trial ends on {startDate, date, long}"
            values={{
              startDate,
            }}
          />,
        );
      } else if (!isFree) {
        bodyChunks.push(
          <FormattedMessage
            id=""
            description="info text, billing settings page, informs user when their credit card will next be charged"
            defaultMessage="Your next credit card charge will be on {nextBillingDate, date, long}"
            values={{
              nextBillingDate,
            }}
          />,
        );
      }
    }

    if (hasWebCoupon && hasAPICoupon) {
      bodyChunks.push(<br />);
      bodyChunks.push(
        <FormattedMessage
          id=""
          description="information text, billing settings page, informs user on coupons listed under subscription and break down of web and api coupons"
          defaultMessage="
            On {renewDate, date, long}, when your subscription renews, you will be charged {totalCharge}{isTaxExclusive, select, true {, plus applicable taxes, } other { }}
            for your {webPlanName} and {apiPlanName} plans. You have a {webCouponPercent, number, percent} discount on your
            subscription {numBillingPeriodsLeft, select, 9999{perpetually} other{for {numBillingPeriodsLeft, plural, one{this billing period} other{# billing periods}}}}.
            You also have a {apiCouponPercent, number, percent} API discount on your subscription applied
            {numAPIBillingPeriodsLeft, select, 9999{perpetually} other{for {numAPIBillingPeriodsLeft, plural, one{this billing period} other{# billing periods}}}}.
          "
          values={{
            totalCharge: localizedTotalWithDiscountsAtRenewal,
            webCouponPercent: webCouponPercentage,
            numBillingPeriodsLeft: billingPeriodsLeft,
            apiCouponPercent: apiCouponPercentage,
            numAPIBillingPeriodsLeft: apiBillingPeriodsLeft,
            webPlanName: billingPlans[0].name,
            apiPlanName: billingPlans[1].name,
            renewDate,
            isTaxExclusive,
          }}
        />,
      );
    } else if (hasWebCoupon) {
      bodyChunks.push(<br />);
      bodyChunks.push(
        <FormattedMessage
          id=""
          description="information text, billing settings page, informs user on web coupons listed under subscription"
          defaultMessage="
            On {renewDate, date, long}, when your subscription renews, you will be charged {totalCharge}{isTaxExclusive, select, true {, plus applicable taxes, } other { }}
            for your {planName} plan. You have a {couponPercent, number, percent} discount on your subscription
            {numBillingPeriodsLeft, select, 9999{perpetually} other{for {numBillingPeriodsLeft, plural, one{this billing period} other{# billing periods}}}}.
          "
          values={{
            totalCharge: localizedTotalWithDiscountsAtRenewal,
            couponPercent: webCouponPercentage,
            numBillingPeriodsLeft: billingPeriodsLeft,
            planName: billingPlans[0].name,
            renewDate,
            isTaxExclusive,
          }}
        />,
      );
    } else if (hasAPICoupon) {
      bodyChunks.push(<br />);
      bodyChunks.push(
        <FormattedMessage
          id=""
          description="information text, billing settings page, informs user on api coupons listed under subscription"
          defaultMessage="
            On {renewDate, date, long}, when your subscription renews, you will be charged {totalCharge}{isTaxExclusive, select, true {, plus applicable taxes, } other { }}
            for your {planName} plan. You have a {couponPercent, number, percent} API discount on your subscription
            applied {numBillingPeriodsLeft, select, 9999{perpetually} other{for {numBillingPeriodsLeft, plural, one{this billing period} other{# billing periods}}}}.
          "
          values={{
            totalCharge: localizedTotalWithDiscountsAtRenewal,
            couponPercent: apiCouponPercentage,
            numBillingPeriodsLeft: apiBillingPeriodsLeft,
            planName: billingPlans[1].name,
            renewDate,
            isTaxExclusive,
          }}
        />,
      );
    }
  } else if (isAutoDowngrade) {
    bodyChunks.push(
      <FormattedMessage
        id=""
        description="info text, billing settings page, informs user when trial period ends they will be downgraded."
        defaultMessage="You will be downgraded to a free plan when your trial ends on {startDate, date, long}"
        values={{
          startDate,
        }}
      />,
    );
  }

  return (
    <div className="billing-settings__billing-cycle">
      <p className="billing-settings__total-charge">
        <FormattedMessage
          id=""
          description="info text, billing settings page, displays total charge user has to pay per period"
          defaultMessage="{totalCharge} / {chargePeriod}{isTaxExclusive, select, true {, plus applicable taxes} other {}}"
          values={{
            totalCharge: localizedTotalWithDiscounts,
            chargePeriod: localizedPeriodName,
            isTaxExclusive,
          }}
        />
      </p>
      {bodyChunks.map((chunk, i) => (
        <p key={i}>{chunk}</p>
      ))}
    </div>
  );
}

export default BillingCycle;
