import React, { useMemo } from 'react';
import { Modal } from '@dropbox/dig-components/modal';
import { Text, Title } from '@dropbox/dig-components/typography';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styles from './resume-paused.module.scss';
import { PauseCancelModalContext } from '../types';
import Spacer, { SpacerSize } from '../../../foundation/spacing/spacer';
import { LineDivider } from '../../../../hello-react/components/line-divider';
import { getBrandName } from '../../../../js/sign-components/common/brand';

type PauseModalProps = {
  totalCharge: PauseCancelModalContext['totalCharge'];
  currencyCode: PauseCancelModalContext['currencyCode'];
  planName: string;
  unpauseDate: string;
};

const ResumePausedContent = ({
  totalCharge,
  currencyCode,
  planName,
  unpauseDate,
}: PauseModalProps) => {
  const getHeader = useMemo(() => {
    return (
      <FormattedMessage
        id=""
        description="header, pause flow header"
        defaultMessage="{brandName} {planName}"
        values={{
          brandName: getBrandName('S'),
          planName,
        }}
      />
    );
  }, [planName]);

  const getDescription = useMemo(() => {
    return (
      <>
        <div className={styles.paused}>
          <FormattedMessage
            id=""
            description="header, single word 'Paused'"
            defaultMessage="Paused"
          />
        </div>
        <div />
        <FormattedMessage
          id=""
          description="header, describing how long the subscription will be paused for"
          defaultMessage="Paused until {unpauseDate}"
          values={{
            unpauseDate: (
              <FormattedDate
                value={unpauseDate}
                day="numeric"
                month="long"
                year="numeric"
              />
            ),
          }}
        />
        <Spacer top={SpacerSize.LG} /> <LineDivider />
        <Spacer top={SpacerSize.LG} />
        <FormattedMessage
          id=""
          description="Pause flow body, description about pause details"
          defaultMessage="Your plan is paused until {unpauseDate}, and you won’t be charged until then. To access all {planName} features before then, resume your plan."
          values={{
            unpauseDate: (
              <FormattedDate
                value={unpauseDate}
                day="numeric"
                month="long"
                year="numeric"
              />
            ),
            planName,
          }}
        />
        <Spacer top={SpacerSize.MD} />
        <FormattedMessage
          id=""
          description="Pause flow body, legal disclaimer"
          defaultMessage="By clicking Resume plan, you will be billed {totalCharge} per year starting today until you cancel your plan. Previous charges won’t be refunded when you cancel except where required by law. All amounts shown are in {currencyCode}."
          values={{
            totalCharge,
            currencyCode,
          }}
        />
      </>
    );
  }, [totalCharge, currencyCode, planName, unpauseDate]);

  return (
    <>
      <Modal.Header hasBottomSpacing="title-standard">
        <Title id="cancellation-flow-modal-title">{getHeader}</Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <Text>{getDescription}</Text>
      </Modal.Body>
    </>
  );
};

export default ResumePausedContent;
