/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  no-alert,
  no-console,
  react/jsx-no-target-blank,
  react/no-unescaped-entities,
  react/prop-types,
  no-restricted-syntax
*/
import React from 'react';
import PropTypes from 'prop-types';
import superagent from 'superagent';
import { stringify } from 'qs';
import Button from 'common/components/button';
import './index.scss';

/**
 * Top-level component for Embedded Testing Tool
 */
class EmbeddedTestingTool extends React.Component {
  static propTypes = {
    // CSRF Token - Sent from the server
    csrfToken: PropTypes.string.isRequired,

    // URL used to get a sample document
    getSampleURL: PropTypes.string.isRequired,

    // Map of userCulture identifiers to their display names
    cultures: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    // Initial state (TODO: Redux store?)
    this.state = {
      pending: false,
      clientId: '',
      url: '',
      userCulture: 'en_US',
      openInModal: true,
      allowCancel: true,
      obtainedSampleDoc: false,
    };

    this.handleLaunch = this.handleLaunch.bind(this);
    this.launchEmbedded = this.launchEmbedded.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleGetSampleDoc = this.handleGetSampleDoc.bind(this);
    this.handleGetSampleDocResponse =
      this.handleGetSampleDocResponse.bind(this);
  }

  /**
   * Click handler for the submit button
   */
  handleLaunch() {
    if (typeof window.HelloSign === 'undefined') {
      // Dynamically load hellosign-embedded
      const script = document.createElement('script');
      script.onload = this.launchEmbedded;
      script.src =
        'https://s3.amazonaws.com/cdn.hellosign.com/public/js/hellosign-embedded.LATEST.min.js';
      document.head.appendChild(script);
    } else {
      this.launchEmbedded();
    }
  }

  /**
   * Actually build options object and call HelloSign.open
   */
  launchEmbedded() {
    const HelloSign = window.HelloSign;
    const clientId = this.state.clientId;
    const url = this.state.url;

    const options = {
      url,
      userCulture: this.state.userCulture,
      messageListener: this.handleMessage,
      debug: true,
      skipDomainVerification: true,
      allowCancel: this.state.allowCancel,
    };

    if (!this.state.openInModal) {
      options.container = document.getElementById('hsEmbeddedContainer');
    }

    // Validate?
    if (!clientId.length) {
      alert('URL is required.');
      return;
    }
    if (!url.length) {
      alert('URL is required.');
      return;
    }

    HelloSign.init(clientId);
    HelloSign.open(options);
  }

  /**
   * Handles client-side events sent by the embedded frame
   */
  handleMessage(eventData) {
    console.log('Received message from embedded HelloSign page:', eventData);
  }

  /**
   * Retrieves a sample doc from the backend and populates the client ID/URL
   */
  handleGetSampleDoc() {
    const data = stringify({
      csrf_token: this.props.csrfToken,
    });

    return superagent
      .post(this.props.getSampleURL)
      .type('form')
      .set('Accept', 'application/json')
      .send(data)
      .on('request', () => {
        // this.props.dispatch(actions.setPending(FORM_NAME));
        this.setState({ pending: true });
      })
      .end(this.handleGetSampleDocResponse);
  }

  handleGetSampleDocResponse(err, response) {
    if (err) {
      let errorMsg;
      switch (response.status) {
        case 429:
          errorMsg = 'Too many requests. Please wait and try again!';
          break;
        default:
          errorMsg = 'There was an error fetching a sample document. Sorry!';
          break;
      }
      alert(errorMsg);
      this.setState({ pending: false });
      return;
    }

    const resp = response.body;
    this.setState({
      clientId: resp.clientId,
      url: resp.embeddedUrl,
      pending: false,
      obtainedSampleDoc: true,
    });
  }

  render() {
    let sampleDocButtonText = 'Get a Sample Document';
    if (this.state.pending) {
      sampleDocButtonText = 'Please Wait...';
    } else if (this.state.obtainedSampleDoc) {
      sampleDocButtonText = 'Already Used';
    }

    return (
      <div className="m-embedded-testing-tool">
        <div className="m-api-docs-section">
          <p className="m-api-docs-note">
            <strong className="m-api-docs-important">NOTE:</strong> This testing
            tool uses HelloSign Embedded v1 which is not officially supported
            after <strong>August 2020</strong>. You can access the new version
            of this testing tool <a href="/api/embeddedTest">here</a>. If you
            need help migrating from v1 to v2, please refer to our{' '}
            <a
              href="https://github.com/hellosign/hellosign-embedded/wiki/Migration-Guide-(v1-to-v2)"
              rel="noreferer nofollow"
            >
              migration guide
            </a>
            .
          </p>
        </div>
        <p>
          You can use this page to quickly test any of our Embedded flows
          without having to write a single line of JavaScript. Submitting the
          form below will trigger a call to <code>HelloSign.open()</code> with
          the options you specify, using the{' '}
          <a
            href="https://github.com/HelloFax/hellosign-embedded"
            target="_blank"
            data-qa-ref="link-hellosign-embedded"
          >
            HelloSign Embedded
          </a>{' '}
          library that's been loaded into this page.
        </p>
        <h3>Client ID</h3>
        <input
          type="text"
          value={this.state.clientId}
          onChange={(e) => {
            this.setState({ clientId: e.target.value });
          }}
          disabled={this.state.pending}
          data-qa-ref="input-client-id"
        />
        <div className="help">
          <p>
            Enter the Client ID you used when creating the embedded signature
            request or template.
          </p>
          <p>
            This value corresponds to the <code>clientId</code> option passed to
            the HelloSign Embedded client.
          </p>
        </div>
        <h3>Embedded URL</h3>
        <div style={{ clear: 'both' }}>
          <input
            type="url"
            style={{ float: 'left' }}
            value={this.state.url}
            onChange={(e) => {
              this.setState({ url: e.target.value });
            }}
            disabled={this.state.pending}
            data-qa-ref="input-embedded-url"
          />
          <div className="or">OR</div>
          <Button
            buttonText={sampleDocButtonText}
            buttonColor="white"
            buttonTextColor="cerulean"
            buttonHoverColor="cerulean"
            buttonBorderColor="cerulean"
            buttonHoverTextColor="white"
            buttonClass="get-sample-button"
            onClick={this.handleGetSampleDoc}
            disabled={this.state.pending || this.state.obtainedSampleDoc}
            data-qa-ref="button-get-a-sample-document"
          />
        </div>
        <div style={{ clear: 'both' }} />
        <div className="help">
          <p>
            Paste your embedded URL here. You can obtain this URL using the{' '}
            <code>/embedded/sign_url</code> or <code>/embedded/edit_url</code>{' '}
            API endpoints, or by clicking the button above to generate a sample
            embedded signature request automatically.
          </p>
          <p>
            This value corresponds to the <code>url</code> option passed to the
            HelloSign Embedded client.
          </p>
        </div>

        <h3>User Culture (Localization)</h3>
        <select
          onChange={(e) => {
            this.setState({ userCulture: e.target.value });
          }}
          disabled={this.state.pending}
          data-qa-ref="dropDown-user-culture"
        >
          {Object.entries(this.props.cultures).map((culture, index) => {
            return (
              <option value={culture[0]} key={index}>
                {culture[1]}
              </option>
            );
          })}
        </select>
        <div className="help">
          <p>
            Choose a language/locale to use in the embedded UI. Only applicable
            for Embedded Signing.
          </p>
          <p>
            This value corresponds to the <code>userCulture</code> option passed
            to the HelloSign Embedded client.
          </p>
        </div>

        <h3>Other Options</h3>

        <label>
          <input
            type="checkbox"
            checked={this.state.openInModal}
            onChange={(e) => {
              console.log(e.target);
              this.setState({
                openInModal: !this.state.openInModal,
              });
            }}
            disabled={this.state.pending}
            data-qa-ref="checkbox-open-in-a-modal"
          />
          Open in a modal (Default)
        </label>
        <div className="help">
          <p>
            If checked, the embedded frame will display on top of the page as a
            modal. Otherwise, it will be mounted to a div element below this
            form.
          </p>
          <p>
            This value corresponds to the <code>container</code> option passed
            to the HelloSign Embedded client.
          </p>
        </div>

        <label>
          <input
            type="checkbox"
            checked={this.state.allowCancel}
            onChange={(e) => {
              console.log(e.target);
              this.setState({
                allowCancel: !this.state.allowCancel,
              });
            }}
            disabled={this.state.pending}
            data-qa-ref="checkbox-allow-cancel"
          />
          Allow cancel
        </label>
        <div className="help">
          <p>
            If checked, the user will have the ability to close the embedded
            frame without signing.
          </p>
          <p>
            This value corresponds to the <code>allowCancel</code> option passed
            to the HelloSign Embedded client.
          </p>
        </div>

        <hr />

        <div className="help">
          <p>
            You're all done! Use this button to call{' '}
            <code>HelloSign.open()</code> using the options you selected above.
            Domain verification will be skipped. Extra debugging information and
            message listener events will be logged to the JavaScript console.
          </p>
        </div>

        <Button
          buttonText="Launch embedded page"
          onClick={this.handleLaunch}
          buttonColor="cerulean"
          buttonHoverColor="black"
          buttonHoverTextColor="white"
          style={{ marginBottom: '40px' }}
          disabled={
            this.state.pending || !this.state.clientId || !this.state.url
          }
          data-qa-ref="button-launch-embedded-page"
        />

        <div id="hsEmbeddedContainer" />
      </div>
    );
  }
}

module.exports = EmbeddedTestingTool;
