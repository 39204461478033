import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Table } from '@dropbox/dig-components/table';
import { Link, Text } from '@dropbox/dig-components/typography';
import { Tooltip } from '@dropbox/dig-components/tooltips';
import { EditPictogram, DeleteLine } from '@dropbox/dig-icons/assets';
import { UIIcon } from '@dropbox/dig-icons';
import { Badge } from '@dropbox/dig-components/badge';
import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Skeleton } from '@dropbox/dig-components/skeleton';
import { Split, Cluster, Box, Stack } from '@dropbox/dig-foundations';
import {
  LabelGroup,
  IconButtonGroup,
} from '@dropbox/dig-components/combinations';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { useSnackbar } from './snackbars';
import { useWebAppClient } from 'hello-react/web-app-client/build-web-app-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiSettingsSection, CopyButton, useDelay } from './shared';
import {
  ApiAppListResponse,
  ApiAppResponse,
} from '@dropbox/sign-internal-client';
import { ConfirmPublishModal } from './api-app-confirm-publish-modal';
import { APIAppBanner } from './warning-banner-app-approval';
import { isInsecureUrl } from './util';

const EMBEDDED_WORKFLOW_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSeOK4k7L3aBRDQNREhPoGxD-C8k520rUJLmVKlDp5xT5lHKdw/viewform';
const OAUTH_WORKFLOW_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSd1MfLe892uCzZUJw5CELLd3TPaUQ3WRDwA7Q1PqI0kuaxGiQ/viewform';

const messages = defineMessages({
  sectionTitle: {
    id: '',
    description:
      'title, settings page, about a list of apps that the user has attached to their account',
    defaultMessage: 'API apps',
  },
  sectionCopy: {
    id: '',
    description:
      'prompt user if they need help getting the app approved, learnMoreLink links to documentation',
    defaultMessage:
      'Manage your apps integrated with the API. Need approval? {learnMoreLink}',
  },
  createAppLink: {
    id: '',
    description:
      'link, settings page, will activate a feature that will allow the user to add custom applications on their account',
    defaultMessage: 'Create app',
  },
  learnMore: {
    id: '',
    description: 'Learn more link for api app approval process',
    defaultMessage: 'Learn more',
  },
  contactUs: {
    id: '',
    description: 'Contact us link for api app approval process',
    defaultMessage: 'contact us',
  },
  nameHeader: {
    id: '',
    description: 'Column header for the API app name, settings page',
    defaultMessage: 'Name',
  },
  statusHeader: {
    id: '',
    description: 'Column header for the API app status, settings page',
    defaultMessage: 'Status',
  },
  actionsHeader: {
    id: '',
    description: 'Column header for the API app actions, settings page',
    defaultMessage: 'Actions',
  },
  statusApproved: {
    id: '',
    description: 'Status of the API app, settings page, approved',
    defaultMessage: 'Published',
  },
  statusNotApproved: {
    id: '',
    description: 'Status of the API app, settings page, not approved',
    defaultMessage: 'Not published',
  },
  clientId: {
    id: '',
    description:
      'label, settings page, a special important string of various characters used to secure connection to remote services',
    defaultMessage: 'Client ID:',
  },
  copyButtonTooltip: {
    id: '',
    description:
      'Tooltip, settings page, for copy client id to clipboard button',
    defaultMessage: 'Copy Client ID',
  },
  copyButtonAriaLabel: {
    id: '',
    description:
      'Aria label, settings page, for copy client id to clipboard button',
    defaultMessage: 'Copy Client ID for {appName}',
  },
  copiedAlert: {
    id: '',
    description: 'Alert, settings page, user feedback when copying a client ID',
    defaultMessage: 'Client ID copied',
  },
  copyFailedAlert: {
    id: '',
    description:
      'Alert, settings page, user feedback when failing to copy a client ID',
    defaultMessage: 'Failed to copy client ID',
  },
  close: {
    id: '',
    description: 'button, settings page, close the snackbar',
    defaultMessage: 'Close',
  },
  edit: {
    id: '',
    description: 'button, settings page, edit the app details',
    defaultMessage: 'Edit',
  },
  delete: {
    id: '',
    description: 'button, settings page, delete the app',
    defaultMessage: 'Delete',
  },
  deleteFailedAlert: {
    id: '',
    description:
      'alert, settings page, user feedback when deleting an app fails',
    defaultMessage: 'Failed to delete app',
  },
  deleteSuccessAlert: {
    id: '',
    description:
      'alert, settings page, user feedback when deleting an app succeeds',
    defaultMessage: 'App deleted',
  },
  deleteConfirmation: {
    id: '',
    description:
      'pop up alert message, settings page, an app is an external integration  with 3rd party services',
    defaultMessage:
      'Are you sure you want to delete this app? All associated grants will be revoked.',
  },
  details: {
    id: '',
    description:
      'button, settings page, see the extra information about the application connected to a users account',
    defaultMessage: 'Details',
  },

  requestToPublish: {
    id: '',
    description: 'button, settings page, request to publish the app',
    defaultMessage: 'Request to publish',
  },

  publish: {
    id: '',
    description: 'button, settings page, publish the app',
    defaultMessage: 'Publish',
  },
  published: {
    id: '',
    description: 'button, settings page, the app has been published',
    defaultMessage: 'Published',
  },
  failedToPublish: {
    id: '',
    description: 'button, settings page, the app has failed to publish',
    defaultMessage: 'Failed to publish',
  },
  insecureEventCallbackUrl: {
    id: '',
    description:
      'alert, settings page, user feedback when the event callback URL is insecure',
    defaultMessage: 'Insecure event callback URL',
  },
  insecureOauthCallbackUrl: {
    id: '',
    description:
      'alert, settings page, user feedback when the OAuth callback URL is insecure',
    defaultMessage: 'Insecure OAuth callback URL',
  },
});

function StatusBadge(props: React.ComponentProps<typeof Badge>) {
  const { variant, children } = props;
  return (
    <Split alignY="center" gap="Micro Small">
      <Split.Item>
        <Badge variant={variant} size="small" style={{ display: 'flex' }} />
      </Split.Item>
      <Split.Item>{children}</Split.Item>
    </Split>
  );
}

function RequestToPublishLink(props: { hasOauth?: boolean }) {
  const { hasOauth } = props;
  const intl = useIntl();

  const link = hasOauth ? OAUTH_WORKFLOW_FORM_URL : EMBEDDED_WORKFLOW_FORM_URL;

  return (
    <Button
      variant="opacity"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {intl.formatMessage(messages.requestToPublish)}
    </Button>
  );
}

const queryKey = ['apiApps'] as const;

function SelfApprovalButton(props: { clientId: string }) {
  const { clientId } = props;
  const [isApproving, setIsApproving] = React.useState(false);
  const intl = useIntl();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const webAppClient = useWebAppClient();

  const publishMutation = useMutation({
    mutationFn: () => {
      return webAppClient.apiAppApi.approve(clientId);
    },
    onError: () => {
      snackbar.fail(intl.formatMessage(messages.failedToPublish));
    },
    onSuccess: () => {
      snackbar.success(intl.formatMessage(messages.published));
    },
    onSettled: () => {
      setIsApproving(false);
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return (
    <>
      <Button
        variant="opacity"
        onClick={() => setIsApproving(true)}
        type="button"
      >
        {intl.formatMessage(messages.publish)}
      </Button>
      <ConfirmPublishModal
        open={isApproving}
        onRequestClose={() => setIsApproving(false)}
        onConfirm={publishMutation.mutate}
      />
    </>
  );
}

/**
 * This is a new version of the API App Section that supports self-serve
 * API app approvals and a redesigned UI.
 */
export function ApiAppSettings() {
  const intl = useIntl();
  const client = useWebAppClient();

  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: () => client.apiAppApi.list(),
  });

  const showSpinner = useDelay(1000) && query.isLoading;

  const apps = query.data?.apiApps ?? [];
  const snackbar = useSnackbar();
  const deleteMutation = useMutation({
    mutationFn: (clientId: string) => client.apiSettings.deleteApp(clientId),
    onMutate: async (clientId: string) => {
      await queryClient.cancelQueries({ queryKey });
      const previous = queryClient.getQueryData<ApiAppListResponse>(queryKey);

      if (previous) {
        queryClient.setQueryData(queryKey, {
          apiApps: previous.apiApps.filter((app) => app.clientId !== clientId),
        });
      }

      return { previous };
    },
    onError: (_error, _clientId, context) => {
      snackbar.fail(intl.formatMessage(messages.deleteFailedAlert));
      queryClient.setQueryData(queryKey, context?.previous);
    },
    onSuccess: () => {
      snackbar.success(intl.formatMessage(messages.deleteSuccessAlert));
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  function deleteApp(clientId: string) {
    // No proper confirmation dialog yet
    // eslint-disable-next-line no-alert
    if (confirm(intl.formatMessage(messages.deleteConfirmation))) {
      deleteMutation.mutate(clientId);
    }
  }

  const showWarningBanner = query.data?.selfPublishLimitReached ?? false;

  return (
    <ApiSettingsSection
      title={intl.formatMessage(messages.sectionTitle)}
      description={intl.formatMessage(messages.sectionCopy, {
        learnMoreLink: (
          <Link
            key="learnMore"
            href={HfReactHelper.getDocumentationUrl(
              'docs/self-publish-approval/overview/',
            )}
          >
            {intl.formatMessage(messages.learnMore)}
          </Link>
        ),
      })}
      action={
        <Button
          variant="outline"
          href="/oauth/createAppForm"
          data-qa-ref="account-app-section-create-button"
        >
          {intl.formatMessage(messages.createAppLink)}
        </Button>
      }
    >
      <Stack
        gap="Micro Medium"
        // NOTE: This is necessary to wire up to the legacy modal
        // We have to attached to an element that is present immediately on the
        // page so that we can attach a click listener.
        className="js-api-apps-legacy-view-details-modal-hook"
      >
        {showWarningBanner ? <APIAppBanner /> : null}
        {showSpinner ? (
          <Stack gap="Micro Small">
            <Skeleton.Box height={50} />
            <Skeleton.Box height={50} />
            <Skeleton.Box height={50} />
          </Stack>
        ) : null}
        {apps.length > 0 ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {intl.formatMessage(messages.nameHeader)}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {intl.formatMessage(messages.statusHeader)}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {intl.formatMessage(messages.actionsHeader)}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {apps.map((app: ApiAppResponse) => {
                const hasInsecureCallbackUrl = isInsecureUrl(app.callbackUrl);
                const hasInsecureOauthCallbackUrl = isInsecureUrl(
                  app.oauth?.callbackUrl,
                );
                return (
                  <Table.Row key={app.clientId}>
                    <Table.Cell>
                      <LabelGroup
                        withText={app.name}
                        withSubtext={
                          <Stack>
                            <Cluster gap="Micro XSmall">
                              <Cluster.Item>
                                {intl.formatMessage(messages.clientId)}{' '}
                                {app.clientId}
                              </Cluster.Item>
                              <Cluster.Item>
                                <CopyButton
                                  value={app.clientId}
                                  tooltip={intl.formatMessage(
                                    messages.copyButtonTooltip,
                                  )}
                                  aria-label={intl.formatMessage(
                                    messages.copyButtonAriaLabel,
                                    {
                                      appName: app.name,
                                    },
                                  )}
                                  onCopy={() => {
                                    snackbar.success(
                                      intl.formatMessage(messages.copiedAlert),
                                    );
                                  }}
                                  onCopyFail={() => {
                                    snackbar.fail(
                                      intl.formatMessage(
                                        messages.copyFailedAlert,
                                      ),
                                    );
                                  }}
                                />
                              </Cluster.Item>
                            </Cluster>
                            {hasInsecureCallbackUrl ||
                            hasInsecureOauthCallbackUrl ? (
                              <Cluster gap="Micro Medium">
                                {hasInsecureCallbackUrl ? (
                                  <Cluster.Item>
                                    <Text color="error" size="small">
                                      {intl.formatMessage(
                                        messages.insecureEventCallbackUrl,
                                      )}
                                    </Text>
                                  </Cluster.Item>
                                ) : null}
                                {hasInsecureOauthCallbackUrl ? (
                                  <Cluster.Item>
                                    <Text color="error" size="small">
                                      {intl.formatMessage(
                                        messages.insecureOauthCallbackUrl,
                                      )}
                                    </Text>
                                  </Cluster.Item>
                                ) : null}
                              </Cluster>
                            ) : null}
                          </Stack>
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {app.isApproved ? (
                        <StatusBadge variant="attention">
                          {intl.formatMessage(messages.statusApproved)}
                        </StatusBadge>
                      ) : (
                        <StatusBadge variant="warning">
                          {intl.formatMessage(messages.statusNotApproved)}
                        </StatusBadge>
                      )}
                    </Table.Cell>
                    <Table.Cell spacing="small">
                      <Box display="flex">
                        <span
                          // NOTE: This classname needs to be here to wire up to the legacy
                          // modal. The `rel` property on the button below is also
                          // necessary
                          className="m-settings--api--app--view-btn"
                          style={{
                            // Fudging this to the left so that the text in the button
                            // lines up with the column header
                            marginLeft:
                              'calc(var(--dig-spacing__micro__medium) * -1)',
                          }}
                        >
                          <Button
                            type="button"
                            variant="borderless"
                            // NOTE: This is necessary to wire up to the legacy modal
                            // Don't change this unless you change the legacy
                            // jQuery code as well
                            data-view-details-modal-id={app.clientId}
                            data-qa-ref="account-app-detail-button"
                          >
                            {intl.formatMessage(messages.details)}
                          </Button>
                        </span>
                        <IconButtonGroup size="medium">
                          {({ getButtonProps }) => (
                            <>
                              <Tooltip
                                title={intl.formatMessage(messages.edit)}
                              >
                                <IconButton
                                  // Being obsessively specific about the icon's position
                                  // The icon is a pixel off specifically when using
                                  // the `borderless` variant as a link. Will bring
                                  // this up with the design system team
                                  style={{ transform: 'translateY(1px)' }}
                                  aria-label={intl.formatMessage(messages.edit)}
                                  variant="borderless"
                                  href={HfReactHelper.urlHelper(
                                    `oauth/editAppDetails?client_id=${app.clientId}`,
                                  )}
                                  data-qa-ref="account-app-edit-button"
                                  type="button"
                                  {...getButtonProps()}
                                >
                                  <UIIcon
                                    src={EditPictogram}
                                    role="presentation"
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={intl.formatMessage(messages.delete)}
                              >
                                <IconButton
                                  aria-label={intl.formatMessage(
                                    messages.delete,
                                  )}
                                  variant="borderless"
                                  data-qa-ref="account-app-delete-button"
                                  onClick={() => deleteApp(app.clientId)}
                                  type="button"
                                  {...getButtonProps()}
                                >
                                  <UIIcon
                                    src={DeleteLine}
                                    role="presentation"
                                  />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </IconButtonGroup>

                        {app.isApproved ? null : (
                          <Box marginLeft="auto">
                            {app.isEligibleForSelfApprove ? (
                              <SelfApprovalButton clientId={app.clientId} />
                            ) : (
                              <RequestToPublishLink
                                hasOauth={Boolean(app.oauth)}
                              />
                            )}
                          </Box>
                        )}
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        ) : null}
      </Stack>
    </ApiSettingsSection>
  );
}
