import React from 'react';
import { FormattedMessage } from 'react-intl';

function AddonInfo(props) {
  const {
    addonSalesforceUsage,
    addonSalesforceQuota,
    addonOracleCPQUsage,
    addonOracleCPQQuota,
    addonQESUsage,
    addonQESQuota,
    addonDisplayNames,
    addonSmsDeliveryQuota,
    addonSmsDeliveryUsage,
  } = props;

  const bodyChunks = [];

  const displayNames = {
    addon_salesforce: 'Salesforce', // doesn't need translating
    addon_salesforcecpq: 'Salesforce CPQ', // doesn't need translating
    addon_oraclecpq: 'Oracle CPQ', // doesn't need translating
    addon_qualifiedesignature: 'eID', // doesn't need translating
    addon_multiteams: (
      <FormattedMessage
        id=""
        description="info text, billing settings page, shows Multi Teams label indicating the subscription has the ability to create an organization with multiple teams"
        defaultMessage="Multi Teams"
      />
    ),
    addon_whitelabeling: (
      <FormattedMessage
        id=""
        description="info text, billing settings page, shows White Labeling label indicating the subscription has the ability to remove the product brand and logo and instead uses their own branding."
        defaultMessage="White Labeling"
      />
    ),
    addon_smstools: (
      <FormattedMessage
        id=""
        description="info text, billing settings page, shows SMS Signature Delivery indicating the subscription has the ability to send signature requests via SMS"
        defaultMessage="SMS tools"
      />
    ),
  };

  if (addonDisplayNames.addon_salesforce) {
    if (addonSalesforceQuota) {
      bodyChunks.push(
        <React.Fragment>
          <FormattedMessage
            id=""
            description="info text, billing settings page, indicates Salesforce is active on the subscription"
            defaultMessage="{salesforceLabel}"
            values={{
              salesforceLabel: `${displayNames.addon_salesforce} ${addonDisplayNames.addon_salesforce.qty}`,
            }}
          />{' '}
          <FormattedMessage
            id=""
            description="Parenthetical displayed after name of a metered feature, indicating how many requests have been used in the current billing cycle"
            defaultMessage="(You've used {amountUsed} of {amountTotal} requests)"
            values={{
              amountUsed: addonSalesforceUsage,
              amountTotal: addonSalesforceQuota,
            }}
          />
        </React.Fragment>,
      );
    } else {
      bodyChunks.push(
        <span>
          {displayNames.addon_salesforce}{' '}
          {addonDisplayNames.addon_salesforce.qty}
        </span>,
      );
    }
  }

  if (addonDisplayNames.addon_salesforcecpq) {
    if (addonSalesforceQuota) {
      bodyChunks.push(
        <React.Fragment>
          <FormattedMessage
            id=""
            description="info text, billing settings page, indicates Salesforce CPQ is active on the subscription"
            defaultMessage="{salesforceCPQLabel}"
            values={{
              salesforceCPQLabel: `${displayNames.addon_salesforcecpq} ${addonDisplayNames.addon_salesforcecpq.qty}`,
            }}
          />{' '}
          <FormattedMessage
            id=""
            description="Parenthetical displayed after name of a metered feature, indicating how many requests have been used in the current billing cycle"
            defaultMessage="(You've used {amountUsed} of {amountTotal} requests)"
            values={{
              amountUsed: addonSalesforceUsage,
              amountTotal: addonSalesforceQuota,
            }}
          />
        </React.Fragment>,
      );
    } else {
      bodyChunks.push(
        <span>
          {displayNames.addon_salesforcecpq}{' '}
          {addonDisplayNames.addon_salesforcecpq.qty}
        </span>,
      );
    }
  }

  if (addonDisplayNames.addon_oraclecpq) {
    if (addonOracleCPQQuota) {
      bodyChunks.push(
        <React.Fragment>
          <FormattedMessage
            id=""
            description="info text, billing settings page, indicates Oracle CPQ is active on this subscription"
            defaultMessage="{oracleLabel}"
            values={{
              oracleLabel: `${displayNames.addon_oraclecpq} ${addonDisplayNames.addon_oraclecpq.qty}`,
            }}
          />{' '}
          <FormattedMessage
            id=""
            description="Parenthetical displayed after name of a metered feature, indicating how many requests have been used in the current billing cycle"
            defaultMessage="(You've used {amountUsed} of {amountTotal} requests)"
            values={{
              amountUsed: addonOracleCPQUsage,
              amountTotal: addonOracleCPQQuota,
            }}
          />
        </React.Fragment>,
      );
    } else {
      bodyChunks.push(
        <span>
          {displayNames.addon_oraclecpq} {addonDisplayNames.addon_oraclecpq.qty}
        </span>,
      );
    }
  }

  if (addonDisplayNames.addon_multiteams) {
    bodyChunks.push(
      <span>
        {displayNames.addon_multiteams} {addonDisplayNames.addon_multiteams.qty}
      </span>,
    );
  }

  if (addonDisplayNames.addon_whitelabeling) {
    bodyChunks.push(
      <span>
        {displayNames.addon_whitelabeling}{' '}
        {addonDisplayNames.addon_whitelabeling.qty}
      </span>,
    );
  }

  if (addonDisplayNames.addon_qualifiedesignature) {
    if (addonQESQuota) {
      bodyChunks.push(
        <React.Fragment>
          <FormattedMessage
            id=""
            description="info text, billing settings page, indicates Salesforce is active on the subscription"
            defaultMessage="{qesLabel}"
            values={{
              qesLabel: `${displayNames.addon_qualifiedesignature} ${addonDisplayNames.addon_qualifiedesignature.qty}`,
            }}
          />{' '}
          <FormattedMessage
            id=""
            description="Parenthetical displayed after name of a metered feature, indicating how many requests have been used in the current billing cycle"
            defaultMessage="(You've used {amountUsed} of {amountTotal} requests)"
            values={{
              amountUsed: addonQESUsage,
              amountTotal: addonQESQuota,
            }}
          />
        </React.Fragment>,
      );
    } else {
      bodyChunks.push(
        <span>
          {displayNames.addon_qualifiedesignature}{' '}
          {addonDisplayNames.addon_qualifiedesignature.qty}
        </span>,
      );
    }
  }

  if (addonDisplayNames.addon_smstools) {
    if (addonSmsDeliveryQuota) {
      bodyChunks.push(
        <React.Fragment>
          {displayNames.addon_smstools}
          {` ${addonDisplayNames.addon_smstools.qty} `}
          <FormattedMessage
            id=""
            description="Parenthetical displayed after name of a metered feature, indicating how many requests have been used in the current billing cycle"
            defaultMessage="(You've used {amountUsed, number} of {amountTotal, number} requests)"
            values={{
              amountUsed: addonSmsDeliveryUsage,
              amountTotal: addonSmsDeliveryQuota,
            }}
          />
        </React.Fragment>,
      );
    } else {
      bodyChunks.push(
        <span>
          {displayNames.addon_smstools} {addonDisplayNames.addon_smstools.qty}
        </span>,
      );
    }
  }

  if (
    !addonSalesforceQuota &&
    !addonOracleCPQQuota &&
    !addonQESQuota &&
    !addonDisplayNames.addon_salesforce &&
    !addonDisplayNames.addon_salesforcecpq &&
    !addonDisplayNames.addon_oraclecpq &&
    !addonDisplayNames.addon_multiteams &&
    !addonDisplayNames.addon_whitelabeling &&
    !addonDisplayNames.addon_qualifiedesignature &&
    !addonDisplayNames.addon_smstools
  ) {
    bodyChunks.push(
      <FormattedMessage
        id=""
        description="info text, billing settings page, shows message to entice user to buy addons"
        defaultMessage="Streamline your workflow with add-ons"
      />,
    );
  }

  return (
    <div className="billing-settings__addon-info">
      {bodyChunks.map((chunk, i) => (
        <p key={i}>{chunk}</p>
      ))}
    </div>
  );
}

export default AddonInfo;
