/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  no-console
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Layout } from 'hellosign/common/layout';
import LogoV2 from 'signer-app/parts/logo-v2';
import StyleProvider from 'hello-ui/style';
import styles from './download-page.module.css';

const iconSrc = require('./folder_clip.svg');

/**
 * This is a full-page component for a file download screen with an automatic download.
 */
export class DownloadPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleDownloadLinkClick = this.handleDownloadLinkClick.bind(this);
  }

  componentDidMount() {
    const { downloadUrl, onDownload } = this.props;
    if (downloadUrl.startsWith('https://')) {
      window.location = downloadUrl;
    } else {
      console.log(
        'Skipping download redirect because URL is missing or invalid',
      );
    }

    if (typeof onDownload === 'function') {
      onDownload({ automatic: true });
    }
  }

  handleDownloadLinkClick(event) {
    const { onDownload } = this.props;
    if (typeof onDownload === 'function') {
      onDownload({ automatic: false });
      event.preventDefault(); // Prevent actual navigation
    }
  }

  render() {
    const { heading, downloadUrl, backLabel, backUrl } = this.props;
    return (
      <StyleProvider>
        <Layout responsive={true}>
          <div className={styles.wordmarkBox}>
            <LogoV2 siteCode="S" variant="wordmark" />
          </div>
          <main className={styles.main}>
            <div className={styles.fileIcon}>
              <img alt="File icon" src={iconSrc} style={{ height: '100px' }} />
            </div>
            <div className={styles.heading}>{heading}</div>
            <div>
              <FormattedMessage
                id=""
                description="form heading text in reporting page of admin console, which generates the report accoring to the user's input"
                defaultMessage="Your download should begin shortly. If not, <a>click here</a>."
                values={{
                  a: (...chunks) => (
                    <a
                      href={downloadUrl}
                      onClick={this.handleDownloadLinkClick}
                    >
                      <strong>{chunks}</strong>
                    </a>
                  ),
                }}
              />
            </div>
            <div className={styles.back}>
              <a href={backUrl}>{backLabel}</a>
            </div>
          </main>
        </Layout>
      </StyleProvider>
    );
  }
}

DownloadPage.propTypes = {
  heading: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string.isRequired,
  backLabel: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
  onDownload: PropTypes.func, // Used for tests/storybook
};

export default injectIntl(DownloadPage);
