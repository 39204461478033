import React from 'react';
import PropTypes from 'prop-types';
import VatRules from 'common/constants/vat-rules';
import TextField from 'hellospa/foundation/control/text-field';

class TaxId extends React.Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onComplete: PropTypes.func,
    countryCode: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      lastTaxId: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(e) {
    const { countryCode } = this.props;
    const taxId = e.currentTarget.value;

    if (taxId.match(VatRules[countryCode].regex) || !taxId) {
      this.setState({ lastTaxId: taxId });
      this.complete(taxId);
    }

    if (this.props.onChange !== undefined) {
      this.props.onChange(taxId);
    }
  }

  handleBlur(e) {
    const taxId = e.currentTarget.value;
    const { lastTaxId } = this.state;

    // don't mark complete if tax id hasn't changed on blur
    if (lastTaxId !== taxId) {
      this.setState({ lastTaxId: taxId });
      this.complete(taxId);
    }
  }

  complete(taxId) {
    if (this.props.onComplete !== undefined) {
      this.props.onComplete(taxId);
    }
  }

  render() {
    const { countryCode, defaultValue, disabled } = this.props;

    return (
      <div>
        <TextField
          key={countryCode}
          defaultValue={defaultValue}
          placeholder={VatRules[countryCode].prefix}
          disabled={disabled}
          data-qa-ref="tax-id"
          data-testid="tax-id"
          onBlur={this.handleBlur}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default TaxId;
