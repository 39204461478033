/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  camelcase,
  import/named,
  import/no-extraneous-dependencies,
  max-len,
  no-console,
  no-param-reassign,
*/
import React from 'react';
import './google-signin-button.scss';
import GoogleLoginOrSignup from './google-login-or-signup';

export default function GoogleSignInButtonWrapper(props) {
  return <GoogleLoginOrSignup {...props} />;
}
