/* eslint-disable no-restricted-syntax */

import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { defineMessages } from 'react-intl';
import { intl } from 'hellospa/common/hs-intl-provider';
import { getBrandName } from 'js/sign-components/common/brand';

const webFlowUrl = HfReactHelper.getWebflowUrl();
const faqHsUrl = HfReactHelper.getFaqUrl();

const messages = defineMessages({
  products: {
    id: '',
    description: 'navbar header text, when clicked opens dropdown',
    defaultMessage: 'Products',
  },
  legallyBindingESign: {
    id: '',
    description:
      'dropdown menu link, defines what the Signatures product is, when clicked goes to the Signatures homepage',
    defaultMessage: 'Legally Binding eSignature',
  },
  features: {
    id: '',
    description: 'dropdown menu link, when clicked opens features page',
    defaultMessage: 'Features',
  },
  pricing: {
    id: '',
    description: 'dropdown menu link, when clicked opens pricing page',
    defaultMessage: 'Pricing',
  },
  enterprise: {
    id: '',
    description: 'dropdown menu link, when clicked opens enterprise page',
    defaultMessage: 'Enterprise',
  },
  whatIsHelloSign: {
    id: '',
    description:
      'dropdown menu link, when clicked opens a page describing the product',
    defaultMessage: 'What is {brandName}?',
  },
  helloSignSalesforce: {
    id: '',
    description:
      'dropdown menu link, when clicked opens page explaining Salesforce integration with the product',
    defaultMessage: '{brandName} for Salesforce',
  },
  integrations: {
    id: '',
    description: 'dropdown menu link, when clicked opens integrations page',
    defaultMessage: 'Integrations',
  },
  fullyIntegratedESign: {
    id: '',
    description:
      'dropdown menu link, defines what the API is, when clicked opens the API page',
    defaultMessage: 'Fully Integrated eSignatures',
  },
  documentation: {
    id: '',
    description:
      'dropdown menu link, when clicked opens API documentation page',
    defaultMessage: 'Documentation',
  },
  libraries: {
    id: '',
    description: 'dropdown menu link, when clicked opens API libraries page',
    defaultMessage: 'Libraries',
  },
  APIHelp: {
    id: '',
    description: 'dropdown menu link, when clicked opens API help page',
    defaultMessage: 'API Help',
  },
  mobileForms: {
    id: '',
    description:
      'dropdown menu link, defines what our smart forms product is, when clicked opens product page',
    defaultMessage: 'Intelligent Mobile Forms',
  },
  onlineFaxing: {
    id: '',
    description:
      'dropdown menu link, defines what is our faxing product is, when clicked opens product page',
    defaultMessage: 'Easy Online Faxing',
  },
  faq: {
    id: '',
    description:
      'dropdown menu link, when clicked opens common question and answer page',
    defaultMessage: 'FAQ',
  },
  resources: {
    id: '',
    description:
      'navbar header text, listes all the resources for users to use',
    defaultMessage: 'Resources',
  },
  trust: {
    id: '',
    description:
      'dropdown menu link, when clicked opens trust and security page, explains our product can be trusted and is very secure',
    defaultMessage: 'Trust & Security',
  },
  support: {
    id: '',
    description: 'dropdown menu link, when clicked opens support page',
    defaultMessage: 'Support',
  },
  gettingStarted: {
    id: '',
    description:
      'dropdown menu link, when clicked opens support page, tells user about our product and guides with helpful links and question answers',
    defaultMessage: 'Getting Started',
  },
  blog: {
    id: '',
    description: 'dropdown menu link, when clicked opens blog page',
    defaultMessage: 'Blog',
  },
  developers: {
    id: '',
    description:
      'dropdown menu link, when clicked opens developers page, shows helpful guide for developers to integrate our product',
    defaultMessage: 'Developers',
  },
  digitalStrength: {
    id: '',
    description:
      'dropdown menu link, when clicked opens digital strength page, shows different courses where companies can transform from paper to digial',
    defaultMessage: 'Digital Strength',
  },
  solutions: {
    id: '',
    description:
      'dropdown menu text, shows the list of companies that has integrations with our product ',
    defaultMessage: 'Solutions',
  },
  useCases: {
    id: '',
    description:
      'dropdown menu text, shows the list of places where our eSignature product can be used like, contracts, aggreements',
    defaultMessage: 'Use Cases',
  },
  salesContracts: {
    id: '',
    description:
      'dropdown menu link, when clicked opens sales contract page, shows how our eSignature product can be used in contracts',
    defaultMessage: 'Sales Contracts',
  },
  onboardingPaperwork: {
    id: '',
    description:
      'dropdown menu link, when clicked open onboaring page, shows how onboading can be done with our eSignature product',
    defaultMessage: 'Onboarding Paperwork',
  },
  mortgageAndEscrow: {
    id: '',
    description:
      'dropdown menu link, when clicked opens page, shows how our eSignature product can help with Mortgage and Escrow paperwork',
    defaultMessage: 'Mortgage and Escrow',
  },
  financialAgreements: {
    id: '',
    description:
      'dropdown menu link, when clicked opens page, shows how our eSignature product can help with financial agreement paperwork',
    defaultMessage: 'Financial Agreements',
  },
  onDemandServices: {
    id: '',
    description:
      'dropdown menu link, when clicked opens page, shows how our eSignature product can help with On-Demand Services paperwork',
    defaultMessage: 'On-Demand Services',
  },
  insuranceDocs: {
    id: '',
    description:
      'dropdown menu link, when clicked opens page, shows how our eSignature product can help with insurance paperwork',
    defaultMessage: 'Insurance Documents',
  },
  customerStories: {
    id: '',
    description:
      'dropdown menu text, shows the list of opinions of list of companies',
    defaultMessage: 'Customer Stories',
  },
  digitizingSalesConracts: {
    id: '',
    description:
      'dropdown menu link, informs how our eSignature product digitalizes the sales contracts',
    defaultMessage: 'Digitizing Sales conracts',
  },
  speedingUpOnboarding: {
    id: '',
    description:
      'dropdown menu link, informs how our eSignature product speed ups the onbaording processes',
    defaultMessage: 'Speeding up onboarding',
  },
  simplifyingHiringPaperwork: {
    id: '',
    description:
      'dropdown menu link, informs how our eSignature product simplifies hiring process',
    defaultMessage: 'Simplifying hiring paperwork',
  },
  lightFastLoanApprovals: {
    id: '',
    description:
      'dropdown menu link, informs how our eSignature product increases the speed of proces of loan approvals',
    defaultMessage: 'Light fast loan approvals',
  },
  improvingConractManagement: {
    id: '',
    description:
      'dropdown menu link, informs how our eSignature product improves contract management',
    defaultMessage: 'Improving contract management',
  },
  streamingInsuranceForms: {
    id: '',
    description:
      'dropdown menu link, informs how our eSignature product streams insurance forms',
    defaultMessage: 'Streaming insurance forms',
  },
});

const Constants = {
  topBarScreenThreshhold: 1024, // pixels
  contactUsUrl: {
    url: webFlowUrl.concat('form/contact-us-general'),
    daCategory: 'contactus-form-start',
    daAction: `${HfReactHelper.isHelloFax() ? 'hellofax' : ''}${location.pathname}`,
    daLabel: '/contact-us-general',
  },
  topBarData: [
    {
      url: '',
      key: 'products',
      text: intl.formatMessage(messages.products),
      daCategory: 'menu',
      daAction: 'products',
      options: {
        class: 'm-link-list--link-break-1',
        containerClass: 'external-top-bar',
        subMenuItems: [
          [
            {
              url: webFlowUrl.concat('products/hellosign'),
              text: getBrandName('S'),
              daLabel: 'hs',
              img: 'hslogo',
              detail: {
                text: intl.formatMessage(messages.legallyBindingESign),
              },
            },
            {
              url: webFlowUrl.concat('features'),
              text: intl.formatMessage(messages.features),
              daLabel: 'hs-features',
            },
            {
              url: HfReactHelper.urlHelper(
                'info/pricing',
                HfReactHelper.HfSites.SITE_CODE_HS,
              ),
              text: intl.formatMessage(messages.pricing),
              daLabel: 'hs-pricing',
            },
            {
              url: webFlowUrl.concat('enterprise'),
              text: intl.formatMessage(messages.enterprise),
              daLabel: 'hs-enterprise',
            },
            {
              url: webFlowUrl.concat('what-is-hellosign'),
              text: intl.formatMessage(messages.whatIsHelloSign, {
                brandName: getBrandName('S'),
              }),
              daLabel: 'hs-what-is-hellosign',
            },
            {
              url: webFlowUrl.concat('integrations/salesforce'),
              text: intl.formatMessage(messages.helloSignSalesforce, {
                brandName: getBrandName('S'),
              }),
              daLabel: 'hs-sfdc',
            },
            {
              url: webFlowUrl.concat('integrations'),
              text: intl.formatMessage(messages.integrations),
              daLabel: 'hs-integrations',
            },
          ],
          [
            {
              url: webFlowUrl.concat('products/api'),
              text: `${getBrandName('S')} API`,
              daLabel: 'hsapi',
              img: 'hs-api-logo',
              detail: {
                text: intl.formatMessage(messages.fullyIntegratedESign),
              },
            },
            {
              url: webFlowUrl.concat('products/api/features'),
              text: intl.formatMessage(messages.features),
              daLabel: 'hsapi-features',
            },
            {
              url: HfReactHelper.urlHelper(
                'api/pricing',
                HfReactHelper.HfSites.SITE_CODE_HS,
              ),
              text: intl.formatMessage(messages.pricing),
              daLabel: 'hsapi-pricing',
            },
            {
              url: HfReactHelper.urlHelper(
                'api/documentation',
                HfReactHelper.HfSites.SITE_CODE_HS,
              ),
              text: intl.formatMessage(messages.documentation),
              daLabel: 'hsapi-documentation',
            },
            {
              url: HfReactHelper.urlHelper(
                'api/libraries',
                HfReactHelper.HfSites.SITE_CODE_HS,
              ),
              text: intl.formatMessage(messages.libraries),
              daLabel: 'hsapi-libraries',
            },
            {
              url: HfReactHelper.urlHelper(
                'api/reference',
                HfReactHelper.HfSites.SITE_CODE_HS,
              ),
              text: intl.formatMessage(messages.APIHelp),
              daLabel: 'hsapi-help',
            },
          ],
          [
            {
              url: webFlowUrl.concat('products/helloworks'),
              text: getBrandName('W'),
              daLabel: 'hw',
              img: 'hwlogo',
              detail: {
                text: intl.formatMessage(messages.mobileForms),
              },
            },
            {
              url: webFlowUrl.concat('products/helloworks/features'),
              text: intl.formatMessage(messages.features),
              daLabel: 'hw-features',
            },
            {
              url: webFlowUrl.concat('products/helloworks-pricing'),
              text: intl.formatMessage(messages.pricing),
              daLabel: 'hw-pricing',
            },
            {
              url: 'https://docs.helloworks.com/reference',
              text: intl.formatMessage(messages.documentation),
              daLabel: 'hw-documentation',
            },
          ],
          [
            {
              url: webFlowUrl.concat('products/hellofax'),
              text: getBrandName('F'),
              daLabel: 'hf',
              img: 'hflogo',
              detail: {
                text: intl.formatMessage(messages.onlineFaxing),
              },
            },
            {
              url: webFlowUrl.concat('products/hellofax/features'),
              text: intl.formatMessage(messages.features),
              daLabel: 'hf-features',
            },
            {
              url: HfReactHelper.urlHelper(
                'info/pricing',
                HfReactHelper.HfSites.SITE_CODE_HF,
              ),
              text: intl.formatMessage(messages.pricing),
              daLabel: 'hf-pricing',
            },
            {
              url: faqHsUrl.concat('/categories/200353237-HelloFax-Help'),
              text: intl.formatMessage(messages.faq),
              daLabel: 'hf-faq',
            },
          ],
          [
            {
              url: '',
              text: intl.formatMessage(messages.resources),
            },
            {
              url: webFlowUrl.concat('trust'),
              text: intl.formatMessage(messages.trust),
              daLabel: 'trust-security',
            },
            {
              url: faqHsUrl,
              text: intl.formatMessage(messages.support),
              daLabel: 'support',
            },
            {
              url: faqHsUrl.concat(
                '/articles/360018757611-Getting-Started-Guide-',
              ),
              text: intl.formatMessage(messages.gettingStarted),
              daLabel: 'getting-started',
            },
            {
              url: '',
              text: '',
              daLabel: ' ',
            },
            {
              url: webFlowUrl.concat('blog'),
              text: intl.formatMessage(messages.blog),
              daLabel: 'blog',
            },
            {
              url: webFlowUrl.concat('developers'),
              text: intl.formatMessage(messages.developers),
              daLabel: 'developers',
            },
            {
              url: webFlowUrl.concat('digitalstrength'),
              text: intl.formatMessage(messages.digitalStrength),
              daLabel: 'digital-strength',
            },
            {
              socialLinks: [
                {
                  img: 'facebook',
                  url: 'https://www.facebook.com/dropboxsign',
                  daLabel: 'facebook',
                },
                {
                  img: 'twitter',
                  url: 'https://twitter.com/DropboxSign',
                  daLabel: 'twitter',
                },
                {
                  img: 'linkedin',
                  url: 'https://www.linkedin.com/company/dropboxsign',
                  daLabel: 'linkedin',
                },
              ],
            },
          ],
        ],
      },
    },
    {
      url: '',
      key: 'solutions',
      text: intl.formatMessage(messages.solutions),
      daCategory: 'menu',
      daAction: 'solutions',
      options: {
        class: 'm-link-list--link-break-1',
        containerClass: 'external-top-bar',
        subMenuItems: [
          [
            {
              url: '',
              key: 'integrations',
              text: intl.formatMessage(messages.integrations),
            },
            {
              url: webFlowUrl.concat('integrations/dropbox'),
              text: 'Dropbox',
              img: 'dropbox',
              daLabel: 'integrations-dropbox',
            },
            {
              url: webFlowUrl.concat('integrations/gmail'),
              text: 'Gmail',
              img: 'gmail',
              daLabel: 'integrations-gmail',
            },
            {
              url: webFlowUrl.concat('integrations/google-docs'),
              text: 'Google Doc',
              img: 'googleDoc',
              daLabel: 'integrations-google-docs',
            },
            {
              url: webFlowUrl.concat('integrations/oracle'),
              text: 'Oracle',
              img: 'oracle',
              daLabel: 'integrations-oracle',
            },
            {
              url: webFlowUrl.concat('integrations/salesforce'),
              text: 'Sales Force',
              img: 'salesforce',
              daLabel: 'integrations-sfdc',
            },
            {
              url: webFlowUrl.concat('integrations/slack'),
              text: 'slack',
              img: 'slack',
              daLabel: 'integrations-slack',
            },
          ],
          [
            {
              url: '',
              key: 'use cases',
              text: intl.formatMessage(messages.useCases),
            },
            {
              url: webFlowUrl.concat('use-case/sales-teams'),
              text: intl.formatMessage(messages.salesContracts),
              daLabel: 'use-case-sales-contracts',
            },
            {
              url: webFlowUrl.concat('use-case/onboarding'),
              text: intl.formatMessage(messages.onboardingPaperwork),
              daLabel: 'use-case-onboarding-paperwork',
            },
            {
              url: webFlowUrl.concat('solutions/real-estate-tech'),
              text: intl.formatMessage(messages.mortgageAndEscrow),
              daLabel: 'use-case-mortgage-escrow',
            },
            {
              url: webFlowUrl.concat('solutions/fintech'),
              text: intl.formatMessage(messages.financialAgreements),
              daLabel: 'use-case-financial-agreements',
            },
            {
              url: webFlowUrl.concat('solutions/marketplaces'),
              text: intl.formatMessage(messages.onDemandServices),
              daLabel: 'use-case-on-demand-services',
            },
            {
              url: webFlowUrl.concat('solutions/insurance'),
              text: intl.formatMessage(messages.insuranceDocs),
              daLabel: 'use-case-insurance-documents',
            },
          ],
          [
            {
              url: '',
              key: 'customer stories',
              text: intl.formatMessage(messages.customerStories),
            },
            {
              url: webFlowUrl.concat('customers/vehiculum'),
              text: 'Vehiculum',
              daLabel: 'customer-stories-vehiculum',
              detail: {
                text: intl.formatMessage(messages.digitizingSalesConracts),
                desktopOnly: true,
              },
            },
            {
              url: webFlowUrl.concat('customers/instacart'),
              text: 'Instacart',
              daLabel: 'customer-stories-instacart',
              detail: {
                text: intl.formatMessage(messages.speedingUpOnboarding),
                desktopOnly: true,
              },
            },
            {
              url: webFlowUrl.concat('customers/fountain'),
              text: 'Fountain',
              daLabel: 'customer-stories-fountain',
              detail: {
                text: intl.formatMessage(messages.simplifyingHiringPaperwork),
                desktopOnly: true,
              },
            },
            {
              url: webFlowUrl.concat('customers/climb-credit'),
              text: 'Climb Credit',
              daLabel: 'customer-stories-climb-credit',
              detail: {
                text: intl.formatMessage(messages.lightFastLoanApprovals),
                desktopOnly: true,
              },
            },
            {
              url: webFlowUrl.concat('customers/advicepay'),
              text: 'Advice Pay',
              daLabel: 'customer-stories-advice-pay',
              detail: {
                text: intl.formatMessage(messages.improvingConractManagement),
                desktopOnly: true,
              },
            },
            {
              url: webFlowUrl.concat('customers/cgia'),
              text: 'CGIA',
              daLabel: 'customer-stories-cgia',
              detail: {
                text: intl.formatMessage(messages.streamingInsuranceForms),
                desktopOnly: true,
              },
            },
          ],
          [
            {
              url: '',
              text: intl.formatMessage(messages.resources),
            },
            {
              url: webFlowUrl.concat('trust'),
              text: intl.formatMessage(messages.trust),
              daLabel: 'trust-security',
            },
            {
              url: faqHsUrl,
              text: intl.formatMessage(messages.support),
              daLabel: 'support',
            },
            {
              url: faqHsUrl.concat(
                '/articles/360018757611-Getting-Started-Guide-',
              ),
              text: intl.formatMessage(messages.gettingStarted),
              daLabel: 'getting-started',
            },
            {
              url: '',
              text: '',
            },
            {
              url: webFlowUrl.concat('blog'),
              text: intl.formatMessage(messages.blog),
              daLabel: 'blog',
            },
            {
              url: webFlowUrl.concat('developers'),
              text: intl.formatMessage(messages.developers),
              daLabel: 'developers',
            },
            {
              url: webFlowUrl.concat('digitalstrength'),
              text: intl.formatMessage(messages.digitalStrength),
              daLabel: 'digital-strength',
            },
            {
              socialLinks: [
                {
                  img: 'facebook',
                  url: 'https://www.facebook.com/hellosignapp',
                  daLabel: 'facebook',
                },
                {
                  img: 'twitter',
                  url: 'https://www.twitter.com/HelloSign',
                  daLabel: 'twitter',
                },
                {
                  img: 'linkedin',
                  url: 'https://www.linkedin.com/company/hellosign',
                  daLabel: 'linkedin',
                },
              ],
            },
          ],
        ],
      },
    },
    {
      url: webFlowUrl.concat('developers'),
      text: intl.formatMessage(messages.developers),
      daCategory: 'developers-external-link',
      daAction: location.pathname,
      options: {
        containerClass: 'external-top-bar',
        subMenuItems: [],
      },
    },
  ],
};

export default Constants;
