import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import DeleteDocumentsConfirmationModal from './delete-documents-confirmation-modal';
import basketImg from './assets/basket.png';

const messages = defineMessages({
  btnCancel: {
    id: '', // IDs are automatically generated
    description:
      'Button text in modal to cancel the delete documents confirmation',
    defaultMessage: 'Cancel',
  },
  btnDeleteDocuments: {
    id: '', // IDs are automatically generated
    description:
      'Button text in modal to confirm the delete documents confirmation',
    defaultMessage: 'Delete documents',
  },
  modalTitleOneTimeDelete: {
    id: '', // IDs are automatically generated
    description: 'Title text for the delete documents confirmation modal',
    defaultMessage: 'One-time delete',
  },
  modalDescriptionOneTimeDelete: {
    id: '',
    description: 'Description text for the delete documents confirmation modal',
    defaultMessage:
      'You are about to permanently delete all completed documents from {brandName}, and they will not be recoverable. <a>Learn more</a>',
  },
  modalFlagText: {
    id: '',
    description: 'Title on top of the image that gives context to the action',
    defaultMessage: 'Did you save all your documents?',
  },
  modalError: {
    id: '',
    description:
      'Error message when trying to move forward with the delete documents policy setting without confirming the conditions',
    defaultMessage:
      'Oops, you have to agree with the conditions above before proceeding.',
  },
  modalUnderstandNotRecoverable: {
    id: '',
    description:
      'Condition to agree to when enabling the One-time delete documents policy setting',
    defaultMessage:
      'I understand these documents will not be recoverable from {brandName} after 72 hours of taking this action.',
  },
  modalAgreesPermanentlyDeletion: {
    id: '',
    description:
      'Condition to agree to when enabling the One-time delete documents policy setting',
    defaultMessage:
      'I agree to allow {brandName} to permanently delete all documents completed to date.',
  },
});

class OneTimeDeleteDocumentsConfirmationModal extends React.PureComponent {
  onConfirmed = () => {
    this.props.onConfirmed({ oneTimeDeletionRequest: true });
  };

  render() {
    const { onConfirmed } = this;
    const { isOpen, onRequestClose } = this.props;

    return (
      <DeleteDocumentsConfirmationModal
        isOpen={isOpen}
        onConfirmed={onConfirmed}
        onRequestClose={onRequestClose}
        flagImage={basketImg}
        messages={messages}
      ></DeleteDocumentsConfirmationModal>
    );
  }
}

export default injectIntl(OneTimeDeleteDocumentsConfirmationModal);
