import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from 'react-intl';
import { Table } from '@dropbox/dig-components/table';
import {
  Button as DigButton,
  IconButton,
} from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import TextField from 'hellospa/foundation/control/text-field';
import { FormHelperText } from '@dropbox/dig-components/form_row';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import {
  KeyLine,
  CheckmarkLine,
  CopyLine,
  CursorLine,
  DeleteLine,
  FailLine,
  InfoSmallLine,
  MoreVerticalLine,
} from '@dropbox/dig-icons/assets';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Tooltip } from '@dropbox/dig-components/tooltips';
import { Menu } from '@dropbox/dig-components/menu';
import RelativeDate from 'hellospa/common/utils/relative-date';
import HelloModal from 'common/components/hello-modal';
import Button, { ButtonType } from 'hellospa/foundation/control/button';
import styles from './api-setting.scss';
import { Text } from '@dropbox/dig-components/typography';
import { useOnMount } from 'js/sign-components/common/hooks';
import { code, strong } from 'hellospa/common/hs-intl-provider';
import { useWebAppClient } from 'hello-react/web-app-client/build-web-app-client';
import type {
  AccountApiKeyListDataResponse,
  ErrorResponse,
} from '@dropbox/sign-internal-client';
import { ApiSettingsSection } from './shared';

const messages = defineMessages({
  generateModalTitle: {
    id: '',
    description:
      'The title of the modal when the user is generating new API key',
    defaultMessage: 'New key details',
  },
  markPrimaryModalTitle: {
    id: '',
    description:
      'The title of the modal when the user is marking API key as primary key',
    defaultMessage: 'Make ‘{apiKeyName}’ the primary API key?',
  },
  renameModalTitle: {
    id: '',
    description: 'The title of the modal when the user is renaming new API key',
    defaultMessage: 'Enter a new name',
  },
  deleteModalTitle: {
    id: '',
    description: 'The title of the modal when the user is deleting new API key',
    defaultMessage: 'Are you sure you want to delete this key?',
  },
  deleteWarningModalTitle: {
    id: '',
    description:
      'The title of the warning modal when the user is deleting primary key',
    defaultMessage: 'Can’t delete key',
  },
  copyConfirmation: {
    id: '',
    description:
      'Snackbar text shown when the user copies API key successfully',
    defaultMessage: 'Value copied to clipboard',
  },
  copyError: {
    id: '',
    description: 'Snackbar error text shown when the user copies API key fails',
    defaultMessage: 'Copy failed. Please try different browser.',
  },
  renameConfirmation: {
    id: '',
    description:
      'Snackbar text shown when the user rename API key successfully',
    defaultMessage: 'API key renamed',
  },
  deleteConfirmation: {
    id: '',
    description:
      'Text for a message displayed to the user noting that the item was deleted.',
    defaultMessage: '{itemName} deleted',
  },
  apiKeyNameLabel: {
    id: '',
    description:
      'Aria label for an input text which will shown when generating and renaming API key.',
    defaultMessage: 'Name of API key',
  },
  moreOptions: {
    id: '',
    description:
      'Aria label for an vertical menu which is used for each API key actions.',
    defaultMessage: 'More options',
  },
  apiKeyNamePlaceholder: {
    id: '',
    description:
      'Placeholder text for a API key input text when generating and renaming.',
    defaultMessage: 'Provide a meaningful name, e.g. Test Key',
  },
  loadingSpinnerAriaValue: {
    id: '',
    description: 'Aria value text for loading message while fetching API key.',
    defaultMessage: 'Loading...',
  },
  primaryKeyInfoTooltip: {
    id: '',
    description:
      'Text for a hover tooltip on primary key header row in API key list table',
    defaultMessage:
      'Your primary key will be used for callback event verification',
  },
  charLimitApiKey: {
    id: '',
    description: 'Error message shown to used when limit exceeds 25 chars',
    defaultMessage: 'Key name must be 25 characters or less',
  },
  copyApiKeyTooltip: {
    id: '',
    description:
      'Text for a hover tooltip on copy button icon besides API key value',
    defaultMessage: 'Click to copy full API key',
  },
});

interface ApiKeyAction {
  guid: string;
  name: string;
  isPrimaryKey: boolean;
  action: string;
}

const activeActionDataInitialState = {
  guid: '',
  name: '',
  isPrimaryKey: false,
  action: '',
};
type dateRangeModalProps = {
  setHasNeverUsedApi: Dispatch<SetStateAction<boolean>>;
  brandName: string;
};

function APIKeySection(props: dateRangeModalProps) {
  const intl = useIntl();
  const [apiKeys, setApiKeys] = useState<AccountApiKeyListDataResponse[]>([]);
  const [loadingApiKeys, setLoadingApiKeys] = useState(true);
  const [actionError, setActionError] = useState('');
  const [isGenerateNewAPIKeyModalOpen, setIsGenerateNewAPIKeyModalOpen] =
    useState(false);
  const [disableGenerateKeyButton, setDisableGenerateKeyButton] =
    useState(false);
  const [disableRenameKeyButton, setDisableRenameKeyButton] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const client = useWebAppClient();

  const displayActionError = actionError ? (
    <FormHelperText variant="alert" id="action-error">
      <UIIcon src={FailLine} size="small" />
      {actionError}
    </FormHelperText>
  ) : null;
  const [activeActionData, setActiveActionData] = useState(
    activeActionDataInitialState,
  );
  const closeSnackbar = React.useCallback(
    (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      evt.preventDefault();
      setOpenSnackBar(false);
    },
    [],
  );
  const showSnackbar = React.useCallback(() => setOpenSnackBar(true), []);
  const openGenerateNewApiKeyModal = React.useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      // Note: to prevent unnecessary submit call which are happening due to old page
      evt.preventDefault();
      setDisableGenerateKeyButton(true);
      setIsGenerateNewAPIKeyModalOpen(true);
    },
    [],
  );
  const abortGenerateNewApiKeyModal = () => {
    setActionError('');
    setIsGenerateNewAPIKeyModalOpen(false);
  };
  const abortConfirmationModal = React.useCallback(() => {
    setActionError('');
    setActiveActionData(activeActionDataInitialState);
  }, []);
  const validate = (
    name: string,
    elementId: string,
    options: { checkLength?: boolean } = {},
  ) => {
    if (options.checkLength && name.length > 25) {
      setActionError(intl.formatMessage(messages.charLimitApiKey));
      setDisableGenerateKeyButton(true);
      setDisableRenameKeyButton(true);
    }
  };

  const changeName: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    setActionError('');
    setDisableGenerateKeyButton(false);
    setDisableRenameKeyButton(false);
    validate(evt.currentTarget.value, evt.target.id, {
      checkLength: true,
    });
  };
  const copyToClipboard = async (value: string) => {
    let snackBarCopyMessage: MessageDescriptor;
    try {
      await navigator.clipboard.writeText(value);
      snackBarCopyMessage = messages.copyConfirmation;
    } catch (error) {
      snackBarCopyMessage = messages.copyError;
    }
    showSnackbar();
    setSnackbarMessage(intl.formatMessage(snackBarCopyMessage));
  };
  const fetchApiKeys = () => {
    client.accountApiKeyApi
      .list()
      .then((response) => {
        setApiKeys(response.data);
      })
      .catch((error: ErrorResponse) => {
        if (error.error === 'Endpoint not enabled') {
          /**
         If user already in split with new UI loaded,
         and we turn off split, no backend action will work.
         It will look like UI is broken.
         So we need to reload page which will redirect page to old UI
         */
          window.location.reload();
        } else {
          setActionError(error.error);
        }
      })
      .finally(() => {
        setLoadingApiKeys(false);
      });
  };
  const generateNewApiKey = () => {
    // Get value from document because change event may not trigger if value is not changed.
    const name: string = (
      document.getElementById('new-name-api-key') as HTMLInputElement
    ).value;
    setDisableGenerateKeyButton(true);
    client.accountApiKeyApi
      .generate({ name })
      .then((_response) => {
        setIsGenerateNewAPIKeyModalOpen(false);
        fetchApiKeys();
      })
      .catch((error: ErrorResponse) => {
        if (error.error === 'Endpoint not enabled') {
          /**
           If user already in split with new UI loaded,
           and we turn off split, no backend action will work.
           It will look like UI is broken.
           So we need to reload page which will redirect page to old UI
           */
          window.location.reload();
        } else {
          setActionError(error.error);
        }
      })
      .finally(() => {
        setDisableGenerateKeyButton(false);
      });
  };
  const markApiKeyAsPrimary = (data: ApiKeyAction) => {
    setActionError('');
    client.accountApiKeyApi
      .markAsPrimary({ guid: data.guid })
      .then((_response) => {
        setActiveActionData(activeActionDataInitialState);
        fetchApiKeys();
      })
      .catch((error: ErrorResponse) => {
        if (error.error === 'Endpoint not enabled') {
          /**
         If user already in split with new UI loaded,
         and we turn off split, no backend action will work.
         It will look like UI is broken.
         So we need to reload page which will redirect page to old UI
         */
          window.location.reload();
        } else {
          setActionError(error.error);
        }
      })
      .finally(() => {
        setDisableGenerateKeyButton(false);
      });
  };
  const renameApiKey = (data: ApiKeyAction) => {
    // Get value from document because change event may not trigger if value is not changed.
    const name: string = (
      document.getElementById('rename-api-key') as HTMLInputElement
    ).value;
    client.accountApiKeyApi
      .rename({ guid: data.guid, name })
      .then((_response) => {
        setActiveActionData(activeActionDataInitialState);
        showSnackbar();
        setSnackbarMessage(intl.formatMessage(messages.renameConfirmation));
        fetchApiKeys();
      })
      .catch((error: ErrorResponse) => {
        if (error.error === 'Endpoint not enabled') {
          /**
           If user already in split with new UI loaded,
           and we turn off split, no backend action will work.
           It will look like UI is broken.
           So we need to reload page which will redirect page to old UI
           */
          window.location.reload();
        } else {
          setActionError(error.error);
        }
      })
      .finally(() => {
        setDisableGenerateKeyButton(false);
      });
  };
  const deleteApiKey = (data: ApiKeyAction) => {
    setActionError('');
    client.accountApiKeyApi
      .delete(data.guid)
      .then((_response) => {
        setActiveActionData(activeActionDataInitialState);
        showSnackbar();
        setSnackbarMessage(
          intl.formatMessage(messages.deleteConfirmation, {
            itemName: data.name,
          }),
        );
        fetchApiKeys();
      })
      .catch((error: ErrorResponse) => {
        if (error.error === 'Endpoint not enabled') {
          // Edge case when page loaded is new noe but split got off in middle of some action
          window.location.reload();
        } else {
          setActionError(error.error);
        }
      })
      .finally(() => {
        setDisableGenerateKeyButton(false);
      });
  };
  const handleSelection = (value: ApiKeyAction) => {
    setActionError('');
    setDisableRenameKeyButton(true);
    setActiveActionData({
      name: value.name,
      guid: value.guid,
      isPrimaryKey: value.isPrimaryKey,
      action: value.action,
    });
  };

  useOnMount(() => {
    fetchApiKeys();
  });

  useEffect(() => {
    if (!loadingApiKeys) {
      const hasNeverUsedApiKey =
        apiKeys !== null &&
        (apiKeys.length === 0 || apiKeys.every((key) => !key.lastUsedAt));
      props.setHasNeverUsedApi(hasNeverUsedApiKey);
    }
  }, [apiKeys, loadingApiKeys, props]);

  if (loadingApiKeys) {
    return (
      <div>
        <Spinner
          aria-valuetext={intl.formatMessage(messages.loadingSpinnerAriaValue)}
          size="small"
        />
        <FormattedMessage
          id=""
          description="Text shown while loading API key list"
          defaultMessage="Loading..."
        />
      </div>
    );
  }

  return (
    <ApiSettingsSection
      title={
        <span id="api-key-table-header">
          <FormattedMessage
            id=""
            description="title, settings page, title above a section that displays the users API key."
            defaultMessage="API keys"
          />
        </span>
      }
      description={
        <FormattedMessage
          id=""
          description="description, settings page, description above a section that displays the users API key."
          defaultMessage="Manage the keys used to authenticate with the API."
        />
      }
      action={
        <DigButton
          data-qa-ref="api-key-generate-new-key-action-open-modal-button"
          variant="outline"
          className={`api-section-right ${styles.generateApiKeyButton}`}
          onClick={openGenerateNewApiKeyModal}
        >
          <FormattedMessage
            id=""
            description="button text on settings page to generate new API key"
            defaultMessage="Generate key"
          />
        </DigButton>
      }
    >
      <Table aria-labelledby="api-key-table-header" data-qa-ref="api-key-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage
                id=""
                description="The heading for a column which shows the names of API keys."
                defaultMessage="Name"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id=""
                description="The heading for a column which shows the value of API keys."
                defaultMessage="Value"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id=""
                description="The heading for a column which shows the created date of API keys."
                defaultMessage="Created (UTC)"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id=""
                description="The heading for a column which shows the last used date of API keys."
                defaultMessage="Last used (UTC)"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id=""
                description="The heading for a column which shows if API key is Primary key or not."
                defaultMessage="Primary key"
              />
              <Tooltip
                title={intl.formatMessage(messages.primaryKeyInfoTooltip)}
              >
                <span>
                  <UIIcon
                    src={InfoSmallLine}
                    className={styles.apiIconButton}
                  />
                </span>
              </Tooltip>
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body data-qa-ref="api-key-table-body">
          {apiKeys &&
            apiKeys.map((item: AccountApiKeyListDataResponse) => (
              <Table.Row key={item.apiKey} data-qa-ref="api-key-table-row">
                <Table.Cell data-qa-ref="api-key-name-data">
                  <Text className={styles.apiWhitespace}>{item.name}</Text>
                </Table.Cell>
                <Table.Cell data-qa-ref="api-key-partial-value-data">
                  {/* Shows only the first four and last four characters of the API key. */}
                  {/* Ex: "D1F3...CE40" */}
                  <Text monospace>
                    {item.apiKey.substring(0, 4)}...{item.apiKey.slice(-4)}
                    <Tooltip
                      title={intl.formatMessage(messages.copyApiKeyTooltip)}
                    >
                      <span>
                        <IconButton
                          data-qa-ref="api-key-copy-action-button"
                          type="button"
                          variant="transparent"
                        >
                          <UIIcon
                            src={CopyLine}
                            onClick={() => copyToClipboard(item.apiKey)}
                            className={styles.apiCopyIconButton}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Text>
                </Table.Cell>
                <Table.Cell data-qa-ref="api-key-created-at-data">
                  <Text>
                    <RelativeDate date={new Date(item.createdAt as string)} />
                  </Text>
                </Table.Cell>
                <Table.Cell data-qa-ref="api-key-last-used-at-data">
                  <Text>
                    {item.lastUsedAt ? (
                      <RelativeDate date={new Date(item.lastUsedAt)} />
                    ) : (
                      <FormattedMessage
                        id=""
                        description="The value for a column if last used at date is null or empty"
                        defaultMessage="Never"
                      />
                    )}
                  </Text>
                </Table.Cell>
                <Table.Cell data-qa-ref="api-key-is-primary-key-data">
                  <Text>
                    {item.isPrimaryKey && (
                      <UIIcon
                        src={CheckmarkLine}
                        size="small"
                        className={styles.apiIconButton}
                      />
                    )}
                    <FormattedMessage
                      id=""
                      description="The value for a column which shows whether the API key is the primary key or not."
                      defaultMessage="{isPrimaryKey, select, true {Yes} other {No}}"
                      values={{
                        isPrimaryKey: item.isPrimaryKey,
                      }}
                    />
                  </Text>
                </Table.Cell>
                <Table.Cell>
                  <Menu.Wrapper
                    hasMaxHeight={480}
                    onSelection={handleSelection}
                    shouldPropagateClickOutsideMouseEvents
                  >
                    {({ getTriggerProps, getContentProps }) => (
                      <>
                        <IconButton
                          data-qa-ref="api-key-menu-icon-open-button"
                          type="button"
                          variant="transparent"
                          {...getTriggerProps()}
                        >
                          <UIIcon
                            src={MoreVerticalLine}
                            aria-label={intl.formatMessage(
                              messages.moreOptions,
                            )}
                          />
                        </IconButton>
                        <Menu.Content
                          {...getContentProps()}
                          positioningStrategy="fixed"
                          auto={false}
                          placement="bottom-end"
                        >
                          <Menu.Segment>
                            {(apiKeys.length === 1 && !item.isPrimaryKey) ||
                            (apiKeys.length > 1 && !item.isPrimaryKey) ? (
                              <Menu.ActionItem
                                key="menu-mark-primary"
                                value={{
                                  guid: item.guid,
                                  action: 'mark-primary',
                                  name: item.name,
                                  isPrimaryKey: item.isPrimaryKey,
                                }}
                                data-qa-ref="api-key-menu-mark-primary-button"
                                preferComposition
                              >
                                <Menu.RowAccessory>
                                  <UIIcon src={KeyLine} />
                                </Menu.RowAccessory>
                                <Menu.RowContent>
                                  <Menu.RowTitle>
                                    <FormattedMessage
                                      id=""
                                      description="Option in a drop down allowing the user to select action to mark API key as primary key"
                                      defaultMessage="Make primary"
                                    />
                                  </Menu.RowTitle>
                                </Menu.RowContent>
                              </Menu.ActionItem>
                            ) : null}
                            <Menu.ActionItem
                              key="menu-rename"
                              value={{
                                guid: item.guid,
                                action: 'rename',
                                name: item.name,
                                isPrimaryKey: item.isPrimaryKey,
                              }}
                              data-qa-ref="api-key-menu-rename-button"
                              preferComposition
                            >
                              <Menu.RowAccessory>
                                <UIIcon src={CursorLine} role="presentation" />
                              </Menu.RowAccessory>
                              <Menu.RowContent>
                                <Menu.RowTitle>
                                  <FormattedMessage
                                    id=""
                                    description="Option in a drop down allowing the user to select action to rename API key"
                                    defaultMessage="Rename"
                                  />
                                </Menu.RowTitle>
                              </Menu.RowContent>
                            </Menu.ActionItem>
                            {apiKeys.length > 1 && (
                              <Menu.ActionItem
                                key="menu-delete"
                                value={{
                                  guid: item.guid,
                                  action: 'delete',
                                  name: item.name,
                                  isPrimaryKey: item.isPrimaryKey,
                                }}
                                data-qa-ref="api-key-menu-delete-button"
                                preferComposition
                              >
                                <Menu.RowAccessory>
                                  <UIIcon src={DeleteLine} />
                                </Menu.RowAccessory>
                                <Menu.RowContent>
                                  <Menu.RowTitle>
                                    <FormattedMessage
                                      id=""
                                      description="Option in a drop down allowing the user to select action to delete API key"
                                      defaultMessage="Delete"
                                    />
                                  </Menu.RowTitle>
                                </Menu.RowContent>
                              </Menu.ActionItem>
                            )}
                          </Menu.Segment>
                        </Menu.Content>
                      </>
                    )}
                  </Menu.Wrapper>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <HelloModal
        isOpen={isGenerateNewAPIKeyModalOpen}
        onRequestClose={abortGenerateNewApiKeyModal}
        contentLabel={intl.formatMessage(messages.generateModalTitle)}
        buttons={[
          <Button
            data-qa-ref="hellomodal-generate-new-key-action-cancel-button"
            key="cancel"
            kind={ButtonType.Subtle}
            onClick={abortGenerateNewApiKeyModal}
          >
            <FormattedMessage
              id=""
              description="Cancel button on the generate API key modal"
              defaultMessage="Cancel"
            />
          </Button>,
          <Button
            data-qa-ref="hellomodal-generate-new-key-action-submit-button"
            key="generate-button"
            kind={ButtonType.Primary}
            disabled={disableGenerateKeyButton}
            onClick={generateNewApiKey}
          >
            <FormattedMessage
              id=""
              description="Generate key button on the generate API key modal"
              defaultMessage="Generate key"
            />
          </Button>,
        ]}
      >
        <TextField
          placeholder={intl.formatMessage(messages.apiKeyNamePlaceholder)}
          id="new-name-api-key"
          aria-label={intl.formatMessage(messages.apiKeyNameLabel)}
          autoComplete="off"
          aria-describedby="action-error"
          onChange={changeName}
        />
        {displayActionError}
      </HelloModal>
      <HelloModal
        isOpen={activeActionData.action === 'mark-primary'}
        onRequestClose={abortConfirmationModal}
        contentLabel={intl.formatMessage(messages.markPrimaryModalTitle, {
          apiKeyName: activeActionData.name,
        })}
        buttons={[
          <Button
            data-qa-ref="hellomodal-mark-primary-action-cancel-button"
            key="cancel"
            kind={ButtonType.Subtle}
            onClick={abortConfirmationModal}
          >
            <FormattedMessage
              id=""
              description="Cancel button on the generate API key modal"
              defaultMessage="Cancel"
            />
          </Button>,
          <Button
            data-qa-ref="hellomodal-mark-primary-action-submit-button"
            key="mark-primary-button"
            kind={ButtonType.Primary}
            onClick={() => markApiKeyAsPrimary(activeActionData)}
          >
            <FormattedMessage
              id=""
              description="Make primary button on the mark API key as primary modal"
              defaultMessage="Make primary"
            />
          </Button>,
        ]}
      >
        <span>
          <FormattedMessage
            id=""
            description="Mark primary key modal body"
            values={{
              // eslint-disable-next-line react/display-name
              learnmore: (chunks: unknown[]) => (
                <a target="_blank" href="/api/eventsAndCallbacksWalkthrough">
                  {chunks}
                </a>
              ),
              code,
              strong,
            }}
            defaultMessage="Making this key primary affects the <code>event_hash</code> and <strong>Content-MD5</strong>
    values generated in callback events. If you’re currently using these hashes for verification,
    please ensure that your callback handler is ready to verify callbacks using this key first.
     <learnmore>Learn more</learnmore>"
          />
        </span>
        {displayActionError}
      </HelloModal>
      <HelloModal
        isOpen={activeActionData.action === 'rename'}
        onRequestClose={abortConfirmationModal}
        contentLabel={intl.formatMessage(messages.renameModalTitle)}
        buttons={[
          <Button
            data-qa-ref="hellomodal-rename-action-cancel-button"
            key="cancel"
            kind={ButtonType.Subtle}
            onClick={abortConfirmationModal}
          >
            <FormattedMessage
              id=""
              description="Cancel button on the generate API key modal"
              defaultMessage="Cancel"
            />
          </Button>,
          <Button
            data-qa-ref="hellomodal-rename-action-submit-button"
            key="rename-button"
            kind={ButtonType.Primary}
            disabled={disableRenameKeyButton}
            onClick={() => renameApiKey(activeActionData)}
          >
            <FormattedMessage
              id=""
              description="Done button on the rename API key modal"
              defaultMessage="Done"
            />
          </Button>,
        ]}
      >
        <TextField
          placeholder={intl.formatMessage(messages.apiKeyNamePlaceholder)}
          id="rename-api-key"
          defaultValue={activeActionData.name}
          aria-label={intl.formatMessage(messages.apiKeyNameLabel)}
          aria-describedby="action-error"
          autoComplete="off"
          onChange={changeName}
        />
        {displayActionError}
      </HelloModal>
      <HelloModal
        isOpen={
          activeActionData.action === 'delete' && !activeActionData.isPrimaryKey
        }
        onRequestClose={abortConfirmationModal}
        contentLabel={intl.formatMessage(messages.deleteModalTitle)}
        buttons={[
          <Button
            data-qa-ref="hellomodal-delete-action-cancel-button"
            key="cancel"
            kind={ButtonType.Subtle}
            onClick={abortConfirmationModal}
          >
            <FormattedMessage
              id=""
              description="Cancel button on the generate API key modal"
              defaultMessage="Cancel"
            />
          </Button>,
          <Button
            data-qa-ref="hellomodal-delete-action-submit-button"
            key="delete-button"
            kind={ButtonType.Primary}
            onClick={() => deleteApiKey(activeActionData)}
          >
            <FormattedMessage
              id=""
              description="Delete button on the delete API key modal"
              defaultMessage="Delete"
            />
          </Button>,
        ]}
      >
        <FormattedMessage
          id=""
          description="The body text for a modal which allows the user to delete their API key."
          defaultMessage="Any applications using this key will no longer be able to access the {brandName} API. You can’t undo this action."
          values={{
            brandName: props.brandName,
          }}
        />
        {displayActionError}
      </HelloModal>
      <HelloModal
        isOpen={
          activeActionData.action === 'delete' && activeActionData.isPrimaryKey
        }
        onRequestClose={abortConfirmationModal}
        contentLabel={intl.formatMessage(messages.deleteWarningModalTitle)}
        buttons={[
          <Button
            data-qa-ref="hellomodal-primary-key-delete-action-cancel-button"
            key="cancel"
            kind={ButtonType.Primary}
            onClick={abortConfirmationModal}
          >
            <FormattedMessage
              id=""
              description="Dismiss button on the delete warning API key modal"
              defaultMessage="Dismiss"
            />
          </Button>,
        ]}
      >
        <FormattedMessage
          id=""
          description="Description text on not allowed delete action on API key"
          defaultMessage="In order to delete this key, first make any other key the Primary key."
        />
      </HelloModal>
      <div>
        <Snackbar
          open={openSnackBar}
          onRequestClose={() => setOpenSnackBar(false)}
          className={styles.digSnackbarCustom}
        >
          <UIIcon src={CheckmarkLine} />
          <Snackbar.Message>{snackbarMessage}</Snackbar.Message>
          <Snackbar.Actions>
            <DigButton
              variant="transparent"
              inverse
              onClick={closeSnackbar}
              data-qa-ref="snackbar-close"
            >
              <FormattedMessage
                id=""
                description="Close button on the snackbar notification API key setting page"
                defaultMessage="Close"
              />
            </DigButton>
          </Snackbar.Actions>
        </Snackbar>
      </div>
    </ApiSettingsSection>
  );
}

export default APIKeySection;
