import './admin-settings-row-group.scss';

import React from 'react';
import classnames from 'classnames';

function AdminSettingsRowGroup({ defaultToggled = false, ...props }) {
  const [isToggled, setIsToggled] = React.useState(defaultToggled);
  const baseClass = 'admin-settings-row-group';

  const rootClasses = React.useMemo(() => {
    return classnames(baseClass, props.className, {
      [`${baseClass}--is-toggled`]: isToggled,
      [`${baseClass}--flush`]: props.flush,
      [`${baseClass}--borderless`]: props.borderless,
    });
  }, [props.className, props.borderless, props.flush, isToggled]);

  return (
    <div className={rootClasses}>
      {props.children({ isToggled, setIsToggled })}
    </div>
  );
}

export default AdminSettingsRowGroup;
