/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len
*/
import React from 'react';
import PropTypes from 'prop-types';
import baseform from 'common/components/base-form';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import logger from 'common/logger';
import pushAnalyticsEvent from 'common/utils/pushAnalyticsEvent';
import { trackHeapCustomEvent } from 'js/sign-components/common/heap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import HelloModal from 'common/components/hello-modal';
import { getTrackingVariant } from 'common/utils/getTrackingVariant';
import { currency } from 'hellospa/common/hs-intl-provider';
import { getBrandName } from 'js/sign-components/common/brand';
import HfPaymentForm from './hf-payment-form';
import HFCCAddressForm from './hf-cc-address-form';

const messages = defineMessages({
  ccPlaceholderName: {
    id: '',
    description:
      'credit card information, placeholder, credit card holder name',
    defaultMessage: 'Cardholder Name',
  },
  ccPlaceholderNumber: {
    id: '',
    description: 'credit card information, placeholder, credit card number',
    defaultMessage: 'Credit card number',
  },
  ccPlaceholderCVV: {
    id: '',
    description:
      'credit card information, placeholder, credit card CVV, security digits on the back of a credit card',
    defaultMessage: 'CVV',
  },
  ccPlaceholderZip: {
    id: '',
    description:
      'credit card information, placeholder, credit card zip code, billing area',
    defaultMessage: 'ZIP code',
  },
  submitButton: {
    id: '',
    description: 'label, button, proceeds to submitting payment form',
    defaultMessage: 'Continue',
  },
  acceptTermsOfService: {
    id: '',
    description:
      'warning message in the payment sign up form that tells the user to accept the terms',
    defaultMessage: 'Please agree to the terms of service.',
  },
  creditCardHolderError: {
    id: '',
    description:
      'warning message in the payment sign up form that tells the user card card name is required',
    defaultMessage: 'Cardholder name is required.',
  },
  creditCardCVCError: {
    id: '',
    description:
      'warning message in the payment sign up form that tells the user card card security code is required',
    defaultMessage: 'Please enter a valid security code',
  },
  creditCardStreetError: {
    id: '',
    description:
      'warning message in the payment sign up form that tells the users billing street address is required',
    defaultMessage: 'Please enter the street address',
  },
  creditCardCityError: {
    id: '',
    description:
      'warning message in the payment sign up form that tells the users billing city is required',
    defaultMessage: 'Please enter the city',
  },
  creditCardStateError: {
    id: '',
    description:
      'warning message in the payment sign up form that tells the users billing state is required',
    defaultMessage: 'Please enter the state',
  },
  creditCardCountryError: {
    id: '',
    description:
      'warning message in the payment sign up form that tells the users billing country is required',
    defaultMessage: 'Please select the country',
  },
  creditCardZipError: {
    id: '',
    description:
      'warning message in the payment sign up form that tells the users billing zip code is required',
    defaultMessage: 'Please enter the ZIP code',
  },
  hfAreaCodeError: {
    id: '',
    description:
      'form submission error, user has to select an area code to proceed',
    defaultMessage: 'Please select an area code',
  },
  areaCodeNotAvailableError: {
    id: '',
    description:
      'error message on pricing modal, area code not available for selected state and country',
    defaultMessage: 'There are currently no numbers available in this country',
  },
  areaCodeCallError: {
    id: '',
    description:
      'error message on pricing modal, error when making rest api call to get area codes',
    defaultMessage:
      'An unknown error occurred. Please reload the page and try again.',
  },
  invalidNumberError: {
    id: '',
    description: 'error message pricing modal, shows when cc number is invalid',
    defaultMessage: 'The card number is not a valid credit card number.',
  },
  invalidExpiryMonthError: {
    id: '',
    description:
      'error message pricing modal, shows when cc expiration date is invalid',
    defaultMessage: "Your card's expiration month is invalid.",
  },
});

const convertPlanName = (planName) => {
  if (planName == null) {
    return planName;
  }

  switch (planName.toLowerCase()) {
    case 'business':
      return 'b5';
    default:
      return planName.toLowerCase();
  }
};

/**
 * NOTE: This form does not follow the proper way to use
 * React and Symfony Forms, a better example can be found
 * by looking at ForgotPasswordForm
 */
const PaymentForm = baseform.createFormClass({
  displayName: 'PaymentForm',

  propTypes: {
    stripeToken: PropTypes.string,
    dbxPaymentMethod: PropTypes.object,
    planId: PropTypes.string,
    planName: PropTypes.string.isRequired,
    couponCode: PropTypes.string,
    timePeriod: PropTypes.string,
    tabOffset: PropTypes.number,
    card: PropTypes.object,
    buttonText: PropTypes.string,
    headerText: PropTypes.string,
    billingString: PropTypes.object,
    ccExpiration: PropTypes.string,
    formAction: PropTypes.string,
    numSeats: PropTypes.number,
    currentPlanName: PropTypes.string,
    errors: PropTypes.object,
    isApi: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool,
    appElement: PropTypes.object,
  },

  CARD_TYPES: [
    { long: 'MasterCard', short: 'mastercard' },
    { long: 'Visa', short: 'visa' },
    { long: 'American Express', short: 'amex' },
    { long: 'Discover', short: 'discover' },
  ],

  getDefaultProps() {
    return {
      formAction: '',
      numSeats: 1,
    };
  },

  getInitialState() {
    const globalError =
      (this.props.errors && this.props.errors['payment[submit]']) || null;
    return {
      card: $.extend(
        {
          expYear: this.getDefaultExpYear(),
          expMonth: this.getDefaultExpMonth(),
          number: '0',
          cvv: '',
          type: 'Unknown',
        },
        this.props.card,
      ),
      isProcessing: false,
      stripeToken: this.props.stripeToken || '',
      errors: { global: globalError },
      hfForm: {
        selectedArea: '-',
        tabOffsetBy: 0,
        hasAreaCode: true,
        areaCodeCallSuccess: true,
      },
      paymentStreet: '',
      paymentCity: '',
      paymentState: '',
      paymentCountry: 'US',
      fieldMap: this.mapFieldsToNames(
        this.props.paymentForm ? this.props.paymentForm.fields : null,
      ),
    };
  },

  mapFieldsToNames(fields) {
    if (!fields || !fields.length) return {};

    return fields.reduce((map, field) => {
      if (field.type !== 'inputhidden') {
        map[field.name] = field;
      }
      return map;
    }, {});
  },

  getHFformData(data) {
    this.setState((prevState) => {
      return {
        hfForm: {
          tabOffsetBy: (() => {
            if (typeof data.isCountryUk !== 'undefined') {
              return data.isCountryUk ? 2 : 3;
            }
            return prevState.hfForm.tabOffsetBy;
          })(),
          selectedArea:
            typeof data.selectedArea !== 'undefined'
              ? data.selectedArea
              : prevState.hfForm.selectedArea,
          hasAreaCode:
            typeof data.hasAreaCode !== 'undefined'
              ? data.hasAreaCode
              : prevState.hfForm.hasAreaCode,
          areaCodeCallSuccess:
            typeof data.areaCodeCallSuccess !== 'undefined'
              ? data.areaCodeCallSuccess
              : prevState.hfForm.areaCodeCallSuccess,
        },
      };
    });
  },

  handleChangeCCAddress(name, value) {
    this.setState({
      [name]: value,
    });
  },

  // By default we set the expiration date to 1 month from today
  getDefaultExpMonth() {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1); // Here 12 will result in January next year (0)
    // Get the next month with offset of +1 because js date stores months in 0-11
    const nextMonth = currentDate.getMonth() + 1;
    // For 1 digit months add leading 0 before returning
    return nextMonth < 10 ? `0${nextMonth.toString()}` : nextMonth.toString();
  },

  getDefaultExpYear() {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    // If december -> return next year
    return currentMonth == 11 ? currentYear + 1 : currentYear;
  },

  onContinueClick() {
    const { intl } = this.props;
    if (
      !this.props.renewalChecked &&
      $('#ack_auto_renewal_checkbox').length > 0
    ) {
      this.setState({
        errors: { global: intl.formatMessage(messages.acceptTermsOfService) },
        isProcessing: false,
      });
      return;
    }

    // Detect if the payment method is DBX and submit the form
    // without CC validation
    const dbxPaymentMethod = this.props.dbxPaymentMethod;
    if (
      !this.state.enterManually &&
      dbxPaymentMethod &&
      dbxPaymentMethod.method_id
    ) {
      this.submitForm();
      return;
    }

    pushAnalyticsEvent({
      category: 'signup',
      action: `credit card submitted - ${getTrackingVariant(this.props.isApi)}`,
      label: convertPlanName(this.props.planName),
    });

    trackHeapCustomEvent('pick_plan_trial_continue', {
      plan_name: this.props.planName,
      plan_id: this.props.planId,
    });

    const card = this.state.card;
    if (HfReactHelper.isHelloFax() && this.props.registerFaxLineForm) {
      if (this.state.hfForm.selectedArea === '-') {
        this.setState({
          errors: { global: intl.formatMessage(messages.hfAreaCodeError) },
          isProcessing: false,
        });
        return;
      } else if (!this.state.hfForm.hasAreaCode) {
        this.setState({
          errors: {
            global: intl.formatMessage(messages.areaCodeNotAvailableError),
          },
          isProcessing: false,
        });
        return;
      } else if (!this.state.hfForm.areaCodeCallSuccess) {
        this.setState({
          errors: { global: intl.formatMessage(messages.areaCodeCallError) },
          isProcessing: false,
        });
        return;
      }
    }

    if (!card.suffix) {
      const cvc = card.cvv.trim();
      if (!card.name || card.name.length < 1) {
        this.setState({
          errors: {
            global: intl.formatMessage(messages.creditCardHolderError),
          },
          isProcessing: false,
        });
        return;
      } else if (!cvc || cvc.length < 3 || cvc.length > 4) {
        this.setState({
          errors: { global: intl.formatMessage(messages.creditCardCVCError) },
          isProcessing: false,
        });
        return;
      } else if (!card.zip || card.zip.length < 1) {
        this.setState({
          errors: { global: intl.formatMessage(messages.creditCardZipError) },
          isProcessing: false,
        });
        return;
      }
    }

    if (HfReactHelper.isHelloFax() && this.state.fieldMap.cc_street) {
      const { paymentStreet, paymentCity, paymentState, paymentCountry } =
        this.state;
      if (!paymentStreet || paymentStreet.length < 1) {
        this.setState({
          errors: {
            global: intl.formatMessage(messages.creditCardStreetError),
          },
          isProcessing: false,
        });
        return;
      } else if (!paymentCity || paymentCity.length < 1) {
        this.setState({
          errors: { global: intl.formatMessage(messages.creditCardCityError) },
          isProcessing: false,
        });
        return;
      } else if (!paymentState || paymentState.length < 1) {
        this.setState({
          errors: { global: intl.formatMessage(messages.creditCardStateError) },
          isProcessing: false,
        });
        return;
      } else if (!paymentCountry || paymentCountry.length < 1) {
        this.setState({
          errors: {
            global: intl.formatMessage(messages.creditCardCountryError),
          },
          isProcessing: false,
        });
        return;
      }
    }

    if (this.state.isProcessing === false) {
      this.setState({
        errors: null,
        isProcessing: true,
      });

      if (card.suffix) {
        this.submitForm();
        return;
      }

      Stripe.card.createToken(
        {
          number: card.number,
          cvc: card.cvv,
          exp_month: card.expMonth,
          exp_year: card.expYear,
          address_zip: card.zip,
          name: card.name,
        },
        (status, response) => {
          if (response.error) {
            if (response.error.code === 'invalid_number') {
              this.setState({
                errors: {
                  global: intl.formatMessage(messages.invalidNumberError),
                },
                isProcessing: false,
              });
            } else if (response.error.code === 'invalid_expiry_month') {
              this.setState({
                errors: {
                  global: intl.formatMessage(messages.invalidExpiryMonthError),
                },
                isProcessing: false,
              });
            } else {
              this.setState({
                errors: { global: response.error.message },
                isProcessing: false,
              });
            }
          } else {
            const token = response.id;
            this.setState({
              stripeToken: token,
            });

            pushAnalyticsEvent({
              category: 'signup',
              action: `account created - ${getTrackingVariant(this.props.isApi)}`,
              label: convertPlanName(this.props.planName),
            });

            this.submitForm();
          }
        },
      );
    }

    return false;
  },

  submitForm() {
    logger.track('PaymentForm._onSuccess', {
      previousPlanName: this.props.currentPlanName,
      newPlanName: this.props.name,
    });
    $('#payment_form').submit();
  },

  onCardType(event) {
    const card = this.state.card;
    card.type = Stripe.cardType(event.target.value);
    card.number = event.target.value;
    this.setState({ card });
  },

  onExpMonthChange(event) {
    const card = this.state.card;
    card.expMonth = event.target.value;
    this.setState({ card });
  },

  onExpYearChange(event) {
    const card = this.state.card;
    card.expYear = event.target.value;
    this.setState({ card });
  },

  onCvvChange(event) {
    const card = this.state.card;
    card.cvv = event.target.value;
    this.setState({ card });
  },

  onZipChange(event) {
    const card = this.state.card;
    card.zip = event.target.value;
    this.setState({ card });
  },

  onCardholderNameChange(event) {
    const card = this.state.card;
    card.name = event.target.value;
    this.setState({ card });
  },

  onSwitch() {
    this.setState({ enterManually: !this.state.enterManually });
  },

  getCCBrandLogoClassSuffix(brand) {
    switch (brand) {
      case 'American Express':
        return 'amex';
      case 'Discover':
        return 'discover';
      case 'Elo':
        return 'elo';
      case 'JCB':
        return 'jcb';
      case 'Maestro':
        return 'maestro';
      case 'MasterCard':
        return 'mastercard';
      case 'UnionPay':
        return 'unionpay';
      case 'Visa':
        return 'visa';
      case 'Unknown':
      default:
        return '';
    }
  },

  render() {
    const { intl } = this.props;
    let formFields;
    let stripeTokenField;
    let defaultCurrencyField;
    const { card, enterManually, hfForm } = this.state;
    const tabOffset = parseInt(this.props.tabOffset || 1000, 10);
    const dbxPaymentMethod = this.props.dbxPaymentMethod;
    const tabOffsetBy = hfForm.tabOffsetBy;
    // Stripe token
    if (this.state.stripeToken) {
      stripeTokenField = (
        <input
          type="hidden"
          name="payment[stripe_token]"
          value={this.state.stripeToken}
        />
      );
    }

    if (
      !card.suffix &&
      (HfReactHelper.isHelloSign() || this.props.registerFaxLineForm)
    ) {
      defaultCurrencyField = (
        <input
          type="hidden"
          name="payment[currency]"
          value={currency.toLowerCase()}
        />
      );
    }

    const longDescription = (
      <div className="m-payment--billing-string">
        <p>{this.props.billingString}</p>
      </div>
    );

    const cardDisplays = this.CARD_TYPES.map((type, i) => {
      return (
        <img
          key={i}
          src={HfReactHelper.imgHelper(
            `api/pricing/upgrade_popup/${type.short}_${card.type == type.long ? '' : 'in'}active.png`,
          )}
        />
      );
    });

    const hasDbxPaymentMethod = dbxPaymentMethod && dbxPaymentMethod.method_id;

    // Form fields
    if (!card.suffix) {
      const switchToDbxMethod = hasDbxPaymentMethod ? (
        <div className="row">
          <div
            className="m-payment--dbx-switch columns small-12"
            onClick={this.onSwitch}
          >
            <FormattedMessage
              id=""
              description="payment form, alternative payment method, allows customer to make payment through Dropbox system."
              defaultMessage="I'd like to use my Dropbox payment method"
            />
          </div>
        </div>
      ) : (
        ''
      );

      if (hasDbxPaymentMethod && !enterManually) {
        const dbxCCBrandLogo = this.getCCBrandLogoClassSuffix(
          dbxPaymentMethod.brand,
        );
        // Show user their existing DBX payment method
        formFields = (
          <div>
            <div className="row m-payment--wrapper">
              <div className="columns small-12 large-7">
                <div className="row">
                  <div className="columns small-12 text-left">
                    <h5 className="m-payment--dbx-header">
                      <FormattedMessage
                        id=""
                        description="header, initiates process for making a payment through Dropbox system"
                        defaultMessage="Use your Dropbox payment method on {brandName}"
                        values={{
                          brandName: getBrandName('S'),
                        }}
                      />
                    </h5>
                    <p className="m-payment--dbx-subheader">
                      <FormattedMessage
                        id=""
                        description="text, label, informs user that our product and Dropbox are single entity and user can use their Dropbox payment method on our app"
                        defaultMessage="We're a part of Dropbox so we've made it easier to use the same credit card."
                      />
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="m-payment--dbx-card-wrapper columns small-12">
                    <div
                      className={`m-payment--cc-logo m-payment--cc-logo--brand-${dbxCCBrandLogo}`}
                    />
                    <div>
                      <span>{dbxPaymentMethod.name}</span>
                      <br />
                      <span>
                        <FormattedMessage
                          id=""
                          description="label, displays last 4 digits of a card to be used for charging"
                          defaultMessage="Card ending in"
                        />
                      </span>{' '}
                      <span className="m-payment--dbx-card-last-four-digits">
                        ****{dbxPaymentMethod.last_four_digits}
                      </span>
                      <br />
                      <span>
                        <FormattedMessage
                          id=""
                          description="label, displays expiration date for a card to be used for charging"
                          defaultMessage="Expires {expirationDate}"
                          values={{
                            expirationDate: dbxPaymentMethod.expiration_date,
                          }}
                        />
                      </span>
                      <input
                        type="hidden"
                        name="dbx_payment_method_id"
                        value={dbxPaymentMethod.method_id}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="m-payment--manual-switch columns small-12"
                    onClick={this.onSwitch}
                  >
                    <FormattedMessage
                      id=""
                      description="actionable label, initiates process to input different card for charging"
                      defaultMessage="I'd like to use another card"
                    />
                  </div>
                </div>
              </div>
              <div className="m-payment--left-border columns small-12 large-5">
                {longDescription}
              </div>
            </div>
          </div>
        );
      } else {
        // A regular CC form
        const curYear = new Date().getFullYear();
        const numYears = 10;
        const yearOptions = Array(...Array(numYears)).map((val, offset) => {
          const year = curYear + offset;
          return (
            <option value={year} key={offset}>
              {year}
            </option>
          );
        });

        formFields = (
          <div>
            <div className="row m-payment--wrapper">
              <div className="columns small-12 large-7">
                {HfReactHelper.isHelloFax() &&
                  this.props.registerFaxLineForm && (
                    <HfPaymentForm
                      fields={this.props.registerFaxLineForm.fields}
                      values={this.props.registerFaxLineForm.values}
                      namespace={this.props.registerFaxLineForm.namespace}
                      getHFformData={this.getHFformData}
                      cardSuffix={card.suffix}
                      tabOffset={tabOffset}
                    />
                  )}
                <div className="row mt20">
                  <div className="m-payment--label columns small-12 text-left">
                    <p>
                      <FormattedMessage
                        id=""
                        description="label, intro to form for adding credit card information "
                        defaultMessage="Enter your credit card information"
                      />
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="columns small-12">
                    <input
                      type="text"
                      placeholder={intl.formatMessage(
                        messages.ccPlaceholderName,
                      )}
                      className="m-payment--cardholder-name columns small-8"
                      value={card.name}
                      onChange={this.onCardholderNameChange}
                      tabIndex={tabOffset + tabOffsetBy}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="columns small-12 m-payment--cc">
                    <input
                      type="text"
                      placeholder={intl.formatMessage(
                        messages.ccPlaceholderNumber,
                      )}
                      onInput={this.onCardType}
                      tabIndex={tabOffset + tabOffsetBy + 1}
                    />
                    <div className="m-payment--ccs show-for-medium-up">
                      {cardDisplays}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="columns small-12 large-6 small-text-left large-text-right">
                    <select
                      className="m-payment--cc-exp-month columns small-4"
                      value={card.expMonth}
                      onChange={this.onExpMonthChange}
                      tabIndex={tabOffset + tabOffsetBy + 2}
                      data-qa-ref="credit-card-expiry-month"
                      data-testid="credit-card-expiry-month"
                    >
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                    <select
                      className="m-payment--cc-exp-year columns small-8"
                      value={card.expYear}
                      onChange={this.onExpYearChange}
                      tabIndex={tabOffset + tabOffsetBy + 3}
                      data-qa-ref="credit-card-expiry-year"
                      data-testid="credit-card-expiry-year"
                    >
                      {yearOptions}
                    </select>
                  </div>
                  <div className="columns small-12 large-6 large-text-left small-text-right">
                    <input
                      type="text"
                      placeholder={intl.formatMessage(
                        messages.ccPlaceholderCVV,
                      )}
                      className="m-payment--cvv columns small-4"
                      onChange={this.onCvvChange}
                      tabIndex={tabOffset + tabOffsetBy + 4}
                    />
                    <input
                      type="text"
                      placeholder={intl.formatMessage(
                        messages.ccPlaceholderZip,
                      )}
                      name="payment[cc_zip]"
                      className="m-payment--zip columns small-8"
                      onChange={this.onZipChange}
                      tabIndex={tabOffset + tabOffsetBy + 5}
                    />
                  </div>
                </div>
                {switchToDbxMethod}
              </div>
              <div className="m-payment--left-border columns small-12 large-5">
                {HfReactHelper.isHelloFax() && this.props.paymentForm && (
                  <HFCCAddressForm
                    fields={this.props.paymentForm.fields}
                    values={this.props.paymentForm.values}
                    fieldMap={this.state.fieldMap}
                    namespace={this.props.paymentForm.namespace}
                    getHFformData={this.getHFformData}
                    handleChange={this.handleChangeCCAddress}
                    tabOffset={tabOffset + tabOffsetBy + 6}
                  />
                )}
                {longDescription}
              </div>
            </div>
          </div>
        );
      }
    } else if (this.props.monthlyExpectedRate == 0) {
      formFields = (
        <div>
          <div className="row">
            <div className="m-payment--billing-string columns small-5">
              <p>{this.props.billingString}</p>
            </div>
          </div>
        </div>
      );
    } else if (HfReactHelper.isHelloFax() && this.props.registerFaxLineForm) {
      formFields = (
        <div>
          <div className="row m-payment--wrapper">
            <div className="columns small-12 large-6">
              {HfReactHelper.isHelloFax() && this.props.registerFaxLineForm && (
                <HfPaymentForm
                  fields={this.props.registerFaxLineForm.fields}
                  values={this.props.registerFaxLineForm.values}
                  namespace={this.props.registerFaxLineForm.namespace}
                  getHFformData={this.getHFformData}
                  cardSuffix={card.suffix}
                  tabOffset={tabOffset}
                />
              )}
            </div>
            <div className="m-payment--left-border columns small-12 large-6">
              <div className="row">
                <div className="columns small-12 text-left m-payment-size">
                  <p>
                    <FormattedMessage
                      id=""
                      description="label, displays card information that will be charged"
                      defaultMessage="The following card will be charged:"
                    />
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="columns small-12 text-left m-payment--cc-info">
                  <h4>xxxx xxxx xxxx {this.state.card.suffix}</h4>
                  <h4>
                    <FormattedMessage
                      id=""
                      description="label, informs user about card expiration date that will be used to perform a charge"
                      defaultMessage="Expires {expirationDate}"
                      values={{
                        expirationDate: this.props.ccExpiration,
                      }}
                    />
                  </h4>
                </div>
                <div className="m-payment--billing-string columns small-12">
                  {longDescription}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      formFields = (
        <div>
          <div className="row">
            <div className="columns small-12 text-left m-payment-size">
              <p>
                <FormattedMessage
                  id=""
                  description="label, displays card information that will be charged"
                  defaultMessage="The following card will be charged:"
                />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="columns small-12 text-left m-payment--cc-info">
              <h4>xxxx xxxx xxxx {this.state.card.suffix}</h4>
              <h4>
                <FormattedMessage
                  id=""
                  description="label, informs user about card expiration date that will be used to perform a charge"
                  defaultMessage="Expires {expirationDate}"
                  values={{
                    expirationDate: this.props.ccExpiration,
                  }}
                />
              </h4>
            </div>
            <div className="m-payment--billing-string columns small-5">
              {longDescription}
            </div>
          </div>
        </div>
      );
    }
    return (
      <HelloModal
        key={`payment_form_modal_${this.props.planId}`}
        appElement={this.props.appElement}
        isOpen={this.props.isModalOpen}
        onRequestClose={this.props.closeModal}
        contentLabel={this.props.headerText}
        contentClasses="hello-modal__content--payment-form-modal"
        buttons={[
          {
            type: 'secondary',
            text: 'Cancel',
            onClick: this.props.closeModal,
          },
          {
            type: 'primary',
            onClick: this.onContinueClick,
            extraClasses: 'm-payment--button hello-modal--close-button',
            text: intl.formatMessage(messages.submitButton),
            buttonText: intl.formatMessage(messages.submitButton),
            'data-qa-ref': 'continue-button-credit-card-window',
          },
        ]}
      >
        <div className="m-payment">
          <form
            id="payment_form"
            action={this.props.formAction}
            method="POST"
            onSubmit={this.onContinueClick}
          >
            {formFields}
            <div className="row l-margin-v-10">
              <div className="columns small-12 large-7 text-left m-payment--error">
                {this.renderErrorText()}
              </div>
            </div>
            {stripeTokenField}
            {defaultCurrencyField}
            {this.renderCsrfField()}
            <input type="hidden" name="cardHolderName" value={card.name} />
            <input
              type="hidden"
              name="payment[num_seats]"
              value={this.props.numSeats}
            />
            <input
              type="hidden"
              name="payment[target_plan]"
              value={this.props.planId}
            />
            <input
              type="hidden"
              name="payment[coupon_code]"
              value={this.props.couponCode || ''}
            />
            <input
              type="hidden"
              name="payment[period]"
              value={this.props.timePeriod}
            />
          </form>
        </div>
      </HelloModal>
    );
  },
});

module.exports = injectIntl(PaymentForm);
