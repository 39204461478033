/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  func-names,
  import/no-extraneous-dependencies,
  max-len,
  no-alert,
  no-restricted-syntax,
  no-tabs,
  no-void
*/
import React from 'react';
import PropTypes from 'prop-types';
import logger from 'common/logger';
import createReactClass from 'create-react-class';
import { injectIntl, defineMessages } from 'react-intl';
import { getSupportEmailAddress } from 'js/sign-components/common/brand';
import Button from './button';
import ExternalServiceTypes from '../constants/ExternalServiceTypes';

const ExternalServiceUpdateURL = '/account/updateExternalService';
const MyAccountIntegrationsHomeURL = '/home/myAccount?current_tab=integrations';

const messages = defineMessages({
  activate: {
    id: '', // IDs are procedurally generated.
    description:
      'button text in setting page, when clicked activates third party integration',
    defaultMessage: 'Activate',
  },
  deactivate: {
    id: '',
    description:
      'button text in setting page, when clicked deactivates third party integration',
    defaultMessage: 'Deactivate',
  },
  disabled: {
    id: '',
    description:
      'button text in setting page, un-clickable because the the integration is disabled',
    defaultMessage: 'Disabled',
  },
  error: {
    id: '',
    description: 'Message shown to the user when an unknown error occurs',
    defaultMessage:
      'An error occurred, try again or contact support at {supportEmail}',
  },
});

const IntegrationActivationButton = createReactClass({
  propTypes: {
    isActive: PropTypes.bool.isRequired,
    serviceTypeCode: PropTypes.string,
    buttonLink: PropTypes.string,
    intl: PropTypes.object.isRequired,
    isEnabled: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      buttonLink: '#',
    };
  },

  // Original logic moved from myAccount.php
  clickHandler(event) {
    event.stopPropagation();
    event.preventDefault();

    const el = $(event.target).closest('div');
    const isActivated = el.hasClass('is-activated');
    const textContainer = $('button .l-nowrap > span', el);
    const text = $.trim(textContainer.text());
    const servTypeCode = $(' > a button', el).attr('rel');

    const postData = {
      service_type: servTypeCode,
      is_deactivate: isActivated ? '1' : '0',
      csrf_token: $('#update_ext_serv_csrf_token').val(),
    };

    // If activating Google Drive
    if (!isActivated && servTypeCode == ExternalServiceTypes.GOOGLE_DRIVE) {
      if ($.browser.msie && parseInt($.browser.version, 10) < 8) {
        hellofaxJS.openFancyBoxPopup_v3(
          '<div class="browser_warning"><strong>This option requires a more up to date browser</strong><p>Download the latest <a href="http://windows.microsoft.com/en-US/internet-explorer/downloads/ie" target="_blank" rel="noopener noreferrer">Microsoft Internet Explorer</a> or <a href="http://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">Google Chrome</a></p></div>',
          { padding: 20 },
        );
        return;
      }
    }

    const showAsBusy = function (isBusy) {
      textContainer.html(isBusy ? 'Activating...' : text);
    };

    showAsBusy(true);

    $.ajax({
      dataType: 'json',
      type: 'POST',
      url: ExternalServiceUpdateURL,
      data: postData,
      success(data) {
        let integration;
        for (const key in ExternalServiceTypes) {
          if (ExternalServiceTypes[key] === postData.service_type) {
            integration = key;
          }
        }
        const eventType =
          postData.is_deactivate == 0 ? 'activate' : 'deactivate';
        logger.track(`IntegrationActivationButton._${eventType}`, {
          integration,
        });

        showAsBusy(false);
        if (data.auth_url) {
          window.location = data.auth_url.replace(/&amp;/g, '&'); // FIXME: Needed because it gets returned HTML-encoded
        } else {
          window.location = MyAccountIntegrationsHomeURL;
        }
      },
      error() {
        showAsBusy(false);
        alert(
          this.props.intl.formatMessage(messages.error, {
            supportEmail: getSupportEmailAddress('S'),
          }),
        );
      },
    });
  },

  render() {
    const hasCustomButtonLink = this.props.buttonLink !== '#';
    const { intl } = this.props;
    let buttonText = '';
    if (this.props.isEnabled) {
      buttonText = this.props.isActive
        ? intl.formatMessage(messages.deactivate)
        : intl.formatMessage(messages.activate);
    } else {
      buttonText = intl.formatMessage(messages.disabled);
    }

    const btn = (
      <Button
        buttonText={buttonText}
        buttonLink={hasCustomButtonLink ? this.props.buttonLink : void 0}
        buttonClass="border-1-ocean-blue"
        buttonColor={this.props.isActive ? 'ocean-blue' : 'white'}
        buttonTextColor={this.props.isActive ? 'white' : 'ocean-blue'}
        buttonHoverTextColor="white"
        buttonHoverColor="ocean-blue"
        openInNewWindow={true}
        buttonAttrs={
          this.props.serviceTypeCode
            ? { rel: this.props.serviceTypeCode }
            : null
        }
        buttonLinkUsePost={false}
        disabled={!this.props.isEnabled}
        onClick={hasCustomButtonLink ? void 0 : this.clickHandler}
      />
    );

    return btn;
  },
});

module.exports = injectIntl(IntegrationActivationButton);
