import hsFetch from 'signer-app/utils/hs-fetch';
import type {
  SignerAppRequest,
  Page,
  Rule,
  SignerField,
} from 'js/sign-components/generated/types/HelloRequest';
import { assertDataIsValid } from 'signer-app/utils/ajv-validation';
import _request from 'signer-app/context/signer-app-client/_request';

export type LegacySignerData = {
  dpi: number;
  pages: Page[];
  fields: SignerField[];
  rules: Rule[];
  signedData?: Record<
    string,
    {
      type: string;
      data: Record<string, unknown>;
    }
  >;
};

/**
 * Custom request object sent by the legacy signer application for the
 * '/signer/ready' endpoint
 *
 * **NOTE**: Incomplete type definition. Only types data known to be used by
 * existing stories and tests.
 */
export type LegacySignerReadyRequest = {
  url: string;
  method: 'GET';
  query: {
    // eslint-disable-next-line camelcase
    tsm_guid: string;
    token: string;
    _c?: number;
  };
};

/**
 * Response sent by the server for '/signer/ready' endpoint
 *
 * **NOTE**: Incomplete type definition. Only types data known to be used by
 * existing stories and tests.
 */
export type LegacySignerReadyResponse = {
  success: boolean;
  error: unknown;
  data: {
    ready: boolean;
    error: boolean;
  } | null;
};

/**
 * Custom request object sent by the legacy signer application for the
 * '/signer/load' endpoint
 *
 * **NOTE**: Incomplete type definition. Only types data known to be used by
 * existing stories and tests.
 */
export type LegacySignerLoadRequest = {
  url: string;
  method: 'GET';
  query: {
    guid: string;
    /* eslint-disable camelcase */
    status_token: string;
    access_code?: unknown;
    signature_id?: unknown;
    selected_signer?: unknown;
    tsm_guid?: string;
    trigger_sms?: unknown;
    sms_auth_code?: unknown;
    _c?: number;
    /* eslint-enable camelcase */
  };
};

/**
 * Response sent by the server for '/signer/load' endpoint
 *
 * **NOTE**: Incomplete type definition. Only types data known to be used by
 * existing stories and tests.
 */
export type LegacySignerLoadResponse = {
  success: boolean;
  error: unknown;
  data: unknown;
};

/**
 * Custom request object sent by the legacy signer application for the
 * '/signer/save' endpoint
 *
 * **NOTE**: Incomplete type definition. Only types data known to be used by
 * existing stories and tests.
 */
export type LegacySignerSaveRequest = {
  url: string;
  method: 'POST';
  form: boolean;
  data: string;
  query?: string;
  headers?: {
    accept?: string;
  };
};

/**
 * Response sent by the server for '/signer/save' endpoint
 *
 * **NOTE**: Incomplete type definition. Only types data known to be used by
 * existing stories and tests.
 */
export type LegacySignerSaveResponse = {
  success: boolean;
  error: unknown | null;
  data: unknown | null;
};

/**
 * Custom request object sent by the legacy signer application for the
 * '/signer/finalize' endpoint
 *
 * **NOTE**: Incomplete type definition. Only types data known to be used by
 * existing stories and tests.
 */
export type LegacySignerFinalizeRequest = {
  url: string;
  method: 'POST';
  data: string;
};

/**
 * Response sent by the server for '/signer/finalize' endpoint
 *
 * **NOTE**: Incomplete type definition. Only types data known to be used by
 * existing stories and tests.
 */
export type LegacySignerFinalizeResponse = {
  success: boolean;
  error: unknown;
  data: unknown;
};

function mapHelloRequestToLegacy(data: SignerAppRequest): LegacySignerData {
  return {
    dpi: 80,
    ...data,
  };
}

export async function loadData(
  cachedParamsToken: string,
): Promise<LegacySignerData> {
  const url = `/signer/getData?cached_params_token=${cachedParamsToken}`;
  const response = await hsFetch(url);
  if (!response.ok) {
    const errorResponse = await response.text();
    let errorMessage = 'unable to fetch signer data';
    if (errorResponse) {
      errorMessage += ` - ${errorResponse}`;
    }
    throw new Error(errorMessage);
  }
  const { data } = await response.json();
  return {
    dpi: 80,
    ...data,
  };
}

export async function loadUnifiedData(
  cachedParamsToken: string,
): Promise<LegacySignerData> {
  const url = `/signer/getUnifiedData?cached_params_token=${cachedParamsToken}`;
  const response = await hsFetch(url);
  if (!response.ok) {
    const errorResponse = await response.text();
    let errorMessage = 'unable to fetch signer data';
    if (errorResponse) {
      errorMessage += ` - ${errorResponse}`;
    }
    throw new Error(errorMessage);
  }
  const { data } = await response.json();
  const { validateSignerAppRequest } = await import(
    'js/sign-components/generated/validators/validateHelloRequest'
  );
  assertDataIsValid(validateSignerAppRequest, data);
  return mapHelloRequestToLegacy(data);
}

/**
 * The old model code has always called `_request` directly. For a while there were fucntions here
 * that were unused, but existed so we could create mocks.
 *
 * Now we are calling through these functions, but there should be no behavior
 * change with just calling out to `_request`
 */
export const finalize = (args: LegacySignerFinalizeRequest) =>
  _request(args as any);
export const save = (args: LegacySignerSaveRequest) => _request(args as any);
export const load = (args: LegacySignerLoadRequest) => _request(args as any);
export const ready = (args: LegacySignerReadyRequest) => _request(args as any);
