/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len
*/
import React from 'react';
import PropTypes from 'prop-types';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import createReactClass from 'create-react-class';
import cx from 'classnames';
import ClickableImage from './clickable-image';

const Tab = createReactClass({
  propTypes: {
    index: PropTypes.number.isRequired,
    onTabClick: PropTypes.func.isRequired,
    clickableItemData: PropTypes.object,
    isActive: PropTypes.bool.isRequired,
    tabClassNames: PropTypes.object,
    tabTypes: PropTypes.string,
    imgSourceWidth: PropTypes.number,
    imgSourceHeight: PropTypes.number,
  },
  getInitialState() {
    return { isActive: this.props.index == 0 };
  },
  onTabClick() {
    this.props.onTabClick(this.props.index);
  },
  render() {
    let classNames = {};

    classNames = HfReactHelper.extend(
      {
        'show-for-large-up': false,
        'is-active': this.props.isActive,
      },
      this.props.tabClassNames || {},
    );

    if (this.props.clickableItemData.tabTypes == 'tabs') {
      classNames = HfReactHelper.extend(
        { 'm-tabs--tab': true, 'large-3': true, columns: true },
        classNames,
      );
    } else if (this.props.clickableItemData.tabTypes == 'tiles') {
      classNames = HfReactHelper.extend(
        { 'm-tabs_tiles--tiles': true },
        classNames,
      );
    }

    const classes = cx(classNames);
    if (this.props.clickableItemData.tabTypes == 'tabs') {
      return (
        <div className={classes} onClick={this.onTabClick}>
          {' '}
          {this.props.clickableItemData.title}{' '}
        </div>
      );
    } else if (this.props.clickableItemData.tabTypes == 'tiles') {
      const clickableImage = (
        <ClickableImage
          imgSourceActiveWidth={
            this.props.clickableItemData.imgSourceActiveWidth
          }
          imgSourceActiveHeight={
            this.props.clickableItemData.imgSourceActiveHeight
          }
          imgSourceInactiveWidth={
            this.props.clickableItemData.imgSourceInactiveWidth
          }
          imgSourceInactiveHeight={
            this.props.clickableItemData.imgSourceInactiveHeight
          }
          imgSourceActive={this.props.clickableItemData.imgSourceActive}
          imgSourceInactive={this.props.clickableItemData.imgSourceInactive}
          isActive={this.props.isActive}
          imgText={this.props.clickableItemData.imgText}
        />
      );
      return (
        <div className={classes} onClick={this.onTabClick}>
          {' '}
          {clickableImage}{' '}
        </div>
      );
    }
  },
});

module.exports = Tab;
