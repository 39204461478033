import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import HelloModal from 'common/components/hello-modal';
import { getBrandName } from 'js/sign-components/common/brand';

const messages = defineMessages({
  btnCancel: {
    id: '', // IDs are automatically generated
    description:
      'Button text in modal to cancel the deactivate ongoing delete documents confirmation',
    defaultMessage: 'Cancel',
  },
  btnDeactivate: {
    id: '', // IDs are automatically generated
    description:
      'Button text in modal to confirm the deactivation of ongoing delete documents',
    defaultMessage: 'Deactivate ongoing delete',
  },
  modalTitleDeactivateOngoingDelete: {
    id: '', // IDs are automatically generated
    description: 'Title text for the delete documents confirmation modal',
    defaultMessage: 'Ready to deactivate ongoing delete?',
  },
});

class DeactivateOngoingDeleteDocumentsModal extends React.PureComponent {
  handleConfirm = async () => {
    this.setState({ submitted: true });

    const { onConfirmed, onRequestClose } = this.props;

    onConfirmed({ ongoingDeletionRequest: false });
    onRequestClose();
  };

  buttons = [
    {
      type: 'secondary',
      text: this.props.intl.formatMessage(messages.btnCancel),
      onClick: this.props.onRequestClose,
    },
    {
      type: 'primary',
      text: this.props.intl.formatMessage(messages.btnDeactivate),
      onClick: this.handleConfirm,
    },
  ];

  render() {
    const { isOpen, onRequestClose } = this.props;

    return (
      <HelloModal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={false}
        onRequestClose={onRequestClose}
        contentLabel={this.props.intl.formatMessage(
          messages.modalTitleDeactivateOngoingDelete,
        )}
        buttons={this.buttons}
      >
        <p>
          <FormattedMessage
            id=""
            description="Description text for the deactivate ongoing delete documents confirmation modal"
            defaultMessage="By deactivating ongoing delete, your completed documents going forward will no longer be deleted automatically from {brandName}."
            values={{
              brandName: getBrandName('S'),
            }}
          />
        </p>
      </HelloModal>
    );
  }
}

export default injectIntl(DeactivateOngoingDeleteDocumentsModal);
