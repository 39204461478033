import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Banner } from '@dropbox/dig-components/banner';
import { getBrandName, SiteCodeType } from 'js/sign-components/common/brand';
import { Title, Text, Link } from '@dropbox/dig-components/typography';
import { Button } from '@dropbox/dig-components/buttons';
import { PictogramIcon } from '@dropbox/dig-icons';
import { WarningPictogram } from '@dropbox/dig-icons/assets';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

const messages = defineMessages({
  bannerTitle: {
    id: '',
    description: 'banner title',
    defaultMessage: 'You now have limited access',
  },
  manualInvoiceAdmin: {
    id: '',
    description:
      'Message notifying team admins of manually invoiced subscriptions that they now have limited access',
    defaultMessage:
      "We didn't receive your payment, so you now have <a>limited access to {brandName}</a>. " +
      'Please contact us to update your billing information so you can continue using <b>{brandName} {prevPlanName}</b> features.',
  },
  creditCardAdmin: {
    id: '',
    description:
      'Message notifying credit card team admin that their subscription has limited access.',
    defaultMessage:
      'We were unable to bill your credit card, so you now have <a>limited access to {brandName}</a>. Please update your billing so you can continue using  <b>{brandName} {prevPlanName}</b> features.',
  },
  teamMember: {
    id: '',
    description:
      'Message notifying credit card team members that their subscription has limited access.',
    defaultMessage:
      'We were unable to bill the credit card for this plan, so you now have <a>limited access to {brandName}</a>. ' +
      'Contact the team admin to update billing so you can continue using <b>{brandName} {prevPlanName}</b> features',
  },
});

type Props = {
  prevPlanName?: string;
  siteCode: SiteCodeType;
  canManageBilling?: boolean;
  hasFrozenSubscription?: boolean;
  hasAlternativeBilling?: boolean;
};

const BillingNotification = (props: Props) => {
  const {
    siteCode,
    prevPlanName,
    canManageBilling,
    hasFrozenSubscription,
    hasAlternativeBilling,
  } = props;
  const intl = useIntl();

  return (
    <>
      {hasFrozenSubscription && (
        <Banner
          variant="rich"
          type="alert"
          withLeftPictogram={
            <PictogramIcon src={WarningPictogram} role="presentation" />
          }
        >
          <Banner.Message>
            <Title size="small">
              {intl.formatMessage(messages.bannerTitle)}
            </Title>

            {/* Invoice Admins message */}
            {hasAlternativeBilling && canManageBilling && (
              <Text>
                {intl.formatMessage(messages.manualInvoiceAdmin, {
                  brandName: getBrandName(siteCode),
                  prevPlanName,
                  // eslint-disable-next-line react/display-name
                  b: (...chunks: any[]) => (
                    <Text key="bold-text" isBold>
                      {chunks}
                    </Text>
                  ),
                  // eslint-disable-next-line react/display-name
                  a: (...chunks: any[]) => (
                    <Link
                      key="article"
                      href={HfReactHelper.getFaqUrl('articles/13819801045133')}
                    >
                      {chunks}
                    </Link>
                  ),
                })}
              </Text>
            )}

            {/* Credit Card Admins  message */}
            {canManageBilling && !hasAlternativeBilling && (
              <Text>
                {intl.formatMessage(messages.creditCardAdmin, {
                  brandName: getBrandName(siteCode),
                  prevPlanName,
                  // eslint-disable-next-line react/display-name
                  b: (...chunks: any[]) => (
                    <Text key="bold-text" isBold>
                      {chunks}
                    </Text>
                  ),
                  // eslint-disable-next-line
                  a: (...chunks: any[]) => (
                    <Link
                      key="article"
                      href={HfReactHelper.getFaqUrl('/articles/13819801045133')}
                    >
                      {chunks}
                    </Link>
                  ),
                })}
              </Text>
            )}

            {/* Team members message */}
            {!canManageBilling && (
              <Text>
                {intl.formatMessage(messages.teamMember, {
                  brandName: getBrandName(siteCode),
                  prevPlanName,
                  // eslint-disable-next-line react/display-name
                  b: (...chunks: any[]) => (
                    <Text key="bold-text" isBold>
                      {chunks}
                    </Text>
                  ),
                  // eslint-disable-next-line react/display-name
                  a: (...chunks: any[]) => (
                    <Link
                      key="article"
                      href={HfReactHelper.getFaqUrl('/articles/13819801045133')}
                    >
                      {chunks}
                    </Link>
                  ),
                })}
              </Text>
            )}
          </Banner.Message>

          {canManageBilling && (
            <Banner.Actions>
              {hasAlternativeBilling ? (
                <Button
                  variant="outline"
                  href="mailto:invoices@billing.dropbox.com"
                  data-testid="billing-notifcation-contact-btn"
                >
                  <FormattedMessage
                    id=""
                    description="Text for a button to contact."
                    defaultMessage="Contact us"
                  />
                </Button>
              ) : (
                <Button
                  variant="outline"
                  href={HfReactHelper.urlHelper('/account/updateCard')}
                  data-testid="billing-notifcation-contact-btn"
                >
                  <FormattedMessage
                    id=""
                    description="Text for a button to update billing."
                    defaultMessage="Update billing"
                  />
                </Button>
              )}
            </Banner.Actions>
          )}
        </Banner>
      )}
    </>
  );
};
export default BillingNotification;
