import {
  TourDataShape,
  TourShape,
  getTourData,
  mergeTourData,
} from 'signer-app/context/signer-app-client/tour-local-storage-utils';
import React, { useEffect, useMemo } from 'react';
import { TourName } from 'js/sign-components/common/TourName';
import { ToursContext } from 'js/sign-components/sign-app-client/tours-provider';
import { useLatestRef } from 'js/sign-components/common/use-latest-ref';
import memoize from 'lodash/memoize';
import { useSignAppClient } from 'js/sign-components/sign-app-client/context';
import invariant from 'invariant';

function useGetIsEligible() {
  const client = useSignAppClient();
  const { tours } = client;
  invariant(tours, 'Expected to find a tours namespace');
  const toursRef = useLatestRef(tours);
  const memoizedIsEligible = useMemo(
    () => memoize(toursRef.current.isEligibleForTour),
    [toursRef],
  );

  return memoizedIsEligible;
}

/**
 * Returns whether tourName is the active tour
 *
 * @param tourName
 * @param ops.requestActive Whether to make tourName the active tour if no tour is active
 * @returns
 */
export function useIsActiveTour(
  tourName: TourName,
  { requestActive = true, disableBackend = false } = {},
) {
  const { activeTour, setActiveTour } = ToursContext.useContext();
  const { completedTours } = ToursContext.useContext();
  const isCompleted = completedTours.includes(tourName);
  const someTourIsOpen = activeTour !== null;
  const getIsEligible = useGetIsEligible();

  React.useEffect(() => {
    if (!isCompleted && !someTourIsOpen && requestActive) {
      const isEligible = disableBackend
        ? Promise.resolve(true)
        : getIsEligible(tourName);
      isEligible.then((shouldMakeActive) => {
        if (shouldMakeActive) {
          setActiveTour((currentActiveTour) => {
            if (currentActiveTour !== null) {
              return currentActiveTour;
            }
            return tourName;
          });
        }
      });
    }
  }, [
    tourName,
    isCompleted,
    someTourIsOpen,
    requestActive,
    setActiveTour,
    getIsEligible,
    disableBackend,
  ]);

  /**
   * When the hook unmounts, we need to cleanup the currently active tour.
   * This was added so that different tours become visible as the user
   * navigates through prep and send
   */
  React.useEffect(() => {
    return function cleanup() {
      if (requestActive) {
        setActiveTour((activeTour) => {
          if (activeTour === tourName) {
            return null;
          } else {
            return activeTour;
          }
        });
      }
    };
  }, [tourName, requestActive, setActiveTour]);
  return tourName === activeTour;
}

/**
 * Returns true if there is some open tour on the page
 */
export function useSomeTourIsOpen() {
  const { activeTour } = ToursContext.useContext();
  return !!activeTour;
}

type ActiveTourGateProps = React.PropsWithChildren<{
  tourName: TourName;
  requestActive?: boolean;
  disableBackend?: boolean;
}>;

/**
 * ActiveTourGate ensures only one Tour is rendered at a time. The earliest rendered tour is given
 * priority
 *
 * Children are only rendered if tourName is the active tour.
 * If no tour is currently active, tourName will be made the active tour.
 *
 * @param props.tourName The tour in question
 * @returns
 */
export function ActiveTourGate({
  tourName,
  children,
  requestActive = true,
  disableBackend = false,
}: ActiveTourGateProps) {
  const isActiveTour = useIsActiveTour(tourName, {
    requestActive,
    disableBackend,
  });

  if (!isActiveTour) {
    return null;
  }

  return <>{children}</>;
}

/**
 *
 * @returns data for all tours as stored in localstorage
 */
export function useToursLocalData() {
  const [tourData, _setTourData] = React.useState(getTourData);

  function _mergeTourData(dataToMerge: TourDataShape) {
    const merged = mergeTourData(dataToMerge);
    _setTourData(merged);
  }

  return [tourData, _mergeTourData] as const;
}

/**
 * @returns data for current tour
 */
export function useIsTourCompleted(tourName: TourName) {
  const [allTours] = useToursLocalData();
  const { completedTours } = ToursContext.useContext();

  const tourData: TourShape = allTours[tourName] || {
    completed: false,
  };

  const isCompleted = completedTours.includes(tourName) || tourData.completed;
  return isCompleted;
}

export function useCompleteTour() {
  const { tours } = useSignAppClient();
  const toursRef = useLatestRef(tours);
  const { addToCompletedTours } = ToursContext.useContext();

  return React.useCallback(
    (tourName: TourName, disableBackend: boolean = false) => {
      addToCompletedTours(tourName);
      toursRef.current.completeTour(tourName, { disableBackend });
    },
    [toursRef, addToCompletedTours],
  );
}

/**
 * Previously we stored all tour state solely on the frontend
 * and would show the tour once on each device the user owns.
 * Now that we have a tour backend, this hook checks if the tour
 * is marked as complete on the frontend and not the backend, and
 * if so, it sends a request to mark it as complete on the backend
 * @param tourName
 * @param disableBackend
 */
export function useBackendTourServiceMigrator(
  tourName: TourName,
  disableBackend: boolean,
) {
  const completeTour = useCompleteTour();
  const { completedTours } = ToursContext.useContext();
  const isCompletedOnBackend = completedTours.includes(tourName);

  useEffect(() => {
    if (!disableBackend) {
      const isCompletedOnFrontend = getTourData()[tourName]?.completed || false;
      const needsToBeCompletedOnBackend =
        isCompletedOnFrontend && !isCompletedOnBackend;
      if (needsToBeCompletedOnBackend) {
        completeTour(tourName, false);
      }
    }
  }, [tourName, isCompletedOnBackend, completeTour, disableBackend]);
}
