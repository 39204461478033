import React from 'react';
import { injectIntl } from 'react-intl';
import HelloModal from 'common/components/hello-modal';
import Checkbox from 'common/components/checkbox';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { getBrandName } from 'js/sign-components/common/brand';
import styles from './delete-documents-confirmation-modal.scss';

class DeleteDocumentsConfirmationModal extends React.PureComponent {
  state = {
    agreesPermanentlyDeletion: false,
    understandNotRecoverable: false,
    submitted: false,
  };

  handleConfirm = async () => {
    this.setState({ submitted: true });

    const { agreesPermanentlyDeletion, understandNotRecoverable } = this.state;
    const { onConfirmed } = this.props;

    if (agreesPermanentlyDeletion && understandNotRecoverable) {
      // All good
      onConfirmed();

      this.onRequestClose();
    }
  };

  toggleAgreesPermanentDeletion = () => {
    this.setState({
      agreesPermanentlyDeletion: !this.state.agreesPermanentlyDeletion,
    });
  };

  toggleUnderstandNotRecoverable = () => {
    this.setState({
      understandNotRecoverable: !this.state.understandNotRecoverable,
    });
  };

  hasError = () => {
    const { agreesPermanentlyDeletion, understandNotRecoverable, submitted } =
      this.state;

    return (
      !(agreesPermanentlyDeletion && understandNotRecoverable) && submitted
    );
  };

  onRequestClose = (...data) => {
    this.setState({
      agreesPermanentlyDeletion: false,
      understandNotRecoverable: false,
      submitted: false,
    });

    this.props.onRequestClose(...data);
  };

  render() {
    const { isOpen, intl, flagImage, messages } = this.props;
    const hasError = this.hasError();

    const { agreesPermanentlyDeletion, understandNotRecoverable } = this.state;

    const buttons = [
      {
        type: 'secondary',
        text: this.props.intl.formatMessage(messages.btnCancel),
        onClick: this.onRequestClose,
      },
      {
        type: 'delete',
        text: this.props.intl.formatMessage(messages.btnDeleteDocuments),
        onClick: this.handleConfirm,
        disabled: !(agreesPermanentlyDeletion && understandNotRecoverable),
      },
    ];

    return (
      <HelloModal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={false}
        onRequestClose={this.onRequestClose}
        contentLabel={this.props.intl.formatMessage(
          messages.modalTitleOneTimeDelete,
        )}
        buttons={buttons}
        contentClasses={styles['delete-documents-modal-content']}
        withFlag={true}
        flag={{
          title: intl.formatMessage(messages.modalFlagText),
          image: flagImage,
        }}
      >
        <p className={styles['modal-title']}>
          {intl.formatMessage(messages.modalDescriptionOneTimeDelete, {
            brandName: getBrandName('S'),
            a: (...chunks) => (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={HfReactHelper.getFaqUrl('articles/360043023071')}
              >
                {chunks}
              </a>
            ),
          })}
        </p>
        <hr className={styles.divider} />
        <div className={styles['checkbox-container']}>
          <Checkbox
            data-testid="agrees-permanent-deletion-checkbox"
            id="agrees-permanent-deletion"
            checked={this.state.agreesPermanentlyDeletion}
            indeterminate={false}
            onChange={this.toggleAgreesPermanentDeletion}
          />
          <label htmlFor="agrees-permanent-deletion">
            <p className={styles['checkbox-description']}>
              {intl.formatMessage(messages.modalAgreesPermanentlyDeletion, {
                brandName: getBrandName('S'),
                startDate: new Date(),
              })}
            </p>
          </label>
        </div>
        <div className={styles['checkbox-container']}>
          <Checkbox
            data-testid="understand-not-recoverable-checkbox"
            id="understand-not-recoverable"
            checked={this.state.understandNotRecoverable}
            indeterminate={false}
            onChange={this.toggleUnderstandNotRecoverable}
          />
          <label htmlFor="understand-not-recoverable">
            <p className={styles['checkbox-description']}>
              {intl.formatMessage(messages.modalUnderstandNotRecoverable, {
                brandName: getBrandName('S'),
              })}
            </p>
          </label>
        </div>
        <hr className={styles.divider} />
        {hasError && (
          <span className={styles.error}>
            {intl.formatMessage(messages.modalError)}
          </span>
        )}
      </HelloModal>
    );
  }
}

export default injectIntl(DeleteDocumentsConfirmationModal);
