import React from 'react';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import CheckMark from './pricing-levels/checkmark';

const PricingLevelFeature = createReactClass({
  render() {
    const containerClasses = classNames({
      'm-pricing-level--feature': true,
      'small-12': true,
      columns: true,
      block: true,
      expanded: this.props.expanded,
      'main-feature': this.props.key === 'main-feature',
    });

    let primaryColor = 'c-cerulean';
    let trueColor = 'var(--dig-color__primary__base)';

    if (HfReactHelper.isHelloFax()) {
      primaryColor = 'c-goldenrod';
      trueColor = 'var(--dig-color__primary__base)';
    }

    const numberClasses = classNames({
      number: true,
      [primaryColor]: true,
      bold: true,
    });

    const nameClasses = 'name bold';

    const name = (
      <div className={nameClasses}>
        {this.props.name}
        {this.props.sup ? (
          <sup
            style={{
              fontSize: '9px',
            }}
          >
            †
          </sup>
        ) : (
          <></>
        )}
      </div>
    );
    const number =
      !this.props.number && this.props.number !== 0 ? (
        <></>
      ) : (
        <div className={numberClasses}>{this.props.number}</div>
      );
    const detail = <div className="detail">{this.props.detail}</div>;
    const check = (
      <div className="number">
        <CheckMark color={trueColor} />
      </div>
    );

    // Add a check mark if you have a feature with a detail but no no name or number
    //  and this isn't the detail for the looking for more? section
    //  (as denoted by removeCheckMark = true)
    let hasCheckMark = false;
    if (
      !this.props.detail &&
      !this.props.number &&
      this.props.number !== 0 &&
      this.props.name &&
      !this.props.removeCheckMark
    ) {
      hasCheckMark = true;
    }

    return (
      <div className={containerClasses}>
        {number}
        {hasCheckMark && check}
        {name}
        {detail}
      </div>
    );
  },
});

export default PricingLevelFeature;
