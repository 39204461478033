import React, { useState } from 'react';
import { Modal, ModalProps } from '@dropbox/dig-components/modal';
import { modalTypes } from './constants';
import Billboard from './billboard';
import Pause from './pause';
import Survey from './survey';
import Error from './error';
import { PauseCancelModalContext, SafeDowngradeStatusEnum } from './types';
import { intl } from 'hellospa/common/hs-intl-provider';
import { defineMessages } from 'react-intl';
import ResumePaused from './resume-paused';

export type DowngradeFlowModalContainerProps = {
  modalContext: PauseCancelModalContext;
  onClose: () => void;
  isModalOpen: boolean;
  startingModal: (typeof modalTypes)[keyof typeof modalTypes];
  currentStatus: SafeDowngradeStatusEnum | null;
};

const messages = defineMessages({
  close: {
    id: '',
    defaultMessage: 'Close',
    description: 'Closes modal',
  },
});

const DowngradeFlowModalContainer = ({
  modalContext,
  onClose,
  isModalOpen,
  startingModal,
  currentStatus,
}: DowngradeFlowModalContainerProps) => {
  const [modalType, setModalType] = React.useState(startingModal);
  const [error, setError] = useState<string | null>(null);

  const closeButtonAriaLabel =
    modalTypes.BILLBOARD !== modalType ? 'Close' : undefined;

  const conditionalProps: Partial<ModalProps> = {};

  if (modalType !== modalTypes.BILLBOARD) {
    conditionalProps.withCloseButton = intl.formatMessage(messages.close);
  }

  const handleCloseModal = async () => {
    onClose();
    setTimeout(() => setModalType(startingModal), 500);
    if (modalType === modalTypes.ERROR || modalType === modalTypes.SURVEY)
      window.location.reload();
  };

  return (
    <Modal
      aria-labelledby="cancellation-flow-modal-title"
      open={isModalOpen}
      isCentered
      withCloseButton={closeButtonAriaLabel}
      onRequestClose={handleCloseModal}
      {...conditionalProps}
    >
      <>
        {modalType === modalTypes.BILLBOARD && (
          <Billboard
            modalContext={modalContext}
            setModalType={setModalType}
            setError={setError}
            handleCloseModal={handleCloseModal}
            currentStatus={currentStatus}
          />
        )}
        {modalType === modalTypes.SURVEY && (
          <Survey
            modalContext={modalContext}
            setModalType={setModalType}
            setError={setError}
            handleCloseModal={handleCloseModal}
            currentStatus={currentStatus}
          />
        )}
        {modalType === modalTypes.PAUSE && (
          <Pause
            modalContext={modalContext}
            setModalType={setModalType}
            setError={setError}
            handleCloseModal={handleCloseModal}
          />
        )}
        {modalType === modalTypes.RESUME_PAUSED && (
          <ResumePaused
            modalContext={modalContext}
            setModalType={setModalType}
            setError={setError}
            handleCloseModal={handleCloseModal}
          />
        )}
        {modalType === modalTypes.ERROR && (
          <Error handleCloseModal={handleCloseModal} error={error} />
        )}
      </>
    </Modal>
  );
};

export default DowngradeFlowModalContainer;
