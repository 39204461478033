import React from 'react';
import GoogleSignInButton from 'hellosign/components/auth/google-signin-button';
import DropboxSignInButton from 'hellosign/components/auth/dropbox-signin-button';
import {
  getTrackingLabel,
  getTrackingVariant,
} from 'common/utils/getTrackingVariant';
import { getCSRFToken } from 'js/sign-components/common/hs-fetch';
import styles from './sign-in-buttons.scss';

type SignInButtonProps = {
  googleSignInClientId: string;
  redirectUrl: string | null | undefined;
  isApi: boolean;
  formType: 'signup' | 'signin';
  googleAnalyticsTrackingVariant: string;
  planName: string | undefined;
  hideDropboxButton?: boolean;
};

export function SignInButtons(props: SignInButtonProps) {
  return (
    <div className={styles.signInButtons}>
      {!props.hideDropboxButton && (
        <DropboxSignInButton
          isSignup={props.formType === 'signup'}
          redirectUrl={props.redirectUrl}
          buttonAttrs={
            props.formType === 'signin'
              ? {
                  'da-category': 'signin',
                  'da-action': `create account inapp - ${getTrackingVariant(props.isApi)}`,
                  'da-label': getTrackingLabel(
                    'dbx',
                    props.googleAnalyticsTrackingVariant,
                    props.planName,
                  ),
                }
              : {}
          }
        />
      )}

      <GoogleSignInButton
        clientId={props.googleSignInClientId}
        redirectUrl={props.redirectUrl}
        csrfToken={getCSRFToken()}
        formType={props.formType}
        isSignup={props.formType === 'signup'}
        buttonAttrs={
          props.formType === 'signin'
            ? {
                'da-category': 'signin',
                'da-action': `create account inapp - ${getTrackingVariant(props.isApi)}`,
                'da-label': getTrackingLabel(
                  'google',
                  props.googleAnalyticsTrackingVariant,
                  props.planName,
                ),
              }
            : {}
        }
      />
    </div>
  );
}
