import React from 'react';
import classNames from 'classnames';
import { injectIntl, defineMessages } from 'react-intl';

import hsFetch from 'js/sign-components/common/hs-fetch';
import Button from 'common/components/button';
import HelloModal from 'common/components/hello-modal';
import OneTimeDeleteDocumentsConfirmationModal from 'hellospa/page/admin/components/one-time-delete-documents-confirmation-modal';
import OngoingDeleteDocumentsConfirmationModal from 'hellospa/page/admin/components/ongoing-delete-documents-confirmation-modal';
import DeactivateOngoingDeleteDocumentsModal from 'hellospa/page/admin/components/deactivate-ongoing-delete-documents-modal';
import { getBrandName } from 'js/sign-components/common/brand';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import styles from './purge-document-settings.scss';

const messages = defineMessages({
  descriptionOngoingDelete: {
    id: '',
    description:
      'Description text for the ongoing delete documents setting confirmation modal',
    defaultMessage:
      '<strong>Ongoing delete:</strong> {status} {br} All completed documents for your organiztion will be automatically deleted from {brandName} going forward.',
  },
  descriptionOneTimeDelete: {
    id: '',
    description:
      'Description text for the "One-time" delete documents confirmation modal',
    defaultMessage:
      '<strong>One-time delete:</strong> {br} You are about to permanently delete all completed documents from {brandName}, and they will not be recoverable.',
  },
  linkLearnMore: {
    id: '',
    description:
      'Setting description link for learning more about One-time delete and Ongoing delete settings.',
    defaultMessage: 'Learn more',
  },
  btnOneTimeDelete: {
    id: '',
    description: 'Button to trigger the One-time delete setting.',
    defaultMessage: 'Delete completed documents',
  },
  btnEnableOngoingDelete: {
    id: '',
    description: 'Button to activate the Ongoing delete setting.',
    defaultMessage: 'Enable ongoing delete ',
  },
  btnDisableOngoingDelete: {
    id: '',
    description: 'Button to deactivate the Ongoing delete setting.',
    defaultMessage: 'Disable ongoing delete',
  },
  updatingStatus: {
    id: '',
    description: 'Text that lets the user know that an update is in process',
    defaultMessage: 'Updating...',
  },
  enabled: {
    id: '',
    description:
      'Text that lets the user know that the feature has been Enabled',
    defaultMessage: 'Enabled',
  },
  disabled: {
    id: '',
    description:
      'Text that lets the user know that the feature has been Disabled',
    defaultMessage: 'Disabled',
  },
  loading: {
    id: '',
    description: 'Text that lets the user know that the feature is loading',
    defaultMessage: 'Loading...',
  },
  confirmedOneTimeDeleteSuccessMessage: {
    brandName: getBrandName('S'),
    id: '',
    description: 'Setting success message for the One-time delete setting.',
    defaultMessage:
      '{completedDocuments, plural, =1 {# completed document} other {# completed documents}} will be permanently deleted from {brandName} in 72 hours.',
  },
  unknownErrorMessage: {
    id: '',
    description:
      'Message displayed to the user when there was an unknown error.',
    defaultMessage:
      'Something went wrong. Please reload the page and try again.',
  },
  activatedOngoingDeleteMessage: {
    id: '',
    description:
      'Setting success message for the activation of ongoing delete setting.',
    defaultMessage:
      'You have successfully activated ongoing delete. All documents will be deleted from {brandName} 72 hours after completion.',
  },
  deactivatedOngoingDeleteMessage: {
    id: '',
    description:
      'Setting success message for the deactivation of ongoing delete setting.',
    defaultMessage:
      'You have successfully deactivated ongoing delete. Documents will no longer be deleted from {brandName} going forward.',
  },
  settingsSectionOneTimeDelete: {
    id: '',
    description:
      'Section title for the One-time delete setting that deletes all of the organization files.',
    defaultMessage: 'One-time delete',
  },
  settingsSectionOngoingDelete: {
    id: '',
    description:
      'Section title for the Ongoing delete setting that deletes all of the organization files.',
    defaultMessage: 'Ongoing delete',
  },
  close: {
    id: '',
    description: 'Button to close an alert',
    defaultMessage: 'Close',
  },
});

class PurgeDocumentSettings extends React.Component {
  state = {
    isOngoingDeleteEnabled: this.props.ongoingDeleteDocuments,
    isUpdatingOngoingDeleteEnabled: false,
    isTriggeringOneTimeDelete: false,
    openOneTimeDeleteConfirmationModal: false,
    openOngoingDeleteConfirmationModal: false,
    openDeactivateOngoingDeleteConfirmationModal: false,
    showAlert: false,
    isAlertError: false,
    alertContentLabel: null,
    alertContent: null,
  };

  renderOngoingDeleteSetting() {
    const { intl } = this.props;

    let btnText = intl.formatMessage(messages.btnEnableOngoingDelete);
    let btnColor = 'white';
    let btnTextColor = 'cerulean';
    let buttonClass = 'border-1-cerulean';
    let buttonHoverColor = 'cerulean';
    let onClick = () => {
      this.setState({ openOngoingDeleteConfirmationModal: true });
    };

    if (this.state.isOngoingDeleteEnabled) {
      btnText = intl.formatMessage(messages.btnDisableOngoingDelete);
      btnColor = 'blood';
      btnTextColor = 'white';
      buttonClass = 'border-1-blood';
      buttonHoverColor = 'blood';
      onClick = () => {
        this.setState({ openDeactivateOngoingDeleteConfirmationModal: true });
      };
    }

    if (this.state.isUpdatingOngoingDeleteEnabled) {
      btnText = intl.formatMessage(messages.updatingStatus);
    }

    return (
      <Button
        buttonText={btnText}
        buttonColor={btnColor}
        buttonTextColor={btnTextColor}
        buttonClass={buttonClass}
        buttonHoverColor={buttonHoverColor}
        buttonHoverTextColor="white"
        onClick={onClick}
        disabled={this.state.isUpdatingOngoingDeleteEnabled}
        className={styles.deletePolicyButton}
      />
    );
  }

  triggerOneTimeDelete = async () => {
    const { app, intl } = this.props;
    const formData = new FormData();
    formData.append('csrf_token', app.csrfToken);

    this.setState({ isTriggeringOneTimeDelete: true });

    try {
      const result = await hsFetch('/home/queueDeletionRequest', {
        credentials: 'same-origin',
        method: 'POST',
      });
      const { total_num_super_groups: totalNumSuperGroups } =
        await result.json();

      this.showNoticeMessage({
        contentLabel: intl.formatMessage(messages.settingsSectionOneTimeDelete),
        content: intl.formatMessage(
          messages.confirmedOneTimeDeleteSuccessMessage,
          {
            brandName: getBrandName('S'),
            completedDocuments: totalNumSuperGroups,
          },
        ),
      });
    } catch (err) {
      this.showErrorMessage({
        contentLabel: intl.formatMessage(messages.settingsSectionOneTimeDelete),
        content: intl.formatMessage(messages.unknownErrorMessage),
      });

      // We want the error to be visible in the console so that
      // it's easier to debug if we come ever across it.
      // eslint-disable-next-line no-console
      console.error(err);
    }

    this.setState({ isTriggeringOneTimeDelete: false });
  };

  updateOngoingDelete = async ({ ongoingDeletionRequest }) => {
    const { app, intl } = this.props;
    const formData = new FormData();
    formData.append('csrf_token', app.csrfToken);
    formData.append(
      'ongoingDeleteDocuments',
      ongoingDeletionRequest ? '1' : '0',
    );

    this.setState({ isUpdatingOngoingDeleteEnabled: true });

    try {
      const result = await hsFetch('/home/updateOngoingDeleteTeamSetting', {
        credentials: 'same-origin',
        method: 'POST',
        body: formData,
      });
      const data = await result.json();

      if (data.success) {
        this.setState({ isOngoingDeleteEnabled: ongoingDeletionRequest });

        if (ongoingDeletionRequest) {
          this.showNoticeMessage({
            contentLabel: intl.formatMessage(
              messages.settingsSectionOngoingDelete,
            ),
            content: intl.formatMessage(
              messages.activatedOngoingDeleteMessage,
              {
                brandName: getBrandName('S'),
              },
            ),
          });
        } else {
          this.showNoticeMessage({
            contentLabel: intl.formatMessage(
              messages.settingsSectionOngoingDelete,
            ),
            content: intl.formatMessage(
              messages.deactivatedOngoingDeleteMessage,
              {
                brandName: getBrandName('S'),
              },
            ),
          });
        }
      }
    } catch (err) {
      this.showErrorMessage({
        contentLabel: intl.formatMessage(messages.settingsSectionOngoingDelete),
        content: intl.formatMessage(messages.unknownErrorMessage),
      });

      // We want the error to be visible in the console so that
      // it's easier to debug if we come ever across it.
      // eslint-disable-next-line no-console
      console.error(err);
    }

    this.setState({ isUpdatingOngoingDeleteEnabled: false });
  };

  showNoticeMessage({ contentLabel, content }) {
    this.setState({
      showAlert: true,
      alertContentLabel: contentLabel,
      alertContent: content,
      isAlertError: false,
    });
  }

  showErrorMessage({ contentLabel, content }) {
    this.setState({
      showAlert: true,
      alertContentLabel: contentLabel,
      alertContent: content,
      isAlertError: true,
    });
  }

  onAlertRequestClose = () => {
    this.setState({
      showAlert: false,
      contentLabel: null,
      content: null,
    });
  };

  renderModal() {
    const { intl } = this.props;

    return (
      <HelloModal
        isOpen={this.state.showAlert}
        showCloseIcon={false}
        width="560px"
        buttons={[
          {
            type: 'secondary',
            text: intl.formatMessage(messages.close),
            onClick: this.onAlertRequestClose,
          },
        ]}
        onRequestClose={this.onAlertRequestClose}
        contentLabel={this.state.alertContentLabel}
      >
        <div
          className={classNames({
            'c-blood': this.state.isAlertError,
          })}
        >
          {this.state.alertContent}
        </div>
      </HelloModal>
    );
  }

  render() {
    const { intl, canSetOngoingDeleteSetting, canSetOneTimeDeleteSetting } =
      this.props;
    const {
      isUpdatingOngoingDeleteEnabled,
      openOneTimeDeleteConfirmationModal,
      openOngoingDeleteConfirmationModal,
      openDeactivateOngoingDeleteConfirmationModal,
    } = this.state;

    const ongoingDeleteLabel = this.state.isOngoingDeleteEnabled
      ? intl.formatMessage(messages.enabled)
      : intl.formatMessage(messages.disabled);

    return (
      <React.Fragment>
        {canSetOneTimeDeleteSetting && (
          <div>
            <p>
              {intl.formatMessage(messages.descriptionOneTimeDelete, {
                brandName: getBrandName('S'),
                br: <br />,
                strong: (...chunks) => <strong>{chunks}</strong>,
              })}
              &nbsp;
              <a
                href={HfReactHelper.getFaqUrl('articles/360043023071')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage(messages.linkLearnMore)}
              </a>
              .
            </p>
            <Button
              buttonText={
                this.state.isTriggeringOneTimeDelete
                  ? intl.formatMessage(messages.loading)
                  : intl.formatMessage(messages.btnOneTimeDelete)
              }
              buttonColor="white"
              buttonClass="border-1-cerulean"
              buttonTextColor="cerulean"
              buttonHoverTextColor="white"
              buttonHoverColor="cerulean"
              onClick={() => {
                this.setState({ openOneTimeDeleteConfirmationModal: true });
              }}
              disabled={this.state.isTriggeringOneTimeDelete}
              className={styles.deletePolicyButton}
            ></Button>
          </div>
        )}

        {canSetOngoingDeleteSetting && (
          <div>
            <p>
              {intl.formatMessage(messages.descriptionOngoingDelete, {
                brandName: getBrandName('S'),
                br: <br />,
                strong: (...chunks) => <strong>{chunks}</strong>,
                status: isUpdatingOngoingDeleteEnabled
                  ? intl.formatMessage(messages.updatingStatus)
                  : ongoingDeleteLabel,
              })}
              &nbsp;
              <a
                href={HfReactHelper.getFaqUrl('articles/360043023071')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage(messages.linkLearnMore)}
              </a>
              .
            </p>
            {this.renderOngoingDeleteSetting()}
          </div>
        )}

        {this.renderModal()}

        <OneTimeDeleteDocumentsConfirmationModal
          isOpen={openOneTimeDeleteConfirmationModal}
          onRequestClose={() => {
            this.setState({ openOneTimeDeleteConfirmationModal: false });
          }}
          onConfirmed={this.triggerOneTimeDelete}
        />

        <OngoingDeleteDocumentsConfirmationModal
          isOpen={openOngoingDeleteConfirmationModal}
          onRequestClose={() => {
            this.setState({ openOngoingDeleteConfirmationModal: false });
          }}
          onConfirmed={this.updateOngoingDelete}
        />

        <DeactivateOngoingDeleteDocumentsModal
          isOpen={openDeactivateOngoingDeleteConfirmationModal}
          onRequestClose={() => {
            this.setState({
              openDeactivateOngoingDeleteConfirmationModal: false,
            });
          }}
          onConfirmed={this.updateOngoingDelete}
        />
      </React.Fragment>
    );
  }
}

export default injectIntl(PurgeDocumentSettings);
