/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs
*/
/**
Billing/Pricing Constants
* */

export const EU_COUNTRIES = 'eu_countries';
export const REST_OF_THE_WORLD = 'rest_of_the_world';
export const MARKETING_EMAIL_AUTO_OPT_IN_COUNTRIES =
  'marketing_email_auto_opt_in_countries';
export const EUROPEAN_COUNTRIES_LIST = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
] as const;
export const MARKETING_EMAIL_AUTO_OPT_IN_COUNTRIES_LIST = [
  'AU',
  'CA',
  'US',
] as const;

export const MARKETING_EMAIL_OPT_IN_COUNTRIES_LIST = [
  'CO',
  'PE',
  'IL',
  'JP',
  'TR',
  'AE',
] as const;

export default {
  // These values mirror what's in SubscriptionInfo.class.php
  BILLING_TYPE_STANDARD: 'S',
  BILLING_TYPE_ALTERNATIVE_CHARGE_INDIVIDUAL: 'AI',
  BILLING_TYPE_ALTERNATIVE_CHARGE_PARTNER: 'AP',
  BILLING_TYPE_ALTERNATIVE_CHARGE_MASTER_SUB: 'AM',
  ALTERNATIVE_BILLING_TYPES: ['AI', 'AP', 'AM'],

  BILLING_METHOD_CREDIT_CARD: 'CC',
  BILLING_METHOD_INVOICE: 'IN',
  EU_COUNTRIES,
  REST_OF_THE_WORLD,
  MARKETING_EMAIL_AUTO_OPT_IN_COUNTRIES,
  EUROPEAN_COUNTRIES_LIST,
  MARKETING_EMAIL_OPT_IN_COUNTRIES_LIST,
  MARKETING_EMAIL_AUTO_OPT_IN_COUNTRIES_LIST,
} as const;
