/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len,
  react/no-unescaped-entities
*/
import './index.scss';
import React from 'react';
import Button from 'common/components/button';
import logger from 'common/logger';
import Notification from 'common/components/notifications/legacy-notification';
import createReactClass from 'create-react-class';

const RemoveEnterpriseMember = createReactClass({
  getInitialState() {
    return {
      transferToAcct: null,
    };
  },

  handleChange(e) {
    this.setState({
      transferToAcct: e.target.value,
    });
  },

  handleSubmit() {
    this.props.member.setProperties({
      transferToAcct: this.state.transferToAcct,
    });

    if (this.props.member.transferToAcct)
      logger.track('RemoveEnterpriseMember.transferred');

    this.props.member.remove().then(() => {
      Notification.displayNotification({
        class_name: 'success',
        text: this.state.transferToAcct
          ? 'The member has been removed and their documents are being transferred.'
          : 'The member has been removed and they will keep their documents.',
      });
    });
  },

  makeDropdown() {
    return (
      <select onChange={(e) => this.handleChange(e)}>
        <option value="">User keeps them</option>
        {this.props.allTeamMembers.map((member, i) => {
          return this.props.member.guid == member.guid ||
            member.pending ? null : (
            <option key={i} value={member.guid}>
              {member.emailAddress}
            </option>
          );
        })}
      </select>
    );
  },

  makeLabel() {
    return (
      <label>
        Confirm you'd like to remove{' '}
        <span className="ellipsis">{this.props.member.emailAddress}</span> from
        your team and convert them to a free account. Who should receive their
        documents, templates, and API apps (if any)?
      </label>
    );
  },

  makeWarning() {
    return this.props.member.hasTeamApiKey ? (
      <div className="l-new-modal--content-wrap">
        <span className="warning-msg">
          <span className="strong">Important:</span> Removing a developer with
          an active API key could interrupt integrations currently in use. We
          recommend ensuring the API key is no longer in use before removing
          this account.
        </span>
      </div>
    ) : (
      ''
    );
  },

  render() {
    return (
      <div className="l-new-modal remove-enterprise-member">
        <div className="l-new-modal--content-wrap">
          <div className="l-new-modal--title">Remove User</div>
          <div className="l-new-modal--content">
            <div className="l-new-modal--content-wrap">
              {this.makeLabel()}
              {this.makeDropdown()}
            </div>
            {this.makeWarning()}
          </div>
          <div className="l-new-modal--actions">
            <Button
              buttonText="Confirm"
              buttonColor="grasshopper"
              buttonHoverColor="ashen"
              buttonClass="m-button-submit close"
              buttonHoverTextColor="white"
              onClick={this.handleSubmit}
            />
            <Button
              buttonText="Cancel"
              buttonColor="black"
              buttonClass="m-button-submit close"
            />
          </div>
        </div>
      </div>
    );
  },
});

module.exports = RemoveEnterpriseMember;
