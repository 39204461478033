/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import createReactClass from 'create-react-class';
import ImageModal from './image-modal';

const ImageModalTrigger = createReactClass({
  propTypes: {
    imagePath: PropTypes.string,
    classNames: PropTypes.string,
  },

  handleClick(e) {
    if (e != null) {
      e.preventDefault();
    }
    const attachPoint = HfReactHelper.getRevealAttachPoint(
      'image_modal',
      'small',
      true,
      'l-margin-t--80 text-center',
    );
    ReactDOM.unmountComponentAtNode(attachPoint);
    const modalPortalHack = ReactDOM.createPortal(
      <ImageModal imagePath={this.props.imagePath}></ImageModal>,
      attachPoint,
    );
    this.setState({ modalPortalHack });
  },

  render() {
    return (
      <span onClick={this.handleClick}>
        {this.state?.modalPortalHack}
        <img
          src={this.props.imagePath}
          className={this.props.classNames}
          style={{ cursor: 'pointer' }}
        />
      </span>
    );
  },
});

module.exports = ImageModalTrigger;
