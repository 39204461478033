import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationBannerType } from '../notification-banner';
import { PictogramIcon } from '@dropbox/dig-icons';
import { WarningPictogram } from '@dropbox/dig-icons/assets';
import { Banner } from '@dropbox/dig-components/banner';
import { Text, Title, Link } from '@dropbox/dig-components/typography';
import { strong } from 'hellospa/common/hs-intl-provider';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { Button } from '@dropbox/dig-components/buttons';

interface CallbackUrlWarningBannerProps {
  page?: string;
}
function CallbackUrlWarningBanner({
  page = 'api',
}: CallbackUrlWarningBannerProps) {
  return (
    <Banner
      data-testid="replace-legacy-api-key-banner"
      variant="rich"
      type={NotificationBannerType.Warning}
      withLeftPictogram={<PictogramIcon src={WarningPictogram} />}
    >
      <Title size="small">
        <FormattedMessage
          id="update-callback-urls"
          description="Warning banner, API settings page, title above a section that displays warning for callback urls being insecure."
          defaultMessage="Update all your callback URLs from HTTP to HTTPS"
        />
      </Title>
      <Banner.Message>
        <Text>
          <FormattedMessage
            id=""
            description="Warning banner, API settings page, message about updating callback URLs."
            defaultMessage="To enhance the security of your callback events, please update all your account and application callback URLs from HTTP to HTTPS. This will ensure your callback URLs are secure and help protect sensitive data. <strong>{learnMoreLink}</strong> is how you can easily update your callback URLs."
            values={{
              learnMoreLink: (
                <Link
                  target="_blank"
                  href={HfReactHelper.getDocumentationUrl(
                    'docs/events/walkthrough/#setting-up-dropbox-sign-events',
                  )}
                >
                  <FormattedMessage
                    id=""
                    description="Learn more link for callback url "
                    defaultMessage="Here"
                  />
                </Link>
              ),
              strong,
            }}
          />
        </Text>
      </Banner.Message>
      <Banner.Actions>
        {page !== 'api' && (
          <Button
            key="api-setting-page-link"
            variant="outline"
            href={HfReactHelper.urlHelper(`home/myAccount?current_tab=api`)}
          >
            <FormattedMessage
              id=""
              description="Learn more link for callback url update"
              defaultMessage="Update callback URL"
            />
          </Button>
        )}
        <Button
          key="document-page-link"
          variant="transparent"
          target="_blank"
          href={HfReactHelper.getDocumentationUrl(
            'docs/events/walkthrough/#setting-up-dropbox-sign-events',
          )}
        >
          <FormattedMessage
            id=""
            description="Learn more link for callback url update"
            defaultMessage="Learn more on updating callback URLs"
          />
        </Button>
      </Banner.Actions>
    </Banner>
  );
}

export default CallbackUrlWarningBanner;
