import React from 'react';
import { Menu } from '@dropbox/dig-components/menu';
import { Text } from '@dropbox/dig-components/typography';
import { Button, ButtonProps } from '@dropbox/dig-components/buttons';
import { MakeRequired } from 'js/sign-components/common/ts-utils';

import styles from './menu-walkthrough.module.scss';

export type SubmenuButtonProps = MakeRequired<ButtonProps, 'href'> &
  Partial<ButtonProps>;

export type SubmenuWalkthroughProps = {
  title: string;
  image?: JSX.Element;
  body: string;
  buttons?: SubmenuButtonProps[];
};

function SubmenuWalkthrough({
  title,
  image,
  body,
  buttons,
}: SubmenuWalkthroughProps) {
  return (
    <Menu.Submenu withTriggerContent={title}>
      <Menu.Segment className={styles.submenuContent}>
        {image && (
          <Menu.Row aria-hidden="true">
            <div className={styles.submenuIllustration}>{image}</div>
          </Menu.Row>
        )}
        <Menu.Row preferComposition>
          <Menu.RowContent>
            <Text variant="paragraph" size="medium">
              {body}
            </Text>
          </Menu.RowContent>
        </Menu.Row>
        {buttons?.map((buttonProps, i) => (
          <Menu.LinkItem key={i} href={buttonProps.href}>
            <Button
              data-testid={`education-submenu-button-${i}`}
              variant="transparent"
              {...buttonProps}
            />
          </Menu.LinkItem>
        ))}
      </Menu.Segment>
    </Menu.Submenu>
  );
}

export default SubmenuWalkthrough;
