/* If you edit this file, please remove this header and clean up the resulting eslint errors. */
/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Button from 'common/components/button';
import classNames from 'classnames';
import HSIntlProvider from 'hellospa/common/hs-intl-provider';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import StringHelper from 'js/sign-components/common/string-helpers';
import { trackHeapCustomEvent } from 'js/sign-components/common/heap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import Body from 'hellospa/foundation/typography/body';
import Spacer, { SpacerSize } from 'hellospa/foundation/spacing/spacer';
import pushAnalyticsEvent from 'common/utils/pushAnalyticsEvent';
import { getTrackingVariant } from 'common/utils/getTrackingVariant';
import { UIIcon } from '@dropbox/dig-icons';
import { ActivityLine } from '@dropbox/dig-icons/assets';
import CreateAccount from '../auth/create-account';
import PaymentModal from './payment-modal';
import PricingLevelFeature from './pricing-level-feature-new';
import MailToButton from './mail-to-button';

const FREE_SEAT = 'Free';
const FREE_TRANSACTION = 'Test Mode';
const dbxLink = HfReactHelper.getWebflowUrl('integrations/dropbox');
const gdLink = HfReactHelper.getWebflowUrl('integrations/google-drive');
const mswLink = HfReactHelper.getWebflowUrl('integrations/microsoft-word');

const messages = defineMessages({
  perMonthPeriod: {
    id: '',
    description:
      'label, pricing page, informs user that displayed price is per month period',
    defaultMessage: 'per month',
  },
  perYearMonthlyPrice: {
    id: '',
    description:
      'label, pricing page, informs user that displayed price is per month period more explicitly',
    defaultMessage: '{price} / mo',
  },
  perYearMonthlyPricePerUser: {
    id: '',
    description:
      'label, pricing page, informs user that displayed price is per month period more explicitly',
    defaultMessage: '{price} / user / mo',
  },
  perYearPeriod: {
    id: '',
    description:
      'label, pricing page, displays to user yearly price based on monthly price of a plan',
    defaultMessage: 'billed annually as {price}',
  },
  perYearPeriodNew: {
    id: '',
    description:
      'label, pricing page, displays to user yearly price based on monthly price of a plan',
    defaultMessage: '{price} charged yearly',
  },
  startingPerYearPeriod: {
    id: '',
    description:
      'label, pricing page, displays to user yearly starting price based on monthly price and number of users',
    defaultMessage: 'starting at {price}, charged yearly',
  },
  startingPerMonthPeriod: {
    id: '',
    description:
      'label, pricing page, displays to user yearly starting price based on monthly price and number of users',
    defaultMessage: 'starting at {price}, charged monthly',
  },
  buttonResume: {
    id: '',
    description:
      'button, pricing page, resumes subscription that was placed on hold by user',
    defaultMessage: 'Resume',
  },
  buttonUpdatePaymentInfo: {
    id: '',
    description:
      'button, pricing page, will initiate flow for updating cred card info',
    defaultMessage: 'Update payment info',
  },
  buttonUnavailable: {
    id: '',
    description: 'button, pricing page, action with the button is unavailable',
    defaultMessage: 'Unavailable',
  },
  buttonEmailInquiry: {
    id: '',
    description:
      'button, pricing page, initiates email sending for inquiry for other plans or custom subscriptions',
    defaultMessage: 'Alt Billing Plan Inquiry',
  },
  buttonFreeTrial: {
    id: '',
    description: 'button, pricing page, initiates free trial for subscription',
    defaultMessage: 'Start free trial',
  },
  buttonGetStarted: {
    id: '',
    description:
      'button, pricing page, initiates flow of subscribing user onto subscription.',
    defaultMessage: 'Get started',
  },
  purchaseNow: {
    id: '',
    description: 'button, pricing page, start subscription, pay today',
    defaultMessage: 'Purchase now',
  },
  modalCreateAccountHeader: {
    id: '',
    description:
      'modal header, shows different UI elements to user and creates account',
    defaultMessage: "Let's get started",
  },
  modalCreateAccountButton: {
    id: '',
    description: 'button, continues some initiated process',
    defaultMessage: 'Continue',
  },
  minNumFeatureValue: {
    id: '',
    description:
      'pricing page, main feature, minimum number value. example: 50+',
    defaultMessage: '{number}+',
  },
  recommendedLabelMessage: {
    id: '',
    description:
      'Heading indicating to the user that the item is the most popular option among multiple options.',
    defaultMessage: 'Popular',
  },
});

class PricingLevelNew extends React.Component {
  state = {
    expanded: false,
    isModalOpen: false,
    isFreeTier: false,
    isDirectPurchase: false,
  };

  formRef = React.createRef();

  componentDidMount() {
    if (this.props.acctModalError) {
      this.launchCreateAccountModal();
    }
    if (this.props.wasSelectedBeforeLogIn) {
      this.launchPaymentModal();
    }
  }

  isFreeTier = () => {
    return [FREE_SEAT, FREE_TRANSACTION].includes(this.props.tier);
  };

  isApi = () => {
    return this.props.pricingSource === 'api';
  };

  onLevelClick = () => {
    this.setState(({ expanded }) => ({
      expanded: !expanded,
    }));
  };

  launchNoCCTrial = () => {
    // Submit the skip cc trial form
    if (this.formRef.current) {
      this.formRef.current.submit();
    }
    this.trackGAPricing();
  };

  closePaymentModal = () => {
    this.setState({ isModalOpen: false });
  };

  launchPaymentModal = () => {
    this.setState({ isModalOpen: true });
  };

  generatePaymentUrl = () => {
    const { planId, timePeriod, couponCode } = this.props;
    let redirectUrl = `/info/payment?plan_id=${planId}&period=${timePeriod}`;
    if (couponCode) {
      redirectUrl += `&coupon_code=${couponCode}`;
    }
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('integration')) {
      redirectUrl += `&integration=${urlParams.get('integration')}`;
    }
    return redirectUrl;
  };

  /* eslint-disable no-param-reassign */
  launchCreateAccountModal = (redirectUrl, isDirectPurchase = false) => {
    const { intl } = this.props;
    this.setState({ isDirectPurchase });
    const attachPoint = HfReactHelper.getRevealAttachPoint(
      `create_account_modal_${this.props.planId}`,
      undefined,
      undefined,
      undefined,
      'close-modal-left-side',
    );
    if (typeof redirectUrl !== 'string') {
      redirectUrl =
        this.props.timePeriod === 'M'
          ? this.props.monthlyRedirect
          : this.props.annualRedirect;

      if (this.props.monthlyExpectedRate > 0) {
        redirectUrl = this.generatePaymentUrl();
      }
    }

    if (this.props.monthlyExpectedRate > 0) {
      trackHeapCustomEvent('pricing_page_start_trial', {
        plan_id: this.props.planId,
      });
    } else {
      // this is a free plan?
      trackHeapCustomEvent('pricing_page_get_started');
    }
    this.trackGAPricing(isDirectPurchase);

    /**
     * This function used to spin up a whole new React application in order to
     * host this modal by way of React.render()
     *
     * When you spin up a new app you lose access to all context:
     * - No localization
     * - No feature flags
     * - This becomes an independent application running in the same browser
     *
     * The way this was functioning ReactDOM.render() is called exactly once
     * with whatever the data was when they clicked the button. In order to
     * avoid introducing new bugs by having this modal render and re-render with
     * updated data, I'm just going to store the DECLARATION of what should be
     * in the modal.
     */
    const modalPortalHack = ReactDOM.createPortal(
      <HSIntlProvider>
        <CreateAccount
          ref={this.createAccountModalRef}
          key={this.props.planId}
          planId={this.props.planId}
          isApi={this.props.isApi}
          planName={this.props.rawName}
          skipCC={this.props.skipCC}
          period={this.props.timePeriod}
          trialPeriod={this.props.trialPeriod}
          googleSignInClientId={this.props.googleSignInClientId}
          csrfToken={this.props.csrfToken}
          errorMsg={this.props.acctModalError}
          header={intl.formatMessage(messages.modalCreateAccountHeader)}
          buttonText={intl.formatMessage(messages.modalCreateAccountButton)}
          isFromApiPricing={this.props.isApi}
          isFromPricing={!this.props.isApi}
          redirectUrl={redirectUrl}
          tabOffset={1000}
          signupUrl={this.props.signupUrl}
          tosUrl={this.props.tosUrl}
          privacyPolicyUrl={this.props.privacyPolicyUrl}
          countryCodeFromIp={this.props.countryCodeFromIp}
          signupForm={this.props.signupForm}
          arkoseSignUpEnabled={this.props.arkoseSignUpEnabled}
          arkoseSignUpPublicKey={this.props.arkoseSignUpPublicKey}
          googleAnalyticsTrackingVariant="pricing_page"
          isDirectPurchase={isDirectPurchase}
        />
      </HSIntlProvider>,
      attachPoint,
    );

    this.setState({ modalPortalHack });
  };

  trackGAPricing = (isDirectPurchase = false) => {
    const actionTag = isDirectPurchase
      ? 'direct purchase link'
      : `create account start - ${getTrackingVariant(this.props.isApi)}`;
    let labelTag = this.props.name.toLowerCase();

    if (labelTag === 'business') {
      labelTag = 'b5';
    }

    pushAnalyticsEvent({
      category: 'signup',
      action: actionTag,
      label: labelTag,
    });
  };

  launchSalesModal = () => {
    $('#contactUsButton').click();
  };

  resumeSub = () => {
    const planParam = this.props.isApi ? 'api' : 'ui';
    window.location.href = `/account/revertPendingCancellation/csrf_token/${this.props.csrfToken}/plan_type/${planParam}`;
    this.trackGAPricing();
  };

  redirectToCardUpdate = () => {
    window.location.href = '/account/updateCard';
    this.trackGAPricing();
  };

  handlePurchaseNow = (redirectUrl) => {
    if (this.props.isLoggedIn) {
      window.location.href = redirectUrl;
      this.trackGAPricing(true);
    } else {
      this.launchCreateAccountModal(redirectUrl, true);
    }
  };

  render() {
    const { intl } = this.props;
    const isFree = this.props.monthlyExpectedRate <= 0;
    const bodyClasses = classNames({
      'm-pricing-level--body': true,
      'small-12': true,
      columns: true,
      expanded: this.state.expanded,
    });

    let price = '0';
    let period = '';
    let billingPeriod;
    let priceStr = HfReactHelper.formatCurrency(
      intl,
      0,
      this.props.currencySymbol,
    );
    let approx = false;
    let originalPrice = '0';
    let originalPriceStr = HfReactHelper.formatCurrency(
      intl,
      0,
      this.props.currencySymbol,
    );

    const approxStr = (
      <span>
        <FormattedMessage
          id=""
          description="part of pricing page, informs user that price for a plan that user looks at is approximate per period select (month or year)"
          defaultMessage="* approx {break}"
          values={{
            break: () => <br />, // eslint-disable-line react/display-name
          }}
        />
      </span>
    );

    if (!isFree) {
      if (this.props.timePeriod === 'M') {
        // Set original price
        originalPriceStr = this.props.formattedMonthlyPrice;
        // Set Price
        price = this.props.formattedMonthlyCouponPrice ?? originalPriceStr;
        // Set price string
        priceStr = !HfReactHelper.isHelloSign()
          ? price
          : intl.formatMessage(
              !this.props.isApi && this.props.minSeats > 1
                ? messages.perYearMonthlyPricePerUser
                : messages.perYearMonthlyPrice,
              { price },
            );
        // Set period string
        if (!HfReactHelper.isHelloSign()) {
          period = intl.formatMessage(messages.perMonthPeriod, { price });
        } else if (!this.props.isApi && this.props.minSeats > 1) {
          period = intl.formatMessage(messages.startingPerMonthPeriod, {
            price: this.props.formattedMonthlyExpectedRate,
          });
        } else {
          period = '';
        }
      } else {
        // Set original price
        originalPrice = this.props.formattedAnnualPricePerMonth;
        // Set original price string
        originalPriceStr = intl.formatMessage(messages.perYearMonthlyPrice, {
          price: originalPrice,
        });
        // Set price
        price = this.props.formattedAnnualCouponPricePerMonth ?? originalPrice;
        // Set price string
        priceStr = intl.formatMessage(
          !this.props.isApi &&
            this.props.minSeats > 1 &&
            HfReactHelper.isHelloSign()
            ? messages.perYearMonthlyPricePerUser
            : messages.perYearMonthlyPrice,
          { price },
        );
        if (parseFloat(price) % 1 > 0) {
          approx = true;
        }
        // Set price
        price = this.props.formattedAnnualExpectedRate;
        // Set period
        let message;
        if (!HfReactHelper.isHelloSign()) {
          message = messages.perYearPeriod;
        } else if (this.props.minSeats > 1) {
          message = messages.startingPerYearPeriod;
        } else {
          message = messages.perYearPeriodNew;
        }

        period = intl.formatMessage(message, { price });
      }
      billingPeriod = (
        <div className="billing-period">
          {approx && approxStr}
          {!approx && period}
        </div>
      );
    } else {
      billingPeriod = <div className="no-billing-period" />;
    }

    let oldPrice;
    let oldPriceMobile;
    if (this.props.coupon) {
      const oldPriceMobileClasses = classNames({
        'show-for-medium-only': true,
        'small-1': true,
        'medium-3': true,
        columns: true,
        'level-price': true,
        'l-padding-t-35': true,
      });

      if (this.props.couponEligible) {
        oldPrice = (
          <div className="m-pricing-level--body small-4 large-12 small-centered columns level-price">
            <div className="price crossed">{originalPriceStr}</div>
          </div>
        );

        oldPriceMobile = (
          <div className={oldPriceMobileClasses}>
            <span className="price crossed">{originalPriceStr}</span>
          </div>
        );
      } else {
        oldPrice = (
          <div className="m-pricing-level--body small-4 large-12 small-centered columns level-price l-padding-t-50"></div>
        );

        oldPriceMobile = <div className={oldPriceMobileClasses}></div>;
      }
    }

    const {
      features: [...featureProps],
    } = this.props;

    let redirectUrl;

    // If this pricing level is free tier,
    // the user has a current plan,
    // and this level is not the current plan.
    if (
      this.isFreeTier() &&
      this.props.hasCurrentPlan &&
      !this.props.isCurrentPlan
    ) {
      redirectUrl = '/account/cancelSubscription';

      if (this.isApi()) redirectUrl += '?p=api';
      else redirectUrl += '?p=ui';
    } else {
      redirectUrl = this.generatePaymentUrl();
    }

    // Get started button logic
    let getStarted;
    let primaryColor = 'cerulean';
    let primaryColorBG = 'bgi-cerulean';
    if (!HfReactHelper.isHelloSign()) {
      primaryColor = 'goldenrod';
      primaryColorBG = 'bgi-goldenrod';
    }

    if (
      this.props.isCurrentPlan &&
      !this.props.isDelinquent &&
      ((!this.props.isApi && this.props.UiPendingCancel) ||
        (this.props.isApi && this.props.ApiPendingCancel)) &&
      this.props.isAdmin
    ) {
      const buttonText = intl.formatMessage(messages.buttonResume);
      let buttonLink = null;
      const buttonFunc = this.resumeSub;
      const autoRenewalPlans = ['Essentials', 'Home Office', 'Professional'];
      if (autoRenewalPlans.includes(this.props.rawName)) {
        buttonLink = redirectUrl;
      }
      const qaRef = `${StringHelper.pathnameToKebabCase()}-${this.props.tier.toLowerCase()}-resumeBtn`;
      getStarted = (
        <Button
          data-qa-ref={qaRef}
          data-testid={qaRef}
          buttonText={buttonText}
          buttonHoverColor={primaryColor}
          buttonBorderRounded={true}
          {...(buttonLink ? { buttonLink } : { onClick: buttonFunc })}
        />
      );
      // Special cases for delinquent subscriptions
    } else if (this.props.isDelinquent && !this.props.changePlanRestricted) {
      let buttonText;
      let qaRef;
      let buttonColor;
      let buttonHoverColor;
      let buttonFunc;
      // Allow access to CC update only on current plan
      if (this.props.isCurrentPlan) {
        buttonText = intl.formatMessage(messages.buttonUpdatePaymentInfo);
        qaRef = `${StringHelper.pathnameToKebabCase()}-${this.props.tier.toLowerCase()}-updatePaymentInfoBtn`;
        buttonFunc = this.redirectToCardUpdate;
        buttonColor = 'ashen';
        buttonHoverColor = primaryColor;
        // Disallow plan change
      } else {
        buttonText = intl.formatMessage(messages.buttonUnavailable);
        qaRef = `${StringHelper.pathnameToKebabCase()}-${this.props.tier.toLowerCase()}-unavailableBtn`;
        buttonFunc = () => {};
        buttonColor = 'cinder-block';
        buttonHoverColor = 'cinder-block';
      }
      getStarted = (
        <Button
          data-qa-ref={qaRef}
          data-testid={qaRef}
          buttonText={buttonText}
          onClick={buttonFunc}
          buttonColor={buttonColor}
          buttonHoverColor={buttonHoverColor}
          buttonBorderRounded={true}
        />
      );
    } else if (this.props.isCurrentPlan) {
      getStarted = (
        <div
          className="current-plan"
          data-qa-ref={`${StringHelper.pathnameToKebabCase()}-${this.props.tier.toLowerCase()}-thisIsYourPlan`}
        >
          <FormattedMessage
            id=""
            description="button, pricing page, button is locked, and informs user that plan is already active"
            defaultMessage="This is your plan"
          />
        </div>
      );
    } else if (this.props.changePlanRestricted) {
      const supportEmail =
        HfReactHelper.HfConstants.supportEmail[
          HfReactHelper.HfSites.SITE_CODE_HS
        ];
      getStarted = (
        <MailToButton
          email={supportEmail}
          subject={intl.formatMessage(messages.buttonEmailInquiry)}
        />
      );
    } else if (
      this.props.isAdmin &&
      ((!this.props.isApi && !this.props.UiPendingCancel) ||
        (this.props.isApi && !this.props.ApiPendingCancel))
    ) {
      const isSkipCC =
        this.props.isLoggedIn &&
        this.props.skipCC &&
        this.props.trialPeriod > 0;
      let buttonText = intl.formatMessage(messages.buttonFreeTrial);
      let qaRef = `${StringHelper.pathnameToKebabCase()}-${this.props.tier.toLowerCase()}-startFreeTrialBtn`;
      let buttonLink = null;
      let buttonFunc = isSkipCC
        ? this.launchNoCCTrial
        : this.launchCreateAccountModal;

      if (this.props.isLoggedIn && !isSkipCC) {
        buttonLink = redirectUrl;
      }
      if (this.props.mustContactSales && this.props.isUpgrade) {
        buttonFunc = this.launchSalesModal;
      }
      if (
        this.props.isApi ||
        isFree ||
        (this.props.isLoggedIn && !this.props.isFreeTrialEligible)
      ) {
        buttonText = intl.formatMessage(messages.buttonGetStarted);
        qaRef = `${StringHelper.pathnameToKebabCase()}-${this.props.tier.toLowerCase()}-getStartedBtn`;
      }
      getStarted = (
        <Button
          data-qa-ref={qaRef}
          data-testid={qaRef}
          buttonText={buttonText}
          buttonHoverColor={primaryColor}
          wrapText={true}
          buttonBorderRounded={true}
          {...(buttonLink ? { buttonLink } : { onClick: buttonFunc })}
        />
      );
    } else {
      getStarted = <div className="no-plan-option" />;
    }

    // No CC Free trial form
    let noCCTrialForm;
    if (
      this.props.isLoggedIn &&
      this.props.skipCC &&
      !isFree &&
      this.props.trialPeriod > 0
    ) {
      noCCTrialForm = (
        <form
          method="POST"
          id={`no_cc_trial_${this.props.planId}`}
          ref={`${this.formRef}`}
        >
          <input type="hidden" name="skip_free_trial_cc" value="1" />
          <input type="hidden" name="period" value={this.props.timePeriod} />
          <input
            type="hidden"
            name="free_trial_period"
            value={this.props.trialPeriod}
          />
          <input type="hidden" name="plan_id" value={this.props.planId} />
        </form>
      );
    }

    let mainFeature = <></>;
    if (this.props.mainFeature) {
      if (!this.props.isApi && this.props.isTeamPlan) {
        const name = (
          <div className="main-feature">
            <FormattedMessage
              id=""
              description="Text asking user to contact support if they want to buy a large number of user seats"
              defaultMessage="Buy <span>{minSize, number} to {maxSize, number}</span> users online"
              values={{
                /* eslint-disable-next-line react/display-name */
                span: (...chunks) => (
                  <span className="c-cerulean">{chunks}</span>
                ),
                minSize: this.props.minSeats,
                maxSize: this.props.maxSeats,
              }}
            />
            <span className="contact-us">
              <FormattedMessage
                id=""
                description="Text asking user to contact support if they want to buy a large number of user seats."
                defaultMessage="for {maxSize, plural, =1{#+ user} other {#+ users}} contact us"
                values={{
                  maxSize: this.props.maxSeats + 1,
                }}
              />
            </span>
          </div>
        );

        mainFeature = (
          <PricingLevelFeature
            name={name}
            planName={this.props.name}
            key={`feature-${name}`}
            isFree={isFree}
            expanded={this.state.expanded}
            removeCheckMark={true}
          />
        );
      } else {
        const number = this.props.mainFeature.number;
        if (!isNaN(number)) {
          if (number > 1) {
            this.props.mainFeature.number = intl.formatMessage(
              messages.minNumFeatureValue,
              {
                number,
              },
            );
          }
        }
        featureProps.unshift(
          Object.assign(this.props.mainFeature, {
            mainFeature: true,
          }),
        );
      }
    }

    const features = featureProps.map((feat) => {
      let name = feat.name || '';
      const detail = feat.detail || null;
      const number = feat.number || feat.number === 0 ? feat.number : null;
      const type = feat.type || null;

      if (name === 'Integrations') {
        name = (
          <FormattedMessage
            id=""
            description="Integrations with Dropbox, Google Drive, Microsoft Word, and more"
            defaultMessage="Integrations with <a>Dropbox</a>, <b>Google Drive</b>, <c>Microsoft Word</c>, and more"
            values={{
              // eslint-disable-next-line react/display-name
              a: (...chunks) => {
                return <a href={dbxLink}>{chunks}</a>;
              },
              b: (...chunks) => {
                return <a href={gdLink}>{chunks}</a>;
              },
              c: (...chunks) => {
                return <a href={mswLink}>{chunks}</a>;
              },
            }}
          />
        );
      }

      return (
        <PricingLevelFeature
          name={name}
          type={type}
          planName={this.props.name}
          sup={
            feat.mainFeature &&
            this.isFreeTier() &&
            this.props.pricingSource === 'api'
          }
          detail={detail}
          number={number}
          key={`feature-${name}`}
          isFree={isFree}
          expanded={this.state.expanded}
        />
      );
    });

    const tier = this.props.tier;

    const levelClasses = classNames({
      'small-5': true,
      'medium-4': !this.props.coupon,
      'medium-offset-1': true,
      'medium-3': this.props.coupon,
      'large-12': true,
      'large-offset-0': true,
      columns: true,
      'level-title': true,
    });
    const arrowClasses = classNames({
      'l-vertical-align': true,
      arrow: true,
      expanded: this.state.expanded,
    });

    const priceClasses = classNames({
      price: true,
    });

    const recommendedLabel = this.props.isRecommended && (
      <div
        className={classNames({
          'm-pricing-level--recommended-label': true,
          'small-12': true,
          'medium-12': true,
          columns: true,
        })}
      >
        <UIIcon src={ActivityLine} role="presentation" />
        {intl.formatMessage(messages.recommendedLabelMessage)}
      </div>
    );

    return (
      <div
        data-test-id="pricing-level"
        className={classNames({
          'm-pricing-level-new': true,
          'small-12': true,
          'medium-12': true,
          'large-3':
            !HfReactHelper.isHelloSign() && this.props.totalColumns >= 3,
          'large-4': HfReactHelper.isHelloSign() && this.props.totalColumns > 3,
          'large-6':
            this.props.totalColumns === 2 ||
            (HfReactHelper.isHelloSign() && this.props.totalColumns === 3),
          'large-12': this.props.totalColumns === 1,
          columns: true,
        })}
      >
        {this.state?.modalPortalHack}

        {recommendedLabel}
        <div
          onClick={this.onLevelClick}
          className={classNames(primaryColorBG, {
            'm-pricing-level--header': true,
            'small-12': true,
            'medium-12': true,
            columns: true,
            block: true,
          })}
        >
          <div className={levelClasses}>
            <div className="level-title l-vertical-align-wrapper">
              <div className="l-vertical-align">{tier}</div>
            </div>
          </div>
          {oldPriceMobile}
          <div
            className={classNames({
              'hide-for-large-up': true,
              'small-7': true,
              'medium-5': true,
              'medium-offset-0': this.props.coupon,
              'medium-offset-1': !this.props.coupon,
              columns: true,
              'level-price': true,
            })}
          >
            <div className="level-title l-vertical-align-wrapper">
              <div className="l-vertical-align">
                <span className={priceClasses}>
                  {priceStr}
                  <span className="billing-period">{period}</span>
                </span>
              </div>
            </div>
          </div>
          <div
            className={classNames({
              'arrow-container': true,
              'hide-for-large-up': true,
              'hide-for-small': true,
              'small-2': true,
              'medium-1': true,
              columns: true,
            })}
          >
            <div className="level-title l-vertical-align-wrapper">
              <div className={arrowClasses}>&nbsp;</div>
            </div>
          </div>
        </div>
        <div className={bodyClasses}>
          <div className="first-section block">
            {oldPrice}
            <div className="m-pricing-level--body small-3 large-12 small-centered columns block">
              <div className={priceClasses}>{priceStr}</div>
              {billingPeriod}
            </div>
            <div className="block">
              {getStarted}
              <PaymentModal
                key={this.props.planId}
                isUpgrade={this.props.isUpgrade}
                planId={this.props.planId}
                planName={this.props.name}
                planRawName={this.props.rawName}
                isFreeTrialEligible={this.props.isFreeTrialEligible}
                isApi={this.props.isApi}
                numFreeDays={this.props.numFreeDays}
                price={this.props.price}
                timePeriod={this.props.timePeriod}
                monthlyExpectedRate={this.props.monthlyExpectedRate}
                annualExpectedRate={this.props.annualExpectedRate}
                formattedMonthlyExpectedRate={
                  this.props.formattedMonthlyExpectedRate
                }
                formattedAnnualExpectedRate={
                  this.props.formattedAnnualExpectedRate
                }
                monthlyProratedRate={this.props.monthlyProratedRate}
                annualProratedRate={this.props.annualProratedRate}
                formattedMonthlyProratedRate={
                  this.props.formattedMonthlyProratedRate
                }
                formattedAnnualProratedRate={
                  this.props.formattedAnnualProratedRate
                }
                currentMonthlyRate={this.props.currentMonthlyRate}
                currentAnnualRate={this.props.currentAnnualRate}
                isTrialing={this.props.isTrialing}
                proratio={this.props.proratio}
                couponCode={this.props.couponCode}
                couponNumMonths={this.props.couponNumMonths}
                ccSuffix={this.props.ccSuffix}
                ccExpiration={this.props.ccExpiration}
                cardHolderName={
                  this.props.cardHolderName ? this.props.cardHolderName : ''
                }
                name={this.props.name}
                paymentForm={this.props.paymentForm}
                registerFaxLineForm={this.props.registerFaxLineForm}
                csrfToken={this.props.csrfToken}
                dbxPaymentMethod={this.props.dbxPaymentMethod}
                errors={this.props.errors}
                currentPlanName={this.props.currentPlanName}
                tosUrl={this.props.tosUrl}
                refundPolicyUrl={this.props.refundPolicyUrl}
                privacyPolicyUrl={this.props.privacyPolicyUrl}
                ackAutoRenewal={this.props.ackAutoRenewal}
                showHSAutoRenewal={this.props.showHSAutoRenewal}
                isAccountConfirmed={this.props.isAccountConfirmed}
                UiPendingCancel={this.props.UiPendingCancel}
                isModalOpen={this.state.isModalOpen}
                closeModal={this.closePaymentModal}
                legalRequirementLinkHref={this.props.legalRequirementLinkHref}
                cancelLinkHref={this.props.cancelLinkHref}
                nextBillingDate={this.props.nextBillingDate}
                newNumFaxPages={this.props.newNumFaxPages}
                newPageQuota={this.props.newPageQuota}
                numPagesUsed={this.props.numPagesUsed}
                faxLinesCostInCents={this.props.faxLinesCostInCents}
                hasTeam={this.props.hasTeam}
              />
              {noCCTrialForm}
            </div>
            {this.props.canDirectPurchase && (
              <Spacer below={SpacerSize.SM}>
                <Body className="m-pricing-level--direct-purchase">
                  {!this.props.isCurrentPlan && !this.isFreeTier() && (
                    <FormattedMessage
                      id=""
                      description="Pricing page, start subscription, pay today"
                      defaultMessage="or <a>purchase now</a>"
                      values={{
                        // eslint-disable-next-line react/display-name
                        a: (...chunks) => {
                          return (
                            <a
                              onClick={() =>
                                this.handlePurchaseNow(
                                  `${redirectUrl}&direct_purchase=1`,
                                )
                              }
                              data-qa-ref={`${StringHelper.pathnameToKebabCase()}-${this.props.tier.toLowerCase()}-purchaseNow`}
                            >
                              {chunks}
                            </a>
                          );
                        },
                      }}
                    />
                  )}
                </Body>
              </Spacer>
            )}
          </div>
        </div>
        {mainFeature}
        {features}
        {this.props.isTeamPlan && (
          <>
            <div
              className={classNames(
                'm-pricing-level--feature final-block small-12 columns block',
                {
                  expanded: this.state.expanded,
                },
              )}
            >
              <FormattedMessage
                id=""
                description="pricing page, plan has many more features"
                defaultMessage="and many more features"
              />
            </div>
            <div className="hide-for-large-up">
              <div
                className={classNames(
                  'm-pricing-level--feature final-block small-12 columns block',
                  {
                    expanded: this.state.expanded,
                  },
                )}
              >
                <FormattedMessage
                  id=""
                  description="pricing plan disclaimer"
                  defaultMessage="*Additional cost add-on (starts at 5 users), <l1>contact sales</l1> for details."
                  values={{
                    l1: (...chunks) => (
                      <a
                        href={HfReactHelper.getWebflowUrl(
                          'form/contact-us-general',
                        )}
                      >
                        {chunks}
                      </a>
                    ),
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

PricingLevelNew.propTypes = {
  signupUrl: PropTypes.string,
  paymentForm: PropTypes.object.isRequired, // Serialized sfForm
  signupForm: PropTypes.object.isRequired, // Serialized sfForm
  dbxPaymentMethod: PropTypes.object,
  mustContactSales: PropTypes.bool,
  isUpgrade: PropTypes.bool,
  isDelinquent: PropTypes.bool,
  changePlanRestricted: PropTypes.bool,
  googleSignInClientId: PropTypes.string,
  csrfToken: PropTypes.string,
  monthlyRedirect: PropTypes.string,
  annualRedirect: PropTypes.string,
  legalRequirementLinkHref: PropTypes.string,
  cancelLinkHref: PropTypes.string,
  countryCodeFromIp: PropTypes.string,
};

export default injectIntl(PricingLevelNew);
