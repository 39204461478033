import React from 'react';
import { Accordion } from '@dropbox/dig-components/accordion';
import styles from './white-labeling-app.module.scss';
import { Text } from '@dropbox/dig-components/typography';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';

interface AccordionColorPickerSectionItem {
  id: string;
  label: string;
}
export interface AccordionColorPickerSection {
  sectionId: string;
  sectionLabel: string;
  items: AccordionColorPickerSectionItem[];
}
export interface AccordionColorPickerProps {
  sections: AccordionColorPickerSection[];
  getValue: (id: string) => string | undefined;
  setValue: (id: string, value: string) => void;
  setLogoUrl: (url: string) => void;
}

const messages = defineMessages({
  fileInputMessage: {
    id: '',
    description: 'File input label',
    defaultMessage: 'white labeling logo',
  },
});

function AccordionColorPicker(props: AccordionColorPickerProps) {
  const intl = useIntl();
  const [expandedPanels, setExpandedPanels] = React.useState<string[]>([
    'logo',
    ...props.sections.map((s) => s.sectionId),
  ]);
  const handleClick = (itemId: string) => {
    setExpandedPanels((prev) => {
      if (prev.includes(itemId)) {
        return prev.filter((id) => id !== itemId);
      } else {
        return prev.concat([itemId]);
      }
    });
  };

  const saveLogo = (event: any) => {
    if (event.target.files[0]) {
      props.setLogoUrl(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <Accordion
      expandedPanels={expandedPanels}
      variant="standard"
      className={styles.accordion}
      data-qa-ref="accordion-color-picker"
    >
      <Accordion.Item className={styles.accordionItem} itemId="logo">
        <Accordion.Header
          onClick={() => handleClick('logo')}
          className={styles.accordionHeader}
          spacing="medium"
        >
          <Accordion.HeaderContent
            withTitle={
              <FormattedMessage
                id=""
                description="Logo Title"
                defaultMessage="Logo"
              />
            }
            className={styles.accordionHeaderContent}
          />
        </Accordion.Header>
        <Accordion.Panel spacing="medium">
          <Text color="faint">
            <FormattedMessage
              id=""
              description="Logo Description"
              defaultMessage="HEIC, PNG, JPG allowed. Recommended width: 64px"
            />
          </Text>
          <input
            type="file"
            accept="image/png, image/jpg, image/jpeg, image/bmp, image/gif"
            aria-label={intl.formatMessage(messages.fileInputMessage)}
            onChange={saveLogo}
          />
        </Accordion.Panel>
      </Accordion.Item>
      {props.sections.map((section) => (
        <Accordion.Item
          className={styles.accordionItem}
          key={section.sectionId}
          itemId={section.sectionId}
          data-qa-ref="color-picker-section"
        >
          <Accordion.Header
            onClick={() => handleClick(section.sectionId)}
            className={styles.accordionHeader}
            spacing="medium"
          >
            <Accordion.HeaderContent
              withTitle={section.sectionLabel}
              className={styles.accordionHeaderContent}
            />
          </Accordion.Header>
          <Accordion.Panel spacing="medium">
            {section.items.map((item) => (
              <div
                key={item.id}
                className={styles.colorPickerRow}
                data-qa-ref="color-picker-subsection"
              >
                <Text>{item.label}</Text>
                <input
                  className={styles.colorPicker}
                  type="color"
                  value={props.getValue(item.id) || '#FFFFFF'}
                  onChange={(e) => props.setValue(item.id, e.target.value)}
                  aria-label={item.label}
                />
              </div>
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default AccordionColorPicker;
