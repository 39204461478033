import React from 'react';
import classnames from 'classnames';
import styles from './icons.scss';

const LinkedinLogo = (props) => (
  <svg
    {...props}
    viewBox={props.viewBox ? props.viewBox : '0 0 21 21'}
    className={classnames(styles.icon, props.className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.40962 0.00439453C1.59798 0.010544 0.940674 0.669562 0.940674 1.48109V19.0188C0.940674 19.835 1.60425 20.4972 2.42116 20.4972H19.5154C20.3281 20.4972 20.9913 19.8437 21.0001 19.035V1.46484C20.9913 0.660851 20.3344 0.010544 19.527 0.00439453H2.40962ZM3.67772 4.58591C3.67772 3.61392 4.44952 2.82416 5.40315 2.82416C6.35427 2.82416 7.12718 3.61392 7.12718 4.58591C7.12718 5.55908 6.35427 6.34873 5.40315 6.34873C4.44952 6.34873 3.67772 5.55908 3.67772 4.58591ZM11.65 9.0224C12.0471 8.25345 13.0176 7.4428 14.4651 7.4428C17.4779 7.4428 18.0341 9.46864 18.0341 12.1017V17.4658H15.0609V12.7098C15.0609 11.5757 15.0405 10.1169 13.515 10.1169C11.9667 10.1169 11.7291 11.3523 11.7291 12.6278V17.4658H8.75646V7.68606H11.6102V9.0224H11.65ZM3.91481 17.4659H6.88998V7.68627H3.91481V17.4659Z"
      fill="#212121"
    />
  </svg>
);

export default React.memo(LinkedinLogo);
