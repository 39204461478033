import { TextInput } from '@dropbox/dig-components/text_fields';
import { Button } from '@dropbox/dig-components/buttons';
import { Banner } from '@dropbox/dig-components/banner';
import { Text } from '@dropbox/dig-components/typography';
import { Card } from '@dropbox/dig-components/card';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { strong } from 'hellospa/common/hs-intl-provider';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from './snackbars';
import { Cluster, Split, Stack } from '@dropbox/dig-foundations';
import { useWebAppClient } from 'hello-react/web-app-client/build-web-app-client';

type ResultState =
  | { type: 'error'; error?: string }
  | { type: 'warn-unmatched'; response: string }
  | { type: 'success'; message?: string }
  | { type: 'idle' };

// NOTE: This is not translated. We don't support multiple languages in the API
const API_EVENT_RECEIVED_RESPONSE = 'Hello API Event Received';

function prettify(jsonString: string) {
  try {
    return JSON.stringify(JSON.parse(jsonString), null, 2);
  } catch (e) {
    return jsonString;
  }
}

interface CallbackTesterProps {
  value?: string;
  defaultValue?: string;
  onValueChange?: (url: string) => void;
  'aria-labelledby'?: string;
  'aria-label'?: string;
  name: string;

  /**
   * An action to display the in success banner shown to the user
   */
  successAction?: React.ReactNode;
}

/**
 * This component has some quirks because it needs to support being nested
 * inside of a global form on the settings page.
 */
export function CallbackTester(props: CallbackTesterProps) {
  const {
    value: valueFromProps,
    onValueChange: onValueChangeFromProps,
    defaultValue = '',
    'aria-labelledby': ariaLabelledby,
    'aria-label': ariaLabel,
    successAction,
    name,
  } = props;
  const isControlled =
    valueFromProps !== undefined && onValueChangeFromProps !== undefined;
  const [internalValue, setInternalValue] = useState(defaultValue);
  const client = useWebAppClient();
  const snackbar = useSnackbar();
  const mutation = useMutation({
    mutationFn: (url: string) => {
      return client.apiSettings.testCallbackURL(url);
    },
  });

  const value = isControlled ? valueFromProps : internalValue;

  let state: ResultState = { type: 'idle' };

  if (mutation.error) {
    state = { type: 'error' };
  } else if (mutation.data?.error) {
    state = { type: 'error', error: mutation.data.error };
  } else if (mutation.data?.success && mutation.data.matched) {
    state = { type: 'success', message: mutation.data.message };
  } else if (mutation.data?.response && mutation.data.matched === false) {
    state = { type: 'warn-unmatched', response: mutation.data.response };
  }

  function submit() {
    if (value) {
      mutation.mutate(value);
    }
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (isControlled) {
      onValueChangeFromProps(event.target.value);
    } else {
      setInternalValue(event.target.value);
    }
    mutation.reset();
  }

  return (
    <Stack gap="Micro Medium">
      <Split alignY="bottom" gap="Micro Medium">
        <Split.Item width="fill" style={{ maxWidth: 500 }}>
          <TextInput
            placeholder="https://example.com/callback"
            name={name}
            aria-labelledby={ariaLabelledby}
            aria-label={ariaLabel}
            autoComplete="off"
            value={value}
            onChange={onChange}
            // Mimicking default form behavior here because we can't wrap
            // this in a separate form without breaking the settings page.
            // There's a top-level form somewhere looking for this input
            onKeyDown={(evt) => {
              if (evt.key === 'Enter') {
                evt.preventDefault();
                submit();
              }
            }}
          />
        </Split.Item>
        <Split.Item>
          <Button variant="opacity" onClick={submit} type="button">
            <FormattedMessage
              id=""
              description="button, setting page, when clicked it will test the call back url the customer entered"
              defaultMessage="Test"
            />
          </Button>
        </Split.Item>
      </Split>

      {mutation.isLoading ? (
        <Cluster padding="Micro Medium" gap="Micro Medium">
          <Cluster.Item>
            <Spinner aria-valuetext="Loading" size="small" />
          </Cluster.Item>
          <Cluster.Item>
            <Text>
              <FormattedMessage
                id=""
                description="text in createAPPForm informing user that test is being processed and message will appear soon"
                defaultMessage="Posting a test message to your URL..."
              />
            </Text>
          </Cluster.Item>
        </Cluster>
      ) : null}

      {state.type === 'error' ? (
        <Banner type="alert">
          <Banner.Message>
            {state.error ? (
              <FormattedMessage
                id=""
                description="error message when testing callback URL, informing user some error has occurred"
                defaultMessage="Oops! We couldn't test because of an error: {errorMessage}"
                values={{
                  errorMessage: state.error,
                }}
              />
            ) : (
              <FormattedMessage
                id=""
                description="error message when testing callback URL, informing user some error has occurred"
                defaultMessage="Oops! We couldn't test because of an error"
              />
            )}
          </Banner.Message>
        </Banner>
      ) : null}

      {state.type === 'warn-unmatched' ? (
        <>
          <Banner type="warning">
            <Banner.Message>
              <FormattedMessage
                id=""
                description="warning message when testing callback URL, informing user some error has occurred"
                defaultMessage="Your callback URL did not return the expected response. Please ensure your callback returns 'Hello API Event Received'."
              />
            </Banner.Message>
            <Banner.Actions>
              <Button
                variant="transparent"
                type="button"
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(
                      API_EVENT_RECEIVED_RESPONSE,
                    );
                    snackbar.success(
                      <FormattedMessage
                        id=""
                        description="message when testing callback URL, informing user that the response has been copied to the clipboard"
                        defaultMessage="Copied response to clipboard"
                      />,
                    );
                  } catch (error) {
                    snackbar.fail(
                      <FormattedMessage
                        id=""
                        description="message when testing callback URL, informing user that the response has not been copied to the clipboard"
                        defaultMessage="Failed to copy response to clipboard"
                      />,
                    );
                  }
                }}
              >
                Copy to Clipboard
              </Button>
            </Banner.Actions>
          </Banner>
          <Stack gap="Micro Small">
            <Text>
              <FormattedMessage
                id=""
                description="text when testing callback URL, response being returned"
                defaultMessage="The following response was received from your callback URL:"
              />
            </Text>
            <Card>
              <Card.Content>
                <pre>
                  <Text size="small" monospace>
                    {prettify(state.response)}
                  </Text>
                </pre>
              </Card.Content>
            </Card>
          </Stack>
        </>
      ) : null}

      {state.type === 'success' ? (
        <>
          <Banner type="success">
            <Banner.Message>
              <FormattedMessage
                id=""
                description="success message while testing event callback"
                defaultMessage="<strong>Success! </strong>''{eventCallbackStr}'' was found in the response."
                values={{
                  eventCallbackStr: API_EVENT_RECEIVED_RESPONSE,
                  strong,
                }}
              />
            </Banner.Message>
            {successAction ? (
              <Banner.Actions>{successAction}</Banner.Actions>
            ) : null}
          </Banner>
          {state.message ? (
            <Stack gap="Micro Small">
              <Text>
                <FormattedMessage
                  id=""
                  description="text when testing callback URL, message is being processed"
                  defaultMessage="The following message was sent to your callback URL:"
                />
              </Text>
              <Card>
                <Card.Content>
                  <pre>
                    <Text size="small" monospace>
                      {prettify(state.message)}
                    </Text>
                  </pre>
                </Card.Content>
              </Card>
            </Stack>
          ) : null}
        </>
      ) : null}
    </Stack>
  );
}
