import React, { useState } from 'react';
import { Button } from '@dropbox/dig-components/buttons';
import { FormattedMessage } from 'react-intl';
import hsFetch from 'js/sign-components/common/hs-fetch';
import { redirectTo } from 'hellospa/common/utils/redirect';
import DowngradeFlowModalContainer from 'hellospa/components/cancellation-flow-modal';
import { PauseCancelModalContext } from 'hellospa/components/cancellation-flow-modal/types';
import { SiteCodeType } from 'js/sign-components/common/brand';
import { modalTypes } from '../../../hellospa/components/cancellation-flow-modal/constants';
import { PAP_Select_SignBillingCancelPlan } from 'pap-events/sign_standalone/select_sign_billing_cancel_plan';
import { logPAPEvent } from 'js/sign-components/common/product-analytics-pipeline';

type CancelPlanButtonProps = {
  isApi: boolean;
  siteCode: SiteCodeType;
  showPauseCancelFlow: boolean;
};

const CancelPlanButton = ({
  isApi,
  siteCode,
  showPauseCancelFlow,
}: CancelPlanButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pauseCancelModalContext, setPauseCancelModalContext] =
    useState<PauseCancelModalContext | null>(null);

  const planType = isApi ? 'api' : 'ui';

  const fallbackCancelSubscriptionFlow = `/account/cancelSubscription?p=${planType}`;

  const onClickCancelPlan = () => {
    if (showPauseCancelFlow && siteCode === 'S') {
      const formData = new FormData();

      formData.append('p', planType);

      hsFetch('/endpoint/org/PauseOption', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: formData,
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data.errorMessage) {
            if (data.errorMessage !== 'unknown') {
              window.location.reload();
            } else {
              logPAPEvent(
                PAP_Select_SignBillingCancelPlan({
                  signCancelSubscriptionExperience: 'cancel_subscription_page',
                }),
              );
              redirectTo(fallbackCancelSubscriptionFlow);
            }
          } else if (!data.showPauseCancelFlow) {
            logPAPEvent(
              PAP_Select_SignBillingCancelPlan({
                signCancelSubscriptionExperience: 'cancel_subscription_page',
              }),
            );
            redirectTo(fallbackCancelSubscriptionFlow);
          } else {
            logPAPEvent(
              PAP_Select_SignBillingCancelPlan({
                signCancelSubscriptionExperience:
                  'pause_or_cancel_subscription_modal',
              }),
            );
            setIsModalOpen(true);
            setPauseCancelModalContext({ ...data, siteCode });
          }
        });
    } else {
      logPAPEvent(
        PAP_Select_SignBillingCancelPlan({
          signCancelSubscriptionExperience: 'cancel_subscription_page',
        }),
      );
      redirectTo(fallbackCancelSubscriptionFlow);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {pauseCancelModalContext && (
        <DowngradeFlowModalContainer
          modalContext={pauseCancelModalContext}
          isModalOpen={isModalOpen}
          onClose={handleCloseModal}
          startingModal={modalTypes.BILLBOARD}
          currentStatus={null}
        />
      )}
      <Button
        variant="opacity"
        onClick={onClickCancelPlan}
        data-qa-ref="cancel-plan-btn"
        data-testid="cancel-plan-btn"
        type="button"
      >
        <FormattedMessage
          id=""
          defaultMessage="Cancel Plan"
          description="Text for a button which allows the user to cancel subscription plan when pressed."
        />
      </Button>
    </>
  );
};

export default CancelPlanButton;
