/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  global-require,
  import/extensions,
  import/no-extraneous-dependencies,
  no-console,
  no-script-url,
  no-void,
*/
import React from 'react';
import GlobalHeader from 'hellospa/components/global-header';

class GlobalHeaderWithNotifier extends React.Component {
  componentDidMount() {
    this.props.app.notifier.push(this);
  }

  // See src/signer/components/main/pages/sig-doc#L426
  notify() {
    this.forceUpdate();
  }

  render() {
    return <GlobalHeader {...this.props} />;
  }
}

export default GlobalHeaderWithNotifier;
