/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  eqeqeq,
  func-names,
  import/no-extraneous-dependencies,
  max-len,
  no-restricted-syntax,
  react/no-find-dom-node,
  react/prop-types
*/
import React from 'react';
import GlobalNav from 'hellospa/components/global-nav';

class GlobalNavWithNotifier extends React.Component {
  componentDidMount() {
    this.props.app.notifier.push(this);
  }

  // See src/signer/components/main/pages/sig-doc#L426
  notify() {
    this.forceUpdate();
  }

  render() {
    return <GlobalNav {...this.props} />;
  }
}

export default GlobalNavWithNotifier;
