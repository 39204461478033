import React from 'react';

const CheckMark = ({ color = '#019BCE', width = '13', height = '13' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.07452 6.28354L5.15207 8.98409"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="square"
    />
    <path
      d="M5.27242 8.86766L10.3414 2.55026"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="square"
    />
  </svg>
);

export default CheckMark;
