import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  date: Date;
  format?: string;
  includeYesterday?: boolean;
};

function RelativeDate(props: Props) {
  const tmp = moment().utc();
  const today = tmp.startOf('day').toDate();
  const yesterday = tmp.subtract(2, 'day').toDate();
  let format = props.format;
  if (props.date > today) {
    format = 'h:mm A';
  } else if (props.date > yesterday && props.includeYesterday) {
    return (
      <FormattedMessage
        id=""
        description="Text for relative date if it is yesterday"
        defaultMessage="Yesterday"
      />
    );
  }
  return <>{moment(props.date).format(format)}</>;
}

RelativeDate.defaultProps = {
  format: 'MM/DD/YY, h:mm A',
  includeYesterday: false,
};

export default RelativeDate;
