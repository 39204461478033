import './white-labeling.scss';
import React from 'react';
import WhiteLabelingApp from 'hellosign/components/api/white-labeling';
import { FormattedMessage } from 'react-intl';
import { Title, Text } from '@dropbox/dig-components/typography';
import { getBrandName, siteCodes } from 'js/sign-components/common/brand';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

function WhiteLabelingLandingPageV2() {
  return (
    <div className="m-white-labeling">
      <header>
        <Title tagName="h1" size="large">
          <FormattedMessage
            id=""
            description="Page Title"
            defaultMessage="Stay on brand with a customizable signing experience"
          />
        </Title>
        <Title tagName="h2">
          <FormattedMessage
            id=""
            description="Page Subtitle"
            defaultMessage="Test how easy it is to brand your eSignature workflow"
          />
        </Title>
      </header>

      <main>
        <WhiteLabelingApp />
        <div className="m-white-labeling-copy">
          <div className="m-white-labeling-copy-text">
            <Text tagName="p" size="large">
              <FormattedMessage
                id=""
                description="First paragraph, introducing personalization"
                defaultMessage="{brandName} API enables businesses to provide their customers with seamless,
              fully branded document workflows. Our easy-to-integrate branding solution empowers you to build
              consistent user experiences and effortlessly enhance your brand presence, all while maintaining
              the highest standards of security and compliance."
                values={{
                  brandName: getBrandName(siteCodes.sign),
                }}
              />
            </Text>
            <Text tagName="p" size="large">
              <FormattedMessage
                id=""
                description="Line inviting user to explore further"
                defaultMessage="Learn more about this feature and plans on our <l1>pricing page</l1>"
                values={{
                  l1(linkText) {
                    return (
                      <a
                        href={HfReactHelper.getWebflowUrl(
                          'products/dropbox-sign-api/pricing',
                        )}
                      >
                        {linkText}
                      </a>
                    );
                  },
                }}
              />
            </Text>
          </div>
        </div>
      </main>
    </div>
  );
}
export default WhiteLabelingLandingPageV2;
