/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  global-require
*/

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// You must register a React component here before calling
// render_react_component in PHP
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// ESNext imports
import React from 'react';
import AdvancedSearch from 'ui/advanced-search';
import BillingSettings from 'common/components/billing-settings';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import DropboxBilling from 'common/components/billing-settings/dropbox-billing';
import HelloReactApplication from 'hello-react/components/hello-react-application';
import APISettings from 'hellospa/components/api-settings';
import APIReferenceDoc from 'hellospa/components/api-reference-doc';
import { Title } from '@dropbox/dig-components/typography';
import { constructStore } from 'hellospa/redux/store';
import { buildWebAppClient } from 'hello-react/web-app-client/build-web-app-client';
import TopBarMenuLinkV2 from './home/top-bar-menu/marketing-nav-bar/top-bar-menu-link';
import TopBarMenu from './home/top-bar-menu';
import LogInForm from './auth/login-form';
import EmbeddedTestingTool from './api-docs/embedded-testing-tool';

const ImportContactsButton = React.lazy(async () => ({
  default: await (
    await import('hellospa/components/contact-input/import-contacts/button')
  ).ImportContactsButton,
}));

let sharedStore;
let sharedAppActions;
/**
 * WARNING: This is a hack to allow components in this file to have access to
 * Redux. It should NOT be used anywhere else.
 *
 * I don't have a good way to get the splits from prepareSPA, so this just
 * assumes splits are all off. That should be good enough for these very small
 * components.
 */
function WrapPHPComponent(Component, displayName) {
  function ComponentWrapper({ csrfToken, ...props }) {
    const { store, appActions } = React.useMemo(() => {
      sharedAppActions ??= buildWebAppClient('');
      sharedStore ??= constructStore(() => ({
        appActions: sharedAppActions,
        // Feature flags are provided in appliaction.js
        featureFlags: {},
      }));

      return {
        store: sharedStore,
        appActions: sharedAppActions,
      };
    }, []);

    return (
      <HelloReactApplication
        store={store}
        appActions={appActions}
        csrfToken={csrfToken}
        featureFlags={{}}
        csrfTokenAttachmentDelete=""
        preloadedTsmGroupKey=""
      >
        <Component {...props} />
      </HelloReactApplication>
    );
  }
  ComponentWrapper.displayName = `PHPApp(${displayName})`;

  return ComponentWrapper;
}

function makeRemovedComponent(name) {
  return function RemovedComponent() {
    throw new Error(`Component ${name} is no longer available`);
  };
}

const WithRouting = (Component, displayName) => {
  const history = createBrowserHistory({});
  function ComponentWrapper(props) {
    return (
      <Router history={history}>
        <Component {...props} />
      </Router>
    );
  }
  ComponentWrapper.displayName = `WithRouting(${displayName})`;
  return ComponentWrapper;
};

module.exports = {
  APIReferenceDoc,
  APISettings,
  AdvancedSearch,
  ApiMenu: require('./api-docs/api-menu'),
  AppFooter: require('./app/footer'),
  AppLeftMenu: WithRouting(require('./app/sidebar').default),
  AppTopBar: require('./app/header').default,
  BillingSettings,
  Button: require('common/components/button').default,
  CancelSubscriptionPage: require('./home/cancel-subscription/cancel-subscription-page'),
  CloseFancyBoxButton: require('common/components/close-fancy-box-button'),
  CollectInfo: require('./home/collect-info'),
  ConfirmRequiredPage: require('./auth/confirm-required-page'),
  CreateAccount: require('./auth/create-account'),
  CreateAccountTrigger: require('./auth/create-account-trigger'),
  CustomerCancelOffer: require('./pricing/customer-cancel-offer'),
  DownloadPage: require('../../ui/download-page').default,
  DropboxBilling,
  EmbeddedTestingTool,
  EmbeddedTestingToolV1: require('./api-docs/embedded-testing-tool-v1'),
  EnterpriseAgreement: require('./pricing/enterprise-agreement'),
  EsignDisclosurePreview: require('./home/esign-disclosure-preview'),
  FaxLineConfigList: require('./app/content/team/team-fax-lines'),
  FillEmptySpace: require('common/components/fill-empty-space'),
  FixedModal: require('common/components/fixed-modal'),
  FoundationalButton: require('hellospa/foundation/control/button').default,
  GoogleSignInButton: require('./auth/google-signin-button'),
  HSTeamPage: require('./app/content/team'),
  ImageModalTrigger: require('./api-docs/image-modal-trigger'),
  ImportContactsButton: WrapPHPComponent(
    ImportContactsButton,
    'ImportContactsButton',
  ),
  IntegrationActivationButton: require('common/components/integration-activation-button'),
  LogInForm,
  LogInPage: makeRemovedComponent('LogInPage'),
  LogInVerificationForm: require('./auth/login-verification-form'),
  Notification: require('common/components/notifications/index'),
  OauthRequest: require('./auth/oauth/oauth-request').default,
  PeekABoo: require('common/components/peekaboo'),
  PricingArea: require('./pricing'),
  PurgeDocumentSettings:
    require('../components/my-settings/purge-document-settings').default,
  SignUpPage: makeRemovedComponent('SignUpPage'),
  SimpleGrayLayout: require('common/components/simple-gray-layout'),
  SkipToContentLink: require('hellospa/components/skip-to-content-link')
    .default,
  TabbedArea: require('./tabbed-area'),
  Title,
  Tooltip: require('common/components/tooltip'),
  TopBarMenu,
  TopBarMenuLink: require('./home/top-bar-menu/top-bar-menu-link'),
  TopBarMenuLinkV2,
  VerifyEmail: require('./auth/verify-email'),
  WhiteLabelingLandingPage: require('landing-pages/components/seo/white-labeling'),
};
