import * as React from 'react';
import classNames from 'classnames';
import styles from './tethered.module.css';
// a HOC...

/**
 * Tethered Component
 *
 * A tethered component is a component that positions itself around another
 * element on the page.  Things like tooltips and dropdown menus can be tethered
 * compoents.  This component takes in an `anchor` and renders the provided
 * children around the anchor in different locations depending on the props.
 */
export function TetheredComponent(props) {
  const {
    active,
    anchor,
    children,

    align,
    justify,
    position,
  } = props;
  return (
    <div className={styles.root}>
      {anchor}
      {active && (
        <div
          className={classNames(styles.content, {
            [styles.top]: position === 'top',
            [styles.bottom]: position === 'bottom',
            [styles.left]: position === 'left',
            [styles.right]: position === 'right',

            [styles.alignTop]: align === 'top',
            [styles.alignCenter]: align === 'center',

            [styles.justifyLeft]: justify === 'left',
            [styles.justifyCenter]: justify === 'center',
            [styles.justifyRight]: justify === 'right',
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
}
TetheredComponent.defaultProps = {
  align: 'top',
  justify: 'left',
  position: 'bottom',
  theme: 'light',
};
export default TetheredComponent;
