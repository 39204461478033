/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import React from 'react';
import createReactClass from 'create-react-class';

const CouponDisplay = createReactClass({
  render() {
    let couponDesc = '';
    if (this.props.couponDescription) {
      couponDesc = <div>{this.props.couponDescription}</div>;
    }
    return (
      <div
        data-test-id="coupon-display"
        className="small-6 large-3 m-coupon-display"
      >
        <div>
          Coupon Code:
          <span className="coupon-code">{this.props.couponCode}</span>
        </div>
        {couponDesc}
      </div>
    );
  },
});

module.exports = CouponDisplay;
