import React from 'react';
import { useFeatureFlag } from 'js/sign-components/common/feature-flag';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

// Use of literal "helloworks" here is acceptable. Questions? #ask-hs-frontend.
// eslint-disable-next-line no-restricted-syntax
export const crossPromoUrl = HfReactHelper.urlHelper('helloworks/crossPromo');

export function useCrossPromoLegalDisclaimerModal() {
  const [
    isCrossPromoLegalDisclaimerModelOpen,
    setIsCrossPromoLegalDisclaimerModalOpen,
  ] = React.useState(false);
  const showCrossPromoLegalDisclaimer = useFeatureFlag(
    'HelloSign_Condors_Show_Cross_Promo_Legal_Disclaimer',
  );

  const showModalIfUnseen = React.useCallback(
    (evt) => {
      if (showCrossPromoLegalDisclaimer) {
        evt.preventDefault();
        setIsCrossPromoLegalDisclaimerModalOpen(true);
      }
    },
    [showCrossPromoLegalDisclaimer],
  );

  const openCrossPromo = React.useCallback(() => {
    setTimeout(() => {
      window.open(crossPromoUrl);
      setIsCrossPromoLegalDisclaimerModalOpen(false);
    }, 4000);
  }, []);

  return {
    showModalIfUnseen,
    openCrossPromo,
    isCrossPromoLegalDisclaimerModelOpen,
  };
}
