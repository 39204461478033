import React from 'react';

const FormattedFaxNumber = (props) => {
  const { faxNumber } = props;

  const formattedFaxNumber = hellofaxJS.formatInternationalFaxNumber(faxNumber);

  return <span className="fax-number">{formattedFaxNumber}</span>;
};

export default FormattedFaxNumber;
