/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React from 'react';
import Logo from 'signer-app/parts/logo-v2';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

import './simple-gray-layout.scss';

/**
 * A top-level page layout component which tries to fill the entire page.
 * Features a simple header, a small centered card containing child content, and no footer.
 */
export default class SimpleGrayLayout extends React.Component {
  render() {
    const siteCode = this.props.siteCode || 'S';
    const externalHomepageUrl = HfReactHelper.getWebflowUrl('', siteCode);
    return (
      <div className="m-simple-gray-layout">
        <Logo
          size={40}
          href={externalHomepageUrl}
          siteCode={this.props.siteCode}
        />
        <div className="m-simple-gray-layout--card">{this.props.children}</div>
      </div>
    );
  }
}
