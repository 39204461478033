import React, { Dispatch, SetStateAction } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import {
  TextInput,
  TextInputRefObject,
} from '@dropbox/dig-components/text_fields';
import {
  FormHelperText,
  FormRow,
  FormLabel,
} from '@dropbox/dig-components/form_row';
import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Link, Text, Title } from '@dropbox/dig-components/typography';
import { Checkbox } from '@dropbox/dig-components/controls';
import { UIIcon } from '@dropbox/dig-icons';
import { HideLine, ShowLine, FailLine } from '@dropbox/dig-icons/assets';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import { getBrandName } from 'js/sign-components/common/brand';
import { useSiteCode } from 'js/sign-components/common/use-site-code';
import { SignUpData } from 'js/sign-components/generated/types/WebApp';
import { OrDivider } from 'hello-react/components/or-divider';
import {
  UpdateType,
  useAuthentication,
} from 'signer-app/authentication/use-authentication';
import styles from './authentication.module.scss';
import { SignInButtons } from './sign-in-buttons';

const messages = defineMessages({
  showPassword: {
    id: '',
    description:
      "Hover text for a button which will show the user's password in plaintext when pressed.",
    defaultMessage: 'Show password',
  },
  hidePassword: {
    id: '',
    description:
      "Hover text for a button which will hide the user's password when pressed.",
    defaultMessage: 'Hide password',
  },
  error: {
    id: '',
    description: 'Error message',
    defaultMessage: 'Error',
  },
});

type SignUpFormProps = {
  hideCreateAccountLink?: boolean; // default false
  redirectUrl?: string;
};

interface ProgressiveCheckoutSignInFormProps {
  isEmailInvalid: boolean;
  setIsEmailInvalid: Dispatch<SetStateAction<boolean>>;
  passwordSelectionRangeRef: React.MutableRefObject<
    [number | null, number | null] | null
  >;
  passwordRef: React.RefObject<TextInputRefObject>;
  isPasswordInvalid: boolean;
  setIsPasswordInvalid: Dispatch<SetStateAction<boolean>>;
  isPasswordVisible: boolean;
  togglePasswordVisible: React.DispatchWithoutAction;
  isPasswordFieldHidden: boolean;
  handleSubmit: React.HTMLAttributes<HTMLFormElement>['onSubmit'];
  submitForm: () => Promise<undefined | void>;
  meta: SignUpData;
  signUpUrl: string;
  update: UpdateType;
  dropboxManagedAndSamlLoginChecked: boolean;
  isSamlLogin: boolean;
  email: string;
  password: string | null;
  isLoading: boolean;
  rememberMe: boolean;
  showDMASnackbar: boolean;
  isOfficeIntegration: boolean;
}

export default function ProgressiveCheckoutSignInForm({
  hideCreateAccountLink = false,
  redirectUrl,
  isEmailInvalid,
  setIsEmailInvalid,
  passwordSelectionRangeRef,
  passwordRef,
  isPasswordInvalid,
  setIsPasswordInvalid,
  isPasswordVisible,
  togglePasswordVisible,
  isPasswordFieldHidden,
  handleSubmit,
  submitForm,
  meta,
  signUpUrl,
  update,
  dropboxManagedAndSamlLoginChecked,
  isSamlLogin,
  email,
  password,
  isLoading,
  rememberMe,
  showDMASnackbar,
  isOfficeIntegration,
}: SignUpFormProps &
  ProgressiveCheckoutSignInFormProps): React.ReactElement | null {
  const intl = useIntl();
  const siteCode = useSiteCode();

  const { queryParams, getDropboxManagedLoginLink } = useAuthentication();

  const onReturnToSignInOptions = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      update('dropboxManagedAndSamlLoginChecked', false);
    },
    [update],
  );

  const getEmailFieldOrEmailWelcome = React.useMemo(() => {
    if (!dropboxManagedAndSamlLoginChecked) {
      return (
        <FormRow>
          <FormLabel htmlFor="email-field">
            <Text size="small" color="faint">
              <FormattedMessage
                id=""
                description="Label for a text input field for users email"
                defaultMessage="Email"
              />
            </Text>
          </FormLabel>
          <div className={styles.inputButtonContainer}>
            <TextInput
              autoFocus
              formNoValidate
              name="email"
              type="email"
              id="email-field"
              data-testid="email-field"
              value={email}
              isInvalid={isEmailInvalid || showDMASnackbar}
              aria-invalid={isEmailInvalid || showDMASnackbar}
              aria-errormessage="email-helper-text"
              aria-describedby="email-helper-text"
              onChange={(e) => {
                setIsEmailInvalid(false);
                setIsPasswordInvalid(false);
                update('email', e.target.value);

                if (dropboxManagedAndSamlLoginChecked) {
                  update('dropboxManagedAndSamlLoginChecked', false);
                }
              }}
            />
          </div>
          {(isEmailInvalid || showDMASnackbar) && (
            <FormHelperText id="email-helper-text" variant="alert">
              <UIIcon
                src={FailLine}
                size="small"
                aria-label={intl.formatMessage(messages.error)}
              />
              {showDMASnackbar ? (
                <FormattedMessage
                  id=""
                  description="Error message on the sign up page that asks user to use Dropbox log in method"
                  defaultMessage="There’s already a Dropbox account with this email. Continue with Dropbox."
                />
              ) : (
                <FormattedMessage
                  id=""
                  description="Error message on the sign up page"
                  defaultMessage="Please enter a valid email address"
                />
              )}
            </FormHelperText>
          )}
        </FormRow>
      );
    } else {
      return (
        <FormRow>
          <Text size="large">
            <FormattedMessage
              id=""
              defaultMessage="Log in using {usersEmail}"
              description="login page, label which indicates which email is used to proceed with login"
              values={{ usersEmail: email }}
            />
          </Text>
        </FormRow>
      );
    }
  }, [
    dropboxManagedAndSamlLoginChecked,
    email,
    isEmailInvalid,
    showDMASnackbar,
    intl,
    setIsEmailInvalid,
    setIsPasswordInvalid,
    update,
  ]);

  const getSubmitContinueButton = React.useMemo(() => {
    if (!dropboxManagedAndSamlLoginChecked) {
      return (
        <Button
          type="submit"
          variant="primary"
          size="large"
          data-testid="continue-btn"
          isLoading={isLoading}
        >
          <FormattedMessage
            id=""
            description="Button text"
            defaultMessage="Continue"
          />
        </Button>
      );
    } else {
      return (
        <>
          <FormRow>
            <div className={styles.rememberMeAndContinueBtn}>
              <div className={styles.checkboxGroup}>
                <Checkbox
                  id="remember-me-checkbox"
                  data-testid="remember-me-checkbox"
                  onChange={() => update('rememberMe', !rememberMe)}
                  checked={rememberMe}
                />
                <Text tagName="label" htmlFor="remember-me-checkbox">
                  <FormattedMessage
                    id=""
                    description="Label for a sign in 'Remember me' checkbox"
                    defaultMessage="Remember me"
                  />
                </Text>
              </div>
              {/* Hide continue/sign in button if user has SAML. */}
              {!isSamlLogin && (
                <Button
                  type="submit"
                  variant="primary"
                  size="large"
                  data-testid="signin-btn"
                  isLoading={isLoading}
                >
                  <FormattedMessage
                    id=""
                    description="Button text for sign in button"
                    defaultMessage="Log in"
                  />
                </Button>
              )}
            </div>
          </FormRow>
          <FormRow>
            <Text isBold={true}>
              {/* &larr;&nbsp; */}
              <Link href="#" isBold={true} onClick={onReturnToSignInOptions}>
                &larr;&nbsp;
                <FormattedMessage
                  id=""
                  defaultMessage="Back to log in"
                  description="link, text, proposes to user to go back to previous page with the whole list of login options"
                />
              </Link>
            </Text>
          </FormRow>
        </>
      );
    }
  }, [
    dropboxManagedAndSamlLoginChecked,
    isLoading,
    rememberMe,
    isSamlLogin,
    onReturnToSignInOptions,
    update,
  ]);

  const formTitleText: JSX.Element = !dropboxManagedAndSamlLoginChecked ? (
    <FormattedMessage
      id=""
      defaultMessage="Get {brandName} {planName}"
      description="title, form title, header for a form that invites user to login and purchase selected plan"
      values={{ brandName: getBrandName(siteCode), planName: 'Essentials' }}
    />
  ) : (
    <FormattedMessage
      id=""
      defaultMessage="Welcome back"
      description="title, form title, header for a form that welcomes back existing user during log in"
    />
  );

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <FormRow>
        <div className={styles.header}>
          <Title tagName="h1" className={styles.headerTitle}>
            {formTitleText}
          </Title>
        </div>
      </FormRow>

      {!dropboxManagedAndSamlLoginChecked && (
        <FormRow>
          {!hideCreateAccountLink && !meta.hubspotVersion && (
            <Text className={styles.headerAside}>
              <FormattedMessage
                id=""
                description="link to the sign up form"
                defaultMessage="Log in or <a>sign up</a>"
                values={{
                  a(chunks: any) {
                    return (
                      <Link
                        data-testid="sign-up"
                        // Preserve URL params when navigating between
                        // login and signup pages.
                        href={signUpUrl}
                      >
                        {chunks}
                      </Link>
                    );
                  },
                }}
              />
            </Text>
          )}
        </FormRow>
      )}

      {!isOfficeIntegration && !dropboxManagedAndSamlLoginChecked && (
        <>
          <FormRow>
            <SignInButtons
              googleSignInClientId={meta.googleSignInClientId}
              redirectUrl={
                redirectUrl ||
                meta.redirectUrl ||
                queryParams.on_login_redirect_url
              }
              isApi={false}
              formType={'signin'}
              googleAnalyticsTrackingVariant={''}
              planName={undefined}
            />
          </FormRow>
          <FormRow>
            <OrDivider />
          </FormRow>
        </>
      )}

      {getEmailFieldOrEmailWelcome}

      {dropboxManagedAndSamlLoginChecked && isSamlLogin && (
        <FormRow>
          <Text size="small" color="faint" role="alert">
            <FormattedMessage
              id=""
              description="Notice informing the user they are being redirect to their companie's SAML login provider."
              defaultMessage="Redirecting you to your SAML login provider…"
            />
          </Text>
        </FormRow>
      )}

      <div
        aria-hidden={isPasswordFieldHidden}
        className={classnames({
          [styles.hidden]: isPasswordFieldHidden,
        })}
      >
        <FormRow>
          <FormLabel htmlFor="password-field">
            <Text size="small" color="faint">
              <FormattedMessage
                id=""
                description="Label for a text input field"
                defaultMessage="Password"
              />
            </Text>
          </FormLabel>
          <div className={styles.passwordInputContainer}>
            <TextInput
              autoFocus
              formNoValidate
              ref={passwordRef}
              name="password"
              id="password-field"
              data-testid="password-field"
              className={styles.passwordInput}
              type={isPasswordVisible ? 'text' : 'password'}
              value={password ?? ''}
              aria-describedby="password-helper-text"
              aria-errormessage="password-error"
              aria-invalid={isPasswordInvalid}
              isInvalid={isPasswordInvalid}
              minLength={1}
              // Apply tab-index="-1" only if the password is hidden
              {...(isPasswordFieldHidden && { tabIndex: -1 })}
              // Do NOT disable this input if isPasswordFieldHidden is true.
              // 1Password won't work with disabled fields (though, Dropbox Passwords will).
              // disabled={isLoading || isPasswordFieldHidden}
              disabled={isLoading}
              withRightAccessory={
                <IconButton
                  type="button"
                  size="small"
                  data-testid={
                    isPasswordVisible
                      ? 'hide-password-btn'
                      : 'show-password-btn'
                  }
                  title={intl.formatMessage(
                    isPasswordVisible
                      ? messages.hidePassword
                      : messages.showPassword,
                  )}
                  variant="transparent"
                  onClick={togglePasswordVisible}
                  // Apply tab-index="-1" only if the password is hidden
                  {...(isPasswordFieldHidden && { tabIndex: -1 })}
                >
                  <UIIcon
                    src={isPasswordVisible ? HideLine : ShowLine}
                    role="presentation"
                  />
                </IconButton>
              }
              onBlur={(evt: React.SyntheticEvent<HTMLInputElement>) => {
                // Cache caret position so that we select the same range when
                // user toggles password visibility.
                const { selectionStart, selectionEnd } = evt.currentTarget;
                passwordSelectionRangeRef.current = [
                  selectionStart,
                  selectionEnd,
                ];
              }}
              onChange={(e) => {
                setIsPasswordInvalid(false);
                update('error', undefined);
                update('password', e.target.value);

                // A password manager like 1Password might be
                // autofilling this form for the user, even
                // though it's not visible. If we notice this
                // form is being auto-filled, perform a quick
                // SAML login check in order to make the password
                // field visible, or redirect to the user's SAML
                // provider automatically, if applicable.
                //
                // We also need to make sure the length >0 because
                // when the user clicks the "modify email" button,
                // we reset the password back to "". This might
                // trigger a submit if we don't check the length.
                if (
                  !dropboxManagedAndSamlLoginChecked &&
                  e.target.value.length > 0
                ) {
                  submitForm();
                }
              }}
            />
          </div>
          <FormHelperText
            id="password-helper-text"
            variant={isPasswordInvalid ? 'alert' : 'default'}
          >
            {isPasswordInvalid && (
              <span id="password-error" className={styles.passwordError}>
                <UIIcon
                  src={FailLine}
                  size="small"
                  aria-label={intl.formatMessage(messages.error)}
                />
                <FormattedMessage
                  id=""
                  description="Error message on the sign up page"
                  defaultMessage="Please enter a valid password."
                />
                &nbsp;
              </span>
            )}
            <Link
              href="/account/recoverPassword"
              data-testid="forgot-password-link"
              // hasNoUnderline={true} I cant set this  but design is like that
              // Apply tab-index="-1" only if the password is hidden
              {...(isPasswordFieldHidden && { tabIndex: -1 })}
              onClick={() => {
                // Save email to sessionStorage for prefill on recover password page
                // (Only if email is not null, since sessionStorage casts that to "null")
                if (email) {
                  try {
                    sessionStorage.setItem('email', email);
                  } catch (err) {
                    // User may be using private browsing.
                    // Fail silently.
                  }
                }
              }}
            >
              <Text size="xsmall" color="inherit">
                <FormattedMessage
                  id=""
                  description="Link to forgot password underneath password text field"
                  defaultMessage="Forgot your password?"
                />
              </Text>
            </Link>
          </FormHelperText>
        </FormRow>
      </div>

      {getSubmitContinueButton}

      <Snackbar.Position>
        <Snackbar
          open={showDMASnackbar}
          timeout={0}
          role="alert"
          preferComposition
        >
          <Snackbar.Content>
            <Snackbar.Message>
              <FormattedMessage
                id=""
                description="Notification message for existing Dropbox account to login on Dropbox"
                defaultMessage="Looks like there’s already a Dropbox account associated with this email."
              />
            </Snackbar.Message>
            <Snackbar.Actions>
              <Button
                variant="transparent"
                href={getDropboxManagedLoginLink}
                inverse
              >
                <FormattedMessage
                  id=""
                  description="Text for a link to redirect to Dropbox login page"
                  defaultMessage="Continue with Dropbox"
                />
              </Button>
            </Snackbar.Actions>
          </Snackbar.Content>
        </Snackbar>
      </Snackbar.Position>
    </form>
  );
}
