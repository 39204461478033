/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  guard-for-in,
  import/no-extraneous-dependencies,
  max-len,
  no-plusplus,
  no-restricted-syntax,
  one-var,
  prefer-const,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import Button from 'hellospa/foundation/control/button';
import { Text } from '@dropbox/dig-components/typography';
import { getBrandName } from 'js/sign-components/common/brand';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import PaymentModal from './payment-modal';
import Constants from './constants';
import EnterpriseAgreementPricingSummary from './enterprise-agreement-pricing-summary';

const messages = defineMessages({
  headerTitle: {
    id: '',
    description:
      'pricing page, enterprise agreement, header, upper case, indicants start of details section for enterprise plan.',
    defaultMessage: 'Plan Details',
  },
  featuresTitle: {
    id: '',
    description:
      'pricing page, enterprise agreement, header, upper case, list of features included in the plan',
    defaultMessage: 'Features included in your plan',
  },
  quoteExpirationDate: {
    id: '',
    description:
      'pricing page, enterprise agreement, footer, Date when quote expires',
    defaultMessage: 'Quote valid through {quoteExpirationDate, date, long}',
  },
});

const EnterpriseAgreement = createReactClass({
  propTypes: {
    isNewHsPlan: PropTypes.bool,
    fullName: PropTypes.string, // Full name of the person agreeing
    email: PropTypes.string.isRequired, // Their email address
    companyName: PropTypes.string, // Their company name
    billingName: PropTypes.string, // Optional billing contact name, if different
    billingEmail: PropTypes.string, // Optional billing contact email, if different
    currentNumSeats: PropTypes.number.isRequired, // Current number of seats on the plan
    numSeats: PropTypes.number.isRequired, // Number of seats on the plan
    formattedTotalRate: PropTypes.string.isRequired, // Total rate (formatted)
    formattedDiscountedTotalRate: PropTypes.string, // Formatted dicounted total rate
    periodName: PropTypes.string.isRequired, // Billing period (month or year)
    features: PropTypes.array.isRequired, // List of features { name, [value]}
    paymentForm: PropTypes.object.isRequired, // Serialized payment form
    paymentInfo: PropTypes.object.isRequired, // Payment information { card, subscription, ... }
    siteCode: PropTypes.string.isRequired, // Site code
    isApi: PropTypes.bool.isRequired, // Whether it's for an API plan
    termsAccepted: PropTypes.bool, // If they've already accepted the terms
    numApiSigRequests: PropTypes.number, // Number of API signat ure requests
    apiQuotaPeriodName: PropTypes.string, // API quota refresh period (month or year)
    couponInfo: PropTypes.object, // API coupon information { percentage, duration }
    isFreeTrial: PropTypes.bool, // Whether to give the user a free trial
    numFreeTrialDays: PropTypes.number,
    billingType: PropTypes.string,
    billingMethod: PropTypes.string,
    tosUrl: PropTypes.string,
    refundPolicyUrl: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,
    skipCreditCardFlow: PropTypes.bool, // Should skip credit card modal
    quoteTimeTillExpire: PropTypes.number,
    legalRequirementLinkHref: PropTypes.string,
    cancelLinkHref: PropTypes.string,
  },

  getDefaultProps() {
    return {
      termsAccepted: false,
    };
  },

  getInitialState() {
    return {
      isModalOpen: false,
    };
  },

  continueClicked(evt) {
    const { paymentInfo, isNewHsPlan } = this.props;

    if (this.isAlternateBilling() || this.skipCreditCardFlow()) {
      this.submitForm();
    } else if (isNewHsPlan) {
      window.location.href = `/info/payment?s=${paymentInfo.quoteId}`;
    } else {
      this.launchPaymentModal(evt);
    }
  },

  isAlternateBilling() {
    return (
      Constants.ALTERNATIVE_BILLING_TYPES.filter(
        (type) => type === this.props.billingType,
      ).length > 0
    );
  },

  skipCreditCardFlow() {
    return this.props.skipCreditCardFlow;
  },

  launchPaymentModal(evt) {
    evt.preventDefault();
    this.setState({ isModalOpen: true });
  },
  closePaymentModal() {
    this.setState({ isModalOpen: false });
  },
  submitForm() {
    $('#agreementForm').submit();
  },

  render() {
    const { intl } = this.props;
    const features = [];
    const name = this.props.billingName || this.props.fullName;
    let quoteExpirationDate = new Date();
    quoteExpirationDate.setSeconds(
      quoteExpirationDate.getSeconds() + this.props.quoteTimeTillExpire,
    );

    // Features
    let feature;

    for (const k in this.props.features) {
      feature = this.props.features[k];
      if (feature.enabled === undefined || feature.enabled === true) {
        const featureValue =
          feature.value && typeof feature.value === 'string'
            ? feature.value.charAt(0).toUpperCase() + feature.value.slice(1)
            : feature.value;
        features.push(
          <div
            key={`feature-${feature.name}`}
            className="m-plan-agreement--features--table--item"
          >
            <svg
              width="15"
              height="13"
              viewBox="0 0 15 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* checkbox icon */}
              <path
                d="M1.59399 7.27868L7.65625 10.7787L13.1563 1.25247"
                stroke="var(--dig-color__primary__base)"
                strokeWidth="3"
              />
            </svg>
            {featureValue} {feature.name}
          </div>,
        );
      }
    }

    const { paymentInfo, couponInfo } = this.props;
    const { card, subscription } = paymentInfo;

    let cardholder = '';
    if (card && card.holderName !== null) {
      cardholder = card.holderName;
    } else if (
      this.props.billingName &&
      this.props.billingEmail !== this.props.email
    ) {
      cardholder = this.props.billingName;
    }
    const { formattedTotalRate } = this.props;
    return (
      <div className="m-plan-agreement">
        <div className="m-plan-agreement--header">
          <div className="m-plan-agreement--header--plan-details">
            <div className="m-plan-agreement--header--title">
              {intl.formatMessage(messages.headerTitle)}
            </div>
            <div className="m-plan-agreement--header--plan-name">
              {this.props.planName}
            </div>
          </div>
          <div className="m-plan-agreement--header--user-details">
            <div className="m-plan-agreement--header--name-company">
              {name} {name && this.props.companyName ? '-' : ''}{' '}
              {this.props.companyName}
            </div>
            <div className="m-plan-agreement--header--email">
              {this.props.billingEmail || this.props.email}
            </div>
          </div>
        </div>
        <EnterpriseAgreementPricingSummary
          couponInfo={this.props.couponInfo}
          formattedTotalRate={this.props.formattedTotalRate}
          formattedDiscountedTotalRate={this.props.formattedDiscountedTotalRate}
          periodName={this.props.periodName}
          isFreeTrial={this.props.isFreeTrial}
          numFreeTrialDays={this.props.numFreeTrialDays}
          noCcFreeTrial={this.props.skipCreditCardFlow}
        />

        <div className="m-plan-agreement--features">
          <div className="m-plan-agreement--features--title">
            {intl.formatMessage(messages.featuresTitle)}
          </div>
          <div className="m-plan-agreement--features--table">{features}</div>
        </div>
        <Text size="xsmall" tagName="p" className="m-legal-description">
          <FormattedMessage
            id=""
            description="Text with a link offering the user to learn more about team settings and permissions."
            defaultMessage="*<a>Learn how to manage {brandNameSign} team settings</a>"
            values={{
              a: (...chunks) => (
                <a
                  href={HfReactHelper.getFaqUrl(
                    'articles/360010125531-Team-Members-and-Role-Permissions',
                    'S',
                  )}
                  data-qa-ref="sign-settings-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {chunks}
                </a>
              ),
              brandNameSign: getBrandName('S'),
            }}
          />
        </Text>
        <div className="m-plan-agreement--cta">
          <div className="m-plan-agreement--cta-quote-expiration-date">
            {intl.formatMessage(messages.quoteExpirationDate, {
              quoteExpirationDate,
            })}
          </div>
          <Button
            data-testid="plan-agreement-continue-btn"
            marginless
            onClick={this.continueClicked}
          >
            <FormattedMessage
              id=""
              description="pricing page, enterprise agreement, button, when clicked agreement gets submitted and user agrees to terms and usage"
              defaultMessage="Continue"
            />
          </Button>
        </div>
        <form id="agreementForm" method="POST" action="" />
        <PaymentModal
          key={`paymentModal_${paymentInfo.planId}`}
          planId={paymentInfo.planId}
          planRawName={this.props.planRawName}
          planName={this.props.planName}
          isFreeTrialEligible={this.props.isFreeTrial}
          forceFreeTrial={this.props.isFreeTrial}
          numFreeTrialDays={this.props.numFreeTrialDays}
          price={paymentInfo.price}
          timePeriod={subscription.timePeriod}
          formattedMonthlyPrice={
            subscription.timePeriod === 'M' ? formattedTotalRate : null
          }
          formattedAnnualPrice={
            subscription.timePeriod === 'Y' ? formattedTotalRate : null
          }
          monthlyExpectedRate={subscription.monthlyExpectedRate}
          annualExpectedRate={subscription.annualExpectedRate}
          formattedMonthlyExpectedRate={
            subscription.formattedMonthlyExpectedRate
          }
          formattedAnnualExpectedRate={subscription.formattedAnnualExpectedRate}
          monthlyProratedRate={subscription.monthlyProratedRate}
          annualProratedRate={subscription.annualProratedRate}
          formattedMonthlyProratedRate={
            subscription.formattedMonthlyProratedRate
          }
          formattedAnnualProratedRate={subscription.formattedAnnualProratedRate}
          currentMonthlyRate={subscription.currentMonthlyRate}
          currentAnnualRate={subscription.currentAnnualRate}
          formattedUpgradeCharge={subscription.formattedUpgradeCharge}
          proratio={subscription.proratio}
          couponCode={subscription.couponCode}
          couponNumMonths={couponInfo ? couponInfo.duration : 0}
          ccSuffix={card ? card.suffix : ''}
          ccExpiration={card ? card.expiration : ''}
          cardHolderName={cardholder}
          name={paymentInfo.name}
          isTrialing={paymentInfo.isTrialing || false}
          isApi={this.props.isApi}
          isEnterprise={true}
          paymentForm={this.props.paymentForm}
          numSeats={this.props.numSeats}
          headerText="Enterprise Plan"
          tosUrl={this.props.tosUrl}
          refundPolicyUrl={this.props.refundPolicyUrl}
          isModalOpen={this.state.isModalOpen}
          closeModal={this.closePaymentModal}
          legalRequirementLinkHref={this.props.legalRequirementLinkHref}
          cancelLinkHref={this.props.cancelLinkHref}
          privacyPolicyUrl={this.props.privacyPolicyUrl}
        />
      </div>
    );
  },
});

module.exports = injectIntl(EnterpriseAgreement);
