import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RadioButton } from '@dropbox/dig-components/controls';
import { Text } from '@dropbox/dig-components/typography';
import { ReasonProps, values } from './types';
import styles from '../survey.module.scss';

function CancelReasonCost(props: ReasonProps) {
  return (
    <div className={styles.reason}>
      <div className={styles.reasonGroup}>
        <RadioButton
          {...props}
          className={styles.reasonButton}
          data-qa-ref="cancellation-reason-cost"
          id="cancel-subscription-survey__radio-cost"
          name="reason"
          value={values.COST}
        />
        <Text tagName="label" htmlFor="cancel-subscription-survey__radio-cost">
          <FormattedMessage
            id=""
            description="An option the user can select when explaining why s/he is canceling their subscription."
            defaultMessage="I want to save money"
          />
        </Text>
      </div>
    </div>
  );
}

export default CancelReasonCost;
