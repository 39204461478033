import React from 'react';

import actions from '../actions/app';

export const AppContext = React.createContext();

export class AppContextProvider extends React.PureComponent {
  state = {
    isSampleDocument: false,
    isGettingSampleDocument: false,
    hasUsedSampleDocument: false,
    hasLaunchedSampleDocument: false,
    getSampleDocumentErrorMsg: null,
    showAdvancedOptions: false,
    csrfToken: '',
    getSampleURL: '',
    cultures: [],
    form: {
      signURL: '',
      clientID: '',
      redirectURL: '',
      requestingEmail: '',
      locale: 'en_US',
      timeout: 30000,
      whiteLabelingCode: '',
      allowCancel: true,
      testMode: true,
      hideHeader: false,
      embedInPage: false,
    },
  };

  actions = actions({
    getState: () => this.state,
    setState: this.setState.bind(this),
  });

  componentDidMount() {
    this.loadFormState();
    this.loadAdvancedOptionsState();
  }

  componentDidUpdate() {
    this.saveFormState();
    this.saveAdvancedOptionsState();
  }

  loadFormState() {
    const str = window.localStorage.getItem('testEmbeddedFormState');

    if (str) {
      const obj = JSON.parse(str);

      this.actions.updateFormOptions(obj);
    }
  }

  loadAdvancedOptionsState() {
    const str = window.localStorage.getItem('testEmbeddedShowAdvanced');

    if (str) {
      const bool = JSON.parse(str);

      this.actions.toggleAdvancedOptions(bool);
    }
  }

  saveFormState() {
    const obj = { ...this.state.form };

    // We don't want to save these.
    if (this.state.isSampleDocument) {
      obj.signURL = '';
      obj.clientID = '';
    }

    window.localStorage.setItem('testEmbeddedFormState', JSON.stringify(obj));
  }

  saveAdvancedOptionsState() {
    const bool = this.state.showAdvancedOptions;

    window.localStorage.setItem(
      'testEmbeddedShowAdvanced',
      JSON.stringify(bool),
    );
  }

  render() {
    return (
      <AppContext.Provider value={{ actions: this.actions, state: this.state }}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

/* eslint-disable-next-line react/display-name */
export const withAppContext = (Component) => (props) => (
  <AppContext.Consumer>
    {(value) => <Component appContext={value} {...props} />}
  </AppContext.Consumer>
);

/* eslint-disable-next-line react/display-name */
export const withAppContextProvider = (Component) => (props) => (
  <AppContextProvider>
    <Component {...props} />
  </AppContextProvider>
);
