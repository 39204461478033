import React from 'react';
import { Split, Stack, Box } from '@dropbox/dig-foundations';
import { Text } from '@dropbox/dig-components/typography';
import { Tooltip } from '@dropbox/dig-components/tooltips';
import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { CopyLine } from '@dropbox/dig-icons/assets';

/**
 * Generic section component for API settings
 */
export function ApiSettingsSection(props: {
  title: React.ReactNode;
  children?: React.ReactNode;
  description?: React.ReactNode;
  action?: React.ReactNode;
}) {
  const { title, description, children, action } = props;

  return (
    <Stack gap="Micro XLarge">
      {/* Heading */}
      <Split gap="Micro Medium">
        <Split.Item marginRight="auto">
          <Stack gap="Micro Small">
            {/**
             * TODO: This should probably be a `Title`. Waiting until we can
             * refactor all of the sections to use this component to make
             * that change
             */}
            <Text variant="label" size="large" isBold>
              {title}
            </Text>
            <Text tagName="div">{description}</Text>
          </Stack>
        </Split.Item>
        <Split.Item>{action}</Split.Item>
      </Split>

      {/* Content */}
      {children ? <div>{children}</div> : null}
    </Stack>
  );
}

/**
 * A horizontal line that separates sections in the API settings page.
 */
export function SectionSeparator() {
  return <Box role="separator" borderTop="Solid" borderColor="Border Subtle" />;
}

export function CopyButton(props: {
  value: string;
  onCopy: () => void;
  onCopyFail: () => void;
  tooltip: React.ReactNode;
  'aria-label': string;
}) {
  const { onCopy, onCopyFail, value, tooltip, 'aria-label': ariaLabel } = props;

  async function copy() {
    try {
      await navigator.clipboard.writeText(value);
      onCopy();
    } catch (error) {
      onCopyFail();
    }
  }

  return (
    <Tooltip title={tooltip}>
      <IconButton
        variant="transparent"
        size="small"
        onClick={copy}
        aria-label={ariaLabel}
        // There's a random form on this page, so without the `type='button'`
        // attribute, the button will be considered a "submit" button and
        // will submit that form on click.
        type="button"
      >
        <UIIcon src={CopyLine} role="presentation" />
      </IconButton>
    </Tooltip>
  );
}

/**
 * This is a hook that returns `true` after a certain amount of time has
 * passed. It can be used to delay rendering of a loading spinner to avoid
 * them flashing in and out quickly.
 */
export function useDelay(ms = 200) {
  const [isPastDelay, setIsPastDelay] = React.useState(ms === 0);
  React.useEffect(() => {
    const timeout = setTimeout(() => setIsPastDelay(true), ms);

    return () => clearTimeout(timeout);
  }, [ms]);

  return isPastDelay;
}
