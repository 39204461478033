/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len,
  react/no-find-dom-node,
  react/no-string-refs,
  react/prop-types
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const FadeOut = createReactClass({
  propTypes: {
    key: PropTypes.string, // NOTE: If another React component is leveraging this component, include a unique key for each generated message. See MultiSelect for more info.
  },

  componentDidMount() {
    this.mountAndFadeOut();
    this.isMounted = true;
  },

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(this));
    this.isMounted = false;
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.key !== nextProps.key) {
      this.mountAndFadeOut();
    }
  },

  mountAndFadeOut() {
    ReactDOM.render(<span>{this.props.children}</span>, this.refs.contents);
    this.fadeOut();
  },

  fadeOut() {
    $(this.refs.contents).fadeOut(5000, () => {
      if (this.isMounted) {
        ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(this));
      }
    });
  },

  render() {
    return <span style={{ display: 'inline-block' }} ref="contents" />;
  },
});
module.exports = FadeOut;
