import React from 'react';
import classnames from 'classnames';
import styles from './icons.scss';

const SlackLogo = (props) => (
  <svg
    {...props}
    viewBox="0 0 200 25"
    className={classnames(styles.icon, props.className)}
  >
    <g
      id="fn-slack"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="Rectangle" x="0" y="0" width="74" height="30"></rect>
      <g
        id="5cfac510a92867f18b22cc8d_slack-black"
        transform="translate(0.000000, 6.000000)"
        fill="#222222"
        fillRule="nonzero"
      >
        <path
          d="M23.2483,14.2359 L24.1486,12.1729 C25.1353,12.8847 26.418,13.2587 27.7006,13.2587 C28.638,13.2587 29.2423,12.8968 29.2423,12.3539 C29.2176,10.8458 23.569,12.0161 23.5196,8.2158 C23.495,6.2855 25.2586,4.78952 27.7376,4.78952 C29.2176,4.78952 30.6976,5.15145 31.746,5.95976 L30.9073,8.05896 C29.9453,7.45574 28.749,7.03349 27.6143,7.03349 C26.8373,7.03349 26.3316,7.39542 26.3316,7.85386 C26.3563,9.34984 32.0543,8.52947 32.116,12.1608 C32.116,14.1394 30.4016,15.5147 27.9596,15.5147 C26.159,15.5389 24.5063,15.1166 23.2483,14.2359 Z M59.0766,11.437 C58.608,12.2453 57.7446,12.7399 56.8073,12.7399 C55.3643,12.7399 54.1926,11.5938 54.1926,10.1823 C54.1926,8.77076 55.3643,7.62464 56.8073,7.62464 C57.7446,7.62464 58.6203,8.11928 59.0766,8.92759 L61.5803,7.56432 C60.643,5.92357 58.8546,4.81365 56.8073,4.81365 C53.7856,4.81365 51.3313,7.21445 51.3313,10.1823 C51.3313,13.1501 53.7856,15.5509 56.8073,15.5509 C58.8546,15.5509 60.643,14.441 61.5803,12.8002 L59.0766,11.437 Z M33.744,15.3217 L36.8766,15.3217 L36.8766,0.337778 L33.744,0.337778 L33.744,15.3217 Z M63.159,0.337778 L63.159,15.3217 L66.2916,15.3217 L66.2916,10.8338 L70.004,15.3217 L74,15.3217 L69.2886,9.98925 L73.6546,5.00668 L69.8313,5.00668 L66.2916,9.14475 L66.2916,0.325714 L63.159,0.337778 Z M46.509,5.01874 L46.509,6.23724 C45.991,5.39274 44.733,4.81365 43.401,4.81365 C40.663,4.81365 38.4923,7.19033 38.4923,10.1702 C38.4923,13.1501 40.6506,15.5509 43.401,15.5509 C44.733,15.5509 45.991,14.9598 46.509,14.1273 L46.509,15.3458 L49.6416,15.3458 L49.6416,5.03081 L46.509,5.03081 L46.509,5.01874 Z M46.509,11.449 C46.065,12.185 45.1153,12.7279 44.067,12.7279 C42.624,12.7279 41.4523,11.5817 41.4523,10.1702 C41.4523,8.75869 42.624,7.61258 44.067,7.61258 C45.1153,7.61258 46.065,8.1796 46.509,8.93966 L46.509,11.449 Z"
          id="Shape"
        ></path>
        <path
          d="M6.72167,0.0120544 C5.71033,0.0120544 4.884,0.820365 4.884,1.80964 C4.884,2.79892 5.71033,3.60723 6.72167,3.60723 L8.55933,3.60723 L8.55933,1.80964 C8.55933,0.820365 7.74533,0.0120544 6.72167,0.0120544 Z M6.72167,4.8016 L1.83767,4.8016 C0.826333,4.8016 0,5.60991 0,6.59919 C0,7.58846 0.826333,8.39677 1.83767,8.39677 L6.734,8.39677 C7.74533,8.39677 8.57167,7.58846 8.57167,6.59919 C8.55933,5.60991 7.74533,4.8016 6.72167,4.8016 Z"
          id="Shape"
        ></path>
        <path
          d="M18.352,6.5992 C18.352,5.60992 17.5257,4.80161 16.5143,4.80161 C15.503,4.80161 14.6767,5.60992 14.6767,6.5992 L14.6767,8.39678 L16.5143,8.39678 C17.5257,8.40885 18.352,7.60054 18.352,6.5992 Z M13.4557,6.5992 L13.4557,1.79759 C13.4557,0.808311 12.6293,0 11.618,0 C10.6067,0 9.78033,0.808311 9.78033,1.79759 L9.78033,6.5992 C9.78033,7.58847 10.6067,8.39678 11.618,8.39678 C12.6417,8.40885 13.4557,7.60054 13.4557,6.5992 Z"
          id="Shape"
        ></path>
        <path
          d="M11.618,18 C12.6293,18 13.4557,17.1917 13.4557,16.2024 C13.4557,15.2131 12.6293,14.4048 11.618,14.4048 L9.78033,14.4048 L9.78033,16.2024 C9.78033,17.1917 10.6067,18 11.618,18 Z M11.618,13.1984 L16.5143,13.1984 C17.5257,13.1984 18.352,12.3901 18.352,11.4008 C18.352,10.4115 17.5257,9.60321 16.5143,9.60321 L11.618,9.60321 C10.6067,9.60321 9.78033,10.4115 9.78033,11.4008 C9.78033,12.3901 10.6067,13.1984 11.618,13.1984 Z"
          id="Shape"
        ></path>
        <path
          d="M0,11.4008 C0,12.3901 0.826333,13.1984 1.83767,13.1984 C2.849,13.1984 3.67533,12.3901 3.67533,11.4008 L3.67533,9.60321 L1.83767,9.60321 C0.814,9.60321 0,10.4115 0,11.4008 Z M4.89633,11.4008 L4.89633,16.2024 C4.89633,17.1917 5.72267,18 6.734,18 C7.74533,18 8.57167,17.1917 8.57167,16.2024 L8.57167,11.4008 C8.57167,10.4115 7.74533,9.60321 6.734,9.60321 C5.71033,9.60321 4.89633,10.4115 4.89633,11.4008 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);

export default React.memo(SlackLogo);
