interface Rules {
  prefix: string;
  regex: RegExp;
  examples: string[];
  maxLength: number;
}

type VatRules = {
  [key: string]: Rules;
};

const vatRules: VatRules = {
  AU: /* Australia */ {
    prefix: '',
    regex: /^[0-9]{11,12}$/,
    examples: ['12345678912', '123456789123'],
    maxLength: 12,
  },
  AT: /* Austria */ {
    prefix: 'ATU',
    regex: /^ATU[0-9]{8}$/,
    examples: ['ATU12345678'],
    maxLength: 11,
  },
  BE: /* Belgium */ {
    prefix: 'BE',
    regex: /^BE(0?|1)[0-9]{9}$/,
    examples: ['BE0123456789', 'BE1123456789', 'BE1234567890'],
    maxLength: 12,
  },
  BG: /* Bulgaria */ {
    prefix: 'BG',
    regex: /^BG[0-9]{9,10}$/,
    examples: ['BG123456789', 'BG1234567890'],
    maxLength: 12,
  },
  CA: /* Canada */ {
    prefix: '',
    regex: /^[0-9]{9,10}(RT|TQ)[0-9]{4}$/, // excluding PST regex since it would trigger tax refresh too often
    examples: ['123456789RT0002', '1234567890TQ1234', '123456-7', '1234567'],
    maxLength: 16,
  },
  CL: /* Chile */ {
    prefix: '',
    regex: /^[0-9]{8}[0-9A-Z]$/,
    examples: ['12345678K'],
    maxLength: 9,
  },
  HR: /* Croatia */ {
    prefix: 'HR',
    regex: /^HR[0-9]{11}$/,
    examples: ['HR12345678912'],
    maxLength: 13,
  },
  CY: /* Cyprus */ {
    prefix: 'CY',
    regex: /^CY[0-9]{8}[A-Z]$/,
    examples: ['CY12345678Z'],
    maxLength: 11,
  },
  CZ: /* Czech Republic */ {
    prefix: 'CZ',
    regex: /^CZ[0-9]{8,10}$/,
    examples: ['CZ12345678', 'CZ123456789', 'CZ1234567890'],
    maxLength: 12,
  },
  DK: /* Denmark */ {
    prefix: 'DK',
    regex: /^DK[0-9]{8}$/,
    examples: ['DK12345678'],
    maxLength: 10,
  },
  EE: /* Estonia */ {
    prefix: 'EE',
    regex: /^EE[0-9]{9}$/,
    examples: ['EE123456789'],
    maxLength: 11,
  },
  FI: /* Finland */ {
    prefix: 'FI',
    regex: /^FI[0-9]{8}$/,
    examples: ['FI12345678'],
    maxLength: 10,
  },
  FR: /* France */ {
    prefix: 'FR',
    regex: /^FR[0-9A-Z]{2}[0-9]{9}$/,
    examples: [
      'FR12345678901',
      'FRX1234567890',
      'FR1X123456789',
      'FRXX123456789',
    ],
    maxLength: 13,
  },
  DE: /* Germany */ {
    prefix: 'DE',
    regex: /^DE[0-9]{9}$/,
    examples: ['DE123456789'],
    maxLength: 11,
  },
  GR: /* Greece */ {
    prefix: 'EL',
    regex: /^EL[0-9]{9}$/,
    examples: ['EL123456789'],
    maxLength: 11,
  },
  HU: /* Hungary */ {
    prefix: 'HU',
    regex: /^HU[0-9]{8}$/,
    examples: ['HU12345678'],
    maxLength: 10,
  },
  IS: /* Iceland */ {
    prefix: '',
    regex: /^[0-9]{5,6}$/,
    examples: ['123456', '12345'],
    maxLength: 6,
  },
  IN: /* India */ {
    prefix: '',
    regex: /^[0-9A-Z]{15}$/,
    examples: ['TS1234567891234', '12ABCDE3456FGZH'],
    maxLength: 15,
  },
  ID: /* Indonesia */ {
    prefix: '',
    regex: /^[0-9.]{20}$/,
    examples: ['12.345.678.9-012.345'],
    maxLength: 20,
  },
  IE: /* Ireland */ {
    prefix: 'IE',
    regex: /^IE([0-9][0-9A-Z+*][0-9]{5}[A-Z]{1,2}|[0-9]{7}WI)$/,
    examples: ['IE1234567AB'],
    maxLength: 11,
  },
  IT: /* Italy */ {
    prefix: 'IT',
    regex: /^IT[0-9]{11}$/,
    examples: ['IT12345678901'],
    maxLength: 13,
  },
  LV: /* Latvia */ {
    prefix: 'LV',
    regex: /^LV[0-9]{11}$/,
    examples: ['LV12345678901'],
    maxLength: 13,
  },
  LT: /* Lithuania */ {
    prefix: 'LT',
    regex: /^LT([0-9]{9}|[0-9]{12})$/,
    examples: ['LT123456789', 'LT123456789012'],
    maxLength: 14,
  },
  LU: /* Luxembourg */ {
    prefix: 'LU',
    regex: /^LU[0-9]{8}$/,
    examples: ['LU12345678'],
    maxLength: 10,
  },
  MT: /* Malta */ {
    prefix: 'MT',
    regex: /^MT[0-9]{8}$/,
    examples: ['MT12345678'],
    maxLength: 10,
  },
  NL: /* Netherlands */ {
    prefix: 'NL',
    regex: /^NL[0-9]{9}B[0-9]{2}$/,
    examples: ['NL123456789B01'],
    maxLength: 14,
  },
  NZ: /* New Zealand */ {
    prefix: '',
    regex: /^[0-9]{8,9}$/,
    examples: ['123456789', '12345678'],
    maxLength: 9,
  },
  NO: /* Norway */ {
    prefix: 'NO',
    regex: /^[0-9]{9}(MVA)$/,
    examples: ['123456789MVA'],
    maxLength: 12,
  },
  PL: /* Poland */ {
    prefix: 'PL',
    regex: /^PL[0-9]{10}$/,
    examples: ['PL1234567890'],
    maxLength: 12,
  },
  PT: /* Portugal */ {
    prefix: 'PT',
    regex: /^PT[0-9]{9}$/,
    examples: ['PT123456789'],
    maxLength: 11,
  },
  RO: /* Romania */ {
    prefix: 'RO',
    regex: /^RO[0-9]{2,10}$/,
    examples: ['RO12', 'RO1234567890'],
    maxLength: 12,
  },
  SA: /* Saudi Arabia */ {
    prefix: '',
    regex: /^[0-9]{15}$/,
    examples: ['123456789012345'],
    maxLength: 15,
  },
  SG: /* Singapore */ {
    prefix: '',
    regex: /^[0-9A-Z]{10}$/,
    examples: ['RG12345678', 'M12345678X'],
    maxLength: 10,
  },
  SK: /* Slovakia */ {
    prefix: 'SK',
    regex: /^SK[0-9]{10}$/,
    examples: ['SK1234567890'],
    maxLength: 12,
  },
  SI: /* Slovenia */ {
    prefix: 'SI',
    regex: /^SI[0-9]{8}$/,
    examples: ['SI12345678'],
    maxLength: 10,
  },
  ZA: /* South Africa */ {
    prefix: '',
    regex: /^4[0-9]{9}$/,
    examples: ['4123456789'],
    maxLength: 10,
  },
  KR: /* South Korea */ {
    prefix: '',
    regex: /^[0-9]{10}$/,
    examples: ['1234567890'],
    maxLength: 10,
  },
  ES: /* Spain */ {
    prefix: 'ES',
    regex: /^ES([A-Z][0-9]{7}[0-9A-Z]|[0-9A-Z][0-9]{7}[A-Z])$/,
    examples: ['ESX12345678', 'ES12345678X', 'ESA1234567Z'],
    maxLength: 11,
  },
  SE: /* Sweden */ {
    prefix: 'SE',
    regex: /^SE[0-9]{12}$/,
    examples: ['SE123456789012'],
    maxLength: 14,
  },
  CH: /* Switzerland */ {
    prefix: 'CH',
    regex: /^CHE-?[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\s?(TVA|MWST|IVA)$/,
    examples: ['CHE-123.456.789 MWST', 'CHE123456789TVA', 'CHE123456789IVA'],
    maxLength: 20,
  },
  TW: /* Taiwan */ {
    prefix: '',
    regex: /^[0-9]{8}$/,
    examples: ['12345678'],
    maxLength: 8,
  },
  TH: /* Thailand */ {
    prefix: '',
    regex: /^[0-9]{13}$/,
    examples: ['1234567890123'],
    maxLength: 13,
  },
  TR: /* Turkey */ {
    prefix: '',
    regex: /^[0-9]{10}$/,
    examples: ['1234567890'],
    maxLength: 10,
  },
  UA: /* Ukraine */ {
    prefix: '',
    regex: /^([0-9]{9}|[0-9]{12})$/,
    examples: ['123456789012', '123456789'],
    maxLength: 12,
  },
  GB: /* United Kingdom */ {
    prefix: 'GB',
    regex: /^GB([0-9]{9}|[0-9]{12}|GD[0-9]{3}|HA[0-9]{3})$/,
    examples: ['GB123456789', 'GB123456789012', 'GBGD123', 'GBHA123'],
    maxLength: 14,
  },
  AE: /* United Arab Emirates */ {
    prefix: '',
    regex: /^[0-9]{15}$/,
    examples: ['123456789012345'],
    maxLength: 15,
  },
  MY: /* Malaysia */ {
    prefix: '',
    regex: /^[A-Z]\d{2}-\d{4}-\d{8}$/,
    examples: ['A12-3456-78912345'],
    maxLength: 17,
  },
};

export default vatRules;
