import React from 'react';
import { Modal } from '@dropbox/dig-components/modal';
import { Stack } from '@dropbox/dig-foundations';
import { Link, Text } from '@dropbox/dig-components/typography';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from '@dropbox/dig-components/buttons';
import { strong } from 'hellospa/common/hs-intl-provider';

const messages = defineMessages({
  includesTheFeatures: {
    id: '',
    description:
      'link, settings page, a link to the features page of the API documentation',
    defaultMessage: 'includes the features',
  },

  formLink: {
    id: '',
    description:
      'link, settings page, a link to the contact sales form for API plans',
    defaultMessage: 'form',
  },

  publishModalTitle: {
    id: '',
    description:
      'title, settings page, modal to confirm publishing an app to the public',
    defaultMessage: 'Before you publish...',
  },

  publisModalBullet1: {
    id: '',
    description:
      'bullet point, settings page, modal to confirm publishing an app to the public',
    defaultMessage:
      'Make sure your app is complete and all features work as expected.',
  },

  publishModalBullet2: {
    id: '',
    description:
      'bullet point, settings page, modal to confirm publishing an app to the public',
    defaultMessage:
      'Verify your paid API plan {featuresLink} used in your app.',
  },

  publishModalBullet3: {
    id: '',
    description:
      'bullet point, settings page, modal to confirm publishing an app to the public',
    defaultMessage:
      'Need a plan? Fill out this {formLink} to speak to a member of our sales team.',
  },

  publishModalBullet4: {
    id: '',
    description:
      'bullet point, settings page, modal to confirm publishing an app to the public',
    defaultMessage:
      '<strong>Note</strong>: You can complete the app approval process prior to signing up for a paid plan, but you won’t be able to make production calls until a paid plan is activated.',
  },

  publishAppButton: {
    id: '',
    description:
      'button, settings page, modal to confirm publishing an app to the public',
    defaultMessage: 'Publish app',
  },

  publishCancelButton: {
    id: '',
    description:
      'button, settings page, modal to confirm publishing an app to the public',
    defaultMessage: 'Cancel',
  },
});

export function ConfirmPublishModal(
  props: React.ComponentPropsWithoutRef<typeof Modal> & {
    onConfirm: () => void;
  },
) {
  const intl = useIntl();
  return (
    <Modal
      {...props}
      isCentered
      withCloseButton="Close"
      aria-labelledby="publish-app-title"
    >
      <Modal.Header hasBottomSpacing="title-standard">
        <Modal.Title id="publish-app-title">
          {intl.formatMessage(messages.publishModalTitle)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Stack
          paddingX="Macro Small"
          paddingBottom="Macro Small"
          gap="Micro Large"
          tagName="ul"
          style={{ listStyleType: 'disc' }}
        >
          <li>
            <Text>{intl.formatMessage(messages.publisModalBullet1)}</Text>
          </li>
          <li>
            <Text>
              {intl.formatMessage(messages.publishModalBullet2, {
                featuresLink: (
                  <Link
                    key="featuresLink"
                    href="https://sign.dropbox.com/features/api"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage(messages.includesTheFeatures)}
                  </Link>
                ),
              })}
            </Text>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                <Text>
                  {intl.formatMessage(messages.publishModalBullet3, {
                    formLink: (
                      <Link
                        key="formLink"
                        href="https://sign.dropbox.com/form/api-pricing"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {intl.formatMessage(messages.formLink)}
                      </Link>
                    ),
                  })}
                </Text>
              </li>
              <li>
                <Text>
                  {intl.formatMessage(messages.publishModalBullet4, { strong })}
                </Text>
              </li>
            </ul>
          </li>
        </Stack>
      </Modal.Body>

      <Modal.Footer hasTopBorder>
        <Button variant="opacity" onClick={props.onRequestClose} autoFocus>
          {intl.formatMessage(messages.publishCancelButton)}
        </Button>
        <Button variant="primary" onClick={props.onConfirm}>
          {intl.formatMessage(messages.publishAppButton)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
