/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  react/no-string-refs
*/
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Button from 'common/components/button';

const MailToButton = createReactClass({
  propTypes: {
    email: PropTypes.string,
    subject: PropTypes.string,
    buttonText: PropTypes.string,
  },
  componentDidMount() {
    if (window.location.search.indexOf('showContactUs=1') > -1) {
      this.handleClick();
    }
  },

  toQueryString(searchParams = {}) {
    return Object.keys(searchParams)
      .map((key) => `${key}=${encodeURIComponent(searchParams[key])}`)
      .join('&');
  },

  createMailtoLink(email, headers) {
    let link = `mailto:${email}`;
    if (headers) {
      link += `?${this.toQueryString(headers)}`;
    }
    return link;
  },

  handleClick() {
    const headers = { subject: this.props.subject };
    window.location.href = this.createMailtoLink(this.props.email, headers);
  },
  render() {
    const text =
      this.props.buttonText !== undefined
        ? this.props.buttonText
        : 'Contact Us';
    return (
      <Button
        data-testid="mail-to-btn"
        ref="mailToButton"
        buttonId="mailToButton"
        buttonText={text}
        buttonHoverColor={'cerulean'}
        onClick={this.handleClick}
      ></Button>
    );
  },
});

module.exports = MailToButton;
