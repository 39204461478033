import React from 'react';
import '../checkbox.scss';

const EnabledEmpty = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
    <g
      id="input-/-checkbox-/--"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect
        id="Box"
        stroke="#959CA4"
        fill="#FFFFFF"
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="2"
      ></rect>
    </g>
  </svg>
);

export default React.memo(EnabledEmpty);
