/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  react/no-find-dom-node,
  react/prop-types
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import CreateAccountForm from './create-account-form';

const CreateAccount = createReactClass({
  propTypes: {
    header: PropTypes.string.isRequired,
    googleSignInClientId: PropTypes.string,
    csrfToken: PropTypes.string,
    isFromApiPricing: PropTypes.bool,
    isInline: PropTypes.bool,
    isVertical: PropTypes.bool, // only for inline
    inlineSideText: PropTypes.string,
    tabOffset: PropTypes.number,
    signupForm: PropTypes.object.isRequired, // Serialized sfForm
    signupUrl: PropTypes.string,
    redirectUrl: PropTypes.string,
    recoverPasswordForm: PropTypes.object, // Serialized sfForm
    loginForm: PropTypes.object, // Serialized sfForm
    hideRememberMe: PropTypes.bool,
    hideGoogleOpenId: PropTypes.bool,
    async: PropTypes.bool,
    // URLs displayed to the user as links for Terms/Privacy Policy
    tosUrl: PropTypes.string.isRequired,
    privacyPolicyUrl: PropTypes.string.isRequired,
    countryCodeFromIp: PropTypes.string,
    marketingOptInOutEnabled: PropTypes.bool, // TODO: Clean up for DEV-13156
    arkoseSignUpPublicKey: PropTypes.string.isRequired,
    arkoseSignUpEnabled: PropTypes.bool.isRequired,
    isDirectPurchase: PropTypes.bool.isRequired,
    googleAnalyticsTrackingVariant: PropTypes.string,
  },

  getDefaultProps() {
    return {
      signupUrl: '/account/signUp',
    };
  },

  getInitialState() {
    return {
      password: '',
      emailAddress: '',
      isProcessing: false,
      isInline: false,
      isVertical: false,
    };
  },

  componentDidMount() {
    if (!this.props.isInline && NODE_ENV !== 'test') {
      $(document).foundation();
      $(ReactDOM.findDOMNode(this))
        .closest('.reveal-modal')
        .foundation('reveal', 'open');
    }
  },

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isInline && NODE_ENV !== 'test') {
      $(ReactDOM.findDOMNode(this))
        .closest('.reveal-modal')
        .foundation('reveal', 'open');
    }
  },

  render() {
    return (
      <div>
        <CreateAccountForm
          header={this.props.header}
          tosUrl={this.props.tosUrl}
          privacyPolicyUrl={this.props.privacyPolicyUrl}
          buttonText={this.props.buttonText}
          isInline={this.props.isInline}
          isVertical={this.props.isVertical}
          inlineSideText={this.props.inlineSideText}
          csrfToken={this.props.csrfToken}
          googleSignInClientId={this.props.googleSignInClientId}
          redirectUrl={this.props.redirectUrl}
          isFromApiPricing={this.props.isFromApiPricing}
          planId={this.props.planId}
          isApi={this.props.isApi}
          planName={this.props.planName}
          skipCC={this.props.skipCC}
          period={this.props.period}
          trialPeriod={this.props.trialPeriod}
          tabOffset={this.props.tabOffset}
          fields={this.props.signupForm.fields}
          values={this.props.signupForm.values}
          namespace={this.props.signupForm.namespace}
          action={this.props.signupUrl}
          recoverPasswordForm={this.props.recoverPasswordForm}
          loginForm={this.props.loginForm}
          hideRememberMe={this.props.hideRememberMe}
          hideGoogleOpenId={this.props.hideGoogleOpenId}
          countryCodeFromIp={this.props.countryCodeFromIp}
          async={this.props.async}
          marketingOptInOutEnabled={this.props.marketingOptInOutEnabled}
          arkoseSignUpEnabled={this.props.arkoseSignUpEnabled}
          arkoseSignUpPublicKey={this.props.arkoseSignUpPublicKey}
          googleAnalyticsTrackingVariant={
            this.props.googleAnalyticsTrackingVariant
          }
          isDirectPurchase={this.props.isDirectPurchase}
        />
      </div>
    );
  },
});

module.exports = CreateAccount;
