import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import classNames from 'classnames';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import HSIntlProvider from 'hellospa/common/hs-intl-provider';
import SignUpButton from './signup-button';
import TopBarMenuLink from './marketing-nav-bar/top-bar-menu-link';
import Constants from './marketing-nav-bar/top-bar-data';
import MobileNavBar from './marketing-nav-bar/mobile-nav-bar';

const messages = defineMessages({
  signUp: {
    id: '',
    description:
      'link text in error page, when clicked redirects to sign up page',
    defaultMessage: 'Sign Up',
  },
});

const TopBarMenu = createReactClass({
  propTypes: {
    topBarMenuLinkData: PropTypes.array.isRequired,
    dropDownMenuLinkData: PropTypes.array.isRequired,
    pageToHighLight: PropTypes.string,
    useWhite: PropTypes.bool,
    useBlack: PropTypes.bool,
    isCompact: PropTypes.bool,
    isLogInButtonVisible: PropTypes.bool,
    isSignUpButtonVisible: PropTypes.bool,
    googleSignInClientId: PropTypes.string,
    loginEmailAddress: PropTypes.string,
    loginDropDownMenuLinkData: PropTypes.array,
    signupTargetUrl: PropTypes.string,
    useRegularLink: PropTypes.bool,
    loginHeaderText: PropTypes.string,
    hideDropDownMenu: PropTypes.bool,
    recoverPasswordForm: PropTypes.object.isRequired, // Serialized sfForm
    loginForm: PropTypes.object.isRequired, // Serialized sfForm
    signupForm: PropTypes.object.isRequired, // Serialized sfForm
    intl: PropTypes.object,
  },

  getInitialState() {
    return {
      menuOpen: false,
    };
  },

  componentDidMount() {
    const container = $('.google-collapse');
    $(document).click((e) => {
      if (container.html() !== $(e.target).parent().parent().html()) {
        if (this.state.menuOpen) {
          this.setState({
            menuOpen: false,
          });
        }
      }
    });

    if (this.isMobileScreen()) {
      return this.renderMobile();
    }
  },

  isMobileScreen() {
    return window.innerWidth < Constants.topBarScreenThreshhold;
  },

  renderMobile() {
    const modalPortalHack = ReactDOM.createPortal(
      <HSIntlProvider>
        <MobileNavBar
          loginDropDownMenuLinkData={this.props.loginDropDownMenuLinkData}
        />
      </HSIntlProvider>,
      document.getElementById('mobile-nav-bar-id'),
    );
    this.setState({ modalPortalHack });
  },

  render() {
    if (!this.isMobileScreen()) {
      return this.renderDesktop();
    }

    const mobileMenuIconClasses = {
      'm-link-list--menu-link-mobile-icon': true,
      'c-white': this.props.useWhite,
      'c-ashen': !this.props.useWhite,
    };

    return (
      <div
        className={`m-link-list small-9 columns text-center ${!this.props.isLogInButtonVisible && !this.props.isSignUpButtonVisible && this.props.hideDropDownMenu ? 'is-compact' : ''}`}
        data-equalizer-watch
      >
        {this.state?.modalPortalHack}
        <a
          className={`toggle-nav m-link-list--menu-link-mobile${this.props.useWhite ? ' c-white hc-warm-chinchilla' : ' c-castle-rock hc-cerulean'}`}
          tabIndex="-1"
        >
          <span className={classNames(mobileMenuIconClasses)}></span>
        </a>
      </div>
    );
  },

  renderDesktop() {
    const topBarMenuLinks = Constants.topBarData.map((topBarMenuLink, i) => {
      return (
        <TopBarMenuLink
          key={i}
          useWhite={this.props.useWhite}
          linkText={topBarMenuLink.text}
          linkUrl={topBarMenuLink.url}
          linkClassName="nav-bar-dropdown-header"
          pageToHighLight={
            this.props.pageToHighLight ? this.props.pageToHighLight : ''
          }
          dropDownMenuLinkData={topBarMenuLink.options.subMenuItems}
          daAction={topBarMenuLink.daAction}
          daCategory={topBarMenuLink.daCategory}
          isMobile={false}
        />
      );
    });

    let signUpButton;
    if (this.props.isSignUpButtonVisible && this.props.googleSignInClientId) {
      signUpButton = (
        <SignUpButton
          googleSignInClientId={this.props.googleSignInClientId}
          signupUrl={this.props.signupTargetUrl}
          useRegularLink={this.props.useRegularLink}
          signupForm={this.props.signupForm}
          buttonText={this.props.intl.formatMessage(messages.signUp)}
          recoverPasswordForm={this.props.recoverPasswordForm}
          loginForm={this.props.loginForm}
          async={false}
          isNavBarButton={true}
        />
      );
    }

    return (
      <div>
        {this.state?.modalPortalHack}
        <div className="nav-bar-dropdown"> {topBarMenuLinks} </div>
        <div className="nav-bar-buttons">
          {signUpButton}
          <a
            className="nav-bar-button"
            href={Constants.contactUsUrl.url}
            da-category={Constants.contactUsUrl.daCategory}
            da-action={Constants.contactUsUrl.daAction}
            da-label={Constants.contactUsUrl.daLabel}
          >
            <FormattedMessage
              id=""
              description="button text in error page, when clicked reroutes user to conact page"
              defaultMessage="Contact Us"
            />
          </a>
        </div>
      </div>
    );
  },
});

export default injectIntl(TopBarMenu);
