import React from 'react';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Button } from '@dropbox/dig-components/buttons';
import { LayerContext } from '@dropbox/dig-components/layer';
import { Tooltip } from '@dropbox/dig-components/tooltips';
import { FormattedMessage } from 'react-intl';
import styles from './global-nav.scss';
import { useAppContext } from 'hello-react/web-app-client/build-web-app-client';
import { OverlayAnchorRef } from '@dropbox/dig-components/overlay';
import { getBrandName } from 'js/sign-components/common/brand';
import { ActiveTourGate } from 'js/sign-components/tour/useTour';
import { useOnMount } from 'js/sign-components/common/hooks';

type Props = {
  triggerRef: OverlayAnchorRef;
};

function BulkSendTooltipRender({ triggerRef }: Props) {
  const { acknowledgeBulkSendEducation, bulkSendEducationStatus } =
    useAppContext().bulkSend;
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const [getTooltipStep, setTooltipStep] = React.useState(1);

  useOnMount(() => {
    bulkSendEducationStatus().then((response) => {
      if (!response.isAcknowledged) {
        setIsTooltipOpen(true);
      }
    });
  });

  const onTooltipClick = React.useCallback(() => {
    if (getTooltipStep === 1) {
      setTooltipStep(2);
    } else {
      setIsTooltipOpen(false);
      acknowledgeBulkSendEducation();
    }
  }, [acknowledgeBulkSendEducation, getTooltipStep]);

  return (
    <ActiveTourGate
      tourName="bulk-send-tooltip-tour"
      requestActive={isTooltipOpen}
    >
      <LayerContext.Provider value={{ zIndex: 26 }}>
        <Tooltip.Control
          variant="rich"
          open={isTooltipOpen}
          auto={true}
          placement="right"
          triggerRef={triggerRef}
          data-testid="bulk-send-education-tooltip"
          openDelay={500}
        >
          <Title size="medium" inverse>
            {getTooltipStep === 1 && (
              <FormattedMessage
                id=""
                description="Text for bulk send updates tooltip title step one."
                defaultMessage="What's new"
              />
            )}
            {getTooltipStep === 2 && (
              <FormattedMessage
                id=""
                description="Text for bulk send updates tooltip title step two."
                defaultMessage="Fix errors faster"
              />
            )}
          </Title>
          <Text tagName="p" color="standard" inverse>
            {getTooltipStep === 1 && (
              <FormattedMessage
                id=""
                description="Text for bulk send updates tooltip body step one."
                defaultMessage="First, you can now include more than one signer role in bulk requests."
              />
            )}
            {getTooltipStep === 2 && (
              <FormattedMessage
                id=""
                description="Text for bulk send updates tooltip body step two."
                defaultMessage="Second, fix bulk send CSV errors right in {brandName}, without uploading the file over and over."
                values={{
                  brandName: getBrandName('S'),
                }}
              />
            )}
          </Text>
          <div className={styles.bulkSendTooltipSteps}>
            <Text inverse color="faint">
              <FormattedMessage
                id=""
                description="Describes which step the user is on"
                defaultMessage="{currentStep, number} of 2"
                values={{
                  currentStep: getTooltipStep,
                }}
              />
            </Text>
            <Button
              variant="outline"
              inverse
              onClick={onTooltipClick}
              autoFocus
              data-testid="bulk-send-education-tooltip-btn"
            >
              {getTooltipStep === 1 && (
                <FormattedMessage
                  id=""
                  description="Text for bulk send updates tooltip next button that advances to step two tooltip."
                  defaultMessage="Next"
                />
              )}
              {getTooltipStep === 2 && (
                <FormattedMessage
                  id=""
                  description="Text for bulk send updates tooltip got it button that dismisses the tooltip."
                  defaultMessage="Got it"
                />
              )}
            </Button>
          </div>
        </Tooltip.Control>
      </LayerContext.Provider>
    </ActiveTourGate>
  );
}

function BulkSendTooltip({ triggerRef }: Props) {
  const appContext = useAppContext();
  // We don't render the tooltip if appContext is empty
  // because the bulk send tooltip is used by the global nav,
  // and since the global nav is rendered on most pages, there can
  // be a situation wherein app context is not available.
  // e.g. if the page is a legacy PHP page
  if (Object.keys(appContext).length === 0) {
    return null;
  }

  return <BulkSendTooltipRender triggerRef={triggerRef} />;
}

export default BulkSendTooltip;
