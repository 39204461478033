/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs
*/
// Countries according to iso3166
// https://dev.maxmind.com/geoip/legacy/codes/iso3166/

import { defineMessages } from 'react-intl';

const countries = defineMessages({
  AF: {
    id: '',
    description: 'country name, Afghanistan',
    defaultMessage: 'Afghanistan',
  },
  AX: {
    id: '',
    description: 'country name, Aland Islands',
    defaultMessage: 'Aland Islands',
  },
  AL: {
    id: '',
    description: 'country name, Albania',
    defaultMessage: 'Albania',
  },
  DZ: {
    id: '',
    description: 'country name, Algeria',
    defaultMessage: 'Algeria',
  },
  AS: {
    id: '',
    description: 'country name, American Samoa',
    defaultMessage: 'American Samoa',
  },
  AD: {
    id: '',
    description: 'country name, Andorra',
    defaultMessage: 'Andorra',
  },
  AO: {
    id: '',
    description: 'country name, Angola',
    defaultMessage: 'Angola',
  },
  AI: {
    id: '',
    description: 'country name, Anguilla',
    defaultMessage: 'Anguilla',
  },
  AQ: {
    id: '',
    description: 'country name, Antarctica',
    defaultMessage: 'Antarctica',
  },
  AG: {
    id: '',
    description: 'country name, Antigua And Barbuda',
    defaultMessage: 'Antigua And Barbuda',
  },
  AR: {
    id: '',
    description: 'country name, Argentina',
    defaultMessage: 'Argentina',
  },
  AM: {
    id: '',
    description: 'country name, Armenia',
    defaultMessage: 'Armenia',
  },
  AW: {
    id: '',
    description: 'country name, Aruba',
    defaultMessage: 'Aruba',
  },
  AU: {
    id: '',
    description: 'country name, Australia',
    defaultMessage: 'Australia',
  },
  AT: {
    id: '',
    description: 'country name, Austria',
    defaultMessage: 'Austria',
  },
  AZ: {
    id: '',
    description: 'country name, Azerbaijan',
    defaultMessage: 'Azerbaijan',
  },
  BS: {
    id: '',
    description: 'country name, Bahamas',
    defaultMessage: 'Bahamas',
  },
  BH: {
    id: '',
    description: 'country name, Bahrain',
    defaultMessage: 'Bahrain',
  },
  BD: {
    id: '',
    description: 'country name, Bangladesh',
    defaultMessage: 'Bangladesh',
  },
  BB: {
    id: '',
    description: 'country name, Barbados',
    defaultMessage: 'Barbados',
  },
  BY: {
    id: '',
    description: 'country name, Belarus',
    defaultMessage: 'Belarus',
  },
  BE: {
    id: '',
    description: 'country name, Belgium',
    defaultMessage: 'Belgium',
  },
  BZ: {
    id: '',
    description: 'country name, Belize',
    defaultMessage: 'Belize',
  },
  BJ: {
    id: '',
    description: 'country name, Benin',
    defaultMessage: 'Benin',
  },
  BM: {
    id: '',
    description: 'country name, Bermuda',
    defaultMessage: 'Bermuda',
  },
  BT: {
    id: '',
    description: 'country name, Bhutan',
    defaultMessage: 'Bhutan',
  },
  BO: {
    id: '',
    description: 'country name, Bolivia',
    defaultMessage: 'Bolivia',
  },
  BA: {
    id: '',
    description: 'country name, Bosnia And Herzegovina',
    defaultMessage: 'Bosnia And Herzegovina',
  },
  BW: {
    id: '',
    description: 'country name, Botswana',
    defaultMessage: 'Botswana',
  },
  BV: {
    id: '',
    description: 'country name, Bouvet Island',
    defaultMessage: 'Bouvet Island',
  },
  BR: {
    id: '',
    description: 'country name, Brazil',
    defaultMessage: 'Brazil',
  },
  IO: {
    id: '',
    description: 'country name, British Indian Ocean Territory',
    defaultMessage: 'British Indian Ocean Territory',
  },
  BN: {
    id: '',
    description: 'country name, Brunei Darussalam',
    defaultMessage: 'Brunei Darussalam',
  },
  BG: {
    id: '',
    description: 'country name, Bulgaria',
    defaultMessage: 'Bulgaria',
  },
  BF: {
    id: '',
    description: 'country name, Burkina Faso',
    defaultMessage: 'Burkina Faso',
  },
  BI: {
    id: '',
    description: 'country name, Burundi',
    defaultMessage: 'Burundi',
  },
  KH: {
    id: '',
    description: 'country name, Cambodia',
    defaultMessage: 'Cambodia',
  },
  CM: {
    id: '',
    description: 'country name, Cameroon',
    defaultMessage: 'Cameroon',
  },
  CA: {
    id: '',
    description: 'country name, Canada',
    defaultMessage: 'Canada',
  },
  CV: {
    id: '',
    description: 'country name, Cape Verde',
    defaultMessage: 'Cape Verde',
  },
  KY: {
    id: '',
    description: 'country name, Cayman Islands',
    defaultMessage: 'Cayman Islands',
  },
  CF: {
    id: '',
    description: 'country name, Central African Republic',
    defaultMessage: 'Central African Republic',
  },
  TD: {
    id: '',
    description: 'country name, Chad',
    defaultMessage: 'Chad',
  },
  CL: {
    id: '',
    description: 'country name, Chile',
    defaultMessage: 'Chile',
  },
  CN: {
    id: '',
    description: 'country name, China',
    defaultMessage: 'China',
  },
  CX: {
    id: '',
    description: 'country name, Christmas Island',
    defaultMessage: 'Christmas Island',
  },
  CC: {
    id: '',
    description: 'country name, Cocos (Keeling) Islands',
    defaultMessage: 'Cocos (Keeling) Islands',
  },
  CO: {
    id: '',
    description: 'country name, Colombia',
    defaultMessage: 'Colombia',
  },
  KM: {
    id: '',
    description: 'country name, Comoros',
    defaultMessage: 'Comoros',
  },
  CG: {
    id: '',
    description: 'country name, Congo',
    defaultMessage: 'Congo',
  },
  CD: {
    id: '',
    description: 'country name, Congo, Democratic Republic',
    defaultMessage: 'Congo, Democratic Republic',
  },
  CK: {
    id: '',
    description: 'country name, Cook Islands',
    defaultMessage: 'Cook Islands',
  },
  CR: {
    id: '',
    description: 'country name, Costa Rica',
    defaultMessage: 'Costa Rica',
  },
  CI: {
    id: '',
    description: "country name, Cote D'Ivoire",
    defaultMessage: "Cote D'Ivoire",
  },
  HR: {
    id: '',
    description: 'country name, Croatia',
    defaultMessage: 'Croatia',
  },
  CU: {
    id: '',
    description: 'country name, Cuba',
    defaultMessage: 'Cuba',
  },
  CY: {
    id: '',
    description: 'country name, Cyprus',
    defaultMessage: 'Cyprus',
  },
  CZ: {
    id: '',
    description: 'country name, Czech Republic',
    defaultMessage: 'Czech Republic',
  },
  DK: {
    id: '',
    description: 'country name, Denmark',
    defaultMessage: 'Denmark',
  },
  DJ: {
    id: '',
    description: 'country name, Djibouti',
    defaultMessage: 'Djibouti',
  },
  DM: {
    id: '',
    description: 'country name, Dominica',
    defaultMessage: 'Dominica',
  },
  DO: {
    id: '',
    description: 'country name, Dominican Republic',
    defaultMessage: 'Dominican Republic',
  },
  EC: {
    id: '',
    description: 'country name, Ecuador',
    defaultMessage: 'Ecuador',
  },
  EG: {
    id: '',
    description: 'country name, Egypt',
    defaultMessage: 'Egypt',
  },
  SV: {
    id: '',
    description: 'country name, El Salvador',
    defaultMessage: 'El Salvador',
  },
  GQ: {
    id: '',
    description: 'country name, Equatorial Guinea',
    defaultMessage: 'Equatorial Guinea',
  },
  ER: {
    id: '',
    description: 'country name, Eritrea',
    defaultMessage: 'Eritrea',
  },
  EE: {
    id: '',
    description: 'country name, Estonia',
    defaultMessage: 'Estonia',
  },
  ET: {
    id: '',
    description: 'country name, Ethiopia',
    defaultMessage: 'Ethiopia',
  },
  FK: {
    id: '',
    description: 'country name, Falkland Islands (Malvinas)',
    defaultMessage: 'Falkland Islands (Malvinas)',
  },
  FO: {
    id: '',
    description: 'country name, Faroe Islands',
    defaultMessage: 'Faroe Islands',
  },
  FJ: {
    id: '',
    description: 'country name, Fiji',
    defaultMessage: 'Fiji',
  },
  FI: {
    id: '',
    description: 'country name, Finland',
    defaultMessage: 'Finland',
  },
  FR: {
    id: '',
    description: 'country name, France',
    defaultMessage: 'France',
  },
  GF: {
    id: '',
    description: 'country name, French Guiana',
    defaultMessage: 'French Guiana',
  },
  PF: {
    id: '',
    description: 'country name, French Polynesia',
    defaultMessage: 'French Polynesia',
  },
  TF: {
    id: '',
    description: 'country name, French Southern Territories',
    defaultMessage: 'French Southern Territories',
  },
  GA: {
    id: '',
    description: 'country name, Gabon',
    defaultMessage: 'Gabon',
  },
  GM: {
    id: '',
    description: 'country name, Gambia',
    defaultMessage: 'Gambia',
  },
  GE: {
    id: '',
    description: 'country name, Georgia',
    defaultMessage: 'Georgia',
  },
  DE: {
    id: '',
    description: 'country name, Germany',
    defaultMessage: 'Germany',
  },
  GH: {
    id: '',
    description: 'country name, Ghana',
    defaultMessage: 'Ghana',
  },
  GI: {
    id: '',
    description: 'country name, Gibraltar',
    defaultMessage: 'Gibraltar',
  },
  GR: {
    id: '',
    description: 'country name, Greece',
    defaultMessage: 'Greece',
  },
  GL: {
    id: '',
    description: 'country name, Greenland',
    defaultMessage: 'Greenland',
  },
  GD: {
    id: '',
    description: 'country name, Grenada',
    defaultMessage: 'Grenada',
  },
  GP: {
    id: '',
    description: 'country name, Guadeloupe',
    defaultMessage: 'Guadeloupe',
  },
  GU: {
    id: '',
    description: 'country name, Guam',
    defaultMessage: 'Guam',
  },
  GT: {
    id: '',
    description: 'country name, Guatemala',
    defaultMessage: 'Guatemala',
  },
  GG: {
    id: '',
    description: 'country name, Guernsey',
    defaultMessage: 'Guernsey',
  },
  GN: {
    id: '',
    description: 'country name, Guinea',
    defaultMessage: 'Guinea',
  },
  GW: {
    id: '',
    description: 'country name, Guinea-Bissau',
    defaultMessage: 'Guinea-Bissau',
  },
  GY: {
    id: '',
    description: 'country name, Guyana',
    defaultMessage: 'Guyana',
  },
  HT: {
    id: '',
    description: 'country name, Haiti',
    defaultMessage: 'Haiti',
  },
  HM: {
    id: '',
    description: 'country name, Heard Island & Mcdonald Islands',
    defaultMessage: 'Heard Island & Mcdonald Islands',
  },
  VA: {
    id: '',
    description: 'country name, Holy See (Vatican City State)',
    defaultMessage: 'Holy See (Vatican City State)',
  },
  HN: {
    id: '',
    description: 'country name, Honduras',
    defaultMessage: 'Honduras',
  },
  HK: {
    id: '',
    description: 'country name, Hong Kong',
    defaultMessage: 'Hong Kong',
  },
  HU: {
    id: '',
    description: 'country name, Hungary',
    defaultMessage: 'Hungary',
  },
  IS: {
    id: '',
    description: 'country name, Iceland',
    defaultMessage: 'Iceland',
  },
  IN: {
    id: '',
    description: 'country name, India',
    defaultMessage: 'India',
  },
  ID: {
    id: '',
    description: 'country name, Indonesia',
    defaultMessage: 'Indonesia',
  },
  IR: {
    id: '',
    description: 'country name, Iran, Islamic Republic Of',
    defaultMessage: 'Iran, Islamic Republic Of',
  },
  IQ: {
    id: '',
    description: 'country name, Iraq',
    defaultMessage: 'Iraq',
  },
  IE: {
    id: '',
    description: 'country name, Ireland',
    defaultMessage: 'Ireland',
  },
  IM: {
    id: '',
    description: 'country name, Isle Of Man',
    defaultMessage: 'Isle Of Man',
  },
  IL: {
    id: '',
    description: 'country name, Israel',
    defaultMessage: 'Israel',
  },
  IT: {
    id: '',
    description: 'country name, Italy',
    defaultMessage: 'Italy',
  },
  JM: {
    id: '',
    description: 'country name, Jamaica',
    defaultMessage: 'Jamaica',
  },
  JP: {
    id: '',
    description: 'country name, Japan',
    defaultMessage: 'Japan',
  },
  JE: {
    id: '',
    description: 'country name, Jersey',
    defaultMessage: 'Jersey',
  },
  JO: {
    id: '',
    description: 'country name, Jordan',
    defaultMessage: 'Jordan',
  },
  KZ: {
    id: '',
    description: 'country name, Kazakhstan',
    defaultMessage: 'Kazakhstan',
  },
  KE: {
    id: '',
    description: 'country name, Kenya',
    defaultMessage: 'Kenya',
  },
  KI: {
    id: '',
    description: 'country name, Kiribati',
    defaultMessage: 'Kiribati',
  },
  KR: {
    id: '',
    description: 'country name, Korea',
    defaultMessage: 'Korea',
  },
  KW: {
    id: '',
    description: 'country name, Kuwait',
    defaultMessage: 'Kuwait',
  },
  KG: {
    id: '',
    description: 'country name, Kyrgyzstan',
    defaultMessage: 'Kyrgyzstan',
  },
  LA: {
    id: '',
    description: "country name, Lao People's Democratic Republic",
    defaultMessage: "Lao People's Democratic Republic",
  },
  LV: {
    id: '',
    description: 'country name, Latvia',
    defaultMessage: 'Latvia',
  },
  LB: {
    id: '',
    description: 'country name, Lebanon',
    defaultMessage: 'Lebanon',
  },
  LS: {
    id: '',
    description: 'country name, Lesotho',
    defaultMessage: 'Lesotho',
  },
  LR: {
    id: '',
    description: 'country name, Liberia',
    defaultMessage: 'Liberia',
  },
  LY: {
    id: '',
    description: 'country name, Libya',
    defaultMessage: 'Libya',
  },
  LI: {
    id: '',
    description: 'country name, Liechtenstein',
    defaultMessage: 'Liechtenstein',
  },
  LT: {
    id: '',
    description: 'country name, Lithuania',
    defaultMessage: 'Lithuania',
  },
  LU: {
    id: '',
    description: 'country name, Luxembourg',
    defaultMessage: 'Luxembourg',
  },
  MO: {
    id: '',
    description: 'country name, Macao',
    defaultMessage: 'Macao',
  },
  MK: {
    id: '',
    description: 'country name, Macedonia',
    defaultMessage: 'North Macedonia',
  },
  MG: {
    id: '',
    description: 'country name, Madagascar',
    defaultMessage: 'Madagascar',
  },
  MW: {
    id: '',
    description: 'country name, Malawi',
    defaultMessage: 'Malawi',
  },
  MY: {
    id: '',
    description: 'country name, Malaysia',
    defaultMessage: 'Malaysia',
  },
  MV: {
    id: '',
    description: 'country name, Maldives',
    defaultMessage: 'Maldives',
  },
  ML: {
    id: '',
    description: 'country name, Mali',
    defaultMessage: 'Mali',
  },
  MT: {
    id: '',
    description: 'country name, Malta',
    defaultMessage: 'Malta',
  },
  MH: {
    id: '',
    description: 'country name, Marshall Islands',
    defaultMessage: 'Marshall Islands',
  },
  MQ: {
    id: '',
    description: 'country name, Martinique',
    defaultMessage: 'Martinique',
  },
  MR: {
    id: '',
    description: 'country name, Mauritania',
    defaultMessage: 'Mauritania',
  },
  MU: {
    id: '',
    description: 'country name, Mauritius',
    defaultMessage: 'Mauritius',
  },
  YT: {
    id: '',
    description: 'country name, Mayotte',
    defaultMessage: 'Mayotte',
  },
  MX: {
    id: '',
    description: 'country name, Mexico',
    defaultMessage: 'Mexico',
  },
  FM: {
    id: '',
    description: 'country name, Micronesia, Federated States Of',
    defaultMessage: 'Micronesia, Federated States Of',
  },
  MD: {
    id: '',
    description: 'country name, Moldova',
    defaultMessage: 'Moldova',
  },
  MC: {
    id: '',
    description: 'country name, Monaco',
    defaultMessage: 'Monaco',
  },
  MN: {
    id: '',
    description: 'country name, Mongolia',
    defaultMessage: 'Mongolia',
  },
  ME: {
    id: '',
    description: 'country name, Montenegro',
    defaultMessage: 'Montenegro',
  },
  MS: {
    id: '',
    description: 'country name, Montserrat',
    defaultMessage: 'Montserrat',
  },
  MA: {
    id: '',
    description: 'country name, Morocco',
    defaultMessage: 'Morocco',
  },
  MZ: {
    id: '',
    description: 'country name, Mozambique',
    defaultMessage: 'Mozambique',
  },
  MM: {
    id: '',
    description: 'country name, Myanmar',
    defaultMessage: 'Myanmar',
  },
  NA: {
    id: '',
    description: 'country name, Namibia',
    defaultMessage: 'Namibia',
  },
  NR: {
    id: '',
    description: 'country name, Nauru',
    defaultMessage: 'Nauru',
  },
  NP: {
    id: '',
    description: 'country name, Nepal',
    defaultMessage: 'Nepal',
  },
  NL: {
    id: '',
    description: 'country name, Netherlands',
    defaultMessage: 'Netherlands',
  },
  AN: {
    id: '',
    description: 'country name, Netherlands Antilles',
    defaultMessage: 'Netherlands Antilles',
  },
  NC: {
    id: '',
    description: 'country name, New Caledonia',
    defaultMessage: 'New Caledonia',
  },
  NZ: {
    id: '',
    description: 'country name, New Zealand',
    defaultMessage: 'New Zealand',
  },
  NI: {
    id: '',
    description: 'country name, Nicaragua',
    defaultMessage: 'Nicaragua',
  },
  NE: {
    id: '',
    description: 'country name, Niger',
    defaultMessage: 'Niger',
  },
  NG: {
    id: '',
    description: 'country name, Nigeria',
    defaultMessage: 'Nigeria',
  },
  NU: {
    id: '',
    description: 'country name, Niue',
    defaultMessage: 'Niue',
  },
  NF: {
    id: '',
    description: 'country name, Norfolk Island',
    defaultMessage: 'Norfolk Island',
  },
  MP: {
    id: '',
    description: 'country name, Northern Mariana Islands',
    defaultMessage: 'Northern Mariana Islands',
  },
  NO: {
    id: '',
    description: 'country name, Norway',
    defaultMessage: 'Norway',
  },
  OM: {
    id: '',
    description: 'country name, Oman',
    defaultMessage: 'Oman',
  },
  PK: {
    id: '',
    description: 'country name, Pakistan',
    defaultMessage: 'Pakistan',
  },
  PW: {
    id: '',
    description: 'country name, Palau',
    defaultMessage: 'Palau',
  },
  PS: {
    id: '',
    description: 'country name, Palestinian Territory, Occupied',
    defaultMessage: 'Palestinian Territory, Occupied',
  },
  PA: {
    id: '',
    description: 'country name, Panama',
    defaultMessage: 'Panama',
  },
  PG: {
    id: '',
    description: 'country name, Papua New Guinea',
    defaultMessage: 'Papua New Guinea',
  },
  PY: {
    id: '',
    description: 'country name, Paraguay',
    defaultMessage: 'Paraguay',
  },
  PE: {
    id: '',
    description: 'country name, Peru',
    defaultMessage: 'Peru',
  },
  PH: {
    id: '',
    description: 'country name, Philippines',
    defaultMessage: 'Philippines',
  },
  PN: {
    id: '',
    description: 'country name, Pitcairn',
    defaultMessage: 'Pitcairn',
  },
  PL: {
    id: '',
    description: 'country name, Poland',
    defaultMessage: 'Poland',
  },
  PT: {
    id: '',
    description: 'country name, Portugal',
    defaultMessage: 'Portugal',
  },
  PR: {
    id: '',
    description: 'country name, Puerto Rico',
    defaultMessage: 'Puerto Rico',
  },
  QA: {
    id: '',
    description: 'country name, Qatar',
    defaultMessage: 'Qatar',
  },
  RE: {
    id: '',
    description: 'country name, Reunion',
    defaultMessage: 'Reunion',
  },
  RO: {
    id: '',
    description: 'country name, Romania',
    defaultMessage: 'Romania',
  },
  RU: {
    id: '',
    description: 'country name, Russian Federation',
    defaultMessage: 'Russian Federation',
  },
  RW: {
    id: '',
    description: 'country name, Rwanda',
    defaultMessage: 'Rwanda',
  },
  BL: {
    id: '',
    description: 'country name, Saint Barthelemy',
    defaultMessage: 'Saint Barthelemy',
  },
  SH: {
    id: '',
    description: 'country name, Saint Helena',
    defaultMessage: 'Saint Helena',
  },
  KN: {
    id: '',
    description: 'country name, Saint Kitts And Nevis',
    defaultMessage: 'Saint Kitts And Nevis',
  },
  LC: {
    id: '',
    description: 'country name, Saint Lucia',
    defaultMessage: 'Saint Lucia',
  },
  MF: {
    id: '',
    description: 'country name, Saint Martin',
    defaultMessage: 'Saint Martin',
  },
  PM: {
    id: '',
    description: 'country name, Saint Pierre And Miquelon',
    defaultMessage: 'Saint Pierre And Miquelon',
  },
  VC: {
    id: '',
    description: 'country name, Saint Vincent And Grenadines',
    defaultMessage: 'Saint Vincent And Grenadines',
  },
  WS: {
    id: '',
    description: 'country name, Samoa',
    defaultMessage: 'Samoa',
  },
  SM: {
    id: '',
    description: 'country name, San Marino',
    defaultMessage: 'San Marino',
  },
  ST: {
    id: '',
    description: 'country name, Sao Tome And Principe',
    defaultMessage: 'Sao Tome And Principe',
  },
  SA: {
    id: '',
    description: 'country name, Saudi Arabia',
    defaultMessage: 'Saudi Arabia',
  },
  SN: {
    id: '',
    description: 'country name, Senegal',
    defaultMessage: 'Senegal',
  },
  RS: {
    id: '',
    description: 'country name, Serbia',
    defaultMessage: 'Serbia',
  },
  SC: {
    id: '',
    description: 'country name, Seychelles',
    defaultMessage: 'Seychelles',
  },
  SL: {
    id: '',
    description: 'country name, Sierra Leone',
    defaultMessage: 'Sierra Leone',
  },
  SG: {
    id: '',
    description: 'country name, Singapore',
    defaultMessage: 'Singapore',
  },
  SK: {
    id: '',
    description: 'country name, Slovakia',
    defaultMessage: 'Slovakia',
  },
  SI: {
    id: '',
    description: 'country name, Slovenia',
    defaultMessage: 'Slovenia',
  },
  SB: {
    id: '',
    description: 'country name, Solomon Islands',
    defaultMessage: 'Solomon Islands',
  },
  SO: {
    id: '',
    description: 'country name, Somalia',
    defaultMessage: 'Somalia',
  },
  SX: {
    id: '',
    description: 'country name, Sint Maarten',
    defaultMessage: 'Sint Maarten',
  },
  ZA: {
    id: '',
    description: 'country name, South Africa',
    defaultMessage: 'South Africa',
  },
  GS: {
    id: '',
    description: 'country name, South Georgia And Sandwich Isl',
    defaultMessage: 'South Georgia And Sandwich Isl',
  },
  ES: {
    id: '',
    description: 'country name, Spain',
    defaultMessage: 'Spain',
  },
  LK: {
    id: '',
    description: 'country name, Sri Lanka',
    defaultMessage: 'Sri Lanka',
  },
  SD: {
    id: '',
    description: 'country name, Sudan',
    defaultMessage: 'Sudan',
  },
  SR: {
    id: '',
    description: 'country name, Suriname',
    defaultMessage: 'Suriname',
  },
  SJ: {
    id: '',
    description: 'country name, Svalbard And Jan Mayen',
    defaultMessage: 'Svalbard And Jan Mayen',
  },
  SZ: {
    id: '',
    description: 'country name, Eswatini',
    defaultMessage: 'Eswatini',
  },
  SE: {
    id: '',
    description: 'country name, Sweden',
    defaultMessage: 'Sweden',
  },
  CH: {
    id: '',
    description: 'country name, Switzerland',
    defaultMessage: 'Switzerland',
  },
  SY: {
    id: '',
    description: 'country name, Syrian Arab Republic',
    defaultMessage: 'Syrian Arab Republic',
  },
  TW: {
    id: '',
    description: 'country name, Taiwan',
    defaultMessage: 'Taiwan',
  },
  TJ: {
    id: '',
    description: 'country name, Tajikistan',
    defaultMessage: 'Tajikistan',
  },
  TZ: {
    id: '',
    description: 'country name, Tanzania',
    defaultMessage: 'Tanzania',
  },
  TH: {
    id: '',
    description: 'country name, Thailand',
    defaultMessage: 'Thailand',
  },
  TL: {
    id: '',
    description: 'country name, Timor-Leste',
    defaultMessage: 'Timor-Leste',
  },
  TG: {
    id: '',
    description: 'country name, Togo',
    defaultMessage: 'Togo',
  },
  TK: {
    id: '',
    description: 'country name, Tokelau',
    defaultMessage: 'Tokelau',
  },
  TO: {
    id: '',
    description: 'country name, Tonga',
    defaultMessage: 'Tonga',
  },
  TT: {
    id: '',
    description: 'country name, Trinidad And Tobago',
    defaultMessage: 'Trinidad And Tobago',
  },
  TN: {
    id: '',
    description: 'country name, Tunisia',
    defaultMessage: 'Tunisia',
  },
  TM: {
    id: '',
    description: 'country name, Turkmenistan',
    defaultMessage: 'Turkmenistan',
  },
  TC: {
    id: '',
    description: 'country name, Turks And Caicos Islands',
    defaultMessage: 'Turks And Caicos Islands',
  },
  TV: {
    id: '',
    description: 'country name, Tuvalu',
    defaultMessage: 'Tuvalu',
  },
  TR: {
    id: '',
    description: 'country name, Türkiye (known previously as Turkey)',
    defaultMessage: 'Türkiye',
  },
  UG: {
    id: '',
    description: 'country name, Uganda',
    defaultMessage: 'Uganda',
  },
  UA: {
    id: '',
    description: 'country name, Ukraine',
    defaultMessage: 'Ukraine',
  },
  AE: {
    id: '',
    description: 'country name, United Arab Emirates',
    defaultMessage: 'United Arab Emirates',
  },
  GB: {
    id: '',
    description: 'country name, United Kingdom',
    defaultMessage: 'United Kingdom',
  },
  US: {
    id: '',
    description: 'country name, United States',
    defaultMessage: 'United States',
  },
  UM: {
    id: '',
    description: 'country name, United States Outlying Islands',
    defaultMessage: 'United States Outlying Islands',
  },
  UY: {
    id: '',
    description: 'country name, Uruguay',
    defaultMessage: 'Uruguay',
  },
  UZ: {
    id: '',
    description: 'country name, Uzbekistan',
    defaultMessage: 'Uzbekistan',
  },
  VU: {
    id: '',
    description: 'country name, Vanuatu',
    defaultMessage: 'Vanuatu',
  },
  VE: {
    id: '',
    description: 'country name, Venezuela',
    defaultMessage: 'Venezuela',
  },
  VN: {
    id: '',
    description: 'country name, Viet Nam',
    defaultMessage: 'Viet Nam',
  },
  VG: {
    id: '',
    description: 'country name, Virgin Islands, British',
    defaultMessage: 'Virgin Islands, British',
  },
  VI: {
    id: '',
    description: 'country name, Virgin Islands, US',
    defaultMessage: 'Virgin Islands, US',
  },
  WF: {
    id: '',
    description: 'country name, Wallis And Futuna',
    defaultMessage: 'Wallis And Futuna',
  },
  EH: {
    id: '',
    description: 'country name, Western Sahara',
    defaultMessage: 'Western Sahara',
  },
  YE: {
    id: '',
    description: 'country name, Yemen',
    defaultMessage: 'Yemen',
  },
  ZM: {
    id: '',
    description: 'country name, Zambia',
    defaultMessage: 'Zambia',
  },
  ZW: {
    id: '',
    description: 'country name, Zimbabwe',
    defaultMessage: 'Zimbabwe',
  },
});

export default countries;
export type CountryCode = keyof typeof countries;
