import React, { useCallback, useMemo } from 'react';
import { Modal } from '@dropbox/dig-components/modal';
import { Text, Title } from '@dropbox/dig-components/typography';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styles from './pause.module.scss';
import { PauseCancelModalContext } from '../types';
import { RemoteWorkHero } from '@dropbox/dig-illustrations/dist/cjs/hero/remote-work';
import Spacer, { SpacerSize } from '../../../foundation/spacing/spacer';
import { LineDivider } from '../../../../hello-react/components/line-divider';
import { link } from '../translation-utils';
import HfReactHelper from '../../../../js/sign-components/common/hf-react-helper';
import { Select } from '@dropbox/dig-components/text_fields';
import { DurationType } from './index';

type PauseModalProps = {
  isMonthly: PauseCancelModalContext['isMonthly'];
  totalCharge: PauseCancelModalContext['totalCharge'];
  duration: string;
  handleSetDuration: (newDuration: DurationType) => void;
  nextChargeDate: string;
};

const PauseContent = ({
  isMonthly,
  totalCharge,
  duration,
  handleSetDuration,
  nextChargeDate,
}: PauseModalProps) => {
  const pauseHelpPageLink = HfReactHelper.getFaqUrl(
    'articles/31327622126605-Pausing-your-Dropbox-Sign-subscription',
  );

  const getPauseUntilDate = useMemo(() => {
    const chargeDate = new Date(nextChargeDate);
    return new Date(chargeDate.setMonth(chargeDate.getMonth() + +duration));
  }, [nextChargeDate, duration]);

  const PauseDurationSelector = useCallback(() => {
    return (
      <div className={styles.pauseDurationContainer}>
        <FormattedMessage
          id=""
          description="Pause modal. This string is followed by a dropdown allowing the user to select a duration to pause for. Ex. Pause for 3 months"
          defaultMessage="Pause for"
        />
        <Spacer right={SpacerSize.SM} />
        <div className={styles.selector} data-qa-ref="pause-duration-selector">
          <Select
            id="pause-duration-selector"
            aria-label="pause duration selector"
            data-qa-ref="pause-duration-selector"
            value={duration}
            onChange={(selection) => {
              handleSetDuration(selection as DurationType);
            }}
          >
            <Select.Option value="1">1 month</Select.Option>
            <Select.Option value="2">2 months</Select.Option>
            <Select.Option value="3">3 months</Select.Option>
          </Select>
        </div>
      </div>
    );
  }, [duration, handleSetDuration]);

  const getHeader = useMemo(() => {
    return (
      <FormattedMessage
        id=""
        description="header, pause flow header"
        defaultMessage="Pause your plan for up to 3 months"
      />
    );
  }, []);

  const getDescription = useMemo(() => {
    return (
      <>
        <Spacer top={SpacerSize.MD} />
        <LineDivider />
        <Spacer top={SpacerSize.LG} />
        <FormattedMessage
          id=""
          description="header, pause flow header highlighting pause offer"
          defaultMessage="You’ll have limited functionality and won't be charged during this time. Billing will automatically resume on {date} and you will be billed {totalCharge} per {isMonthly, select, true {month} other {year}} starting that date until you cancel your plan."
          values={{
            totalCharge,
            isMonthly,
            date: (
              <FormattedDate
                value={getPauseUntilDate}
                day="numeric"
                month="long"
                year="numeric"
              />
            ),
          }}
        />
        <Spacer top={SpacerSize.MD} />
        <FormattedMessage
          id=""
          description="sub-header, pause flow header highlighting pause offer"
          defaultMessage="Previous charges won't be refunded when you cancel unless it's legally required. You can choose to resume any time. Learn more about what pausing your account does <a>here</a>."
          values={{
            a: (chunks: any[]) => link(chunks, pauseHelpPageLink),
          }}
        />
        <Spacer top={SpacerSize.LG} />
        <LineDivider />
      </>
    );
  }, [totalCharge, isMonthly, getPauseUntilDate, pauseHelpPageLink]);

  return (
    <>
      <div className={styles.heroImage}>
        <RemoteWorkHero aspectRatio="16:9" />
      </div>
      <Modal.Header hasBottomSpacing="title-standard">
        <Title id="cancellation-flow-modal-title" className={styles.header}>
          {getHeader}
        </Title>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <PauseDurationSelector />
        <div className={styles.description}>
          <Text>{getDescription}</Text>
        </div>
      </Modal.Body>
    </>
  );
};

export default PauseContent;
