import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { getBrandName } from 'js/sign-components/common/brand';
import { Text } from '@dropbox/dig-components/typography';
import { SafeDowngradeStatusEnum } from '../../../hellospa/components/cancellation-flow-modal/types';

function Plan(props) {
  const {
    id,
    name,
    // isCanceled,
    isApi,
    isFree,
    isFreeTrial,
    isBillingInfoFake,
    hasAlternativeBilling,
    isHf,
    isMobilePurchase,
    // endDate,
    startDate,
    // Not sure why this is different from endDate, but it is
    subEndDate,
    subApiEndDate,
    numSeats,
    teamSize,
    numSigRequestsUsed,
    numSigRequestsPerMonth,
    numTrialDays,
    numTemplatesLeft,
    hfNumFaxPagesPerMonth,
    hfNumFaxPagesLeft,
    hfNumBonusFaxPages,
    hfInviteURL,
    hfIsPaid,
    hfNumExtraPages,
    hfPromoName,
    hasApiAccess,
    allowManagePlan,

    // SMS quota
    hasSignerSMSAuth,
    hasUnlimitedSMSAuth,
    signerSMSAuthenticationTotal,
    signerSMSAuthenticationUsed,
    signerSMSAuthenticationLeft,

    // SafeDowngrade
    safeDowngradeStatus,
    pauseDate,
    unpauseDate,
  } = props;

  const bodyChunks = [];

  if (
    !isFreeTrial &&
    ((isApi && !!subApiEndDate) || (!isApi && !!subEndDate))
  ) {
    bodyChunks.push(
      <b>
        <FormattedMessage
          id=""
          description="info text, billing settings page, informs user of his/her subscription status"
          defaultMessage="You have canceled this plan"
        />
      </b>,
    );
  }

  if (!isApi && isFreeTrial) {
    bodyChunks.push(
      <Text isBold>
        <FormattedMessage
          id=""
          description="info text, billing settings page, informs user when trial period will end and user will be charged."
          defaultMessage="Your free trial ends on {startDate, date, long}"
          values={{
            startDate,
          }}
        />
      </Text>,
    );
  }

  if (!isBillingInfoFake || hasAlternativeBilling) {
    if (!isApi && subEndDate) {
      bodyChunks.push(
        <FormattedMessage
          id=""
          description="info text, billing settings page, informs user when their subscription will end"
          defaultMessage="Plan access ends on {endDate, date, long}"
          values={{
            endDate: subEndDate,
          }}
        />,
      );
    } else if (isApi && subApiEndDate) {
      bodyChunks.push(
        <FormattedMessage
          id=""
          description="info text, billing settings page, informs user when their subscription will end"
          defaultMessage="Plan access ends on {endDate, date, long}"
          values={{
            endDate: subApiEndDate,
          }}
        />,
      );
    }
  }

  if (!isHf && !isFree && !isApi && numTemplatesLeft !== null) {
    bodyChunks.push(
      <FormattedMessage
        id=""
        description="info text, billing settings page, informs user how many templates they have used"
        defaultMessage="You have {numTemplatesLeft, plural, =0 {no templates} one {# template} other {# templates}} remaining"
        values={{
          numTemplatesLeft,
        }}
      />,
    );
  }

  if (!isHf && !hasApiAccess && numSeats > 1) {
    const addOrRemoveUsersLink = (
      <a
        className="billing-settings__plan-manage-link"
        href={`/info/payment?plan_id=${id}`}
      >
        <FormattedMessage
          id=""
          description="Text for a link which sends users to an area where they can manage their user count."
          defaultMessage="Add or remove users"
        />
      </a>
    );

    if (teamSize === numSeats) {
      bodyChunks.push(
        <React.Fragment>
          <FormattedMessage
            id=""
            description="info text, billing settings page, informs user that they have used all of the seats in their subscription"
            defaultMessage="{numUsers, plural, one {# user is} other {# users are}} on your team"
            values={{
              numUsers: teamSize,
            }}
          />
          {allowManagePlan && !isFree && addOrRemoveUsersLink}
        </React.Fragment>,
      );
    } else {
      bodyChunks.push(
        <React.Fragment>
          <FormattedMessage
            id=""
            description="info text, billing settings page, informs user of current count of seats taken in subscription against maximum number of seats"
            defaultMessage="{numUsers, plural, one {# user is} other {# users are}} on your team, {numAvailableSeats, plural, one {# is available} other {# are available}}"
            values={{
              numUsers: teamSize,
              numAvailableSeats: numSeats - teamSize,
            }}
          />
          {allowManagePlan && !isFree && addOrRemoveUsersLink}
        </React.Fragment>,
      );
    }
  }

  if (!isHf) {
    const manageLink = (
      <a
        className="billing-settings__plan-manage-link"
        href={`/info/payment?plan_id=${id}`}
      >
        <FormattedMessage
          id=""
          description="Text for a link which sends users to an area where they can manage their plan."
          defaultMessage="Manage"
        />
      </a>
    );

    if (numSigRequestsPerMonth === null) {
      bodyChunks.push(
        <FormattedMessage
          id=""
          description="info text, billing settings page, informs user of api signature request usage"
          defaultMessage="You have unlimited signature requests"
        />,
      );
    } else if (isApi) {
      bodyChunks.push(
        <React.Fragment>
          <FormattedMessage
            id=""
            description="info text, billing settings page, informs user of api signature request usage"
            defaultMessage="You've used {numSigRequestsUsed, number} of {numSigRequestsPerMonth, number} total signature requests"
            values={{
              numSigRequestsUsed,
              numSigRequestsPerMonth,
            }}
          />
          {allowManagePlan && !isFree && manageLink}
        </React.Fragment>,
      );
    } else {
      bodyChunks.push(
        <React.Fragment>
          <FormattedMessage
            id=""
            description="info text, billing settings page, informs user of API signature request usage"
            defaultMessage="You've used {numSigRequestsUsed, number} of {numSigRequestsPerMonth, number} total signature requests"
            values={{
              numSigRequestsUsed,
              numSigRequestsPerMonth,
            }}
          />
          *{allowManagePlan && !isFree && manageLink}
        </React.Fragment>,
      );

      bodyChunks.push(
        <span className="billing-settings__plan-disclosure-text">
          *
          <FormattedMessage
            id=""
            description="info text, billing settings page, disclaimer that the number of signature request used above includes requests from the website, via API or add-ons"
            defaultMessage="Total signature requests includes requests sent from API, website or any add-ons, such as Salesforce or Oracle."
          />
        </span>,
      );
    }

    if (hasSignerSMSAuth) {
      if (hasUnlimitedSMSAuth) {
        bodyChunks.push(
          <React.Fragment>
            <FormattedMessage
              id=""
              description="info text, billing settings page, unlimited total amount of SMS for user\'s subscription"
              defaultMessage="You have unlimited SMS for signer authentication."
            />
          </React.Fragment>,
        );
      } else {
        bodyChunks.push(
          <React.Fragment>
            <FormattedMessage
              id=""
              description="info text, billing settings page, unlimited total amount of SMS for user\'s subscription"
              defaultMessage="You have used {signerSMSAuthenticationUsed, number} of {signerSMSAuthenticationTotal, number} total SMS for signer authentication ({signerSMSAuthenticationLeft, number} left)"
              values={{
                signerSMSAuthenticationUsed,
                signerSMSAuthenticationTotal,
                signerSMSAuthenticationLeft,
              }}
            />
          </React.Fragment>,
        );
      }
    }
  } else {
    if (hfIsPaid || hfPromoName) {
      if (hfPromoName) {
        bodyChunks.push(
          <FormattedMessage
            id=""
            description="label, settings page, label for promotion name that was associated with an account"
            defaultMessage="Promo: {promoName}"
            values={{
              promoName: hfPromoName,
            }}
          />,
        );
      }

      // Null means unlimited. Could be zero.
      if (typeof hfNumFaxPagesPerMonth === 'number') {
        bodyChunks.push(
          <FormattedMessage
            id=""
            description="label, settings page, displays number of pages per month under account"
            defaultMessage="Fax pages per month: {numPages, number}"
            values={{
              numPages: hfNumFaxPagesPerMonth,
            }}
          />,
        );
      }

      // Null means unlimited. Could be zero.
      if (typeof hfNumFaxPagesLeft === 'number') {
        bodyChunks.push(
          <FormattedMessage
            id=""
            description="label, billing, settings page, informs user on remaining faxes current month"
            defaultMessage="Fax pages remaining this month: {numPages, number}"
            values={{
              numPages: hfNumFaxPagesLeft,
            }}
          />,
        );
      }

      if (hfNumBonusFaxPages) {
        bodyChunks.push(
          <FormattedMessage
            id=""
            description="label, billing settings page, informs user of num of bonus fax pages left"
            defaultMessage="Bonus fax pages: {numPages, number}"
            values={{
              numPages: hfNumBonusFaxPages,
            }}
          />,
        );
      }

      if (hfNumExtraPages) {
        bodyChunks.push(
          <FormattedMessage
            id=""
            description="label, billing settings page, informs user of extra pages used"
            defaultMessage="Extra fax pages used: {numPages, number}"
            values={{
              numPages: hfNumExtraPages,
            }}
          />,
        );
      }
    } else {
      bodyChunks.push(
        <FormattedMessage
          id=""
          description="label, billing, settings page, informs user on remaining faxes current month"
          defaultMessage="Fax pages remaining this month: {numPages, number}"
          values={{
            numPages: hfNumFaxPagesLeft,
          }}
        />,
      );
    }

    if (hfInviteURL) {
      bodyChunks.push(
        <React.Fragment>
          <br />
          <FormattedMessage
            id=""
            description="info text, clickable link, informs user that he/she can get bonus pages if invite link is used and new user is registered"
            defaultMessage="Get 5 free pages by sharing the following link with your friends:"
          />
          <br />
          {/* Don't worry, I'm a professional! */}
          {/* This invite URL comes directly from our backend and cannot be affected, */}
          {/* influenced, or manipulated by any user input in any way. */}
          <span dangerouslySetInnerHTML={{ __html: hfInviteURL }}></span>
        </React.Fragment>,
      );
    }
  }
  if (isMobilePurchase) {
    bodyChunks.push(
      <React.Fragment>
        <br />
        <FormattedMessage
          id=""
          description="info text, clickable link, informs user that he/she can get bonus pages if invite link is used and new user is registered"
          defaultMessage="You purchased {brandName} {name} through the App Store or Google Play Store. To make changes to your subscription, visit the store you purchased from."
          values={{
            name,
            brandName: getBrandName('S'),
          }}
        />
        <br />
      </React.Fragment>,
    );
  }

  let planName = name;
  if (isApi) {
    planName = (
      <FormattedMessage
        id=""
        description="Displays the name of the user's subscription plan followed by '(API)' to indicate that the plan is an API plan"
        defaultMessage="{name} (API)"
        values={{
          name,
        }}
      />
    );
  } else if (isFreeTrial) {
    if (numTrialDays > 0) {
      planName = (
        <FormattedMessage
          id=""
          description="Displays the name of the user's trial subscription plan followed by '(##-day free trial)'"
          defaultMessage="{name} ({numTrialDays, number}-day free trial)"
          values={{
            numTrialDays,
            name,
          }}
        />
      );
    } else {
      planName = (
        <FormattedMessage
          id=""
          description="Displays the name of the user's trial subscription plan followed by '(free trial)'"
          defaultMessage="{name} (free trial)"
          values={{
            numTrialDays,
            name,
          }}
        />
      );
    }
  }

  if (Object.values(SafeDowngradeStatusEnum).includes(safeDowngradeStatus)) {
    return (
      <div className="billing-settings__plan">
        {planName && <p className="billing-settings__plan-name">{planName}</p>}
        {safeDowngradeStatus === SafeDowngradeStatusEnum.PAUSED && (
          <>
            <div className="billing-settings__paused-text">
              <FormattedMessage
                id=""
                description="header, single word 'Paused'"
                defaultMessage="Paused"
              />
            </div>
            <div />
            {unpauseDate && (
              <FormattedMessage
                id=""
                description="header, describing how long the subscription will be paused for"
                defaultMessage="Paused until {unpauseDate}"
                values={{
                  unpauseDate: (
                    <FormattedDate
                      value={unpauseDate}
                      day="numeric"
                      month="long"
                      year="numeric"
                    />
                  ),
                }}
              />
            )}
          </>
        )}
        {safeDowngradeStatus === SafeDowngradeStatusEnum.PENDING_PAUSE && (
          <>
            <div className="billing-settings__paused-text">
              {pauseDate ? (
                <FormattedMessage
                  id=""
                  description="header, decibing pause date'"
                  defaultMessage="Pausing on {pauseDate}"
                  values={{
                    pauseDate: (
                      <FormattedDate
                        value={pauseDate}
                        day="numeric"
                        month="long"
                        year="numeric"
                      />
                    ),
                  }}
                />
              ) : (
                <FormattedMessage
                  id=""
                  description="header, single word 'Pausing'"
                  defaultMessage="Pausing"
                />
              )}
            </div>
            <div />
            {unpauseDate && (
              <FormattedMessage
                id=""
                description="header, describing how long the subscription will be paused for"
                defaultMessage="until {unpauseDate}"
                values={{
                  unpauseDate: (
                    <FormattedDate
                      value={unpauseDate}
                      day="numeric"
                      month="long"
                      year="numeric"
                    />
                  ),
                }}
              />
            )}
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="billing-settings__plan">
        {planName && <p className="billing-settings__plan-name">{planName}</p>}

        {bodyChunks.map((chunk, i) => (
          <p key={i}>{chunk}</p>
        ))}
      </div>
    );
  }
}

export default Plan;
