import React from 'react';

import MinimalLayout from 'signer-app/parts/minimal-layout';
import { siteCodeSign } from 'js/sign-components/common/brand';
import SignInForm from 'hellospa/page/authentication/sign-in-form';
import { CountryCodeProvider } from 'signer-app/authentication/use-country-code';
import { setCsrfToken } from 'js/sign-components/common/hs-fetch';
import { FeatureFlagProvider } from 'js/sign-components/common/feature-flag';

type LoginFormProps = {
  csrfToken: string;
};

function LoginForm({ csrfToken }: LoginFormProps) {
  setCsrfToken(csrfToken);

  return (
    // @ts-ignore window.config comes from backend
    <FeatureFlagProvider featureFlags={window?.config?.featureFlags || {}}>
      <MinimalLayout siteCode={siteCodeSign} locale="en-US" contentCentered>
        <CountryCodeProvider>
          <div style={{ width: '100%', maxWidth: '420px', margin: '0 auto' }}>
            <SignInForm
              hideCreateAccountLink
              redirectUrl={window.location.href}
            />
          </div>
        </CountryCodeProvider>
      </MinimalLayout>
    </FeatureFlagProvider>
  );
}

export default LoginForm;
