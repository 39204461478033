import React from 'react';
import styles from './error.module.scss';
import { Modal } from '@dropbox/dig-components/modal';
import { Button } from '@dropbox/dig-components/buttons';
import { FormattedMessage } from 'react-intl';
import { Text, Title } from '@dropbox/dig-components/typography';
import { errors } from '../constants';
import { link } from '../translation-utils';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

const supportLink = HfReactHelper.getWebflowUrl('home/help');

type ErrorProps = {
  error: string | null;
  handleCloseModal: () => void;
};

const Error = ({ handleCloseModal, error }: ErrorProps) => (
  <div className={styles.error}>
    <Modal.Header hasBottomSpacing="title-standard">
      <Title id="cancellation-flow-modal-title" className={styles.header}>
        <FormattedMessage
          id=""
          description="Header in the case of a cancellation, pause, or downgrade error."
          defaultMessage="Oops! Something went wrong"
        />
      </Title>
    </Modal.Header>
    <Modal.Body>
      {errors.RESUME_PAUSED === error && (
        <Text data-testid="error-resume-pause-text">
          <FormattedMessage
            id=""
            description="Error description and call to action"
            defaultMessage="Sorry, we couldn't resume your subscription. Please try again or <l1>contact us</l1>."
            values={{
              l1: (chunks: any[]) => link(chunks, supportLink),
            }}
          />
        </Text>
      )}
      {errors.PAUSE === error && (
        <Text data-testid="error-pause-text">
          <FormattedMessage
            id=""
            description="Error description and call to action"
            defaultMessage="Sorry, we couldn't pause your subscription. Please try again or <l1>contact us</l1>."
            values={{
              l1: (chunks: any[]) => link(chunks, supportLink),
            }}
          />
        </Text>
      )}
      {errors.CANCELLATION === error && (
        <Text data-testid="error-cancellation-text">
          <FormattedMessage
            id=""
            description="Error description and call to action"
            defaultMessage="Sorry, we couldn't cancel your subscription. Please try again or <l1>contact us</l1>."
            values={{
              l1: (chunks: any[]) => link(chunks, supportLink),
            }}
          />
        </Text>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="primary"
        onClick={handleCloseModal}
        data-qa-ref="error-done-button"
      >
        <FormattedMessage
          id=""
          description="Button to close modal"
          defaultMessage="Done"
        />
      </Button>
    </Modal.Footer>
  </div>
);

export default Error;
