/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  camelcase,
  import/no-extraneous-dependencies,
  no-restricted-globals,
  no-useless-escape
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import createReactClass from 'create-react-class';
import LoginModal from './login-modal';

const LogInTrigger = createReactClass({
  propTypes: {
    header: PropTypes.string.isRequired,
    linkText: PropTypes.string,
    trigger: PropTypes.element,
    googleSignInClientId: PropTypes.string,
    buttonText: PropTypes.string,
    tabOffset: PropTypes.number,
    loginUrl: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string,
    recoverPasswordForm: PropTypes.object.isRequired, // Serialized sfForm
    loginForm: PropTypes.object.isRequired, // Serialized sfForm
    hideRememberMe: PropTypes.bool,
    hideGoogleOpenId: PropTypes.bool,
  },

  componentDidMount() {
    if (window.handlingLogInTriggers) {
      // We only want to process this once per page
      return;
    }
    // If the 'login=1' URL parameter is present, display the modal
    if (/\?display_login\=1|&display_login\=1/i.test(location.href)) {
      window.handlingLogInTriggers = true;
      this.handleClick();
    }
  },

  handleClick(evt, react_id, extraProps) {
    const tabOffset = parseInt(this.props.tabOffset || 1000, 10);
    const attachPoint = HfReactHelper.getRevealAttachPoint('login_modal');
    const props = extraProps
      ? HfReactHelper.extend({}, this.props, extraProps)
      : this.props;
    const modalPortalHack = ReactDOM.createPortal(
      <LoginModal
        header={props.header}
        buttonText={props.buttonText}
        googleSignInClientId={props.googleSignInClientId}
        isFromApiPricing={props.isFromApiPricing}
        tabOffset={tabOffset}
        loginUrl={props.loginUrl}
        recoverPasswordForm={props.recoverPasswordForm}
        redirectUrl={props.redirectUrl}
        loginForm={props.loginForm}
        hideRememberMe={props.hideRememberMe}
        hideGoogleOpenId={props.hideGoogleOpenId}
      />,
      attachPoint,
    );
    this.setState({ modalPortalHack });
  },

  renderTrigger() {
    if (this.props.trigger) {
      return this.props.trigger;
    } else {
      return <a href="#">{this.props.linkText}</a>;
    }
  },

  render() {
    return (
      <span className="log-in-trigger" onClick={this.handleClick}>
        {this.state?.modalPortalHack}
        {this.renderTrigger()}
      </span>
    );
  },
});

module.exports = LogInTrigger;
