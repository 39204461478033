export const modalTypes = {
  BILLBOARD: 'Billboard',
  RESUME_PAUSED: 'ResumePaused',
  SURVEY: 'Survey',
  PAUSE: 'Pause',
  SUPPORT: 'Support',
  ERROR: 'Error',
};

export const errors = {
  PAUSE: 'PAUSE',
  RESUME_PAUSED: 'RESUME_PAUSED',
  CANCELLATION: 'CANCELLATION',
};
