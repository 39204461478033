import React from 'react';
import classnames from 'classnames';
import styles from './icons.scss';

const FacebookLogo = (props) => (
  <svg
    {...props}
    viewBox="0 0 20 20"
    className={classnames(styles.icon, props.className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1143 10.3116C20.1143 4.61664 15.6115 0 10.0571 0C4.50271 0 0 4.61664 0 10.3116C0 15.4585 3.67774 19.7244 8.48569 20.498V13.2924H5.93213V10.3116H8.48569V8.03984C8.48569 5.45549 9.98716 4.02802 12.2844 4.02802C13.3848 4.02802 14.5357 4.2294 14.5357 4.2294V6.767H13.2675C12.0182 6.767 11.6285 7.56187 11.6285 8.37737V10.3116H14.4178L13.972 13.2924H11.6285V20.498C16.4365 19.7244 20.1143 15.4585 20.1143 10.3116Z"
      fill="#212121"
    />
  </svg>
);

export default React.memo(FacebookLogo);
