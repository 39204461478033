import React from 'react';
import { FormattedMessage } from 'react-intl';

function Cycle(props) {
  const { lastChargeDate, isWebPaid, hasAPIBillingPlan, isAPIPaid } = props;

  const bodyChunks = [];

  if (lastChargeDate !== null) {
    bodyChunks.push(
      <a href="/account/receipts">
        <FormattedMessage
          id=""
          description="label, link to receipts and invoices"
          defaultMessage="View"
        />
      </a>,
    );
  } else if (isWebPaid || (hasAPIBillingPlan && isAPIPaid)) {
    bodyChunks.push(
      <FormattedMessage
        id=""
        description="info text, billing settings page, informs user that invoices/receipts will be available after trial."
        defaultMessage="Your first receipt will be available after your trial ends"
      />,
    );
  } else {
    bodyChunks.push(
      <FormattedMessage
        id=""
        description="info text, billing settings page, informs user that there are no invoices/receipts"
        defaultMessage="No receipt yet"
      />,
    );
  }

  return (
    <div className="billing-settings__invoices">
      {bodyChunks.map((chunk, i) => (
        <p key={i}>{chunk}</p>
      ))}
    </div>
  );
}

export default Cycle;
