import hsFetch, { getCSRFToken } from 'js/sign-components/common/hs-fetch';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { postMessage, messages } from 'js/sign-components/common/messages';
import { convertForEditor, LegacyEditorData } from './editor-converters';
import { validatePrepAndSendRequest } from 'js/sign-components/generated/validators/validateHelloRequest';
import { assertDataIsValid } from 'signer-app/utils/ajv-validation';
import { BadResponseError } from '../utils';

/* eslint-disable camelcase */
export type SaveEmbeddedRequestResponse = {
  success: boolean;
  signature_request_id: string;
  signature_request_info: {
    title: string;
    message: string;
    expires_at?: Date | null | undefined;
    signatures: Array<{
      signer_name: string;
      signer_email_address: string;
      order: null | number;
    }>;
    cc_email_addresses: string[];
  };
};
export type SaveEmbeddedTemplateResponse = {
  success: boolean;
  template_id: string;
  template_status_id: string;
  template_info: {
    title: string;
    message: string;
    expires_at?: Date | null | undefined;
    signer_roles: Array<{
      name: string;
      order: null | number;
    }>;
    cc_roles: string[];
  };
};
/* eslint-enable camelcase */

/**
 * WARNING: This type is incomplete. I'm only including enough functionality to
 * make Embedded work.
 */
export type SaveDataResponse =
  | SaveEmbeddedRequestResponse
  | SaveEmbeddedTemplateResponse;
export { LegacyEditorData };

/* eslint-enable camelcase */
export async function loadData(
  cachedParamsToken: string,
): Promise<LegacyEditorData> {
  // Fetch the data from the server
  const url = `/editor/getData?cached_params_token=${cachedParamsToken}`;
  const response = await hsFetch(url, {
    credentials: 'same-origin',
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  if (response.status === 200) {
    return (await response.json()).data;
  }

  throw new BadResponseError(response);
}

export async function loadUnifiedData(
  cachedParamsToken: string,
): Promise<LegacyEditorData> {
  const url = `/editor/getUnifiedData?cached_params_token=${cachedParamsToken}`;
  const response = await hsFetch(url, {
    credentials: 'same-origin',
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });
  const unifiedData: unknown = await response.json();

  // The return type of `validate` is `asserts data is T`. TypeScript knows that
  // if this function doesn't throw, then unifiedData must be a PrepAndSendRequest
  // because the first parameter is a ValidateFunction<T>
  assertDataIsValid(validatePrepAndSendRequest, unifiedData);

  const data = convertForEditor(unifiedData);

  return data;
}

export async function updateEditor(
  snapshots: Array<string>,
  cachedParamsToken: string,
  preLoadedTsmGroupKey: string,
) {
  const formData = new FormData();
  snapshots
    .map((guid) => guid)
    .forEach((guid: string) => {
      formData.append('snapshot_guids[]', guid);
    });
  formData.append('csrf_token', getCSRFToken());
  formData.append('preloaded_tsm_group_key', preLoadedTsmGroupKey);
  formData.append('cached_params_token', cachedParamsToken);
  const response = await hsFetch('/editor/updateEditor', {
    method: 'POST',
    body: formData,
  });

  const data = await response.json();

  if (data === 'success') {
    return data;
  }
  throw new Error('Unhandled response from editor/updateEditor');
}

export async function reorderSnapshots(
  transmissionGroupGuid: string,
  snapshotOrder: { [guid: string]: Number },
) {
  const url = '/attachment/asyncReorderSnapshots';

  const formData = new FormData();
  formData.append('preloaded_tsm_group_key', transmissionGroupGuid);
  formData.append('csrf_token', getCSRFToken());

  // eslint-disable-next-line no-restricted-syntax
  for (const [guid, order] of Object.entries(snapshotOrder)) {
    formData.append(`snapshot_order[${guid}]`, String(order));
  }

  const response = await hsFetch(url, {
    method: 'POST',
    body: formData,
  });

  if (response.status === 200) {
    const data = await response.json();
    if (!data.success) {
      throw new Error('Unhandled response reordering files');
    }
    return;
  }
  throw new Error('Unhandled response reordering files');
}

export async function loadAutoSuggestionData() {
  const autocompleteResponse = await hsFetch('/editor/asyncAutocomplete', {
    credentials: 'same-origin',
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  return autocompleteResponse.json();
}

export async function deleteDocument(
  snapshotGuid: string,
  preLoadedTsmGroupKey: string,
) {
  if (snapshotGuid) {
    const formData = new FormData();
    formData.append('snapshot_guid', snapshotGuid);
    formData.append('csrf_token', getCSRFToken());
    formData.append('preloaded_tsm_group_key', preLoadedTsmGroupKey);

    const response = await hsFetch('/attachment/delete', {
      method: 'POST',
      body: formData,
    });

    const result = await response.json();
    if (result.error) {
      throw new Error(result.error);
    }

    // Posting message to event listener
    // to remove the deleted document from
    // the prep & send page
    postMessage(
      {
        type: messages.USER_PREP_SEND_EDITOR_DELETE_DOC,
        guid: result.guid,
        parent_guid: result.parent_guid,
      },
      window.parent.location.protocol + HfReactHelper.urlHelper(''),
    );
  }
}

export async function revertDocumentReplace(
  snapshotGuid: string,
  preLoadedTsmGroupKey: string,
) {
  if (snapshotGuid) {
    const formData = new FormData();
    formData.append('snapshot_guid', snapshotGuid);
    formData.append('csrf_token', getCSRFToken());
    formData.append('preloaded_tsm_group_key', preLoadedTsmGroupKey);

    const response = await hsFetch('/attachment/revertUploadReplace', {
      method: 'POST',
      body: formData,
    });

    const result = await response.json();
    if (result.error) {
      throw new Error(result.error);
    }
  }
}

export async function saveData(data: any): Promise<SaveDataResponse> {
  data.csrf_token = getCSRFToken();

  const response = await hsFetch('/editor/saveData', {
    credentials: 'same-origin',
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const res = await response.json();

  if (typeof res.template_info === 'string') {
    res.template_info = JSON.parse(res.template_info);
    res.template_info.signer_roles = JSON.parse(res.template_info.signer_roles);
    res.template_info.cc_roles = res.template_info.cc_roles
      ? JSON.parse(res.template_info.cc_roles)
      : null;
  }

  return res;
}

export async function saveUnifiedData(data: any): Promise<SaveDataResponse> {
  data.csrf_token = getCSRFToken();

  const url = '/editor/saveUnifiedData';
  const response = await hsFetch(url, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  if (!res.success) {
    // eslint-disable-next-line no-console
    console.log('Could not save Unified data');
  }
  return res;
}
