import styles from './dropbox-billing.module.scss';

import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Banner } from '@dropbox/dig-components/banner';
import { Button } from '@dropbox/dig-components/buttons';
import { GlyphLogo, FssGlyph } from '@dropbox/dig-logos';
import {
  getBrandName,
  getBrandShortName,
  SiteCodeType,
} from 'js/sign-components/common/brand';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { Text } from '@dropbox/dig-components/typography';
import Spacer, { SpacerSize } from 'hellospa/foundation/spacing/spacer';

const cookieBaseName = 'dropbox-billing-notice';
const yearInMs = 1000 * 60 * 60 * 24 * 365;
const messages = defineMessages({
  closeButton: {
    id: '',
    description: 'Aria label for the close button on a banner',
    defaultMessage: 'Close',
  },
});

type DropboxBillingProps = React.PropsWithChildren<{
  siteCode: SiteCodeType;
  accountGuid: string;
  planName: string;
  ctaLink: string;
}>;

export default function DropboxBilling({
  siteCode,
  accountGuid,
  planName,
  ctaLink,
}: DropboxBillingProps) {
  const [showBanner, setShowBanner] = useState(false);
  const intl = useIntl();

  const cookieName = `${cookieBaseName}-${accountGuid}`;
  const handleClose = () => {
    HfReactHelper.Cookie.set(cookieName, 'hidden', yearInMs, '/');
    setShowBanner(false);
  };

  const dbxBrandName = getBrandName('D');

  // Open the banner, if applicable
  useEffect(() => {
    if (document && document.cookie) {
      // Only if the user has not previously closed the banner
      const status = HfReactHelper.Cookie.get(cookieName);
      if (status === 'hidden') return;
    }

    setShowBanner(true);
  }, [cookieName]);

  return (
    <div className={styles.dbxBillingContainer}>
      <div>
        {showBanner && (
          <Banner
            type="attention"
            withCloseButton={intl.formatMessage(messages.closeButton)}
            onRequestClose={handleClose}
            data-testid="dbx-billing-banner"
          >
            <Banner.Message>
              <FormattedMessage
                id=""
                description="Banner explaining to the user that they need to manage their billing on Dropbox and will not be charged here on our site."
                defaultMessage="{shortBrandName} features are included in your {dbxBrandName} plan. To manage billing, go to {dbxBrandName}. You will not be billed by {brandName}."
                values={{
                  shortBrandName: getBrandShortName(siteCode),
                  dbxBrandName,
                  brandName: getBrandName(siteCode),
                }}
              />
            </Banner.Message>
          </Banner>
        )}
      </div>

      <Spacer top={SpacerSize.LG} />

      <div>
        <Text size="large" isBold>
          <FormattedMessage
            id=""
            description="Heading for the billing section where it shows the plan name"
            defaultMessage="Your plan"
          />
        </Text>

        <Spacer top={SpacerSize.SM} />

        <div className={styles.dbxBillingDetails}>
          <div>
            <GlyphLogo size={32} src={FssGlyph} />
          </div>
          <div>
            <Text isBold data-testid="dbx-billing-plan">
              {planName}
            </Text>
            <Spacer top={SpacerSize.XS} />
            <Text>
              <FormattedMessage
                id=""
                description="description on the billing page informing the user they can manage their billing, invoice and payment details on Dropbox rather than here on our site"
                defaultMessage="Manage billing, invoice and payment details from your {dbxBrandName} account"
                values={{
                  dbxBrandName,
                }}
              />
            </Text>

            <Spacer top={SpacerSize.SM} />
            <Button variant="primary" href={ctaLink} target="_blank">
              <FormattedMessage
                id=""
                description="button label on the user's billing page, clicking this button opens the Dropbox billing page in a new tab"
                defaultMessage="Manage billing on {dbxBrandName}"
                values={{
                  dbxBrandName,
                }}
              />
            </Button>
          </div>
        </div>
      </div>

      <Spacer top={SpacerSize.XL} />

      <Text size="large" isBold>
        <FormattedMessage
          id=""
          description="Header for a section with information related to a user\'s billing receipts."
          defaultMessage="Billing receipts"
        />
      </Text>

      <Spacer top={SpacerSize.SM} />

      <div>
        <Button variant="outline" href="/account/receipts">
          <FormattedMessage
            id=""
            description="button label on the user's billing page, clicking this button opens up a page to their previous receipts"
            defaultMessage="View past receipts"
            values={{
              dbxBrandName,
            }}
          />
        </Button>
      </div>
    </div>
  );
}
