/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies
*/
import './index.scss';
import React from 'react';
import classNames from 'classnames';
import RightArrow from './right_arrow.svg';

function Arrow(props) {
  const arrowClasses = classNames({
    'arrow-button': true,
    [`arrow-button-${props.direction}`]: true,
  });
  const lineClasses = classNames({
    'arrow-line': true,
    [`arrow-line-${props.direction}`]: true,
  });
  return (
    <div className="m-pricing-arrow" data-test-id="arrow-button">
      <div className={lineClasses} />
      <img
        src={RightArrow}
        className={arrowClasses}
        onClick={props.handleClick}
      />
    </div>
  );
}

module.exports = Arrow;
