/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  no-useless-constructor
*/
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import FixedModal from 'common/components/fixed-modal';
import { unescape } from 'lodash';
import Button from 'common/components/button';
import CloseImageSrc from 'common/assets/modal-close@2x.png';
import DownloadImageSrc from 'common/assets/download-24.svg';
import PrintImageSrc from 'common/assets/print-24.svg';
import StyleProvider from 'hello-ui/style';
import './index.scss';
import classNames from 'classnames';
import styles from './disclosure-modal.module.css';

const messages = defineMessages({
  gotItButton: {
    id: '', // IDs are procedurally generated
    description:
      'button text in preview disclosure of setting page, understood, when clicked closes the modal',
    defaultMessage: 'Got it',
  },
});

export class DisclosureModal extends React.Component {
  static defaultProps = {
    rounded: true,
    showPrintButton: false,
    showDownloadButton: false,
    onPrint: () => {
      window.print();
    },
  };

  static propTypes = {
    header: PropTypes.string,
    content: PropTypes.string,
    onSubmit: PropTypes.func,
    submitButton: PropTypes.string,
    onClose: PropTypes.func,
    rounded: PropTypes.bool,
    showPrintButton: PropTypes.bool,
    showDownloadButton: PropTypes.bool,
    onPrint: PropTypes.func,
    onDownload: PropTypes.func,
    intl: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      header,
      content,
      rounded,
      onSubmit,
      submitButton,
      onClose,
      showPrintButton,
      showDownloadButton,
      onDownload,
      onPrint,
    } = this.props;

    const modalStyle = { borderRadius: rounded ? 5 : 0 };
    const downloadButton = (
      <a onClick={onDownload}>
        <img src={DownloadImageSrc} />
      </a>
    );
    const printButton = (
      <a onClick={onPrint}>
        <img src={PrintImageSrc} />
      </a>
    );

    return (
      <StyleProvider>
        <FixedModal width={600} modalStyle={modalStyle}>
          <div className="disclosure-modal-close-button" onClick={onClose}>
            <img src={CloseImageSrc} />
          </div>
          <div
            p={3}
            className={classNames(styles.header, 'disclosure-modal-header')}
          >
            <p>{unescape(header)}</p>
          </div>

          <div
            className={classNames(styles.content, 'disclosure-modal-content')}
          >
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>

          <div
            className={classNames(
              styles.modalFooter,
              'disclosure-modal-footer',
            )}
          >
            <div className={styles.flex}>
              <div className={styles.downloadAndPrint}>
                {showDownloadButton ? downloadButton : null}
                {showPrintButton ? printButton : null}
              </div>
              <div>
                <Button
                  buttonColor="cerulean"
                  buttonText={
                    submitButton ||
                    this.props.intl.formatMessage(messages.gotItButton)
                  }
                  onClick={onSubmit}
                />
              </div>
            </div>
          </div>
        </FixedModal>
      </StyleProvider>
    );
  }
}

export default injectIntl(DisclosureModal);
