import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { SiteCodeType } from 'signer-app/utils/brand';
import { GlobalHeader } from '@dropbox/dig-components/global_header';
import { getInitials } from 'signer-app/utils/i18n-user';
import { Menu } from '@dropbox/dig-components/menu';
import { Text } from '@dropbox/dig-components/typography';
import { avatarColorForUserIdentifier } from '@dropbox/dig-components/avatar';
import Logo from 'signer-app/parts/logo-v2';

import styles from 'signer-app/parts/minimal-layout/minimal-layout.module.css';
import { getSite, urlHelper } from 'signer-app/utils/url-helpers';

export type MinimalLayoutProps = React.PropsWithChildren<{
  siteCode: SiteCodeType;
  locale: string;
  contentCentered?: boolean; // default false
  showAccountMenu?: boolean; // default false
  helpMenu?: React.ReactNode;
  emailAddress?: string;
  name?: string;
  logoHref?: string;
  variant?: '840' | '900';
}>;

function MinimalLayout({
  siteCode,
  locale,
  emailAddress,
  name,
  logoHref,
  contentCentered = false,
  showAccountMenu = false,
  helpMenu,
  variant = '840',
  children,
}: MinimalLayoutProps) {
  const site = getSite(siteCode);

  // Use initials based on name, or email if no name is set.
  const initials = React.useMemo(() => {
    if (!showAccountMenu || !emailAddress) return null;

    if (name != null && name.length > 0) {
      return getInitials(name, locale);
    } else {
      return getInitials(emailAddress, locale);
    }
  }, [showAccountMenu, locale, name, emailAddress]);

  const avatarColor = React.useMemo(() => {
    if (!initials) return null;

    return avatarColorForUserIdentifier(initials);
  }, [initials]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <GlobalHeader.Wrapper hasNoBottomBorder>
          <Logo
            size={40}
            siteCode={siteCode}
            href={logoHref}
            className={styles.logo}
            variant="wordmark"
          />
          {(helpMenu || showAccountMenu) && (
            <GlobalHeader.UtilityNav>
              {helpMenu}
              {showAccountMenu && initials && avatarColor && (
                <GlobalHeader.AccountMenu
                  initials={initials}
                  backgroundColor={avatarColor}
                  data-qa-ref="global-acct-menu"
                >
                  <Menu.Segment>
                    <Menu.LinkItem
                      href={urlHelper('account/logOut', site)}
                      data-qa-ref="global-acct-menu-sign-out-link"
                    >
                      <Text size="large">
                        <FormattedMessage
                          id="f5ba1432f03fde4ad8db4e9dda43d97af4e02394b56788105af120244002b59d"
                          description="Text for a button that when pressed will log the user out"
                          defaultMessage="Sign out"
                        />
                      </Text>
                    </Menu.LinkItem>
                  </Menu.Segment>
                </GlobalHeader.AccountMenu>
              )}
            </GlobalHeader.UtilityNav>
          )}
        </GlobalHeader.Wrapper>
      </div>
      <div
        className={classnames(styles.content, {
          [styles.centered]: contentCentered,
          [styles.v900]: variant === '900',
        })}
      >
        <div className={styles.contentInner}>{children}</div>
      </div>
    </div>
  );
}

export default MinimalLayout;
