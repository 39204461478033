import React from 'react';
import classnames from 'classnames';

import { Link, LinkProps } from '@dropbox/dig-components/typography';

import styles from './icon-link.scss';

export type IconLinkProps = LinkProps & {
  icon: React.ReactNode;
};

/**
 * @example
 *
 * ```
 * <IconLink
 *   href="#"
 *   icon={<UIIcon src={LinkLine} />}
 *   onClick={copyUrlToClipboard}
 * >
 *   <FormattedMessage
 *     id=""
 *     description="Text for a button which copies the link in a field when pressed."
 *     defaultMessage="Copy link" />
 * </IconLink>
 * ```
 */
export const IconLink: React.FC<IconLinkProps> = ({
  icon,
  className,
  children,
  ...linkProps
}) => (
  <Link
    className={classnames(styles.iconLink, className)}
    isBold
    {...linkProps}
  >
    <span className={styles.icon}>{icon}</span>
    {children}
  </Link>
);
