import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import styles from './api-setting.scss';
import { getBrandName } from 'js/sign-components/common/brand';
import { ApiSettingsSection } from './shared';
import { Split, Stack } from '@dropbox/dig-foundations';

type ThirdPartyAppsProps = {
  apps: ApiAppProps[];
};

export type ApiAppProps = {
  isApproved: boolean;
  shortName: string;
  clientId: string;
  mainDomainName: string;
  scopeNames: string[];
  defaultOauthCallbackUrl: string;
  eventCallbackUrl: string;
};

// These classes are temporarily used to connect to JS functions rendered by PHP.
const helperClasses = {
  details: 'm-settings--api--app--view-btn',
  edit: 'm-settings--api--app--edit-btn',
  delete: 'm-settings--api--app--delete-btn',
  revoke: 'm-settings--api--app--revoke-btn',
  row: `m-settings--api--apps--row ${styles.apiApp}`,
};

function ThirdPartyApp({
  shortName,
  mainDomainName,
  scopeNames,
  clientId,
}: ApiAppProps) {
  const preventDefaultAction = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    evt.preventDefault();
  };
  const intl = useIntl();

  // Attribute is temporarily used to connect to JS functions rendered by PHP.
  const customAttribs = {
    client_id: `deleteAppButton_${clientId}`,
  };

  return (
    <Split className={helperClasses.row} {...customAttribs}>
      <Split.Item marginRight="auto">
        <Stack tagName="ul" gap="Micro Small">
          <li>
            <Text isBold>{shortName}</Text>
          </li>
          <li>
            <Text data-qa-ref="account-app-third-party-domain-data">
              <FormattedMessage
                id=""
                defaultMessage="Domain: {domainName}"
                description="label, domain name"
                values={{
                  // TODO: I don't think should be using the primary color here
                  // because it makes it look like a clickable link. Leaving it
                  // for now but defining it inline to make it easier to change later.
                  domainName: (
                    <span style={{ color: 'var(--dig-color__primary__base)' }}>
                      {mainDomainName}
                    </span>
                  ),
                }}
              />
            </Text>
          </li>
          <li>
            <Text data-qa-ref="account-app-third-party-scope-button">
              <FormattedMessage
                id=""
                defaultMessage="Scopes: {preformattedList}"
                description="Label, describes app resources third party is allowed to access"
                values={{
                  preformattedList: intl.formatList(scopeNames, {
                    type: 'conjunction',
                    style: 'narrow',
                  }),
                }}
              />
            </Text>
          </li>
        </Stack>
      </Split.Item>
      <Split.Item>
        <div className={styles.actions}>
          {/* span is needed so that the click handler for showing details (from PHP) is attached */}
          <span className={helperClasses.revoke}>
            <Button
              onClick={preventDefaultAction}
              rel={customAttribs.client_id}
              variant="opacity"
              data-qa-ref="account-app-third-party-remove-button"
            >
              <FormattedMessage
                id=""
                defaultMessage="Remove"
                description="button, settings page, button to remove applications  the user had previous activated"
              />
            </Button>
          </span>
        </div>
      </Split.Item>
    </Split>
  );
}

export function ThirdPartyApps({ apps }: ThirdPartyAppsProps) {
  return (
    <ApiSettingsSection
      title={
        <FormattedMessage
          id=""
          description="settings page title for a list of third party apps that user has granted access to their account"
          defaultMessage="Third-party applications"
        />
      }
      description={
        <FormattedMessage
          id=""
          defaultMessage="Apps that you have authorized to perform actions on {brandName} on your behalf"
          description="text describing what Third-party applications section is for"
          values={{
            brandName: getBrandName('S'),
          }}
        />
      }
    >
      <Stack gap="Macro Small">
        {apps.map((app: ApiAppProps) => {
          return <ThirdPartyApp key={app.clientId} {...app} />;
        })}
      </Stack>
    </ApiSettingsSection>
  );
}
