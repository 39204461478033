/* eslint-disable import/no-commonjs */
import React, { Component } from 'react';
import Logger from 'common/logger';
import pushAnalyticsEvent from 'common/utils/pushAnalyticsEvent';
import { getTrackingVariant } from 'common/utils/getTrackingVariant';
import CollectInfoModal from './collect-info-modal';

const PLAN_ABBREVIATION = {
  Business: 'b5',
  'Business 10': 'b10',
};

class CollectInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { show: true };
    this.hide = this.hide.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  componentDidMount() {
    Logger.track('CollectInfoModal.loaded');
  }

  hide() {
    this.setState({
      show: false,
    });
  }

  onSuccess() {
    const { plan = '' } = this.props;
    pushAnalyticsEvent({
      category: 'signup',
      action: `signature profile created - ${getTrackingVariant()}`,
      label: PLAN_ABBREVIATION[plan] || plan.toLowerCase(),
    });

    if (this.props.shouldExplainAfterClose) {
      let redirect = '/home/chooseSignMethod?explain=1';
      if (this.props.isEnterprise) {
        redirect += '&enterprise=1';
      }
      window.location = redirect;
    } else {
      this.hide();
    }
  }

  render() {
    let modal;
    if (this.state.show) {
      modal = <CollectInfoModal {...this.props} onSuccess={this.onSuccess} />;
    }

    return <div className="m-collect-info">{modal}</div>;
  }
}

module.exports = CollectInfo;
