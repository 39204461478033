import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RadioButton } from '@dropbox/dig-components/controls';
import { Text } from '@dropbox/dig-components/typography';
import { ReasonProps, values } from './types';
import { Button } from '@dropbox/dig-components/buttons';
import styles from '../survey.module.scss';
import { getBrandName } from 'js/sign-components/common/brand';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

function CancelReasonTechnical({ siteCode, ...props }: ReasonProps) {
  const supportLink = React.useMemo(() => {
    switch (siteCode) {
      case 'F':
        // Use of literal "HelloFax" here is acceptable. Questions? #ask-hs-frontend.
        // eslint-disable-next-line no-restricted-syntax
        return HfReactHelper.getFaqUrl('categories/200353237-HelloFax');
      default:
        return HfReactHelper.getFaqUrl('');
    }
  }, [siteCode]);

  return (
    <div className={styles.reason}>
      <div className={styles.reasonGroup}>
        <RadioButton
          {...props}
          data-qa-ref="cancel-subscription-survey-reason-technical"
          id="cancel-subscription-survey__radio-technical"
          name="reason"
          value={values.TECHNICAL}
        />
        <div className={styles.contextContainer}>
          <Text
            tagName="label"
            htmlFor="cancel-subscription-survey__radio-technical"
          >
            <FormattedMessage
              id=""
              description="An option the user can select when explaining why s/he is canceling their subscription."
              defaultMessage="I'm having technical issues with {brandName}"
              values={{
                brandName: getBrandName(siteCode),
              }}
            />
          </Text>
          {props.checked && (
            <div className={styles.reasonContext}>
              <Button
                data-qa-ref="cancel-subscription-survey-get-support-btn"
                variant="outline"
                href={supportLink}
                className={styles.supportButton}
              >
                <FormattedMessage
                  id=""
                  description="An option the user can select when explaining why s/he is canceling their subscription."
                  defaultMessage="Contact Us"
                />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CancelReasonTechnical;
