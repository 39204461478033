import React from 'react';
import '../checkbox.scss';

const DisabledIndeterminate = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
    <g
      id="input-/-checkbox-/-indeterminate"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect
        id="Box"
        fillOpacity="0.100000001"
        fill="#00394D"
        x="0"
        y="0"
        width="16"
        height="16"
        rx="2"
      ></rect>
      <path
        d="M5,8 L11,8"
        id="Line"
        stroke="#959CA4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </svg>
);

export default React.memo(DisabledIndeterminate);
