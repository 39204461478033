/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  no-console,
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './dropbox-signin-button.scss';
import { queryParams } from 'js/sign-components/common/fetch';
import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { DropboxLine } from '@dropbox/dig-icons/assets';

class DropboxSignInButton extends React.Component {
  static propTypes = {
    redirectUrl: PropTypes.string,
    isSignup: PropTypes.bool,
    buttonAttrs: PropTypes.object,
    siteCodeString: PropTypes.string,
    rememberMe: PropTypes.bool,
  };

  onSignIn = () => {
    const { redirectUrl } = this.props;
    const qs = queryParams({
      on_login_redirect_url: redirectUrl || null,
      utm_source: 'dropbox-signin',
      should_remember_me: this.props.rememberMe,
    });
    const authUrl = `/account/dropboxLogin${qs}`;
    window.location.replace(authUrl);
  };

  render() {
    const btnText = this.props.isSignup ? (
      <FormattedMessage
        id=""
        description="sign up with Dropbox button"
        defaultMessage="Sign up with Dropbox"
      />
    ) : (
      <FormattedMessage
        id=""
        description="sign in with Dropbox button"
        defaultMessage="Sign in with Dropbox"
      />
    );

    return (
      <Button
        type="button"
        variant="outline"
        size="large"
        withIconLeft={<UIIcon src={DropboxLine} />}
        onClick={this.onSignIn}
        data-testid="dropbox-button"
        da-category={this.props.buttonAttrs?.['da-category'] || 'signup'}
        da-action={
          this.props.buttonAttrs?.['da-action'] ||
          `create account inapp - ${this.props.siteCodeString}`
        }
        da-label={this.props.buttonAttrs?.['da-label'] || 'sign in with dbx'}
      >
        {btnText}
      </Button>
    );
  }
}

export default function DropboxSignInButtonWrapper(props) {
  return <DropboxSignInButton {...props} />;
}
