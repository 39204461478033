import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import styles from './index.scss';
import { getBrandName } from 'js/sign-components/common/brand';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

type EventLike = {
  target: {
    value: string;
  };
};
type props = {
  isMonthly?: boolean;
  isSwitchToAnnual?: boolean;
  onChange: (evt: EventLike) => void;
};

const IAgreeCheckbox: React.FunctionComponent<props> = ({
  isMonthly,
  onChange,
  isSwitchToAnnual,
}) => {
  const agreeLabelMessage = React.useMemo(() => {
    if (isSwitchToAnnual) {
      return (
        <FormattedMessage
          id=""
          description="'I agree' legal text for a payment form."
          defaultMessage="I agree to the <l1>{brandName} Terms</l1>, <l2>Privacy Policy</l2>, <l3>Refund Policy</l3>, and <b>Automatic Renewal Terms</b> above."
          values={{
            brandName: getBrandName('S'),
            /* eslint-disable react/display-name, react/jsx-no-target-blank */
            b: (...chunks: any[]) => <strong>{chunks}</strong>,
            l1: (...chunks: any[]) => (
              <a href={HfReactHelper.getWebflowUrl('terms')} target="_blank">
                {chunks}
              </a>
            ),
            l2: (...chunks: any[]) => (
              <a href={HfReactHelper.getWebflowUrl('privacy')} target="_blank">
                {chunks}
              </a>
            ),
            l3: (...chunks: any[]) => (
              <a
                href={HfReactHelper.getWebflowUrl('legal/tos#cancel_account')}
                target="_blank"
              >
                {chunks}
              </a>
            ),
            /* eslint-enable react/display-name, react/jsx-no-target-blank */
          }}
        />
      );
    } else if (isMonthly) {
      return (
        <FormattedMessage
          id=""
          description="Label for a checkbox authorizing app to charge the user's credit card."
          defaultMessage="I agree to allow {brandName} to charge my credit card annually."
          values={{
            brandName: getBrandName('S'),
          }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id=""
          description="Label for a checkbox authorizing app to charge the user's credit card."
          defaultMessage="I agree to allow {brandName} to charge my credit card monthly."
          values={{
            brandName: getBrandName('S'),
          }}
        />
      );
    }
  }, [isSwitchToAnnual, isMonthly]);

  return (
    <div
      className={classNames(styles.switchBillingPeriodModalAgreeContainer, {
        [styles.marginTop]: !isSwitchToAnnual,
      })}
    >
      <input
        id="switchBillingPeriodModalAgreeCheckbox"
        type="checkbox"
        onChange={onChange}
      />
      <label
        className={styles.switchBillingPeriodModalAgreeLabel}
        htmlFor="switchBillingPeriodModalAgreeCheckbox"
      >
        {agreeLabelMessage}
      </label>
    </div>
  );
};

export default IAgreeCheckbox;
