import React from 'react';
import classnames from 'classnames';
import * as Yup from 'yup';
import { useAuthentication } from 'signer-app/authentication/use-authentication';
import {
  TextInput,
  TextInputRefObject,
} from '@dropbox/dig-components/text_fields';
import {
  FormHelperText,
  FormRow,
  FormLabel,
} from '@dropbox/dig-components/form_row';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Link, Text, Title } from '@dropbox/dig-components/typography';
import { useArkoseIntercept } from 'signer-app/authentication/use-arkose-intercept';
import { Checkbox } from '@dropbox/dig-components/controls';
import { OrDivider } from 'hello-react/components/or-divider';

import { UIIcon } from '@dropbox/dig-icons';
import { HideLine, ShowLine, FailLine } from '@dropbox/dig-icons/assets';

import styles from './authentication.module.scss';
import { SignInButtons } from './sign-in-buttons';
import { useOnLoginRedirectUrl } from './use-on-login-redirect-url';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import { useFeatureFlag } from 'js/sign-components/common/feature-flag';
import ProgressiveCheckoutSignInForm from './progressive-checkout-sign-in-form';

const messages = defineMessages({
  showPassword: {
    id: '',
    description:
      "Hover text for a button which will show the user's password in plaintext when pressed.",
    defaultMessage: 'Show password',
  },
  hidePassword: {
    id: '',
    description:
      "Hover text for a button which will hide the user's password when pressed.",
    defaultMessage: 'Hide password',
  },
  error: {
    id: '',
    description: 'Error message',
    defaultMessage: 'Error',
  },
});

type SignUpFormProps = {
  hideCreateAccountLink?: boolean; // default false
  redirectUrl?: string;
};

const yupEmail = Yup.string().email();
export default function SignInForm({
  hideCreateAccountLink = false,
  redirectUrl,
}: SignUpFormProps): React.ReactElement | null {
  const intl = useIntl();
  const isProgressiveCheckout = useFeatureFlag(
    'sign_services_20240325_progressive_checkout',
  );

  const {
    state,
    update,
    meta,
    signIn,
    dropboxManagedAndSamlCheck,
    getDropboxManagedLoginLink,
    queryParams,
  } = useAuthentication();
  const passwordRef = React.useRef<TextInputRefObject>(null);
  const passwordSelectionRangeRef = React.useRef<
    [number | null, number | null] | null
  >(null);
  const {
    dropboxManagedAndSamlLoginChecked,
    isSamlLogin,
    email,
    password,
    isLoading,
  } = state;
  const [isPasswordVisible, togglePasswordVisible] = React.useReducer(
    (v) => !v,
    false,
  );

  const signUpUrl = useOnLoginRedirectUrl('/account/signUp');
  const isPasswordFieldHidden =
    !dropboxManagedAndSamlLoginChecked || isSamlLogin;

  const [isEmailInvalid, setIsEmailInvalid] = React.useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = React.useState(false);

  const arkoseSubmit = useArkoseIntercept(
    ({ arkoseToken }) => signIn({ arkoseToken }),
    email,
    meta?.arkoseEnabled,
    meta?.arkoseSignInPublicKey,
  );

  const submitForm = async () => {
    // Exit early if loading.
    if (isLoading) return;

    // Validate email
    if (!state.email.length || !yupEmail.isValidSync(email)) {
      setIsEmailInvalid(true);
      return;
    }

    // Check user can log in using this form if email is valid
    if (!dropboxManagedAndSamlLoginChecked && email.length > 0) {
      await dropboxManagedAndSamlCheck({});
      return;
    }

    // Check password
    if (!password || !password.length) {
      setIsPasswordInvalid(true);
      return;
    }

    if (isSamlLogin) {
      return;
      // User not allowed to continue. Use SAML provider.
    }

    return arkoseSubmit();
  };

  const handleSubmit: React.HTMLAttributes<HTMLFormElement>['onSubmit'] = (
    evt,
  ) => {
    evt.preventDefault();

    return submitForm();
  };

  const focusPasswordField = React.useCallback(() => {
    // We have to wrap this in a setTimeout(0), so that
    // we run the focus() code at the tail end of the
    // JavaScript event loop. This will give React time
    // to do what it needs to do before we try to focus it.
    setTimeout(() => {
      if (passwordRef.current) {
        const input = passwordRef.current;

        if (passwordSelectionRangeRef.current) {
          input.setSelectionRange(...passwordSelectionRangeRef.current);
        } else {
          const len = input.value.length;
          input.setSelectionRange(len, len);
        }

        input.focus();
      }
    }, 0);
  }, []);

  // Automatically focus on the password field after
  // SAML login is checked and no SAML provider was
  // found. Also, re-focus password field when visibility
  // toggle is clicked.
  React.useEffect(() => {
    if (dropboxManagedAndSamlLoginChecked && !isSamlLogin) {
      focusPasswordField();
    }
  }, [
    dropboxManagedAndSamlLoginChecked,
    isSamlLogin,
    isPasswordVisible,
    focusPasswordField,
  ]);

  if (!meta) {
    // loading
    return null;
  }

  if (isProgressiveCheckout) {
    return (
      <ProgressiveCheckoutSignInForm
        isEmailInvalid={isEmailInvalid}
        setIsEmailInvalid={setIsEmailInvalid}
        handleSubmit={handleSubmit}
        passwordSelectionRangeRef={passwordSelectionRangeRef}
        passwordRef={passwordRef}
        isPasswordInvalid={isPasswordInvalid}
        setIsPasswordInvalid={setIsPasswordInvalid}
        isPasswordVisible={isPasswordVisible}
        togglePasswordVisible={togglePasswordVisible}
        isPasswordFieldHidden={isPasswordFieldHidden}
        submitForm={submitForm}
        meta={meta}
        signUpUrl={signUpUrl}
        update={update}
        dropboxManagedAndSamlLoginChecked={dropboxManagedAndSamlLoginChecked}
        isSamlLogin={isSamlLogin}
        email={email}
        password={password}
        isLoading={isLoading}
        rememberMe={state.rememberMe}
        showDMASnackbar={state.showDMASnackbar}
        isOfficeIntegration={state.isOfficeIntegration}
      />
    );
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <FormRow>
        <div className={styles.header}>
          <Title tagName="h1" className={styles.headerTitle}>
            <FormattedMessage
              id=""
              description="Title for the sign in page"
              defaultMessage="Sign in"
            />
          </Title>
          {!hideCreateAccountLink && !meta.hubspotVersion && (
            <Text className={styles.headerAside}>
              <FormattedMessage
                id=""
                description="link to the sign up form"
                defaultMessage="or <a>create an account</a>"
                values={{
                  a(chunks: any) {
                    return (
                      <Link
                        data-testid="sign-up"
                        // Preserve URL params when navigating between
                        // login and signup pages.
                        href={signUpUrl}
                      >
                        {chunks}
                      </Link>
                    );
                  },
                }}
              />
            </Text>
          )}
        </div>
      </FormRow>

      {!state.isOfficeIntegration && (
        <>
          <FormRow>
            <SignInButtons
              googleSignInClientId={meta.googleSignInClientId}
              redirectUrl={
                redirectUrl ||
                meta.redirectUrl ||
                queryParams.on_login_redirect_url
              }
              isApi={false}
              formType={'signin'}
              googleAnalyticsTrackingVariant={''}
              planName={undefined}
            />
          </FormRow>
          <FormRow>
            <OrDivider />
          </FormRow>
        </>
      )}

      <FormRow>
        <FormLabel htmlFor="email-field">
          <FormattedMessage
            id=""
            description="Label for a text input field"
            defaultMessage="Email address"
          />
        </FormLabel>
        <div className={styles.inputButtonContainer}>
          <TextInput
            autoFocus
            formNoValidate
            name="email"
            type="email"
            id="email-field"
            data-testid="email-field"
            value={state.email}
            isInvalid={isEmailInvalid || state.showDMASnackbar}
            aria-invalid={isEmailInvalid || state.showDMASnackbar}
            aria-errormessage="email-helper-text"
            aria-describedby="email-helper-text"
            onChange={(e) => {
              setIsEmailInvalid(false);
              setIsPasswordInvalid(false);
              update('email', e.target.value);

              if (state.dropboxManagedAndSamlLoginChecked) {
                update('dropboxManagedAndSamlLoginChecked', false);
              }
            }}
          />
        </div>
        {(isEmailInvalid || state.showDMASnackbar) && (
          <FormHelperText id="email-helper-text" variant="alert">
            <UIIcon
              src={FailLine}
              size="small"
              aria-label={intl.formatMessage(messages.error)}
            />
            {state.showDMASnackbar ? (
              <FormattedMessage
                id=""
                description="Error message on the sign up page that asks user to use Dropbox log in method"
                defaultMessage="There’s already a Dropbox account with this email. Continue with Dropbox."
              />
            ) : (
              <FormattedMessage
                id=""
                description="Error message on the sign up page"
                defaultMessage="Please enter a valid email address"
              />
            )}
          </FormHelperText>
        )}
      </FormRow>

      {dropboxManagedAndSamlLoginChecked && isSamlLogin && (
        <FormRow>
          <Text size="small" color="faint" role="alert">
            <FormattedMessage
              id=""
              description="Notice informing the user they are being redirect to their companie's SAML login provider."
              defaultMessage="Redirecting you to your SAML login provider…"
            />
          </Text>
        </FormRow>
      )}

      <div
        aria-hidden={isPasswordFieldHidden}
        className={classnames({
          [styles.hidden]: isPasswordFieldHidden,
        })}
      >
        <FormRow>
          <FormLabel htmlFor="password-field">
            <FormattedMessage
              id=""
              description="Label for a text input field"
              defaultMessage="Password"
            />
          </FormLabel>
          <div className={styles.passwordInputContainer}>
            <TextInput
              autoFocus
              formNoValidate
              ref={passwordRef}
              name="password"
              id="password-field"
              data-testid="password-field"
              className={styles.passwordInput}
              type={isPasswordVisible ? 'text' : 'password'}
              value={state.password ?? ''}
              aria-describedby="password-helper-text"
              aria-errormessage="password-error"
              aria-invalid={isPasswordInvalid}
              isInvalid={isPasswordInvalid}
              minLength={1}
              // Apply tab-index="-1" only if the password is hidden
              {...(isPasswordFieldHidden && { tabIndex: -1 })}
              // Do NOT disable this input if isPasswordFieldHidden is true.
              // 1Password won't work with disabled fields (though, Dropbox Passwords will).
              // disabled={state.isLoading || isPasswordFieldHidden}
              disabled={state.isLoading}
              withRightAccessory={
                <IconButton
                  type="button"
                  size="small"
                  data-testid={
                    isPasswordVisible
                      ? 'hide-password-btn'
                      : 'show-password-btn'
                  }
                  title={intl.formatMessage(
                    isPasswordVisible
                      ? messages.hidePassword
                      : messages.showPassword,
                  )}
                  variant="transparent"
                  onClick={togglePasswordVisible}
                  // Apply tab-index="-1" only if the password is hidden
                  {...(isPasswordFieldHidden && { tabIndex: -1 })}
                >
                  <UIIcon
                    src={isPasswordVisible ? HideLine : ShowLine}
                    role="presentation"
                  />
                </IconButton>
              }
              onBlur={(evt: React.SyntheticEvent<HTMLInputElement>) => {
                // Cache caret position so that we select the same range when
                // user toggles password visibility.
                const { selectionStart, selectionEnd } = evt.currentTarget;
                passwordSelectionRangeRef.current = [
                  selectionStart,
                  selectionEnd,
                ];
              }}
              onChange={(e) => {
                setIsPasswordInvalid(false);
                update('error', undefined);
                update('password', e.target.value);

                // A password manager like 1Password might be
                // autofilling this form for the user, even
                // though it's not visible. If we notice this
                // form is being auto-filled, perform a quick
                // SAML login check in order to make the password
                // field visible, or redirect to the user's SAML
                // provider automatically, if applicable.
                //
                // We also need to make sure the length >0 because
                // when the user clicks the "modify email" button,
                // we reset the password back to "". This might
                // trigger a submit if we don't check the length.
                if (
                  !dropboxManagedAndSamlLoginChecked &&
                  e.target.value.length > 0
                ) {
                  submitForm();
                }
              }}
            />
          </div>
          <FormHelperText
            id="password-helper-text"
            variant={isPasswordInvalid ? 'alert' : 'default'}
          >
            {isPasswordInvalid && (
              <span id="password-error" className={styles.passwordError}>
                <UIIcon
                  src={FailLine}
                  size="small"
                  aria-label={intl.formatMessage(messages.error)}
                />
                <FormattedMessage
                  id=""
                  description="Error message on the sign up page"
                  defaultMessage="Please enter a valid password."
                />
                &nbsp;
              </span>
            )}
            <Link
              href="/account/recoverPassword"
              data-testid="forgot-password-link"
              // Apply tab-index="-1" only if the password is hidden
              {...(isPasswordFieldHidden && { tabIndex: -1 })}
              onClick={() => {
                // Save email to sessionStorage for prefill on recover password page
                // (Only if email is not null, since sessionStorage casts that to "null")
                if (email) {
                  try {
                    sessionStorage.setItem('email', email);
                  } catch (err) {
                    // User may be using private browsing.
                    // Fail silently.
                  }
                }
              }}
            >
              <FormattedMessage
                id=""
                description="Link to forgot password underneath password text field"
                defaultMessage="Forgot your password?"
              />
            </Link>
          </FormHelperText>
        </FormRow>
      </div>

      <div className={styles.rememberMeAndContinueBtn}>
        <div className={styles.checkboxGroup}>
          <Checkbox
            id="remember-me-checkbox"
            data-testid="remember-me-checkbox"
            onChange={() => update('rememberMe', !state.rememberMe)}
            checked={state.rememberMe}
          />
          <Text tagName="label" htmlFor="remember-me-checkbox">
            <FormattedMessage
              id=""
              description="Label for a sign in 'Remember me' checkbox"
              defaultMessage="Remember me"
            />
          </Text>
        </div>
        {/* Hide continue/sign in button if user has SAML. */}
        {!isSamlLogin && (
          <Button
            type="submit"
            variant="primary"
            size="large"
            data-testid="continue-or-signin-btn"
            isLoading={isLoading}
          >
            {dropboxManagedAndSamlLoginChecked ? (
              <FormattedMessage
                id=""
                description="Button text for sign in button"
                defaultMessage="Sign in"
              />
            ) : (
              <FormattedMessage
                id=""
                description="Button text"
                defaultMessage="Continue"
              />
            )}
          </Button>
        )}
      </div>
      <Snackbar.Position>
        <Snackbar
          open={state.showDMASnackbar}
          timeout={0}
          role="alert"
          preferComposition
        >
          <Snackbar.Content>
            <Snackbar.Message>
              <FormattedMessage
                id=""
                description="Notification message for existing Dropbox account to login on Dropbox"
                defaultMessage="Looks like there’s already a Dropbox account associated with this email."
              />
            </Snackbar.Message>
            <Snackbar.Actions>
              <Button
                variant="transparent"
                href={getDropboxManagedLoginLink}
                inverse
              >
                <FormattedMessage
                  id=""
                  description="Text for a link to redirect to Dropbox login page"
                  defaultMessage="Continue with Dropbox"
                />
              </Button>
            </Snackbar.Actions>
          </Snackbar.Content>
        </Snackbar>
      </Snackbar.Position>
    </form>
  );
}
