import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import HelloModal from 'common/components/hello-modal';
import hsFetch from 'js/sign-components/common/hs-fetch';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

import IAgreeCheckbox from './i-agree-checkbox';
import styles from './index.scss';

const messages = defineMessages({
  cancel: {
    id: '',
    description: 'Text for a button which cancels the user flow when pressed.',
    defaultMessage: 'Cancel',
  },
  confirmSwitchToAnnual: {
    id: '',
    description:
      "Text for a button which switches the user's subscription to annual billing when pressed.",
    defaultMessage: 'Confirm switch to annual billing?',
  },
  confirmSwitchToMonthly: {
    id: '',
    description:
      "Text for a button which switches the user's subscription to monthly billing when pressed.",
    defaultMessage: 'Confirm switch to monthly billing?',
  },
  switchToAnnual: {
    id: '',
    description:
      'Title text for a modal which gives the user the option to switch to annual vs monthly billing.',
    defaultMessage: 'Switch to annual',
  },
  switchToMonthly: {
    id: '',
    description:
      'Title text for a modal which gives the user the option to switch to annual vs monthly billing.',
    defaultMessage: 'Switch to monthly',
  },
  contactUs: {
    id: '',
    description:
      'Title text for a button which redirects to the support page when pressed.',
    defaultMessage: 'Contact us',
  },
  errorHeader: {
    id: '',
    description: 'Title text for a modal for modal when an error occurs',
    defaultMessage: "Can't switch billing period",
  },
  errorHigher: {
    id: '',
    description:
      'Message noting that the user is unable to switch their billing period and to contact support.',
    defaultMessage:
      'The refund amount is more than the charged amount. Please contact us.',
  },
  errorDifferent: {
    id: '',
    description:
      'Message noting that the user is unable to switch their billing period and to contact support.',
    defaultMessage:
      'Your subscription charge amount is different than the actual charged amount. Please contact us.',
  },
  errorZero: {
    id: '',
    description:
      'Message noting that the user is unable to switch their billing period and to contact support.',
    defaultMessage:
      'The refund amount is zero after trial end. Please contact us.',
  },
  errorUnknown: {
    id: '',
    description:
      'Message noting that the user is unable to switch their billing period and to contact support.',
    defaultMessage: 'Please contact us.',
  },
});

const errorReasons = {
  HIGHER: 'h',
  DIFFERENT: 'd',
  ZERO: 'z',
  UNKNOWN: 'u',
};

const errorMessages = {
  [errorReasons.HIGHER]: messages.errorHigher,
  [errorReasons.DIFFERENT]: messages.errorDifferent,
  [errorReasons.ZERO]: messages.errorZero,
  [errorReasons.UNKNOWN]: messages.errorUnknown,
};

function SwitchBillingPeriodModal({
  isTrialing,
  isMonthly,
  chargeAmount,
  startDate,
  refund,
  planName,
  numTrialDays,
  error,
  csrfToken,
  onRequestClose,
  onSwitchBillingPeriodSuccess,
  isTaxExclusive,
  ...modalProps
}) {
  const intl = useIntl();
  const [userDidAgree, setUserDidAgree] = React.useState(false);
  const [isSwitchingBillingPeriod, setIsSwitchingBillingPeriod] =
    React.useState(false);

  const getHeaderText = React.useMemo(() => {
    if (error) {
      return intl.formatMessage(messages.errorHeader);
    }
    return isMonthly
      ? intl.formatMessage(messages.confirmSwitchToAnnual)
      : intl.formatMessage(messages.confirmSwitchToMonthly);
  }, [intl, error, isMonthly]);

  const onSwitchBillingPeriod = React.useCallback(() => {
    if (error) return;

    const formData = new FormData();
    formData.append('csrf_token', csrfToken);
    formData.append('confirm_switch', userDidAgree ? 1 : 0);
    formData.append('period_type_code', isMonthly ? 'Y' : 'M');

    setIsSwitchingBillingPeriod(true);
    hsFetch('/account/changeSubscriptionPeriod', {
      method: 'POST',
      body: formData,
    }).then((_response) => {
      setIsSwitchingBillingPeriod(false);

      // "Success"
      onSwitchBillingPeriodSuccess();
    });
  }, [csrfToken, isMonthly, error, userDidAgree, onSwitchBillingPeriodSuccess]);

  const onAgreeInputChange = React.useCallback((evt) => {
    setUserDidAgree(evt.target.checked);
  }, []);

  const errorMessage = React.useMemo(() => {
    return error ? intl.formatMessage(errorMessages[error]) : null;
  }, [intl, error]);

  const newChargeMessage = React.useMemo(() => {
    return isTrialing ? (
      <FormattedMessage
        id=""
        description="Text noting the amount and cadence that a user will be charged."
        defaultMessage="You currently have a {numTrialDays}-day {planName} trial and will be charged <b>{chargeAmount}{isTaxExclusive, select, true {, plus any applicable taxes,} other {}}</b> {period, select, m { per month } y { per year }} starting on {startDate, date, long}."
        values={{
          numTrialDays,
          planName,
          chargeAmount,
          startDate,
          period: isMonthly ? 'y' : 'm',
          isTaxExclusive,
          /* eslint-disable-next-line react/display-name */
          b: (...chunks) => <b>{chunks}</b>,
        }}
      />
    ) : (
      <FormattedMessage
        id=""
        description="Text noting the amount and cadence that a user will be charged."
        defaultMessage="You will be charged <b>{chargeAmount}{isTaxExclusive, select, true {, plus any applicable taxes,} other {}}</b> {period, select, m { per month } other { per year }} starting on {startDate, date, long}."
        values={{
          chargeAmount,
          startDate,
          period: isMonthly ? 'y' : 'm',
          isTaxExclusive,
          /* eslint-disable-next-line react/display-name */
          b: (...chunks) => <b>{chunks}</b>,
        }}
      />
    );
  }, [
    isTrialing,
    isMonthly,
    numTrialDays,
    planName,
    chargeAmount,
    startDate,
    isTaxExclusive,
  ]);

  return (
    <HelloModal
      onRequestClose={onRequestClose}
      showCloseIcon={!isSwitchingBillingPeriod}
      showCloseOnEsc={!isSwitchingBillingPeriod}
      contentLabel={getHeaderText}
      buttons={[
        {
          type: 'secondary',
          text: intl.formatMessage(messages.cancel),
          onClick: onRequestClose,
        },
        error
          ? {
              type: 'primary',
              text: intl.formatMessage(messages.contactUs),
              onClick: () => {
                window.open(
                  HfReactHelper.getFaqUrl('requests/new'),
                  '_blank',
                  'noopener noreferrer',
                );
              },
            }
          : {
              type: 'primary',
              disabled: !userDidAgree || isSwitchingBillingPeriod,
              text: isMonthly
                ? intl.formatMessage(messages.switchToAnnual)
                : intl.formatMessage(messages.switchToMonthly),
              onClick: onSwitchBillingPeriod,
            },
      ]}
      {...modalProps}
    >
      {!error ? (
        <React.Fragment>
          <p className="switch-billing-period-modal__new-charge">
            {newChargeMessage}
          </p>
          {refund && (
            <p className={styles.switchBillingPeriodModalRefund}>
              <FormattedMessage
                id=""
                description="info message, pop-up, informs user about refund amount and processing time"
                defaultMessage="You will be refunded <b>{refund}, plus relevant taxes</b>, the prorated annual amount you already paid. Depending on your bank's processing time, it can take up to 10 business days for the refund to show up on your bank account."
                values={{
                  refund,
                  /* eslint-disable-next-line react/display-name */
                  b: (...chunks) => <b>{chunks}</b>,
                }}
              />
            </p>
          )}
          <IAgreeCheckbox isMonthly={isMonthly} onChange={onAgreeInputChange} />
        </React.Fragment>
      ) : (
        <p>{errorMessage}</p>
      )}
    </HelloModal>
  );
}

export default SwitchBillingPeriodModal;
