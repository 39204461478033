import React from 'react';
import PropTypes from 'prop-types';
import baseform from 'common/components/base-form';
import hsFetch from 'js/sign-components/common/hs-fetch';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { queryParams } from 'js/sign-components/common/fetch';
import throbberUrl from './transparent-throbber.gif';

const messages = defineMessages({
  selectOption: {
    id: '',
    description: 'default option for state dropdown in payment modal',
    defaultMessage: 'Select',
  },
});

const HfPaymentForm = baseform.createFormClass({
  countryCA: '38', // from VoxboneWrapper
  countryUK: '224',
  countryUS: '225',

  propTypes: {
    fields: PropTypes.array,
    values: PropTypes.object,
    tabOffset: PropTypes.number,
  },

  getInitialState() {
    return {
      fieldMap: this.mapFieldsToNames(),
      selectedCountry: this.countryUS,
      selectedState: '-',
      selectedArea: '-',
      areaOptions: null,
      showState: true,
      showArea: false,
    };
  },

  componentDidMount() {
    this.props.getHFformData({
      isCountryUk: this.countryUK === this.state.selectedCountry,
    });
  },

  // Creates a named map of field names so you can find by name, instead of just array index
  mapFieldsToNames() {
    if (!this.props.fields || !this.props.fields.length) return {};

    return this.props.fields.reduce((map, field) => {
      if (field.type !== 'inputhidden') {
        map[field.name] = field;
      }
      return map;
    }, {});
  },

  getThrobber() {
    if (this.state.selectedState !== '-') {
      return (
        <img
          src={throbberUrl}
          className="m-payment-throbber"
          width="16"
          height="16"
        />
      );
    }
  },

  async getAreaCodes(url) {
    const { intl } = this.props;
    const response = await hsFetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      if (data === null || data.length === 0) {
        this.props.getHFformData({ hasAreaCode: false });
      } else {
        const options = data.area_codes.map((item) => {
          return (
            <option
              key={`option-value-${item.area_code}`}
              value={item.area_code}
            >
              {item.area_code}
            </option>
          );
        });
        options.unshift(
          <option key="-" value="-">
            --{intl.formatMessage(messages.selectOption)}--
          </option>,
        );
        this.setState({ areaOptions: options });
      }
    } else {
      this.props.getHFformData({ areaCodeCallSuccess: false });
    }
  },

  onSelectArea(evt) {
    this.setState({ selectedArea: evt.target.value });
    this.props.getHFformData({ selectedArea: evt.target.value });
  },

  onSelectState(evt) {
    if (evt.target.value === '-') {
      this.setState({ showArea: false, selectedState: '-', selectedArea: '-' });
    } else {
      this.setState({
        selectedState: evt.target.value,
        showArea: true,
        selectedArea: '-',
        areaOptions: null,
      });
      const url = `/content/asyncGetAreaCodes${queryParams({
        country_id: this.state.selectedCountry,
        state: evt.target.value,
      })}`;
      this.getAreaCodes(url);
    }
  },

  onSelectCountry(evt) {
    if (evt.target.value === this.countryUK) {
      this.setState({
        showState: false,
        showArea: true,
        selectedCountry: this.countryUK,
        selectedState: '',
        selectedArea: '-',
        areaOptions: null,
      });
      const url = `/content/asyncGetAreaCodesWithCity${queryParams({
        country_id: this.state.selectedCountry,
      })}`;
      this.getAreaCodes(url);
      this.props.getHFformData({ isCountryUk: true });
    } else {
      this.setState({
        showState: true,
        showArea: false,
        selectedState: '-',
        selectedArea: '-',
      });
      if (evt.target.value === this.countryCA) {
        this.setState({ selectedCountry: this.countryCA });
      } else {
        this.setState({ selectedCountry: this.countryUS });
      }
      this.props.getHFformData({ isCountryUk: false });
    }
  },

  render() {
    const { tabOffset, cardSuffix } = this.props;
    return (
      <>
        <div className="row mt20">
          <div className="m-payment--label columns small-12 text-left">
            <p>
              <FormattedMessage
                id=""
                description="modal title in pricing page, asking user to select fax area code"
                defaultMessage="Select a fax number"
              />
            </p>
          </div>
        </div>
        <div className="row m-payment--wrapper">
          <div className="columns small-12 large-12">
            <div className="row">
              <div className="m-payment--billing-string columns small-6 text-left">
                <p>
                  <FormattedMessage
                    id=""
                    description="input label in pricing page, asking user to select users country"
                    defaultMessage="Country"
                  />
                </p>
              </div>
              <div className="columns small-6">
                {this.renderField(this.state.fieldMap.country.name, {
                  className: 'payment--register-fax-line--country',
                  tabIndex: tabOffset,
                  placeholder: this.state.fieldMap.country.options.label,
                  'data-format': 'select',
                  onChange: this.onSelectCountry,
                  value: this.state.selectedCountry,
                  'data-qa-ref': 'register-fax-line-country',
                })}
              </div>
              {this.state.showState && (
                <>
                  <div className="m-payment--billing-string columns small-6 text-left">
                    <p>
                      <FormattedMessage
                        id=""
                        description="input label in pricing page, asking user to select users state"
                        defaultMessage="State/Province"
                      />
                    </p>
                  </div>
                  <div className="columns small-6">
                    {this.renderField(
                      this.state.selectedCountry === this.countryUS
                        ? this.state.fieldMap.state.name
                        : this.state.fieldMap.ca_province.name,
                      {
                        className: 'payment--register-fax-line--state',
                        tabIndex: tabOffset + 1,
                        placeholder: this.state.fieldMap.state.options.label,
                        'data-format': 'select',
                        onChange: this.onSelectState,
                        value: this.state.selectedState,
                        'data-qa-ref': 'register-fax-line-state',
                      },
                    )}
                  </div>
                </>
              )}
              {this.state.showArea && this.state.areaOptions !== null ? (
                <>
                  <div className="m-payment--billing-string columns small-6 text-left">
                    <p>
                      <FormattedMessage
                        id=""
                        description="input label in pricing page, asking user to select users area code"
                        defaultMessage="Area"
                      />
                    </p>
                  </div>
                  <div className="columns small-6">
                    <select
                      className="payment--register-fax-line--area"
                      tabIndex={
                        this.state.selectedCountry === this.countryUK
                          ? tabOffset + 1
                          : tabOffset + 2
                      }
                      data-format="select"
                      name="register_fax_line[area_code]"
                      onChange={this.onSelectArea}
                      data-qa-ref="register-fax-line-area"
                      data-testid="register-fax-line-area"
                    >
                      {this.state.areaOptions.map((option) => option)}
                    </select>
                  </div>
                </>
              ) : (
                <div className="columns small-6">{this.getThrobber()}</div>
              )}
            </div>
          </div>
        </div>
        <div className="row mt20">
          <div className="m-payment--billing-string columns small-12 text-left">
            <p>
              <span>
                <FormattedMessage
                  id=""
                  description="text in payment modal, informing user that fax number will be based on user selected area code"
                  defaultMessage="We will assign you a number in the area code you select."
                />
              </span>
            </p>
            {!cardSuffix && <hr />}
          </div>
        </div>
      </>
    );
  },
});

export default injectIntl(HfPaymentForm);
