import React from 'react';
import classnames from 'classnames';
import styles from './icons.scss';

const TwitterLogo = (props) => (
  <svg
    {...props}
    viewBox="0 0 20 20"
    className={classnames(styles.icon, props.className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7706 7.63682C15.7758 7.7495 15.778 7.86275 15.778 7.97662C15.778 11.4496 13.1837 15.4549 8.43953 15.4549C6.98271 15.4549 5.6274 15.0198 4.48592 14.2744C4.68781 14.2986 4.89308 14.3107 5.10116 14.3107C6.30958 14.3107 7.42165 13.8906 8.30457 13.1856C7.1755 13.1642 6.22344 12.4043 5.89507 11.3601C6.05212 11.3907 6.21374 11.4075 6.37993 11.4075C6.61527 11.4075 6.84321 11.3751 7.05984 11.315C5.87979 11.074 4.99058 10.0113 4.99058 8.73826C4.99058 8.72673 4.99058 8.71571 4.99119 8.70474C5.33876 8.90183 5.73627 9.02025 6.15933 9.03357C5.46694 8.56259 5.01155 7.7576 5.01155 6.84571C5.01155 6.36438 5.13918 5.91297 5.36089 5.52464C6.63282 7.115 8.53367 8.16099 10.6772 8.27076C10.6329 8.07833 10.6103 7.87777 10.6103 7.67151C10.6103 6.22043 11.7654 5.04331 13.1899 5.04331C13.9316 5.04331 14.6019 5.36286 15.0726 5.87377C15.6601 5.7558 16.2118 5.53684 16.7103 5.23578C16.5175 5.84942 16.1086 6.36438 15.5761 6.68967C16.0978 6.62608 16.5951 6.48506 17.0574 6.27588C16.712 6.80297 16.2742 7.26586 15.7706 7.63682ZM18.3144 0H3.22865C1.84005 0 0.714355 1.14712 0.714355 2.56224V17.9357C0.714355 19.3508 1.84005 20.498 3.22865 20.498H18.3144C19.703 20.498 20.8287 19.3508 20.8287 17.9357V2.56224C20.8287 1.14712 19.703 0 18.3144 0Z"
      fill="#212121"
    />
  </svg>
);

export default React.memo(TwitterLogo);
