import { SiteCodeType } from 'js/sign-components/common/brand';

export type PauseCancelModalContext = {
  planToCancel: string;
  planName: string;
  totalCharge: string;
  isMonthly: boolean;
  freePlanName: string;
  showPauseCancelFlow: boolean;
  siteCode: SiteCodeType;
  pauseDate: string;
  unpauseDate: string;
  nextChargeDate: string;
  currencyCode: string;
};

export type SurveySubmitBody = {
  reason: string;
  planToCancel: string;
  context?: string;
};

export enum SafeDowngradeStatusEnum {
  PAUSED = 'P', // paused
  PENDING_PAUSE = 'O', // pending pause
}
