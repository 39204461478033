import React from 'react';
import { Link, Text } from '@dropbox/dig-components/typography';

export const bold = (...chunks: any[]) => {
  return <Text isBold>{chunks}</Text>;
};

export const link = (chunks: any[], link: string) => {
  return (
    <Link href={link} target="_blank">
      {chunks}
    </Link>
  );
};
