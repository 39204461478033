import { SiteCodeType } from 'js/sign-components/common/brand';

export type ReasonProps = {
  siteCode: SiteCodeType;
  disabled: boolean;
  checked: boolean;
  contextRef: React.Ref<HTMLTextAreaElement>;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
};

export enum values {
  COST = 'cost',
  TEMPORARY = 'temporary',
  ALTERNATIVE = 'alternative',
  FEATURES = 'features',
  TECHNICAL = 'technical',
  OTHER = 'other',
}
