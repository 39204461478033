/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  func-names,
  import/no-extraneous-dependencies,
  max-len,
  react/no-find-dom-node
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const FillEmptySpace = createReactClass({
  propTypes: {
    footerClass: PropTypes.string, // NOTE: If another React component is leveraging this component, include a unique key for each generated message. See MultiSelect for more info.
  },

  expandSpace() {
    const footerClass = this.props.footerClass || '.m-footer';

    const thisNode = $(ReactDOM.findDOMNode(this));
    const windowHeight = $(window).height();
    const componentYPos = thisNode.offset().top;
    const footerHeight = $(footerClass).outerHeight();
    const twitterHeight = $('.m-footer') && $('.m-twitter').outerHeight(); // this should probably be passed in as a prop for more flexibility like the line above
    const spaceToFill =
      windowHeight - componentYPos - footerHeight - twitterHeight - 5;
    thisNode.height(spaceToFill);
  },

  componentDidMount() {
    const self = this;
    this.expandSpace();
    if (window) {
      window.onresize = function () {
        self.expandSpace();
      };
    }
    $(document).bind('repositionFooter', () => {
      this.expandSpace();
    });
  },
  render() {
    return <div></div>;
  },
});
module.exports = FillEmptySpace;
