import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalProps } from '@dropbox/dig-components/modal';
import { Button } from '@dropbox/dig-components/buttons';
import HFReactHelper from 'js/sign-components/common/hf-react-helper';
import styles from './global-nav.scss';
import { OceanCargoShipHero } from '@dropbox/dig-illustrations/dist/cjs/hero/ocean-cargo-ship';

function BulkSendModal({ onRequestClose, ...props }: ModalProps) {
  return (
    <Modal
      aria-labelledby="bulk-send-modal-title"
      isCentered
      onRequestClose={onRequestClose}
      {...props}
    >
      {/* This content is portaled and requires new backdrop theme context */}
      <>
        <div className={styles.bulkSendModalHero}>
          <OceanCargoShipHero aspectRatio="16:9" />
        </div>
        <Modal.Header hasBottomSpacing="title-standard">
          <Modal.Title id="bulk-send-modal-title">
            <FormattedMessage
              id=""
              description="Upsell modal that appears in webapp, when users click on homepage sidebar bulkSend link"
              defaultMessage="Upgrade your plan to use bulk send"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage
            id=""
            description="text upselling bulk send when a non Enterprise Plus customer clicks on bulk send"
            defaultMessage="Bulk send is the easiest way to send a large number of signature requests at one time. Just open a template and import a CSV of signer emails and data."
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="transparent"
            href={HFReactHelper.getFaqUrl(
              'articles/360026162552-How-to-send-signature-requests-in-bulk',
            )}
            data-qa-ref="global-nav-bulk-send-modal-learn-more"
            target="_blank"
          >
            <FormattedMessage
              id=""
              description="Learn more button in upsell modal that appears in webapp, when users click on homepage sidebar bulkSend link"
              defaultMessage="Learn more"
            />
          </Button>
          <Button
            variant="opacity"
            onClick={onRequestClose}
            data-qa-ref="global-nav-bulk-send-modal-close"
          >
            <FormattedMessage
              id=""
              description="Close button in upsell modal that appears in webapp, when users click on homepage sidebar bulkSend link"
              defaultMessage="Close"
            />
          </Button>
          <Button
            variant="primary"
            href="/info/pricing"
            data-qa-ref="global-nav-bulk-send-modal-pricing"
          >
            <FormattedMessage
              id=""
              description="Pricing button in upsell modal that appears in webapp, when users click on homepage sidebar bulkSend link. When clicked, will send users to the pricing plans page"
              defaultMessage="View plans and pricing"
            />
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
}

export default BulkSendModal;
