import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LineDivider } from './line-divider';
import { Text } from '@dropbox/dig-components/typography';
import styles from './or-divider.module.scss';

export function OrDivider() {
  return (
    <div className={styles.orDivider}>
      <LineDivider className={styles.divider} />
      <Text className={styles.or}>
        <FormattedMessage
          id=""
          description="A separator between two groups of form fields"
          defaultMessage="or"
        />
      </Text>
      <LineDivider className={styles.divider} />
    </div>
  );
}
