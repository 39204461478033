let didMarketingToolsTimeOut = false;

function wait(timeInMs) {
  return new Promise((resolve) => setTimeout(resolve, timeInMs));
}

const dispatchToTealium = (events) => {
  try {
    window.hsMarketingUtils.dispatchLegacyHsEvent({
      action: events.action || '',
      category: events.category || '',
      label: events.label || '',
    });
  } catch (e) {
    l(`Error: Could not report AA event (${e.message})`);
  }
};

// wait 5 seconds for tealium/hsMarketingUtils to load from dropbox
async function waitForTealiumAndThenDispatch(event) {
  for (let i = 0; i < 5; i++) {
    // eslint-disable-next-line no-await-in-loop
    await wait(1000);
    if (window?.hsMarketingUtils?.dispatchLegacyHsEvent) {
      dispatchToTealium(event);
      return true;
    }
  }
  didMarketingToolsTimeOut = true;
  return false;
}

export default function pushAnalyticsEvent(event) {
  /* global l */
  // send event to adobe analytics/marketing_tracker.
  if (window?.hsMarketingUtils?.dispatchLegacyHsEvent) {
    dispatchToTealium(event);
  } else if (!didMarketingToolsTimeOut) {
    waitForTealiumAndThenDispatch(event);
  }
}
