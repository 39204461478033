import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import countries from 'common/constants/countries';
import flattenMessages from 'common/utils/flatten-messages';
import { useGetFeatureFlagExperimentVariant } from 'js/sign-components/common/feature-flag';

const messages = defineMessages({
  creditCardNumber: {
    id: '',
    description:
      'info text, billing settings page, shows last 4 digits of users billing credit card',
    defaultMessage: 'Credit card number: **** **** **** {lastFourDigits}',
  },
  yourCardNumber: {
    id: '',
    description:
      'info text, billing settings page, shows last 4 digits of users billing credit card',
    defaultMessage: 'Your card number: **** **** **** {lastFourDigits}',
  },
  cardNumber: {
    id: '',
    description:
      'info text, billing settings page, shows last 4 digits of users billing credit card',
    defaultMessage: 'Card number: **** **** **** {lastFourDigits}',
  },
});

function BillingInfo(props) {
  const {
    hasBillingInfo,
    hasAPIBillingPlan,
    isDelinquent,
    isBillingInfoFake,
    billingPlans,
    ccSuffix,
    ccExpMonth,
    ccExpYear,
    hasTeam,
    isOrgAdmin,
    taxCountry,
    updateExperimentVariant,
  } = props;

  const bodyChunks = [];
  const intl = useIntl();
  const fallback = 'OFF';
  const CONTROL = 'CONTROL';
  const V1 = 'V1';
  const V2 = 'V2';
  const experimentVariant = useGetFeatureFlagExperimentVariant(
    'sign_growth_2024_10_02_test_growth_book_experiment',
    fallback,
  );

  React.useEffect(() => {
    if (typeof updateExperimentVariant === 'function') {
      updateExperimentVariant(experimentVariant);
    }
  }, [experimentVariant, updateExperimentVariant]);

  let creditCardLine = intl.formatMessage(messages.creditCardNumber, {
    lastFourDigits: ccSuffix,
  });
  if (experimentVariant === fallback) {
    creditCardLine = intl.formatMessage(messages.creditCardNumber, {
      lastFourDigits: ccSuffix,
    });
    // TODO: add PAP logging
  } else if (experimentVariant === CONTROL) {
    creditCardLine = intl.formatMessage(messages.creditCardNumber, {
      lastFourDigits: ccSuffix,
    });
    // TODO: add PAP logging
  } else if (experimentVariant === V1) {
    creditCardLine = intl.formatMessage(messages.yourCardNumber, {
      lastFourDigits: ccSuffix,
    });
    // TODO: add PAP logging
  } else if (experimentVariant === V2) {
    creditCardLine = intl.formatMessage(messages.cardNumber, {
      lastFourDigits: ccSuffix,
    });
    // TODO: add PAP logging
  }

  if (hasBillingInfo && !isBillingInfoFake) {
    bodyChunks.push(
      <>
        {creditCardLine}
        <br />
        <FormattedMessage
          id=""
          description="info text, billing settings page, shows expiration date of used card for billing"
          defaultMessage="Expiration date: {twoDigitExpiryMonth}/{fourDigitExpiryYear}"
          values={{
            twoDigitExpiryMonth: ccExpMonth,
            fourDigitExpiryYear: ccExpYear,
          }}
        />
        <br />
        {taxCountry && countries[taxCountry] && (
          <FormattedMessage
            id=""
            description="info text, billing settings page. Display country string based on country code"
            defaultMessage="Country: {country}"
            values={{
              country: flattenMessages(countries[taxCountry]).defaultMessage,
            }}
          />
        )}
      </>,
    );
  } else if (hasTeam && !isOrgAdmin) {
    bodyChunks.push(
      <FormattedMessage
        id=""
        description="info text, billing settings page, informs user that billing is managed by subscription admins"
        defaultMessage="Billing is managed by admins"
      />,
    );
  } else {
    bodyChunks.push(
      <FormattedMessage
        id=""
        description="info text, billing settings page, informs user that there is no card on file"
        defaultMessage="You have no card on record"
      />,
    );
  }

  if (isDelinquent) {
    if (hasAPIBillingPlan) {
      bodyChunks.push(
        <span className="c-red">
          <FormattedMessage
            id=""
            description="info text, error message, billing settings page, informs user of unsuccessful charge to credit card (or other payment method)"
            defaultMessage="We’ve been unsuccessful in charging your credit card."
          />
        </span>,
      );
    } else if (billingPlans.length === 1 /* has ui plan */) {
      bodyChunks.push(
        <span className="c-red">
          <FormattedMessage
            id=""
            description="info text, error message, billing settings page, informs user of unsuccessful charge to credit card (or other payment method)"
            defaultMessage="We’ve been unsuccessful in charging your credit card for your <b>{planName}</b> subscription"
            values={{
              planName: billingPlans[0].name,
              /* eslint-disable-next-line react/display-name */
              b: (...chunks) => <b>{chunks}</b>,
            }}
          />
        </span>,
      );
    }

    bodyChunks.push(
      <FormattedMessage
        id=""
        description="info text, error message, billing settings page, informs user to update their billing info to continue their plan"
        defaultMessage="Please update your billing info to continue your plan"
      />,
    );
  }

  return (
    <div className="billing-settings__billing-info">
      {bodyChunks.map((chunk, i) => (
        <p key={i}>{chunk}</p>
      ))}
    </div>
  );
}

export default BillingInfo;
