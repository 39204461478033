import React from 'react';
import classnames from 'classnames';
import styles from './icons.scss';

const OraclecpqLogo = (props) => (
  <svg
    {...props}
    viewBox="0 0 200 25"
    className={classnames(styles.icon, props.className)}
  >
    <g
      id="fn-oracle"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="Rectangle" x="0" y="0" width="118" height="30"></rect>
      <g
        id="5cfac50fa92867333722cc75_oracle-cpq-black"
        transform="translate(0.000000, 9.000000)"
        fill="#222222"
      >
        <path d="M73.76211,8.68973A3.37074,3.37074,0,0,1,70.5159,6.2343h8.57324l1.18192-1.8395H70.5159a3.38052,3.38052,0,0,1,3.24621-2.45542h5.88472L80.83707.09988H73.62892a5.21468,5.21468,0,1,0,0,10.42935h6.18436l1.18192-1.8395H73.76211m-24.52939,1.8395a5.21468,5.21468,0,1,1,0-10.42935h7.19984l-1.17362,1.8395H49.36588a3.37518,3.37518,0,1,0,0,6.75035H56.599l-1.18191,1.8395Zm-38.09-1.8395a3.37518,3.37518,0,1,0,0-6.75035H5.34954a3.37518,3.37518,0,1,0,0,6.75035Zm-5.92712,1.8395a5.21468,5.21468,0,1,1,0-10.42935h6.062a5.21468,5.21468,0,1,1,0,10.42935Zm21.1192-3.37934a3.525,3.525,0,1,0,0-7.05H17.55925V10.52923h2.00518V1.93938h6.63716a1.68969,1.68969,0,0,1,0,3.37934l-5.655-.00833,5.98787,5.21884h2.91329L25.41921,7.14989ZM59.52059,8.68973V.09988h-2.006V9.53041a.983.983,0,0,0,.29134.69917,1.0249,1.0249,0,0,0,.73246.29965H67.686l1.18193-1.8395ZM34.96624,6.85024h5.352l-2.82994-4.553-5.19392,8.232H29.93051L36.24806.64091A1.49273,1.49273,0,0,1,37.48829,0a1.46488,1.46488,0,0,1,1.2152.62425l6.34251,9.905H42.68215l-1.11536-1.8395H36.14816Z" />
        <path d="M82.80975,1.11535c.08321-.00833.16647-.00833.21639-.08324a.17629.17629,0,0,0,.03332-.11653.18653.18653,0,0,0-.0999-.15814.60191.60191,0,0,0-.24139-.025H82.6433v.38288h.16645M82.71817.51606a.84453.84453,0,0,1,.34129.04161.36668.36668,0,0,1,.258.36624.33259.33259,0,0,1-.01664.1082.33756.33756,0,0,1-.15813.22474c-.01666.00832-.025.01665-.05824.03329l.29964.541h-.29134l-.26636-.49942H82.6433v.49942h-.25806V.51606Zm.10821,1.87278A1.17364,1.17364,0,1,0,81.6611,1.21523,1.17227,1.17227,0,0,0,82.82638,2.38884ZM81.91083,1.2069a.91973.91973,0,1,1,.91555.92391A.91289.91289,0,0,1,81.91083,1.2069Z" />
      </g>
    </g>
  </svg>
);

export default React.memo(OraclecpqLogo);
