import React from 'react';
import '../checkbox.scss';

const DisabledEmpty = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16">
    <g
      id="input-/-checkbox-/-disabled"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fillOpacity="0.100000001"
    >
      <rect
        id="Box"
        fill="#00394D"
        x="0"
        y="0"
        width="16"
        height="16"
        rx="2"
      ></rect>
    </g>
  </svg>
);

export default React.memo(DisabledEmpty);
