/**
 * This is an opinionated snackbar provider that can handle multiple snackbars
 * being shown at the same time. If a new snackbar is shown while another is
 * open, the new one will be shown after the old one is closed.
 */
import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import { useIntl, defineMessages } from 'react-intl';
import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkCircleLine, FailLine } from '@dropbox/dig-icons/assets';

const messages = defineMessages({
  close: {
    id: '',
    description: 'button, settings page, close the snackbar',
    defaultMessage: 'Close',
  },
});

type SnackbarConfig = {
  type: 'success' | 'error';
  message: React.ReactNode;
};

type SnackbarContextType = {
  success: (message: React.ReactNode) => void;
  fail: (message: React.ReactNode) => void;
};

export const SnackbarContext = createContext<SnackbarContextType>({
  success: () => {},
  fail: () => {},
});

type Snack =
  | { status: 'open'; config: SnackbarConfig }
  | { status: 'closed'; next?: SnackbarConfig };

export function SnackbarProvider(props: { children: React.ReactNode }) {
  const [snack, setSnack] = useState<Snack>({ status: 'closed' });
  const intl = useIntl();

  function dismissSnackbar() {
    setSnack({ status: 'closed' });
  }

  const show = useCallback((config: SnackbarConfig) => {
    setSnack((currentState) => {
      if (currentState.status === 'open') {
        return { status: 'closed', next: config };
      } else {
        return { status: 'open', config };
      }
    });
  }, []);

  const context = useMemo(
    () => ({
      success: (message: React.ReactNode) => {
        show({ type: 'success', message });
      },
      fail: (message: React.ReactNode) => {
        show({ type: 'error', message });
      },
    }),
    [show],
  );

  useEffect(() => {
    if (snack.status === 'open') return;
    const { next } = snack;
    if (!next) return;
    // If there is an open snackbar, close it and show the new one after a delay
    // The delay makes it clear to the user that a new snackbar is being shown
    // Without it, it looks like nothing has happened
    const timer = setTimeout(() => {
      setSnack({ config: next, status: 'open' });
    }, 150);

    return () => {
      clearTimeout(timer);
    };
  }, [snack]);

  return (
    <SnackbarContext.Provider value={context}>
      {props.children}
      <Snackbar.Position>
        <Snackbar
          timeout={2000}
          preferComposition
          open={snack.status === 'open'}
          onRequestClose={dismissSnackbar}
        >
          <Snackbar.Accessory>
            <UIIcon
              src={
                snack.status === 'open' && snack.config.type === 'success'
                  ? CheckmarkCircleLine
                  : FailLine
              }
              role="presentation"
            />
          </Snackbar.Accessory>
          <Snackbar.Content>
            <Snackbar.Message>
              {snack.status === 'open' ? snack.config.message : null}
            </Snackbar.Message>
            <Snackbar.Actions>
              <Button variant="transparent" onClick={dismissSnackbar}>
                {intl.formatMessage(messages.close)}
              </Button>
            </Snackbar.Actions>
          </Snackbar.Content>
        </Snackbar>
      </Snackbar.Position>
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  return React.useContext(SnackbarContext);
}
