/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!  WARNING: Do NOT edit this file!  !!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * Foundation components are not intended to be edited to
 * satisfy specific use cases. If you would like to propose
 * a change to this component, please reach out to a
 * frontend engineer.
 *
 * @author Nathan Buchar <nathan.buchar@hellosign.com>
 */

import React from 'react';
import classnames from 'classnames';

import styles from './spacer.scss';

/**
 *
 */
export enum SpacerSize {
  /** 4px */
  XS,
  /** 8px */
  SM,
  /** 12px */
  MS,
  /** 16px */
  MD,
  /** 24px */
  LG,
  /** 32px */
  XL,
  /** 64px */
  XXL,
}

type SpacerProps = React.HTMLAttributes<HTMLDivElement> & {
  top?: SpacerSize;
  right?: SpacerSize;
  bottom?: SpacerSize;
  left?: SpacerSize;
  above?: SpacerSize; // alias for "top"
  below?: SpacerSize; // alias for "bottom"
};

function Spacer({
  top,
  right,
  bottom,
  left,
  above,
  below,
  children,
  className = '',
  ...props
}: SpacerProps) {
  const topOrAbove = top ?? above;
  const bottomOrBelow = bottom ?? below;

  return (
    <div
      {...props}
      className={classnames(styles.spacer, className, {
        // Top spacer
        [styles.xsAbove]: topOrAbove === SpacerSize.XS,
        [styles.smAbove]: topOrAbove === SpacerSize.SM,
        [styles.msAbove]: topOrAbove === SpacerSize.MS,
        [styles.mdAbove]: topOrAbove === SpacerSize.MD,
        [styles.lgAbove]: topOrAbove === SpacerSize.LG,
        [styles.xlAbove]: topOrAbove === SpacerSize.XL,
        [styles.xxlAbove]: topOrAbove === SpacerSize.XXL,

        // Left spacer
        [styles.xsLeft]: left === SpacerSize.XS,
        [styles.smLeft]: left === SpacerSize.SM,
        [styles.msLeft]: left === SpacerSize.MS,
        [styles.mdLeft]: left === SpacerSize.MD,
        [styles.lgLeft]: left === SpacerSize.LG,
        [styles.xlLeft]: left === SpacerSize.XL,
        [styles.xxlLeft]: left === SpacerSize.XXL,

        // Bottom spacer
        [styles.xsBelow]: bottomOrBelow === SpacerSize.XS,
        [styles.smBelow]: bottomOrBelow === SpacerSize.SM,
        [styles.msBelow]: bottomOrBelow === SpacerSize.MS,
        [styles.mdBelow]: bottomOrBelow === SpacerSize.MD,
        [styles.lgBelow]: bottomOrBelow === SpacerSize.LG,
        [styles.xlBelow]: bottomOrBelow === SpacerSize.XL,
        [styles.xxlBelow]: bottomOrBelow === SpacerSize.XXL,

        // Right spacer
        [styles.xsRight]: right === SpacerSize.XS,
        [styles.smRight]: right === SpacerSize.SM,
        [styles.msRight]: right === SpacerSize.MS,
        [styles.mdRight]: right === SpacerSize.MD,
        [styles.lgRight]: right === SpacerSize.LG,
        [styles.xlRight]: right === SpacerSize.XL,
        [styles.xxlRight]: right === SpacerSize.XXL,
      })}
    >
      {children}
    </div>
  );
}

export default Spacer;
