/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import './checkbox.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import EnabledChecked from './assets/enabled-checked';
import EnabledEmpty from './assets/enabled-empty';
import EnabledIndeterminate from './assets/enabled-indeterminate';
import DisabledChecked from './assets/disabled-checked';
import DisabledEmpty from './assets/disabled-empty';
import DisabledIndeterminate from './assets/disabled-indeterminate';

const allStates = {
  'enabled-checked': <EnabledChecked />,
  'enabled-empty': <EnabledEmpty />,
  'enabled-indeterminate': <EnabledIndeterminate />,
  'disabled-checked': <DisabledChecked />,
  'disabled-empty': <DisabledEmpty />,
  'disabled-indeterminate': <DisabledIndeterminate />,
};

export default function Checkbox({
  id,
  disabled,
  checked,
  indeterminate,
  className,
  ...props
}) {
  const state = [
    disabled ? 'disabled' : 'enabled',
    // eslint-disable-next-line no-nested-ternary
    !checked ? 'empty' : indeterminate ? 'indeterminate' : 'checked',
  ].join('-');

  return (
    <div
      className={classnames('indeterminate-checkbox', className, {
        'indeterminate-checkbox--disabled': disabled,
      })}
    >
      <input
        {...props}
        disabled={disabled}
        checked={checked}
        id={id}
        type="checkbox"
      />
      <label htmlFor={id}>{allStates[state]}</label>
    </div>
  );
}
Checkbox.defaultProps = { mode: 'default' };
Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
};
