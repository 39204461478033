/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  block-scoped-var,
  func-names,
  no-redeclare,
  no-restricted-globals,
  no-var,
  one-var,
  react/no-find-dom-node,
  vars-on-top
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import hsFetch from 'js/sign-components/common/hs-fetch';
import baseform from 'common/components/base-form';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import Button from 'common/components/button';
import classNames from 'classnames';
import setTimeout from 'common/utils/set-timeout';
import logger from 'common/logger';
import CountriesDropdown from 'common/components/countries-dropdown';
import OrDivider from 'common/components/or-divider';
import { trackHeapCustomEvent } from 'js/sign-components/common/heap';
import pushAnalyticsEvent from 'common/utils/pushAnalyticsEvent';
import {
  getTrackingLabel,
  getTrackingVariant,
} from 'common/utils/getTrackingVariant';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { getBrandName } from 'js/sign-components/common/brand';
import { loadArkoseCdnScript } from 'signer-app/authentication/arkose';
import LogInTrigger from './login-trigger';
import GoogleSignInButton from './google-signin-button';
import DropboxSignInButton from './dropbox-signin-button';
import {
  EUROPEAN_COUNTRIES_LIST,
  MARKETING_EMAIL_AUTO_OPT_IN_COUNTRIES_LIST,
} from '../pricing/constants';

const messages = defineMessages({
  placeholderEmail: {
    id: '',
    description:
      'input, placeholder, default un-used text for a text field that suggests to user what to do. In this case placeholder for email field',
    defaultMessage: 'Enter your email',
  },
  optInText: {
    id: '',
    description:
      'checkbox, asks user if user agrees to receive marketing/updates emails',
    defaultMessage:
      'I would like to receive marketing email updates from {siteName}',
  },
  optOutText: {
    id: '',
    description:
      'checkbox, asks user if user wants to remove marketing/updates emails',
    defaultMessage:
      'I do not want to receive marketing email updates from {siteName}',
  },
  buttonSignUpHere: {
    id: '',
    description:
      'label, upper case, button, actionable, submits form to sign up user in the platform',
    defaultMessage: '<upper>sign up free</upper>',
  },
  buttonProcessing: {
    id: '',
    description:
      'label, upper case, button, disabled, informs user that action is in progress and button is not active',
    defaultMessage: '<upper>processing...</upper>',
  },
  loginFormLinkText: {
    id: '',
    description:
      'link, label, link to page where user can login in the platform',
    defaultMessage: 'Login',
  },
  loginFormButtonText: {
    id: '',
    description:
      'button, label, processes user sign in based on provided credentials',
    defaultMessage: 'Sign In',
  },
  loginFormHeader: {
    id: '',
    description: 'title, welcome message for user who just signed in',
    defaultMessage: 'Welcome back',
  },
});

/* -----------  SIGNUP  ----------------------------------- */

/**
 * NOTE: This form does not follow the proper way to use
 * React and Symfony Forms, a better example can be found
 * by looking at ForgotPasswordForm
 */
// Skip list to allow QA Automation
const SKIP_LIST = new RegExp(
  /.+[+].*@(hellosign.com|hellodocument.com|dbx51.com)$/,
);
const CreateAccountForm = baseform.createFormClass({
  displayName: 'CreateAccountForm',
  checkboxRef: React.createRef(),

  propTypes: {
    header: PropTypes.string,
    buttonText: PropTypes.string,
    isInline: PropTypes.bool,
    isVertical: PropTypes.bool, // only works for inline
    inlineSideText: PropTypes.string,
    csrfToken: PropTypes.string,
    googleSignInClientId: PropTypes.string,
    redirectUrl: PropTypes.string,
    isFromApiPricing: PropTypes.bool,
    planId: PropTypes.string,
    planName: PropTypes.string,
    skipCC: PropTypes.bool,
    period: PropTypes.string,
    trialPeriod: PropTypes.number,
    tabOffset: PropTypes.number,
    async: PropTypes.bool,
    recoverPasswordForm: PropTypes.object, // Serialized sfForm
    loginForm: PropTypes.object, // Serialized sfForm
    hideRememberMe: PropTypes.bool,
    hideGoogleOpenId: PropTypes.bool,
    isModal: PropTypes.bool,
    countryCodeFromIp: PropTypes.string,
    marketingOptInOutEnabled: PropTypes.bool, // TODO: Clean up for DEV-13156
    arkoseSignUpPublicKey: PropTypes.string.isRequired,
    arkoseSignUpEnabled: PropTypes.bool.isRequired,
    isDirectPurchase: PropTypes.bool.isRequired,
    googleAnalyticsTrackingVariant: PropTypes.string,
  },

  getDefaultProps() {
    return {
      async: true,
    };
  },

  /**
   * Returns the google analytics tracking label
   *
   * If pricing_page, continue (dbx | google) (<planType>)
   *
   * @param {"google" | "dbx" | "email"} buttonType
   */
  getTrackingLabel(buttonType) {
    return getTrackingLabel(
      buttonType,
      this.props.googleAnalyticsTrackingVariant,
      this.props.planName,
    );
  },

  getInitialState() {
    let optInMessage = '';
    let countryCode = '';
    let isOptedIn = false;

    if (EUROPEAN_COUNTRIES_LIST.includes(this.props.countryCodeFromIp)) {
      optInMessage = messages.optOutText;
    } else if (
      !EUROPEAN_COUNTRIES_LIST.includes(countryCode) &&
      !MARKETING_EMAIL_AUTO_OPT_IN_COUNTRIES_LIST.includes(countryCode)
    ) {
      optInMessage = messages.optInText;
    }

    if (this.props.countryCodeFromIp) {
      countryCode = this.props.countryCodeFromIp;
      isOptedIn = MARKETING_EMAIL_AUTO_OPT_IN_COUNTRIES_LIST.includes(
        this.props.countryCodeFromIp,
      );
    }

    return {
      errors: this.props.errors,
      countryCode,
      optInMessage,
      emailAddress: '',
      isProcessing: false,
      isInline: false,
      isVertical: false,
      isOptedIn,
      arkoseSignUpEnforcement: null,
      arkoseToken: null,
      formEvent: null,
    };
  },

  componentDidMount() {
    logger.track('CreateAccountForm.componentDidMount', {
      location: location.pathname,
    });

    if (this.props.arkoseSignUpEnabled) {
      this.loadArkose();
    }

    if (!this.props.isInline) {
      // This is a modal, so focus on the email address input element
      this._inlineTimer = setTimeout(() => {
        $(ReactDOM.findDOMNode(this))
          .find('input[name="signup[email_address]"]')
          .focus();
      }, 500);
    }
  },

  componentWillUnmount() {
    if (this._inlineTimer) {
      clearTimeout(this._inlineTimer);
    }
  },

  onEmailChange(event) {
    this.setState({
      emailAddress: event.target.value.trim(),
    });
  },

  onOptInChange(event) {
    this.setState({
      isOptedIn: event.target.checked,
    });
  },

  onCountryCodeChange(countryCode) {
    let isOptedIn = this.state.isOptedIn;
    let optInMessage = this.state.optInMessage;

    if (EUROPEAN_COUNTRIES_LIST.includes(countryCode)) {
      isOptedIn = false;
      optInMessage = messages.optOutText;
    } else if (
      MARKETING_EMAIL_AUTO_OPT_IN_COUNTRIES_LIST.includes(countryCode)
    ) {
      isOptedIn = true;
    } else if (
      !EUROPEAN_COUNTRIES_LIST.includes(countryCode) &&
      !MARKETING_EMAIL_AUTO_OPT_IN_COUNTRIES_LIST.includes(countryCode)
    ) {
      isOptedIn = false;
      optInMessage = messages.optInText;
    }
    this.setState({
      countryCode,
      isOptedIn,
      optInMessage,
    });
  },

  getFormData() {
    const fd = new FormData();
    $('input, select, textarea', ReactDOM.findDOMNode(this)).each(function () {
      const el = $(this);
      const name = el.attr('name');
      if (name) {
        if (el.attr('type') === 'checkbox') {
          let isChecked = false;
          if (typeof el.prop === 'function') {
            if (el.prop('checked')) {
              isChecked = true;
            }
          } else if (
            el.attr('checked') === 'checked' ||
            el.attr('checked') === true
          ) {
            isChecked = true;
          }
          fd.append(name, isChecked);
        } else {
          fd.append(name, el.val());
        }
      }
    });
    return fd;
  },

  async submitAsync(okCallback, errCallback) {
    const formData = this.getFormData();

    if (this.state.arkoseToken) {
      formData.append('arkose_token', this.state.arkoseToken);
    }

    // Mix-shift experiment relies on this value to determine eligibility
    if (this.props.isDirectPurchase) {
      formData.append('direct_purchase', this.props.isDirectPurchase);
    }

    try {
      const response = await hsFetch(this.props.action, {
        method: this.props.method || 'POST',
        body: formData,
      });
      const data = await response.json();
      okCallback(data);
    } catch (err) {
      errCallback();
    }
  },

  handleArkoseEnforcementCompleted(response) {
    this.setState({ arkoseToken: response.token, isProcessing: false });
    // Go back and submit the form
    this.onSubmit(this.state.formEvent);
  },

  loadArkose() {
    loadArkoseCdnScript(this.props.arkoseSignUpPublicKey, (enforcement) => {
      enforcement.setConfig({
        data: {},
        onCompleted: (response) => {
          this.handleArkoseEnforcementCompleted(response);
        },
        onHide: () => {
          // User has closed the FunCaptcha overlay, return to the previous state
          this.setState({
            isProcessing: false,
          });
        },
        onReady: () => {},
        onReset: () => {},
        onShow: () => {},
        onShown: () => {},
        onSuppress: () => {},
      });
      // Add the enforcement to the state so we can use it later
      this.setState({ arkoseSignUpEnforcement: enforcement });
    });
  },

  onSubmit(evt) {
    evt.preventDefault();

    trackHeapCustomEvent('pricing_page_continue', {
      plan_id: this.props.planId,
    });

    pushAnalyticsEvent({
      category: 'signup',
      action: `create account inapp - ${getTrackingVariant(this.props.isApi)}`,
      label: 'create an account',
    });

    if (this.state.isProcessing === false) {
      // Retrieve the values from the email field one more time
      // prior to submit. This fixes an issue where Safari auto-complete
      // would not trigger the on*Change events for these fields.
      const root = ReactDOM.findDOMNode(this);
      const emailFieldId = !this.props.isInline
        ? '#sign_up_email_address_modal_input'
        : '#sign_up_email_address_input';
      const emailAddress = $(emailFieldId, root).val();

      const checkboxValue = this.checkboxRef.current.value === 'true';
      if (EUROPEAN_COUNTRIES_LIST.includes(this.state.countryCode)) {
        this.checkboxRef.current.value = !checkboxValue;
      } else {
        this.checkboxRef.current.value = checkboxValue;
      }

      this.setState({
        errors: null,
        isProcessing: true,
        emailAddress,
        formEvent: evt,
      });

      if (!HfReactHelper.isValidEmailAddress(emailAddress)) {
        return this.setState({
          errors: { named: { email_address: 'Invalid email address' } },
          isProcessing: false,
        });
      }

      if (
        this.state.arkoseSignUpEnforcement &&
        !SKIP_LIST.test(this.state.emailAddress) &&
        !this.state.arkoseToken
      ) {
        return this.state.arkoseSignUpEnforcement.run();
      }

      if (!this.props.async) {
        logger.track('CreateAccountForm._onSubmit');
        // Inline submit
        $('#sign_up_form', root).submit();
      } else {
        // Async submit
        this.submitAsync(
          (resp) => {
            this.setState({
              isProcessing: false,
            });

            // Display notification
            if (resp.notification) {
              hellofaxJS.createNotification({
                text: resp.notification.message,
                class_name: resp.notification.type,
              });
            }

            // Redirect to redirect_url
            if (resp.success === true) {
              logger.track('CreateAccountForm._onSubmit');
              if (resp.redirect_url) {
                document.location = resp.redirect_url;
              }
            }

            // Failure
            if (!resp.success) {
              // Display error message
              if (resp.error && !resp.notification_message) {
                this.setState({
                  errors: { global: resp.error.message },
                });
              }
            }
          },
          () => {
            // Unexpected error
            this.setState({
              errors: { global: 'An unexpected error occurred' },
              isProcessing: false,
            });
          },
        );
      }
    }
  },

  render() {
    const { intl } = this.props;

    // is vertical (even if not inline)
    const isVertical = !this.props.isInline || this.props.isVertical;
    const tabOffset = parseInt(this.props.tabOffset || 0, 10);
    const rowClassNames = classNames({
      row: true,
      'text-center': true,
      'bg-plume': !!this.props.isInline,
      'm-create-account--inline-container':
        this.props.isInline && !this.props.isVertical,
      'm-create-account--inline-container-vertical':
        this.props.isInline && this.props.isVertical,
      'l-padding-v-20': !!this.props.isInline,
    });

    const tosStyles = {
      marginTop: '16px',
      fontSize: '14px',
      textAlign: 'left',
      letterSpacing: 'normal',
    };

    const includeInlineText = function (text) {
      return (
        <div className="m-create-account--login-side-text columns small-12 large-4 l-margin-v-20 ">
          <h2 className="c-ashen">{text}</h2>
        </div>
      );
    };

    const formColumnClassNames = classNames({
      'text-left m-create-account--sign-up': !isVertical,
      'columns small-12 l-margin-v-10 text-center': true,
      'large-5 large-left': !isVertical,
    });
    const buttonColumnClassNames = classNames({
      'columns small-12 l-margin-v-10 text-center': true,
      'large-3 large-left': !isVertical,
      'm-create-account--inline-google-login': !isVertical,
      'm-create-account--modal-google-login': isVertical,
    });
    const submitButtonClassNames = classNames({
      'm-create-account--inline-create-account-button': !isVertical,
      'm-create-account--modal-create-account-button': isVertical,
    });

    let redirectUrl;
    if (this.props.redirectUrl) {
      redirectUrl = (
        <input
          type="hidden"
          name="signup[redirect_url]"
          value={this.props.redirectUrl}
        />
      );
    }

    let isFromApiPricing;
    if (this.props.isFromApiPricing) {
      isFromApiPricing = (
        <input
          type="hidden"
          name="is_from_api_pricing"
          value={this.props.isFromApiPricing ? '1' : ''}
        />
      );
    }

    let planId;
    if (this.props.planId) {
      planId = <input type="hidden" name="plan_id" value={this.props.planId} />;
    }

    let skipCC;
    if (this.props.skipCC) {
      skipCC = <input type="hidden" name="skip_cc" value={this.props.skipCC} />;
    }

    let period;
    if (this.props.period) {
      period = <input type="hidden" name="period" value={this.props.period} />;
    }

    let trialPeriod;
    if (this.props.trialPeriod) {
      trialPeriod = (
        <input
          type="hidden"
          name="trial_period"
          value={this.props.trialPeriod}
        />
      );
    }

    // added hidden checkbox as unchecked checkbox doesn't get picked by form on POST
    const hiddenOptInMarketingEmailCheckBox = (
      <input
        ref={this.checkboxRef}
        type="hidden"
        name="signup[is_opted_in_marketing_emails]"
        value={this.state.isOptedIn}
      />
    );

    let errors;
    if (this.state.errors) {
      errors = (
        <div className="row">
          <div className="columns l-margin-b-10 small-10 small-offset-1 text-center m-modal--error">
            {this.renderErrorText()}
          </div>
        </div>
      );
    }

    let buttonText = this.props.buttonText
      ? this.props.buttonText
      : intl.formatMessage(messages.buttonSignUpHere, {
          upper: ([msg]) => msg.toLocaleUpperCase(intl.locale),
        });
    if (this.state.isProcessing) {
      buttonText = intl.formatMessage(messages.buttonProcessing, {
        upper: ([msg]) => msg.toLocaleUpperCase(intl.locale),
      });
    }

    const loginUrl = HfReactHelper.urlHelper('account/logIn');
    let loginTrigger = null;
    let loginPrompt = null;
    if (this.props.loginForm) {
      loginTrigger = (
        <LogInTrigger
          linkText={intl.formatMessage(messages.loginFormLinkText)}
          buttonText={intl.formatMessage(messages.loginFormButtonText)}
          header={intl.formatMessage(messages.loginFormHeader)}
          googleSignInClientId={this.props.googleSignInClientId}
          loginUrl={loginUrl}
          redirectUrl={this.props.redirectUrl}
          recoverPasswordForm={this.props.recoverPasswordForm}
          loginForm={this.props.loginForm}
          hideRememberMe={this.props.hideRememberMe}
          hideGoogleOpenId={this.props.hideGoogleOpenId}
        ></LogInTrigger>
      );

      loginPrompt = (
        <div className="columns small-12">
          <div className="m-create-account--login-prompt">
            <FormattedMessage
              id=""
              description="link, "
              defaultMessage="Already have an account? {link}"
              values={{ link: loginTrigger }}
            />
          </div>
        </div>
      );
    }

    const optInStyle =
      MARKETING_EMAIL_AUTO_OPT_IN_COUNTRIES_LIST.includes(
        this.state.countryCode,
      ) || this.state.countryCode === ''
        ? { display: 'none' }
        : {};
    const siteName = HfReactHelper.isHelloSign()
      ? getBrandName('S')
      : getBrandName('F');
    const signupFormLink1 = (msg) => (
      <a href={this.props.tosUrl} target="_blank" rel="noopener noreferrer">
        {msg}
      </a>
    );
    const signupFormLink2 = (msg) => (
      <a
        href={this.props.privacyPolicyUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {msg}
      </a>
    );
    const signupFormFields = (
      <div className={formColumnClassNames}>
        <div className="small-10 small-offset-1 text-left">
          {!this.props.isInline && (
            <label
              className="l-margin-t-20 m-create-account--label"
              htmlFor="sign_up_email_address_modal_input"
            >
              <FormattedMessage
                id=""
                description="Label for a text input field."
                defaultMessage="Email address"
              />
            </label>
          )}
          {this.renderField('email_address', {
            id: !this.props.isInline
              ? 'sign_up_email_address_modal_input'
              : 'sign_up_email_address_input',
            'data-qa-ref': 'email-input',
            className: `m-create-account--text-input ${isVertical ? 'm-create-account--email-vertical' : ''}`,
            maxLength: '99',
            placeholder: !this.props.isInline
              ? ''
              : intl.formatMessage(messages.placeholderEmail),
            onChange: this.onEmailChange,
            tabIndex: tabOffset,
            value: this.state.emailAddress,
            'data-format': 'email',
          })}
        </div>
        <div className="small-10 small-offset-1 small-text-left">
          <div className="m-create-account--opt-in-container">
            <div
              className={`m-create-account--opt-in-align${isVertical ? '-vertical' : ''}`}
              style={optInStyle}
            >
              {this.renderField('is_opted_in_marketing_emails', {
                className: 'm-create-account--text-input',
                tabIndex: tabOffset + 2,
                onChange: this.onOptInChange,
                value: this.state.isOptedIn,
                'data-qa-ref': 'opt_in_marketing_emails_checkbox',
              })}
              {hiddenOptInMarketingEmailCheckBox}
              &nbsp;
              <label className="m-create-account--margin-left">
                {this.state.optInMessage
                  ? intl.formatMessage(this.state.optInMessage, { siteName })
                  : ''}
              </label>
            </div>
          </div>
        </div>
        {errors}
        {this.renderHiddenFields()}
        <input
          type="hidden"
          name="async"
          value={this.props.async ? '1' : '0'}
        />
        <input type="hidden" name="force_delay_analytics_js" value="true" />
        {this.state.arkoseToken && (
          <input
            type="hidden"
            name="arkose_token"
            value={this.state.arkoseToken}
          />
        )}
        <div
          className={`l-margin-b-10 small-10 small-offset-1 small-text-center ${!isVertical ? 'large-text-left' : ''}`}
        >
          <Button
            type="submit"
            data-qa-ref="create-account-submit"
            data-testid="create-account-submit"
            buttonClass={submitButtonClassNames}
            buttonText={buttonText}
            buttonColor="ocean-blue"
            buttonHoverColor="castle-rock"
            buttonTabIndex={tabOffset + 3}
            buttonAttrs={{
              'da-category': 'signup',
              'da-action': `create account inapp - ${getTrackingVariant(this.props.isApi)}`,
              'da-label': this.getTrackingLabel('email'),
            }}
          />

          <p style={tosStyles}>
            <FormattedMessage
              id=""
              description="policies text with links"
              defaultMessage="By signing up or creating an account, you agree to the {brandName} <link1>Terms</link1> & <link2>Privacy Policy</link2>."
              values={{
                brandName: getBrandName('S'),
                link1: signupFormLink1,
                link2: signupFormLink2,
              }}
            />
          </p>
        </div>
      </div>
    );

    const googleLoginImage = !isVertical ? (
      <OrDivider isVertical />
    ) : (
      <OrDivider isModal />
    );

    const extraPadding = this.props.isInline
      ? 'm-create-account__inline-google-login--minimal-top-padding'
      : 'm-create-account__inline-google-login--small-top-padding';

    const formType = 'signup';
    const signInButtons = this.props.googleSignInClientId ? (
      <div className={buttonColumnClassNames}>
        {this.props.fields ? googleLoginImage : null}
        <div
          className={`${!isVertical ? 'm-create-account--inline-google-login-button' : 'm-create-account--modal-google-login-button'} ${extraPadding}`}
        >
          <GoogleSignInButton
            clientId={this.props.googleSignInClientId}
            redirectUrl={this.props.redirectUrl}
            csrfToken={this.props.csrfToken}
            formType={formType}
            isSignup
            buttonAttrs={{
              'da-category': 'signup',
              'da-action': `create account inapp - ${getTrackingVariant(this.props.isApi)}`,
              'da-label': this.getTrackingLabel('google'),
            }}
          />
          <div className="l-margin-t-20">
            <DropboxSignInButton
              isSignup
              redirectUrl={this.props.redirectUrl}
              buttonAttrs={{
                'da-category': 'signup',
                'da-action': `create account inapp - ${getTrackingVariant(this.props.isApi)}`,
                'da-label': this.getTrackingLabel('dbx'),
              }}
            />
          </div>
        </div>
      </div>
    ) : (
      ''
    );

    const a = (msg) => {
      const currentPageURL = new URL(window.location.href);
      let logInPageURL = '/account/logIn';

      if (
        currentPageURL.pathname === '/info/pricing' &&
        currentPageURL.searchParams.has('integration')
      ) {
        logInPageURL += `?on_login_redirect_url=${this.props.redirectUrl}`;
      }

      return <a href={logInPageURL}>{msg}</a>;
    };

    const signInLink = (
      <div className="m-create-account--sign-in-link">
        <div className="m-create-account--h-divider small-10"></div>
        <p>
          <FormattedMessage
            id=""
            defaultMessage="Already have an account? <a>Sign in ›</a>"
            description="sign up page"
            values={{ a }}
          />
        </p>
      </div>
    );

    const signUpForm = (
      <form
        id="sign_up_form"
        className="m-create-account--form"
        onSubmit={this.onSubmit}
        action={this.props.action}
        method="POST"
      >
        {this.props.fields ? signupFormFields : null}
        {isVertical && this.props.loginForm ? loginPrompt : ''}
        {redirectUrl}
        {isFromApiPricing}
        {planId}
        {skipCC}
        {period}
        {trialPeriod}
      </form>
    );

    const countryDropdown = (
      <CountriesDropdown
        selectedCountryCode={this.state.countryCode}
        onCountryCodeChange={this.onCountryCodeChange}
      />
    );

    return (
      <React.Fragment>
        <div className="m-create-account">
          {!this.props.isInline && (
            <>
              <div className="m-create-account--countries-dropdown">
                {countryDropdown}
              </div>
              <div className={rowClassNames}>
                <div className="columns small-12">
                  <h3 className="header-text--size">{this.props.header}</h3>
                </div>
                {signInButtons}
                {signUpForm}
              </div>
              {signInLink}
            </>
          )}
          {this.props.isInline && (
            <div className={rowClassNames}>
              <div className="columns small-12">
                <h4>{this.props.header}</h4>
              </div>
              {includeInlineText(this.props.inlineSideText)}
              {countryDropdown}
              {signInButtons}
              {signUpForm}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  },
});

module.exports = injectIntl(CreateAccountForm);
