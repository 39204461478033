import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { WarningLine } from '@dropbox/dig-icons/assets';
import { SiteCodeType, getBrandName } from 'js/sign-components/common/brand';
import styles from './global-header.scss';
import { Banner } from '@dropbox/dig-components/banner';

export type DelinquencyBannerProps = {
  siteCode: SiteCodeType;
  nextBillingDateInMs: number;
  endDateInMs: number;
  canManageBilling: boolean;
};

function DelinquencyBanner({
  siteCode,
  nextBillingDateInMs,
  endDateInMs,
  canManageBilling,
}: DelinquencyBannerProps) {
  const intl = useIntl();

  const daysUntilDowngrade = React.useMemo(() => {
    const currDateInMs = Date.now();

    return Math.ceil(Math.abs(endDateInMs - currDateInMs) / (1000 * 3600 * 24));
  }, [endDateInMs]);

  return (
    <Banner
      className={styles.warningBanner}
      variant="basic"
      type="alert"
      withLeftIcon={<UIIcon src={WarningLine} role="presentation" />}
    >
      <Banner.Message>
        {canManageBilling ? (
          <FormattedMessage
            id=""
            description="Error message displayed when a user is delinquent"
            defaultMessage="We were unable to bill your credit card on {preformattedNextBillingDate}. Unless you update your billing, your {brandName} access will become limited in {daysUntilDowngrade, plural, =1 {# day} other {# days}}."
            values={{
              daysUntilDowngrade,
              brandName: getBrandName(siteCode),
              preformattedNextBillingDate: intl.formatDate(
                nextBillingDateInMs,
                {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                },
              ),
            }}
          />
        ) : (
          <FormattedMessage
            id=""
            description="Error message displayed to team-member on a delinquent subscription"
            defaultMessage="We were unable to bill your credit card on {preformattedNextBillingDate}. Please contact your Admin to update your billing, otherwise your {brandName} access will become limited in {daysUntilDowngrade, plural, =1 {# day} other {# days}}."
            values={{
              daysUntilDowngrade,
              brandName: getBrandName(siteCode),
              preformattedNextBillingDate: intl.formatDate(
                nextBillingDateInMs,
                {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                },
              ),
            }}
          />
        )}
      </Banner.Message>
      {canManageBilling && (
        <Banner.Actions>
          <Button
            variant="transparent"
            href="/account/updateCard"
            data-testid="billing-info-update-link-on-landing"
          >
            <FormattedMessage
              id=""
              description="Text for a link that leads to the billing settings page."
              defaultMessage="Update billing"
            />
          </Button>
        </Banner.Actions>
      )}
    </Banner>
  );
}

export default DelinquencyBanner;
