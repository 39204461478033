import './index.scss';

import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import HelloModal from 'common/components/hello-modal';
import Button from 'common/components/button';
import { Checkbox } from '@dropbox/dig-components/controls';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import AdminSetting from 'hellospa/components/admin-setting';
import { AdminSettingsSimpleRow } from 'hellospa/components/admin-settings-row';
import AdminSettingsRowGroup from 'hellospa/components/admin-settings-row-group';
import hsFetch from 'js/sign-components/common/hs-fetch';
import SwitchBillingPeriodModal from 'hellospa/components/switch-billing-period-modal';
import { trackHeapCustomEvent } from 'js/sign-components/common/heap';
import VatRules from 'common/constants/vat-rules';
import TaxId from 'common/components/tax-id';
import countries from 'common/constants/countries';
import { getBrandName } from 'js/sign-components/common/brand';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

import { useAppContext } from 'hello-react/web-app-client/build-web-app-client';
import Plan from './plan';
import BillingCycle from './billing-cycle';
import BillingInfo from './billing-info';
import AddonInfo from './addon-info';
import Invoices from './invoices';
import ReceiptAddress from './receipt-address';
import CancelPlanButton from './cancel-plan-button';
import ResumePausedButton from './resume-paused-button';
import CancelPauseButton from './cancel-pause-button';
import { SafeDowngradeStatusEnum } from '../../../hellospa/components/cancellation-flow-modal/types';

const messages = defineMessages({
  plan: {
    id: '',
    description: 'Header for a the subscription plan.',
    defaultMessage: 'Plan',
  },
  plans: {
    id: '',
    description: 'Header for a list of subscription plans.',
    defaultMessage: 'Plans',
  },
  cancelPlan: {
    id: '',
    description:
      'Text for a button which allows the user to cancel subscription plan when pressed.',
    defaultMessage: 'Cancel plan',
  },
  resumePlan: {
    id: '',
    description:
      'Text for a button which allows the user to resume a canceled subscription plan when pressed.',
    defaultMessage: 'Resume plan',
  },
  changePlan: {
    id: '',
    description:
      'Text for a button which allows the user to change their subscription plan when pressed.',
    defaultMessage: 'Change plan',
  },
  switchToAnnual: {
    id: '',
    description:
      'Text for a button which allows the user to change their subscription to annual billing when pressed.',
    defaultMessage: 'Switch to annual',
  },
  switchToMonthly: {
    id: '',
    description:
      'Text for a button which allows the user to change their subscription to monthly billing when pressed.',
    defaultMessage: 'Switch to monthly',
  },
  billingCycle: {
    id: '',
    description:
      "Header for a section with information related to a user's billing cycle.",
    defaultMessage: 'Billing cycle',
  },
  billingInformation: {
    id: '',
    description:
      "Header for a section with information related to a user's billing information.",
    defaultMessage: 'Billing information',
  },
  billingReceipts: {
    id: '',
    description:
      "Header for a section with information related to a user's billing receipts.",
    defaultMessage: 'Billing receipts',
  },
  addBilling: {
    id: '',
    description:
      'Text for a button which allows the user to add a credit card to their account when pressed.',
    defaultMessage: 'Add credit card',
  },
  updateBilling: {
    id: '',
    description:
      'Text for a button which allows the user to update their credit card information on their account.',
    defaultMessage: 'Update',
  },
  businessInfo: {
    id: '',
    description:
      "Header for a section with information related to a user's business/company information.",
    defaultMessage: 'Business information',
  },
  businessName: {
    id: '',
    description:
      'Header for a section where the user can see and edit their business name.',
    defaultMessage: 'Business name',
  },
  receiptAddress: {
    id: '',
    description:
      "Header for a section with information related to a user's receipt address a text input a user can type an address into.",
    defaultMessage: 'Receipt address',
  },
  businessAddress: {
    id: '',
    description:
      'Header for a section where the user can see and edit their business address.',
    defaultMessage: 'Business address',
  },
  nevermind: {
    id: '',
    description: 'Text for a button which cancels an action when pressed.',
    defaultMessage: 'Nevermind',
  },
  removeCard: {
    id: '',
    description:
      "Text for a button which removes the credit card from the user's account when pressed.",
    defaultMessage: 'Remove card',
  },
  delete: {
    id: '',
    description: 'Text for a button which deletes an item when pressed.',
    defaultMessage: 'Delete',
  },
  confirmDelete: {
    id: '',
    description:
      'Text for a button which confirms that the user wants to delete the item.',
    defaultMessage: 'Yes, delete',
  },
  switchBillingCycle: {
    id: '',
    description:
      "Text for a button which switches the user's billing cycle from monthly to annual when pressed.",
    defaultMessage: 'Switch billing cycle',
  },
  saveButton: {
    id: '',
    description:
      'Text for a button which saves the users address they inputed into a text area',
    defaultMessage: 'Save',
  },
  businessInfoTooltip: {
    id: '',
    description:
      'tooltip content that appears over the label on a section telling user to ender an address or content in a textarea',
    defaultMessage: 'Information will appear on your receipt.',
  },
  addonInformation: {
    id: '',
    description:
      "Header for a section with information related to a user's add-on usage.",
    defaultMessage: 'Add-Ons',
  },
  contactUsAddon: {
    id: '',
    description:
      'Text for a button which allows the user to reach a contact form for Sales when pressed.',
    defaultMessage: 'Contact us to update',
  },
  // messaging for all tax ids will display as "VAT number" unless the users country is canada
  taxIdTitle: {
    id: '',
    description:
      'header of a section where the user can see their tax ID/VAT number',
    defaultMessage: '{taxCountry, select, CA {Tax ID} other {VAT number}}',
  },
  updateTaxId: {
    id: '',
    description:
      'title of a modal when a user is updating their tax ID/VAT number',
    defaultMessage:
      'Update {taxCountry, select, CA {Tax ID} other {VAT number}}',
  },
  addTaxId: {
    id: '',
    description:
      'text of a button allowing the user to add their tax ID/VAT number',
    defaultMessage: 'Add {taxCountry, select, CA {Tax ID} other {VAT number}}',
  },
  deleteTaxId: {
    id: '',
    description:
      'text of a button allowing the user to delete their tax ID/VAT number',
    defaultMessage:
      'Delete {taxCountry, select, CA {Tax ID} other {VAT number}}?',
  },
  invalidTaxId: {
    id: '',
    description:
      'error message when something goes wrong when trying to save a customers tax id number with examples of expected input',
    defaultMessage:
      'Please enter a valid {taxCountry, select, CA {Tax ID} other {VAT number}} (ex: {example})',
  },
  editButton: {
    id: '',
    description: 'text of a button allowing the user to edit a field',
    defaultMessage: 'Edit',
  },
  cancelButton: {
    id: '',
    description:
      'text of a button allowing the user to cancel their current action',
    defaultMessage: 'Cancel',
  },
  updateSettingError: {
    id: '',
    description:
      'error message when something goes wrong when trying to update a setting',
    defaultMessage: 'There was a problem updating this setting.',
  },
  saving: {
    id: '',
    description:
      'tells user that their data is being saved after clicking the save button',
    defaultMessage: 'Saving...',
  },
  unsaved: {
    id: '',
    description:
      'Text under a text area that tells user their edits have not been saved',
    defaultMessage: 'These changes have not been saved',
  },
});

const fallback = 'OFF';
const CONTROL = 'CONTROL';
const V1 = 'V1';
const V2 = 'V2';

class BillingSettings extends React.PureComponent {
  state = {
    isRemovingCard: false,
    showRemoveCardModal: false,
    isSwitchingBillingCycle: false,
    showSwitchBillingPeriodModal: false,
    isBusinessInfoReadOnly: false,
    isBusinessPurposes: this.props.isBusinessPurposes,
    isBusinessPurposesError: '',
    businessNameReadOnly: true,
    businessNameValue: '',
    businessNameSavedMessage: '',
    receiptAddressReadOnly: true,
    addressSavedMessage: '',
    receiptAddressValue: '',
    showTaxIdModal: false,
    showDeleteTaxIdModal: false,
    isDeletingTaxId: false,
    taxId: '',
    taxIdValue: this.props.taxId,
    taxIdSaving: false,
    taxIdErrorMessage: '',
    experimentVariant: fallback,
  };

  hideRemoveCardModal = () => {
    this.setState({
      showRemoveCardModal: false,
    });
  };

  showRemoveCardModal = (evt) => {
    evt.preventDefault();

    this.setState({
      showRemoveCardModal: true,
    });
  };

  updateExperimentVariant = (experimentVariant) => {
    this.setState({ experimentVariant });
  };

  updateCard = (evt) => {
    evt.preventDefault();
    if (this.state.experimentVariant === fallback) {
      // TODO: add PAP logging
    } else if (this.state.experimentVariant === CONTROL) {
      // TODO: add PAP logging
    } else if (this.state.experimentVariant === V1) {
      // TODO: add PAP logging
    } else if (this.state.experimentVariant === V2) {
      // TODO: add PAP logging
    }
    window.location.href = '/account/updateCard';
  };

  hideTaxIdModal = () => {
    this.setState({
      showTaxIdModal: false,
    });
  };

  showTaxIdModal = (evt) => {
    evt.preventDefault();

    this.setState({
      showTaxIdModal: true,
      taxIdErrorMessage: '',
    });
  };

  hideDeleteTaxIdModal = () => {
    this.setState({
      showDeleteTaxIdModal: false,
    });
  };

  showDeleteTaxIdModal = (evt) => {
    evt.preventDefault();

    this.setState({
      showDeleteTaxIdModal: true,
    });
  };

  showSwitchBillingPeriodModal = (evt) => {
    evt.preventDefault();

    this.setState({
      showSwitchBillingPeriodModal: true,
    });
  };

  onSwitchBillingPeriodModalRequestClose = () => {
    this.setState({
      showSwitchBillingPeriodModal: false,
    });
  };

  onSwitchBillingPeriodSuccess = () => {
    this.setState({
      showSwitchBillingPeriodModal: false,
    });

    if (this.props.isMonthly) {
      trackHeapCustomEvent('switch_to_annual_billing_settings');
    } else {
      trackHeapCustomEvent('switch_to_monthly_billing_settings');
    }

    // Reload page.
    document.location.reload();
  };

  onRemoveCardModalRequestClose = () => {
    this.hideRemoveCardModal();
  };

  onTaxIdModalRequestClose = () => {
    this.hideTaxIdModal();
  };

  onDeleteTaxIdModalRequestClose = () => {
    this.hideDeleteTaxIdModal();
  };

  onClickTaxIdSave = async () => {
    const { appActions, intl, taxCountry } = this.props;

    this.setState({
      taxIdSaving: true,
      taxIdErrorMessage: '',
    });

    // if prefix set in VatRules, check if the taxId starts with the prefix
    if (
      this.state.taxId &&
      taxCountry in VatRules &&
      'prefix' in VatRules[taxCountry] &&
      !this.state.taxId.startsWith(VatRules[taxCountry].prefix)
    ) {
      this.setState({
        taxIdErrorMessage: intl.formatMessage(messages.invalidTaxId, {
          taxCountry,
          example:
            taxCountry === 'CA'
              ? 'GST, HST, QST'
              : VatRules[taxCountry].examples.join(', '),
        }),
      });

      this.setState({ taxIdSaving: false });
      return;
    }

    const updateTaxResponse = await appActions.billing.updateTaxId(
      this.state.taxId,
      taxCountry,
    );

    if (updateTaxResponse == null) {
      this.setState({
        taxIdErrorMessage: intl.formatMessage(messages.invalidTaxId, {
          taxCountry,
          example:
            taxCountry === 'CA'
              ? 'GST, HST, QST'
              : VatRules[taxCountry].examples.join(', '),
        }),
      });
    } else {
      this.setState({
        taxId: '',
        taxIdValue: updateTaxResponse.value,
      });
      this.onTaxIdModalRequestClose();
    }

    this.setState({ taxIdSaving: false });
  };

  onDeleteTaxId = () => {
    hsFetch('/endpoint/account/deleteTaxId', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        csrf_token: this.props.app.csrfToken,
      }),
    }).then((response) => {
      response.json().then(() => {
        this.setState({
          taxId: '',
          taxIdValue: '',
        });

        this.onDeleteTaxIdModalRequestClose();
      });
    });
  };

  onRemoveCard = async () => {
    this.setState({ isRemovingCard: true });

    await this.props.appActions.billing.onRemoveCard();

    this.setState({ isRemovingCard: false });

    window.location.reload();
  };

  onIsBusinessPurposesChange = async (evt) => {
    const { intl } = this.props;
    const target = evt.currentTarget;
    const checked = target.checked;

    this.setState({
      isBusinessInfoReadOnly: true,
      isBusinessPurposesError: '',
    });

    const revert = () => {
      target.checked = !checked;
      this.setState({
        isBusinessInfoReadOnly: false,
        isBusinessPurposesError: intl.formatMessage(
          messages.updateSettingError,
        ),
      });
    };

    try {
      const res = await hsFetch('/account/updateIsBusinessPurposes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          csrf_token: this.props.app.csrfToken,
          is_business_purposes: checked,
        }),
      });
      if (res.ok) {
        const obj = await res.json();
        this.setState({
          isBusinessInfoReadOnly: false,
          isBusinessPurposes: obj.value,
        });
      } else {
        revert();
      }
    } catch (e) {
      revert();
    }
  };

  onChangeBusinessName = (evt) => {
    const { intl } = this.props;

    this.setState({
      businessNameValue: evt.target.value,
      businessNameSavedMessage: intl.formatMessage(messages.unsaved),
    });
  };

  onClickBusinessNameSave = async () => {
    if (this.state.businessNameReadOnly) {
      this.setState({
        businessNameReadOnly: false,
      });
    } else {
      const { intl } = this.props;

      this.setState({
        isBusinessInfoReadOnly: true,
        businessNameSavedMessage: intl.formatMessage(messages.saving),
      });

      const revert = () => {
        this.setState({
          isBusinessInfoReadOnly: false,
          businessNameSavedMessage: intl.formatMessage(
            messages.updateSettingError,
          ),
        });
      };

      try {
        const res = await hsFetch('/account/updateBusinessName', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            csrf_token: this.props.app.csrfToken,
            business_name: this.state.businessNameValue,
          }),
        });
        if (res.ok) {
          const obj = await res.json();
          this.setState({
            isBusinessInfoReadOnly: false,
            businessNameReadOnly: true,
            businessNameValue: obj.value,
            businessNameSavedMessage: obj.message,
          });
        } else {
          revert();
        }
      } catch (e) {
        revert();
      }
    }
  };

  onClickReceiptAddressSave = async () => {
    if (this.state.receiptAddressReadOnly) {
      this.setState({
        receiptAddressReadOnly: false,
      });
    } else {
      const { appActions, intl } = this.props;

      this.setState({
        isBusinessInfoReadOnly: true,
        addressSavedMessage: intl.formatMessage(messages.saving),
      });

      const revert = () => {
        this.setState({
          isBusinessInfoReadOnly: false,
          addressSavedMessage: intl.formatMessage(messages.updateSettingError),
        });
      };

      try {
        const obj = await appActions.billing.saveReceiptAddress(
          this.state.receiptAddressValue,
        );

        this.setState({
          isBusinessInfoReadOnly: false,
          receiptAddressReadOnly: true,
          receipt_address: obj.value,
          addressSavedMessage: obj.message,
        });
      } catch (e) {
        revert();
      }
    }
  };

  onChangeReceiptAddress = (evt) => {
    const { intl } = this.props;

    this.setState({
      receiptAddressValue: evt.target.value,
      addressSavedMessage: intl.formatMessage(messages.unsaved),
    });
  };

  onChangeTaxId = (taxId) => {
    this.setState({ taxId });
  };

  render() {
    const {
      hasSubscriptionAccess,
      billingPlans,
      canCancel,
      canChangePlan,
      numSeats,
      numTrialDays,
      teamSize,
      startDate,
      isHf,
      isFreeTrial,
      isFree,
      localizedTotalWithDiscounts,
      localizedTotalWithDiscountsAtRenewal,
      localizedPeriodName,
      numTemplates,
      numTemplatesLeft,
      nextBillingDate,
      renewDate,
      isEligibleToSwitchBillingPeriodInWebApp,
      isMonthly,
      isDelinquent,
      isBillingInfoFake,
      hasBillingInfo,
      hasAlternativeBilling,
      hasBillingInfoAccess,
      isWebPaid,
      lastChargeDate,
      hasAPIBillingPlan,
      isAPIPaid,
      hasWebCoupon,
      webCouponPercentage,
      hasAPICoupon,
      apiCouponPercentage,
      billingPeriodsLeft,
      apiBillingPeriodsLeft,
      ccSuffix,
      ccExpMonth,
      ccExpYear,
      intl,
      hasTeam,
      isOrgAdmin,
      resumeLinkUi,
      resumeLinkApi,
      subEndDate,
      subApiEndDate,
      hfNumFaxPagesPerMonth,
      hfNumFaxPagesLeft,
      hfNumBonusFaxPages,
      hfInviteURL,
      hfIsPaid,
      hfNumExtraPages,
      hfPromoName,
      taxIdSupported,
      taxCountry,
      taxId,
      isBusinessPurposes,
      businessName,
      receiptAddress,
      formattedAltBillingPeriodCharge,
      altBillingPeriodStartDate,
      formattedAltBillingPeriodRefund,
      altBillingPeriodError,
      addonSalesforceUsage,
      addonSalesforceQuota,
      addonOracleCPQUsage,
      addonOracleCPQQuota,
      addonQESUsage,
      addonQESQuota,
      addonDisplayNames,
      addonSmsDeliveryQuota,
      addonSmsDeliveryUsage,
      isAutoDowngrade,
      allowManagePlan,
      isMobilePurchase,
      siteCode,

      // SMS quota
      hasSignerSMSAuth,
      hasUnlimitedSMSAuth,
      signerSMSAuthenticationTotal,
      signerSMSAuthenticationUsed,
      signerSMSAuthenticationLeft,

      // Blueberry
      plan,
      currentPeriod,
      proratio,

      // Safe downgrade
      isFrozen,
      showPauseCancelFlow,
      safeDowngradeStatus,
      pauseDate,
      unpauseDate,

      ...rest
    } = this.props;

    const { taxIdValue } = this.state;
    const isTaxExclusive = !['AU'].includes(taxCountry);
    const shouldDisplayIsBusinessPurposesCheckbox =
      !hasAlternativeBilling && hasBillingInfo;

    let taxIdButtonText;
    if (taxIdValue) {
      taxIdButtonText = intl.formatMessage(messages.editButton);
    } else {
      taxIdButtonText = intl.formatMessage(messages.addTaxId, { taxCountry });
    }

    return (
      <div className="billing-settings">
        {(isHf || hasSubscriptionAccess) && (
          <React.Fragment>
            <AdminSettingsRowGroup flush>
              {() =>
                isHf && !billingPlans ? (
                  <AdminSettingsSimpleRow
                    title={intl.formatMessage(messages.plan)}
                    isPrimary
                  >
                    <AdminSetting className="billing-settings__billing-plan">
                      <Plan
                        allowManagePlan={allowManagePlan}
                        isBillingInfoFake={isBillingInfoFake}
                        hasAlternativeBilling={hasAlternativeBilling}
                        numSeats={numSeats}
                        numTrialDays={numTrialDays}
                        teamSize={teamSize}
                        startDate={startDate}
                        isHf={isHf}
                        isFree={isFree}
                        isFreeTrial={isFreeTrial}
                        numTemplates={numTemplates}
                        numTemplatesLeft={numTemplatesLeft}
                        subEndDate={subEndDate}
                        subApiEndDate={subApiEndDate}
                        hfNumFaxPagesPerMonth={hfNumFaxPagesPerMonth}
                        hfNumFaxPagesLeft={hfNumFaxPagesLeft}
                        hfNumBonusFaxPages={hfNumBonusFaxPages}
                        hfInviteURL={hfInviteURL}
                        hfIsPaid={hfIsPaid}
                        hfNumExtraPages={hfNumExtraPages}
                        hfPromoName={hfPromoName}
                        isMobilePurchase={isMobilePurchase}
                      />
                    </AdminSetting>
                  </AdminSettingsSimpleRow>
                ) : (
                  <AdminSettingsSimpleRow
                    title={intl.formatMessage(
                      billingPlans.length > 1 ? messages.plans : messages.plan,
                    )}
                    isPrimary
                  >
                    {billingPlans.map((bpq, i) => (
                      <AdminSetting
                        className="billing-settings__billing-plan"
                        key={`${i}-${bpq.name}`}
                        customCol={
                          <React.Fragment>
                            {/* eslint-disable-next-line max-len */}
                            {canCancel && bpq.isCanceled && !isDelinquent && (
                              // If the user is not delinquent, and the current
                              // plan's end date is not null, then show the
                              // the "Resume" button.
                              <Button
                                data-qa-ref="resume-plan-btn"
                                data-testid="resume-plan-btn"
                                buttonColor="white"
                                buttonBorderColor="white"
                                buttonTextColor="castle-rock"
                                buttonHoverTextColor="castle-rock"
                                buttonHoverColor="warm-chinchilla"
                                buttonHoverBorderColor="warm-chinchilla"
                                buttonSize="sm"
                                buttonLink={
                                  bpq.isApi ? resumeLinkApi : resumeLinkUi
                                }
                                buttonText={intl.formatMessage(
                                  messages.resumePlan,
                                )}
                              />
                            )}
                            {/* eslint-disable-next-line max-len */}
                            {!isMobilePurchase && !bpq.isCanceled && (
                              <React.Fragment>
                                {canCancel &&
                                  !Object.values(
                                    SafeDowngradeStatusEnum,
                                  ).includes(safeDowngradeStatus) &&
                                  ((bpq.isApi && isAPIPaid) ||
                                    (!bpq.isApi && isWebPaid)) && (
                                    <CancelPlanButton
                                      isApi={bpq.isApi}
                                      siteCode={siteCode}
                                      showPauseCancelFlow={showPauseCancelFlow}
                                    />
                                  )}

                                {/* Safe downgrade flow buttons */}
                                {safeDowngradeStatus ===
                                  SafeDowngradeStatusEnum.PAUSED && (
                                  <ResumePausedButton
                                    isApi={bpq.isApi}
                                    siteCode={siteCode}
                                  />
                                )}
                                {safeDowngradeStatus ===
                                  SafeDowngradeStatusEnum.PENDING_PAUSE && (
                                  <CancelPauseButton />
                                )}

                                {canChangePlan && (
                                  <Button
                                    data-qa-ref="change-plan-btn"
                                    data-testid="change-plan-btn"
                                    buttonClass="billing-settings__col-button"
                                    buttonSize="sm"
                                    buttonColor="white"
                                    buttonBorderColor="ocean-blue"
                                    buttonTextColor="ocean-blue"
                                    buttonHoverColor="ocean-blue"
                                    buttonHoverBorderColor="ocean-blue"
                                    buttonLink={bpq.changePlanLink}
                                    buttonText={intl.formatMessage(
                                      messages.changePlan,
                                    )}
                                  />
                                )}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        }
                      >
                        <Plan
                          {...bpq}
                          allowManagePlan={allowManagePlan}
                          isBillingInfoFake={isBillingInfoFake}
                          hasAlternativeBilling={hasAlternativeBilling}
                          numSeats={numSeats}
                          numTrialDays={numTrialDays}
                          teamSize={teamSize}
                          startDate={startDate}
                          isHf={isHf}
                          isFree={isFree}
                          isFreeTrial={isFreeTrial}
                          numTemplates={numTemplates}
                          numTemplatesLeft={numTemplatesLeft}
                          subEndDate={subEndDate}
                          subApiEndDate={subApiEndDate}
                          hfNumFaxPagesPerMonth={hfNumFaxPagesPerMonth}
                          hfNumFaxPagesLeft={hfNumFaxPagesLeft}
                          hfNumBonusFaxPages={hfNumBonusFaxPages}
                          hfInviteURL={hfInviteURL}
                          hfIsPaid={hfIsPaid}
                          hfNumExtraPages={hfNumExtraPages}
                          hfPromoName={hfPromoName}
                          hasSignerSMSAuth={
                            hasSignerSMSAuth &&
                            !addonDisplayNames.addon_smstools
                          }
                          hasUnlimitedSMSAuth={hasUnlimitedSMSAuth}
                          signerSMSAuthenticationTotal={
                            signerSMSAuthenticationTotal
                          }
                          signerSMSAuthenticationUsed={
                            signerSMSAuthenticationUsed
                          }
                          signerSMSAuthenticationLeft={
                            signerSMSAuthenticationLeft
                          }
                          isMobilePurchase={isMobilePurchase}
                          safeDowngradeStatus={safeDowngradeStatus}
                          pauseDate={pauseDate}
                          unpauseDate={unpauseDate}
                        />
                      </AdminSetting>
                    ))}
                  </AdminSettingsSimpleRow>
                )
              }
            </AdminSettingsRowGroup>
            {hasSubscriptionAccess && (
              <React.Fragment>
                <AdminSettingsRowGroup flush>
                  {() => (
                    <AdminSettingsSimpleRow
                      title={intl.formatMessage(messages.addonInformation)}
                      isPrimary
                    >
                      <AdminSetting
                        customCol={
                          <Button
                            data-qa-ref="contact-us-addon-btn"
                            data-testid="contact-us-addon-btn"
                            buttonColor="white"
                            buttonBorderColor="white"
                            buttonTextColor="ocean-blue"
                            buttonHoverTextColor="ocean-blue"
                            buttonHoverColor="white"
                            buttonHoverBorderColor="white"
                            buttonSize="sm"
                            buttonLink={HfReactHelper.getWebflowUrl(
                              'form/contact-us-general',
                            )}
                            buttonText={intl.formatMessage(
                              messages.contactUsAddon,
                            )}
                          />
                        }
                      >
                        <AddonInfo
                          addonSalesforceUsage={addonSalesforceUsage}
                          addonSalesforceQuota={addonSalesforceQuota}
                          addonOracleCPQUsage={addonOracleCPQUsage}
                          addonOracleCPQQuota={addonOracleCPQQuota}
                          addonQESUsage={addonQESUsage}
                          addonQESQuota={addonQESQuota}
                          addonDisplayNames={addonDisplayNames}
                          addonSmsDeliveryQuota={addonSmsDeliveryQuota}
                          addonSmsDeliveryUsage={addonSmsDeliveryUsage}
                        />
                      </AdminSetting>
                    </AdminSettingsSimpleRow>
                  )}
                </AdminSettingsRowGroup>
              </React.Fragment>
            )}
            {hasSubscriptionAccess && (
              <AdminSettingsRowGroup flush>
                {() => (
                  <AdminSettingsSimpleRow
                    title={intl.formatMessage(messages.billingCycle)}
                    isPrimary
                  >
                    <AdminSetting
                      customCol={
                        isEligibleToSwitchBillingPeriodInWebApp && (
                          <Button
                            data-qa-ref="switch-billing-period"
                            data-testid="switch-billing-period"
                            disabled={this.state.isSwitchingBillingCycle}
                            onClick={this.showSwitchBillingPeriodModal}
                            buttonSize="sm"
                            buttonColor="white"
                            buttonBorderColor="ocean-blue"
                            buttonTextColor="ocean-blue"
                            buttonHoverColor="ocean-blue"
                            buttonHoverBorderColor="ocean-blue"
                            buttonText={
                              isMonthly
                                ? intl.formatMessage(messages.switchToAnnual)
                                : intl.formatMessage(messages.switchToMonthly)
                            }
                          />
                        )
                      }
                    >
                      <BillingCycle
                        billingPlans={billingPlans || []}
                        isFreeTrial={isFreeTrial}
                        localizedPeriodName={localizedPeriodName}
                        localizedTotalWithDiscounts={
                          localizedTotalWithDiscounts
                        }
                        localizedTotalWithDiscountsAtRenewal={
                          localizedTotalWithDiscountsAtRenewal
                        }
                        startDate={startDate}
                        isFree={isFree}
                        nextBillingDate={nextBillingDate}
                        renewDate={renewDate}
                        hasWebCoupon={hasWebCoupon}
                        webCouponPercentage={webCouponPercentage}
                        hasAPICoupon={hasAPICoupon}
                        apiCouponPercentage={apiCouponPercentage}
                        billingPeriodsLeft={billingPeriodsLeft}
                        apiBillingPeriodsLeft={apiBillingPeriodsLeft}
                        hasAlternativeBilling={hasAlternativeBilling}
                        isDelinquent={isDelinquent}
                        subEndDate={subEndDate}
                        subApiEndDate={subApiEndDate}
                        isAutoDowngrade={isAutoDowngrade}
                        isTaxExclusive={isTaxExclusive}
                        isFrozen={isFrozen}
                      />
                    </AdminSetting>
                  </AdminSettingsSimpleRow>
                )}
              </AdminSettingsRowGroup>
            )}
          </React.Fragment>
        )}
        {hasBillingInfoAccess && (
          <React.Fragment>
            <AdminSettingsRowGroup flush>
              {() => (
                <AdminSettingsSimpleRow
                  title={intl.formatMessage(messages.billingInformation)}
                  isPrimary
                >
                  <AdminSetting
                    customCol={
                      <React.Fragment>
                        {hasBillingInfo && isFree && !isBillingInfoFake && (
                          <Button
                            onClick={this.showRemoveCardModal}
                            data-qa-ref="remove-card-btn"
                            data-testid="remove-card-btn"
                            buttonColor="white"
                            buttonBorderColor="white"
                            buttonTextColor="castle-rock"
                            buttonHoverTextColor="castle-rock"
                            buttonHoverColor="warm-chinchilla"
                            buttonHoverBorderColor="warm-chinchilla"
                            buttonSize="sm"
                            buttonText={intl.formatMessage(messages.removeCard)}
                          />
                        )}
                        <Button
                          onClick={this.updateCard}
                          data-qa-ref="update-card"
                          data-testid="update-card"
                          buttonClass="billing-settings__col-button"
                          buttonSize="sm"
                          buttonColor="white"
                          buttonBorderColor="ocean-blue"
                          buttonTextColor="ocean-blue"
                          buttonHoverColor="ocean-blue"
                          buttonHoverBorderColor="ocean-blue"
                          buttonText={
                            hasBillingInfo && !isBillingInfoFake
                              ? intl.formatMessage(messages.updateBilling)
                              : intl.formatMessage(messages.addBilling)
                          }
                        />
                      </React.Fragment>
                    }
                  >
                    <BillingInfo
                      hasBillingInfo={hasBillingInfo}
                      billingPlans={billingPlans || []}
                      isBillingInfoFake={isBillingInfoFake}
                      hasAPIBillingPlan={hasAPIBillingPlan}
                      hasTeam={hasTeam}
                      isOrgAdmin={isOrgAdmin}
                      ccSuffix={ccSuffix}
                      ccExpMonth={ccExpMonth}
                      ccExpYear={ccExpYear}
                      isDelinquent={isDelinquent}
                      taxCountry={taxCountry}
                      updateExperimentVariant={this.updateExperimentVariant}
                    />
                  </AdminSetting>
                </AdminSettingsSimpleRow>
              )}
            </AdminSettingsRowGroup>
            {taxIdSupported && (
              <AdminSettingsRowGroup flush>
                {() => (
                  <AdminSettingsSimpleRow
                    title={intl.formatMessage(messages.taxIdTitle, {
                      taxCountry,
                    })}
                    isPrimary
                  >
                    <AdminSetting
                      customCol={
                        <React.Fragment>
                          {this.state.taxIdValue && (
                            <Button
                              onClick={this.showDeleteTaxIdModal}
                              data-qa-ref="remove-card-btn"
                              data-testid="remove-card-btn"
                              buttonColor="white"
                              buttonBorderColor="white"
                              buttonTextColor="castle-rock"
                              buttonHoverTextColor="castle-rock"
                              buttonHoverColor="warm-chinchilla"
                              buttonHoverBorderColor="warm-chinchilla"
                              buttonSize="sm"
                              buttonText={intl.formatMessage(messages.delete)}
                            />
                          )}
                          <Button
                            onClick={this.showTaxIdModal}
                            data-qa-ref="update-tax-id-btn"
                            data-testid="update-tax-id-btn"
                            buttonClass="billing-settings__col-button"
                            buttonSize="sm"
                            buttonColor="white"
                            buttonBorderColor="ocean-blue"
                            buttonTextColor="ocean-blue"
                            buttonHoverColor="ocean-blue"
                            buttonHoverBorderColor="ocean-blue"
                            buttonText={taxIdButtonText}
                          />
                        </React.Fragment>
                      }
                    >
                      {this.state.taxIdValue}
                    </AdminSetting>
                  </AdminSettingsSimpleRow>
                )}
              </AdminSettingsRowGroup>
            )}
            <AdminSettingsRowGroup flush bordlerless>
              {() => (
                <AdminSettingsSimpleRow
                  title={intl.formatMessage(messages.billingReceipts)}
                  isPrimary
                >
                  <AdminSetting>
                    <Invoices
                      isWebPaid={isWebPaid}
                      lastChargeDate={lastChargeDate}
                      hasAPIBillingPlan={hasAPIBillingPlan}
                      isAPIPaid={isAPIPaid}
                    />
                  </AdminSetting>
                </AdminSettingsSimpleRow>
              )}
            </AdminSettingsRowGroup>
            {shouldDisplayIsBusinessPurposesCheckbox && (
              <div>
                <AdminSettingsRowGroup flush bordlerless>
                  {() => (
                    <AdminSettingsSimpleRow
                      title={intl.formatMessage(messages.businessInfo)}
                      isPrimary
                    >
                      <Checkbox
                        id="business-purpose-checkbox"
                        disabled={this.state.isBusinessInfoReadOnly}
                        defaultChecked={isBusinessPurposes}
                        onChange={this.onIsBusinessPurposesChange}
                      />
                      <label
                        htmlFor="business-purpose-checkbox"
                        className="billing-settings__for-business-label"
                      >
                        <FormattedMessage
                          id=""
                          description={
                            'Label for a checkbox where the user indicates that ' +
                            'they plan to use the service for business purposes.'
                          }
                          defaultMessage="I plan to use {brandName} for business purposes"
                          values={{
                            brandName: getBrandName(siteCode),
                          }}
                        />
                      </label>
                      {this.state.isBusinessPurposesError && (
                        <Text size="small" color="error" tagName="div">
                          {this.state.isBusinessPurposesError}
                        </Text>
                      )}
                    </AdminSettingsSimpleRow>
                  )}
                </AdminSettingsRowGroup>

                {this.state.isBusinessPurposes && (
                  <AdminSettingsRowGroup flush bordlerless>
                    {() => (
                      <AdminSettingsSimpleRow
                        title={intl.formatMessage(messages.businessName)}
                        tooltip={intl.formatMessage(
                          messages.businessInfoTooltip,
                        )}
                        isPrimary
                      >
                        <AdminSetting
                          customCol={
                            <React.Fragment>
                              <Button
                                data-qa-ref="save-billing-name"
                                data-testid="save-billing-name"
                                buttonClass="billing-settings__col-button"
                                onClick={this.onClickBusinessNameSave}
                                buttonSize="sm"
                                buttonColor="white"
                                buttonBorderColor="ocean-blue"
                                buttonTextColor="ocean-blue"
                                buttonHoverColor="ocean-blue"
                                buttonHoverBorderColor="ocean-blue"
                                disabled={this.state.isBusinessInfoReadOnly}
                                buttonText={
                                  this.state.businessNameReadOnly
                                    ? intl.formatMessage(messages.editButton)
                                    : intl.formatMessage(messages.saveButton)
                                }
                              />
                            </React.Fragment>
                          }
                        >
                          <TextInput
                            id="business-name"
                            name="business-name"
                            defaultValue={businessName}
                            onChange={this.onChangeBusinessName}
                            disabled={this.state.businessNameReadOnly}
                            data-testid="billing-name"
                            data-qa-ref="billing-name"
                            className="billing-settings__input"
                          />
                        </AdminSetting>
                        <div className="state-message">
                          <small>{this.state.businessNameSavedMessage}</small>
                        </div>
                      </AdminSettingsSimpleRow>
                    )}
                  </AdminSettingsRowGroup>
                )}
              </div>
            )}
            <AdminSettingsRowGroup flush bordlerless>
              {() => (
                <AdminSettingsSimpleRow
                  title={
                    shouldDisplayIsBusinessPurposesCheckbox &&
                    this.state.isBusinessPurposes
                      ? intl.formatMessage(messages.businessAddress)
                      : intl.formatMessage(messages.receiptAddress)
                  }
                  tooltip={intl.formatMessage(messages.businessInfoTooltip)}
                  isPrimary
                  id="receipt-memo"
                >
                  <AdminSetting
                    customCol={
                      <React.Fragment>
                        <Button
                          data-qa-ref="save-receipt-address"
                          data-testid="save-receipt-address"
                          buttonClass="billing-settings__col-button"
                          onClick={this.onClickReceiptAddressSave}
                          buttonSize="sm"
                          buttonColor="white"
                          buttonBorderColor="ocean-blue"
                          buttonTextColor="ocean-blue"
                          buttonHoverColor="ocean-blue"
                          buttonHoverBorderColor="ocean-blue"
                          disabled={this.state.isBusinessInfoReadOnly}
                          buttonText={
                            this.state.receiptAddressReadOnly
                              ? intl.formatMessage(messages.editButton)
                              : intl.formatMessage(messages.saveButton)
                          }
                        />
                      </React.Fragment>
                    }
                  >
                    <ReceiptAddress
                      onChange={this.onChangeReceiptAddress}
                      receiptAddress={receiptAddress}
                      message={this.state.addressSavedMessage}
                      disabled={this.state.receiptAddressReadOnly}
                    />
                  </AdminSetting>
                </AdminSettingsSimpleRow>
              )}
            </AdminSettingsRowGroup>
          </React.Fragment>
        )}
        <HelloModal
          contentLabel={intl.formatMessage(messages.removeCard)}
          isOpen={this.state.showRemoveCardModal}
          onRequestClose={this.onRemoveCardModalRequestClose}
          buttons={[
            {
              type: 'secondary',
              text: intl.formatMessage(messages.nevermind),
              onClick: this.onRemoveCardModalRequestClose,
            },
            {
              type: 'primary',
              text: intl.formatMessage(messages.removeCard),
              onClick: this.onRemoveCard,
            },
          ]}
        >
          <p>
            <FormattedMessage
              id=""
              description="alert, billing settings page, asks user if user really wants to delete billing info"
              defaultMessage="Are you sure you want to remove your billing information from the system?"
            />
          </p>
        </HelloModal>
        {taxIdSupported && (
          <div>
            <HelloModal
              contentLabel={intl.formatMessage(messages.updateTaxId, {
                taxCountry,
              })}
              isOpen={this.state.showTaxIdModal}
              onRequestClose={this.onTaxIdModalRequestClose}
              buttons={[
                {
                  type: 'secondary',
                  text: intl.formatMessage(messages.cancelButton),
                  onClick: this.onTaxIdModalRequestClose,
                },
                {
                  type: 'primary',
                  text: intl.formatMessage(messages.saveButton),
                  onClick: this.onClickTaxIdSave,
                  disabled: this.state.taxIdSaving,
                },
              ]}
            >
              {taxCountry in countries && (
                <div>
                  {intl.formatMessage(messages.taxIdTitle, { taxCountry })} (
                  {intl.formatMessage(countries[taxCountry])})
                </div>
              )}
              <TaxId countryCode={taxCountry} onChange={this.onChangeTaxId} />
              <div className="hello-modal__error">
                <small>{this.state.taxIdErrorMessage}</small>
              </div>
            </HelloModal>
            <HelloModal
              contentLabel={intl.formatMessage(messages.deleteTaxId, {
                taxCountry,
              })}
              isOpen={this.state.showDeleteTaxIdModal}
              onRequestClose={this.onDeleteTaxIdModalRequestClose}
              buttons={[
                {
                  type: 'secondary',
                  text: intl.formatMessage(messages.cancelButton),
                  onClick: this.onDeleteTaxIdModalRequestClose,
                },
                {
                  type: 'primary',
                  text: intl.formatMessage(messages.confirmDelete),
                  onClick: this.onDeleteTaxId,
                },
              ]}
            >
              <p>
                <FormattedMessage
                  id=""
                  description="alert, billing settings page, asks user if user really wants to delete their tax ID/VAT number"
                  defaultMessage="This could impact any applicable taxes for future charges."
                />
              </p>
            </HelloModal>
          </div>
        )}
        {billingPlans && this.state.showSwitchBillingPeriodModal ? (
          <SwitchBillingPeriodModal
            isOpen
            isTrialing={isFreeTrial}
            isMonthly={isMonthly}
            chargeAmount={formattedAltBillingPeriodCharge}
            startDate={altBillingPeriodStartDate}
            refund={formattedAltBillingPeriodRefund}
            planName={billingPlans[0].name}
            numTrialDays={numTrialDays}
            error={altBillingPeriodError}
            csrfToken={rest.app.csrfToken}
            onRequestClose={this.onSwitchBillingPeriodModalRequestClose}
            onSwitchBillingPeriodSuccess={this.onSwitchBillingPeriodSuccess}
            isTaxExclusive={isTaxExclusive}
          />
        ) : null}
      </div>
    );
  }
}

export default function BillingSettingsWrapper(props) {
  const appActions = useAppContext();
  const intl = useIntl();

  return <BillingSettings {...props} intl={intl} appActions={appActions} />;
}
