import classNames from 'classnames';
import React from 'react';
import styles from './line-divider.module.scss';

type Props = {
  className?: string;
  color?: 'faint' | 'standard';
  variant?: 'horizontal' | 'vertical';
};
export function LineDivider({ className, color, variant }: Props) {
  return (
    <div
      className={classNames(className, styles.lineDivider, {
        [styles.faint]: color === 'faint',
        [styles.vertical]: variant === 'vertical',
      })}
    />
  );
}
