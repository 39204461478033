import React, { useCallback, useState } from 'react';
import { Button } from '@dropbox/dig-components/buttons';
import { FormattedMessage } from 'react-intl';

import { confirmCancelPause } from '../../../hellospa/components/cancellation-flow-modal/utils';

/**
 * This component allows users to cancel their current pending pause status
 * @constructor
 */
const CancelPauseButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onButtonPress = useCallback(async () => {
    setIsLoading(true);
    await confirmCancelPause();
    window.location.reload();
    setIsLoading(false);
  }, []);

  return (
    <>
      <Button
        variant="opacity"
        onClick={onButtonPress}
        data-qa-ref="cancel-pause-btn"
        type="button"
        disabled={isLoading}
      >
        <FormattedMessage
          id=""
          defaultMessage="Cancel pause"
          description="Text for a button which allows the user to cancel their pending pause status."
        />
      </Button>
    </>
  );
};

export default CancelPauseButton;
