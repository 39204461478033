/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-extraneous-dependencies,
  import/no-named-as-default,
  max-len,
  prefer-const,
  react/prop-types
*/
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import './enterprise-agreement-pricing-summary.scss';

const messages = defineMessages({
  yourPriceLabel: {
    id: '',
    description:
      'pricing page, enterprise quote, label, shows section where details about the users price for a period.',
    defaultMessage: 'Your Price',
  },
  usersDiscountAmount: {
    id: '',
    description:
      'pricing page, enterprise quote, pricing discount percentage, shows discount that will be applied.',
    defaultMessage: '{couponAmount, number, percent} off',
  },
  currentPeriodDates: {
    id: '',
    description:
      'pricing page, enterprise quote, dates of the current period, shows dates, could be free trial, discount, or regular price.',
    defaultMessage:
      '{currentPeriodStartDate, date, medium} - {currentPeriodEndDate, date, medium}',
  },
  currentPeriodDateStartOnly: {
    id: '',
    description: 'pricing page, enterprise quote, current period start.',
    defaultMessage: 'Starting {currentPeriodStartDate, date, medium}',
  },
  defaultAdditionalInfo: {
    id: '',
    description:
      'pricing page, enterprise quote, default additional info about the enterprise quote.',
    defaultMessage:
      'You will be charged {charge}, plus applicable taxes, {periodName, select, year {per year} other {per month}}.',
  },
  additionalInfoCouponPeriod: {
    id: '',
    description:
      'pricing page, enterprise quote, additional info about the coupon period after free trial ends.',
    defaultMessage:
      'Starting {nextPeriodStartDate, date, medium}, with your {couponAmount, number, percent} coupon you will be charged {formattedDiscountedTotalRate} for the billing period ending {discountPeriodEnd, date, medium}. ',
  },
  additionalInfoPayingPeriod: {
    id: '',
    description:
      'pricing page, enterprise quote, additional info about the coupon period after free trial ends.',
    defaultMessage:
      'Starting {previousPeriodEndDate, date, medium}, you will be charged {listPrice} {periodName, select, year {per year} other {per month}}.',
  },
  additionalInfoCouponPeriodTaxCopy: {
    id: '',
    description:
      'pricing page, enterprise quote, additional info about the coupon period after free trial ends.',
    defaultMessage:
      'Starting {nextPeriodStartDate, date, medium}, with your {couponAmount, number, percent} coupon you will be charged {formattedDiscountedTotalRate}, plus applicable taxes, for the billing period ending {discountPeriodEnd, date, medium}. ',
  },
  additionalInfoPayingPeriodTaxCopy: {
    id: '',
    description:
      'pricing page, enterprise quote, additional info about the coupon period after free trial ends.',
    defaultMessage:
      'Starting {previousPeriodEndDate, date, medium}, you will be charged {listPrice}, plus applicable taxes, {periodName, select, year {per year} other {per month}}.',
  },
  additionalInfoNoCcFreeTrial: {
    id: '',
    description:
      'pricing page, enterprise quote, additional info about the coupon period after free trial ends.',
    defaultMessage:
      'Your {numFreeTrialDays, number}-day free trial starts today. On {nextPeriodStartDate, date, medium}, your plan will be downgraded to a free plan.',
  },
});

const cloneDate = (d) => new Date(d.getTime());
const isYearlyPlan = (periodName) => periodName === 'year';
const isPerpetualCoupon = (couponInfo) => couponInfo.duration === 9999;

function addMonths(amount, date) {
  const d = cloneDate(date);
  d.setMonth(d.getMonth() + amount);
  return d;
}

function addDays(count, date) {
  const d = cloneDate(date);
  d.setDate(d.getDate() + count);
  return d;
}

function addYears(count, date) {
  const d = cloneDate(date);
  d.setFullYear(d.getFullYear() + count);
  return d;
}

function subtractDays(count, date) {
  const d = cloneDate(date);
  d.setDate(d.getDate() - count);
  return d;
}

/**
 * Computes the date ranges for each scenario based on the defined scenarios.
 *
 * TODO: I'd like to try and find a cleaner way to write this section of code.
 */
function computeDateRanges(
  now,
  periodName,
  couponInfo,
  isFreeTrial,
  numFreeTrialDays,
) {
  let freeTrialStart;
  let freeTrialEnd;
  let freeTrialLength;
  let couponStart;
  let couponEnd;
  let listStart;

  // Pointer to the date that represents a day before the next range's start
  // date.  In each range, we compute the start date by adding one to this
  // pointer
  let nextEndDate = subtractDays(1, now);

  // Month offset is used to compute the coupon end date if applicable.
  let couponMonthOffset = 0;

  if (isFreeTrial) {
    freeTrialStart = addDays(1, nextEndDate);

    // For free trials, we're including the last day so we
    // subtract 1 day from numFreeTrialDays
    freeTrialLength = numFreeTrialDays - 1;

    freeTrialEnd = addDays(freeTrialLength, freeTrialStart);

    nextEndDate = freeTrialEnd;
  }

  if (couponInfo) {
    couponStart = addDays(1, nextEndDate);

    if (isYearlyPlan(periodName)) {
      couponMonthOffset = couponInfo.duration < 12 ? 12 : couponInfo.duration;
    } else {
      couponMonthOffset = couponInfo.duration;
    }

    couponEnd = subtractDays(1, addMonths(couponMonthOffset, couponStart));
    nextEndDate = couponEnd;
  }

  listStart = addDays(1, nextEndDate);

  return {
    freeTrialPrice: {
      start: freeTrialStart,
      end: freeTrialEnd,
    },
    couponPrice: {
      start: couponStart,
      end: couponEnd,
    },
    listPrice: {
      start: listStart,
    },
  };
}

/**
 * Displays the pricing info to the user for enterprise agreements.  There are a
 * lot of different rendering variations depending on the coupon, free trial,
 * and billing plan period.  Please see the tests for the different scenarios.
 */
export default function EnterpriseAgreementPricingSummary(props) {
  const {
    couponInfo,
    now = new Date(), // Helps for testing
    formattedDiscountedTotalRate,
    periodName,
    formattedTotalRate,
    isFreeTrial,
    numFreeTrialDays,
    noCcFreeTrial,
  } = props;

  const intl = useIntl();
  const { freeTrialPrice, couponPrice, listPrice } = computeDateRanges(
    now,
    periodName,
    couponInfo,
    isFreeTrial,
    numFreeTrialDays,
  );

  const shouldHideListPrice = couponInfo && isPerpetualCoupon(couponInfo);
  let userRate;
  let couponAmount = null;
  let currentPeriodStartDate;
  let currentPeriodEndDate;
  let discountPeriodEnd;

  if (couponInfo) {
    if (isYearlyPlan(periodName)) {
      const billingPeriods = Math.ceil(couponInfo.duration / 12);
      currentPeriodStartDate = couponPrice.start;
      currentPeriodEndDate = addYears(
        billingPeriods,
        new Date(couponPrice.start),
      );
      discountPeriodEnd = currentPeriodEndDate;
      couponAmount = couponInfo.annual_percentage;
    } else {
      currentPeriodStartDate = couponPrice.start;
      currentPeriodEndDate = couponPrice.end;
      discountPeriodEnd = couponPrice.end;
      couponAmount = couponInfo.percentage;
    }
    couponAmount /= 100;
  } else {
    currentPeriodStartDate = listPrice.start;
    currentPeriodEndDate = null;
  }

  if (isFreeTrial) {
    currentPeriodStartDate = freeTrialPrice.start;
    currentPeriodEndDate = freeTrialPrice.end;
    userRate = HfReactHelper.formatCurrency(intl, 0);
  } else {
    userRate = formattedDiscountedTotalRate;
  }

  if (shouldHideListPrice) return <></>;

  return (
    <div className="m-ent-plan-summary">
      <div className="m-ent-plan-pricing-summary">
        <div className="m-ent-plan-pricing-summary--pill">
          <span>{intl.formatMessage(messages.yourPriceLabel)}</span>
        </div>
        <div className="m-ent-plan-pricing-summary--users-price">
          {userRate}
        </div>
        <div className="m-ent-plan-pricing-summary--users-price-description" />
        {!!couponAmount && !isFreeTrial && (
          <div className="m-ent-plan-pricing-summary--users-discount-amount">
            {intl.formatMessage(messages.usersDiscountAmount, { couponAmount })}
          </div>
        )}
        <div className="m-ent-plan-pricing-summary--period-dates">
          {currentPeriodStartDate && currentPeriodEndDate
            ? intl.formatMessage(messages.currentPeriodDates, {
                currentPeriodStartDate,
                currentPeriodEndDate: isFreeTrial
                  ? currentPeriodEndDate
                  : addDays(-1, discountPeriodEnd),
              })
            : intl.formatMessage(messages.currentPeriodDateStartOnly, {
                currentPeriodStartDate,
              })}
        </div>
      </div>
      {isFreeTrial || couponInfo ? (
        <div className="m-ent-plan-pricing-additional-info">
          {noCcFreeTrial ? (
            intl.formatMessage(messages.additionalInfoNoCcFreeTrial, {
              numFreeTrialDays,
              nextPeriodStartDate: addDays(1, currentPeriodEndDate),
            })
          ) : (
            <React.Fragment>
              {isFreeTrial &&
                couponInfo &&
                intl.formatMessage(messages.additionalInfoCouponPeriodTaxCopy, {
                  nextPeriodStartDate: currentPeriodEndDate,
                  discountPeriodEnd: isFreeTrial
                    ? subtractDays(1, discountPeriodEnd)
                    : discountPeriodEnd,
                  couponAmount,
                  formattedDiscountedTotalRate,
                })}
              {intl.formatMessage(messages.additionalInfoPayingPeriodTaxCopy, {
                periodName,
                previousPeriodEndDate: couponInfo
                  ? subtractDays(1, discountPeriodEnd)
                  : currentPeriodEndDate,
                listPrice: formattedTotalRate,
              })}
            </React.Fragment>
          )}
        </div>
      ) : (
        <div className="m-ent-plan-pricing-additional-info">
          {intl.formatMessage(messages.defaultAdditionalInfo, {
            periodName,
            charge: formattedTotalRate,
          })}
        </div>
      )}
    </div>
  );
}

EnterpriseAgreementPricingSummary.propTypes = {
  couponInfo: PropTypes.shape({
    duration: PropTypes.number,
  }),
  isFreeTrial: PropTypes.bool,
  numFreeTrialDays: PropTypes.number,
  /**
   * This is included as a prop for ease
   * of testing, but it will default to the current date for the general case.
   */
  now: PropTypes.instanceOf(Date),
  periodName: PropTypes.oneOf(['month', 'year']).isRequired,
  formattedTotalRate: PropTypes.string.isRequired,
  formattedDiscountedTotalRate: PropTypes.string,
  noCcFreeTrial: PropTypes.bool,
};
