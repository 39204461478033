import React from 'react';
import classnames from 'classnames';
import styles from './toggle.scss';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  indeterminate?: boolean;
  id: string;
  className?: string;
};

export default function Toggle({
  indeterminate = false,
  id,
  className = '',
  ...rest
}: Props) {
  const input: React.RefObject<HTMLInputElement> = React.useRef(null);

  const handleKeyPress = React.useCallback((e) => {
    if (e.key === ' ') {
      e.preventDefault();
      input.current!.click();
    }
  }, []);

  return (
    <React.Fragment>
      <input
        id={id}
        ref={input}
        className={styles.checkbox}
        type="checkbox"
        {...rest}
      />
      <label
        tabIndex={0}
        className={classnames(styles.label, className, {
          [styles.indeterminate]: indeterminate,
        })}
        onKeyPress={handleKeyPress}
        htmlFor={id}
      />
    </React.Fragment>
  );
}
