import React from 'react';
import { useFeatureFlag } from 'signer-app/context/feature-flags';
import { useLatestRef } from 'signer-app/utils/use-latest-ref';
import {
  loadArkoseCdnScript,
  Enforcement,
} from 'signer-app/authentication/arkose';

const SKIP_LIST = new RegExp(
  /.+[+].*@(hellosign.com|hellodocument.com|dbx51.com)$/,
);

/**
 * This hook will intercept the form submission and run Arkose CAPTCHA
 * to surface a token that can be used to in server-side verification
 *
 * We bypass coverage on this because it uses a third-party script that we
 * don't control
 */
/* istanbul ignore next */
export function useArkoseIntercept(
  handleSubmit: (data: { arkoseToken?: string }) => void,
  email: string,
  arkoseEnabled?: boolean,
  arkosePublicKey?: string,
) {
  const useNewArkoseHostname = useFeatureFlag(
    'sign_services_20240328_change_arkose_cdn_script_hostname',
  );
  const [arkoseToken, setToken] = React.useState(undefined);
  const [arkoseEnforcement, setEnforcement] =
    React.useState<null | Enforcement>(null);

  // This will get closured into `onCompleted`, so it needs to be a ref that can
  // continue to be updated. No matter the status
  const intercept = useLatestRef(() => {
    if (
      arkoseEnabled &&
      // arkose can't really run in storybook/tests
      !IS_STORYBOOK &&
      NODE_ENV !== 'test'
    ) {
      if (!arkoseEnforcement) {
        throw new Error("arkose enforcement isn't ready");
      }

      if (!SKIP_LIST.test(email) && !arkoseToken) {
        // Save the form values in the state so we can use them after verification
        return arkoseEnforcement.run();
      }
      return handleSubmit({
        arkoseToken,
      });
    }

    return handleSubmit({});
  });

  // Load enforcement as soon as it's enabled
  React.useEffect(() => {
    if (
      arkoseEnabled &&
      // arkose can't really run in storybook/tests
      !IS_STORYBOOK &&
      NODE_ENV !== 'test' &&
      arkosePublicKey &&
      arkoseEnforcement == null
    ) {
      loadArkoseCdnScript(
        arkosePublicKey,
        (enforcement: Enforcement) => {
          enforcement.setConfig({
            data: {},
            onCompleted: (response: any) => {
              setToken(response.token);
              intercept.current();
            },
            onHide: () => {},
            onReady: () => {},
            onReset: () => {
              setToken(undefined);
            },
            onShow: () => {},
            onShown: () => {},
            onSuppress: () => {},
          });
          // Add the enforcement to the state so we can use it later
          setEnforcement(enforcement);
        },
        useNewArkoseHostname,
      );
    }
  });

  return React.useCallback(() => {
    intercept.current();
  }, [intercept]);
}
