/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies
*/
// needed for react intl otherwise stuff busts

import React from 'react';
import { Provider } from 'react-redux';
import { FeatureFlagProvider } from 'js/sign-components/common/feature-flag';
import { debugTestIds } from 'hello-react/components/debug-test-ids';
import { isProduction } from 'js/sign-components/common/ts-utils';
import {
  // eslint-disable-next-line camelcase
  PAP_Eng_RenderReactComponent,
} from 'pap-events/sign/eng_render_react_component';
import { logPAPEvent } from 'js/sign-components/common/product-analytics-pipeline';
import { getSignComponentFromString } from 'pap-events/enums/sign_component-utils';

import ReactDOM from 'react-dom';
import { constructStore } from 'hellospa/redux/store';
import HSIntlProvider from 'hellospa/common/hs-intl-provider';
import useDiv from 'js/sign-components/common/use-div';
import { buildWebAppClient } from 'hello-react/web-app-client/build-web-app-client';
import { SignAppClientProvider } from 'js/sign-components/sign-app-client/context';
import { ToursProvider } from 'js/sign-components/sign-app-client/tours-provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Application from './detached-application';

module.exports = Application;

// All HelloSign components need to be attached to the application for legacy
// purposes.
Application.components = require('./components');

const singletonMap = new Map();
function useSingleton(Component) {
  const state = React.useState(Math.random());
  if (!singletonMap.has(Component)) {
    singletonMap.set(Component, state);
  }
  return singletonMap.get(Component) === state;
}

function ShowPHPFlag() {
  const div = useDiv();
  const singleton = useSingleton(ShowPHPFlag);

  try {
    // I'm not sure if Safari might ever throw when trying to access
    // localStorage, so I'm using this try/catch to make sure this doesn't break
    // even if we can't access localStorage
    if (singleton && localStorage.showPHPFlag === 'true') {
      return ReactDOM.createPortal(
        <div
          title="This page uses React components in a PHP template"
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            backgroundColor: 'red',
            padding: '0.5em',
            zIndex: 9999,
          }}
        >
          PHP Template
        </div>,
        div,
      );
    }
  } catch (e) {
    // ignore
  }

  return null;
}

function SingletonTestIds() {
  const singleton = useSingleton(SingletonTestIds);
  return <>{singleton && debugTestIds}</>;
}

let webAppClientSingleton;
function getWebAppClient() {
  webAppClientSingleton ??= buildWebAppClient(
    // This ID is for Prep&Send, so it shouldn't be relevant to any PHP pages
    '',
  );
  return webAppClientSingleton;
}

const queryClient = new QueryClient({
  // Although networkMode is a great idea in theory, headless selenium tests trick react-query
  // into thinking there is no network connection and all queries are 'paused'
  // https://tanstack.com/query/v4/docs/react/guides/network-mode
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      staleTime: Infinity,
      networkMode: 'always',
      retry: false, // don't retry on failure
      refetchOnWindowFocus: false, // don't refetch when a window/tab is refocused
      refetchOnReconnect: false, // don't refetch if the user regains internet connection
    },
  },
});

let storeSingleton;
// I'm wrapping these here instead of inside ./components so that they only get
// the provider when called from PHP's render_react_components. If there is code
// somewhere in `hellosign-web` that imports components, it should already have
// a provider near the top of the tree.
Application.components = Object.keys(Application.components).reduce(
  (componentMap, name) => {
    const Component = Application.components[name];
    storeSingleton ??= constructStore();

    // When using `render_react_component` on the backend, you need to pass
    // `featureFlags` if any component in the tree accesses feature flags.  If you
    // do not pass feature flags and something down the tree attempts to acccess
    // them, it will throw an error. This is because I believe it's better to
    // crash early and try to get developers to fix the problem instead of
    // allowing the user to have a feature flag, that works in some scenarios and
    // doesn't in others.
    const WrapperComponent = ({ featureFlags = null, ...props }) => {
      const allFlags = React.useMemo(() => {
        return {
          ...window.featureFlags,
          ...featureFlags,
        };
      }, [featureFlags]);
      React.useEffect(() => {
        logPAPEvent(
          PAP_Eng_RenderReactComponent({
            signComponent: getSignComponentFromString(name),
          }),
          undefined,
          undefined,
        );
      }, []);

      return (
        <QueryClientProvider client={queryClient}>
          <SignAppClientProvider client={getWebAppClient()}>
            <ToursProvider
              completedTours={
                [
                  /**
                   * This is intentionally left empty. There's no way to know from here
                   * what tours have been completed, but not having this context can crash
                   * the page.
                   */
                ]
              }
            >
              <FeatureFlagProvider featureFlags={allFlags}>
                <React.Suspense fallback={<React.Fragment />}>
                  {!isProduction() && <ShowPHPFlag />}
                  <SingletonTestIds />
                  <HSIntlProvider>
                    <Provider store={storeSingleton}>
                      <Component {...props} />
                    </Provider>
                  </HSIntlProvider>
                </React.Suspense>
              </FeatureFlagProvider>
            </ToursProvider>
          </SignAppClientProvider>
        </QueryClientProvider>
      );
    };

    WrapperComponent.displayName = `Wrapped(${name})`;

    componentMap[name] = WrapperComponent;
    return componentMap;
  },
  {},
);

Application.mixins = {
  AdjustScrollingMixin: require('./components/api-docs/api-menu/adjust-scrolling-mixin'),
};
