import React from 'react';
import DropDownMenuItem from './drop-down-item';

const DropDownMenu = (props) => {
  const dropDownMenuLinks = props.dropDownMenuLinkData.map(
    (dropdownArray, k) => {
      const dropDownItems = dropdownArray.map((dropDownMenuLink, i) => {
        return (
          <div key={i} className="fn-dropdown-col-item">
            <DropDownMenuItem
              daCategory={props.daCategory}
              daAction={props.daAction}
              isMobile={props.isMobile}
              value={dropDownMenuLink}
              index={i}
            />
          </div>
        );
      });

      return (
        <div key={k} style={props.style} className="fn-dropdown-col">
          {dropDownItems}
        </div>
      );
    },
  );

  return (
    <div className="fn-dropdown-grid">
      {props.children}
      {dropDownMenuLinks}
    </div>
  );
};

export default DropDownMenu;
