import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { RadioButton } from '@dropbox/dig-components/controls';
import { Text } from '@dropbox/dig-components/typography';
import { ReasonProps, values } from './types';
import styles from '../survey.module.scss';
import { getBrandName } from 'js/sign-components/common/brand';

const messages = defineMessages({
  featuresPlaceholder: {
    id: '',
    description:
      'Placeholder text for a text area when users can provide more information about why they are cancelling their subscription.',
    defaultMessage: 'The features I want are ...',
  },
});

function CancelReasonFeatures({ siteCode, contextRef, ...props }: ReasonProps) {
  const intl = useIntl();

  return (
    <div className={styles.reason}>
      <div className={styles.reasonGroup}>
        <RadioButton
          {...props}
          className={styles.reasonButton}
          data-qa-ref="cancel-subscription-survey-reason-features"
          id="cancel-subscription-survey__radio-features"
          name="reason"
          value={values.FEATURES}
        />
        <div className={styles.contextContainer}>
          <Text
            tagName="label"
            htmlFor="cancel-subscription-survey__radio-features"
          >
            <FormattedMessage
              id=""
              description="An option the user can select when explaining why s/he is canceling their subscription."
              defaultMessage="{brandName} features weren't right for me"
              values={{
                brandName: getBrandName(siteCode),
              }}
            />
          </Text>
          {props.checked && (
            <div className={styles.reasonContext}>
              <TextArea
                data-qa-ref="cancel-subscription-survey-reason-features-context"
                ref={contextRef}
                id="cancel-subscription-survey__context-features"
                rows={3}
                disabled={props.disabled}
                placeholder={intl.formatMessage(messages.featuresPlaceholder)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CancelReasonFeatures;
