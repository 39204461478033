/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len
*/
import './index.scss';
import React, { Component } from 'react';
import Logger from 'common/logger';
import PlanDescription from './cancel-offer-plan-description';
import PricingLevel from './cancel-offer-pricing-level';

class CustomerCancelOffer extends Component {
  componentDidMount() {
    Logger.track('CustomerCancelOffer.load');
  }

  render() {
    const headerText = (
      <div className="m-customer-cancel-offer--header-text text-center">
        <h1>Wait. Before you leave...</h1>
        <h2 className="subheader-text">
          Canceling your account will prevent you from accessing some of our
          greatest features
        </h2>
      </div>
    );

    const mainContent = (
      <div className="m-customer-cancel-offer--main-content">
        <PlanDescription {...this.props} />
        <PricingLevel {...this.props} />
      </div>
    );

    return (
      <div className="m-customer-cancel-offer">
        {headerText}
        {mainContent}
      </div>
    );
  }
}

module.exports = CustomerCancelOffer;
