import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Banner } from '@dropbox/dig-components/banner';
import { Title, Text } from '@dropbox/dig-components/typography';
import { PictogramIcon } from '@dropbox/dig-icons';
import { InfoPictogram } from '@dropbox/dig-icons/assets';

const messages = defineMessages({
  freeTrialEndingTitle: {
    id: '',
    description: 'Banner title warning of a Free Trial ending soon',
    defaultMessage: 'Your free trial is expiring soon',
  },
  freeTrialEndingWarning: {
    id: '',
    description:
      'Message notifying customer that their free trial will be ending on a given date',
    defaultMessage:
      'You will be charged for renewal on <b>{formattedFreeTrialEndDate}</b>.',
  },
});

type Props = {
  freeTrialEndDateInMs?: number;
  onRequestClose?: () => void;
};

const FreeTrialEndingBanner = ({
  freeTrialEndDateInMs,
  onRequestClose,
}: Props) => (
  <Banner
    variant="rich"
    type="attention"
    withCloseButton="true"
    onRequestClose={onRequestClose}
    withLeftPictogram={
      <PictogramIcon src={InfoPictogram} role="presentation" />
    }
  >
    <Banner.Message>
      <Title size="small">
        {useIntl().formatMessage(messages.freeTrialEndingTitle)}
      </Title>

      <Text>
        {useIntl().formatMessage(messages.freeTrialEndingWarning, {
          formattedFreeTrialEndDate: useIntl().formatDate(
            freeTrialEndDateInMs,
            {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            },
          ),
          b: (...chunks: any[]) => (
            <Text key="bold-text" isBold>
              {chunks}
            </Text>
          ),
        })}
      </Text>
    </Banner.Message>
  </Banner>
);
export default FreeTrialEndingBanner;
