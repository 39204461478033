/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  global-require,
  guard-for-in,
  import/no-extraneous-dependencies,
  no-restricted-syntax,
  no-script-url,
  react/no-find-dom-node,
  react/prop-types
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import TetherToggle from 'hello-ui/tether-toggle';
import createReactClass from 'create-react-class';
import StyleProvider from 'hello-ui/style';
import { FormattedMessage } from 'react-intl';
import { getBrandName, siteCodes } from 'js/sign-components/common/brand';
import classNames from 'classnames';
import Logo from 'signer-app/parts/logo-v2';

import styles from './footer.module.css';
import './index.scss';

const MenuLink = (props) => (
  <a
    className={classNames(styles.menuItem, 'm-app-footer--menu-item')}
    display="block"
    target="_blank"
    {...props}
  />
);

// In-app footer
const AppFooter = createReactClass({
  propTypes: {
    siteCode: PropTypes.string.isRequired, // S, F, or W
    termsURL: PropTypes.string.isRequired,
    privacyURL: PropTypes.string.isRequired,
  },

  SOCIAL_NAMES: ['linkedin', 'fb', 'twitter'],
  SOCIAL_URLS: {
    twitter: 'https://twitter.com/DropboxSign',
    fb: 'https://www.facebook.com/dropboxsign',
    linkedin: 'https://www.linkedin.com/company/dropboxsign',
  },

  getInitialState() {
    return {
      showProductMenu: false,
      showFinePrintMenu: false,
    };
  },

  getDefaultProps() {
    return {
      siteCode: siteCodes.sign,
    };
  },

  componentDidMount() {
    const root = $(ReactDOM.findDOMNode(this));
    $('.product-link', root).hover(this.toggleProductMenu);
    $('.fine-print-link', root).hover(this.toggleFinePrintMenu);
  },

  toggleProductMenu() {
    const show = !this.state.showProductMenu;
    this.setState({
      showProductMenu: show,
    });
  },

  toggleFinePrintMenu() {
    const show = !this.state.showFinePrintMenu;
    this.setState({
      showFinePrintMenu: show,
    });
  },

  render() {
    const { responsive } = this.props;
    const cmsSubDomain = 'www';
    const site = HfReactHelper.HfSites.getSite(this.props.siteCode);
    const isHf = HfReactHelper.isHelloFax();
    const pricingUrl = HfReactHelper.urlHelper('info/pricing', site);
    const hfHomeUrl = HfReactHelper.urlHelper(
      'home/bridge/to/F',
      HfReactHelper.HfSites.SITE_CODE_HS,
    );
    const hsHomeUrl = HfReactHelper.urlHelper(
      'home/bridge/to/S',
      HfReactHelper.HfSites.SITE_CODE_HF,
    );
    const helpUrl = HfReactHelper.urlHelper('home/help', site);
    const bridgeUrl = isHf ? hsHomeUrl : hfHomeUrl;

    const socialImageUrls = {
      twitter: require('./twitter@2x.png'),
      fb: require('./fb@2x.png'),
      linkedin: require('./linkedin@2x.png'),
    };

    // Social links
    let name;
    const social = [];
    for (const i in this.SOCIAL_NAMES) {
      name = this.SOCIAL_NAMES[i];
      social.push(
        <div
          key={`social-${i}`}
          className={classNames(
            'm-app-footer--social--icon',
            styles.socialIcon,
          )}
        >
          <a
            href={this.SOCIAL_URLS[name]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="m-app-footer--social--icon--img"
              src={socialImageUrls[name]}
            />
          </a>
        </div>,
      );
    }

    // Product menu
    const items = [
      isHf ? undefined : (
        <MenuLink
          key={0}
          href={HfReactHelper.urlHelper('api', site, cmsSubDomain)}
        >
          <FormattedMessage
            id=""
            description="webapp, homepage footer product link dd api"
            defaultMessage="API"
          />
        </MenuLink>
      ),
      isHf ? undefined : (
        <MenuLink
          key={1}
          href={HfReactHelper.urlHelper('gmail', site, cmsSubDomain)}
        >
          <FormattedMessage
            id=""
            description="webapp, homepage footer product link dd gmail"
            defaultMessage="Gmail"
          />
        </MenuLink>
      ),
      <MenuLink
        key={2}
        href={HfReactHelper.urlHelper('googledocs', site, cmsSubDomain)}
      >
        <FormattedMessage
          id=""
          description="webapp, homepage footer product link dd google docs"
          defaultMessage="Google Docs"
        />
      </MenuLink>,
      <MenuLink
        key={3}
        href={HfReactHelper.urlHelper('googledrive', site, cmsSubDomain)}
      >
        <FormattedMessage
          id=""
          description="webapp, homepage footer product link dd google drive"
          defaultMessage="Google Drive"
        />
      </MenuLink>,
      isHf ? undefined : (
        <MenuLink
          key={4}
          href={HfReactHelper.urlHelper('features/mobile', site, cmsSubDomain)}
        >
          <FormattedMessage
            id=""
            description="webapp, homepage footer product link dd mobile"
            defaultMessage="Mobile"
          />
        </MenuLink>
      ),
      <MenuLink
        key={5}
        href={bridgeUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {getBrandName(this.props.siteCode)}
      </MenuLink>,
    ];

    // Fine print menu
    const finePrintItems = [
      <MenuLink
        key={0}
        href={HfReactHelper.urlHelper('info/security', site, cmsSubDomain)}
      >
        <FormattedMessage
          id=""
          description="webapp, homepage footer fine print link dd security"
          defaultMessage="Security"
        />
      </MenuLink>,
      isHf ? undefined : (
        <MenuLink
          key={1}
          href={HfReactHelper.urlHelper('info/legal', site, cmsSubDomain)}
        >
          <FormattedMessage
            id=""
            description="webapp, homepage footer fine print link dd legal"
            defaultMessage="Legal"
          />
        </MenuLink>
      ),
      <MenuLink key={2} href={this.props.privacyURL}>
        <FormattedMessage
          id=""
          description="webapp, homepage footer fine print link dd privacy"
          defaultMessage="Privacy"
        />
      </MenuLink>,
      <MenuLink key={3} href={this.props.termsURL}>
        <FormattedMessage
          id=""
          description="webapp, homepage footer fine print link dd terms"
          defaultMessage="Terms"
        />
      </MenuLink>,
    ];

    return (
      <StyleProvider>
        <div
          className={classNames(
            styles.footerContainer,
            'm-app-footer--container',
            {
              [styles.responsive]: responsive,
            },
          )}
        >
          <div className={classNames(styles.footer)}>
            <div
              className={classNames(styles.footerLogo, 'm-app-footer--logo')}
            >
              <Logo siteCode={this.props.siteCode} variant="glyph" />
            </div>
            <div className={classNames(styles.footerLinks, '_footer__links')}>
              <div
                className={classNames(
                  styles.linkContainer,
                  'm-app-footer--links--link',
                )}
              >
                <a target="_blank" href={pricingUrl} rel="noreferrer">
                  <FormattedMessage
                    id=""
                    description="webapp, homepage footer pricing link"
                    defaultMessage="Pricing"
                  />
                </a>
              </div>
              <div
                className={classNames(
                  styles.linkContainer,
                  'm-app-footer--links--link product-link',
                )}
              >
                <TetherToggle
                  anchor={
                    <a>
                      <FormattedMessage
                        id=""
                        description="webapp, homepage footer products link"
                        defaultMessage="Products"
                      />
                    </a>
                  }
                  position="top"
                  justify="center"
                >
                  {items}
                </TetherToggle>
              </div>
              <div
                className={classNames(
                  styles.linkContainer,
                  'm-app-footer--links--link fine-print-link',
                )}
              >
                <TetherToggle
                  anchor={
                    <a>
                      <FormattedMessage
                        id=""
                        description="webapp, homepage footer fine print link"
                        defaultMessage="Fine print"
                      />
                    </a>
                  }
                  position="top"
                  justify="center"
                >
                  {finePrintItems}
                </TetherToggle>
              </div>
              <div
                className={classNames(
                  styles.linkContainer,
                  'm-app-footer--links--link',
                )}
              >
                <a target="_blank" href={helpUrl} rel="noreferrer">
                  <FormattedMessage
                    id=""
                    description="webapp, homepage footer help link"
                    defaultMessage="Help"
                  />
                </a>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.footerSocial,
              '_footer__social m-app-footer--social',
            )}
          >
            {social}
          </div>
        </div>
      </StyleProvider>
    );
  },
});

module.exports = AppFooter;
