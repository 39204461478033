import React, { useEffect } from 'react';
import {
  Tooltip,
  ControlVariant,
  TooltipRefObject,
} from '@dropbox/dig-components/tooltips';
import { Title, Text } from '@dropbox/dig-components/typography';
import { Button } from '@dropbox/dig-components/buttons';
import { OverlayPlacement } from '@dropbox/dig-components/overlay';
import { useEventListener } from 'signer-app/utils/use-event-listener';
import { useLatestRef } from 'js/sign-components/common/use-latest-ref';
import styles from 'js/sign-components/tour/styles.module.css';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import {
  useIsTourCompleted,
  useBackendTourServiceMigrator,
  ActiveTourGate,
  useCompleteTour,
} from 'js/sign-components/tour/useTour';
import { TourName } from 'js/sign-components/common/TourName';
import { trackHeapCustomEvent } from 'js/sign-components/common/heap';

const messages = defineMessages({
  tourLabel: {
    id: '',
    defaultMessage: 'New Feature Tour',
    description:
      'ARIA label for the tour tooltips we use when demonstrating new features',
  },
});

/**
 * `tourItem` is the `data-tour-item={whatever}` that needs to be attached to tour
 * targets.
 */
export type Step = {
  tourItem: string;
  content:
    | JSX.Element
    | string
    | (({
        nextStep,
        prevStep,
      }: {
        nextStep: () => void;
        prevStep: () => void;
      }) => JSX.Element);
  title?: JSX.Element | string;
  cta?: JSX.Element;
  closeCta?: JSX.Element;
  stepCounter?: boolean;
  placement?: OverlayPlacement;
  variant?: ControlVariant;
  withDefaultCtas?: boolean;
  disableAdvanceOnTargetClick?: boolean;
  maxWidth?: number;
};

const stepDefaults: Partial<Step> = {
  variant: 'rich',
  stepCounter: true,
  disableAdvanceOnTargetClick: true,
  closeCta: (
    <FormattedMessage
      id=""
      description="Label of button that prematurely ends product tour when clicked"
      defaultMessage="Skip"
    />
  ),
  cta: (
    <FormattedMessage
      id=""
      description="Label of button that goes to next step of product tour when clicked"
      defaultMessage="Next"
    />
  ),
};

const finalStepDefaults: Partial<Step> = {
  ...stepDefaults,
  closeCta: undefined,
  cta: (
    <FormattedMessage
      id=""
      description="Label of button on last step of tour that ends tour when clicked"
      defaultMessage="Done"
    />
  ),
};

type TourProps = {
  steps: Array<Step | undefined>;
  tourName: TourName;
  disableBackend?: boolean;
};

type TourTooltipProps = {
  idx: number;
  tourName: string;
  totalSteps: number;
  step: Step;
  open: boolean;
  variant?: ControlVariant;
  prevStep: () => void;
  nextStep: () => void;
  endTour: (args: { heapEventName: string }) => void;
  disableAdvanceOnTargetClick?: boolean;
  maxWidth?: number;
};

function useDelegatedClick(
  target: Element | null,
  callback: (event: Event) => void,
) {
  const targetRef = useLatestRef(target);

  useEventListener(document.body, 'mousedown', (event) => {
    if (targetRef.current && event.target) {
      const clickedInTarget = targetRef.current.contains(event.target as Node);
      if (clickedInTarget) {
        callback(event);
      }
    }
  });
}

const TourTooltip = ({
  idx,
  tourName,
  totalSteps,
  step,
  open,
  variant,
  prevStep,
  nextStep,
  endTour,
  disableAdvanceOnTargetClick,
  maxWidth,
}: TourTooltipProps) => {
  const [placement, setPlacement] = React.useState(step.placement || 'bottom');
  const [target, setTarget] = React.useState<Element | null>(null);
  const ref = React.useRef<TooltipRefObject>(null);
  const triggerRef = useLatestRef(target);
  const intl = useIntl();

  React.useEffect(() => {
    const domSelector =
      step !== null ? `[data-tour-item~="${step?.tourItem}"]` : '';

    const t = document.querySelector(domSelector);
    triggerRef.current = t;
    setTarget(t);

    /**
     * This interval is necessary to deal with a FOUC
     * issue where styling is not yet loaded, and the tooltip
     * is positioned incorrectly. It runs for 1 second, and the
     * interval exists until the Tour component unmounts.
     */
    let count = 0;
    const timer = setInterval(() => {
      if (count++ < 5) {
        ref?.current?.update();
      } else {
        // Need this to clean up after count reaches 5.
        // Otherwise timer will only be removed on unmount.
        clearInterval(timer);
      }
    }, 200);

    // Cleanup timer when component unmounts
    return function onCleanup() {
      clearInterval(timer);
    };
  }, [step, triggerRef]);

  useDelegatedClick(target, () => {
    if (!disableAdvanceOnTargetClick && open) {
      nextStep();
    }
  });

  const hasCurrentStep = !!step;
  useEffect(() => {
    if (hasCurrentStep) {
      trackHeapCustomEvent('Tour tooltip is visible', {
        tourName,
        step: idx + 1,
        totalSteps,
      });
    }
  }, [hasCurrentStep, tourName, totalSteps, idx]);

  if (!step) {
    return null;
  }

  return (
    <Tooltip.Control
      ref={ref}
      placement={placement}
      onChangePlacement={setPlacement}
      triggerRef={triggerRef}
      variant={variant || undefined}
      open={open}
      shouldReturnFocus
      auto={true}
      data-qa-ref={`tour-tooltip-control-${idx}`}
      maxWidth={maxWidth}
    >
      <div
        role="dialog"
        aria-label={intl.formatMessage(messages.tourLabel)}
        data-qa-ref={`tour-tooltip-${idx}`}
      >
        {step.title && (
          <Title size="medium" inverse data-qa-ref="tour-tooltip-title">
            {step.title}
          </Title>
        )}

        <Text
          tagName="p"
          color="standard"
          inverse
          data-qa-ref="tour-tooltip-text"
        >
          {typeof step.content === 'function'
            ? step.content({ nextStep, prevStep })
            : step.content}
        </Text>

        <div className={styles.footer}>
          <div data-qa-ref="step-counter" className={styles.footerSection}>
            {step.stepCounter && (
              <Text tagName="p" color="faint" inverse>
                <FormattedMessage
                  id=""
                  description="Shows current progress of product tour"
                  defaultMessage="{currentStep, number} of {totalSteps, number}"
                  values={{
                    currentStep: idx + 1,
                    totalSteps,
                  }}
                />
              </Text>
            )}
          </div>
          <div className={styles.footerSection}>
            {step.closeCta && (
              <Button
                variant="transparent"
                onClick={() => endTour({ heapEventName: 'Tour skip click' })}
                inverse
                className={styles.cta}
                data-qa-ref="tour-tooltip-close-cta"
              >
                {step.closeCta}
              </Button>
            )}
            {step.cta && (
              <Button
                variant="outline"
                onClick={nextStep}
                inverse
                autoFocus
                className={styles.cta}
                data-qa-ref="tour-tooltip-cta"
              >
                {step.cta}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Tooltip.Control>
  );
};

function Tour({ steps, tourName, disableBackend = false }: TourProps) {
  const isCompleted = useIsTourCompleted(tourName);
  const [stepIndex, setStepIndex] = React.useState<number>(0);
  const completeTour = useCompleteTour();

  useBackendTourServiceMigrator(tourName, disableBackend);

  // steps is really an Array<Steps>, but I needed to add the undefined to its
  // type to make TS recognize that currentStep may be undefined after
  // completing last step
  const currentStep = steps[stepIndex];
  const isFinalStep = stepIndex === steps.length - 1;

  // When tour completes...
  React.useEffect(() => {
    if (stepIndex === steps.length) {
      trackHeapCustomEvent('Tour closing', {
        tourName,
        step: stepIndex + 1,
        totalSteps: steps.length,
      });
      completeTour(tourName, disableBackend);
    }
  }, [stepIndex, steps.length, tourName, completeTour, disableBackend]);

  // click handlers...
  const endTour = React.useCallback(
    (args: { heapEventName: string }) => {
      setStepIndex(steps.length);
      trackHeapCustomEvent(args.heapEventName, {
        tourName,
        step: stepIndex,
      });
    },
    [tourName, stepIndex, steps.length],
  );

  const nextStep = React.useCallback(() => {
    trackHeapCustomEvent('Tour next click', {
      tourName,
      step: stepIndex + 1,
      totalSteps: steps.length,
    });
    setStepIndex((stepIdx) => {
      return stepIdx + 1;
    });
  }, [tourName, stepIndex, steps.length]);

  const prevStep = React.useCallback(() => {
    trackHeapCustomEvent('Tour previous click', {
      tourName,
      step: stepIndex,
      totalSteps: steps.length,
    });
    setStepIndex((stepIdx) => Math.min(stepIdx - 1, 0));
  }, [tourName, stepIndex, steps.length]);

  if (!currentStep || isCompleted) {
    return null;
  }
  const _defaults = isFinalStep ? finalStepDefaults : stepDefaults;
  const step: Step = {
    ...(currentStep.withDefaultCtas ? _defaults : {}),
    ...currentStep,
  };
  return (
    <ActiveTourGate tourName={tourName} disableBackend={disableBackend}>
      <TourTooltip
        key={stepIndex}
        idx={stepIndex}
        tourName={tourName}
        totalSteps={steps.length}
        step={step}
        variant={step?.variant}
        nextStep={nextStep}
        prevStep={prevStep}
        endTour={endTour}
        disableAdvanceOnTargetClick={currentStep?.disableAdvanceOnTargetClick}
        maxWidth={step.maxWidth}
        open
      />
    </ActiveTourGate>
  );
}

export default Tour;

export function buildTourSteps<S extends string>(
  tourName: string,
  steps: Array<S>,
): Record<S, string> {
  // I need to cast this because TS recognizes it doesn't contain all the keys
  // yet.
  const obj = {} as Record<S, string>;
  steps.forEach((stepName) => {
    obj[stepName] = `${tourName}.${stepName}`;
  });

  return obj;
}
