/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!  WARNING: Do NOT edit this file!  !!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * Foundation components are not intended to be edited to
 * satisfy specific use cases. If you would like to propose
 * a change to this component, please reach out to a
 * frontend engineer.
 *
 * @author Nathan Buchar <nathan.buchar@hellosign.com>
 */

import React from 'react';
import classnames from 'classnames';

import styles from './body.scss';

export enum BodyType {
  Default,
  Large,
  Tiny,
  Dim,
  Action,
  Error,
}

type BodyProps = React.HTMLAttributes<HTMLParagraphElement> &
  React.PropsWithChildren<{
    kind?: BodyType;
  }>;

function Body({
  kind = BodyType.Default,
  className = '',
  children,
  ...props
}: BodyProps) {
  return (
    <p
      className={classnames(styles.body, className, {
        [styles.large]: kind === BodyType.Large,
        [styles.tiny]: kind === BodyType.Tiny,
        [styles.dim]: kind === BodyType.Dim,
        [styles.action]: kind === BodyType.Action,
        [styles.error]: kind === BodyType.Error,
      })}
      {...props}
    >
      {children}
    </p>
  );
}

export default Body;
