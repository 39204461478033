/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  import/no-extraneous-dependencies,
  max-len,
  no-script-url,
  react/no-find-dom-node,
  react/no-string-refs
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import setTimeout from 'common/utils/set-timeout';
import createReactClass from 'create-react-class';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine } from '@dropbox/dig-icons/assets';

const messages = defineMessages({
  twoFactorInstructionsApp: {
    id: '',
    defaultMessage:
      'Please enter the code from your 2-Factor Authentication application to log in.',
    description: 'Description text for 2 factor app authentication action.',
  },
  twoFactorInstructionsPhone: {
    id: '',
    defaultMessage:
      'We just sent you a text message to (***) ***-**{phone} with your code. Please enter the code to log in.',
    description: 'Description text for 2 factor app phone action.',
  },
  enterCodePlaceholder: {
    id: '',
    defaultMessage: 'Enter code',
    description:
      'placeholder text in input field, where user will input auth code',
  },
  close: {
    id: '',
    description: 'Close button',
    defaultMessage: 'Close',
  },
});

/* -----------  LOGIN  ------------------------------------ */

const LogInVerificationForm = createReactClass({
  propTypes: {
    errorMsg: PropTypes.string,
    redirectUrl: PropTypes.string,
    tabOffset: PropTypes.number,
    emailAddress: PropTypes.string.isRequired,
    rememberMe: PropTypes.bool.isRequired,
    remember2FA: PropTypes.bool.isRequired,
    siteCode: PropTypes.string.isRequired,
    signature: PropTypes.string.isRequired,
    phoneNumberLast2: PropTypes.string,
    isSmsEnabled: PropTypes.bool.isRequired,
    isAuthenticatorEnabled: PropTypes.bool.isRequired,
    isDeepIntegration: PropTypes.bool,
    csrfToken: PropTypes.string,
    intl: PropTypes.object.isRequired,
  },

  componentDidMount() {
    const self = this;
    const root = $(ReactDOM.findDOMNode(this));
    const codeInput = root.find('.m-login--verification--code input');

    setTimeout(() => {
      codeInput.focus();
    }, 500);

    const overlayElem = $('.m-login--verification--links--modal');

    if (overlayElem && overlayElem.overlay) {
      $('.m-login--verification--links--modal').overlay({
        mask: 'rgba(100, 100, 100, 0.65)',
        speed: 0,
        onClose() {
          self.hideHelp();
        },
      });
    }
  },

  onSubmit() {},

  resendCode(evt) {
    evt.preventDefault();
    const root = $(ReactDOM.findDOMNode(this));
    $("input[name='resend']", root).val('1');
    $('#login_form', root).submit();
  },

  enterRecoveryCode(evt) {
    evt.preventDefault();
    const root = $(ReactDOM.findDOMNode(this));
    $('.m-login--verification--instructions', root).hide();
    $('.m-login--verification--recovery', root).show();
    $('.m-login--verification--code input', root).attr(
      'placeholder',
      'Enter recovery code',
    );
    this.hideHelp();
  },

  showHelp(evt) {
    evt.preventDefault();
    const root = $(ReactDOM.findDOMNode(this));
    $('.m-login--verification--links--modal', root).show();
    $('#problemLink', root).hide();
  },

  hideHelp() {
    const root = $(ReactDOM.findDOMNode(this));
    $('.m-login--verification--links--modal', root).hide();
    $('#problemLink', root).show();
  },

  hideHelpWithoutProblemLink(evt) {
    evt.preventDefault();
    const root = $(ReactDOM.findDOMNode(this));
    $('.m-login--verification--links--modal', root).hide();
  },

  getPhoneNumberLast2Safe() {
    // Make sure we're only getting 2 characters. Since we're getting the prop from query params, it prevents text injection. (DEV-2313)
    return this.props.phoneNumberLast2
      ? this.props.phoneNumberLast2.substr(0, 2)
      : null;
  },

  render() {
    const { intl } = this.props;
    const targetUrl = HfReactHelper.urlHelper(
      `${this.props.isDeepIntegration ? 'dropbox' : 'account'}/logInVerification`,
      this.props.siteCode,
    );
    const tabOffset = parseInt(this.props.tabOffset || 0, 10);
    const err = this.props.errorMsg ? (
      <p className="m-login--verification--error">{this.props.errorMsg}</p>
    ) : null;

    const verificationInstructions = (
      <span>
        {' '}
        {this.props.isAuthenticatorEnabled
          ? intl.formatMessage(messages.twoFactorInstructionsApp)
          : intl.formatMessage(messages.twoFactorInstructionsPhone, {
              phone: this.getPhoneNumberLast2Safe(),
            })}{' '}
      </span>
    );

    return (
      <div className="m-login is-verification">
        <form
          ref="login_form"
          className="m-login--form"
          id="login_form"
          action={targetUrl}
          method="POST"
        >
          <div>
            <div className="columns small-12">
              <h3 className="m-login--verification--title">
                <FormattedMessage
                  id=""
                  description="Title for 2-step verification"
                  defaultMessage="2-step account verification"
                />
              </h3>
            </div>
            <p className="columns small-12 l-text-centered m-login--verification--instructions">
              {verificationInstructions}
            </p>
            <p className="columns small-12 l-text-centered m-login--verification--recovery">
              <FormattedMessage
                id=""
                description="Message requesting recovery code for 2-factor authentication"
                defaultMessage="Enter the recovery code that was given to you when you enabled 2-Factor Authentication."
              />
              &nbsp;
              <b>
                <FormattedMessage
                  id=""
                  description="Message suggesting that recovery code will be void and 2-factor authentication will be disabled"
                  defaultMessage="The code will become void and 2-Factor Authentication will be disabled."
                />
              </b>
            </p>

            {this.props.csrfToken ? (
              <input
                type="hidden"
                name="csrf_token"
                value={this.props.csrfToken}
              />
            ) : (
              ''
            )}
            {this.props.phoneNumberLast2 ? (
              <input
                type="hidden"
                name="verification[last2]"
                value={this.getPhoneNumberLast2Safe()}
              />
            ) : (
              ''
            )}
            <input
              type="hidden"
              name="verification[email_address]"
              value={this.props.emailAddress}
            />
            <input
              type="hidden"
              name="verification[rm]"
              value={this.props.rememberMe ? '1' : '0'}
            />
            <input
              type="hidden"
              name="verification[redirect_url]"
              value={
                this.props.redirectUrl
                  ? this.props.redirectUrl.replace(/&amp;/g, '&')
                  : this.props.redirectUrl
              }
            />
            <input
              type="hidden"
              name="verification[signature]"
              value={this.props.signature}
            />
            <input
              type="hidden"
              name="verification[isSmsEnabled]"
              value={this.props.isSmsEnabled ? '1' : '0'}
            />
            <input
              type="hidden"
              name="verification[isAuthenticatorEnabled]"
              value={this.props.isAuthenticatorEnabled ? '1' : '0'}
            />
            <input type="hidden" name="resend" value="0" />

            <div className="m-login--verification--code">
              <input
                type="text"
                name="verification[code]"
                className="m-login--text-input"
                placeholder={intl.formatMessage(messages.enterCodePlaceholder)}
                maxLength="6"
                tabIndex={tabOffset}
              />
              {err}
            </div>

            <div className={'small-10 small-offset-1 text-center'}>
              <input
                type="checkbox"
                id="trust-device-checkbox"
                name="verification[remember2FA]"
                defaultChecked={this.props.remember2FA}
              />
              <label htmlFor="trust-device-checkbox">
                <FormattedMessage
                  id=""
                  description="Label for remember checkbox on 2-factor authentication"
                  defaultMessage="Remember this device for 30 days"
                />
              </label>
            </div>

            <div
              className={'small-10 small-offset-1 text-center submit-button'}
            >
              <Button
                data-testid="login-verification-submit"
                onClick={this.onSubmit}
                type="submit"
                buttonTabIndex={tabOffset + 1}
                variant="primary"
              >
                <FormattedMessage
                  id=""
                  description="button text in 2-auth page, verifies the code that user inputted"
                  defaultMessage="VERIFY"
                />
              </Button>
            </div>
            <div className="m-login--verification--links">
              <Button onClick={this.showHelp} variant="transparent">
                <FormattedMessage
                  id=""
                  description="link text in 2-auth page, when clicked takes user to help page"
                  defaultMessage="Problems with your code?"
                />
              </Button>
              <div className="m-login--verification--links--modal form-overlay">
                <IconButton
                  onClick={this.hideHelpWithoutProblemLink}
                  className="m-login--verification--links--modal--close-button"
                  variant="transparent"
                  aria-label={intl.formatMessage(messages.close)}
                >
                  <UIIcon src={CloseLine} />
                </IconButton>
                <div className="m-login--verification--links--modal--title">
                  <FormattedMessage
                    id=""
                    description="text in 2-auth page, asking user to choose between two verification options"
                    defaultMessage="Try one of these methods"
                  />
                </div>
                <div className="helpLinks">
                  {this.props.isSmsEnabled && (
                    <Button
                      id="resendCodeLink"
                      onClick={this.resendCode}
                      variant="outline"
                    >
                      {this.props.isAuthenticatorEnabled ? (
                        <FormattedMessage
                          id=""
                          description="button text in 2-auth page, when clicked sends auth code to user"
                          defaultMessage="Send me the code'"
                        />
                      ) : (
                        <FormattedMessage
                          id=""
                          description="button text in 2-auth page, when clicked resends auth code to user"
                          defaultMessage="Resend me the code"
                        />
                      )}
                    </Button>
                  )}
                  <Button
                    id="recoveryLink"
                    onClick={this.enterRecoveryCode}
                    variant="outline"
                  >
                    <FormattedMessage
                      id=""
                      description="link text, when clicked uses the recovery code that was provided to user before"
                      defaultMessage="Use my recovery code"
                    />
                  </Button>
                  <Button
                    href={HfReactHelper.getFaqUrl('requests/new')}
                    id="helpLink"
                    variant="outline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      id=""
                      description="Text for contact button"
                      defaultMessage="Contact support for help"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  },
});

module.exports = injectIntl(LogInVerificationForm);
