import React, { PropsWithChildren } from 'react';

/**
 * If window.dropbox.privacyConsent.autoInit is set, we know that the
 * consent banner is enabled and can render associated ui's.
 *
 * Depending on the current php layout, if the privacy consent script is placed after
 * the spa script in the html document, privacyConsent.autoInit will be false in the global scope.
 * For that reason we expose a react hook like function to get the value, because we know that by
 * the time react begins to render, all the <head> scripts will have been loaded and the
 * privacyConsent.autoInit window value will have been set.
 *
 * On the backend the consent banner is controlled by the following split:
 *
 * sign_services_2022_09_08_privacy_consent_banner
 *
 * I opted to not simply pass the feature flag to the frontend because
 * feature flags aren't setup on all pages (e.g. settings pages). I thought
 * it would be more simple to use privacyConsent.autoInit
 */

export function useCookiesAndCcpaIsEnabled() {
  return !!window?.dropbox?.privacyConsent?.autoInit;
}

type CookiesAndCCPAGateProps = PropsWithChildren<{
  targetValue?: boolean;
}>;

/**
 * If props.targetValue is true, children are rendered if cookie banner is enabled.
 *
 * If props.targetValue is false, children are rendered if cookie banner is disabled.
 */
export function CookiesAndCcpaFeatureGate({
  children,
  targetValue = true,
}: CookiesAndCCPAGateProps) {
  const isEnabled = useCookiesAndCcpaIsEnabled();
  if (isEnabled !== targetValue) {
    return null;
  }
  return <>{children}</>;
}
