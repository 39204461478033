import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RadioButton } from '@dropbox/dig-components/controls';
import { Text } from '@dropbox/dig-components/typography';
import { ReasonProps, values } from './types';
import styles from '../survey.module.scss';
import { getBrandName } from 'js/sign-components/common/brand';

function CancelReasonTemporary({ siteCode, ...props }: ReasonProps) {
  return (
    <div className={styles.reason}>
      <div className={styles.reasonGroup}>
        <RadioButton
          {...props}
          data-qa-ref="survey-reason-temporary"
          id="cancel-subscription-survey__radio-temporary"
          name="reason"
          value={values.TEMPORARY}
        />
        <Text
          tagName="label"
          htmlFor="cancel-subscription-survey__radio-temporary"
        >
          <FormattedMessage
            id=""
            description="An option the user can select when explaining why s/he is canceling their subscription."
            defaultMessage="I only needed {brandName} temporarily"
            values={{
              brandName: getBrandName(siteCode),
            }}
          />
        </Text>
      </div>
    </div>
  );
}

export default CancelReasonTemporary;
