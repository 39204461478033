/*
  If you edit this file, please remove this header and clean up the resulting eslint errors.
*/
/*
  eslint-disable
  import/named,
  import/no-commonjs,
  react/prop-types
*/
import './index.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from 'common/components/toggle';
import flagIcon from 'common/images/flag.svg';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import PricingLevels from './pricing-levels';
import CouponDisplay from './coupon-display';

const messages = defineMessages({
  errorMessage: {
    id: '',
    description: 'error, displayed to user when something went wrong.',
    defaultMessage: 'Sorry, we could not process your payment.',
  },
});

class PricingArea extends React.Component {
  state = {
    timePeriod:
      this.props.selectedTimePeriod || this.props.timePeriodChoices[1].value,
  };

  componentDidMount() {
    if (this.props.mustContactSales) $('#contactUsButton').click();
  }

  onSelection = (selection) => this.setState({ timePeriod: selection });

  isAnnual = () => this.state.timePeriod === 'Y';

  isMonthly = () => this.state.timePeriod === 'M';

  billingPlans = () => this.props.pricingData.billing_plans;

  pricingLevelCount = () => this.billingPlans().length;

  shouldDisplayToggle = () => this.props.timePeriodChoices.length > 1;

  hasValidCoupon = () => {
    const isAnnual = this.isAnnual();
    const isMonthly = this.isMonthly();
    const { coupon, pricingData } = this.props;
    const isCouponEligible = pricingData.billing_plans.filter(
      (el) => el.coupon === true,
    );
    if (isCouponEligible.length === 0) return false;
    if (coupon) {
      const { monthly_rate: monthlyRate, annual_rate: annualRate } = coupon;
      const invalidMonthlyRate = isMonthly && monthlyRate === 0;
      const invalidAnnualRate = isAnnual && annualRate === 0;
      const invalidCouponCode = !coupon.coupon_code;
      if (invalidMonthlyRate || invalidAnnualRate || invalidCouponCode)
        return false;
    }
    return !!coupon;
  };

  couponDisplay = () => {
    const { coupon } = this.props;
    return (
      <CouponDisplay
        couponCode={coupon.coupon_code}
        couponDescription={coupon.description}
      />
    );
  };

  toggleButton = () => {
    const { timePeriodChoices, selectedTimePeriod } = this.props;

    return (
      <Toggle
        data-testid="time-period-toggle"
        choices={timePeriodChoices}
        initialSelection={selectedTimePeriod}
        onSelection={this.onSelection}
      />
    );
  };

  apiText = () => {
    if (HfReactHelper.isHelloSign() && this.props.isApi) {
      return (
        <div className="row text-left l-padding-t-20 pp-api-documentation-text">
          <img
            className="pricing-area__flag-icon"
            src={flagIcon}
            style={{
              width: '12px',
              display: 'inline-block',
              marginLeft: '14px',
              marginRight: '10px',
            }}
          />
          <div style={{ display: 'inline-block' }} className="blurb-container">
            <>
              <div className="blurb-text">
                <FormattedMessage
                  id=""
                  description="sub-header, marketing, api pricing page, invites user to test API."
                  defaultMessage="For a full list of API features, check out our"
                />
              </div>
              <div className="blurb-link">
                <a href={HfReactHelper.getWebflowUrl('products/api/features')}>
                  <FormattedMessage
                    id=""
                    description="link, marketing, api pricing page, link to API documentation."
                    defaultMessage="API features page"
                  />
                </a>
              </div>
            </>
          </div>
        </div>
      );
    }
  };

  apiMessage = () => {
    if (
      HfReactHelper.isHelloSign() &&
      this.props.pricingSource === 'web' &&
      !this.props.canUseApi
    ) {
      return (
        <div className="row text-left l-padding-t-20 pp-api-pricing-text">
          <img
            className="pricing-area__flag-icon"
            src={flagIcon}
            style={{
              width: '12px',
              display: 'inline-block',
              marginLeft: '14px',
              marginRight: '10px',
            }}
          />
          <div style={{ display: 'inline-block' }} className="blurb-container">
            <>
              <div className="blurb-text">
                <FormattedMessage
                  id=""
                  description="sub-header, marketing, web pricing page, invites user to web features page"
                  defaultMessage="For a full list of eSignature features,"
                />
              </div>
              <div className="blurb-link">
                <a href={HfReactHelper.getWebflowUrl('features')}>
                  <FormattedMessage
                    id=""
                    description="link, marketing, web pricing page, link to web features page"
                    defaultMessage="check out our features page"
                  />
                </a>
                .
              </div>
            </>
          </div>
        </div>
      );
    }
  };

  errorMessage = () => {
    let errors;
    if (this.props.errors) {
      let errorMsg = this.props.intl.formatMessage(messages.errorMessage);
      if (this.props.errors['payment[submit]']) {
        errorMsg += ` ${this.props.errors['payment[submit]']}`;
      }
      if (this.props.errors['payment[cc_number]']) {
        errorMsg += ` ${this.props.errors['payment[cc_number]']}`;
      }
      if (this.props.errors['payment[0]']) {
        errorMsg += ` ${this.props.errors['payment[0]']}`;
      }
      errors = (
        <div data-test-id="errors" className="row text-center l-padding-v-20">
          <p className="c-red">{errorMsg}</p>
        </div>
      );
    }
    return errors;
  };

  render() {
    return (
      <div className="pricing-area-container">
        <div className="row text-left toggle-container">
          {this.hasValidCoupon() ? this.couponDisplay() : null}
          {this.shouldDisplayToggle() ? this.toggleButton() : null}
        </div>
        {this.apiMessage()}
        {this.apiText()}
        {this.errorMessage()}
        <div
          className="row levels l-padding-v-60 text-center"
          style={{ paddingTop: 0 }}
        >
          <PricingLevels
            isMonthly={this.isMonthly()}
            hasValidCoupon={this.hasValidCoupon()}
            billingPlans={this.billingPlans()}
            {...this.props}
            {...this.state}
          />
        </div>
      </div>
    );
  }
}

PricingArea.propTypes = {
  signupUrl: PropTypes.string,
  pricingSource: PropTypes.string,
  paymentForm: PropTypes.object.isRequired, // Serialized sfForm
  registerFaxLineForm: PropTypes.object, // Serialized sfForm
  signupForm: PropTypes.object.isRequired, // Serialized sfForm
  dbxPaymentMethod: PropTypes.object,
  mustContactSales: PropTypes.bool,
  skipCC: PropTypes.bool,
  trialPeriod: PropTypes.number,
  corpsLevelName: PropTypes.string,
  changePlanRestricted: PropTypes.bool,
  googleSignInClientId: PropTypes.string,
  csrfToken: PropTypes.string,
  canUseApi: PropTypes.bool,
  legalRequirementLinkHref: PropTypes.string,
  cancelLinkHref: PropTypes.string,
  selectedTimePeriod: PropTypes.oneOf(['Y', 'M']),
  timePeriodChoices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOf(['Y', 'M']),
    }),
  ),
  countryCodeFromIp: PropTypes.string,
};

module.exports = injectIntl(PricingArea);
