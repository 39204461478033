import React, { useCallback, useEffect, useRef } from 'react';
import { Title } from '@dropbox/dig-components/typography';
import styles from './white-labeling-app.module.scss';
import AccordionColorPicker, {
  AccordionColorPickerSection,
} from './accordion-color-picker';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@dropbox/dig-components/buttons';
import { MessageType, postTo } from 'js/sign-components/common/ts-message';

export const defaultColors = {
  page_background_color: '#f7f8f9',
  header_background_color: '#1a1a1a',
  text_color1: '#808080',
  text_color2: '#ffffff',
  link_color: '#0061FE',
  primary_button_color: '#0061FE',
  primary_button_text_color: '#ffffff',
  primary_button_color_hover: '#0061FE',
  primary_button_text_color_hover: '#ffffff',
  secondary_button_color: '#ffffff',
  secondary_button_text_color: '#0061FE',
  secondary_button_color_hover: '#ffffff',
  secondary_button_text_color_hover: '#0061FE',
  legal_version: 'terms1',
};

export function WhiteLabelingApp() {
  const [colors, setColors] =
    React.useState<Record<string, string>>(defaultColors);
  const [iframeKey, setIframeKey] = React.useState<number>(0);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [logoUrl, setLogoUrl] = React.useState<string>();
  const intl = useIntl();
  const messages = defineMessages({
    primaryButtonSectionLabel: {
      id: '',
      description: 'Primary Button sectionLabel',
      defaultMessage: 'Primary button colors',
    },
    primaryButtonColorLabel: {
      id: '',
      description: 'Primary Button color label',
      defaultMessage: 'Primary button color',
    },
    primaryButtonTextColorLabel: {
      id: '',
      description: 'Primary Button text color label',
      defaultMessage: 'Primary button text color',
    },
    primaryButtonHoverColorLabel: {
      id: '',
      description: 'Primary Button hover color label',
      defaultMessage: 'Primary button hover color',
    },
    secondaryButtonSectionLabel: {
      id: '',
      description: 'Secondary Button sectionLabel',
      defaultMessage: 'Secondary button colors',
    },
    secondaryButtonColorLabel: {
      id: '',
      description: 'Secondary Button button color label',
      defaultMessage: 'Secondary button color',
    },
    secondaryButtonTextColorLabel: {
      id: '',
      description: 'Secondary Button button text color label',
      defaultMessage: 'Secondary button text color',
    },
    secondaryButtonHoverColorLabel: {
      id: '',
      description: 'Secondary Button button hover color label',
      defaultMessage: 'Secondary button hover color',
    },
    secondaryButtonHoverTextColorLabel: {
      id: '',
      description: 'Secondary Button button text hover color label',
      defaultMessage: 'Secondary button text hover color',
    },
    backgroundColorsSectionLabel: {
      id: '',
      description: 'Background Color sectionLabel',
      defaultMessage: 'Background colors',
    },
    pageBackgroundColorLabel: {
      id: '',
      description: 'Page background color label',
      defaultMessage: 'Page background color',
    },
    headerBackgroundColorLabel: {
      id: '',
      description: 'Header background color label',
      defaultMessage: 'Header background color',
    },
    textColorsSectionLabel: {
      id: '',
      description: 'Text Colors Section Label',
      defaultMessage: 'Text colors',
    },
    textColor1Label: {
      id: '',
      description: 'Text Color 1 Label',
      defaultMessage: 'Text color 1',
    },
    textColor2Label: {
      id: '',
      description: 'Text Color 2 Label',
      defaultMessage: 'Text color 2',
    },
    linkColorLabel: {
      id: '',
      description: 'Link Color Label',
      defaultMessage: 'Link color',
    },
  });

  const updateColors = useCallback(() => {
    if (iframeRef.current?.contentWindow) {
      postTo(iframeRef.current.contentWindow, {
        type: MessageType.SignerAppDemoWhiteLabeling,
        whiteLabeling: colors,
      });
    }
  }, [colors]);

  const updateLogo = useCallback(() => {
    if (iframeRef.current?.contentWindow) {
      postTo(iframeRef.current.contentWindow, {
        type: MessageType.SignerAppDemoLogoUrl,
        url: logoUrl ?? '',
      });
    }
  }, [logoUrl]);

  useEffect(() => {
    updateColors();
  }, [updateColors]);

  useEffect(() => {
    updateLogo();
  }, [updateLogo]);

  const updateIframe = () => {
    updateColors();
    updateLogo();
  };

  const refreshIframe = () => {
    setIframeKey(iframeKey + 1);
  };

  const sections: AccordionColorPickerSection[] = [
    {
      sectionId: 'primary-button',
      sectionLabel: intl.formatMessage(messages.primaryButtonSectionLabel),
      items: [
        {
          id: 'primary_button_color',
          label: intl.formatMessage(messages.primaryButtonColorLabel),
        },
        {
          id: 'primary_button_text_color',
          label: intl.formatMessage(messages.primaryButtonTextColorLabel),
        },
        {
          id: 'primary_button_color_hover',
          label: intl.formatMessage(messages.primaryButtonHoverColorLabel),
        },
      ],
    },
    {
      sectionId: 'background-color',
      sectionLabel: intl.formatMessage(messages.backgroundColorsSectionLabel),
      items: [
        {
          id: 'header_background_color',
          label: intl.formatMessage(messages.headerBackgroundColorLabel),
        },
      ],
    },
    {
      sectionId: 'text-color',
      sectionLabel: intl.formatMessage(messages.textColorsSectionLabel),
      items: [
        {
          id: 'link_color',
          label: intl.formatMessage(messages.linkColorLabel),
        },
      ],
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.accordionWrapper}>
        <AccordionColorPicker
          getValue={(id) => colors[id]}
          setValue={(id, value) =>
            setColors((prev) => ({ ...prev, [id]: value }))
          }
          sections={sections}
          setLogoUrl={setLogoUrl}
        />
      </div>
      <div
        className={styles.preview}
        data-qa-ref="white-labeling-preview-selection"
      >
        <div className={styles.previewHeader}>
          <Title size="small">
            <FormattedMessage
              id=""
              description="Preview Title"
              defaultMessage="Preview"
            />
          </Title>
          <div className={styles.viewSelectorRow}>
            <Button variant="primary" onClick={refreshIframe}>
              <FormattedMessage
                id=""
                description="Button which restarts the contract from first page"
                defaultMessage="Restart Contract"
              />
            </Button>
          </div>
        </div>
        <div className={styles.previewComponentWrapper}>
          <iframe
            key={iframeKey}
            ref={iframeRef}
            src={'/api/white-labeling-signer-demo'}
            className={styles.signerIframe}
            onLoad={updateIframe}
          />
        </div>
      </div>
    </div>
  );
}
export default WhiteLabelingApp;
