import React from 'react';
import '../checkbox.scss';

const EnabledChecked = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
    <g
      id="input-/-checkbox-/-checked"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect
        id="Rectangle"
        x="0"
        y="0"
        fill="currentColor"
        width="16"
        height="16"
        rx="2"
      ></rect>
      <polyline
        id="Path-2"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="4 8.90518257 6.95751413 12 12 4"
      ></polyline>
    </g>
  </svg>
);

export default React.memo(EnabledChecked);
