import './admin-setting.scss';

import React from 'react';
import classnames from 'classnames';

import Toggle from 'hellospa/components/toggle';
import AdminSettingsLockSelect from 'hellospa/components/admin-settings-lock-select';

function AdminSetting({ id, className, ...props }) {
  const baseClass = 'admin-setting';

  const rootClasses = React.useMemo(() => {
    return classnames(baseClass, className, {
      [`${baseClass}--is-offset-controls`]: props.isOffsetControls,
      [`${baseClass}--is-arbitrary-design-offset`]:
        props.isArbitraryDesignOffset,
    });
  }, [className, props.isOffsetControls, props.isArbitraryDesignOffset]);

  const onToggleChange = React.useCallback(
    (evt) => {
      if (props.onToggleChange) {
        props.onToggleChange(evt.currentTarget.checked);
      }
    },
    [props],
  );

  const onLockChange = React.useCallback(
    (evt) => {
      if (props.onLockChange) {
        props.onLockChange(evt.currentTarget.value);
      }
    },
    [props],
  );

  const onValueChange = React.useCallback(
    ({ target }) => {
      if (props.onValueChange) {
        let value;

        switch (target.type) {
          case 'checkbox':
            value = target.checked;
            break;
          case 'file':
            value = target.files;
            break;
          default:
            value = target.value;
        }

        props.onValueChange(value);
      }
    },
    [props],
  );

  return (
    <div className={rootClasses}>
      <div className="admin-setting__col admin-setting__col--content">
        <div className="admin-setting__content" onChange={onValueChange}>
          {props.children}
        </div>
      </div>
      {props.customCol ? (
        <div className="admin-setting__col">{props.customCol}</div>
      ) : (
        <React.Fragment>
          <div className="admin-setting__col admin-setting__col--toggle">
            {props.hasToggle && (
              <Toggle
                data-testid="admin-setting-toggle"
                id={props.toggleId}
                className="admin-setting__toggle"
                defaultChecked={props.defaultToggled}
                disabled={!!props.toggleDisabled}
                onChange={onToggleChange}
                {...(props.toggled ? { checked: props.toggled } : {})}
                {...(props.toggleName ? { name: props.toggleName } : {})}
              />
            )}
          </div>
          <div className="admin-setting__col admin-setting__col--lock-select">
            {props.hasLock && (
              <AdminSettingsLockSelect
                id={props.lockId}
                name={props.lockName}
                defaultValue={props.defaultLockValue}
                onLockChange={onLockChange}
                disabled={!!props.lockDisabled}
                isOrgLevel={props.isOrgLevel}
                allowEachUserOption={!props.lockDisableEachUserOption}
                dmaSettingUrl={props.dmaSettingUrl}
                isDmaSetting={props.isDmaSetting}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default AdminSetting;
