import React from 'react';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import { Text } from '@dropbox/dig-components/typography';
import { Motion } from '@dropbox/dig-components/motion';
import { UIIcon } from '@dropbox/dig-icons';
import { LinkLine } from '@dropbox/dig-icons/assets';
import { useClipboard } from 'js/sign-components/common/hooks';
import { IconLink } from 'hello-react/components/icon-link';

import styles from './copy-text.scss';

type CopyTextProps = React.PropsWithChildren<{
  textToCopy: string;
  textCopiedMessage: string;
  className?: string;
}>;

function CopyText({
  textToCopy,
  textCopiedMessage,
  children,
  className = '',
  ...props
}: CopyTextProps) {
  const [, copyToClipboard] = useClipboard();
  const [showCopiedText, setShowCopiedText] = React.useState(false);

  // Hides the "Text copied" text after 5s without being
  // interrupted.
  const debouncedHideTextCopiedText = React.useMemo(
    () =>
      debounce(() => {
        setShowCopiedText(false);
      }, 5000 /* 5s */),
    [],
  );

  const copyTextToClipboard = React.useCallback(
    async (evt: React.MouseEvent<HTMLAnchorElement>) => {
      evt.preventDefault();

      try {
        await copyToClipboard(textToCopy);

        setShowCopiedText(true);
        debouncedHideTextCopiedText();
      } catch (err) {
        // Navigator must not have clipboard support.
      }
    },
    [copyToClipboard, textToCopy, debouncedHideTextCopiedText],
  );

  return (
    <div {...props} className={classnames(className, styles.copyText)}>
      <IconLink
        href="#"
        data-qa-ref="refer-modal-copy-button"
        icon={<UIIcon src={LinkLine} size="small" />}
        onClick={copyTextToClipboard}
      >
        {children}
      </IconLink>
      <Motion
        in={showCopiedText}
        transitionIn={{
          duration: 333,
          easing: 'enter',
          transitions: [{ property: 'opacity', value: 1 }],
        }}
        transitionOut={{
          duration: 333,
          easing: 'leave',
          transitions: [{ property: 'opacity', value: 0 }],
        }}
      >
        {({ style }) => (
          <Text style={style} className={styles.textCopiedMessage}>
            {textCopiedMessage}
          </Text>
        )}
      </Motion>
    </div>
  );
}

export default CopyText;
