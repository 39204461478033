/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  camelcase,
  func-names,
  guard-for-in,
  no-restricted-syntax
*/
import HelloSign from 'common/utils/hellosign';
import setTimeout from 'common/utils/set-timeout';

module.exports = {
  INIT_OPEN_DELAY_MS: 200,
  ANIMATION_SPEED_MS: 400,

  _createShowFunction(height, displayTime, _hideFn) {
    const self = this;
    return function () {
      window.scrollTo(0, 0); // Scroll to the top of the page
      $('body').animate(
        {
          top: `+=${height}`,
        },
        self.ANIMATION_SPEED_MS,
        (function (displayTime, _hideFn) {
          return function () {
            if (displayTime > 0) {
              setTimeout(_hideFn, displayTime);
            }
          };
        })(displayTime, _hideFn),
      );
    };
  },

  _createHideFunction() {
    const self = this;
    return function () {
      const container = $('#notification'); // $('body');
      container.animate(
        {
          height: '0', // '-=' + height
        },
        self.ANIMATION_SPEED_MS,
        () => {
          // Fast way to remove child DOM elements
          $('#notification>div.m-notification').empty();
          container[0].removeAttribute('style'); // remove the height: 0px style that's left over from the animation
          $('body').css('top', 0);
        },
      );
    };
  },

  /**
   * Display a notification
   *
   * @param {Object} options
   *  - class_name: 'success', 'error', or 'info'
   *  - header: String to be used as the heading inside the notification
   *  - text: String to be used as the normal text beneath the heading
   *  - display_time: Duration to display before auto-dismissal, in ms (0 for persistent)
   */
  displayNotification(options) {
    // Merge defaults and options
    const settings = $.extend(
      {
        class_name: 'info',
        box_id: `notification_${Math.round(Math.random() * 99999)}`,
        icon: '',
        header: '',
        text: '',
        display_time: 5000,
      },
      options,
    );

    // Build the notification HTML using a template
    let notification_html =
      '<div id="{{box_id}}" class="{{class_name}}">' +
      '<div class="columns small-12 text-center" style="height:100%;">' + // height 100% to allow for vertical centering
      '<div class="l-vertical-align-wrapper" style="height:100%;">' +
      '<div class="l-vertical-align">' +
      '<span class="m-notification--close">&#215;</span>' +
      '<p><strong>' +
      '{{header}}' +
      '</strong></p>' +
      '<p>' +
      '{{text}}' +
      '</p>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';

    // Replace template variables
    for (const i in settings) {
      notification_html = notification_html.replace(
        new RegExp(`{{${i}}}`, 'g'),
        settings[i],
      );
    }

    // Add to the page, but initially we will set it off-page and
    // allow it to render naturally, so that we can calculate its height
    const notification = $(notification_html).css({
      height: 'auto',
      width: '100%',
      position: 'absolute',
      top: '-1000px',
    });
    $('#notification>div.m-notification').append(notification);
    const height = $(notification).height() + 10; // 20px to account for 10px vertical padding

    // Once we have the 'natural' height, let's set it in stone we can
    // animate the element and also center the notification text vertically
    // inside the div.
    $(notification).css({
      height: `${height}px`,
      position: 'relative',
      left: '0px',
      top: '0px',
    });

    // Offset the page container by the notification height so that the
    // notification element appears to 'push' the container into place.
    $('body').css({
      position: 'relative',
      top: `-${height}px`,
    });

    // Create a closure we can pass around to close the notification
    // (we'll use this for closing after a timeout and for closing when
    // the user clicks on the notification)
    const _hideNotification = this._createHideFunction(height);

    // Add the click handler to close the notification
    $(notification).click(_hideNotification);

    // Delay the notification slightly to attract the user's attention
    setTimeout(
      this._createShowFunction(
        height,
        settings.display_time,
        _hideNotification,
      ),
      this.INIT_OPEN_DELAY_MS,
    );
  },

  displayModalNotification() {
    // TODO: Implement
  },

  attach() {
    if (window.location.search.indexOf('aliId=') >= 0) {
      this.displayNotification({
        class_name: 'success',
        header: 'Thank you. Your request has been received.',
      });
    }
    if (window) {
      HelloSign.displayNotification = this.displayNotification;
    }
  },
};
