import HfReactHelper from 'js/sign-components/common/hf-react-helper';

/**
 * Often when sending events to google tag manager, we append either:
 * * "- hsapi" if we are tracking an event related to hellosign api
 * * "- hs" if we are tracking an event related to hellosign
 * * "- hf" if we are tracking an event related to hellofax
 *
 * @param isApi Whether we should return the tracking variant for hellosign api
 * @returns string
 */
export function getTrackingVariant(
  isApi = false,
): 'hsapi' | 'hs' | 'hf' | 'hw' {
  if (HfReactHelper.isHelloFax()) {
    return 'hf';
  } else if (HfReactHelper.isHelloSign()) {
    return 'hs';
  } else if (isApi) {
    return 'hsapi';
  } else {
    return 'hw';
  }
}

/**
 * Returns the google analytics tracking label
 *
 * If pricing_page, continue (dbx | google) (<planType>)
 *
 * @param {"google" | "dbx" | "email"} buttonType
 */
export function getTrackingLabel(
  buttonType: 'google' | 'dbx' | 'email',
  googleAnalyticsTrackingVariant: string,
  planName: string | undefined,
): string {
  if (googleAnalyticsTrackingVariant === 'pricing_page') {
    return [
      'continue',
      buttonType === 'email' ? undefined : buttonType,
      planName?.toLowerCase?.(),
    ]
      .filter((str) => !!str)
      .join(' ');
  } else {
    return buttonType === 'email' ? 'continue' : `sign in with ${buttonType}`;
  }
}

// Map site codes to site names
export const siteAbbreviations = {
  S: 'hs',
  F: 'hf',
  W: 'hw',
};
