import React from 'react';
import classnames from 'classnames';
import styles from './text-field.scss';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  inputSize?: 'default' | 'small' | 'large';
};

export default function TextField({
  className = '',
  inputSize = 'default',
  ...rest
}: Props) {
  return (
    <React.Fragment>
      <input
        type="text"
        className={classnames(
          styles.textField,
          {
            [styles.smallSize]: inputSize === 'small',
            [styles.largeSize]: inputSize === 'large',
          },
          className,
        )}
        {...rest}
      />
    </React.Fragment>
  );
}
