import './index.scss';
import React from 'react';
import classNames from 'classnames';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import StringHelper from 'js/sign-components/common/string-helpers';
import { trackHeapCustomEvent } from 'js/sign-components/common/heap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import Spacer, { SpacerSize } from 'hellospa/foundation/spacing/spacer';
import pushAnalyticsEvent from 'common/utils/pushAnalyticsEvent';
import PricingLevelFeature from '../pricing-level-feature-new';
import MailToButton from '../mail-to-button';

const dbxLink = HfReactHelper.getWebflowUrl('integrations/dropbox');
const gdLink = HfReactHelper.getWebflowUrl('integrations/google-drive');
const mswLink = HfReactHelper.getWebflowUrl('integrations/microsoft-word');

const messages = defineMessages({
  altBillingInquiry: {
    id: '',
    description:
      'button, email header, button send automatic message to HS support with inquiry from user regarding alternative plans',
    defaultMessage: 'Alt Billing Plan Inquiry',
  },
  minNumFeatureValue: {
    id: '',
    description:
      'pricing page, main feature, minimum number value. example: 50+',
    defaultMessage: '{number}+',
  },
});

class CorporatePricingLevelNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      corpLevelName: 'Premium',
    };
  }

  onLevelClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleContactUsClick = () => {
    trackHeapCustomEvent('pricing_page_enterprise_contact_us');
    pushAnalyticsEvent({
      category: 'contactus-form-start',
      action: window.location.pathname,
      label: this.props.isApi
        ? '/enterprise-api-contact-us'
        : '/enterprise-lp-contact-us',
    });
  };

  haltEvents = (e) => {
    if (e) {
      e.stopPropagation();
    }
  };

  render() {
    // eslint-disable-next-line max-len
    const {
      intl,
      corporateFeatures,
      corporateFeatures: {
        features: [...featureProps],
      },
    } = this.props;
    const bodyClasses = classNames({
      'm-pricing-level--body': true,
      'small-12': true,
      columns: true,
      expanded: this.state.expanded,
    });
    const levelClasses = classNames({
      'small-6': true,
      'medium-4': true,
      'medium-offset-1': true,
      'large-12': true,
      'large-offset-0': true,
      columns: true,
      'level-title': true,
    });
    const arrowContainerClasses = classNames({
      'arrow-container': true,
      'hide-for-large-up': true,
      'hide-for-small': true,
      'small-2': true,
      'medium-1': true,
      columns: true,
    });
    const arrowClasses = classNames({
      'l-vertical-align': true,
      arrow: true,
      expanded: this.state.expanded,
    });
    const contactUsClasses = classNames({
      'phone-container': true,
      'hide-for-large-up': true,
      'small-6': true,
      'medium-5': true,
      'medium-offset-1': true,
      columns: true,
      'level-price': true,
    });
    const lookingForMoreClasses = classNames({
      'm-pricing-level--body': true,
      'small-10': true,
      'small-centered': true,
      columns: true,
      'looking-for-more': true,
      'l-padding-t-50': !!this.props.coupon,
    });

    let mainFeature = <></>;
    if (corporateFeatures.main_feature) {
      if (!this.props.isApi) {
        const name = (
          <div className="main-feature">
            <FormattedMessage
              id=""
              description="Text asking user to contact support if they want to buy a large number of user seats"
              defaultMessage="Start at <span>{minSize, number}</span> {minSize, plural, =1{user} other{users}}"
              values={{
                /* eslint-disable-next-line react/display-name */
                span: (...chunks) => (
                  <span className="c-cerulean">{chunks}</span>
                ),
                minSize: this.props.minSize,
              }}
            />
            <span className="contact-us">
              <FormattedMessage
                id=""
                description="Text asking user to contact support"
                defaultMessage="contact us"
              />
            </span>
          </div>
        );

        mainFeature = (
          <PricingLevelFeature
            name={name}
            planName={this.props.name}
            key={`feature-${name}`}
            expanded={this.state.expanded}
            removeCheckMark={true}
          />
        );
      } else if (featureProps) {
        const number = corporateFeatures.main_feature.number;
        if (!isNaN(number)) {
          if (number > 1) {
            // eslint-disable-next-line max-len
            corporateFeatures.main_feature.number = intl.formatMessage(
              messages.minNumFeatureValue,
              {
                number,
              },
            );
          }
        }
        featureProps.unshift(corporateFeatures.main_feature);
      }
    }

    let features = <></>;
    if (corporateFeatures && featureProps) {
      features = featureProps.map((feat) => {
        let name = feat.name || '';
        const detail = feat.detail || null;
        const number = feat.number || feat.number === 0 ? feat.number : null;

        if (name === 'Integrations') {
          name = (
            <FormattedMessage
              id=""
              description="Integrations with Dropbox, Google Drive, Microsoft Word, and more"
              defaultMessage="Integrations with <a>Dropbox</a>, <b>Google Drive</b>, <c>Microsoft Word</c>, and more"
              values={{
                // eslint-disable-next-line react/display-name
                a: (...chunks) => {
                  return <a href={dbxLink}>{chunks}</a>;
                },
                b: (...chunks) => {
                  return <a href={gdLink}>{chunks}</a>;
                },
                c: (...chunks) => {
                  return <a href={mswLink}>{chunks}</a>;
                },
              }}
            />
          );
        }

        return (
          <PricingLevelFeature
            name={name}
            planName={this.props.name}
            detail={detail}
            number={number}
            key={`feature-${name}`}
            expanded={this.state.expanded}
          />
        );
      });
    }

    const corpLevelColumnClasses = classNames({
      'm-pricing-level-new': true,
      'small-12': true,
      'medium-12': true,
      'large-3': this.props.totalColumns >= 3,
      'large-4': this.props.totalColumns === 2,
      'large-6': this.props.totalColumns === 1,
      columns: true,
      end: true,
      'corporate-pricing-level-new': true,
    });

    let contactButton = null;
    if (this.props.changePlanRestricted) {
      const siteCode = HfReactHelper.isHelloSign()
        ? HfReactHelper.HfSites.SITE_CODE_HS
        : HfReactHelper.HfSites.SITE_CODE_HF;
      const supportEmail = HfReactHelper.HfConstants.supportEmail[siteCode];

      contactButton = (
        <MailToButton
          email={supportEmail}
          subject={intl.formatMessage(messages.altBillingInquiry)}
        />
      );
    } else {
      contactButton = (
        <a
          data-qa-ref={`${StringHelper.pathnameToKebabCase()}-premium-contactUsBtn`}
          className="button m-button bg-ashen m-button--rounded hbg-cerulean c-white progress-button state-loading"
          onClick={this.handleContactUsClick}
          href={this.props.corporateFeatures.contact_url}
        >
          <FormattedMessage
            id=""
            description="button text of contact sales team modal, when clicked, user queries are submitted for sales team"
            defaultMessage="Contact us"
          />
        </a>
      );
    }

    const currentPlanIndicator = (
      <div
        className="current-plan"
        data-qa-ref={`${StringHelper.pathnameToKebabCase()}-premium-thisIsYourPlan>`}
      >
        <FormattedMessage
          id=""
          description="button, pricing page, button is locked, and informs user that plan is already active"
          defaultMessage="This is your plan"
        />
      </div>
    );

    let primaryColorBG = 'bgi-cerulean';
    if (!HfReactHelper.isHelloSign()) {
      primaryColorBG = 'bgi-goldenrod';
    }

    return (
      <div className={corpLevelColumnClasses}>
        <div
          onClick={this.onLevelClick}
          className={classNames(primaryColorBG, {
            'm-pricing-level--header': true,
            'small-12': true,
            columns: true,
            block: true,
          })}
        >
          <div className={levelClasses}>
            <div className="level-title l-vertical-align-wrapper">
              <div className="l-vertical-align">{this.state.corpLevelName}</div>
            </div>
          </div>
          {!this.props.isCurrentPlan && (
            <div className="hide-for-large-up small-6 medium-5 medium-offset-1 columns level-price">
              <div className="l-vertical-align-wrapper">
                <div className="l-vertical-align">
                  <span className="price">{contactButton}</span>
                </div>
              </div>
            </div>
          )}
          {this.props.isCurrentPlan && (
            <div className={contactUsClasses}>
              <div className="phone-container l-vertical-align-wrapper">
                {!this.state.expanded && contactButton}
              </div>
            </div>
          )}
          <div className={arrowContainerClasses}>
            <div className="level-title l-vertical-align-wrapper">
              <div className={arrowClasses}>&nbsp;</div>
            </div>
          </div>
        </div>
        <div className={classNames(bodyClasses, 'hide-for-medium-down')}>
          <div className="first-section block">
            <div className={lookingForMoreClasses}>
              <FormattedMessage
                id=""
                description="marketing, instead of price question is shown to prompt user to contact support for more details on custom subscription"
                defaultMessage="Looking for more?"
              />
            </div>
            <div className="block">
              {this.props.isCurrentPlan ? currentPlanIndicator : contactButton}
            </div>
            {this.props.canDirectPurchase && (
              <Spacer below={SpacerSize.SM}>
                <div className="m-pricing-level--direct-purchase"></div>
              </Spacer>
            )}
          </div>
        </div>
        {this.props.isCurrentPlan && (
          <div className={classNames(bodyClasses, 'hide-for-large-up')}>
            <div className="first-section block">
              <div className="block">{currentPlanIndicator}</div>
            </div>
          </div>
        )}
        {mainFeature}
        {features}
        <div
          className={classNames(
            'm-pricing-level--feature final-block small-12 columns block',
            {
              expanded: this.state.expanded,
            },
          )}
        >
          <FormattedMessage
            id=""
            description="pricing page, plan has many more features"
            defaultMessage="and many more features"
          />
        </div>
        <div className="hide-for-large-up">
          <div
            className={classNames(
              'm-pricing-level--feature final-block small-12 columns block',
              {
                expanded: this.state.expanded,
              },
            )}
          >
            <FormattedMessage
              id=""
              description="pricing plan disclaimer"
              defaultMessage="*Additional cost add-on (starts at 5 users), <l1>contact sales</l1> for details."
              values={{
                l1: (...chunks) => (
                  <a
                    href={HfReactHelper.getWebflowUrl(
                      'form/contact-us-general',
                    )}
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

CorporatePricingLevelNew.defaultProps = { changePlanRestricted: false };

export default injectIntl(CorporatePricingLevelNew);
