import React, { useState } from 'react';
import { Button } from '@dropbox/dig-components/buttons';
import { FormattedMessage } from 'react-intl';
import hsFetch from 'js/sign-components/common/hs-fetch';
import DowngradeFlowModalContainer from 'hellospa/components/cancellation-flow-modal';
import {
  PauseCancelModalContext,
  SafeDowngradeStatusEnum,
} from 'hellospa/components/cancellation-flow-modal/types';
import { SiteCodeType } from 'js/sign-components/common/brand';
import { modalTypes } from '../../../hellospa/components/cancellation-flow-modal/constants';

type ResumePausedButtonProps = {
  isApi: boolean;
  siteCode: SiteCodeType;
};

// This button is meant to allow users to resume their plan when paused
const ResumePausedButton = ({ isApi, siteCode }: ResumePausedButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pauseCancelModalContext, setPauseCancelModalContext] =
    useState<PauseCancelModalContext | null>(null);

  const planType = isApi ? 'api' : 'ui';
  const onClickOpenUnpauseModal = () => {
    if (siteCode === 'S') {
      const formData = new FormData();

      formData.append('p', planType);

      hsFetch('/endpoint/org/PauseOption', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: formData,
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data.errorMessage) {
            window.location.reload();
          } else {
            setIsModalOpen(true);
            setPauseCancelModalContext({ ...data, siteCode });
          }
        });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {pauseCancelModalContext && (
        <DowngradeFlowModalContainer
          modalContext={pauseCancelModalContext}
          isModalOpen={isModalOpen}
          onClose={handleCloseModal}
          startingModal={modalTypes.RESUME_PAUSED}
          currentStatus={SafeDowngradeStatusEnum.PAUSED}
        />
      )}
      <Button
        variant="transparent"
        onClick={onClickOpenUnpauseModal}
        data-qa-ref="unpause-plan-btn"
        type="button"
      >
        <FormattedMessage
          id=""
          defaultMessage="Resume plan"
          description="Text for a button which allows the user to unpause subscription plan when pressed."
        />
      </Button>
    </>
  );
};

export default ResumePausedButton;
