import React from 'react';
import Tour, { Step } from 'js/sign-components/tour/tour';
import { defineMessages, useIntl } from 'react-intl';
import { LayerContext } from '@dropbox/dig-components/layer';
import { useMatchMedia } from 'js/sign-components/common/hooks';
import { useFeatureFlag } from 'js/sign-components/common/feature-flag';
import { sideBarCollapsedMaxWidth } from '.';

export enum GlobalNavTourItems {
  signDocuments = 'signDocuments',
  templates = 'templates',
  integrations = 'integrations',
  helpButton = 'helpButton',
}

const messages = defineMessages({
  title1: {
    id: '',
    description: 'Text which describes where to begin',
    defaultMessage: '👋 Start here',
  },
  content1: {
    id: '',
    description: 'Text that discribes sign documents button',
    defaultMessage: 'For whatever needs signing, by you or someone else.',
  },
  title2: {
    id: '',
    description: 'Text that describes templates button',
    defaultMessage: 'Save and reuse',
  },
  content2: {
    id: '',
    description: 'Text that describes how templates work',
    defaultMessage:
      'Format a document once, then save it as a template so you can get it signed again in the future.',
  },
  title3: {
    id: '',
    description: 'Text that describes integrations',
    defaultMessage: 'Connect tools you already use',
  },
  content3: {
    id: '',
    description: 'Text that describes what integrations are for',
    defaultMessage:
      'Get documents signed directly in Gmail, Microsoft Word, Salesforce, and more.',
  },
  title4: {
    id: '',
    description: 'Text that describes help button',
    defaultMessage: 'Have more questions?',
  },
  content4: {
    id: '',
    description: 'Text that describes what help button is for',
    defaultMessage:
      'Learn time saving tips to help with document signing, storing, and tracking.',
  },
});

export function GlobalNavOnboardingTour() {
  const showOnboardingTour = useFeatureFlag(
    'HelloSign_Yetis_show_onboarding_tours',
  );
  const intl = useIntl();
  const sidebarIsCollapsed = useMatchMedia(
    `(max-width: ${sideBarCollapsedMaxWidth})`,
  );

  if (!showOnboardingTour || sidebarIsCollapsed) {
    return null;
  }

  const tourSteps: Step[] = [
    {
      title: intl.formatMessage(messages.title1),
      content: intl.formatMessage(messages.content1),
      tourItem: GlobalNavTourItems.signDocuments,
      withDefaultCtas: true,
      placement: 'right-start',
    },
    {
      title: intl.formatMessage(messages.title2),
      content: intl.formatMessage(messages.content2),
      tourItem: GlobalNavTourItems.templates,
      withDefaultCtas: true,
      placement: 'right',
    },
    {
      title: intl.formatMessage(messages.title3),
      content: intl.formatMessage(messages.content3),
      tourItem: GlobalNavTourItems.integrations,
      withDefaultCtas: true,
      placement: 'right',
    },
    {
      title: intl.formatMessage(messages.title4),
      content: intl.formatMessage(messages.content4),
      tourItem: GlobalNavTourItems.helpButton,
      withDefaultCtas: true,
      placement: 'bottom-end',
    },
  ];

  return (
    <LayerContext.Provider value={{ zIndex: 30 }}>
      <Tour steps={tourSteps} tourName="onboarding-global-nav-tour" />
    </LayerContext.Provider>
  );
}
