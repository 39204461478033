import './index.scss';

import React from 'react';

export default function AdminSettingsDropdown({
  id,
  title,
  notice,
  options,
  onChange,
  ...props
}) {
  // Augmenting the onChange handler because in some cases
  // we won't handle changes directly on the field but
  // rather via its parent form after the event has bubbled
  // up the DOM tree. If we supply a value but we don't
  // supply an onChange handler, React will log a warning.
  // by explicitly providing this augmented onChange
  // handler, we can silence those warnings.
  const onSelectChange = React.useCallback(
    (...args) => {
      if (onChange) {
        onChange(...args);
      }
    },
    [onChange],
  );

  return (
    <div className="admin-settings-dropdown">
      {title && (
        <label className="admin-settings-dropdown__label" htmlFor={id}>
          {title}
        </label>
      )}
      <select
        id={id}
        ref={props.forwardedRef}
        className="admin-settings-dropdown__select"
        data-qa-ref={id}
        data-testid={id}
        onChange={onSelectChange}
        {...props}
      >
        {options.map(({ value, text }, i) => (
          <option key={i} value={value}>
            {text}
          </option>
        ))}
      </select>
      {notice && <p className="admin-settings-dropdown__notice">{notice}</p>}
    </div>
  );
}
