import React from 'react';
import { CountryCode } from 'signer-app/authentication/countries';
import { createSimpleDataContext } from 'signer-app/utils/create-simple-data-context';

export type CountryCodeContextShape = {
  countryCode: CountryCode;
  setCountryCode: (countryCode: CountryCode) => void;
};

export const CountryCodeContext =
  createSimpleDataContext<CountryCodeContextShape>('CountryCodeContext');

export type UseCountryCodeProps = {
  initialCountryCode: CountryCode;
};

export function CountryCodeProvider({
  children,
}: React.PropsWithChildren<object>) {
  const [countryCode, setCountryCode] = React.useState<CountryCode>('US');

  return (
    <CountryCodeContext.Provider value={{ countryCode, setCountryCode }}>
      {children}
    </CountryCodeContext.Provider>
  );
}

export function useCountryCode() {
  return CountryCodeContext.useContext();
}
