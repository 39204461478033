import React, { useState } from 'react';
import Tour, { Step } from 'js/sign-components/tour/tour';
import { defineMessages, useIntl } from 'react-intl';
import { LayerContext } from '@dropbox/dig-components/layer';
import { useMatchMedia } from 'js/sign-components/common/hooks';
import { sideBarCollapsedMaxWidth } from '../global-header';
import { useFeatureFlag } from 'js/sign-components/common/feature-flag';
import { useIsTourCompleted } from 'js/sign-components/tour/useTour';

export enum ApiDashboardTourItems {
  apiNavLink = 'apiNavLink',
}

const messages = defineMessages({
  title1: {
    id: '',
    description: 'Tooltip title text',
    defaultMessage: '👋 Explore the new API Dashboard',
  },
  content1: {
    id: '',
    description: 'Tooltip description text',
    defaultMessage:
      'Gain rich insights into your API activity and integration health with the new, interactive API Dashboard.',
  },
  gotIt: {
    id: '',
    description: 'Button text to dismiss tooltip',
    defaultMessage: 'Got it',
  },
});

export function ApiDashboardLinkTooltip() {
  const intl = useIntl();
  const sidebarIsCollapsed = useMatchMedia(
    `(max-width: ${sideBarCollapsedMaxWidth})`,
  );
  // we don't want to show the api link tour if the onboarding tour might show
  const onboardingTourIsEnabled = useFeatureFlag(
    'HelloSign_Yetis_show_onboarding_tours',
  );
  // we only show the api tour if the onboarding tour has already been completed on page load
  const [onboardingTourIsCompleted] = useState(
    useIsTourCompleted('onboarding-global-nav-tour'),
  );
  const onboardingTourMightBeShowing =
    onboardingTourIsEnabled && !onboardingTourIsCompleted;

  if (sidebarIsCollapsed) {
    return null;
  }
  if (onboardingTourMightBeShowing) {
    return null;
  }

  const tourSteps: Step[] = [
    {
      variant: 'rich',
      title: intl.formatMessage(messages.title1),
      content: intl.formatMessage(messages.content1),
      tourItem: ApiDashboardTourItems.apiNavLink,
      stepCounter: false,
      cta: <span>{intl.formatMessage(messages.gotIt)}</span>,
      placement: 'right-end',
    },
  ];

  return (
    <LayerContext.Provider value={{ zIndex: 30 }}>
      <Tour steps={tourSteps} tourName="api-dashboard-nav-link" />
    </LayerContext.Provider>
  );
}
