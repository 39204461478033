import React from 'react';

export function GoogleLogo() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12.3576C20 11.8195 19.9523 11.3086 19.8706 10.8114H12.1737V13.8834H16.5807C16.3831 14.8914 15.8042 15.7429 14.9459 16.3218V18.3653H17.5751C19.1145 16.9417 20 14.8438 20 12.3576Z"
        fill="#4285F4"
      />
      <path
        d="M12.1737 20.3474C14.3806 20.3474 16.2265 19.6118 17.5751 18.3653L14.9459 16.3218C14.2103 16.8123 13.2771 17.112 12.1737 17.112C10.0417 17.112 8.2367 15.6748 7.58961 13.7335H4.87867V15.8382C6.22052 18.5083 8.97914 20.3474 12.1737 20.3474Z"
        fill="#34A853"
      />
      <path
        d="M7.58961 13.7335C7.41933 13.2431 7.33078 12.7186 7.33078 12.1737C7.33078 11.6288 7.42614 11.1043 7.58961 10.6139V8.50915H4.87867C4.32014 9.6126 4 10.8523 4 12.1737C4 13.4951 4.32014 14.7348 4.87867 15.8382L7.58961 13.7335Z"
        fill="#FBBC05"
      />
      <path
        d="M12.1737 7.23542C13.3793 7.23542 14.4555 7.65092 15.3069 8.46148L17.6364 6.13197C16.2265 4.81056 14.3806 4 12.1737 4C8.97914 4 6.22052 5.83908 4.87867 8.50915L7.58961 10.6139C8.2367 8.67263 10.0417 7.23542 12.1737 7.23542Z"
        fill="#EA4335"
      />
    </svg>
  );
}
