/* eslint-disable */
import './white-labeling.scss';
import React from 'react';
import WhiteLabelingLandingPageV2 from './white-labeling-v2';

module.exports = class WhiteLabelingLandingPage extends React.Component {
  render() {
    return <WhiteLabelingLandingPageV2 />;
  }
};
