import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import DeleteDocumentsConfirmationModal from './delete-documents-confirmation-modal';
import ongoingDeleteImg from './assets/ongoing-delete.png';

const messages = defineMessages({
  btnCancel: {
    id: '', // IDs are automatically generated
    description:
      'Button text in modal to cancel the delete documents confirmation',
    defaultMessage: 'Cancel',
  },
  btnDeleteDocuments: {
    id: '', // IDs are automatically generated
    description:
      'Button text in modal to confirm the delete documents confirmation',
    defaultMessage: 'Delete future documents',
  },
  modalTitleOneTimeDelete: {
    id: '', // IDs are automatically generated
    description: 'Title text for the delete documents confirmation modal',
    defaultMessage: 'Ongoing delete',
  },
  modalDescriptionOneTimeDelete: {
    id: '',
    description: 'Description text for the delete documents confirmation modal',
    defaultMessage:
      'All completed documents for your organization will be automatically deleted from {brandName} going forward. <a>Learn more</a>',
  },
  modalFlagText: {
    id: '',
    description: 'Title on top of the image that gives context to the action',
    defaultMessage: 'Delete documents going forward?',
  },
  modalError: {
    id: '',
    description:
      'Error message when trying to move forward with the delete documents policy setting without confirming the conditions',
    defaultMessage:
      'Oops, you have to agree with the conditions above before proceeding.',
  },
  modalUnderstandNotRecoverable: {
    id: '',
    description:
      'Condition to agree to when enabling the One-time delete documents policy setting',
    defaultMessage:
      'I understand that documents will not be recoverable from {brandName} 72 hours after they are deleted.',
  },
  modalAgreesPermanentlyDeletion: {
    id: '',
    description:
      'Condition to agree to when enabling the One-time delete documents policy setting',
    defaultMessage:
      'I agree to allow {brandName} to automatically delete all completed documents starting on {startDate, date, MM/dd/yyyy}.',
  },
});

class OngoingDeleteDocumentsConfirmationModal extends React.PureComponent {
  onConfirmed = () => {
    this.props.onConfirmed({ ongoingDeletionRequest: true });
  };

  render() {
    const { onConfirmed } = this;
    const { isOpen, onRequestClose } = this.props;

    return (
      <DeleteDocumentsConfirmationModal
        isOpen={isOpen}
        onConfirmed={onConfirmed}
        onRequestClose={onRequestClose}
        flagImage={ongoingDeleteImg}
        messages={messages}
      ></DeleteDocumentsConfirmationModal>
    );
  }
}

export default injectIntl(OngoingDeleteDocumentsConfirmationModal);
