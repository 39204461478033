/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  global-require,
  import/no-extraneous-dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const TabSwitcher = createReactClass({
  propTypes: {
    onNextClick: PropTypes.func.isRequired,
    onPrevClick: PropTypes.func.isRequired,
  },

  onNextClick() {
    this.props.onNextClick();
  },

  onPrevClick() {
    this.props.onPrevClick();
  },

  render() {
    return (
      <div className="hide-for-large-up">
        <div className="m-tabs--switcher right" onClick={this.onNextClick}>
          <img
            className="m-tabs--right-arrow"
            src={require('./arrow_right@2x.png')}
          />
        </div>
        <div className="m-tabs--switcher left" onClick={this.onPrevClick}>
          <img
            className="m-tabs--left-arrow"
            src={require('./arrow_left@2x.png')}
          />
        </div>
      </div>
    );
  },
});

module.exports = TabSwitcher;
