/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  func-names,
  import/no-extraneous-dependencies,
  max-len,
  react/no-find-dom-node
*/
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import createReactClass from 'create-react-class';
import FadeOut from './fade-out';

const MultiSelect = createReactClass({
  propTypes: {
    initiallySelectedOptionValues: PropTypes.array.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,

    options(props, propName, componentName) {
      if (props[propName] === undefined || !$.isArray(props[propName])) {
        throw new Error(`${propName} must be an array for ${componentName}`);
      }
      props[propName].forEach((entry) => {
        if (entry.key === undefined) {
          throw new Error(
            `key attribute must exist for each entry of ${propName} for ${componentName}`,
          );
        }
        if (entry.descr === undefined) {
          throw new Error(
            `descr attribute must exist for each entry of ${propName} for ${componentName}`,
          );
        }
      });
    },

    statusMessage(props, propName, componentName) {
      if (props[propName]) {
        if (props[propName].className === undefined) {
          throw new Error(
            `${propName}.className must be defined for ${componentName}`,
          );
        }
        if (props[propName].text === undefined) {
          throw new Error(
            `${propName}.text must be defined for ${componentName}`,
          );
        }
      }
    },
  },

  // Just a band-aid to prevent react from making the multi-select from disappearing. Fix
  // is to refactor this component to be entirely reactified. (CC)
  shouldComponentUpdate() {
    return !this.preventRerender;
  },

  getInitialState() {
    return {
      selectedOptionValues: this.props.initiallySelectedOptionValues,
    };
  },

  componentDidMount() {
    this._setupMultiSelect();
  },

  componentDidUpdate() {
    this._setupMultiSelect();
  },

  _setupMultiSelect() {
    const self = this;
    const selectEl = $('select', ReactDOM.findDOMNode(this));

    // in jQuery 1.9+, trying to "destroy" prior to initialization will
    // throw an error. Check first if it exists.
    if (selectEl.multiselect('instance')) {
      selectEl.multiselect('destroy');
    }

    selectEl.multiselect({
      selectEl: this,
      minWidth: 300,
      maxHeight: 300,
      noneSelectedText: 'Please select one',
      open() {
        self.preventRerender = true;
      },
      selectedList: 1,
      selectedText: (num, total, checkedEls) => {
        const firstSelectedOpt = self.props.options.find(
          (opt) => opt.key === checkedEls[0].value,
        );
        if (!firstSelectedOpt) {
          return '';
        }

        const firstOptDescr = firstSelectedOpt.descr;
        if (num > 2) {
          return `${firstOptDescr} and ${num - 1} more`;
        } else if (num === 2) {
          const secondSelectedOpt = self.props.options.find(
            (opt) => opt.key === checkedEls[1].value,
          );
          return `${firstOptDescr} and ${secondSelectedOpt.descr}`;
        } else {
          return firstOptDescr;
        }
      },
      close() {
        self.preventRerender = false;

        if (self.props.disabled) {
          return;
        }
        // FIXME: onChange and .selectedOptions both don't work when we use jQuery multiselect plugin, so we have to go this less React-y route of fetching data from the DOM using jQuery
        const checkedEls = selectEl.multiselect('getChecked');
        const checkedValues = checkedEls
          .map(function () {
            return this.value;
          })
          .get();

        self.props.onSelectionChange(checkedValues);
        self.setState({ selectedOptionValues: checkedValues });
      },
    });

    if (this.props.disabled) {
      selectEl.multiselect('disable');
    }
  },

  render() {
    const selectionOptions = this.props.options.map((option) => {
      return (
        <option key={option.key} value={option.key}>
          {option.descr}
        </option>
      );
    }, this);

    const msg = this.props.statusMessage ? (
      <FadeOut
        key={this.state.selectedOptionValues + this.props.statusMessage.text}
      >
        <div className={`message ${this.props.statusMessage.className}`}>
          {this.props.statusMessage.text}
        </div>
      </FadeOut>
    ) : null;

    return (
      <span
        {...omit(this.props, [
          'app',
          'options',
          'initiallySelectedOptionValues',
          'onSelectionChange',
          'statusMessage',
        ])}
      >
        <span>
          <select
            className={this.props.disabled ? 'disabled' : ''}
            multiple="multiple"
            defaultValue={this.state.selectedOptionValues}
          >
            {selectionOptions}
          </select>
        </span>
        {msg}
      </span>
    );
  },
});

module.exports = MultiSelect;
