import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Menu } from '@dropbox/dig-components/menu';
import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import styles from './global-nav.scss';
import { getBrandName } from 'js/sign-components/common/brand';
import type { PropsWithSiteCode } from 'js/sign-components/common/brand';
import { CookiesAndCcpaFeatureGate } from 'hello-react/components/cookies-and-ccpa-feature';

type GlobalNavFooterProps = PropsWithSiteCode;

function GlobalNavFooter({ siteCode }: GlobalNavFooterProps) {
  const site = HfReactHelper.HfSites.getSite(siteCode);
  const isSign = HfReactHelper.HfSites.isHelloSign(site);
  const isFax = HfReactHelper.HfSites.isHelloFax(site);

  return (
    <div className={styles.footerItems}>
      <div className={styles.footerItem}>
        <Button
          className={styles.navButton}
          variant="transparent"
          href={HfReactHelper.urlHelper('info/pricing', site)}
          data-qa-ref="global-nav-pricing-link"
          target="_blank"
          rel="noreferrer"
          hasNoUnderline
        >
          <Text color="faint">
            <FormattedMessage
              id=""
              description="Text for a link which redirects user to the Plans and Pricing page."
              defaultMessage="Pricing"
            />
          </Text>
        </Button>
      </div>
      <div className={styles.footerItem}>
        <Menu.Wrapper closeOnSelection>
          {({ getContentProps, getTriggerProps }) => (
            <>
              <Button
                {...getTriggerProps()}
                className={styles.navButton}
                variant="transparent"
                data-qa-ref="global-nav-products-menu"
                hasNoUnderline
              >
                <Text color="faint">
                  <FormattedMessage
                    id=""
                    description="Text for a link which redirects user to the Products page."
                    defaultMessage="Products"
                  />
                </Text>
              </Button>
              <Menu.Content {...getContentProps()}>
                <>
                  <Menu.Segment
                    // Only show this label on HelloSign. This menu on
                    // HelloFax is simpler and the label is not necessary.
                    {...(isSign
                      ? {
                          withLabel: (
                            <FormattedMessage
                              id=""
                              description="Heading for a list of products owned by us."
                              defaultMessage="Our products"
                            />
                          ),
                        }
                      : {})}
                  >
                    {isFax && (
                      <Menu.LinkItem
                        data-qa-ref="global-nav-esign-product-link"
                        href={HfReactHelper.urlHelper(
                          'home/bridge/to/S',
                          HfReactHelper.HfSites.SITE_CODE_HF,
                        )}
                        target="_blank"
                      >
                        {getBrandName('S')}
                      </Menu.LinkItem>
                    )}
                    <Menu.LinkItem
                      data-qa-ref="global-nav-esign-api-product-link"
                      href={HfReactHelper.getWebflowUrl('products/api')}
                      target="_blank"
                    >
                      <FormattedMessage
                        id=""
                        description="A link which redirects the user to the API page for the given product. Ex. 'Acme API'"
                        defaultMessage="{productName} API"
                        values={{
                          productName: getBrandName('S'),
                        }}
                      />
                    </Menu.LinkItem>
                    <Menu.LinkItem
                      data-qa-ref="global-nav-esign-mobile-product-link"
                      href={HfReactHelper.getWebflowUrl('features/mobile')}
                      target="_blank"
                    >
                      <FormattedMessage
                        id=""
                        description="A link which redirects the user to the mobile app page for the given product. Ex. 'Acme mobile'"
                        defaultMessage="{productName} mobile"
                        values={{
                          productName: getBrandName('S'),
                        }}
                      />
                    </Menu.LinkItem>
                    {isSign && (
                      <Menu.LinkItem
                        data-qa-ref="global-nav-fax-product-link"
                        href={HfReactHelper.urlHelper(
                          'home/bridge/to/F',
                          HfReactHelper.HfSites.SITE_CODE_HS,
                        )}
                        target="_blank"
                      >
                        {getBrandName('F')}
                      </Menu.LinkItem>
                    )}
                  </Menu.Segment>
                  {isSign && (
                    <Menu.Segment
                      withLabel={
                        <FormattedMessage
                          id=""
                          description="Heading for a list of integrations available for our product."
                          defaultMessage="Integrations"
                        />
                      }
                    >
                      <Menu.LinkItem
                        data-qa-ref="global-nav-gmail-integration-link"
                        href={HfReactHelper.getWebflowUrl('integrations/gmail')}
                        target="_blank"
                      >
                        <FormattedMessage
                          id=""
                          description="A link which redirects the user to Gmail integration page."
                          defaultMessage="Gmail"
                        />
                      </Menu.LinkItem>
                      <Menu.LinkItem
                        data-qa-ref="global-nav-gdocs-integration-link"
                        href={HfReactHelper.getWebflowUrl(
                          'integrations/google-docs',
                        )}
                        target="_blank"
                      >
                        <FormattedMessage
                          id=""
                          description="A link which redirects the user to Google Docs integration page."
                          defaultMessage="Google Docs"
                        />
                      </Menu.LinkItem>
                      <Menu.LinkItem
                        data-qa-ref="global-nav-gdrive-integration-link"
                        href={HfReactHelper.getWebflowUrl(
                          'integrations/google-drive',
                        )}
                        target="_blank"
                      >
                        <FormattedMessage
                          id=""
                          description="A link which redirects the user to Google Drive integration page."
                          defaultMessage="Google Drive"
                        />
                      </Menu.LinkItem>
                    </Menu.Segment>
                  )}
                </>
              </Menu.Content>
            </>
          )}
        </Menu.Wrapper>
      </div>
      <div className={styles.footerItem}>
        <Menu.Wrapper closeOnSelection>
          {({ getContentProps, getTriggerProps }) => (
            <>
              {/* Move Privacy and legal to help nav to be more consistent with dropbox */}
              <CookiesAndCcpaFeatureGate targetValue={false}>
                <Button
                  {...getTriggerProps()}
                  className={styles.navButton}
                  variant="transparent"
                  data-qa-ref="global-nav-privacy-legal-menu"
                  hasNoUnderline
                >
                  <Text color="faint">
                    <FormattedMessage
                      id=""
                      description="Text for a link which redirects user to the Privacy and Legal page."
                      defaultMessage="Privacy and legal"
                    />
                  </Text>
                </Button>
              </CookiesAndCcpaFeatureGate>
              <Menu.Content {...getContentProps()}>
                <>
                  <Menu.LinkItem
                    data-qa-ref="global-nav-security-link"
                    href={HfReactHelper.getWebflowUrl('trust/security')}
                    target="_blank"
                  >
                    <FormattedMessage
                      id=""
                      description="Text for a link which redirects user to the website Security information page."
                      defaultMessage="Security"
                    />
                  </Menu.LinkItem>
                  <Menu.LinkItem
                    data-qa-ref="global-nav-legal-link"
                    href={HfReactHelper.getWebflowUrl('legal')}
                    target="_blank"
                  >
                    <FormattedMessage
                      id=""
                      description="Text for a link which redirects user to the Legal information page."
                      defaultMessage="Legal"
                    />
                  </Menu.LinkItem>
                  <Menu.LinkItem
                    data-qa-ref="global-nav-privacy-link"
                    href={HfReactHelper.getWebflowUrl('privacy')}
                    target="_blank"
                  >
                    <FormattedMessage
                      id=""
                      description="Text for a link which redirects user to the Privacy Policy page."
                      defaultMessage="Privacy"
                    />
                  </Menu.LinkItem>
                  <Menu.LinkItem
                    data-qa-ref="global-nav-terms-link"
                    href={HfReactHelper.getWebflowUrl('terms')}
                    target="_blank"
                  >
                    <FormattedMessage
                      id=""
                      description="Text for a link which redirects user to the Terms and Conditions page."
                      defaultMessage="Terms"
                    />
                  </Menu.LinkItem>
                </>
              </Menu.Content>
            </>
          )}
        </Menu.Wrapper>
      </div>
    </div>
  );
}

export default GlobalNavFooter;
