/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  array-callback-return,
  eqeqeq,
  import/no-extraneous-dependencies,
  max-len,
  no-lonely-if,
  no-restricted-syntax,
  react/prop-types
*/
import './index.scss';
import React from 'react';
import watchAppNotifierMixin from 'common/components/mixins/watch-app-notifier';
import Button from 'common/components/button';
import createReactClass from 'create-react-class';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import FaxLineConfigEntry from './config-entry';

const messages = defineMessages({
  searchInputPlaceholder: {
    id: '',
    description:
      'HelloFax, placeholder, input field, placeholder for input field that performs search of fax numbers registered under current user',
    defaultMessage: 'Fax number or email address',
  },
});

const FaxLineConfigList = createReactClass({
  mixins: [watchAppNotifierMixin],

  propTypes: {},

  getInitialState() {
    const app = this.props.app || window.app;
    return {
      app,
      user: app.user,
      faxLines: app.user.getFaxLines(),
      teamMembers: app.serverSideGeneratedData.allTeamMembers, // app.user.getTeam().getMembers({ pageSize: Infinity }), // This should be allTeamMembers
      query: '',
    };
  },

  componentDidMount() {
    // register to receive addedFaxLine events
    $(document).bind('addedFaxLine', this.handleAddedFaxLine); // FIXME: old jquery
  },

  componentDidUpdate() {
    // FIXME: old jquery
    $('.fax-line-member-email-search').autocomplete({
      source: this.getAutocompleteResults,
      select: this.autocompleteMadeSelection,
    });
  },

  // If there are more than this many fax lines, search functionality will be enabled.
  // Also, only this many fax lines will be displayed on page load.
  numFaxLinesForSearch: 5,

  // Maximum number of fax line config widgets to display (because rendering too many hangs the browser)
  maxFaxLineConfigsToDisplay: 50,

  // Returns whether search functionality should be enabled
  searchEnabled() {
    return this.state.faxLines.length > this.numFaxLinesForSearch;
  },

  // Strip a string of everything but digits and plus symbols
  // Example: '+1 (555) 444-3333' becomes '+15554443333'
  stripFaxNumberString(text) {
    return text.replace(/[^\d+]/g, '');
  },

  // Provides search results to the autocomplete box
  getAutocompleteResults(request, response) {
    const text = request.term;
    const textDigits = this.stripFaxNumberString(text);
    const matches = [];
    const limit = 10;

    if (textDigits.length > 0) {
      (this.state.faxLines || []).forEach((faxLine) => {
        if (matches.length < limit && faxLine.number.includes(textDigits)) {
          matches.push(hellofaxJS.formatInternationalFaxNumber(faxLine.number));
        }
      });
    }
    (this.state.teamMembers || []).forEach((teamMember) => {
      if (matches.length < limit && teamMember.emailAddress.startsWith(text)) {
        matches.push(teamMember.emailAddress);
      }
    });

    response(matches);
  },

  // Invoked when an autocomplete item is selected
  autocompleteMadeSelection(event, ui) {
    event.target.value = ui.item.value;
    this.commitSearchQuery(event.target.form);
  },

  // Save the search query, triggering a re-render with the results
  commitSearchQuery(form) {
    const query = form.elements[0].value;
    this.setState({ query });
  },

  // OnSubmit handler for search form
  searchFaxLines(event) {
    event.preventDefault();

    // Try to close the autocomplete menu
    // FIXME: old jquery
    $('.fax-line-member-email-search').autocomplete('close');

    // Commit the search query
    const form = event.target;
    this.commitSearchQuery(form);
  },

  // OnChange handler for search input field
  searchTextChanged(event) {
    // Clear the current search if the user clears the input
    if (event.target.value === '') {
      this.commitSearchQuery(event.target.form);
    }
  },

  handleAddedFaxLine(e) {
    this.state.faxLines.addFaxLine({ data: e.addedFaxLines[0] });
  },

  updateFaxLineStatusCode(guid, newStatusCode) {
    const faxLines = this.state.faxLines;
    for (const i in faxLines) {
      if (faxLines[i].guid === guid) {
        faxLines[i].statusCode = newStatusCode;
      }
    }
    this.setState({ faxLines });
  },

  removeFaxLine(faxLine) {
    const updatedFaxLines = this.state.faxLines.filter((candidate) => {
      return candidate.guid !== faxLine.guid;
    });

    this.setState({
      faxLines: updatedFaxLines,
      faxLinesCount: updatedFaxLines.length,
    });
  },

  render() {
    const { intl } = this.props;
    // Get only the fax lines we wish to display right now (search functionality happens here)
    const searchEnabled = this.searchEnabled();
    const query = this.state.query;
    const queryExists = query.length > 0;
    let tooManyFaxLinesToDisplay = false;
    let needToSearchToSeeMore = false;
    let faxLines = (this.state.faxLines || []).filter((faxLine, i) => {
      if (searchEnabled && queryExists) {
        // Return true if the number matches the query
        if (query.match(/[a-zA-Z]/) === null) {
          const queryDigits = this.stripFaxNumberString(query);
          if (queryDigits.length > 0 && faxLine.number.includes(queryDigits)) {
            return true;
          }
        }

        // Return true if any user's email matches the query
        for (let j = 0; j < faxLine.teamMembers.length; j++) {
          if (faxLine.teamMembers[j].emailAddress.startsWith(query)) {
            return true;
          }
        }

        // Don't show this FaxLine if it didn't match the query
        return false;
      } else {
        // If no query present, just show the first n FaxLines by default
        if (i >= this.numFaxLinesForSearch) {
          needToSearchToSeeMore = true;
          return false;
        } else {
          return true;
        }
      }
    });

    if (faxLines.length >= this.maxFaxLineConfigsToDisplay) {
      // Over the limit. Hide this fax line and show a message to the user
      tooManyFaxLinesToDisplay = true;
      faxLines = faxLines.slice(0, this.maxFaxLineConfigsToDisplay);
    }

    // Get only the team members that aren't pending
    const teamMembers = (this.state.teamMembers || []).filter((tm) => {
      return !tm.pending;
    });

    // Create the search form if search is supposed to be enabled
    let searchWidget;
    if (searchEnabled) {
      searchWidget = (
        <div>
          <div className="search-header">
            <FormattedMessage
              id=""
              description="HelloFax, header, section for searching users fax numbers"
              defaultMessage="Manage fax numbers"
            />
          </div>
          <form className="search-fax-lines" onSubmit={this.searchFaxLines}>
            <input
              type="text"
              placeholder={intl.formatMessage(messages.searchInputPlaceholder)}
              className="fax-line-member-email-search"
              onChange={this.searchTextChanged}
            />
            <Button iconUri="v2/common/search_icon_white.svg" type="submit" />
          </form>
        </div>
      );
    }

    // Create the configuration components for all the visible fax lines
    const faxLineConfigs = faxLines.map((faxLine) => {
      return (
        <FaxLineConfigEntry
          handleDelete={this.removeFaxLine}
          key={faxLine.number}
          faxLine={faxLine}
          teamMembers={teamMembers}
          hasMultiple={this.state.faxLines.length > 1}
          app={this.props.app}
        />
      );
    });

    // Create the message to conditionally show at the end of the list
    let postMessage;
    if (tooManyFaxLinesToDisplay) {
      postMessage = (
        <div className="search-footer-msg">
          <FormattedMessage
            id=""
            description="HelloFax, label, information, describes rules by which current list of fax numbers is shown"
            defaultMessage="{maxFaxLineConfigsToDisplay, plural,
              one {Showing the the first # match. Search for a more specific term to narrow the results.}
              other {Showing the the first # matches. Search for a more specific term to narrow the results.}}"
            values={{
              maxFaxLineConfigsToDisplay: this.maxFaxLineConfigsToDisplay,
            }}
          />
        </div>
      );
    } else if (needToSearchToSeeMore) {
      postMessage = (
        <div className="search-footer-msg">
          <FormattedMessage
            id=""
            description="HelloFax, label, information, describes rules by which current list of fax numbers is shown"
            defaultMessage="{numFaxLinesForSearch, plural,
              one {Only showing # fax line. Perform a search using the form above to see more.}
              other {Only showing # fax lines. Perform a search using the form above to see more.}}"
            values={{ numFaxLinesForSearch: this.numFaxLinesForSearch }}
          />
        </div>
      );
    } else if (queryExists && faxLines.length == 0) {
      postMessage = (
        <div className="search-footer-msg">
          <FormattedMessage
            id=""
            description="HelloFax, label, information, informs user that fax search query did not produce any results"
            defaultMessage="No results found for <b>{query}</b>. Please try again."
            values={{ query }}
          />
        </div>
      );
    }

    return (
      <div id="fax_line_config">
        {searchWidget}
        <div className="clearfix">{faxLineConfigs}</div>
        {postMessage}
      </div>
    );
  },
});

module.exports = injectIntl(FaxLineConfigList);
