/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-extraneous-dependencies
*/
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './or-divider.scss';

/**
 * A horizontal rule with the word "OR" appearing over the center
 */
export default class OrDivider extends React.Component {
  render() {
    const rootClassName = this.props.isModal
      ? 'm-or-divider--modal-google-login-border'
      : 'm-or-divider';
    return (
      <div
        className={
          this.props.isVertical
            ? 'm-or-divider--inline-google-login-border'
            : rootClassName
        }
      >
        <div
          className={`${this.props.isVertical ? 'm-or-divider--or-inline-google-login-border ' : ''}m-or-divider--or`}
        >
          <FormattedMessage
            id=""
            description="text in signup container, also means 'if not' || 'and also' "
            defaultMessage="or"
          />
        </div>
        <div
          className={
            this.props.isVertical
              ? 'm-or-divider--inline-google-login-border--rule'
              : 'm-or-divider--rule'
          }
        ></div>
      </div>
    );
  }
}
