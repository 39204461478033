import './admin-settings-row.scss';
import './admin-settings-row-simple.scss';
import './admin-settings-row-link.scss';
import './admin-settings-row-integration.scss';

import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Toggle from 'hellospa/components/toggle';
import IntegrationConfirmDialog from './integration-confirm-dialog';

export function AdminSettingsRow({ titleBody, ...props }) {
  const baseClass = 'admin-settings-row';

  const rootClasses = React.useMemo(() => {
    return classnames(baseClass, props.className, {
      [`${baseClass}--is-primary`]: props.isPrimary,
    });
  }, [props.className, props.isPrimary]);

  return (
    <div className={rootClasses}>
      <div className="admin-settings-row__col admin-settings-row__col--title">
        <div className="admin-settings-row__title">{titleBody()}</div>
      </div>
      <div className="admin-settings-row__col admin-settings-row__col--body">
        <div className="admin-settings-row__body">{props.children}</div>
      </div>
    </div>
  );
}

export function AdminSettingsSimpleRow({ title, tooltip, ...props }) {
  return (
    <AdminSettingsRow
      {...props}
      titleBody={() => (
        <p>
          {title}
          {tooltip && (
            <span className="admin-settings-simple-row__tooltip">
              <span className="admin-settings-simple-row__tooltip-content">
                {tooltip}
              </span>
            </span>
          )}
        </p>
      )}
      {...props}
    >
      {props.children}
    </AdminSettingsRow>
  );
}

export function AdminSettingsIntegrationRow({ onToggleChange, ...props }) {
  const [showModal, setShowModal] = React.useState(false);
  const [checked, setChecked] = React.useState(props.toggleChecked);

  const onChange = React.useCallback(
    (evt) => {
      const isChecked = evt.currentTarget.checked;

      setChecked(isChecked);

      if (!isChecked) {
        setShowModal(true);
      }

      if (onToggleChange) {
        onToggleChange(isChecked);
      }
    },
    [onToggleChange],
  );

  const onModalRequestClose = React.useCallback(
    (disable) => {
      onToggleChange(!disable);
      setChecked(!disable);
      setShowModal(false);
    },
    [onToggleChange],
  );

  return (
    <AdminSettingsRow
      className="admin-settings-integration-row"
      isPrimary={props.isPrimary}
      titleBody={() => (
        <React.Fragment>
          <div className="admin-settings-integration-row__icon">
            {props.icon()}
          </div>
          <p className="admin-settings-integration-row__title">{props.title}</p>
        </React.Fragment>
      )}
    >
      <div className="admin-setting">
        <div className="admin-setting__col admin-setting__col--content">
          <div className="admin-setting__content">{props.description}</div>
        </div>
        {props.isComingSoon ? (
          <div className="admin-setting__col admin-setting__col--toggle">
            <FormattedMessage
              id=""
              description="Used to indicate an integration is not yet available, but will be available soon."
              defaultMessage="Coming soon"
            />
          </div>
        ) : (
          <div className="admin-setting__col admin-setting__col--toggle">
            <Toggle
              data-testid="admin-setting-toggle"
              id={props.toggleId}
              className="admin-setting__toggle"
              checked={checked}
              defaultChecked={props.defaultToggled}
              disabled={!!props.toggleDisabled}
              onChange={onChange}
              name={props.toggleName}
            />
            <IntegrationConfirmDialog
              isOpen={showModal}
              integrationName={props.title}
              onRequestClose={onModalRequestClose}
            />
          </div>
        )}
      </div>
    </AdminSettingsRow>
  );
}

export default AdminSettingsRow;
