import React from 'react';
import propTypes from 'prop-types';
import styles from './oauth-request.scss';

class OauthRequest extends React.Component {
  static propTypes = {
    user_name: propTypes.string.isRequired,
    _csrf_token: propTypes.string.isRequired,
    cached_params_token: propTypes.string.isRequired,
    instance_url: propTypes.string.isRequired,
  };

  render() {
    return (
      <div id="site-wrapper">
        <div id="site-canvas">
          <div className="l-app--container l-padding-t-60">
            <div className="l-app--content center">
              <div className="row">
                <h2 className="headline">Hello, {this.props.user_name}</h2>
                <h1 className="headline">Granting access to Salesforce</h1>
                <h2>Org: {this.props.instance_url}</h2>
              </div>

              <div className={styles['mt-5']}>
                <p>Salesforce will have access to:</p>
                <ul className={styles['mt-3']} style={{ marginTop: '1rem' }}>
                  <li>Your basic information</li>
                  <li>Documents</li>
                  <li>Templates</li>
                  <li>Sending signature requests</li>
                </ul>
              </div>

              <div className={styles['mt-5']}>
                <form
                  className={styles.inline}
                  method="get"
                  action="/salesforce/SalesforceOAuthCallback"
                >
                  <input type="hidden" name="confirm" value="1" />
                  <input
                    type="hidden"
                    name="_csrf_token"
                    value={this.props._csrf_token}
                  />
                  <input
                    type="hidden"
                    name="cached_params_token"
                    value={this.props.cached_params_token}
                  />
                  <button
                    type="submit"
                    className="m-button bg-cerulean c-white"
                  >
                    Grant
                  </button>
                </form>
                <a href="/home/index" className="m-button bg-red c-white">
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OauthRequest;
