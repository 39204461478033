import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  Carousel,
  CarouselButton,
  CarouselContainer,
  CarouselHeading,
  CarouselSlide,
  CarouselSlideActions,
  CarouselSlideColumn,
  CarouselSlideColumnContent,
  CarouselText,
} from '@dropbox/dig-components/carousel';
import { Modal } from '@dropbox/dig-components/modal';
import { DoorAwaySpot } from '@dropbox/dig-illustrations/dist/cjs/spot/door-away';

import styles from './modal-throttle-usage.module.scss';

export const messages = defineMessages({
  headerText: {
    id: '',
    description: 'Carousel header',
    defaultMessage: 'Sorry for the trouble...',
  },
  carouselText: {
    id: '',
    description: 'Carousel text',
    defaultMessage:
      'Due to higher than usual demand, signing and sending documents is unavailable right now. We apologize for the inconvenience. Please try again later.',
  },
  carouselOk: {
    id: '',
    description: 'Primary button "OK" text',
    defaultMessage: 'OK',
  },
});

type Props = {
  handleModalClose: () => void;
};

export function ModalThrottleUsage({ handleModalClose }: Props) {
  const intl = useIntl();

  return (
    <Modal
      aria-label={intl.formatMessage(messages.headerText)}
      open={true}
      isCentered
      onRequestClose={handleModalClose}
      shouldCloseOnOverlayClick={true}
    >
      <Carousel width={700} height={420} totalSlides={1}>
        {() => (
          <CarouselContainer>
            <CarouselSlide inverted={false}>
              <CarouselSlideColumn>
                <CarouselSlideColumnContent>
                  <CarouselHeading>
                    {intl.formatMessage(messages.headerText)}
                  </CarouselHeading>
                  <CarouselText>
                    {intl.formatMessage(messages.carouselText)}
                  </CarouselText>
                </CarouselSlideColumnContent>
                <CarouselSlideActions>
                  <CarouselButton variant="primary" onClick={handleModalClose}>
                    {intl.formatMessage(messages.carouselOk)}
                  </CarouselButton>
                </CarouselSlideActions>
              </CarouselSlideColumn>
              <CarouselSlideColumn className={styles.illustrationContainer}>
                <DoorAwaySpot />
              </CarouselSlideColumn>
            </CarouselSlide>
          </CarouselContainer>
        )}
      </Carousel>
    </Modal>
  );
}
