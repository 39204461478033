import * as Sentry from '@sentry/browser';

declare global {
  interface Window {
    onArkoseLoad: (e: Enforcement) => void;
  }
}

export type Enforcement = {
  run: () => unknown;
  setConfig: (config: any) => unknown;
};
export const loadArkoseCdnScript = (
  publicApiKey: string,
  onArkoseLoad: (e: Enforcement) => void,
  useNewHostname = false,
) => {
  const script = document.createElement('script');
  if (useNewHostname) {
    script.src = `//dropbox-api.arkoselabs.com/v2/${publicApiKey}/api.js`;
  } else {
    script.src = `//client-api.arkoselabs.com/v2/${publicApiKey}/api.js`;
  }
  script.async = true;
  script.defer = true;
  script.onerror = () => {
    Sentry.captureException('Unable to fetch api.js from Arkose', {
      tags: {
        src: script.src,
      },
    });
  };
  window.onArkoseLoad = onArkoseLoad;
  script.setAttribute('data-callback', 'onArkoseLoad');
  document.body.appendChild(script);
};
